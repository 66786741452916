import React, { useState } from 'react'
import { Column, Row } from './containers'
import { FormTextInput, FormCheckboxInput } from './forms'
import 'react-dropdown/style.css'
import { genderPattern, gendersList, getMinimumTestDate, getPreferredTimeList, getPreferredTimeListPattern, preferredTimeList, preferredTimePattern } from '../utils/uitl'
import { sortableUid } from '../utils'

const OrderPatientDetailsSameDayEditor = ({ editPatientMode, isMailKit, orgs }) => {
    console.log('patient details editor: ', isMailKit)

    let date = new Date()
    let options = { day: '2-digit', month: '2-digit', year: 'numeric' }
    let today = date.toLocaleDateString('en-GB', options)
    let todayTime = date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    })
    const datalistId = sortableUid();
    const datalistIdPt = sortableUid();

    return (
        <>
            <Column size={8}>
                <h5>Basic Individual Details</h5>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Full name *"
                        name="fullName"
                        required
                        errorMessage="Field is required"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        pattern={/^\d+$/}
                        label="Telephone *"
                        name="telephone"
                        required
                        errorMessage="Field is required"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Email *"
                        name="email"
                        required
                        pattern={/(.*)@(.*)\.([a-zA-Z]{2,20})/}
                        errorMessage="Has to be valid email address"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Confirm Email"
                        name="confirmEmail"
                        confirmationFor="email"
                        errorMessage="Emails must match"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Gender"
                        required
                        listId={datalistId}
                        datalist={gendersList}
                        pattern={genderPattern}
                        name="gender"
                        errorMessage="Please enter one of: Male, Female, Transgender or Other"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Date of birth (DD / MM / YYYY)"
                        placeholder="01/11/1980"
                        //mask="99  /  99  /  9999"
                        name="DOB"
                        required
                        pattern={/^(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](?:19|20)\d\d$/}
                        errorMessage="Please enter date in DD/MM/YYYY format"
                    />

                </Row>
                <Row size={8} spacingTop="s">
                    <FormTextInput
                        desktopSize={8}
                        tabletSize={8}
                        label="Address line 1 *"
                        name="addressLine1"
                        required
                        errorMessage="Field is required"
                    />
                </Row>
                <Row size={8} spacingTop="s">
                    <FormTextInput
                        desktopSize={8}
                        tabletSize={8}
                        label="Address line 2"
                        name="addressLine2"
                        errorMessage="Field is required"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Town/City *"
                        name="city"
                        required
                        errorMessage="Field is required"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Postcode *"
                        name="postcode"
                        required
                        errorMessage="Field is required"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Country *"
                        name="country"
                        required
                        errorMessage="Field is required"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Date of Test (DD / MM / YYYY)"
                        name="dateOfTest"
                        type="date"
                        min={getMinimumTestDate()}
                        //pattern={/^(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](?:19|20)\d\d$/}
                        errorMessage="Please enter date in DD / MM / YYYY format"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Preferred Time"
                        name="preferredTime"
                        listId={datalistIdPt}
                        datalist={getPreferredTimeList()}
                        pattern={getPreferredTimeListPattern()}
                        errorMessage={`Please enter preferred time slot from the dropdown`}
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Preferred Time Instructions"
                        name="instructions"
                        errorMessage="Enter value"
                    />
                </Row>

                <h5>Additional Patient Details</h5>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Nationality"
                        name="nationality"
                        errorMessage="Enter value"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="National Health Number"
                        name="healthNumber"
                        errorMessage="Enter value"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Document Type"
                        name="documentType"
                        errorMessage="Enter value"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Document Number"
                        name="documentNumber"
                        errorMessage="Enter value"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Document Authority"
                        name="documentAuthority"
                        errorMessage="Enter value"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Issuing Country"
                        name="documentCountry"
                        errorMessage="Enter value"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={8}
                        tabletSize={8}
                        label="Document Expiration"
                        placeholder="01/11/2025"
                        name="documentExpiration"
                        pattern={/^(([0-2][0-9]|(3)[0-1])+\/+(((0)[0-9])|((1)[0-2]))+\/+(?:19|20)\d\d$)$/}
                        errorMessage="Please enter document expiration in DD/MM/YYYY format"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Given Name"
                        name="givenName"
                        errorMessage="Enter value"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Family Name"
                        name="familyName"
                        errorMessage="Enter value"
                    />
                </Row>
            </Column>
        </>
    )
}

export { OrderPatientDetailsSameDayEditor }
