import React from 'react'
import { Column, Row, Body, Spacer, Button, Image } from '../../components'
import groupDesktopImg from './assets/hp-group-desktop.svg'
import groupTabletImg from './assets/hp-group-tablet.svg'
import groupMobileImg from './assets/hp-group-mobile.svg'
import partnerImg from './assets/partner.svg'
import healthPractitioner2Img from './assets/health-practitioner-2.svg'
import scientistImg from './assets/scientist.svg'

const ForHealthPractitionersPage = () => {
	return (
		<>
			<Column marketing className="for-hp hero" centered>
				<Column desktopSize={8}>
					<h1 className="huge centered">Covid-19 testing for your patients</h1>
					<Body paragraph>
						Expand or improve Covid-19 testing services to your patients. Our digital platform is simple and convenient to use, enabling you to obtain fast and accurate Covid-19 results, on demand. Our results dashboard provides you with an easy overview of tests conducted and more.
					</Body>
					{/* <Body paragraph>
						Being confident in their health also allows you to determine who is able to travel for
						critical business needs, who can attend face-to-face meetings and who can be
						re-organised to perform crucial business functions.
					</Body> */}
				</Column>
				<Spacer spacing="m" />
				<Image
					desktopSrc={groupDesktopImg}
					tabletSrc={groupTabletImg}
					mobileSrc={groupMobileImg}
					className="group-img"
					alt="Illustration of the fact that tested for covid-19 people can work together without fear"
					width="100%"
				/>
			</Column>
			<Column marketing desktopSize={6} className="for-hp " centered>
				<Column desktopSize={7} bordered centered>
					<Column desktopSize={7} className="scientist-background" centered>
						<img src={scientistImg} alt="Tested people can work together without fear" />
					</Column>

					<h1>Payments received for testing</h1>
					<Body paragraph centered>
						Health Practitioners and health practices are paid for every test administered,<br /> so the more tests you administer and send for analysis, the more you can earn.
						{/* <br /> Consider it our reward for a job well done in the fight against Covid-19. */}
					</Body>
				</Column>
			</Column>
			<Row marketing desktopSize={12} className="for-hp " centered>
				<Column desktopSize={4} tabletSize={10} className="desktop">
					<img src={healthPractitioner2Img} alt="Tested people can work together without fear" />
				</Column>
				<Column desktopSize={5} tabletSize={10}>
					<h1>Simple and accurate</h1>
					<Column desktopSize={4} tabletSize={10}>
						<Body paragraph>
							The testing process is extremely simple: just order the kit for your customers,
							administer it and mail it back. We take care of everything else. Our proprietary
							platform integrates seamlessly into your workflow and usually provides results within
							24 hours of receipt by the laboratory. We have automated the data tracking, analysis
							and reporting processes to remove as much opportunity for human error, ensuring you
							can be confident your results are accurate and timely.
						</Body>
					</Column>
				</Column>
				<Column desktopSize={1} tabletSize={0}></Column>
			</Row>

			<Column marketing className="for-hp alternative" centered>
				<Column desktopSize={6} centered>
					<img src={partnerImg} alt="Tested people can work together without fear" />

					<h1>Earn as you test</h1>
					<Body paragraph centered style={{ textAlign: "left" }}>
						Every test administered and sent for analysis earns you an incentive payment. The more
						tests you administer and send for analysis, the more you can earn.
						<br /> Consider it our reward for a job well done in the fight against Covid-19.
					</Body>
					<Spacer spacing="m" />
					<Button href="/contact-us">Get in touch</Button>
				</Column>
			</Column>
		</>
	)
}

export { ForHealthPractitionersPage }
