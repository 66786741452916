import React from 'react'
import './hamburger-icon.css'

const resolveClassName = (opened, closeIcon) => {
	if (closeIcon) {
		return 'open'
	}

	return opened ? 'open' : ''
}

const HamburgerIcon = ({ opened, closeIcon }) => {
	const className = resolveClassName(opened, closeIcon)

	return (
		<div id="hamburger-icon" className={className}>
			<span></span>
			<span></span>
		</div>
	)
}

export { HamburgerIcon }
