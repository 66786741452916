import React from 'react'
import { Link } from 'react-router-dom'
import { Column, Row, Spacer, Body } from '.'
import { Button } from './Button'
import { useAuth } from '../utils'
import { Authorized } from '../utils/backend'

const FooterColumn = ({ heading, children }) => {
	return (
		<Column desktopSize={2} tabletSize={6} spacingTop="l">
			<Body small>{heading}</Body>
			{children}
		</Column>
	)
}

const FooterBar = (props) => {
	const { authStatus } = useAuth()
	const isLoggedIn = authStatus === Authorized

	return (
		<Column marketing className="footer segment">
			<Row style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
				<FooterColumn heading="Information">
					<Link className="footer navigation blue" to="/about-us">
						About us
					</Link>
					{/* <Link className="footer navigation blue" to="/test-to-release">
						Test to Release
					</Link> */}
					<Link className="footer navigation blue" to="/for-individuals">
						Individuals & families
					</Link>
					<Link className="footer navigation blue" to="/for-businesses">
						For organisations
					</Link>
					<Link className="footer navigation blue" to="/for-health-practitioners">
						For health practitioners
					</Link>
					<Link className="footer navigation blue" to="/test-kit-instructions">
						Test instructions
					</Link>
					<Link className="footer navigation blue" to="/contact-us">
						Contact us
					</Link>
				</FooterColumn>
				<FooterColumn heading={isLoggedIn ? '' : 'Access your account'}>
					{!isLoggedIn && (
						<>
							<Spacer spacing="xs" />
							<Button fitted href="/test-results" noSpacing>
								Get my test results
							</Button>
							<Spacer spacing="xs" />
							<Button fitted alternative href="/login" noSpacing>
								Log in
							</Button>
						</>
					)}
				</FooterColumn>
				<FooterColumn heading="Legal">
					<Link className="footer navigation" to="/terms-and-conditions">
						Terms and conditions
					</Link>
					<Link className="footer navigation" to="/privacy-policy">
						Privacy policy
					</Link>
				</FooterColumn>
				<FooterColumn heading="Contact us">
					<a href="mailto:enquiries@beaconsecure.co.uk">enquiries@beaconsecure.co.uk</a>
					<Body>0800 078 6112</Body>
					<br />
					<Body>
						20-22 Wenlock Road
						<br />
						London
						<br />
						N1 7GU <br />
						United Kingdom
					</Body>
				</FooterColumn>
			</Row>

			<Column padding="m" style={{ borderTop: '1px solid #c7d4da' }} spacingTop="m">
				<Body small>
					All content provided on this site is for informational purposes only. Any medical
					decisions regarding treatments and diagnoses should be made in consultation with a medical
					practitioner.
				</Body>
				<Row>
					<Body small>Copyright © Beacon Secure Technologies Ltd 2020</Body>
				</Row>
			</Column>
		</Column>
	)
}

export { FooterBar }
