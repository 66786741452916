import React, { useState, useEffect } from 'react'
import { Column, Row, Body, TextInput, Button, Spacer } from '../../../components'
import { withAuthentication, useOrdering, useAuth } from '../../../utils'
import { ConfirmOrder } from '../ConfirmOrder'
import { useHistory } from 'react-router-dom'
import { HealthPractitioners } from './HealthPractitioners'
import { NoAddressesMessage } from './NoAddressesMessage'
import { FormTextInput } from '../../../components/forms'
import { isValid } from 'date-fns'

const AWAITING = 'awaiting'
const READY = 'ready'

const OrderHealthPractitionerTestKitsPage = withAuthentication(() => {
	const maybeKits = Number(JSON.parse(localStorage.getItem('numKits')))
	const maybeAddressIndex = Number(JSON.parse(localStorage.getItem('addressIndex')))

	const [numKits, setNumKits] = useState(maybeKits | 5)
	const [addressIndex, setAddressIndex] = useState(maybeAddressIndex | -1)
	const [deliveryAddresses, setDeliveryAddresses] = useState([])
	const [practitioner, setPractitioner] = useState(null)
	const [addressWithRecipient, setAddressWithRecipient] = useState(null)
	const [step, setStep] = useState(AWAITING)
	const [isSubmittingOrder, setIsSubmittingOrder] = useState(false)
	const { customer } = useAuth()
	const { defaultOrganisation, createProfessionalOrder, submitOrder } = useOrdering()
	const history = useHistory()

	const organisationId = defaultOrganisation()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [step])

	useEffect(() => {
		const maybeAddresses = JSON.parse(localStorage.getItem('deliveryAddresses'))
		const addresses = Array.isArray(maybeAddresses) ? maybeAddresses : []
		setDeliveryAddresses(addresses)
	}, [deliveryAddresses.length, numKits, maybeAddressIndex])

	const handleAddressAndPractitionerChange = ({ address, practitioner, index }) => {
		setPractitioner(practitioner)
		setAddressIndex(index)
		setAddressWithRecipient({
			recipient: `${practitioner.firstName} ${practitioner.lastName}`,
			recipientEmail: practitioner.email,
			organisationName: practitioner.companyOrPracticeName,
			...address,
		})
	}

	const handleAddressAndPractitionerEdit = (deliveryAddresses, addressIndex = -1) => {
		localStorage.setItem('addressIndex', JSON.stringify(addressIndex))

		history.push('/customer/update-delivery-address')
	}

	const handleConfirm = (event) => {
		if (numKits > 0) {
			localStorage.setItem('numKits', JSON.stringify(numKits))
			localStorage.setItem('addressIndex', JSON.stringify(addressIndex))
			setStep(READY)
		}
	}

	const handleSubmitOrder = (event) => {
		event.preventDefault()

		const orderDraft = createProfessionalOrder(numKits, addressWithRecipient)

		const patients = orderDraft.patients.map((patient) => ({
			...patient,
			gender: patient.gender.substring(0, 1).toUpperCase(),
		}))
		const order = {
			...orderDraft,
			organisationId,
			patients,
			createdBy: customer.uid,
		}
		setIsSubmittingOrder(true)
		submitOrder(order)
			.then(() => {
				localStorage.removeItem('numKits')
				localStorage.removeItem('addressIndex')
				history.push('/customer')
			})
			.catch((error) => {
				console.error('submitOrder', 'error:', error)
			})
	}

	const handleBackFromOrderConfirm = (event) => {
		setStep(AWAITING)
	}
	if (step === AWAITING) {
		return (
			<Column size={8}>
				<Spacer spacing="m" />
				<h5>step 1 of 2</h5>
				<h3>Please provide the following details</h3>
				<Column size={2}>
					<Body>Number of test-kits:</Body>
					<TextInput
						datalist={[10, 25, 50, 100, 150, 200]}
						size={2}
						value={numKits}
						setter={(value) => {
							if (Number(value) || value === '') {
								setNumKits(value)
							}
						}}
						rightAligned
						pattern={/[0..9]*$/}
					/>
				</Column>
				<Spacer spacing="m" />

				{deliveryAddresses.length === 0 && <NoAddressesMessage />}
				{deliveryAddresses.length > 0 && (
					<HealthPractitioners
						addresses={deliveryAddresses}
						practitioner={practitioner}
						addressIndex={addressIndex}
						onAddressChange={handleAddressAndPractitionerChange}
						onEnterEditMode={handleAddressAndPractitionerEdit}
					/>
				)}
				<Spacer spacing="s" />
				<Row size={8}>
					<Button fitted secondary href="/customer/new-delivery-address">
						+ Add new address
					</Button>
					<Button
						fitted
						primary
						disabled={practitioner === null || numKits < 1}
						onClick={handleConfirm}
					>
						Use this address
					</Button>
				</Row>
			</Column>
		)
	} else if (step === READY) {
		return (
			<ConfirmOrder
				orderType="professional-kits"
				addressWithRecipient={addressWithRecipient}
				numKits={numKits}
				onConfirmClick={handleSubmitOrder}
				onBackClick={handleBackFromOrderConfirm}
				isSubmittingOrder={isSubmittingOrder}
			/>
		)
	}
})

export { OrderHealthPractitionerTestKitsPage }
