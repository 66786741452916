import React, { useState } from 'react'
import { Column, Body, Spacer } from '../../../components'
import { HealthPractitionerAddress } from './HealthPractitionerAddress'

const HealthPractitioners = ({ addresses, onAddressChange, practitioner, addressIndex = -1, onEnterEditMode }) => {
	const isOnlyOneAddress = addresses.length === 1
	const [selectedIndex, setSelectedIndex] = useState(addressIndex)

	const handleRowClick = (index) => {
		setSelectedIndex(index)
		const { practitioner, address } = addresses[index]
		onAddressChange({ address, practitioner, index })
	}

	const handleEnterEditMode = ({ index }) => {
		const { practitioner, address } = addresses[index]
		onEnterEditMode({ addresses, practitioner }, index)
	}

	return (
		<Column size={8}>
			<Body>Delivery address:</Body>
			<Spacer spacing="s" />

			<Column size={8}>
				{addresses.map(({ practitioner, address }, index) => (
					<HealthPractitionerAddress
						practitioner={practitioner}
						address={address}
						borderedAsList={!isOnlyOneAddress}
						key={index}
						isSelected={index === selectedIndex}
						onClick={() => handleRowClick(index)}
						onEnterEditMode={()=>handleEnterEditMode({index})}
					/>
				))}
			</Column>
		</Column>
	)
}

export { HealthPractitioners }
