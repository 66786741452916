import React, { useState } from 'react'
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import styled from 'styled-components';

const SearchInputStyled = styled.div`
    display: block;

    input {
        padding: 1rem 2rem;
        margin-bottom: 1rem;
        width: 250px;
        font-size: 14px;
        letter-spacing: 0.5px;
    }

    table {
        border-spacing: 0;
		border: 1px solid #cccaca;
        margin: 1.5rem 0;

		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		th, td {
			margin: 0;
			padding: 0.5rem;
			border-bottom: 1px solid #cccaca;
			border-right: 1px solid #cccaca;
			font-size: 14px;
    		letter-spacing: 0.5px;

			:last-child {
				border-right: 0;
			}
		}

        .table-header {
            background-color: var(--color-blue);
            color: #fff;
        }
        .table-row{
            background: #fff;

            :nth-of-type(even){
                background: rgb(185 180 180 / 10%) !important;
            }
        }
    }

    .pagination {
        padding: 0.5rem;

        button {
            border: 1px solid;
            width: 40px;
            height: 40px;
            font-weight: bold;
            cursor: pointer;
            margin-right: 1rem;
        }

        span {
            height: 40px;
            line-height: 40px;
            font-weight: bold;
            font-size: 14px;
            margin-right: 1rem;
            align-items: center;
        }
    }
`

export default function Table({ columns, data }) {
    const [filterInput, setFilterInput] = useState("");

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setGlobalFilter(value);
        setFilterInput(value);

        setPageSize(Number(10))
    };

    function getPagingText() {
        let pageText = '';
        if (pageOptions.length > 0){
            pageText = `Page ${pageIndex + 1} of ${pageOptions.length}`;
        } else {
            pageText = `Page 0 of 0`
        }
        return pageText;
    }

    return (
        <SearchInputStyled>
            
            <input
                value={filterInput}
                onChange={handleFilterChange}
                placeholder={"Search"}
            />
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr className="table-header" {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th 
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={
                                        column.isSorted ? column.isSortedDesc ? "sort-desc" : "sort-asc" : ""
                                    }
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr className="table-row" {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                {' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</button>
                {' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</button>
                {' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                {' '}
                <span>{getPagingText()}</span>
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>Show {pageSize}</option>
                    ))}
                </select>
            </div>
        </SearchInputStyled>
    );
}