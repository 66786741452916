import React, { useEffect } from 'react'
import ContentDialog from 'components/common/content.dialog/content.dialog'
import { Typography } from '@material-ui/core'

const ValidationErrorDialog = (props) => {
    const { open,
        isValidemail,
        isValiddateOfTest,
        isValidpreferredTime,
        isValidfullName,
        isValidfamilyName,
        isValidgivenName,
        isValidDOB,
        isValidtelephone,
        isValidaddressLine1,
        isValidpostcode,
        isValidcity,
        isValidnationality,
        isValiddocumentType,
        isFitToFly,
        isValiddocumentCountry,
        isValiddocumentNumber,
        isValidcountry,
        isValiddocumentAuthority,
        isValiddocumentExpiration,
        serviceType,
        isValidgender,
        isValidethnicity,
        isValidvaccinationStatus,
        isValidarrivalDate,
        isValidtravelMethod,
        isValidvesselNumber,
        isValiddepartureDate,
        isValiddepartureCountry,
        isValidhomeAddressCountry,
        allValid

    } = props;

    return (
        <div>
            <ContentDialog
                onClose={props.handleClose}
                dialogIsOpen={open}
                title={<div><Typography><b>Please fill all required fields</b></Typography></div>}>
                <div className="p-3">

                    {/* Test Details */}
                    {
                        !isValiddateOfTest ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddateOfTest ? "Date of test is not valid" : null}
                            </div> : null
                    }
                    {
                        (!isValidpreferredTime && serviceType.value !== 'PCR') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidpreferredTime ? "Preferred time is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidpostcode ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidpostcode ? "Post Code is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidaddressLine1 ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidaddressLine1 ? "Test Address Line 1 is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidcity ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidcity ? "City is not valid" : null}
                            </div> : null

                    }

                    {/* Individual Personal Details */}
                    {
                        !isValidgivenName ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidgivenName ? "First Name is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidfamilyName ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidfamilyName ? "Last Name is not valid" : null}
                            </div> : null

                    }
                    {/* {
                        !isValidfullName && (serviceType.value === 'SAME_DAY' || serviceType.value === 'NEXT_DAY') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidfullName ? "Full Name is not valid" : null}
                            </div> : null

                    } */}
                    {
                        (!isValidDOB && serviceType.value === 'PCR_DAY_2') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidDOB ? "Date of Birth is not valid" : null}
                            </div> : null
                    }
                    {
                        (!isValidgender && serviceType.value === 'PCR_DAY_2') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidgender ? "Gender is not valid" : null}
                            </div> : null
                    }
                    {
                        (!isValiddocumentNumber && serviceType.value === 'PCR_DAY_2') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddocumentNumber ? "Document number is not valid" : null}
                            </div> : null

                    }
                    {
                        (!isValidethnicity && serviceType.value === 'PCR_DAY_2') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidethnicity ? "Ethnicity is not valid" : null}
                            </div> : null

                    }
                    {
                        (!isValidvaccinationStatus && serviceType.value === 'PCR_DAY_2') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidvaccinationStatus ? "Vaccination status is not valid" : null}
                            </div> : null

                    }


                    {/*-----------  Additional Details   ---------------*/}


                    {
                        !isValidemail ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidemail ? "Email is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidtelephone ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidtelephone ? "Telephone number is not valid" : null}
                            </div> : null
                    }
                    {
                        (!isValidarrivalDate && serviceType.value === 'PCR_DAY_2') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidarrivalDate ? "Arrival Date is not valid" : null}
                            </div> : null

                    }
                    {
                        (!isValidtravelMethod && serviceType.value === 'PCR_DAY_2') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidtravelMethod ? "Travel Method is not valid" : null}
                            </div> : null

                    }
                    {
                        (!isValidvesselNumber && serviceType.value === 'PCR_DAY_2') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidvesselNumber ? "Flight/Coach/Vessel number is not valid" : null}
                            </div> : null

                    }
                    {
                        (!isValiddepartureDate && serviceType.value === 'PCR_DAY_2') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddepartureDate ? "Departure Date is not valid" : null}
                            </div> : null

                    }
                    {
                        (!isValiddepartureCountry && serviceType.value === 'PCR_DAY_2') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddepartureCountry ? "Departure country is not valid" : null}
                            </div> : null

                    }

                    {
                        (!isValidhomeAddressCountry && serviceType.value === 'PCR_DAY_2') ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidhomeAddressCountry ? "Home country is not valid" : null}
                            </div> : null
                    }

                    {/* {
                        !isValidfullName ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidfullName ? "Full Name is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidfamilyName ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidfamilyName ? "Family Name is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidgivenName ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidgivenName ? "Given Name is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidDOB ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidDOB ? "Date of Birth is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidtelephone ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidtelephone ? "Telephone number is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidgender ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidgender ? "Please select gender" : null}
                            </div> : null

                    }
                    {
                        !isValidaddressLine1 ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidaddressLine1 ? "Home Address Line 1 is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidpostcode ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidpostcode ? "Post Code is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidcountry ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidcountry ? "Country is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidcity ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidcity ? "City is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidnationality ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidnationality ? "Nationality is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValiddocumentType && isFitToFly === "true" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddocumentType && isFitToFly === "true" ? "Document type is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValiddocumentCountry && isFitToFly === "true" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddocumentCountry && isFitToFly === "true" ? "Document Country is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValiddocumentNumber && isFitToFly === "true" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddocumentNumber && isFitToFly === "true" ? "Document Number is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValiddocumentAuthority && isFitToFly === "true" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddocumentAuthority && isFitToFly === "true" ? "Document Authority is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValiddocumentExpiration && isFitToFly === "true" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddocumentExpiration && isFitToFly === "true" ? "Document Expiry is not valid" : null}
                            </div> : null

                    } */}
                </div>
            </ContentDialog>
        </div>
    )
}
export default ValidationErrorDialog