import React from 'react'
import { Column, Row, Body, Spacer, Button, Image } from '../../components'
import groupDesktopImg from './assets/group-desktop.svg'
import groupTabletImg from './assets/group-tablet.svg'
import groupMobileImg from './assets/group-mobile.svg'
import healthPractitionerImg from './assets/health-practitioner.svg'
import suitcaseWalkingImg from './assets/suitcase-walking.svg'
import educationImg from './assets/education.svg'
import careHomesImg from './assets/care-home.svg'

const ForBusinessesPage = () => {
	return (
		<>
			<Column marketing className="for-businesses hero" centered>
				<Column desktopSize={8}>
					<h1 className="huge centered">Limit risk and manage your employees return to work</h1>
					<Body paragraph>
						As your most important asset, it is critical to ensure that you provide a healthy and
						safe workplace for your employees. Actively infected staff may not be exhibiting any
						symptoms of Covid-19 and thus be spreading it to colleagues at work, clients, customers
						and the wider community. By proactively monitoring their health, you can act quickly in
						the case of an exposure and mitigate risk to your staff, your business and your
						reputation.
					</Body>
					<Body paragraph>
						Being confident in their health also allows you to determine who is able to travel for
						critical business needs, who can attend face-to-face meetings and who can be
						re-organised to perform crucial business functions.
					</Body>
				</Column>
				<Spacer spacing="m" />
				<Image
					desktopSrc={groupDesktopImg}
					tabletSrc={groupTabletImg}
					mobileSrc={groupMobileImg}
					className="group-img"
					alt="Illustration of the fact that tested for covid-19 people can work together without fear"
				/>
			</Column>
			<Column marketing desktopSize={6} className="for-businesses" centered>
				<h1>Get powerful data analytics tools at your fingertips</h1>
				<Body paragraph>
					Once completed tests are returned to the lab, all reporting is automated via Beacon’s
					powerful analytical dashboard. It provides end-to-end control of your Covid-19 testing
					plan and is a single portal where all test activity and results are managed.
				</Body>
				<Body paragraph>
					The dashboard provides you with all the data insights required to confidently make
					workforce decisions, keeping your employees and your business secure.
				</Body>
				<br />
			</Column>
			<Row marketing desktopSize={12} className="for-businesses">
				<Column desktopSize={2} tabletSize={0}></Column>
				<Column desktopSize={5} tabletSize={10} centered>
					<Column tabletSize={6} className="tablet">
						<img
							src={healthPractitionerImg}
							className="health-practitioner"
							alt="Tested people can work together without fear"
						/>
					</Column>
					<h1>Total testing flexibility, as you need it</h1>
					<Body paragraph>
						Testing methods and schedules are completely flexible depending on your company’s
						requirements. It can be one-off tests mailed to individual workers at home, regular
						on-site screening conducted by your own in-house clinician, team testing with a trusted
						partner at a specified location or a suitable alternative of your choice. We are happy
						to build a plan to your needs.
					</Body>
				</Column>
				<Column desktopSize={1} tabletSize={0}></Column>
				<Column desktopSize={4} className="desktop">
					<img
						src={healthPractitionerImg}
						className="health-practitioner"
						alt="Tested people can work together without fear"
					/>
				</Column>
			</Row>
			<Column marketing desktopSize={10} tabletSize={12} mobileSize={12} className="for-businesses" centered>
				<div className="marketing-border">
				<h1>Simple, accurate and secure </h1>
				<Body paragraph>
					The testing process is extremely simple: order the kit for your staff and they, or the
					administrator of the test, just mail back the completed test. We take care of everything
					else. Our proprietary platform integrates securely and seamlessly into your workflow to
					conveniently provide you with unparalleled levels of results data.
				</Body>
				<Body paragraph>
					We have automated the data tracking, analysis and reporting processes to remove as much
					opportunity for human error, ensuring you can be confident your results are accurate,
					timely and confidential.
				</Body>
				</div>
			</Column>
			<Column marketing desktopSize={6} className="for-businesses" centered>
				<h1>The right tool for your business</h1>
				<Body paragraph>
					The flexibility and adaptability of Beacon means it is the right tool for any sized
					business across any industry. Some great use cases so far are:
				</Body>
				<Column desktopSize={4}>
					<img
						src={suitcaseWalkingImg}
						className="suitcase-walking"
						alt="Tested people can work together without fear"
					/>
				</Column>

				<h3>Airlines</h3>
				<Body paragraph>
					Our platform can help to detect the virus before the onset of symptoms, thus mitigating
					the risk of transmission to other passengers and staff. In the case of symptomatic
					individuals, it can diagnose the presence of Covid-19 infection to avoid unnecessary
					exclusion or confinement.
				</Body>
			</Column>
			<Row marketing desktopSize={13}>
				<Column desktopSize={1}></Column>
				<Column desktopSize={4} className="for-businesses" centered>
					<Column desktopSize={4}>
						<img
							src={educationImg}
							className="education"
							alt="Tested people can work together without fear"
						/>
					</Column>
					<h3>Educational institutions</h3>
					<Body paragraph>
						Our platform helps mitigate the risk of transmission and reduce anxiety in staff,
						students and loved ones.
					</Body>
				</Column>
				<Column desktopSize={1}></Column>
				<Column desktopSize={4} className="for-businesses" centered>
					<Column desktopSize={4}>
						<img
							src={careHomesImg}
							className="care-home"
							alt="Tested people can work together without fear"
						/>
					</Column>
					<h3>Care homes</h3>
					<Body paragraph>
						Provides proactive assurance for vulnerable individuals and their families to minimise
						risk of possible exposure and infection.
					</Body>
				</Column>
			</Row>
			<Row marketing className="for-businesses alternative" centered>
				<Column desktopSize={6} tabletSize={10} className="for-businesses" centered>
					<h1>Flexible and affordable</h1>
					<Body paragraph>
						Costs are dependent on the solution chosen, which we tailor to your needs. Rest assured,
						we are competitive and flexible on pricing, with discounts on bulk orders and contracts.
						Please complete this form and a member of our team will be in touch with you.
					</Body>
					
					<Button fitted href="/contact-us">
				     	Get in Touch
					</Button>
				</Column>
			</Row>
		</>
	)
}

export { ForBusinessesPage }
