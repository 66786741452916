import clsx from 'clsx'
import React, { useState } from 'react'
import { Row, Column, Body, CheckboxInput, Button, Spacer } from '../../components'
import { useOrdering, useAuth } from '../../utils'
import { Link } from 'react-router-dom'

//SAME_DAY_ORDER_CHANGES
const InvoiceLine = ({ label, children, bold }) => {
	const l = label === '' ? '' : `${label}:`
	return (
		<Row size={6} marginTop={0}>
			<Row size={3} marginTop={0}>
				<Body className={clsx({ bold: bold })}>{l}</Body>
			</Row>
			<Row size={3} marginTop={0}>
				<Body>{children}</Body>
			</Row>
		</Row>
	)
}

const AddressSegment = ({ addressWithRecipient }) => {
	if (addressWithRecipient) {
		const { recipient, addressLine1, addressLine2, city, postcode, country } = addressWithRecipient
		return (
			<Column desktopSize={8}>
				<InvoiceLine label="Pracitioner">{recipient}</InvoiceLine>
				<InvoiceLine label="Address">{addressLine1}</InvoiceLine>
				<InvoiceLine label="">{addressLine2}</InvoiceLine>
				<InvoiceLine label="">
					{city} {postcode}
				</InvoiceLine>
				<InvoiceLine label="">{country}</InvoiceLine>
			</Column>
		)
	}
	return null
}

const ConfirmOrder = ({
	orderType,
	onBackClick,
	onConfirmClick,
	isSubmittingOrder,
	numKits,
	addressWithRecipient,
	execStatusProcessSameDayOrder,
	patients = [],
}) => {
	const { createSelfTestOrder, createProfessionalOrder, createSameDayOrder } = useOrdering()
	console.log('Order type: ', orderType)
	const createOrderDraft =
		orderType === 'self-test' || orderType === 'courier'
			? createSelfTestOrder.bind(null, patients, orderType === 'courier' ? true : false)
			: orderType === 'same-day-test' ? createSameDayOrder.bind(null, patients, orderType === 'courier' ? true : false)
				: createProfessionalOrder.bind(null, numKits)
	const { defaultOrganisation } = useAuth()
	const {
		confirmationDisplayText: {
			subTotalText,
			vatText,
			totalText,
			numberOfKitsText,
			orderTypeText,
			orderDateText,
			estimatedDeliveryDateText,
		},
	} = createOrderDraft()

	const [agreedToTandC, setAgreedToTandC] = useState(false)

	const agreedToAllTerms = agreedToTandC

	const organisationName =
		orderType === 'self-test' || orderType === 'courier' || orderType === 'same-day-test'
			? defaultOrganisation()
			: addressWithRecipient.organisationName

	return (
		<Column desktopSize={8}>
			<Spacer spacing="m" />
			<h5 className="no-space">step 3 of 3</h5>
			<h3 className="no-space">Please confirm your order</h3>
			<Body paragraph>
				You will be emailed an invoice once you have confirmed the order which will need to be
				settled by the agreed payment terms.
			</Body>
			<Column bordered desktopSize={8} style={{ padding: '2rem' }}>
				<Column desktopSize={8}>
					<InvoiceLine label="Company">{organisationName}</InvoiceLine>
					<InvoiceLine label="Order date">{orderDateText}</InvoiceLine>
				</Column>
				<Column desktopSize={8} style={{ paddingTop: '2rem' }}>
					<InvoiceLine label="Order type">{orderTypeText}</InvoiceLine>
					<InvoiceLine label="Number of kits">{numberOfKitsText}</InvoiceLine>
					{/* <InvoiceLine label="Estimated delivery date">{estimatedDeliveryDateText}</InvoiceLine> */}
				</Column>
				{/* <Column desktopSize={8} style={{ paddingTop: '2rem' }}>
					<InvoiceLine label="Price">{subTotalText}</InvoiceLine>
					<InvoiceLine label="VAT">{vatText}</InvoiceLine>
					<InvoiceLine label="Total price" bold>
						<strong>{totalText}</strong>
					</InvoiceLine>
				</Column> */}

				<AddressSegment addressWithRecipient={addressWithRecipient} />
				<Column desktopSize={8} style={{ paddingTop: '2rem' }}>
					<Column desktopSize={6} tabletSize={10}>
						<Body small bold>
							Please read and confirm:
						</Body>
						<Body small>· I have authorisation to place an order for use of this service.</Body>
						<Body small>
							· I have provided complete and accurate information required for the use of this
							service.
						</Body>
						<Body small>
							· I have been given explicit consent by the data subject for the processing of the
							data and the use of this service.
						</Body>
						<Body small>
							· I fully understand my obligations as a data controller. If I am unsure about any
							aspect of the service I will contact Beacon Secure before proceeding.
						</Body>
						<Body small>
							· I have read, understand and agree to the&nbsp;
							<Link to="/terms-and-conditionsterms" style={{ fontSize: '1.2rem' }}>
								terms and conditions
							</Link>
							&nbsp;and&nbsp;
							<Link to="/privacy-policy" style={{ fontSize: '1.2rem' }}>
								privacy policy
							</Link>
							.
						</Body>
						<br />
					</Column>
					<CheckboxInput desktopSize={8} checked={agreedToTandC} setter={setAgreedToTandC}>
						I confirm the above
					</CheckboxInput>
				</Column>
			</Column>
			<Spacer spacing="s" />
			<Row desktopSize={8} style={{ justifyContent: 'space-between' }}>
				<Button secondary onClick={onBackClick}>
					← Back
				</Button>
				<Button
					primary
					style={{ minWidth: '15rem' }}
					loading={isSubmittingOrder || (execStatusProcessSameDayOrder && execStatusProcessSameDayOrder.status === "PENDING")}
					onClick={onConfirmClick}
					disabled={!agreedToAllTerms}
				>
					Confirm order
				</Button>
			</Row>
		</Column>
	)
}
export { ConfirmOrder }
