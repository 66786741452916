import React from 'react'
// import propTypes from 'prop-types'
import { useBeaconForm } from './BeaconForm'
import { inputDefaultPropTypes, inputDefaultPropValues } from './defaults'
import { RadioMenu } from '../inputs';

const FormRadioMenuInput = ({
	name,
  group,
  options,
	value,
  setter,
  required
}) => {
	const { register } = useBeaconForm(name)
	const validator = register({ required })

	return <RadioMenu
    group={group}
    options={options}
    value={value}
    setter={setter}
    validator={validator}
  />
}

FormRadioMenuInput.propTypes = {
	...inputDefaultPropTypes,
	// errorMessage: propTypes.string.isRequired,
}

FormRadioMenuInput.defaultProps = { ...inputDefaultPropValues }

export { FormRadioMenuInput }
