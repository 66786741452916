import React from 'react'
import { Row, Column, Button, Body, Form, Spacer } from '../../components'
import { withAuthentication } from '../../utils'

export const AccountApproval = withAuthentication(() => {
    return (
        <Row>
            <Column desktopSize={2} tabletSize={2} mobileSize={0} spacingTop="m" />
            <Column desktopSize={8} tabletSize={8} mobileSize={12} spacingTop="m" style={{ textAlign: "center", padding: "70px 0px" }}>
                <h1>Your account approval is in progress.</h1>
                <h1>We will let you know shortly.</h1>
                {/* <Body>
                    If there is an account associated with the email address provided, you will receive a
                    reset password email.{' '}
                </Body>
                <Column desktopSize={4} tabletSize={8} mobileSize={12} spacingTop="m">
                    <Button href="/login">Back to log in</Button>
                </Column> */}
            </Column>
            <Column desktopSize={2} tabletSize={2} mobileSize={0} spacingTop="m" />
        </Row>
    )
})
