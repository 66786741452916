/**
 * The global state selectors
 */

import { createSelector } from 'reselect';

const selectApp = (state) => state.app;

const languageSelector = () => createSelector(
   selectApp,
  (selectApp) => selectApp.get('language')
)

const executionsSelector = () => createSelector(
  selectApp,
  (selectApp) =>  selectApp.get('appLoadersStatus')
)

const appPropertiesSelector = () => createSelector(
  selectApp,
  (selectApp) =>  selectApp.get('appProperties')
)

const selectUserConfirmationDetail = () => createSelector(
  selectApp,
  (selectApp) =>  selectApp.get('userConfirmationDetail')
)

const selectOrganisations = () => createSelector(
  selectApp,
  (selectApp) =>  selectApp.get('organisations')
)
const selectConfirmPatientDetailResponse = () => createSelector(
  selectApp,
  (selectApp) =>  selectApp.get('confirmPatientDetailResponse')
)

const appAnimationSelector = () => createSelector(
  selectApp,
  (selectApp) => {
    return selectApp.get('animations')
  })


export {
  languageSelector,
  executionsSelector,
  appPropertiesSelector,
  appAnimationSelector,
  selectUserConfirmationDetail,
  selectConfirmPatientDetailResponse,
  selectOrganisations
};
