//SAME_DAY_ORDER_CHANGES
import React, { createContext, useEffect, useState, useContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import '@firebase/auth'
import '@firebase/firestore'
import '@firebase/functions'
import { createSelfTestOrder, createProfessionalOrder, createSameDayOrder } from './create-order'
import { useHistory } from 'react-router-dom'
import { func } from 'prop-types'

firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	databaseURL: 'https://beacon--staging.firebaseio.com',
})

// At this point this is hardcoded, to make sure that we don't accidentially switch to
// any other region. Once BeaconSecure moves to other EU or Non-EU countries you need to
// extract this to the environment variables. But make sure to add appropriate measures,
// to avoid accidential deployment to different region.
const REGION = process.env.REACT_APP_FIREBASE_REGION || 'europe-west2'
const auth = firebase.auth()
const funs = firebase.app().functions(REGION)
export const db = firebase.firestore()

const defaultValue = {}

const BackendContext = createContext(defaultValue)

const retrieveCurrentCustomer = funs.httpsCallable('retrieveCurrentCustomerCall')
const querySettingsAndConfiguration = funs.httpsCallable('querySettingsAndConfigurationCall')
const updateUserOrganisationId = funs.httpsCallable('updateUserOrganisationIdCall')


const generateCustomerDocument = async (user) => {
	if (!user) {
		return null
	}

	const idTokenResult = await user.getIdTokenResult()

	const {
		isHumanResource,
		isMedicalPractitioner,
		isSuperUser,
		isIndependent,
		isAllowedToManageOrganisation,
		isOrganisationAdmin,
	} = idTokenResult.claims

	const { lastSignInTime } = user.metadata

	const customerRef = await retrieveCurrentCustomer({ uid: user.uid })

	const customer = {
		uid: user.uid,
		email: user.email,
		displayName: user.displayName,
		emailVerified: user.emailVerified,
		lastSignInTime,
		isHumanResource: customerRef.data.permissions.isHumanResource,
		isMedicalPractitioner: customerRef.data.permissions.isMedicalPractitioner,
		isIndependent: customerRef.data.permissions.isIndependent,
		isAllowedToManageOrganisation: customerRef.data.permissions.isAllowedToManageOrganisation,
		isOrganisationAdmin: customerRef.data.permissions.isOrganisationAdmin,
		isSuperUser: customerRef.data.permissions.isSuperUser || false,
		...customerRef.data,
	}

	return customer
}

const retrieveOrganisationSettings = async (organisation) => {
	const settings = await querySettingsAndConfiguration()
	const { maxDeliveryEstimate, minDeliveryEstimate, pricePerKit } = settings.data

	return {
		bulkKitPrice: pricePerKit,
		deliveryEstimates: [minDeliveryEstimate, maxDeliveryEstimate],
	}
}

export const Authorized = 'Authorized'
export const Authorizing = 'Authorizing'
export const NotAuthorized = 'NotAuthorized'

const BackendProvider = (props) => {
	const { children } = props
	const [customer, setCustomer] = useState(null)
	const [kitPrices, setKitPrices] = useState({
		bulkKitPrice: '100.00',
	})
	const [deliveryEstimates, setDeliveryEstimates] = useState([1, 3])
	const [authStatus, setAuthStatus] = useState(Authorizing)
	const [loading, setLoading] = useState(false)
	const history = useHistory()
	const customerUid = customer ? customer.uid : ''

	const signInWithEmailAndPassword = (email, password) => {
		const signInResponse = auth.signInWithEmailAndPassword.apply(auth, [email, password])
		setAuthStatus(Authorizing)

		signInResponse.then((response) => console.log('signInWithEmailAndPassword:then', response))

		return signInResponse
	}

	const setSelectedOrganisation = async (defaultOrganisationId) => {
		console.log('setSelectedOrganisation', defaultOrganisationId);
		if (!loading) {
			setLoading(true)

			await updateUserOrganisationId(defaultOrganisationId)

			auth.onAuthStateChanged((authPayload) => {

				generateCustomerDocument(authPayload)
					.then((customer) => {
						setAuthStatus(Authorizing)
						return customer
					})
					.then((customer) => {

						if (customer) {
							setCustomer(customer)
							return retrieveOrganisationSettings().then(({ bulkKitPrice, deliveryEstimates }) => {
								setKitPrices({ bulkKitPrice })
								setDeliveryEstimates(deliveryEstimates)
								setLoading(false)

								return customer
							})
						} else {
							setAuthStatus(NotAuthorized)
							setCustomer(null)
							setLoading(false)

							return null
						}
					})
					.then((maybeCustomer) => {
						if (maybeCustomer !== null) {
							setAuthStatus(Authorized)
							const { isPasswordChangeRequired, isApproved } = maybeCustomer
							if (isPasswordChangeRequired) {
								history.push('/customer/change-password')
							}
							// if (isApproved === true) {
							// 	if (isPasswordChangeRequired) {
							// 		history.push('/customer/change-password')
							// 	}
							// }
							// else if (isApproved === false) {
							// 	history.push('/customer/account-approval')
							// }
						}
					})
					.catch((error) => {
						console.error(error)
						// auth.signOut().
					})
			})
		}
	}


	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((authPayload) => {
			if (!loading) {
				setLoading(true)
				generateCustomerDocument(authPayload)
					.then((customer) => {
						setAuthStatus(Authorizing)
						return customer
					})
					.then((customer) => {
						if (customer) {
							setCustomer(customer)
							return retrieveOrganisationSettings().then(({ bulkKitPrice, deliveryEstimates }) => {
								setKitPrices({ bulkKitPrice })
								setDeliveryEstimates(deliveryEstimates)
								setLoading(false)

								return customer
							})
						} else {
							setAuthStatus(NotAuthorized)
							setCustomer(null)
							setLoading(false)

							return null
						}
					})
					.then((maybeCustomer) => {
						if (maybeCustomer !== null) {
							setAuthStatus(Authorized)
							const { isPasswordChangeRequired, isApproved } = maybeCustomer

							if (isPasswordChangeRequired) {
								history.push('/customer/change-password')
							}

							// if (isApproved === true) {
							// 	if (isPasswordChangeRequired) {
							// 		history.push('/customer/change-password')
							// 	}
							// }
							// else if (isApproved === false) {
							// 	history.push('/customer/account-approval')
							// }
						}
					})
					.catch((error) => {
						console.error(error)
						// auth.signOut()
					})
			}
		})

		return () => unsubscribe()
	}, [customerUid])

	const value = {
		signInWithEmailAndPassword: signInWithEmailAndPassword,
		sendPasswordResetEmail: auth.sendPasswordResetEmail.bind(auth),
		confirmPasswordReset: auth.confirmPasswordReset.bind(auth),

		registerOrganization: funs.httpsCallable('invokeRegisterOrganizationCall'),
		registerProducer: funs.httpsCallable('invokeRegisterProducerCall'),

		// Functions as a Service (Lambdas)
		// - Metrics
		queryAllKits: funs.httpsCallable('queryAllKitsCall'),
		queryReadyKits: funs.httpsCallable('queryReadyKitsCall'),
		querySameDayReadyKits: funs.httpsCallable('querySameDayReadyKitsCall'),
		querySameDayKits: funs.httpsCallable('querySameDayKitsCall'),
		queryAllSameDayKits: funs.httpsCallable('queryAllSameDayKitsCall'),

		invokeGenerateSameDayKitsCsv: funs.httpsCallable('invokeGenerateSameDayKitsCsvCall'),
		invokeUpdateSameDayKits: funs.httpsCallable('invokeUpdateSameDayKitsCall'),
		queryOrganisationMetrics: funs.httpsCallable('queryOrganisationMetricsCall'),
		// - Auth
		updateCustomerPassword: funs.httpsCallable('updateCustomerPasswordCall'),
		// - Ordering
		retrieveOrganisationMetrics: funs.httpsCallable('retrieveOrganisationMetricsCall'),
		submitOrder: funs.httpsCallable('invokeCreateOrderCall'),
		submitSameDayOrder: funs.httpsCallable('invokeCreateOrderCall'),
		assignTestKit: funs.httpsCallable('invokeAssignTestKitCall'),

		// useSubscription: useSubscription(),

		// useSubscription: useSubscription((taskIds = []), (observer = {})),
		queryOrders: funs.httpsCallable('queryOrdersCall'),
		queryTasks: funs.httpsCallable('queryTasksCall'),
		queryLastestOrder: funs.httpsCallable('queryLastestOrderCall'),
		queryLastestKit: funs.httpsCallable('queryLastestKitCall'),
		retrieveKits: funs.httpsCallable('retrieveKitsCall'),
		retrieveOrganisationsByIds: funs.httpsCallable('retrieveOrganisationsByIdsCall'),
		// - Public Test Kits Results
		invokeRequestTestResultAccess: funs.httpsCallable('invokeRequestTestResultAccessCall'),
		queryTestResultsWithEphemeralAuth: funs.httpsCallable('queryTestResultsWithEphemeralAuthCall'),
		retrieveSameDayKit: funs.httpsCallable('retrieveSameDayKitCall'),

		// - Public Sales and Contacts
		salesRequest: funs.httpsCallable('salesRequestCall'),
		invokeDataErasure: funs.httpsCallable('invokeDataErasureCall'),

		// - Retail Customers
		invokeCreateRetailCustomer: funs.httpsCallable('invokeCreateRetailCustomerCall'),

		// updateCustomerPassword: updateCustomerPassword,
		signOut: auth.signOut.bind(auth),
		customer: customer,
		defaultOrganisation: () => customer && customer.defaultOrganisationId,
		setSelectedOrganisation: setSelectedOrganisation,
		authStatus,
		pricePerKit: kitPrices.bulkKitPrice,
		backendLoading: loading,
		deliveryEstimates,
		createSelfTestOrder: createSelfTestOrder.bind(null, kitPrices.bulkKitPrice, deliveryEstimates),
		createSameDayOrder: createSameDayOrder.bind(null, kitPrices.bulkKitPrice, deliveryEstimates),
		createProfessionalOrder: createProfessionalOrder.bind(
			null,
			kitPrices.bulkKitPrice,
			deliveryEstimates
		),
		createOrganisation: funs.httpsCallable('invokeCreateOrganisationCall'),
		getAllOrgs: funs.httpsCallable('queryOrgsCall'),
		getPendingApprovalOrganisations: funs.httpsCallable('queryPendingApprovalOrganisationsCall'),
		approveOrganisation: funs.httpsCallable('invokeApproveOrganisationCall'),
		queryPendingApprovalCustomers: funs.httpsCallable('queryPendingApprovalCustomersCall'),
		approveCustomer: funs.httpsCallable('invokeApproveCustomerCall'),
		searchCustomer: funs.httpsCallable('searchCustomerCall'),
		invokeInviteProducer: funs.httpsCallable('invokeInviteProducerCall'),
		queryProducerOrgInvites: funs.httpsCallable('queryProducerOrgInvitesCall'),
		invokeAcceptOrganisationInvite: funs.httpsCallable('invokeAcceptOrganisationInviteCall'),

		//Barcode functions
		createBarcode: funs.httpsCallable('createBarcodeCall'),
		listBarcodes: funs.httpsCallable('listBarcodesCall'),
		getBarcodeById: funs.httpsCallable('getBarcodeByIdCall'),
		updateBarcode: funs.httpsCallable('updateBarcodeCall'),
		deleteBarcode: funs.httpsCallable('deleteBarcodeCall'),

		//SBS functions
		getAllSbsEthnicity: funs.httpsCallable('getAllSbsEthnicityCall'),
		getAllSbsTravelMethods: funs.httpsCallable('getAllSbsTravelMethodsCall'),
		getAllSbsVaccinationStatus: funs.httpsCallable('getAllSbsVaccinationStatusCall'),
		getAllSbsCountries: funs.httpsCallable('getAllSbsCountriesCall'),
		getAllSbsOrderTypes: funs.httpsCallable('getAllSbsOrderTypesCall'),

	}

	return <BackendContext.Provider value={value}>{children}</BackendContext.Provider>
}

const useMetrics = () => {
	const context = useContext(BackendContext)

	if (context === defaultValue) {
		throw new Error('useKits must be used within BackendProvider')
	}

	return {
		queryReadyKits: context.queryReadyKits,
		querySameDayReadyKits: context.querySameDayReadyKits,
		querySameDayKits: context.querySameDayKits,
		queryAllSameDayKits: context.queryAllSameDayKits,
		invokeGenerateSameDayKitsCsv: context.invokeGenerateSameDayKitsCsv,
		invokeUpdateSameDayKits: context.invokeUpdateSameDayKits,
		queryOrganisationMetrics: context.queryOrganisationMetrics,
		queryAllKits: context.queryAllKits,
	}
}

const useRetailPublic = () => {
	const context = useContext(BackendContext)

	if (context === defaultValue) {
		throw new Error('useKits must be used within BackendProvider')
	}

	return {
		invokeCreateRetailCustomer: context.invokeCreateRetailCustomer,
	}
}

const useKits = () => {
	const context = useContext(BackendContext)

	if (context === defaultValue) {
		throw new Error('useKits must be used within BackendProvider')
	}

	return {
		invokeRequestTestResultAccess: context.invokeRequestTestResultAccess,
		queryTestResultsWithEphemeralAuth: context.queryTestResultsWithEphemeralAuth,
		retrieveSameDayKit: context.retrieveSameDayKit,
		queryLastestKit: context.queryLastestKit,
	}
}

const useOrdering = () => {
	const context = useContext(BackendContext)

	if (context === defaultValue) {
		throw new Error('useOrdering must be used within BackendProvider')
	}

	return {
		createSelfTestOrder: context.createSelfTestOrder,
		createSameDayOrder: context.createSameDayOrder,
		createProfessionalOrder: context.createProfessionalOrder,
		customerData: context.customer,
		defaultOrganisation: context.defaultOrganisation,

		submitOrder: context.submitOrder,
		submitSameDayOrder: context.submitSameDayOrder,
		assignTestKit: context.assignTestKit,
		queryOrders: context.queryOrders,
		queryTasks: context.queryTasks,
		// useSubscription: useSubscription,
		queryLastestOrder: context.queryLastestOrder,
		retrieveKits: context.retrieveKits,
		retrieveOrganisationsByIds: context.retrieveOrganisationsByIds,
	}
}

// const useSubscription = () => {
// 	const doc = db.collection('couriers')
// 	const observer = doc.onSnapshot(
// 		(docSnapshot) => {
// 			return console.log(`Received doc snapshot: ${docSnapshot}`)
// 			return console.log(
// 				`Received doc snapshot:`,
// 				docSnapshot.docs.map((snapshot) => snapshot.data())
// 			)
// 			// ...
// 		},
// 		(err) => {
// 			console.log(`Encountered error: ${err}`)
// 		}
// 	)
// }

// export const useSubscription = async (taskIds) => {
// 	console.log('task ids -------------------------------------------------- ', taskIds)
// 	console.log('===============================--------- ', taskIds)
// 	if (taskIds.length > 0) {
// 		const querySnapshot = db
// 			.collection('couriers')
// 			// .where('task', 'in', taskIds)
// 			// .orderBy('updated_at', 'desc')
// 			.onSnapshot(
// 				(docSnapshot) => {
// 					console.log(`Received doc snapshot: ${docSnapshot}`)
// 					// ...
// 				},
// 				(err) => {
// 					console.log(`Encountered error: ${err}`)
// 				}
// 			)

// 		// const updatedTaskItems = querySnapshot.docs.map((docSnapshot) => docSnapshot.data())

// 		console.log('updatedTaskItems: ', querySnapshot)

// 		return null
// 	}
// }

const useAdmin = () => {
	const context = useContext(BackendContext)

	if (context === defaultValue) {
		throw new Error('useAdmin must be used within BackendProvider')
	}

	return {
		getAllOrgs: context.getAllOrgs,
		createOrganisation: context.createOrganisation,
		pricePerKit: context.pricePerKit,
		setSelectedOrganisation: context.setSelectedOrganisation,
		backendLoading: context.backendLoading,
		getPendingApprovalOrganisations: context.getPendingApprovalOrganisations,
		queryPendingApprovalCustomers: context.queryPendingApprovalCustomers,
		approveOrganisation: context.approveOrganisation,
		approveCustomer: context.approveCustomer,

		//Barcode
		createBarcode: context.createBarcode,
		listBarcodes: context.listBarcodes,
		getBarcodeById: context.getBarcodeById,
		updateBarcode: context.updateBarcode,
		deleteBarcode: context.deleteBarcode,

		//SBS functions
		getAllSbsEthnicity: funs.httpsCallable('getAllSbsEthnicityCall'),
		getAllSbsTravelMethods: funs.httpsCallable('getAllSbsTravelMethodsCall'),
		getAllSbsVaccinationStatus: funs.httpsCallable('getAllSbsVaccinationStatusCall'),
		getAllSbsCountries: funs.httpsCallable('getAllSbsCountriesCall'),
		getAllSbsOrderTypes: funs.httpsCallable('getAllSbsOrderTypesCall'),

	}
}

const authCache = (context) => ({
	signInWithEmailAndPassword: context.signInWithEmailAndPassword,
	sendPasswordResetEmail: context.sendPasswordResetEmail,
	confirmPasswordReset: context.confirmPasswordReset,
	signOut: context.signOut,
	customer: context.customer,
	authStatus: context.authStatus,

	defaultOrganisation: context.defaultOrganisation,
	updateCustomerPassword: context.updateCustomerPassword,
})

const useAuth = () => {
	const context = useContext(BackendContext)

	if (context === defaultValue) {
		throw new Error('useAuth must be used within BackendProvider')
	}

	return authCache(context)
}

const usePublic = () => {
	const context = useContext(BackendContext)

	if (context === defaultValue) {
		throw new Error('useAuth must be used within BackendProvider')
	}

	return { salesRequest: context.salesRequest, invokeDataErasure: context.invokeDataErasure }
}

const useRegistration = () => {

	const context = useContext(BackendContext)

	if (context === defaultValue) {
		throw new Error('userRegistration must be used within BackendProvider')
	}

	return {
		registerOrganization: context.registerOrganization,
		registerProducer: context.registerProducer,
	}

}

const useCustomer = () => {

	const context = useContext(BackendContext)

	if (context === defaultValue) {
		throw new Error('userRegistration must be used within BackendProvider')
	}

	return {
		searchCustomer: context.searchCustomer,
		queryProducerOrgInvites: context.queryProducerOrgInvites,
		invokeAcceptOrganisationInvite: context.invokeAcceptOrganisationInvite,
	}

}

const useOrganisation = () => {

	const context = useContext(BackendContext)

	if (context === defaultValue) {
		throw new Error('userRegistration must be used within BackendProvider')
	}

	return {
		invokeInviteProducer: context.invokeInviteProducer,
	}
	
}
const useSBS = () => {

	const context = useContext(BackendContext)

	if (context === defaultValue) {
		return 'useSBS must be used within BackendProvider';
	}

	return {
		getAllSbsEthnicity: context.getAllSbsEthnicity,
		getAllSbsTravelMethods: context.getAllSbsTravelMethods,
		getAllSbsVaccinationStatus: context.getAllSbsVaccinationStatus,
		getAllSbsCountries: context.getAllSbsCountries,
		getAllSbsOrderTypes: context.getAllSbsOrderTypes,
	}

}

export {
	BackendProvider,
	useAuth,
	useOrdering,
	useAdmin,
	useRetailPublic,
	useMetrics,
	useKits,
	usePublic,
	useRegistration,
	useCustomer,
	useOrganisation,
	useSBS,
}
