import React from 'react'

import { Column, RadioMenu, Spacer, Body } from '../../../components'

const options = [
	{
		label: 'The test sample taken was not fit for processing',
		value: 'The test sample taken was not fit for processing',
	},
	{
		label: 'The test kit was faulty (broken/missing parts)',
		value: 'The test kit was faulty (broken/missing parts)',
	},
	{ label: 'Other', value: 'Other' },
]

const ChangeTestKitModal = () => {
	return (
		<>
			<Column desktopSize={6} tabletSize={10} centered>
				<Column desktopSize={5} tabletSize={9}>
					<h3>Change test kit</h3>
					<Body>
						Are you sure? The current test kit will be <strong>marked as invalid</strong> but must
						still be mailed back to our lab.
					</Body>
					<Spacer spacing="m" />
					<Body>Please provide a reason:</Body>
					<RadioMenu desktopSize={5} tabletSize={9} options={options} />
				</Column>
			</Column>
		</>
	)
}

export { ChangeTestKitModal }
