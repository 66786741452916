import './site.label.textfield.scss';
import { makeStyles } from '@material-ui/core/styles'
import React from 'react';
import { TextField } from '@material-ui/core';
import { useStyles } from './site.label.textfield.style';
import { Paper, InputAdornment } from '@material-ui/core'
import clsx from 'clsx';
import { IsMobileWidth } from 'components/common/util/util'
import { regex } from '../util/regex';

export default function SiteLabelTextField(props) {
  const { classNames, validationType, minWidth, defaultValue, maxLength, pattern, required, endAdornment, label, autoFocus, error, value, placeholder, signUp, responsiveEndAdornment } = props;
  const mobileWidth = IsMobileWidth()
  const classes = useStyles()
  const customStyle = makeStyles((theme) => ({
    customInput: {
      height: 8,
      minWidth: minWidth,
      textOverflow: "ellipsis"
    }
  }))
  const customClasses = customStyle();

  const onChange = (event) => {
    if (validationType === "NUMERIC") {
      if (regex.wholeNumber.test(event.target.value) || event.target.value === "") {
        props.onChange && props.onChange(event)
      }
    }
    else {
      props.onChange && props.onChange(event)
    }
  }
  return (
    <React.Fragment>
      <div className="w-100" >
        <div>
          {
            label ?
              <div className={`${classes.textColor} pt-2 pb-2 d-flex ${signUp  ? '' : 'font-weight-bold' }`}>
                {
                  required == true ?
                    <div className="text-danger">*</div> : null
                }
                <div>
                  {`${label}`}
                </div>
              </div> : <div></div>
          }
        </div>
        <Paper style={{
          height: '40px'
        }} id="site-label-text-field" elevation={1} className={clsx(classes.paperRoot, "paper-root w-100", mobileWidth && responsiveEndAdornment && "d-flex flex-column")} >
          {
            <div className="d-flex align-items-center w-100" style={props.disabled ? { background: "lightgray", cursor: "not-allowed" } : {}}>
              <div className="flex-grow-1">
                {
                  props.disabled === true ?
                    <div className={clsx('ml-2 d-flex align-items-center pt-1 pb-2', classNames, endAdornment && "justify-content-between")} style={{
                      minHeight: '40px',
                      fontWeight: '200',
                    }}>
                      <div>{value}</div>
                      <div>
                        {endAdornment && !mobileWidth ? (
                          endAdornment
                        ) : responsiveEndAdornment ? null :
                          endAdornment ? endAdornment : null
                        }
                      </div>
                    </div> :
                    <div>
                      <div>
                        <TextField
                          defaultValue={defaultValue}
                          value={value}
                          autoFocus={autoFocus}
                          placeholder={placeholder}
                          onKeyPress={props?.onKeyDown ? props?.onKeyDown : null}
                          label={null}
                          disabled={props.disabled ? props.disabled : null}
                          variant="outlined"
                          inputProps={{
                            maxLength: maxLength,
                            pattern: pattern
                          }}
                          onBlur={props.onBlur && props.onBlur}
                          onChange={onChange}
                          InputProps={{
                            classes: {
                              //inputAdornedStart : classes.startAdn,
                              inputAdornedEnd: classes.endAdn,
                              //inputAdornedStart: classes.paddingLeft,
                              notchedOutline: classes.noBorder,
                              input: minWidth ? customClasses.customInput : classes.input,
                              disabled: classes.disabled
                            },
                            startAdornment: (
                              <InputAdornment
                                color="secondary"
                                className="h-100"
                                position="start">
                                {/* {
                              label ?
                                <div className={`${classes.textColor} d-flex font-weight-bold pl-2`}>
                                   {
                                    required == true ?
                                      <div className="text-danger pr-2 pl-2">*</div> :  <div className="text-danger pr-3 pl-2"></div>
                                  }
                                  <div>
                                    {`${label}`}
                                  </div>
                                </div> : <div></div>
                            } */}
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment className="h-100" position="end">
                                {endAdornment && !mobileWidth ? (
                                  endAdornment
                                ) : responsiveEndAdornment ? <div></div> :
                                  endAdornment && endAdornment ? endAdornment : <div></div>
                                }
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                }
                {
                  mobileWidth ?
                    responsiveEndAdornment : null
                }
              </div>
            </div>
          }
        </Paper>
        {
          error ?
            <div className="text-danger p-2">
              {error}
            </div> : null
        }
      </div>
    </React.Fragment>
  );
};
