import React from 'react'
import { Column, Row } from '../../components'
import { Label, Body } from '../../components/typography'

const rh = () => Math.floor(Math.random() * 6).toString()
const columnStyle = () => ({
	backgroundColor: `#${rh()}${rh()}${rh()}${rh()}${rh()}${rh()}`,
	textAlign: 'center',
	color: 'white',
})

const StyleguidePage = () => {
	return (
		<>
			<Column desktopSize={12}>
				<Row desktopSize={12}>
					<Column desktopSize={6}>
						<h1>Heading 1</h1>
						<h2>Heading 2</h2>
						<h3>Heading 3</h3>
						<h4>Heading 4</h4>
						<h5>Heading 5</h5>
					</Column>
					<Column desktopSize={6}>
						<h1 class="no-space">Heading 1 no marging</h1>
						<h2 class="no-space">Heading 2 no marging</h2>
						<h3 class="no-space">Heading 3 no marging</h3>
						<h4 class="no-space">Heading 4 no marging</h4>
						<h5 class="no-space">Heading 5 no marging</h5>
					</Column>
				</Row>
				<Column>
					<Label>Label</Label>
					<Body>Body text</Body>
					<Body paragraph>
						This is a paragraph style, that used as a default text, such as body, explanations,
						descriptions.
					</Body>
					<Body small>Small print, used for boring text</Body>
				</Column>
			</Column>
			<h1>Columns</h1>
			<Row desktopSize={12}>
				<Column style={columnStyle()} tabletSize={12} desktopSize={12}>
					12
				</Column>
			</Row>
			<Row desktopSize={12}>
				<Column style={columnStyle()} tabletSize={10} desktopSize={10}>
					10
				</Column>
				<Column style={columnStyle()} tabletSize={2} desktopSize={2}>
					2
				</Column>
			</Row>
			<Row desktopSize={12}>
				<Column style={columnStyle()} tabletSize={6} desktopSize={6}>
					6
				</Column>
				<Column style={columnStyle()} tabletSize={6} desktopSize={6}>
					6
				</Column>
			</Row>
			<Row desktopSize={12}>
				<Column style={columnStyle()} tabletSize={4} desktopSize={4}>
					4
				</Column>
				<Column style={columnStyle()} tabletSize={4} desktopSize={4}>
					4
				</Column>
				<Column style={columnStyle()} tabletSize={4} desktopSize={4}>
					4
				</Column>
			</Row>
			<Row desktopSize={12}>
				<Column style={columnStyle()} tabletSize={3} desktopSize={3}>
					3
				</Column>
				<Column style={columnStyle()} tabletSize={3} desktopSize={3}>
					3
				</Column>
				<Column style={columnStyle()} tabletSize={3} desktopSize={3}>
					3
				</Column>
				<Column style={columnStyle()} tabletSize={3} desktopSize={3}>
					3
				</Column>
			</Row>
			<h1>Columns with child columns</h1>
			<Row tabletSize={12} desktopSize={12} noWrap>
				<Row style={columnStyle()} tabletSize={6} desktopSize={6}>
					<Column style={columnStyle()} tabletSize={3} desktopSize={3}>
						3
					</Column>
					<Column style={columnStyle()} tabletSize={3} desktopSize={3}>
						3
					</Column>
				</Row>
				<Row style={columnStyle()} tabletSize={3} desktopSize={3}>
					<Column style={columnStyle()} tabletSize={1} desktopSize={1}>
						1
					</Column>
					<Column style={columnStyle()} tabletSize={2} desktopSize={2}>
						2
					</Column>
				</Row>
				<Row style={columnStyle()} tabletSize={3} desktopSize={3}>
					<Column style={columnStyle()} tabletSize={1} desktopSize={1}>
						1
					</Column>
					<Column style={columnStyle()} tabletSize={1} desktopSize={1}>
						1
					</Column>
					<Column style={columnStyle()} tabletSize={1} desktopSize={1}>
						1
					</Column>
				</Row>
			</Row>
			<h1>Bordered Containers</h1>
			<Row tabletSize={12} desktopSize={12}>
				<Column bordered tabletSize={6} desktopSize={6}>
					<h1>This is bordered container</h1>
					<Body paragraph>
						Content in this container is centered, usually content in the container has to be
						specified as -1 size of the parent. For example if Bordered Column is 6 columns wide,
						then it’s children should be 5 columns wide.
					</Body>
				</Column>
				<Column bordered tabletSize={6} desktopSize={6}>
					<h5>This is how 5 columns look inside of the 6 column bordered container.</h5>
					<Row style={columnStyle()} tabletSize={5} desktopSize={5}>
						<Column style={columnStyle()} tabletSize={1} desktopSize={1}>
							1
						</Column>
						<Column style={columnStyle()} tabletSize={1} desktopSize={1}>
							1
						</Column>
						<Column style={columnStyle()} tabletSize={3} desktopSize={3}>
							3
						</Column>
					</Row>
					<h5>This is how 6 columns look inside of the 6 column bordered container.</h5>
					<Row style={columnStyle()} tabletSize={6} desktopSize={6}>
						<Column style={columnStyle()} tabletSize={2} desktopSize={2}>
							2
						</Column>
						<Column style={columnStyle()} tabletSize={2} desktopSize={2}>
							2
						</Column>
						<Column style={columnStyle()} tabletSize={2} desktopSize={2}>
							2
						</Column>
					</Row>
				</Column>
			</Row>
		</>
	)
}

export { StyleguidePage }
