import { Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { format24HrDateTime, formatDate } from '../util/util'
// import '../styles/table.css';

export default function Table(props) {

    const [headers, setHeaders] = useState(props?.headers)

    const [rows, setRows] = useState(props?.rows)
    const [pageNo, setPageNo] = useState(1)
    const [bracket, setBracket] = useState({
        lower: 0,
        heigher: props.maxRecordsPerView
    })
    const [filteredRow, setFilteredRow] = useState(props?.rows)

    const [selectedRow, setSelectedRow] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const nextPage = () => {
        setBracket({
            lower: bracket.heigher,
            heigher: bracket.heigher + props.maxRecordsPerView
        })
        setPageNo(pageNo + 1)
    }

    const PreviousPage = () => {
        setBracket({
            lower: bracket.lower - props.maxRecordsPerView,
            heigher: bracket.lower
        })
        setPageNo(pageNo - 1)
    }

    Array.prototype.sortBy = function (p, type, requirement) {
        if (type === "string") {
            return this.slice(0).sort(function (a, b) {
                const StringA = a[p] ? a[p].toLowerCase() : ""
                const StringB = b[p] ? b[p].toLowerCase() : ""
                return (StringA > StringB) ? 1 : (StringA < StringB) ? -1 : 0;
            });
        }
        else if (type === "number") {
            return this.slice(0).sort(function (a, b) {
                const numberA = isNaN(parseInt(a[p])) ? 0 : parseInt(a[p].toString().replaceAll(":", "").replaceAll("-", ""))
                const numberB = isNaN(parseInt(b[p])) ? 0 : parseInt(b[p].toString().replaceAll(":", "").replaceAll("-", ""))
                return (numberA > numberB) ? 1 : (numberA < numberB) ? -1 : 0;
            });
        }
        else if (type === "date") {
            if (requirement === "formatDate") {
                return this.slice(0).sort(function (a, b) {
                    const dateA = isNaN(new Date(moment(a[p], "DD/MM/YYYY")).getTime()) ? 0 : new Date(moment(a[p], "DD/MM/YYYY")).getTime()
                    const dateB = isNaN(new Date(moment(b[p], "DD/MM/YYYY")).getTime()) ? 0 : new Date(moment(b[p], "DD/MM/YYYY")).getTime()
                    return (dateA > dateB) ? 1 : (dateA < dateB) ? -1 : 0;
                });
            }
            else {
                return this.slice(0).sort(function (a, b) {
                    const dateA = isNaN(new Date(a[p]).getTime()) ? 0 : new Date(a[p]).getTime()
                    const dateB = isNaN(new Date(b[p]).getTime()) ? 0 : new Date(b[p]).getTime()
                    return (dateA > dateB) ? 1 : (dateA < dateB) ? -1 : 0;
                });
            }
        }
    }

    Array.prototype.sortByDec = function (p, type, requirement) {
        if (type === "string") {
            return this.slice(0).sort(function (a, b) {
                const StringA = a[p] ? a[p].toLowerCase() : ""
                const StringB = b[p] ? b[p].toLowerCase() : ""
                return (StringA < StringB) ? 1 : (StringA > StringB) ? -1 : 0;
            });
        }
        else if (type === "number") {
            return this.slice(0).sort(function (a, b) {
                const numberA = isNaN(parseInt(a[p])) ? 0 : parseInt(a[p].toString().replaceAll(":", "").replaceAll("-", ""))
                const numberB = isNaN(parseInt(b[p])) ? 0 : parseInt(b[p].toString().replaceAll(":", "").replaceAll("-", ""))
                return (numberA < numberB) ? 1 : (numberA > numberB) ? -1 : 0;
            });
        }
        else if (type === "date") {
            if (requirement === "formatDate") {
                return this.slice(0).sort(function (a, b) {
                    const dateA = isNaN(new Date(moment(a[p], "DD/MM/YYYY")).getTime()) ? 0 : new Date(moment(a[p], "DD/MM/YYYY")).getTime()
                    const dateB = isNaN(new Date(moment(b[p], "DD/MM/YYYY")).getTime()) ? 0 : new Date(moment(b[p], "DD/MM/YYYY")).getTime()
                    return (dateA < dateB) ? 1 : (dateA > dateB) ? -1 : 0;
                });
            }
            else {
                return this.slice(0).sort(function (a, b) {
                    const dateA = isNaN(new Date(a[p]).getTime()) ? 0 : new Date(a[p]).getTime()
                    const dateB = isNaN(new Date(b[p]).getTime()) ? 0 : new Date(b[p]).getTime()
                    return (dateA < dateB) ? 1 : (dateA > dateB) ? -1 : 0;
                });
            }
        }
    }

    useEffect(() => {
        // console.log(props.rows, "rows")
        const _rows = props.rows
        if (_rows) {
            if (props.showNo || props.enableActions) {
                _rows.forEach((row, index) => {
                    //    if(enableActions){
                    //        _rows[index] = { ..._rows[index],  }
                    //    }
                    //    else{

                    _rows[index] = { no: (index) + pageNo, ..._rows[index] }
                    //    }
                });
                setRows(_rows)
                setFilteredRow(_rows)
            }
            else {
                setRows(_rows)
                setFilteredRow(_rows)
            }
        }

        // console.log(sortedObjs, "hey yeah")
    }, [props.rows, props.showNo, props.pageNo])

    useEffect(() => {
        const headers = props.headers

        if (props.showNo) {
            headers.unshift({ name: "No.", value: "no", type: "number" },)
        }
        if (props.showActions) {
            headers.push({ name: "Actions", value: "actions", type: "actions", disableSorting: true })
        }

    }, [props.headers])

    const sortAssending = (byColumn) => {
        const { value, type, requirement } = byColumn
        if (props.rows) {
            var sortedObjs = []
            sortedObjs = props.rows.sortBy(value, type, requirement)
        }
        setFilteredRow(sortedObjs)
    }

    const sortDessending = (byColumn) => {
        const { value, type, requirement } = byColumn
        if (props.rows) {
            var sortedObjs = []
            sortedObjs = props.rows.sortByDec(value, type, requirement)
        }
        setFilteredRow(sortedObjs)
    }

    return (
        <div id="kit-list" style={{ width: "100%" }}>
            <table style={{ width: "100%" }}>
                <tr className='mb-2'>
                    {
                        headers && headers?.map((header, index) => {
                            return (
                                <th
                                    className='py-5'
                                    style={{ width: `${(props.showNo && index === 0) ? "50px" : `${100 / headers.length}%`}`, textAlign: (props.showActions && header.name === "Actions") ? "center" : props?.textAlign ? props?.textAlign : "space-between" }}
                                    key={index}

                                >
                                    <div className='d-flex justify-content-center'>
                                        <div className='w-75' style={{ cursor: "pointer" }} onClick={() => { setFilteredRow(rows) }}>
                                            {header.name}
                                        </div>
                                        {
                                            !header.disableSorting &&
                                            <div className='w-15 d-flex flex-column justify-content-center '>
                                                <i class="fas fa-chevron-up text-muted" style={{ cursor: "pointer", width: "4px", height: "15px" }}
                                                    onClick={() => { sortAssending(header) }}></i>
                                                <i class="fas fa-chevron-down text-muted" style={{ cursor: "pointer", width: "4px", height: "15px" }} onClick={() => { sortDessending(header) }}></i>
                                            </div>
                                        }

                                    </div>
                                </th>
                            )
                        })
                    }

                </tr>

                {

                    filteredRow && filteredRow.map((row, rowIndex) => {
                        const rowLength = props.showActions ? headers.length - 1 : headers.length
                        return (
                            <>
                                {

                                    < tr className={clsx("w-100  py-4 px-2 border-radius-10", rowIndex % 2 === 0 && "bg-light")} onClick={() => { setSelectedRow(row); setIsModalOpen(true) }}>
                                        {
                                            row && headers.map((entity, entityIndex) => {
                                                if (entityIndex < rowLength) {
                                                    return (
                                                        <td
                                                            className='py-4 px-2'
                                                            style={{ border: "1px solid lightgray", paddingTop: "1.75rem", paddingBottom: "1.75rem", width: `${(props.showNo && entityIndex === 0) ? "50px" : `${100 / headers.length}%`}`, textAlign: props?.textAlign ? props?.textAlign : "left" }}
                                                            key={entityIndex}
                                                        >
                                                            {
                                                                entity.type === "date"
                                                                    ?
                                                                    <>
                                                                        {
                                                                            entity.requirement === "format24HrDateTime"
                                                                                ?
                                                                                format24HrDateTime(row[entity.value])
                                                                                :
                                                                                entity.requirement === "formatDate"
                                                                                    ?
                                                                                    formatDate(moment(row[entity.value], "DD/MM/YYYY"))
                                                                                    :
                                                                                    row[entity.value]
                                                                        }
                                                                    </>
                                                                    :
                                                                    entity.type === "showLinks"
                                                                        ?
                                                                        <>
                                                                            {
                                                                                <div className='w-100 justify-content-start'>
                                                                                    {
                                                                                        row.status !== "CONFIRMATION_LINK_SENT" &&
                                                                                        <div style={{ color: 'black', textAlign: 'start' }}>Form Submitted</div>
                                                                                    }
                                                                                    {
                                                                                        row.status === "CONFIRMATION_LINK_SENT" ?
                                                                                            <div>
                                                                                                <a target="_blank"
                                                                                                    style={{ fontSize: '14px', textAlign: "start", textDecoration: 'underline' }}
                                                                                                    href={`${window.location.origin}/confirmation-form/${row.id}`}>Form
                                                                                                    Link
                                                                                                </a>
                                                                                                <div className='text-primary cursor-pointer text-start'
                                                                                                    onClick={() => props.onCopyLink(`${window.location.origin}/confirmation-form/${row.id}`)}>
                                                                                                    Copy Link
                                                                                                </div>
                                                                                            </div>
                                                                                            : ''
                                                                                    }
                                                                                </div>

                                                                            }
                                                                        </>
                                                                        :
                                                                        row[entity.value]
                                                            }
                                                        </td>
                                                    )

                                                }

                                            })
                                        }
                                        {
                                            props.showActions &&
                                            <td
                                                className='py-4 px-2'
                                                style={{ border: "1px solid lightgray", paddingTop: "1.75rem", paddingBottom: "1.75rem", width: `${100 / headers.length}%`, textAlign: props?.textAlign ? props?.textAlign : "center" }}
                                            >
                                                <div className='d-flex flex-column'>
                                                    <div className='w-100 d-flex align-items-center justify-content-center'>
                                                        <Tooltip title="View more details" placement="top">
                                                            <i onClick={() => {
                                                                let _row = row;
                                                                delete _row["no"]
                                                                props.onView(_row)
                                                            }}
                                                                style={{ fontSize: '18px', color: "rgb(6, 122, 184)" }}
                                                                className="mr-2  text-center cursor-pointer fas fa-info-circle"></i>
                                                        </Tooltip>
                                                        {
                                                            props.showEditFunction === true ?
                                                                <Tooltip title="Update test details" placement="top">
                                                                    <i
                                                                        onClick={() => {
                                                                            let _row = row;
                                                                            delete _row["no"]
                                                                            props.onUpdate(_row)
                                                                        }}
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            color: `rgb(6, 122, 184)`,
                                                                            cursor: `pointer`
                                                                        }} className="fas fa-edit text-center cursor-pointer"></i>
                                                                </Tooltip> : ""
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            row.taskId &&
                                                            <div style={{ width: "150px" }} onClick={() => {
                                                                let _row = row;
                                                                delete _row["no"]
                                                                props.onCourierStatusShow(_row)
                                                            }} className="text-primary cursor-pointer">
                                                                Courier Status</div>
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                        }
                                    </tr>

                                }
                            </>

                        )
                    })
                }

            </table >
        </div>
    )
}
