import React from 'react'
import { Column, LoadingAnimation } from '../../../components'

const KitsLoadingIndicator = (props) => (
	<Column desktopSize={8} tabletSize={12} centered bordered>
		<LoadingAnimation orange />
	</Column>
)

export { KitsLoadingIndicator }
