import { useHistory, useLocation } from 'react-router-dom'

export const AuthPage = () => {
	const history = useHistory()
	const location = useLocation()

	const params = new URLSearchParams(location.search)
	const mode = params.get('mode')

	if (mode === 'resetPassword') {
		const oobCode = params.get('oobCode')
		history.push(`/reset-password?oobCode=${oobCode}`)
	}

	return null
}
