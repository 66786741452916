import React, { useState, useEffect } from 'react'
import { Row, Column, Body } from '../../components'
import { BeaconForm, FormTextInput, SubmitButton, FormTextAreaInput } from '../../components/forms'
import { usePublic } from '../../utils/backend'

const ContactFormSegment = ({ subject }) => {
	console.log('here')
	const { salesRequest } = usePublic()
	const [submitted, setSubmitted] = useState(false)
	const [firstName, setFirstName] = useState()

	const handleSubmit = (data) => {
		if (subject != null) {
			data.subject = subject
		}
		setFirstName(data.firstName)
		setSubmitted(true)
		return salesRequest(data)
	}

	return (
		<BeaconForm onSubmit={handleSubmit} resetAfterSubmit>
			<Column desktopSize={6} tabletSize={8} padding="m">
				{submitted && (
					<Row desktopSize={6} tabletSize={8}>
						<Body error>
							Thank you {firstName}, we have received your enquiry. <br />
						</Body>
					</Row>
				)}
				<Row desktopSize={6} tabletSize={8} padding="s" spaced>
					<FormTextInput
						label="First Name"
						name="firstName"
						desktopSize={3}
						tabletSize={4}
						required
						errorMessage="Please tell us your name"
					/>
					<FormTextInput
						label="Last Name"
						name="lastName"
						desktopSize={3}
						tabletSize={4}
						required
						errorMessage="Please tell us your name"
					/>
				</Row>
				<Row desktopSize={6} tabletSize={8} padding="s" spaced>
					<FormTextInput
						label="Email address (required)"
						name="email"
						type="email"
						desktopSize={3}
						tabletSize={4}
						required
						pattern={/(.*)@(.*)\.([a-zA-Z]{2,20})/}
						errorMessage="Has to be valid email address"
					/>
					<FormTextInput
						label="Phone number"
						name="phoneNumber"
						type="tel"
						desktopSize={3}
						tabletSize={4}
						pattern={/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/}
						errorMessage="Has to be valid phone number"
					/>
				</Row>
				<Row desktopSize={6} tabletSize={8} padding="s">
					<FormTextAreaInput
						name="message"
						label="Message"
						desktopSize={6}
						tabletSize={8}
						required
						errorMessage="Please tell us nature of your request"
					/>
				</Row>
				<Row desktopSize={6} tabletSize={8} padding="xs">
					<Body small>
						Any information shared here will be used solely for the purposes of answering your
						enquiry. By submitting this form, you are agreeing to our terms and conditions as well
						our privacy policy.
					</Body>
				</Row>
				<Row desktopSize={6} padding="xs">
					<SubmitButton fitted>Send Enquiry</SubmitButton>
				</Row>
			</Column>
		</BeaconForm>
	)
}

export { ContactFormSegment }
