import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme) => ({
    selectedBox: {
        border: `1px solid ${theme.palette.secondary.main}`
    },
    buttonIcon: {
        position: 'absolute',
        left: "0px",
        width: 32,
        backgroundColor: theme.palette.secondary.dark,
        display: "flex",
        borderRadius: 100,
        height: "100%",
        top: "0px",
        justifyContent: "center",
        alignItems: "center"
    },
    box: {
        color: '#000000'
    }
}))

//color: "#ffffff !important"
