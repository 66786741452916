//SAME_DAY_ORDER_CHANGES
import React, { useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Row, Column, Button, Spacer, BackButton } from '../../../../components'
import { withAuthentication, useOrdering, useAuth } from '../../../../utils'
import { PatientCsvUploadForm } from './PatientCsvUploadForm'
import { PatientOrderRow } from './PatientOrderRow'
// import { ConfirmOrder } from '../ConfirmOrder'
// import { AppContext } from '../../../app.container'
import { checkExecutionStatus } from 'components/common/util/util';
import moment from 'moment';
import PatientDetailUploadExcel from './patient.detail.upload.excel'

const AWAITING = 'awaiting'
const PROCESSING = 'processing'
const PROCESSED = 'processed'
const ORDERING = 'ordering'

const replacePatient = (patients, updatedPatient) => {
	const nextPatients = [].concat(patients).map((patient) => {
		return patient.pid === updatedPatient.pid ? updatedPatient : patient
	})
	return nextPatients
}

const removePatient = (patients, updatedPatient) => {
	const nextPatients = [].concat(patients).filter((patient) => {
		return patient.pid !== updatedPatient.pid
	})

	return nextPatients
}

const isValidString = (maybeString, regex = /.*/) => {
	return typeof maybeString === 'string' && regex.test(maybeString.toLowerCase())
}

const isPatientValid = (patient) => {
	const {
		fullName,
		email,
		telephone,
		DOB,
		gender,
		documentType,
		documentCounrty,
		documentNumber,
		documentAuthority,
		documentExpiryDate,
		addressLine1,
		addressLine2,
		city,
		country,
		nationality,
		postcode,
		healthNumber,
		givenName,
		familyName,
		preferredTime,
		deliveryDate,
		deliverBy,
		deliveryWindow } = patient
	const result =
		isValidString(fullName, /([a-zA-Z\-\s]+)/) &&
		//isValidString(lastName, /([a-zA-Z\-\s]+)/) &&
		isValidString(email, /(.*@.*\.[a-zA-Z]+)/) &&
		//isValidString(postcode, /([A-Za-z\s]+)/) &&
		isValidString(addressLine1, /([a-zA-Z\-\s]+)/) &&
		isValidString(city, /([a-zA-Z\-\s.]+)/)
	//isValidString(telephone, /([a-zA-Z\-\s.]+)/)
	//isValidString(healthNumber, /([a-zA-Z\-\s.]+)/)

	return result
}

const PlaceOrderSameDayPage = withAuthentication((props) => {
	const { search } = useLocation()
	const params = new URLSearchParams(search)
	const courierParam = params.get('courier')
	const [patientsData, setPatientsData] = useState([])
	const orderType = "same-day-test"; //courierParam != null ? 'courier' : 'self-test'
	const [patientsProcessingStep, setPatientsProcessingStep] = useState(AWAITING)
	const [patientInEditingMode, setPatientInEditingMode] = useState(-1)
	const [isSubmittingOrder, setIsSubmittingOrder] = useState(false)
	const [allPatients, setAllPatients] = useState([])
	const [showJobNumber, setShowJobNumber] = useState(false)
	const [showDelivery, setShowDelivery] = useState(false)
	const [showDriverNotes, setShowDriverNotes] = useState(false)
	const [invalidPatients, setInvalidPatients] = useState([])
	const [displayedPatients, setDisplayedPatients] = useState([])
	const { customer } = useAuth()
	const { defaultOrganisation, submitSameDayOrder, createSameDayOrder } = useOrdering()
	const history = useHistory()
	// const context = useContext(AppContext);
	// const execStatusProcessSameDayOrder = checkExecutionStatus(context, "PROCESS_SAME_DAY_ORDER");

	const isSubmitAllowed = invalidPatients.length === 0
	const organisationId = defaultOrganisation()

	const handlePatientsProcessing = (event) => {
		setPatientsProcessingStep(PROCESSING)
	}

	const handleShowAllPatients = () => {
		setDisplayedPatients(allPatients)
	}

	const handleShowInvalidPatients = () => {
		setDisplayedPatients(invalidPatients)
	}

	const handlePatientsProcessed = (event) => {
		setPatientsData(event.patients)
		setPatientsProcessingStep(PROCESSED)
	}

	const handleConfirmPatients = (event) => {
		setPatientsProcessingStep(ORDERING)
		setPatientInEditingMode(-1)
	}

	const handleConfirmOrder = (event) => {
		event.preventDefault()
		const orderDraft = createSameDayOrder(allPatients, orderType === 'courier' ? true : false)
		const patients = orderDraft.patients.map((patient) => ({
			...patient,
			gender: patient.gender && patient.gender.toUpperCase(),
			email: patient.email && patient.email.toLowerCase(),
			DOB: patient.DOB// && moment(patient.DOB, "DD/MM/YYYY").format('YYYY/MM/DD')
		}))

		const order = {
			...orderDraft,
			organisationId,
			patients,
			createdBy: customer.uid,
			email: customer.email,
			partnerLab: "catalyst"
		}
		order.orderType = orderType
		console.log('submitting order: ', order)

		//setIsSubmittingOrder(true)
		props.processSameDayOrder(order);
		// submitSameDayOrder(order)
		// 	.then((result) => {
		// 		return order.patients.map((patient) => ({
		// 			orderUid: order.orderUid,
		// 			patient,
		// 		}))
		// 	})
		// 	.then((results) => {
		// 		history.push('/customer/order-history')
		// 	})
		// 	.catch((error) => {
		// 		console.error('submitOrder', 'error:', error)
		// 	})
	}

	const handleBackFromOrderConfirm = (event) => {
		setPatientsProcessingStep(PROCESSED)
	}

	if (patientsProcessingStep === AWAITING) {
		return (
			<PatientCsvUploadForm
				onPatientsProcessing={handlePatientsProcessing}
				onPatientsProcessed={handlePatientsProcessed}
			/>
		)
	} else if (patientsProcessingStep === PROCESSING) {
		return <div>Processing</div>
	} else if (patientsProcessingStep === PROCESSED) {
		const showColumns = displayedPatients.map((p) => {
			// console.log('patient info: ', p)
		})
		return (
			<>
				<Column desktopSize={9} className="w-100">
					<Spacer spacing="m" />
					<h5 className="no-space">step 2 of 2</h5>
					<h3 className="no-space">Please confirm the details below</h3>
					<Row desktopSize={9}></Row>

					{/* <Row desktopSize={9} spaced>
						<Row size={7} tabletSize={9}>
							<BackButton />
							<Button primary onClick={handleShowAllPatients}>
								{`${allPatients.length} processed`}
							</Button>

							{!isSubmitAllowed && (
								<Button dangerous onClick={handleShowInvalidPatients}>
									{invalidPatients.length === 1
										? `1 error found`
										: `${invalidPatients.length} errors
								found`}
								</Button>
							)}
						</Row>
					</Row> */}
					<PatientDetailUploadExcel
						{...props}
						patientsData={patientsData}
						onRest={() => {
							setPatientsProcessingStep(AWAITING);
							setPatientsData([])
						}}
						onUpload={() => { }} />
					{/* <Spacer spacing="s" />
					<Row desktopSize={9} spaced>
						<BackButton />
						<Button primary fitted disabled={!isSubmitAllowed} onClick={handleConfirmPatients}>
							Submit Details
						</Button>
					</Row> */}
				</Column>
			</>
		)
	} else if (patientsProcessingStep === ORDERING) {
		return (
			<>
			</>
		)
	}
})

export { PlaceOrderSameDayPage }
