import KitsList from './components/kits.list'

import React, { useContext, useEffect, useState } from "react";
import { withAuthentication } from "../../utils";
import { Breadcrumps } from "../../components";
import DashboardIconBarV2 from 'components/common/dashboard.icon.bar.v2/dashboard.icon.bar.v2'
import { IsMobileWidth } from 'components/common/util/util'
import clsx from 'clsx';
import { CircularProgress } from "@material-ui/core";

const ViewWronglyAssignedKits = withAuthentication((props) => {

    return (
        <div className='w-100 p-5 m-5 d-flex flex-column align-items-start'>
            <div>
                <h3>
                    Wrongly Assigned Kits
                </h3>
            </div>
            <KitsList  {...props} />
        </div>
    )
})

export default ViewWronglyAssignedKits;
