import React, { useEffect } from 'react'
import ContentDialog from 'components/common/content.dialog/content.dialog'
import { CircularProgress, Typography } from '@material-ui/core'
import { Body } from '../../../components'

const CourierStatusDialog = (props) => {
    const { open, selectedKit, execStatusGetCourierStatus, kitsCourierStatus } = props;
    useEffect(() => {
        if (selectedKit && selectedKit.get('taskId')) {
            props.getCourierStatus({
                taskIds: [`https://gsmtasks.com/api/tasks/tasks/${selectedKit.get('taskId')}/`]
            })
        }
    }, [])
    const dateTime = (isoDate) => {
        const date = new Date(isoDate)
        return (
            date.getDate() +
            '/' +
            (date.getMonth() + 1) +
            ' ' +
            (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
            ':' +
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
        )
    }
    return (
        <div>
            <ContentDialog
                onClose={props.handleClose}
                dialogIsOpen={open}
                title={<div><Typography><b>Courier Status</b></Typography></div>}>
                <div className="p-3 pl-5">
                    <div className="w-100 d-flex justify-content-start">
                         {`Full Name: ${selectedKit && selectedKit.get('fullName')}`}
                    </div>
                    <div className="w-100 d-flex justify-content-start">
                       {`Email: ${selectedKit && selectedKit.get('email')}`}
                    </div>
                </div>
                {
                    execStatusGetCourierStatus.status === "PENDING" ?
                        <div className="p-3 pl-5">
                            <div className="w-100 d-flex justify-content-center p-2">
                                <CircularProgress size={30} />
                            </div>
                        </div> : null
                }
                <div className="pl-5 p-3 pb-5">
                    {kitsCourierStatus && kitsCourierStatus.map((task) => {
                        return <div key={task.get('id')}>
                            {/* <Body small>{task.get('notes')}</Body>
                            <Body small>
                                <b>{task.get('event') === 'create' && 'Delivery order created ' + dateTime(task.get('updated_at'))}</b>
                            </Body>
                            <Body small>
                                <b>{task.get('event') === 'assign' && 'Assigned to driver ' + dateTime(task.get('updated_at'))}</b>
                            </Body>
                            <Body small>
                                <b>{task.get('event') === 'unassign' && 'Unassigned from driver ' + dateTime(task.get('updated_at'))}</b>
                            </Body>
                            <Body small>
                                <b> {task.get('event') === 'transit' && '🚚 En route ' + dateTime(task.get('updated_at'))}</b>
                            </Body> */}
                            <Body small>
                                <b>{task.get('event') === 'create' && 'Delivery order created ' + dateTime(task.get('updated_at'))}</b>
                            </Body>
                            <Body small>
                                <div style={{ color: 'green' }}>
                                    <b>
                                        {task.get('event') === 'complete' && '✅ Delivery complete ' + dateTime(task.get('updated_at'))}
                                    </b>
                                </div>
                            </Body>
                            <Body small>
                                <div style={{ color: 'red' }}>
                                    <b>{task.get('event') === 'fail' && '❌ Delivery fail ' + dateTime(task.get('updated_at'))}</b>
                                </div>
                            </Body>
                            <Body small>
                                <div style={{ color: 'red' }}>
                                    <b>
                                        {task.get('event') === 'cancel' && '❌ Delivery cancel ' + dateTime(task.get('updated_at'))}
                                    </b>
                                </div>
                            </Body>
                        </div>
                    })
                    }
                </div>
            </ContentDialog>
        </div>
    )
}
export default CourierStatusDialog