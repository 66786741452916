import React, { useEffect, useState } from 'react'
import { Row, Column, Body, Button, Spacer, CheckboxInput, BackButton } from '../../../components'
import { useModal, useOrdering } from '../../../utils'
import { ChangeTestKitModal } from './ChangeTestKitModal'
import tickIcon from '../../../assets/images/tick-icon.svg'

const DetailsRow = ({ label, value }) => {
	return (
		<Row desktopSize={5} tabletSize={10}>
			<Row desktopSize={2} tabletSize={3}>
				<Body>{label}:</Body>
			</Row>
			<Row desktopSize={3} tabletSize={7}>
				<Body>{value}</Body>
			</Row>
		</Row>
	)
}

const toDdMmYyyy = (date) => {
	if (!date) {
		return ''
	}
	const year = date.substring(0, 4)
	const month = date.substring(4, 6)
	const day = date.substring(6, 8)
	return `${day}/${month}/${year}`
}

const OrderConfirmation = ({
	confirmed,
	testKitBarcode,
	patientDetails,
	organisationId,
	onBackClick,
	onNext,
	onEditPatientDetailsClick,
}) => {
	const [greeting] = confirmed ? ['Thank you'] : ['Confirm details and test completion']
	const {
		firstName,
		lastName,
		dateOfBirth,
		gender,
		dateOfSwab,
		timeOfSwab,
		passportNumber,
	} = patientDetails
	const [isChangeKitModalVisible, setIsChangeKitModalVisible] = useState(false)
	const [testKitCompletedSuccessfuly, setTestKitCompletedSuccessfuly] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [showModal] = useModal(<ChangeTestKitModal />)
	const { assignTestKit } = useOrdering()

	const handleModalClose = () => {
		setIsChangeKitModalVisible(false)
	}

	const performOnNext = () => {
		// onNext()
	}

	const performAssignTestKit = () => {
		setIsLoading(true)

		const {
			firstName,
			lastName,
			gender,
			email,
			dateOfBirth,
			dateOfSwab,
			timeOfSwab,
			addressLine1,
			addressLine2,
			city,
			postcode,
			passportNumber,
		} = patientDetails
		assignTestKit({
			firstName,
			lastName,
			gender,
			email: email.toLowerCase(),
			dateOfBirth,
			dateOfSwab,
			timeOfSwab,
			addressLine1,
			addressLine2,
			city,
			organisationId: organisationId,
			postcode,
			shouldNotifyPatientOfResult: patientDetails.shouldShareResults,
			barcode: testKitBarcode,
			passportNumber,
			testKitType: patientDetails.testKitType,
			courierPrice: patientDetails.courierPrice
		})
			.then((response) => {
				localStorage.removeItem('testKitId')
				setIsLoading(false)
				onNext()
			})
			.catch((error) => {
				setErrorMessage(error && error.message)
				setIsLoading(false)
			})
	}

	const handleOnNextClick = confirmed ? performOnNext : performAssignTestKit

	console.log('TestKit', testKitBarcode, patientDetails, organisationId)
	return (
		<>
			<Row desktopSize={8}>
				<Column desktopSize={6} tabletSize={12}>
					<h3>{greeting}</h3>
					{confirmed ? (
						<>
							<Body>The test kit details have now been successfully logged.</Body>
							<Spacer spacing="sm" />
							<Body>Please return the test kit to our partner laboratory as soon as possible.</Body>
							<Spacer spacing="sm" />
						</>
					) : null}
					<Column bordered desktopSize={6} tabletSize={12}>
						<Spacer spacing="xs" />
						<Row
							desktopSize={5}
							tabletSize={10}
							spaced
							style={{
								width: '100%',
								position: 'relative',
							}}
						>
							<h5 className="no-space">Patient details</h5>
							{confirmed ? (
								<img
									src={tickIcon}
									alt="Completed Icon"
									style={{
										position: 'relative',
										right: '0',
									}}
								/>
							) : (
								<Button tiny chromeless onClick={onEditPatientDetailsClick}>
									Edit
								</Button>
							)}
						</Row>
						<DetailsRow label="Name" value={`${firstName} ${lastName}`} />
						<DetailsRow label="Date of birth" value={toDdMmYyyy(dateOfBirth)} />
						<DetailsRow label="Gender" value={gender} />
						<DetailsRow label="Swab taken date" value={`${toDdMmYyyy(dateOfSwab)} ${timeOfSwab}`} />
						{!!passportNumber && <DetailsRow label="Passport Number" value={passportNumber} />}
					</Column>
					<Spacer spacing="s" />
					<Column bordered desktopSize={6} tabletSize={12}>
						<Spacer spacing="xs" />
						<Row
							desktopSize={5}
							tabletSize={10}
							spaced
							style={{
								width: '100%',
								position: 'relative',
							}}
						>
							<h5 className="no-space">Test Kit</h5>
							{confirmed ? (
								<img
									src={tickIcon}
									alt="Completed Icon"
									style={{
										position: 'relative',
										right: '0',
									}}
								/>
							) : null}
						</Row>
						<DetailsRow label="Test kit ID" value={testKitBarcode} />
					</Column>
					<Spacer spacing="s" />
					{confirmed && (
						<Row contained>
							<Button primary href="/customer">
								Go to Home
							</Button>
						</Row>
					)}

					{!confirmed && (
						<>
							<CheckboxInput
								value={testKitCompletedSuccessfuly}
								setter={setTestKitCompletedSuccessfuly}
							>
								<Body>
									I confirm that the peelable label from the test requisition form has been attached
									to the sample tube.
								</Body>
							</CheckboxInput>
							<Spacer spacing="s" />

							<Row contained>
								<BackButton onClick={onBackClick} />
								<Button
									primary
									disabled={!testKitCompletedSuccessfuly}
									onClick={handleOnNextClick}
									loading={isLoading}
								>
									Complete test
								</Button>
							</Row>
						</>
					)}
				</Column>
			</Row>
			{isChangeKitModalVisible && showModal(handleModalClose)}
		</>
	)
}

export { OrderConfirmation }
