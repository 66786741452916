import React from 'react'
import { VictoryPie } from 'victory'

const PieChart = ({ data, colors }) => {
	if (Array.isArray(data) && data.length > 0) {
		return (
			<div style={{ minWidth: '128px', width: '128px', minHeight: '128px', height: '128px' }}>
				<VictoryPie
					width={148}
					height={148}
					labels={[]}
					innerRadius={40}
					radius={64}
					data={data}
					colorScale={colors}
					animate={{ duration: 1000 }}
				/>
			</div>
		)
	} else {
		return <div style={{ width: '128px', height: '128px', padding: '64px' }} />
	}
}

export { PieChart }
