import React, { useState, useMemo, useReducer } from 'react'
import { Column, Breadcrumps, Row, Spacer, Button } from '../../components'
import { withAuthentication, useModal, useOrdering } from '../../utils'

import { BulkAssignUpload } from './AssignTestKitPage/BulkAssignUpload'
import Table from "./Table";
import ProcessingMessages from './ProcessingMessages';
import XLSX from 'xlsx';

import styled from 'styled-components';

const WrapperStyled = styled.div`
	display: block;
`

function messagesReducer(state, newMessage) {
	return [...state, newMessage];
  }

const BulkAssignTestKitPage = withAuthentication(() => {

	const [patientsData, setPatientsData] = useState([]);
	const [selectedFileName, setSelectedFileName] = useState('');
	const [messagesArray, setMessagesArray] = useReducer(messagesReducer, []);

	const { assignTestKit } = useOrdering()
	
	const bulkAssignFileUploaded = (file) => {
		setSelectedFileName(file.name);
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = (e) => {
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'});
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			const data = XLSX.utils.sheet_to_json(ws, {raw: false});
			setPatientsData(data);
		};
		if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
	}

	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	const validateDateValue = (date) => {
		const date_regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
		return date_regex.test(date);
	}

	const validateTimeValue = (time) => {
		const time_regex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/;
		return time_regex.test(time);
	}

	const validatePatientData = (patientData) => {
		let valid = true;
		// Reuired fields check
		if (
			patientData.testKitId && patientData.organisationId && patientData.firstName && patientData.lastName && patientData.gender && patientData.email && patientData.confirmationEmail &&
			patientData.dateOfBirth && patientData.dateOfSwab && patientData.timeOfSwab
		) {
			// Validate gender values
			if(['M', 'F', 'U'].includes(patientData.gender)) {

				// Validate email values
				if (validateEmail(patientData.email) && validateEmail(patientData.confirmationEmail)) {
					// Email values  match
					if (patientData.email === patientData.confirmationEmail) {
						// Validate DOB
						if (validateDateValue(patientData.dateOfBirth)) {
							// Validate dateOfSwab
							if (validateDateValue(patientData.dateOfSwab)) {
								// Validate time of swab
								if (validateTimeValue(patientData.timeOfSwab)) {
									valid = true;
								} else {
									setMessagesArray({ 
										text: `Test Kit Id: ${patientData.testKitId} - Data for ${patientData.firstName} ${patientData.lastName} has invalid time of swab value. The correct format is HH:mm.`, 
										type: "failure" 
									});
									valid = false;
								}
							} else {
								setMessagesArray({ 
									text: `Test Kit Id: ${patientData.testKitId} - Data for ${patientData.firstName} ${patientData.lastName} has invalid date of swab value. The correct format is DD/MM/YYYY.`, 
									type: "failure" 
								});
								valid = false;
							}

						} else {
							setMessagesArray({ 
								text: `Test Kit Id: ${patientData.testKitId} - Data for ${patientData.firstName} ${patientData.lastName} has invalid date of birth value. The correct format is DD/MM/YYYY.`, 
								type: "failure" 
							});
							valid = false;
						}

					} else {
						setMessagesArray({ 
							text: `Test Kit Id: ${patientData.testKitId} - Email values for patient ${patientData.firstName} ${patientData.lastName} do not match.`, 
							type: "failure" 
						});
						valid = false;
					}
				} else {
					setMessagesArray({ 
						text: `Test Kit Id: ${patientData.testKitId} - Data for patient ${patientData.firstName} ${patientData.lastName} has invalid email value(s).`, 
						type: "failure" 
					});
					valid = false;
				}
			} else {
				setMessagesArray({ 
					text: `Test Kit Id: ${patientData.testKitId} - Data for patient ${patientData.firstName} ${patientData.lastName} has invalid gender value. Valid gender values are: M, F, U.`, 
					type: "failure" 
				});
				valid = false;
			}

		} else {
			setMessagesArray({ 
				text: `Test Kit Id: ${patientData.testKitId} - Data for patient ${patientData.firstName} ${patientData.lastName} is missing one or more mandatory field(s). Mandatory fields are: 
				testKitId, organisationId, firstName, lastName, gender, email, confirmationEmail, dateOfBirth, dateOfSwab, timeOfSwab.`, 
				type: "failure" 
			});
			valid = false;
		}
		return valid;
	}

	const formatDateValueForBackend = (dateValue) => {
		const dateArray = dateValue.trim().split('/');
		return dateArray.length == 3 ? `${dateArray[2]}${dateArray[1]}${dateArray[0]}` : dateValue;
	}

	const formatBooleanValues = (value) => {
		if (typeof value === "string") {
			return value.toLowerCase() === 'true';
		} else {
			return value;
		}
	}

	const handleBulkProcess = () => {
		console.log(patientsData, 'patient data');
		setMessagesArray({ text: `Processing started for document - ${selectedFileName}`, type: "start" });
		for(const patient of patientsData) {
			if (validatePatientData(patient)) {
				const {
					testKitId,
					organisationId,
					firstName,
					lastName,
					gender,
					email,
					dateOfBirth,
					dateOfSwab,
					timeOfSwab,
					addressLine1,
					addressLine2,
					city,
					postcode,
					passportNumber,
				} = patient
				assignTestKit({
					firstName,
					lastName,
					gender,
					email: email.toLowerCase(),
					dateOfBirth: formatDateValueForBackend(dateOfBirth),
					dateOfSwab: formatDateValueForBackend(dateOfSwab),
					timeOfSwab,
					addressLine1,
					addressLine2,
					city,
					organisationId: organisationId,
					postcode,
					shouldNotifyPatientOfResult: formatBooleanValues(patient.shouldShareResults),
					barcode: testKitId,
					passportNumber,
					testKitType: patient.testKitType,
					courierPrice: patient.courierPrice
				})
					.then((response) => {
						setMessagesArray({ text: `Test Kit Id: ${testKitId} - Data for patient ${firstName} ${lastName} has been successfully processed.`, type: "success" });
					})
					.catch((error) => {
						console.error("error", error)
						setMessagesArray({ text: `Test Kit Id: ${testKitId} - We are unable to process data for ${firstName} ${lastName}.`, type: "failure" });
					})
			}
		}
	}

	const patientColumns = useMemo(
		() => [
			{
				Header: "Test Kit ID",
				accessor: "testKitId"
			},
			{
				Header: "Organisation ID",
				accessor: "organisationId"
			},
			{
				Header: "First Name",
				accessor: "firstName"
			},
			{
				Header: "Last Name",
				accessor: "lastName"
			},
			{
				Header: "Email",
				accessor: "email"
			},
			{
				Header: "Confirm Email",
				accessor: "confirmationEmail"
			},
			{
				Header: "Share Results",
				accessor: "shouldShareResults"
			},
			{
				Header: "Date of Birth",
				accessor: "dateOfBirth"
			},
			{
				Header: "Gender",
				accessor: "gender"
			},
			{
				Header: "Date of Swab",
				accessor: "dateOfSwab"
			},
			{
				Header: "Time of Swab",
				accessor: "timeOfSwab"
			},
			{
				Header: "Address Line 1",
				accessor: "addressLine1"
			},
			{
				Header: "Address Line 2",
				accessor: "addressLine2"
			},
			{
				Header: "City",
				accessor: "city"
			},
			{
				Header: "Postal Code",
				accessor: "postcode"
			},
			{
				Header: "Passport Number",
				accessor: "passportNumber"
			},
			{
				Header: "Test Kit Type",
				accessor: "testKitType"
			},
			{
				Header: "Courier Charges",
				accessor: "courierPrice"
			}
		],
		[]
	  );

	return (
		<>
			<Column desktopSize={12} spacingTop="s" style={{width: '80%'}}>
				<Breadcrumps />
				<Row size={8} spacingTop="s">
					<h3>Upload excel file to bulk assign test kits.</h3>
				</Row>
				<Row size={8}>
					<BulkAssignUpload
						onFileSelect={bulkAssignFileUploaded}
						// onFileSelectError={({ error }) => alert(error)}
					/>
				</Row>
				<Spacer spacing="m" />
				{ patientsData.length > 0 &&
					<WrapperStyled>
						{/* <Row size={8}>
							<div>{patientsData.length} Records</div>
						</Row> */}
						<Row size={8}>
							<Table columns={patientColumns} data={patientsData} />
						</Row>
						<Row size={8}>
							<ProcessingMessages messages={messagesArray} totalRecords={patientsData.length} />
						</Row>
						<Row size={4}>
							<Button secondary onClick={handleBulkProcess}>Process</Button>
						</Row>
					</WrapperStyled>
				}
			</Column>
		</>
	)
})

export { BulkAssignTestKitPage }
