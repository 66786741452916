import React from 'react'
import { Column, Row, Body, Spacer, Button, Image } from '../../components'
import airlinesImg from './assets/airlines.svg'
import callOrEmail from './assets/call-or-email.svg'
import delivery from './assets/delivery.svg'
import ownSample from './assets/own-sample.svg'
import results from './assets/results.svg'
import phoneIcon from '../../assets/images/phone-icon.svg'

import './marketing.css'


const ForIndividualPage = () => {
	return (
		<>
			<Column marketing className="for-businesses hero" centered>
				<Column desktopSize={8}>
					<h1 className="huge centered">Ultra-fast Covid-19 Testing and Fit-to-Fly Certificates</h1>
					<Body paragraph>
					Do you want to check if you or a family member currently have Covid-19, or do you need a Fit-to-Fly certificate to travel abroad? Our ultra-fast Covid-19 testing kits can provide test results and a Fit-to-Fly certificate within 24 hours of sample receipt by the lab. Tests are only £120 per kit.
					</Body>
				</Column>
				<Spacer spacing="m" />
				<img
					src={airlinesImg}
					className="health-practitioner"
					alt="Tested people can work together without fear"/>

			</Column>
			<Column marketing desktopSize={10} tabletSize={12} mobileSize={12} className="for-businesses" centered>
				<div className="marketing-border">
				<h1>Testing in four easy steps:</h1>
				<Body paragraph>
					<table>
						<tr >
							<img src={callOrEmail} className="health-practitioner" alt="Order a test kit by calling or emailing us"/>
							<td style={{ paddingLeft: '3.2rem' }}>Order a test kit by calling or emailing us</td>
						</tr>
						<tr><br/>
							<img src={delivery} className="health-practitioner" alt="Tested people can work together without fear"/>
							<td style={{ paddingTop:'15px', paddingLeft: '3.2rem' }}>Test kit is dispatched within 24 hours</td>
						</tr>
						<tr><br/>
							<img src={ownSample} className="health-practitioner" alt="You complete the test and return it to us by courier"/>
							<td style={{ paddingTop:'15px', paddingLeft: '3.2rem' }}>You complete the test and return it to us by courier</td>
						</tr>
						<tr><br/>
							<img src={results} className="health-practitioner" alt="Tested people can work together without fear"/>
							<td style={{ paddingTop:'15px', paddingLeft: '3.2rem' }}>Your results and Fit-to Fly certificate are ready on our website within 24 hours</td>
						</tr>
					
					</table>

				</Body>
	
				</div>
			</Column>
			<Row marketing className="for-hp alternative" centered>
				<Column desktopSize={6} tabletSize={10} className="for-businesses" centered>
					<h1>Order today by:</h1>
					<table><tr><td><img src={phoneIcon} alt="phone icon" /></td><td style={{  paddingLeft: '1.2rem' }}><a href="tel:08000786112" style={{fontSize: '25px', fontWeight: 'bold'}}>
					0800 0786112
					</a></td></tr></table>
					<br/>
					<br/>
					<Button fitted href="contact-us">
					Contact us
					</Button>
				</Column>
			</Row>
		</>
	)
}

export { ForIndividualPage }
