import propTypes from 'prop-types'

export const inputDefaultPropValues = {
	mobileSize: 12,
	tabletSize: 12,
	desktopSize: 12,
	size: 12,
}

export const inputDefaultPropTypes = {
	disabled: propTypes.bool,
	labelAdendum: propTypes.any,
	name: propTypes.string.isRequired,

	mobileSize: propTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	tabletSize: propTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	desktopSize: propTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	size: propTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
}
