import React from 'react';
import PropTypes from 'prop-types'

//TO DO
// - Dynamic sizing by button/icon type
// - Reposition icon CSS
// - Style 


const buildProps = (type) => {
	const props = {};

	if(type === 'btn-inline-left') {
		props['style'] = {
			padding: '0 0.2rem 0 1.2rem',
		};
		props['width']="12px"; 
		props['height']="12px"; 
	}

	if(type === 'pdf-inline-center') {
		props['style'] = {
			padding: '0',
		};
		props['width']="24px"; 
		props['height']="24px"; 
	}

	return props;
};

const Icon = ({ img, type ,alt }) => {
	return (
		<div className="icon-img">
			<img 
				src={img} 
				alt={alt} 
				{...buildProps(type)}
				/>
		</div>
	)
}

Icon.propTypes = {
	img: PropTypes.string.isRequired,
	type: PropTypes.string,
	alt: PropTypes.string.isRequired,
}

export { Icon }
