import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import ContentDialog from '../../../components/common/content.dialog/content.dialog';
import { IsMobileWidth } from "components/common/util/util";
import Calender from 'components/common/custom.calender/Calender';
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
import MaterialAutoComplete from 'components/common/material.autocomplete/material.autocomplete';
import { COUNTRIES_LIST } from 'components/common/util/util';
import MaterialAutoCompleteTypeable from 'components/common/material.autocomplete.typeable/material.autocomplete.typeable';
import { getPastDate } from 'components/common/util/util';
import { SERVICE_TYPES } from 'components/common/util/util';
import ReactTelInput from 'react-telephone-input';
import 'react-telephone-input/css/default.css'
import { Checkbox, FormControlLabel, Snackbar } from "@material-ui/core";
import moment from 'moment';
import { lastWord, PostCodeDropdown, PostCodeHandle } from "../../../components/common/postcode";
import { COUNTRY_CODES, ETHNICITY_LIST, GENDER_LIST, GENDER_LIST_SBS, getCountry, getReferedTimeAccordingToTodaysTime, TRAVEL_METHOD_LIST, VACCINATION_STATUS } from '../../../components/common/util/util';
import { isValidPatientDetail } from '../validator';
import { Alert } from '@material-ui/lab';
import { useAdmin } from '../../../utils';

const UpdatePatientDetailsDialog = (props) => {
    const kit = { ...props?.kit };
    const [state, setState] = useState({
        dateOfTest: kit?.dateOfTest,
        serviceType: {
            value: kit?.serviceType,
            name: kit?.serviceTypeText,
        },
        preferredTime: {
            value: kit?.preferredTime,
            preferredFromTime: kit?.preferredFromTime,
            preferredToTime: kit?.preferredToTime,
        },

        postcode: kit?.postcode,
        addressLine1: kit?.addressLine1,
        addressLine2: kit?.addressLine2,
        city: kit?.city,
        country: getCountry(kit?.country),
        countryText: kit?.countryText,

        familyName: kit?.familyName ? kit?.familyName : "",
        givenName: kit?.givenName ? kit?.givenName : "",
        fullName: kit?.fullName ? kit?.fullName : "",
        documentNumber: kit?.documentNumber ? kit?.documentNumber : "",
        ethnicity: kit?.ethnicity ? {
            value: kit?.ethnicity,
            name: kit?.ethnicityText ? kit?.ethnicityText : "",
        } : "",
        vaccinationStatus: kit?.vaccinationStatus ? {
            value: kit?.vaccinationStatus,
            text: kit?.vaccinationStatusText ? kit?.vaccinationStatusText : ""
        } : "",

        email: kit?.email,
        telephone: kit?.telephone,
        DOB: kit?.DOB,
        gender: { value: kit?.gender },
        arrivalDate: kit?.arrivalDate ? kit?.arrivalDate : "",
        travelMethod: kit?.travelMethod ? {
            value: kit?.travelMethod,
            name: kit?.travelMethodText ? kit?.travelMethodText : ""
        } : "",
        vesselNumber: kit?.vesselNumber ? kit?.vesselNumber : "",
        departureDate: kit?.departureDate ? kit?.departureDate : "",
        departureCountry: kit?.departureCountry ? getCountry(kit?.departureCountry) : "",
        swabTaken: kit?.swabTaken ? kit?.swabTaken : "",

        homeAddressPostcode: kit?.homeAddressPostcode,
        homeAddressLine1: kit?.homeAddressLine1,
        homeAddressLine2: kit?.homeAddressLine2,
        homeAddressCity: kit?.homeAddressCity,
        homeAddressCountry: getCountry(kit?.homeAddressCountry),
        homeAddressCountryText: kit?.homeAddressCountryText,
        producerComments: kit?.producerComments,
    })
    let contryList = COUNTRY_CODES.map((cnt) => {
        return { name: cnt[0], iso2: cnt[1], dialCode: cnt[2], priority: 0, format: cnt[3] }
    })

    const { getAllSbsEthnicity, getAllSbsTravelMethods, getAllSbsVaccinationStatus, getAllSbsCountries } = useAdmin();

    const [ethnicityList, setEthnicityList] = useState(undefined);
    const [travelMethodList, setTravelMethodList] = useState(undefined);
    const [vaccinationList, setVaccinationList] = useState(undefined);
    const [sbsCountriesList, setSbsCountriesList] = useState(undefined);

    const [homeAddressPostCodeAtr, setHomeAddressPostCodeAtr] = useState({
        resValue: "",
        errorValue: [],
        postcodeDropdown: false
    })
    const [testAddressPostCodeAtr, setTestAddressPostCodeAtr] = useState({
        resValue: "",
        errorValue: [],
        postcodeDropdown: false
    })
    const [isHomeAddressSameAsAboveAddress, setIsHomeAddressSameAsAboveAddress] = useState(false)
    const [updateServiceType, setUpdateServiceType] = useState({
        value: kit?.serviceType
    });
    const [updatedPreferedTime, setUpdatedPreferedTime] = useState([]);


    const mobileWidth = IsMobileWidth
    const [showErrors, setShowErrors] = useState(false)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);


    const onChange = (value, name) => {
        setState({ ...state, [name]: value })
    }

    const onSelect = (value, data, name) => {
        setState({ ...state, [name]: data })
    }

    const handlePostCodeInputClick = (event, evnetTargetName) => {

        // console.log(state.postcode)
        if (evnetTargetName === "testAddressPostCode") {
            PostCodeHandle(state.postcode, (item) => {
                setTestAddressPostCodeAtr({
                    ...testAddressPostCodeAtr,
                    postcodeDropdown: item?.dropdown,
                    errorValue: item?.errorValue,
                    resValue: item?.resValue
                })
            });
        }
        else if (evnetTargetName === "homeAddressPostcode") {
            PostCodeHandle(state.homeAddressPostcode, (item) => {
                setHomeAddressPostCodeAtr({
                    ...homeAddressPostCodeAtr,
                    postcodeDropdown: item?.dropdown,
                    errorValue: item?.errorValue,
                    resValue: item?.resValue
                })
            });
        }
    }

    const addressDetails = (item, evnetTargetName) => {
        if (evnetTargetName === "testAddressPostCode") {
            setState({ ...state, city: lastWord(item?.Place), addressLine1: item?.StreetAddress, addressLine2: item?.addressLine2 })
            setTestAddressPostCodeAtr({
                ...testAddressPostCodeAtr,
                postcodeDropdown: false
            })
        }
        else if (evnetTargetName === "homeAddressPostcode") {
            setState({ ...state, homeAddressCity: lastWord(item?.Place), homeAddressLine1: item?.StreetAddress, homeAddressLine2: item?.addressLine2 })
            setHomeAddressPostCodeAtr({
                ...homeAddressPostCodeAtr,
                postcodeDropdown: false
            })
        }
    }

    useEffect(() => {
        const preferredTime = getReferedTimeAccordingToTodaysTime(new Date(state.dateOfTest), updateServiceType?.serviceTypeText)
        console.log(preferredTime, new Date(state.dateOfTest), "prefered time")
        setUpdatedPreferedTime(preferredTime.preferredTime)

    }, [state.dateOfTest, updateServiceType])

    useEffect(() => {
        if (state.postcode !== state.homeAddressPostcode || state.addressLine1 !== state.homeAddressLine1 || state.addressLine2 != state.homeAddressLine2 || state.city !== state.homeAddressCity || state.country !== state.homeAddressCountry) {
            setIsHomeAddressSameAsAboveAddress(false)
        }
    }, [state.postcode, state.addressLine1, state.addressLine2, state.city, state.country, state.homeAddressPostcode, state.homeAddressLine1, state.homeAddressLine2, state.homeAddressCity, state.homeAddressCountry])

    useEffect(() => {
        if (isHomeAddressSameAsAboveAddress) {
            setState({
                ...state, homeAddressLine1: state.addressLine1, homeAddressLine2: state.addressLine2,
                homeAddressPostcode: state.postcode, homeAddressCity: state.city, homeAddressCountry: state.country, homeAddressCountryText: state.countryText
            })
        }
    }, [isHomeAddressSameAsAboveAddress])

    const updateTest = () => {
        const validationResult = isValidPatientDetail({
            ...state
        })
        if (validationResult.allValid === true) {
            setState({
                ...state,
                ...validationResult
            })
            let newState = {
                ...state,
                dateOfTest: moment(new Date(state.dateOfTest))?.format('DD/MM/YYYY')?.trim(),
                serviceType: state?.serviceType?.value,
                serviceTypeText: state?.serviceType?.name?.trim(),
                country: state?.country?.value,
                countryText: state?.country?.name?.trim(),
                homeAddressCountry: state?.homeAddressCountry?.value,
                homeAddressCountryText: state?.homeAddressCountry?.name?.trim(),
                email: state?.email && state?.email?.toLowerCase()?.trim(),
                DOB: state?.DOB ? moment(new Date(state?.DOB))?.format('DD/MM/YYYY')?.trim() : "",
                telephone: state?.telephone ? state?.telephone?.trim()?.replaceAll(" ", "") : ""
            };

            delete newState['isValiddateOfTest']
            delete newState["isValidpreferredTime"]
            delete newState['isValidpostcode']
            delete newState['isValidaddressLine1']
            delete newState['isValidcity']

            delete newState['isValidfullName']
            delete newState['isValidfamilyName']
            delete newState['isValidgivenName']
            delete newState['isValidDOB']
            delete newState['isValidgender']
            delete newState['isValiddocumentNumber']
            delete newState['isValidethnicity']
            delete newState['isValidvaccinationStatus']

            delete newState['isValidemail']
            delete newState['isValidtelephone']
            delete newState['isValidarrivalDate']
            delete newState['isValidtravelMethod']
            delete newState['isValidvesselNumber']
            delete newState['isValiddepartureDate']
            delete newState['isValiddepartureCountry']

            delete newState['isValiHomeAddressPostcode']
            delete newState['isValidHomeAddressLine1']
            delete newState['isValidHomeAddressCity']
            delete newState['isValidHomeAddressCountry']
            // delete newState['isValidnationality']
            delete newState['isValiddocumentType']
            delete newState['isValiddocumentCountry']
            delete newState['isValiddocumentNumber']
            delete newState['isValiddocumentAuthority']
            delete newState['isValiddocumentExpiration']
            delete newState['allValid']
            delete newState["showValidationErrorDialog"]

            if (state.serviceType.value === "PCR_DAY_2") {
                delete newState['preferredFromTime']
                delete newState['preferredTime']
                delete newState['preferredToTime']
                delete newState['fullName']
                newState = {
                    ...newState,
                    ethnicity: state.ethnicity.value,
                    ethnicityText: state.ethnicity.name,
                    vaccinationStatus: state.vaccinationStatus.value,
                    vaccinationStatusText: state.vaccinationStatus.name,
                    travelMethod: state.travelMethod.value,
                    travelMethodText: state.travelMethod.name,
                    arrivalDate: moment(new Date(state.arrivalDate)).format('DD/MM/YYYY'),
                    departureDate: moment(new Date(state.departureDate)).format('DD/MM/YYYY'),
                    departureCountry: state.departureCountry.value,
                    departureCountryText: state.departureCountry.name
                }
            }
            else if (state.serviceType.value === "NEXT_DAY" || state.serviceType.value === "SAME_DAY") {
                delete newState['documentNumber']
                delete newState['ethnicity']
                delete newState['vaccinationStatus']
                delete newState['arrivalDate']
                delete newState['travelMethod']
                delete newState['vesselNumber']
                delete newState['departureDate']
                delete newState['departureCountry']
                delete newState['swabTaken']
                delete newState['isSelfAddressSameAsAboveTestAddress']
                delete newState['selfIsolationAddressPostcode']
                delete newState['selfIsolationAddressLine1']
                delete newState['selfIsolationAddressLine2']
                delete newState['selfIsolationAddressCity']
                delete newState['selfIsolationAddressCountry']
                newState = {
                    ...newState,
                    preferredTime: state?.preferredTime?.value,
                    preferredFromTime: state?.preferredTime?.preferredFromTime,
                    preferredToTime: state?.preferredTime?.preferredToTime,
                    fullName: (state?.givenName || state?.familyName) && `${state?.givenName?.trim()} ${state?.familyName?.trim()}`,
                    ethnicity: "",
                    ethnicityText: "",
                    vaccinationStatus: "",
                    vaccinationStatusText: "",
                    travelMethod: "",
                    travelMethodText: "",
                    arrivalDate: "",
                    departureDate: "",
                    departureCountry: "",
                    departureCountryText: "",
                }
            }

            props.updateProducerTestKitCall({
                kit: {
                    ...kit,
                    id: kit && kit.id,
                    ...newState,
                    producerUpdateTestDetail: true,
                    gender: state.gender.value.trim(),
                    updatedAt: Date.now()
                }
            })
            setShowSuccessAlert(true)
        }
        else {
            setState({
                ...state,
                ...validationResult,
            })
            setShowErrors(true)
        }
    }


    useEffect(() => {
        getAllSbsEthnicity()
            .then((response) => {
                const updatedEthnicity = [];
                const res = response?.data?.map((item) => {
                    updatedEthnicity.push({ name: item?.name, value: item?.pheCode })
                })
                setEthnicityList(updatedEthnicity)
            })
            .catch((error) => { })

        getAllSbsTravelMethods()
            .then((response) => {
                console.log('Response travel method BE', response);

                const updatedTravelMethod = [];
                const res = response?.data?.map((item) => {
                    updatedTravelMethod.push({ name: item?.name, value: item?.travelMethodId.toString() })
                })
                setTravelMethodList(updatedTravelMethod)
            })
            .catch((error) => { })

        getAllSbsVaccinationStatus()
            .then((response) => {
                console.log('response vacc BE', response);
                const updatedVaccination = [];
                const res = response?.data?.map((item) => {
                    updatedVaccination.push({ name: item?.name, value: item?.vaccinationStatusId.toString() })
                })
                setVaccinationList(updatedVaccination)
            })
            .catch((error) => { })

        getAllSbsCountries()
            .then((response) => {
                const updatedCountries = [];
                const res = response?.data?.map((item) => {
                    updatedCountries.push({ name: item?.name, value: item?.iso3Code })
                })
                setSbsCountriesList(updatedCountries)
            })
            .catch((error) => { })

    }, []);
    return (
        <div>
            {
                !showSuccessAlert ?
                    <ContentDialog
                        onClose={props.handleClose}
                        dialogIsOpen={props.isOpen}
                        open={props.isOpen}
                        showActions={true}
                        // loading={execStatusAddLastMinuteTest.status === "PENDING" ? true : false}
                        successText="Update test"
                        onSuccess={updateTest}
                    //title={<div><Typography><b>Patient Detail</b></Typography></div>}
                    >
                        <div className='p-2'>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <Calender
                                    label="Test Date"
                                    value={moment(state.dateOfTest, "DD/MM/YYYY")}
                                    placeholder="Test Date"
                                    minDate={getPastDate(0)}
                                    required={true}
                                    // isDayRequired
                                    getSelectedDate={(date) => {
                                        console.log(date, "date g")
                                        onChange(date, 'dateOfTest');
                                    }}
                                />
                                {
                                    showErrors && !state?.isValiddateOfTest && <span className='ml-1 mt-1 text-danger font-weight-bold'>Date of test is not valid</span>
                                }
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <MaterialAutoComplete
                                    startAdornment="Service Type"
                                    required={true}
                                    selectedItem={updateServiceType ? updateServiceType : 'Please choose service type'}
                                    onSelect={(value, data) => {
                                        onSelect(value, data, "serviceType")
                                        setUpdateServiceType(data)
                                    }}
                                    placeholder="Select Service Type"
                                    data={SERVICE_TYPES}
                                />
                                {
                                    showErrors && !state?.isValidpreferredTime && <span className='ml-1 mt-1 text-danger font-weight-bold'>Preferred time is not valid</span>
                                }
                            </div>

                            {
                                state.serviceType.value !== "PCR_DAY_2" &&
                                <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                    <MaterialAutoComplete
                                        startAdornment="Preferred Time"
                                        selectedItem={state.preferredTime}
                                        //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                        placeholder="Select Preferred Time"
                                        required={true}
                                        onSelect={(value, data) => onSelect(value, data, "preferredTime")}
                                        data={updatedPreferedTime}
                                    />
                                    {
                                        updatedPreferedTime.length === 0 &&
                                        <span className="mt-1 p-1" style={{ color: "red" }}>There is no available window to select so please change the test date </span>
                                    }
                                </div>
                            }

                            <div className="position-relative">
                                <SiteLabelTextField label="Post Code"
                                    value={state.postcode}
                                    required={true}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { handlePostCodeInputClick(e, "testAddressPostCode") } }}
                                    onChange={(e) => { onChange(e.target.value, "postcode") }}
                                    placeholder="Please enter postcode" />
                                <div className="position-absolute "
                                    onClick={(e) => { handlePostCodeInputClick(e, "testAddressPostCode") }}
                                    style={{ top: '36px', right: '15px', display: 'flex', alignItems: 'center' }}>
                                    {testAddressPostCodeAtr.postcodeDropdown &&
                                        <i onClick={(e) => { e.stopPropagation(); setTestAddressPostCodeAtr({ ...testAddressPostCodeAtr, postcodeDropdown: false }) }}
                                            className="fas fa-times-circle"
                                            style={{ marginRight: '10px', color: 'darkred', fontSize: '14px', cursor: 'pointer' }}
                                        >
                                        </i>

                                    }
                                    <button type="button" className="btn btn-primary">Search</button>
                                </div>

                                {testAddressPostCodeAtr.postcodeDropdown &&
                                    <PostCodeDropdown
                                        absoluteLeft={false}
                                        errorValue={testAddressPostCodeAtr.errorValue}
                                        resValue={testAddressPostCodeAtr.resValue}
                                        addressDetails={(e) => addressDetails(e, "testAddressPostCode")}
                                    />
                                }

                                {
                                    showErrors && !state?.isValidpostcode && <span className='ml-1 mt-1 text-danger font-weight-bold'>Postcode is not valid</span>
                                }
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <SiteLabelTextField
                                    required={true}
                                    label="Test Address Line 1" value={state.addressLine1} onChange={(e) => onChange(e.target.value, "addressLine1")} placeholder="Address Line 1" />
                                {
                                    showErrors && !state?.isValidaddressLine1 && <span className='ml-1 mt-1 text-danger font-weight-bold'>Test address line 1 is not valid</span>
                                }
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <SiteLabelTextField
                                    label="Test Address Line 2" value={state.addressLine2} onChange={(e) => onChange(e.target.value, "addressLine2")} placeholder="Address Line 2" />
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <SiteLabelTextField
                                    required={true}
                                    label="Test City" value={state.city} onChange={(e) => onChange(e.target.value, "city")} placeholder="Test City" />
                                {
                                    showErrors && !state?.isValidcity && <span className='ml-1 mt-1 text-danger font-weight-bold'>Test City is not valid</span>
                                }
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <MaterialAutoCompleteTypeable
                                    startAdornment="Test Country"
                                    required={true}
                                    selectedItem={getCountry(state.country)}
                                    onSelect={(value, data) => onSelect(value, data, "country")}
                                    placeholder="Select Country from a list"
                                    data={COUNTRIES_LIST}
                                />
                            </div>

                            {
                                state.serviceType.value !== "PCR_DAY_2" &&
                                <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                    <SiteLabelTextField
                                        required={true}
                                        label="Full Name"
                                        disabled={true}
                                        value={(state?.givenName || state?.familyName) ? `${state?.givenName} ${state?.familyName}` : state?.fullName} placeholder="Full Name" />
                                </div>
                            }

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <SiteLabelTextField
                                    required={true}
                                    label="First Name"
                                    value={state.givenName} onChange={(e) => onChange(e.target.value, "givenName")} placeholder="First Name" />
                                {
                                    showErrors && !state?.isValidgivenName && <span className='ml-1 mt-1 text-danger font-weight-bold'>First Name is not valid</span>
                                }
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <SiteLabelTextField
                                    required={true}
                                    label="Last Name"
                                    value={state.familyName} onChange={(e) => onChange(e.target.value, "familyName")} placeholder="Last Name" />
                                {
                                    showErrors && !state?.isValidfamilyName && <span className='ml-1 mt-1 text-danger font-weight-bold'>Last Name is not valid</span>
                                }
                            </div>

                            {
                                state.serviceType.value === "PCR_DAY_2" &&
                                <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                    <SiteLabelTextField
                                        label="Document Number"
                                        required={true}
                                        value={state.documentNumber} onChange={(e) => onChange(e.target.value, "documentNumber")} placeholder="Document Number" />
                                    {
                                        showErrors && !state?.isValiddocumentNumber && <span className='ml-1 mt-1 text-danger font-weight-bold'>Document Number is not valid</span>
                                    }
                                </div>
                            }

                            {
                                (state.serviceType.value === "PCR_DAY_2" && ethnicityList !== undefined) &&
                                <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                    <MaterialAutoComplete
                                        required={true}
                                        startAdornment="Ethnicity"
                                        selectedItem={state.ethnicity}
                                        onSelect={(value, data) => onSelect(value, data, "ethnicity")}
                                        placeholder="Select Ethnicity"
                                        data={ethnicityList}
                                    />
                                    {
                                        showErrors && !state?.isValidethnicity && <span className='ml-1 mt-1 text-danger font-weight-bold'>Ethnicity is not valid</span>
                                    }
                                </div>
                            }

                            {
                                (state.serviceType.value === "PCR_DAY_2" && vaccinationList !== undefined) &&
                                <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                    <MaterialAutoComplete
                                        required={true}
                                        startAdornment="Vaccination Status"
                                        selectedItem={state.vaccinationStatus}
                                        onSelect={(value, data) => onSelect(value, data, "vaccinationStatus")}
                                        placeholder="Select Vaccination Status"
                                        data={vaccinationList}
                                    />
                                    {
                                        showErrors && !state?.isValidvaccinationStatus && <span className='ml-1 mt-1 text-danger font-weight-bold'>Vaccination Status is not valid</span>
                                    }
                                </div>
                            }

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <SiteLabelTextField
                                    label="Email"
                                    required={true}
                                    value={state.email} onChange={(e) => onChange(e.target.value, "email")} placeholder="Email" />
                                {
                                    showErrors && !state?.isValidemail && <span className='ml-1 mt-1 text-danger font-weight-bold'>Email is not valid</span>
                                }
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <div className="font-weight-bold pt-2 pb-2">
                                    <span style={{ color: '#DC3545', fontWeight: 'bold' }}>*</span>Telephone
                                </div>
                                <ReactTelInput
                                    defaultCountry="gb"
                                    preferredCountries={['gb', 'us']}
                                    value={state.telephone}
                                    onlyCountries={contryList}
                                    onChange={(telNumber, selectedCountry) => onChange(telNumber, "telephone")}
                                    flagsImagePath={`${process.env.PUBLIC_URL}/flags.png`}
                                />
                                {
                                    showErrors && !state?.isValidtelephone && <span className='ml-1 mt-1 text-danger font-weight-bold'>Telephone number is not valid</span>
                                }
                            </div>

                            <div className="d-flex p-2 font-weight-bold ">
                                <Calender
                                    label="Date of Birth"
                                    value={(state.DOB && state.DOB.toString().toLowerCase() !== "invalid date") ? moment(state.DOB, "DD/MM/YYYY") : ""}
                                    placeholder="Select Date of Birth"
                                    getSelectedDate={(date) => { onChange(date, 'DOB') }}
                                />
                            </div>

                            {/* <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <div className="font-weight-bold pt-2 pb-2">
                                    Gender
                                </div>
                                <MaterialAutoComplete
                                    selectedItem={state.gender}
                                    onSelect={(value, data) => onSelect(value, data, "gender")}
                                    placeholder="Select gender"
                                    data={state.serviceType.value === "PCR_DAY_2" ? GENDER_LIST_SBS : GENDER_LIST}
                                />
                                {
                                    state.serviceType.value !== "PCR_DAY_2" && showErrors && !state?.isValidgender && <span className='ml-1 mt-1 text-danger font-weight-bold'>Gender is not valid</span>
                                }
                            </div> */}

                            {
                                state.serviceType.value === "PCR_DAY_2" &&
                                <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                    <Calender
                                        label="Arrival Date"
                                        required={true}
                                        value={moment(state.arrivalDate, "DD/MM/YYYY")}
                                        placeholder="Select Date of Arrival"
                                        minDate={state.departureDate ? state.departureDate : getPastDate(0)}
                                        getSelectedDate={(date) => { onChange(date, "arrivalDate") }}
                                    />
                                    {
                                        showErrors && !state?.isValidarrivalDate && <span className='ml-1 mt-1 text-danger font-weight-bold'>Arrival Date is not valid</span>
                                    }
                                </div>
                            }
                            {
                                (state.serviceType.value === "PCR_DAY_2" && travelMethodList !== undefined) &&
                                <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                    <MaterialAutoComplete
                                        required={true}
                                        startAdornment="Travel Method"
                                        selectedItem={state.travelMethod}
                                        onSelect={(value, data) => onSelect(value, data, "travelMethod")}
                                        placeholder="Select Travel Method"
                                        data={travelMethodList}
                                    />
                                    {
                                        showErrors && !state?.isValidtravelMethod && <span className='ml-1 mt-1 text-danger font-weight-bold'>Travel Method is not valid</span>
                                    }
                                </div>
                            }
                            {
                                state.serviceType.value === "PCR_DAY_2" &&
                                <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                    <SiteLabelTextField
                                        label="Flight/Coach/Vessel Number"
                                        required={true}
                                        value={state.vesselNumber} onChange={(e) => onChange(e.target.value, "vesselNumber")} placeholder="Flight/Coach/Vessel Number" />
                                    {
                                        showErrors && !state?.isValidvesselNumber && <span className='ml-1 mt-1 text-danger font-weight-bold'>Flight/Coach/Vessel Number is not valid</span>
                                    }
                                </div>
                            }
                            {
                                state.serviceType.value === "PCR_DAY_2" &&
                                <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                    <Calender
                                        label="Departure Date "
                                        required={true}
                                        value={moment(state.departureDate, "DD/MM/YYYY")}
                                        placeholder="Select Date of Departure"
                                        maxDate={state.arrivalDate}
                                        getSelectedDate={(date) => { onChange(date, "departureDate") }}
                                    />
                                    {
                                        showErrors && !state?.isValiddepartureDate && <span className='ml-1 mt-1 text-danger font-weight-bold'>Departure Date is not valid</span>
                                    }
                                </div>
                            }
                            {
                                (state.serviceType.value === "PCR_DAY_2" && sbsCountriesList !== undefined) &&
                                <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                    <MaterialAutoCompleteTypeable
                                        startAdornment="Departure Country "
                                        required={true}
                                        selectedItem={getCountry(state.departureCountry)}
                                        onSelect={(value, data) => onSelect(value, data, "departureCountry")}
                                        placeholder="Select Country from a list"
                                        data={sbsCountriesList}
                                    />
                                    {
                                        showErrors && !state?.isValiddepartureCountry && <span className='ml-1 mt-1 text-danger font-weight-bold'>Departure Country is not valid</span>
                                    }
                                </div>
                            }

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <FormControlLabel
                                    className="checkbox-size mb-0"
                                    control={
                                        <Checkbox
                                            size='medium'
                                            checked={isHomeAddressSameAsAboveAddress}
                                            onChange={(e) => setIsHomeAddressSameAsAboveAddress(!isHomeAddressSameAsAboveAddress)}
                                            name="isHomeAddressSameAsAboveAddress"
                                            color="secondary"
                                            inputProps={{ 'aria-label': 'primary checkbox' }} />
                                    }
                                    label="Home address same as test address"
                                />
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column position-relative", mobileWidth && "w-100 position-relative")}>
                                <SiteLabelTextField label="Home Post Code"
                                    value={state.homeAddressPostcode}
                                    required={false}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { handlePostCodeInputClick(e, "homeAddressPostcode") } }}
                                    onChange={(e) => {
                                        onChange(e.target.value, 'homeAddressPostcode')
                                    }}
                                    placeholder="Please enter postcode" />
                                <div className="position-absolute "
                                    onClick={(e) => { handlePostCodeInputClick(e, "homeAddressPostcode") }}
                                    style={{ top: '36px', right: '15px', display: 'flex', alignItems: 'center' }}>
                                    {homeAddressPostCodeAtr.postcodeDropdown &&
                                        <i onClick={(e) => { e.stopPropagation(); setHomeAddressPostCodeAtr({ ...homeAddressPostCodeAtr, postcodeDropdown: false }); }}
                                            className="fas fa-times-circle"
                                            style={{ marginRight: '10px', color: 'darkred', fontSize: '14px', cursor: 'pointer' }}
                                        >
                                        </i>

                                    }
                                    <button type="button" className="btn btn-primary">Search</button>
                                </div>

                                {homeAddressPostCodeAtr.postcodeDropdown &&
                                    <PostCodeDropdown
                                        absoluteLeft={false}
                                        errorValue={homeAddressPostCodeAtr.errorValue}
                                        resValue={homeAddressPostCodeAtr.resValue}
                                        addressDetails={(e) => addressDetails(e, "homeAddressPostcode")}
                                    />
                                }
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <SiteLabelTextField label="Home Address Line 1"
                                    value={state.homeAddressLine1}
                                    onChange={(e) => onChange(e.target.value, "homeAddressLine1")}
                                    placeholder="Home Address Line 1" />
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <SiteLabelTextField label="Home Address Line 2"
                                    value={state.homeAddressLine2}
                                    onChange={(e) => onChange(e.target.value, "homeAddressLine2")}
                                    placeholder="Home Address Line 2" />
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <SiteLabelTextField
                                    // required={true}
                                    label="Home City" value={state.homeAddressCity} onChange={(e) => onChange(e.target.value, "homeAddressCity")} placeholder="Home City" />
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <MaterialAutoCompleteTypeable
                                    startAdornment="Home Country"
                                    // required={true}
                                    selectedItem={getCountry(state.homeAddressCountry)}
                                    onSelect={(value, data) => onSelect(value, data, "homeAddressCountry")}
                                    placeholder="Select Country from a list"
                                    data={state.serviceType.value === "PCR_DAY_2" ? sbsCountriesList : COUNTRIES_LIST}
                                />
                            </div>

                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <SiteLabelTextField
                                    // required={true}
                                    label="Comments" value={state.producerComments} onChange={(e) => onChange(e.target.value, "producerComments")} placeholder="Comments" />
                            </div>
                        </div>
                    </ContentDialog>
                    :
                    <Snackbar
                        style={{ position: "absolute", top: "13vh" }}
                        anchorOrigin={
                            { vertical: 'center', horizontal: 'center' }}
                        open={showSuccessAlert} onClose={props.handleClose} autoHideDuration={8000}>
                        <Alert onClose={props.handleClose} severity="success">
                            Individual details has been updated successfully and sent for approval. Thanks
                        </Alert>
                    </Snackbar>
            }
        </div>
    )
}

export default UpdatePatientDetailsDialog
