import React, { useState, useEffect } from 'react'
import { Column, Body, LoadingAnimation, Button } from '../../components'
import { formatDate, useKits } from '../../utils'
import { Link } from 'react-router-dom'

const TestResults = ({ email, code, cachedTestResults, onTestResults, onTestResultSelect, resetWorkflow }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [testResults, setTestResults] = useState(cachedTestResults)
	const { queryTestResultsWithEphemeralAuth } = useKits()

	useEffect(() => {
		if (email && code) {
			setIsLoading(true)
			console.log('email:', email)
			queryTestResultsWithEphemeralAuth({ email, code })
				.then((response) => {
					const testResults = response.data
					setTestResults(testResults)
					onTestResults({ testResults })
					setIsLoading(false)
				})
				.catch((error) => {
					setIsLoading(false)
					setErrorMessage('Error occured')
					console.error('queryTestResultsWithEphemeralAuth', errorMessage)
				})
		}
	}, [errorMessage, email, code])

	if (isLoading) {
		return (
			<>
				<Column desktopSize={4} tabletSize={6} spacingTop="m" centered>
					<LoadingAnimation orange />
				</Column>
			</>
		)
	}

	if (errorMessage.length > 0) {
		return (
			<>
				<Column desktopSize={4} tabletSize={6} spacingTop="m">
					<h1>Incorrect or expired passcode</h1>
					<Body>
						The passcode provided is either incorrect or expired,&nbsp;
						<Link to="/test-results?shouldStartAgain=true">click here to start over.</Link>
					</Body>
				</Column>
			</>
		)
	}

	return (
		<>
			<Column desktopSize={4} tabletSize={6} spacingTop="m">
				<h1>Your results</h1>
				<Body>
					in partnership with the a UKAS accredited laboratory <strong>ISO:15189:2012</strong>
					&nbsp;medical laboratories standard <br/>
					Please click the date below to view your test result
				</Body>
				<Body bold>Date tested</Body>
				{errorMessage && <Body error>{errorMessage}</Body>}
				{testResults.map((testResult, index) => {
					return (
						<Button
							key={testResult.id}
							secondary
							onClick={() => onTestResultSelect({ id: testResult.id })}
						>
							{formatDate(new Date(testResult.catalyst_resultReportedAt ? testResult.catalyst_resultReportedAt : testResult.testedAt))}
						</Button>
					)
				})}
			</Column>
		</>
	)
}

export { TestResults }
