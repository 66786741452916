// The initial state of the App
import { fromJS } from 'immutable';
import {
  ADD_UPDATE_APP_LOADERS_STATUS, CHANGE_LANGUAGE,
  SET_APP_PROPERTIES, SET_ANIMATION, CONFIRM_PATIENT_DETAIL,
  GET_USER_CONFIRMATION_DETAIL, GET_ALL_ORGANISATIONS
} from 'view.updater/actions/app.actions';
import { getLocalStorageValue } from 'components/common/util/util'
import { getActionName } from './util';

const initialState = fromJS({
  appLoadersStatus: [],
  appProperties: {
    sidebarOpen: true
  },
  animations: {},
  organisations: '',
  userConfirmationDetail: '',
  confirmPatientDetailResponse: false,
  language: getLocalStorageValue("language") ? getLocalStorageValue("language") : "en"
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case '@@INIT':
      return state;
    case SET_APP_PROPERTIES:
      return state.update('appProperties', (appProperties) => {
        return appProperties.merge(action.data)
      })

    case ADD_UPDATE_APP_LOADERS_STATUS:
      return state.update('appLoadersStatus', (loaderStatus) => {
        let index = loaderStatus.findIndex((status) => status.get('name') === action.name);
        if (index > -1) {
          if (action.status === "FINNISH") {
            return loaderStatus.filterNot((status) => status.get('name') === action.name);
          }
          else {
            return loaderStatus.updateIn([index], status => {
              return status.merge({
                status: action.status,
                errorId: action.errorId,
                error: fromJS(action.error)
              })
            })
          }
        }
        else
          return loaderStatus.push(fromJS({
            name: action.name,
            status: action.status
          }))
      })
    case CHANGE_LANGUAGE:
      return state.set("language", action.language)

    case GET_USER_CONFIRMATION_DETAIL:
      return state.set('userConfirmationDetail', '')

    case getActionName(GET_USER_CONFIRMATION_DETAIL):
      return state.set('userConfirmationDetail', fromJS(action.response))

    case getActionName(GET_ALL_ORGANISATIONS):
      return state.set('organisations', fromJS(action.response))

    case getActionName(CONFIRM_PATIENT_DETAIL):
      return state.set('confirmPatientDetailResponse', true)

    case SET_ANIMATION:
      return state.setIn(["animations", action.data.key], action.data.animation)

    default:
      return state;
  }
}
export default appReducer;
