import React from 'react'
import { Row, Body } from '../../components'

export const PositiveCopy = () => {
	return (
		<>
			<h5>What this means</h5>
			<Body>You and your household will need to isolate, even if you have no symptoms.</Body>

			<h5>What you should do now</h5>
			<Body>
				For more information on isolation, go to the NHS covid-19 website. Do not visit a health
				centre such as a hospital, GP surgery or pharmacy. Your employer will receive a copy of your
				result.
			</Body>
		</>
	)
}

export const NegativeCopy = () => {
	return (
		<>
			<h5>What this means</h5>
			<Body>You were not infected with covid-19 when you took the test.</Body>

			<h5>What you should do now</h5>
			<Body>
				If you have no symptoms, you can continue as normal. However, if you have symptoms, follow
				the advice on the NHS covid-19 website. Your employer will also have access to your test
				result.
			</Body>
		</>
	)
}

export const RetestCopy = () => {
	return (
		<>
			<h5>What this means</h5>
			<Body>
				An unclear, void, borderline or inconclusive result means it's not possible to say if you
				had coronavirus when the test was done.
			</Body>

			<h5>What you should do now</h5>
			<Body>
				Speak to HR and get another coronavirus test as soon as possible. If you had a test because
				you had symptoms, you must keep self-isolating and have another test within 5 days of your
				symptoms starting. If you’re not able to have another test in time, you must self-isolate
				for at least 10 days from when your symptoms started. Anyone you live with, and anyone in
				your support bubble, must self-isolate for 14 days.Your employer will also have access to
				your test result.
			</Body>
		</>
	)
}
