import React, { useEffect, useState } from 'react'
import ContentDialog from 'components/common/content.dialog/content.dialog'
import { Button, Typography } from '@material-ui/core'
import LoaderButton from 'components/common/loader.button/loader.button';
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';

const CancellationConfirmationDialog = (props) => {
    const { type, open, selectedKit, execStatusCancelTest } = props;
    useEffect(() => {

    }, [])
    const [state, setState] = useState({
        cancellationComments: ''
    })
    const onChange = (value, name) => {
        setState({ ...state, [name]: value })
    }

    const cancelKit = () => {
        props.handleClose();
        if (selectedKit) {
            props.updateTestKitDataCall({
                kit: {
                    ...selectedKit.toJS(),
                    id: selectedKit && selectedKit.get('id'),
                    status: "CANCELLED",
                    cancellationComments: state.cancellationComments,
                    updatedAt: Date.now()
                }
            })
        }

    }

    const updateKit = () => {
        props.handleClose();
        if (selectedKit) {
            props.updateTestKitDataCall({
                kit: {
                    id: selectedKit && selectedKit.get('id') && selectedKit.get('id')?.trim(),
                    givenName: selectedKit && selectedKit.get('givenName') && selectedKit.get('givenName')?.trim(),
                    familyName: selectedKit && selectedKit.get('familyName') && selectedKit.get('familyName')?.trim(),
                    fullName: selectedKit && `${selectedKit.get('givenName')?.trim()} ${selectedKit.get('familyName')?.trim()}`,
                    email: selectedKit && selectedKit.get('email') && selectedKit.get('email')?.toLowerCase()?.trim(),
                    addressLine1: selectedKit && selectedKit.get('addressLine1') && selectedKit.get('addressLine1')?.trim(),
                    addressLine2: selectedKit && selectedKit.get('addressLine2') && selectedKit.get('addressLine2')?.trim(),
                    city: selectedKit && selectedKit.get('city') && selectedKit.get('city')?.trim(),
                    postcode: selectedKit && selectedKit.get('postcode') && selectedKit.get('postcode')?.trim(),
                    dateOfTest: selectedKit && selectedKit.get('dateOfTest') && selectedKit.get('dateOfTest')?.trim(),
                    producerComments: selectedKit && selectedKit.get('producerComments') && selectedKit.get('producerComments')?.trim(),
                    telephone: selectedKit && selectedKit.get('telephone') && selectedKit.get('telephone')?.trim().replaceAll(" ", ""),
                    courierCost: selectedKit && selectedKit.get('courierCost') && selectedKit.get('courierCost'),
                    partnerLab: selectedKit && selectedKit.get('partnerLab') && selectedKit.get('partnerLab'),
                    updatedAt: Date.now()
                }
            })
        }
    }
    if (type === "cancel") {
        return (
            <div>
                <ContentDialog
                    onClose={props.handleClose}
                    dialogIsOpen={open}
                    title={<div><Typography><b>Are you sure you want to cancel test?</b></Typography></div>}>
                    <div className="p-3 pl-5">
                        <div className="w-100 d-flex justify-content-start">
                            {`Full Name: ${selectedKit && selectedKit.get('fullName')}`}
                        </div>
                        <div className="w-100 d-flex justify-content-start">
                            {`Email: ${selectedKit && selectedKit.get('email')}`}
                        </div>
                        <div className="w-100 d-flex justify-content-start">
                            <SiteLabelTextField label="Comments" value={state.cancellationComments} onChange={(e) => onChange(e.target.value, "cancellationComments")} placeholder="Comments" />
                        </div>
                    </div>
                    <div className="pl-5 pt-3 mt-1 d-flex justify-content-center p-3 pb-5">
                        <LoaderButton
                            classNames="mt-2"
                            loading={execStatusCancelTest.status === "PENDING" ? true : false}
                            onClick={cancelKit} variant="outlined" color="primary" >
                            Cancel Test
                        </LoaderButton>
                    </div>
                </ContentDialog>
            </div>
        )
    }
    else if (type === "update") {
        return (
            <div>
                <ContentDialog
                    onClose={props.handleClose}
                    dialogIsOpen={open}
                    title={<div><Typography><b>Are you sure you want to Update test?</b></Typography></div>}>
                    <div className="p-3 pl-5">
                        <div className="w-100 d-flex justify-content-start">
                            {`Full Name: ${selectedKit && `${selectedKit.get('givenName')} ${selectedKit.get('familyName')}`}`}
                        </div>
                        <div className="w-100 d-flex justify-content-start">
                            {`Email: ${selectedKit && selectedKit.get('email')}`}
                        </div>
                        <div className="w-100 d-flex justify-content-start">
                            {`Test Address Line 1: ${selectedKit && selectedKit.get('addressLine1')}`}
                        </div>
                        {
                            selectedKit.get('addressLine2') &&
                            <div className="w-100 d-flex justify-content-start">
                                {`Test Address Line 2: ${selectedKit && selectedKit.get('addressLine2')}`}
                            </div>
                        }
                        <div className="w-100 d-flex justify-content-start">
                            {`Test City: ${selectedKit && selectedKit.get('city')}`}
                        </div>
                        <div className="w-100 d-flex justify-content-start">
                            {`Post Code: ${selectedKit && selectedKit.get('postcode')}`}
                        </div>
                        <div className="w-100 d-flex justify-content-start">
                            {`Date of Test: ${selectedKit && selectedKit.get('dateOfTest')}`}
                        </div>
                        {
                            selectedKit.get('producerComments') &&
                            <div className="w-100 d-flex justify-content-start">
                                {`Producer Comments: ${selectedKit && selectedKit.get('producerComments')}`}
                            </div>
                        }
                        <div className="w-100 d-flex justify-content-start">
                            {`Telephone No.: ${selectedKit && selectedKit.get('telephone')}`}
                        </div>
                        <div className="w-100 d-flex justify-content-start">
                            {`Courier Cost: ${selectedKit && selectedKit.get('courierCost')}`}
                        </div>

                        <div className="w-100 d-flex justify-content-start">
                            {`Lab: ${selectedKit && selectedKit.get('partnerLab')}`}
                        </div>
                    </div>
                    <div className="pl-5 pt-3 mt-1 d-flex justify-content-center p-3 pb-5">
                        <LoaderButton
                            classNames="mt-2"
                            loading={execStatusCancelTest.status === "PENDING" ? true : false}
                            onClick={updateKit} variant="outlined" color="primary" >
                            Update Test
                        </LoaderButton>
                    </div>
                </ContentDialog>
            </div>
        )
    }
}
export default CancellationConfirmationDialog