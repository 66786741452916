import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { Paper } from '@material-ui/core'
import { formatDate, format24HrDateTime, format24HrDateTimeV2 } from 'components/common/util/util'

export default function CustomCalender(props) {
  const [state, setState] = useState({
    anchorEl: undefined,
    width: undefined,
    dateDialogIsOpen: false,
    dateAnchor: undefined
  })
  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const { classNames, required, defaultValue, views, label, value, displayText, minDate, maxDate, error, showTime } = props; // views can be month , year , date , should be array



  const [defaultSelectedValue, setDefaultSelectedValue] = useState();
  const [selectedDate, setSelectedDate] = useState(value ? (value === "Invalid Date" ? null : value) : null)
  const [isDateValid, setIsDateValid] = useState(false)


  const onAccept = (date) => {
    setSelectedDate(date)
  }

  const onClose = (date) => {
    setState({ ...state, dateAnchor: false })
  }

  const onChange = (date) => {
    setSelectedDate(date)
    console.log(date, "date g date")
    if (date?.toString().toLowerCase() === "invalid date") {
      props.getSelectedDate("Invalid Date")
    }
    else if (date === "" || date === null) {
      props.getSelectedDate("")
    }
  }

  useEffect(() => {
    if (defaultValue) {
      setDefaultSelectedValue(defaultValue)
    }
  }, [])

  useEffect(() => {
    console.log(value, "123")
  }, [value])

  useEffect(() => {
    if (selectedDate && selectedDate.toString() !== "Invalid Date" && isDateValid) {
      let tempDate = selectedDate

      if (minDate && new Date(tempDate.toString()).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
        tempDate = new Date()
      }

      if (minDate && minDate?.toString() !== "Invalid Date" && new Date(tempDate.toString()).setHours(0, 0, 0, 0) >= new Date(minDate.toString()).setHours(0, 0, 0, 0)) {
        props.getSelectedDate(tempDate)
      }
      else if (!minDate) {
        props.getSelectedDate(tempDate)
      }
    }
  }, [isDateValid, selectedDate])


  const openKeyboardDatePicker = Boolean(state.dateAnchor)
  return (
    <React.Fragment>
      <div className="w-100" style={{ minWidth: "200px", position: "relative" }}>

        {label ?
          <div style={{ height: "28px" }}>
            <Typography className=" d-flex pb-2 pt-2 font-weight-bold">
              {
                required == true ?
                  <div className="text-danger">*</div> : null
              }
              <div style={{ fontSize: "small" }}>
                {label}
              </div>
            </Typography>
          </div>
          : null}

        <div className="date-input-container">
          {
            props?.isDayRequired &&
            <span className="day-text">{selectedDate ? days[selectedDate.getDay()] : ""}</span>
          }

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              keyboardIcon={<img src={`${process.env.PUBLIC_URL}/icons/calendar-icon.svg`} />}
              clearable
              views={views}
              autoOk={true}
              placeholder="DD-MM-YYYY"
              margin="normal"
              variant="inline"
              id="date-picker-dialog"
              format="dd-MM-yyyy"
              PopoverProps={{
                // anchorEl: state.dateAnchor,
                // open: openKeyboardDatePicker,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                }
              }}
              defaultValue={""}
              value={selectedDate}
              onChange={onChange}
              onAccept={(date) => onAccept(date)}
              onClose={() => onClose()}
              onError={(error) => {
                if (error) {
                  setIsDateValid(false)
                }
                else {
                  setIsDateValid(true)
                }
              }}
              on
              minDate={minDate}
              maxDate={maxDate}
            // invalidLabel
            // invalidDateMessage=""
            />
          </MuiPickersUtilsProvider>
          {
            error ?
              <div className="text-danger pt-2 pl-1">
                Please select date
              </div> : null
          }
        </div>
      </div>
    </React.Fragment>
  )
}