import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Column, Body, BackButton, PatientDetailsEditor, Spacer } from '../../../components'
import {
	BeaconForm,
	SubmitButton,
	FormCheckboxInput,
	FormRadioMenuInput,
} from '../../../components/forms'
import { toUiPatient, toSbsPatient } from '../../../utils/sbs-utils'
import { FormTextInput } from '../../../components/forms'

const PatientDetailsEntry = ({ onSubmit, value, onBack }) => {
	const [idDocument, setIdDocument] = useState(value.idDocument)

	const idOptions = [
		{ label: 'Passport', value: 'passport' },
		{ label: 'National ID', value: 'national-id' },
		{ label: 'Driving Licence', value: 'driving-licence' },
		{ label: 'Employee ID Card', value: 'employee-id' },
	]

	const handlePatientSubmit = (patientDetails) => {
		onSubmit({ patientDetails, idDocument })
	}
	return (
		<>
			<Row size={8} spacingTop="s">
				<h3>Please capture the following details</h3>
				<Body>
					All fields are required for test kit processing purposes unless labeled optional.
				</Body>
			</Row>
			<Spacer spacing="m" />
			<BeaconForm
				onSubmit={handlePatientSubmit}
				initialValues={value.patientDetails}
				io={{ input: toUiPatient, output: toSbsPatient }}
			>
				<PatientDetailsEditor />
				<Column size={8} spacingTop="l">
					<h5>Verification</h5>
					<Body>I confirm that I have verified the patient’s identity using their:</Body>
					{idDocument === 'passport' && (
						<FormTextInput
							desktopSize={4}
							tabletSize={4}
							label="Passport Number"
							name="passportNumber"
							pattern={/^[^,\s\'\"]*$/}
							errorMessage="Please enter correct passport id"
						/>
					)}
					<FormRadioMenuInput
						name="patientIdVerification"
						group="documentType"
						options={idOptions}
						value={idDocument}
						setter={setIdDocument}
						required={true}
					/>
					Please read and confirm:
					<Body small>
						· I have administered the test correctly in line with the guidelines provided.
					</Body>
					<Body small>
						· I have provided complete and accurate details for the individual(s) and test kit(s).
					</Body>
					<Body small>
						· I fully understand all the information provided. If I am unsure about any aspect of
						the service I will contact Beacon Secure before proceeding.
					</Body>
					<Body small>
						· I have read, understand and agree to the
						<Link to="/terms-and-conditionsterms" style={{ fontSize: '1.2rem' }}>
							terms and conditions
						</Link>{' '}
						&nbsp;and&nbsp;
						<Link to="/privacy-policy" style={{ fontSize: '1.2rem' }}>
							privacy policy
						</Link>
						.
					</Body>
					<FormCheckboxInput desktopSize={8} name="agreedToTandC" isConsent>
						I confirm the above
					</FormCheckboxInput>
				</Column>
				<Row contained size={8}>
					<BackButton onClick={onBack} />
					<SubmitButton primary submit>
						Next
					</SubmitButton>
				</Row>
			</BeaconForm>
		</>
	)
}

export { PatientDetailsEntry }
