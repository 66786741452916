import React, { useState, useEffect } from 'react'
import XLSX from 'xlsx';
import { checkIfAllEmpty } from 'components/common/util/util';
import { Row, Spacer, Icon, BackButton } from '../../../../components'
import downloadIcon from '../../../../assets/images/download-icon.svg';
import { BulkAssignUpload } from '../../../customers/AssignTestKitPage/BulkAssignUpload';
import { Button } from '@material-ui/core';
// import { COUNTRIES_LIST } from 'components/common/util/util';
import EditableTable from '../editable.table';
import LoaderButton from '../../../../components/common/loader.button/loader.button';
import { isValidExcelPatientDetail } from '../validator';
import { GENDER_LIST, PREFERRED_TIMES, SERVICE_TYPES } from 'components/common/util/util';
import moment from 'moment';
import { sortableUid } from '../../../../utils';
import { getCountry, getGender, getPreferredTime, getServiceType, getTelephoneNumber } from '../../../../components/common/util/util';

const PatientDetailUploadExcel = (props) => {
    const { execStatusScheduleTest, scheduleTestStatus } = props;
    const [patientsData, setPatientsData] = useState(props.patientsData);

    // const validateTimeValue = (time) => {
    //     const time_regex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/;
    //     return time_regex.test(time);
    // }
    const handleDownloadTemplateClick = () => {
        const templateHref = '/test_request_upload.xlsx'
        const anchor = document.createElement('a')
        anchor.href = templateHref
        anchor.download = templateHref
        document.body.appendChild(anchor)
        anchor.click()
    }

    const submitPatientDetails = () => {
        let newPatientsData = [...patientsData];
        for (const patient of newPatientsData) {
            const validationResult = isValidExcelPatientDetail(patient);
            if (validationResult.allValid === true) {
                if (patient.executionStatus !== "DONE" && patient.executionStatus !== "PENDING") {
                    let newPatient = { ...patient };
                    delete newPatient['executionStatus'];
                    props.bulkSubmit({ ...newPatient })
                }
            }
            else {
                patient.executionStatus = "ERROR"
            }
        }
        setPatientsData(newPatientsData)
    }


    const onUpload = (file) => {
        setPatientsData([])
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', cellDates: true, dateNF: 'dd/mm/yyyy;@' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { raw: false, blankrows: false, defval: '' });
            let exsValidData = [];
            data && data.map((dat, index) => {
                if (!checkIfAllEmpty(dat)) {
                    exsValidData.push({
                        serialNumber: sortableUid(),
                        dateOfTest: dat["Date of Test (DD/MM/YYYY)*"] ? moment(dat["Date of Test (DD/MM/YYYY)*"], "DD/MM/YYYY") : '',
                        preferredTime: getPreferredTime(dat["Service Type*"], dat["Preferred Time*"]),
                        serviceType: getServiceType(dat["Service Type*"]),

                        postcode: dat["Test Postcode*"],
                        addressLine1: dat["Test Address Line 1*"],
                        addressLine2: dat["Test Address Line 2"],
                        city: dat["Test City*"],
                        country: {
                            name: "United Kingdom",
                            value: "GBR"
                        },

                        email: dat["Email Address*"],
                        telephone: getTelephoneNumber(dat["Telephone*"]),
                        fullName: `${dat["First Name*"] ? dat["First Name*"] : ""} ${dat["Last Name*"] ? dat["Last Name*"] : ""}`,
                        familyName: dat["Last Name*"],
                        givenName: dat["First Name*"],

                        homeAddressLine1: dat["Home Address Line 1"],
                        homeAddressLine2: dat["Home Address Line 2"],
                        homeAddressCity: dat["Home City"],
                        homeAddressPostcode: dat["Home Postcode"],
                        homeAddressCountry: {
                            name: "United Kingdom",
                            value: "GBR"
                        },
                        DOB: dat["Date of Birth (DD/MM/YYYY)"] ? moment(dat["Date of Birth (DD/MM/YYYY)"], "DD/MM/YYYY") : '',
                        gender: getGender(dat["Gender"]),

                        documentType: dat["Document Type"],
                        documentCountry: dat["Issuing Country"] ? getCountry(dat["Issuing Country"]) : '',
                        documentAuthority: dat["Document Authority"],
                        documentNumber: dat["Document Number"],
                        documentExpiration: dat["Document Expiry Date (DD/MM/YYYY)"] ? moment(dat["Document Expiry Date (DD/MM/YYYY)"], "DD/MM/YYYY") : '',
                        //countryText: dat["Test Country"] ? dat["Test Country"] : "United Kingdom",
                        healthNumber: dat["Health Number"]
                    });
                }
            })
            setPatientsData(exsValidData);
        };
        if (file) {
            if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
        }
    }

    useEffect(() => {
        let newPatientsData = [...patientsData];
        if (scheduleTestStatus && scheduleTestStatus.size > 0) {
            for (let patient of newPatientsData) {
                let status = scheduleTestStatus && scheduleTestStatus.find((status) => status.get('serialNumber') === patient.serialNumber);
                if (status) {
                    patient.executionStatus = status.get('executionStatus')
                }
            }
            setPatientsData(newPatientsData);
        }
    }, [scheduleTestStatus])
    const updateData = (sortableUid, name, value) => {
        let newPatientData = [...patientsData];
        let index = newPatientData.findIndex((pt) => pt.serialNumber === sortableUid)
        if (name === "givenName") {
            newPatientData[index] = { ...newPatientData[index], [name]: value, ["fullName"]: `${value} ${newPatientData[index].familyName}` }
        }
        else if (name === "familyName") {
            newPatientData[index] = { ...newPatientData[index], [name]: value, ["fullName"]: `${newPatientData[index].givenName} ${value}` }
        }
        else {
            newPatientData[index] = { ...newPatientData[index], [name]: value }
        }
        setPatientsData(newPatientData);
    }
    const addNew = () => {
        let newPatientData = [...patientsData];
        newPatientData.push({ serialNumber: sortableUid() })
        setPatientsData(newPatientData);
    }
    const onDelete = (sortableUid) => {
        let newPatientData = [...patientsData];
        let index = newPatientData.findIndex((pt) => pt.serialNumber === sortableUid)
        newPatientData.splice(index, 1);
        setPatientsData(newPatientData);
    }
    return (
        <>
            <div className="w-100">
                {/* <Spacer spacing="m" />
                <Row>
                    <Button secondary icon onClick={handleDownloadTemplateClick}>
                        <Icon img={downloadIcon} type="btn-inline-left" alt="Download icon" />
                        Download excel template
                    </Button>
                </Row> */}
                <Spacer spacing="s" />
                <Row size={8}>
                    {/* {
                        execStatusScheduleTest.status === "PENDING" ?
                            null :
                            <BulkAssignUpload
                                onFileSelect={onUpload}
                            // onFileSelectError={({ error }) => alert(error)}
                            />
                    } */}
                    {
                        patientsData && patientsData.length > 0 ?
                            <Row size={7} tabletSize={9}>
                                <LoaderButton style={{ backgroundColor: "rgb(6, 122, 184)" }} onClick={props.onRest}>
                                    Reset
                                </LoaderButton>
                                {/* <LoaderButton style={{ backgroundColor: "rgb(6, 122, 184)" }} onClick={props.onUpload}>
                                    Upload
                                </LoaderButton> */}
                                <div className='ml-3 d-flex align-items-center'>
                                    {patientsData.filter((data) => { return (data?.executionStatus !== "DONE") }).length === 0 ? <div className={{ color: 'lightgreen' }}>Form Submitted</div> :
                                        <LoaderButton style={{ backgroundColor: "rgb(6, 122, 184)" }} loading={execStatusScheduleTest.status === "PENDING" ? true : false} onClick={submitPatientDetails}>
                                            Process
                                        </LoaderButton>
                                    }
                                </div>
                            </Row>
                            : null
                    }
                </Row>
                <Spacer spacing="s" />
                {
                    patientsData && patientsData.length > 0 ?
                        <div className="">
                            <EditableTable onDelete={onDelete} addNew={addNew} updateData={updateData} rows={patientsData} execStatusScheduleTest={execStatusScheduleTest} submitPatientDetails={submitPatientDetails} />
                        </div> : null
                }
            </div>
        </>
    )
}
export default PatientDetailUploadExcel
