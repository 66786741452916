import React from 'react'
import { Row, Column, Body, Spacer } from '../components'
import phoneIcon from '../assets/images/phone-icon.svg'
import addressIcon from '../assets/images/address-icon.svg'
import { ContactFormSegment } from './marketing/ContactFormSegment'

const ContactIconAndDescription = ({ icon, children }) => {
	return (
		<Row desktopSize={3} contained style={{ alignItems: 'flex-start' }}>
			<img src={icon} alt="phone icon" style={{ padding: '0.8rem 1rem 0.8rem 0' }} />
			<Spacer xs />
			<Column style={{ width: 'auto' }}>{children}</Column>
		</Row>
	)
}

const ContactUsPage = () => {
	return (
		<Column padding="m">
			<h1>Contact us</h1>
			<Row contained>
				<Column desktopSize={6} tabletSize={12}>
					<Body>Please use this form to get in touch or email us if you have any questions.</Body>
					<Body> We’ll get back to you as soon as possible.</Body>

					<ContactFormSegment subject="Contact Form" />
				</Column>
				<Column desktopSize={1} tabletSize={0}></Column>
				<Column contained desktopSize={5} tabletSize={12}>
					<h5>Other options</h5>
					<Body>We can also be contacted at the following:</Body>
					<Spacer s />
					<ContactIconAndDescription icon={phoneIcon}>
						<Body>
							Freephone:{' '}
							<a href="tel:08000786112" style={{ fontSize: '14px', fontWeight: 'bold' }}>
								0800 078 6112
							</a>
						</Body>
						<Body small>Available 24/7 </Body>
						<Body>
							Email:{' '}
							<a
								href="mailto:enquiries@beaconsecure.co.uk"
								style={{ fontSize: '14px', fontWeight: 'bold' }}
							>
								enquiries@beaconsecure.co.uk
							</a>
						</Body>
					</ContactIconAndDescription>
					<ContactIconAndDescription icon={addressIcon}>
						<Body>20-22 Wenlock Road</Body>
						<Body>London</Body>
						<Body>N1 7GU</Body>
						<Body>United Kingdom</Body>
					</ContactIconAndDescription>
					<div style={{ width: '200px', height: '200px' }}>
						{/* <GoogleMapReact defaultCenter={{ lat: 59.95, lng: 30.33 }} /> */}
					</div>
				</Column>
			</Row>
		</Column>
	)
}

export { ContactUsPage }
