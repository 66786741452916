import React from 'react'
import { Column } from '../containers'
import { useBeaconForm } from './BeaconForm'
import { resolveTextInputValidators } from './resolveTextInputValidators'
import { inputDefaultPropValues } from './defaults'
import { Body } from '../typography'

const FormTextAreaInput = ({
	label,
	placeholder,
	name,
	required,
	errorMessage,
	mobileSize,
	tabletSize,
	desktopSize,
}) => {
	const { register, getValues, hasError, error } = useBeaconForm(name)
	const validators = resolveTextInputValidators(null, null, required, errorMessage, getValues)
	const borderClassName = `form-component form-component-border ${hasError ? 'invalid' : ''}`.trim()

	return (
		<Column mobileSize={mobileSize} tabletSize={tabletSize} desktopSize={desktopSize}>
			<label className="form-component">{label}</label>
			<textarea className={borderClassName} name={name} ref={register({ ...validators })} />
			{error && (
				<Body small error>
					{error.message}
				</Body>
			)}
		</Column>
	)
}

FormTextAreaInput.defaultProps = inputDefaultPropValues

export { FormTextAreaInput }
