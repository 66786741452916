import React, { useState, useEffect } from 'react'
import {IsMobileWidth} from "../../components/common/util/util";
import {CircularProgress, Paper} from "@material-ui/core";
import clsx from 'clsx';
import {Button} from "../../components";
import DashboardIconBarV2 from '../../components/common/dashboard.icon.bar.v2/dashboard.icon.bar.v2'
import Pagination from "@material-ui/lab/Pagination";
import BarcodeDialog from "./Barcode.dialog";
import {useAdmin} from "../../utils";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {Skeleton} from "@material-ui/lab";

const Barcode = (props) => {
    const mobileWidth = IsMobileWidth();

    const [showUpdateDialog, setShowUpdateDialog] = useState(false)
    const [barcodeFlag, setBarcodeFlag] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [pageIndex, setPageIndex] = useState(1)
    const [paginatedBarcode, setPaginatedBarcode] = useState([])
    const [currentBarCode, setCurrentBarCode] = useState(null)
    const [barcodes, setBarcodes] = useState([])
    const [barcodesCount, setBarcodesCount] = useState([])
    const [ barcodeStatus, setBarcodeStatus ] = useState(false);
    const [ selectedBarcode, setSelectedBarcode ] = useState('TOTAL');
    const [ loader, setLoader ] = useState(false);
    const { listBarcodes } = useAdmin()

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(()=>{
        {console.log('paginatedBarcode',barcodes)}

        if (barcodes) {
            const page = Math.ceil(barcodes?.length / 6);
            const index = pageIndex == 1 ? 0 : (6* (pageIndex-1));
            setTotalCount(page);
            setPaginatedBarcode(barcodes?.slice(index, 6*(pageIndex)))
        }
    },[pageIndex, barcodes, ])

    useEffect(()=> {
        setLoader(true)
        barcodeList();
    },[])

    const barcodeList = () => {
        listBarcodes()
            .then((response) => {
                const BCode = response.data?.data
                setBarcodes(BCode)
                setBarcodesCount(BCode)
                setLoader(false)
            })
            .catch((error) => {

            })
    }


    const onPageChange = (event, pageNumber) => {
        setPageIndex(pageNumber);
        const index = pageNumber == 1 ? 0 : (6* (pageNumber-1));
        setPaginatedBarcode(barcodes?.slice(index, 6*(pageNumber)))
    }

    const assignedBarcodes = () => {
        setBarcodes(barcodesCount?.filter((item)=> item?.isUsed === true));
        setSelectedBarcode('ASSIGNED');
        setPageIndex(1)
    }

    const unassignedBarcodes = () => {
        setBarcodes(barcodesCount?.filter((item)=> item?.isUsed === false));
        setSelectedBarcode('UNASSIGNED');
        setPageIndex(1)
    }

    const totalBarcodes = () => {
        setBarcodes(barcodesCount);
        setSelectedBarcode('TOTAL')
        setPageIndex(1)
    }

    return(
        <>
            <div>
                <h3>
                    Barcodes Sourcebioscience (SBS) lab
                </h3>
            </div>
            <div className="d-flex flex-row justify-content-between w-100 px-5">
                {loader ?
                    <CircularProgress size={30} />
                : paginatedBarcode?.length > 0 ?
                    <div className={clsx("d-flex", !mobileWidth && 'w-75 flex-row', mobileWidth && 'w-75 flex-column')}>
                        <DashboardIconBarV2
                            className="result-default-v2 mb-2"
                            description={barcodesCount?.length > 0 ? barcodesCount?.length : "0"}
                            onClick={() => totalBarcodes()}
                            selected={selectedBarcode === "TOTAL" ? true : false}
                        >
                            Total
                        </DashboardIconBarV2>
                        <DashboardIconBarV2
                            className="result-negative-v2 mb-2"
                            description={barcodesCount?.filter((item)=> item?.isUsed === true)?.length ? barcodesCount?.filter((item)=> item?.isUsed === true)?.length : "0"}
                            onClick={() => assignedBarcodes()}
                            selected={selectedBarcode === "ASSIGNED" ? true : false}
                        >
                            Assigned
                        </DashboardIconBarV2>
                        <DashboardIconBarV2
                            className="result-positive-v2 mb-2"
                            description={barcodesCount?.filter((item)=> item?.isUsed === false)?.length ? barcodesCount?.filter((item)=> item?.isUsed === false)?.length : "0"}
                            onClick={() => unassignedBarcodes()}
                            selected={selectedBarcode === "UNASSIGNED" ? true : false}
                        >
                            Unassigned
                        </DashboardIconBarV2>
                    </div>
                : null
                }
                <Button primary
                        onClick={() => { setShowUpdateDialog(true);  setBarcodeFlag('added'); setCurrentBarCode(null)}}>
                    Add
                </Button>
            </div>
            <div className="w-100 d-flex justify-content-start px-5">
                {!(mobileWidth) && (
                    <div className="d-flex flex-column w-100">
                        <div className="pt-3">
                            <div className="w-100 d-flex pb-2 pt-2 p-1">
                                <div style={{ width: '5%' }} className=" d-flex  align-items-center">
                                    <b>No</b>
                                </div>
                                <div className="w-50  pl-2 d-flex align-items-center">
                                    <b>Barcodes</b>
                                </div>
                                <div className="w-25  pl-2 d-flex align-items-center">
                                    <b>Status</b>
                                </div>
                                <div style={{ width: '20%' }} className=" ml-2 d-flex justify-content-center align-items-center">
                                    <b> Actions </b>
                                </div>
                            </div>
                        </div>
                        {loader ?
                            <div>
                                <Skeleton variant="text" width="100%" height={45} />
                                <Skeleton variant="text" width="100%" height={45} />
                                <Skeleton variant="text" width="100%" height={45} />
                                <Skeleton variant="text" width="100%" height={45} />
                                <Skeleton variant="text" width="100%" height={45} />
                                <Skeleton variant="text" width="100%" height={45} />
                                <Skeleton variant="text" width="100%" height={45} />
                                <Skeleton variant="text" width="100%" height={45} />
                                <Skeleton variant="text" width="100%" height={45} />
                                <Skeleton variant="text" width="100%" height={45} />
                                <Skeleton variant="text" width="100%" height={45} />
                                <Skeleton variant="text" width="100%" height={45} />
                            </div>
                            :
                            paginatedBarcode ? paginatedBarcode?.map((barcode, index) => {
                                return <Paper key={index} className={clsx("w-100 paper-root d-flex py-4 px-2 border-radius-10", index % 2 === 0 && "bg-light")}
                                    elevation={1}>
                                    <div style={{width: '5%'}}
                                         className=" d-flex justify-content-start align-items-center border-right">
                                        <div className="pl-2">
                                            {pageIndex == 1 ? (index + 1) : (6* (pageIndex-1)) + index + 1  }
                                        </div>
                                    </div>
                                    <div style={{wordBreak: 'break-all'}}
                                         className="w-50 pl-2 d-flex justify-content-start align-items-center border-right">
                                        <b> {barcode?.code}</b>
                                    </div>
                                    <div style={{wordBreak: 'break-all'}}
                                         className="w-25 pl-2 d-flex justify-content-start align-items-center border-right">
                                        <b> {barcode?.isUsed ? 'Assigned' : 'Unassigned'}</b>
                                    </div>
                                    <div style={{width: '20%', padding: '2px 4px'}}
                                         className="rounded pl-2 d-flex justify-content-center align-items-center border-right">

                                        <i
                                            onClick={() => { setShowUpdateDialog(true); setBarcodeFlag('edited'); setCurrentBarCode(barcode)}}
                                            // setSelectedKit(kit) }}
                                            style={{fontSize: '18px', color: "rgb(6, 122, 184)"}}
                                            className="mr-2 fas fa-edit text-center cursor-pointer"></i>
                                        <i
                                            onClick={() => { setShowUpdateDialog(true); setBarcodeFlag('deleted'); setCurrentBarCode(barcode)}}
                                            style={{fontSize: '18px', color: "rgb(6, 122, 184)"}}
                                            className="text-center cursor-pointer fas fa-trash"></i>
                                    </div>
                                </Paper>
                            })
                                :
                                <div>No Record Found</div>
                        }
                        {
                            totalCount > 1 ?
                                <div className="d-flex justify-content-center pt-4">
                                    <Pagination
                                        page={ pageIndex}
                                        onChange={onPageChange}
                                        count={totalCount} color="primary" />
                                </div> : null
                        }
                    </div>
                )}
            </div>

            {
                showUpdateDialog &&
                <BarcodeDialog
                    handleClose={() => setShowUpdateDialog(false)}
                    isOpen={showUpdateDialog}
                    barcodeFlag={barcodeFlag}
                    barcodeStatuses={()=> {setShowUpdateDialog(false); setBarcodeStatus(true); barcodeList()}}
                    currentBarCode={currentBarCode}
                    // kit={{ ...selectedKit.toJS() }}
                    {...props}
                />
            }

            <Snackbar
                      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                      open={barcodeStatus}
                      autoHideDuration={5000}
                      onClose={()=>setBarcodeStatus(false)}>

                <Alert onClose={()=>setBarcodeStatus(false)} severity="success">
                    {`Barcode has been successfully "${barcodeFlag}"`}
                </Alert>

            </Snackbar>

        </>
    )
}

export default Barcode