import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Row, Column, Body, TextInput, Button, Form, Spacer } from '../components'
import { usePublic } from '../utils/backend'

const RequestSent = () => {
	return (
		<>
			<Row>
				<Column desktopSize={4} tabletSize={3} mobileSize={0} spacingTop="m" />
				<Column desktopSize={4} tabletSize={6} spacingTop="m">
					<h1>Data Protection</h1>
					<Body>Request to erase your data was sent</Body>

					<Button href="/">Back to Home</Button>
				</Column>
				<Column desktopSize={4} tabletSize={3} mobileSize={0} spacingTop="m" />
			</Row>
		</>
	)
}

const SendRequestForm = () => {
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const { invokeDataErasure } = usePublic()
	const history = useHistory()

	const handleLoginWithEmail = async () => {
		setLoading(true)

		const safeEmail = email.trim()

		invokeDataErasure(safeEmail)
			.then(() => {
				setLoading(false)
				history.push('/dataprotection/requested')
			})
			.catch((error) => {
				setLoading(false)
				console.error(error)
			})
	}

	return (
		<>
			<Row>
				<Column desktopSize={4} tabletSize={3} mobileSize={0} spacingTop="m" />
				<Column desktopSize={4} tabletSize={6} spacingTop="m">
					<h1>Data Protection</h1>
					<Body>
						If you believe that we have data associated with you, please submit your email, and our
						Data Protection Officer will be in touch with you.
					</Body>
					<Column desktopSize={4} tabletSize={6} spacingTop="m">
						<Form onSubmit={handleLoginWithEmail}>
							<TextInput
								label="Email Address"
								value={email}
								setter={setEmail}
								desktopSize={4}
								tabletSize={6}
								disabled={loading}
							/>
							<Spacer spacing="s" />
							<Button submit loading={loading}>
								Request deletion
							</Button>
						</Form>
					</Column>
				</Column>
				<Column desktopSize={4} tabletSize={3} mobileSize={0} spacingTop="m" />
			</Row>
		</>
	)
}

export const DataProtectionPage = () => {
	const location = useLocation()

	if (location.pathname === '/dataprotection/requested') {
		return <RequestSent />
	} else {
		return <SendRequestForm />
	}
}
