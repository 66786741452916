// TODO Check and remove special characters allow only numbers and letters, ie. no dashes or any other character
//

import moment from "moment"
import { sortableUid } from "."
import { getCountry, getGender, getPreferredTime, getServiceType, getTelephoneNumber, isEmpty } from "../components/common/util/util"

//SAME_DAY_ORDER_CHANGES
const isValidString = (input, minLength = 2, maxLength = 1024) =>
	typeof input === 'string' && input.length >= minLength && input.length <= maxLength

const isPatientValid = ({
	firstName,
	lastName,
	email,
	telephone,
	addressLine1,
	addressLine2,
	city,
	postcode,
	jobNumber,
	deliveryDate,
	deliverBy,
	deliveryWindow,
}) =>
	[
		isValidString(firstName, 1),
		isValidString(lastName, 1),
		isValidString(email, 5),
		isValidString(telephone, 2),
		isValidString(jobNumber, 2),
		isValidString(addressLine1, 2),
		isValidString(addressLine2, 2),
		isValidString(city, 2),
		isValidString(postcode, 5, 12),
	].every((isValid) => isValid)

const isSameDayPatientValid = ({
	fullName,
	email,
	telephone,
	DOB,
	gender,
	documentType,
	documentCountry,
	documentAuthority,
	documentNumber,
	documentExpiryDate,
	addressLine1,
	addressLine2,
	city,
	country,
	nationality,
	postcode,
	healthNumber,
	givenName,
	familyName,
	preferredTime,
	deliveryDate,
	deliverBy,
	deliveryWindow,
}) =>
	[
		isValidString(fullName, 1),
		isValidString(DOB, 1),
		isValidString(email, 5),
		isValidString(gender, 1),
		//isValidString(telephone, 2),
		isValidString(healthNumber, 2),
		isValidString(addressLine1, 2),
		isValidString(addressLine2, 2),
		isValidString(city, 2),
		isValidString(postcode, 5, 12),
	].every((isValid) => isValid)


// Return array of string values, or NULL if CSV string not well formed.

const csvToArray = (text) => {
	let p = '',
		row = [''],
		ret = [row],
		i = 0,
		r = 0,
		s = !0,
		l
	for (l of text) {
		if ('"' === l) {
			if (s && l === p) row[i] += l
			s = !s
		} else if (',' === l && s) l = row[++i] = ''
		else if ('\n' === l && s) {
			if ('\r' === p) row[i] = row[i].slice(0, -1)
			row = ret[++r] = [(l = '')]
			i = 0
		} else row[i] += l
		p = l
	}
	return ret
}

export const parsePatientCsv = (csv) => {
	// const lines = csv.split('\r\n').join('\n').split('\r').join('\n').split('\n')
	// console.log('lines: ', lines)

	// lines.shift()
	// const values = lines.map((line) => line.split(','))
	const values = csvToArray(csv)
	values.shift()
	console.log('value: ', values)

	const patients = values
		.map(
			([
				firstName,
				lastName,
				email,
				telephone,
				addressLine1,
				addressLine2,
				city,
				postcode,
				jobNumber,
				deliveryDate,
				deliverBy,
				deliveryWindow,
				driverNotes,
			]) => ({
				firstName,
				lastName,
				email,
				telephone,
				addressLine1,
				addressLine2,
				city,
				postcode,
				jobNumber,
				deliveryDate,
				deliverBy,
				deliveryWindow,
				driverNotes,
			})
		)
		.map((patient) => ({
			...patient,
			isValid: isPatientValid(patient),
		}))

	return patients
}

const checkIfAllEmpty = (patient) => {
	let allEmpty = true
	Object.entries(patient).forEach(pt => {
		if (pt !== undefined) {
			if (pt?.toString()?.trim() !== "") {
				allEmpty = false;
				return allEmpty
			}
		}
	})
	return allEmpty;
}

const checkIfAllEmptyTestTemp = (patient) => {
	let allEmpty = true
	Object.entries(patient).forEach((pt, key) => {
		if (pt[1] || pt[1] !== undefined) {
			if (pt[1]?.toString()?.trim() !== "" || !isEmpty(pt[1])) {
				console.log(pt[1], "pt")
				allEmpty = false;
				return allEmpty
			}
		}
	})
	return allEmpty;
}
export const parseSameDayPatientCsv = (csv) => {
	// const lines = csv.split('\r\n').join('\n').split('\r').join('\n').split('\n')
	// console.log('lines: ', lines)

	// lines.shift()
	// const values = lines.map((line) => line.split(','))
	const values = csvToArray(csv)
	values.shift()
	// console.log('value: ', values)
	const patients = [];
	values.map(
		([
			fullName,
			email,
			telephone,
			DOB,
			gender,
			documentType,
			documentCountry,
			documentAuthority,
			documentNumber,
			documentExpiryDate,
			addressLine1,
			addressLine2,
			city,
			country,
			nationality,
			postcode,
			healthNumber,
			givenName,
			familyName,
			preferredTime,
			deliveryDate,
			deliverBy,
			deliveryWindow,
		]) => ({
			fullName,
			email,
			telephone,
			DOB,
			gender,
			documentType,
			documentCountry,
			documentAuthority,
			documentNumber,
			documentExpiryDate,
			addressLine1,
			addressLine2,
			city,
			country,
			nationality,
			postcode,
			healthNumber,
			givenName,
			familyName,
			preferredTime,
			deliveryDate,
			deliverBy,
			deliveryWindow,
		})
	)
		.map((patient, index) => {
			if (!checkIfAllEmpty(patient)) {
				patients.push({
					...patient,
					isValid: isSameDayPatientValid(patient),
				})
			}
		})
	return patients
}

export const parsePCRPaitentCsv = (csv) => {
	// const lines = csv.split('\r\n').join('\n').split('\r').join('\n').split('\n')
	// console.log('lines: ', lines)

	// lines.shift()
	// const values = lines.map((line) => line.split(','))
	const values = csvToArray(csv)
	values.shift()
	// console.log('value: ', values)
	const patients = [];
	values.map(
		([
			dateOfTest,
			familyName,
			givenName,
			email,
			telephone,
			serviceType,
			preferredTime,
			addressLine1,
			addressLine2,
			postcode,
			city,
			country,
			DOB,
			gender,
			homeAddressPostcode,
			homeAddressLine1,
			homeAddressLine2,
			homeAddressCity,
			homeAddressCountry,
			documentType,
			documentCountry,
			documentAuthority,
			documentNumber,
			documentExpiryDate,
			healthNumber
		]) => (
			{
				dateOfTest,
				familyName,
				givenName,
				email,
				telephone,
				serviceType,
				preferredTime,
				addressLine1,
				addressLine2,
				postcode,
				city,
				country,
				DOB,
				gender,
				homeAddressPostcode,
				homeAddressLine1,
				homeAddressLine2,
				homeAddressCity,
				homeAddressCountry,
				documentType,
				documentCountry,
				documentAuthority,
				documentNumber,
				documentExpiryDate,
				healthNumber
			}
		)
	)
		.map((patient, index) => {
			if (!checkIfAllEmptyTestTemp(patient)) {
				// console.log(getServiceType(patient.serviceType), "123321")
				patients.push({
					...patient,
					serialNumber: sortableUid(),
					dateOfTest: patient.dateOfTest ? moment(patient.dateOfTest, "DD/MM/YYYY") : '',
					fullName: `${patient.givenName ? patient.givenName : ""} ${patient.familyName ? patient.familyName : ""}`,
					telephone: getTelephoneNumber(patient.telephone),
					serviceType: getServiceType(patient.serviceType),
					preferredTime: getPreferredTime(patient.serviceType, patient.preferredTime),
					country: patient.country ? getCountry(patient.country) : "",
					DOB: patient.DOB ? moment(patient.DOB, "DD/MM/YYYY") : '',
					gender: getGender(patient.gender),
					homeAddressCountry: patient.homeAddressCountry ? getCountry(patient.homeAddressCountry) : "",
					documentCountry: patient.documentCountry ? getCountry(patient.documentCountry) : "",
					documentExpiryDate: patient.documentExpiryDate ? moment(patient.documentExpiryDate, "DD/MM/YYYY") : '',

					// isValid: isSameDayPatientValid(patient),
				})
			}
		})
	console.log(patients, "ye lo g")
	return patients
}
