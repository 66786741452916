import React, { useContext, useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import PatientsList from './patient.list';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
// import PatientDetailUploadExcel from './patient.detail.upload.excel';
import MaterialAutoComplete from 'components/common/material.autocomplete/material.autocomplete';
import { Button } from '@material-ui/core';
import AddEventDialog from './add.event.dialog';
import { isValidEvent } from './validator';
import { sortableUid, useOrdering } from '../../../utils';
import UploadBuldPatientDetail from './patient-details-csv-upload/upload.bulk.patient.detail';

const ScheduleTest = (props) => {
    const { defaultOrganisation } = useOrdering()
    const { events, execStatusGetEvents, selectedEvent, execStatusGetKits, execStatusCreateEvent } = props;
    const [selectedTab, setSelectedTab] = useState("ADD_USING_FORM")
    const [state, setState] = useState({
        showAddEventDialogIsOpen: false,
        eventName: '',
        isValidEventName: true,
        isEventAlreadyExist: false
    })
    const handleChange = (value) => {
        setSelectedTab(value)
    };
    let evts = events && events.map((evnt) => {
        return {
            name: evnt.get('eventName'),
            value: evnt.get('eventId')
        }
    })
    const showAddEventDialog = () => {
        setState({ ...state, showAddEventDialogIsOpen: true, eventName: '' })
    }
    const hideAddEventDialog = () => {
        setState({ ...state, showAddEventDialogIsOpen: false })
    }
    const onChange = (value, name) => {
        setState({ ...state, [name]: value })
    }
    const onEventAdd = () => {
        const validationResult = isValidEvent({ ...state });
        if (validationResult.allValid === true) {
            let index = events && events.findIndex((event) => event.get('eventName').toLowerCase() === state.eventName.toLowerCase().trim())
            if (index > -1) {
                setState({ ...state, ...validationResult, isEventAlreadyExist: true })
            }
            else {
                setState({ ...state, ...validationResult, isEventAlreadyExist: false })
                const organisationId = defaultOrganisation()
                props.createEvent({
                    eventName: state.eventName.trim(),
                    organisationId: organisationId && organisationId.trim(),
                    eventId: sortableUid()
                })
            }
        }
        else {
            setState({ ...state, ...validationResult })
        }
    }
    useEffect(() => {
        if (execStatusGetEvents.status === "PENDING") {
            setState({ ...state, showAddEventDialogIsOpen: false })
        }
    }, [execStatusGetEvents])

    return (
        <div className="w-100">
            <div className="d-flex align-items-center">
                <div className="w-25 pt-2 pb-2" style={{ maxWidth: "400px" }}>
                    <MaterialAutoComplete
                        //startAdornment={"Events"}
                        execStatus={execStatusGetEvents}
                        disabled={execStatusGetKits.status === "PENDING" ? true : false}
                        selectedItem={selectedEvent}
                        onSelect={(value, data) => props.onSelect(value, data, 'selectedEvent')}
                        placeholder="Select Job"
                        data={evts && evts.toJS()}
                    />
                </div>
                <div className="ml-3">
                    <Button variant="outlined" onClick={showAddEventDialog} className="text-capitalize" > Add New Job </Button>
                </div>
            </div>
            {
                selectedEvent && selectedEvent.value ?
                    <Paper className="paper-root pl-3 pr-3 mt-2" style={{
                        width: 'max-content'
                    }} square>
                        <FormLabel className="pt-2 font-weight-bold" component="legend"></FormLabel>
                        <RadioGroup aria-label="" value={selectedTab} onChange={(e) => handleChange(e.target.value)} name=""
                            style={{
                                flexWrap: 'nowrap',
                                fontSize: 'initial'
                            }} className="d-flex flex-row">
                            <FormControlLabel value={"ADD_USING_FORM"} control={<Radio />} label="Add using form" />
                            <FormControlLabel value={"ADD_USING_EXCEL"} control={<Radio />} label="Add using excel" />
                        </RadioGroup>
                    </Paper> : null

            }
            <div>
            </div>
            {
                selectedEvent && selectedEvent.value ?
                    <React.Fragment>
                        {
                            selectedTab === "ADD_USING_FORM" ?
                                <div className="w-100 d-flex justify-content-start pt-2">
                                    <PatientsList
                                        {...props} />
                                </div> : null
                        }
                        {
                            selectedTab === "ADD_USING_EXCEL" ?
                                <div className="w-100 d-flex justify-content-start pt-2">
                                    <UploadBuldPatientDetail {...props} />
                                    {/* <PatientDetailUploadExcel
                                        {...props} /> */}
                                </div> : null
                        }
                    </React.Fragment> : null
            }
            {
                state.showAddEventDialogIsOpen === true ?
                    <AddEventDialog
                        onChange={onChange}
                        execStatusCreateEvent={execStatusCreateEvent}
                        onEventAdd={onEventAdd}
                        isValidEventName={state.isValidEventName}
                        open={state.showAddEventDialogIsOpen}
                        isEventAlreadyExist={state.isEventAlreadyExist}
                        handleClose={hideAddEventDialog}
                        {...state}
                    /> : null
            }
        </div>
    )
}
export default ScheduleTest