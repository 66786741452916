export const LOGIN = "LOGIN";
export const RESET_AUTH = "RESET_AUTH";
export const LOGOUT = "LOGOUT";

export function login(data) {
    return {
        url : 'api',
        type : LOGIN,
        data,
        method : 'POST'
    }
}
export function logout() {
    return {
        url : '',
        type : LOGOUT,
        method : 'POST'
    }
}
export function resetAuth() {
    return {
        type : RESET_AUTH
    }
}