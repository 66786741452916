export const gendersList = ['Male', 'Female', 'Transgender', 'Other']
export const genderPattern = /^(Female|Male|Transgender|Other)$/i
export const preferredTimeList = ['07:00 - 10:00', '10:00 - 13:00', '13:00 - 16:00', '16:00 - 19:00']
export const preferredTimePattern = /^(07:00 - 10:00|10:00 - 13:00|13:00 - 16:00|16:00 - 19:00)$/i
export const preferredTimeListCase1 = ['10:00 - 13:00', '13:00 - 16:00', '16:00 - 19:00']
export const preferredTimePatternCase1 = /^(10:00 - 13:00|13:00 - 16:00|16:00 - 19:00)$/i
export const preferredTimeListCase2 = ['13:00 - 16:00', '16:00 - 19:00']
export const preferredTimePatternCase2 = /^(13:00 - 16:00|16:00 - 19:00)$/i
export const preferredTimeListCase3 = ['16:00 - 19:00']
export const preferredTimePatternCase3 = /^(16:00 - 19:00)$/i

function getMinutes(str) {
    var time = str.split(':');
    return time[0] * 60 + time[1] * 1;
}
function getMinutesNow() {
    var timeNow = new Date();
    return timeNow.getHours() * 60 + timeNow.getMinutes();
}
export const getPreferredTimeList = () => {
    var now = getMinutesNow();
    var startCase1 = getMinutes('07:00');
    var endCase1 = getMinutes('10:00');

    var startCase2 = getMinutes('10:00');
    var endCase2 = getMinutes('13:00');

    var startCase3 = getMinutes('13:00');
    var endCase3 = getMinutes('16:00');

    var startCase4 = getMinutes('16:00');
    var endCase4 = getMinutes('19:00');

    if (now <= startCase1) {
        return preferredTimeList;
    }
    else if ((now > startCase1) && (now < endCase1)) {
        return preferredTimeList;
    }
    else if ((now > startCase2) && (now < endCase2)) {
        return preferredTimeListCase1;
    }
    else if ((now > startCase3) && (now < endCase3)) {
        return preferredTimeListCase2;
    }
    else if ((now > startCase4) && (now < endCase4)) {
        return preferredTimeListCase3;
    }
    else {
        return preferredTimeList;
    }
}
export const getPreferredTimeListPattern = () => {
    var now = getMinutesNow();
    var startCase1 = getMinutes('07:00');
    var endCase1 = getMinutes('10:00');

    var startCase2 = getMinutes('10:00');
    var endCase2 = getMinutes('13:00');

    var startCase3 = getMinutes('13:00');
    var endCase3 = getMinutes('16:00');

    var startCase4 = getMinutes('16:00');
    var endCase4 = getMinutes('19:00');
    if (now <= startCase1) {
        return preferredTimePattern;
    }
    else if ((now > startCase1) && (now < endCase1)) {
        return preferredTimePattern;
    }
    else if ((now > startCase2) && (now < endCase2)) {
        return preferredTimePatternCase1;
    }
    else if ((now > startCase3) && (now < endCase3)) {
        return preferredTimePatternCase2;
    }
    else if ((now > startCase4) && (now < endCase4)) {
        return preferredTimePatternCase3;
    }
    else {
        return preferredTimePattern;
    }
}

export function formatTime(date) {
    if (date === undefined) {
        return ""
    }
    if (typeof date === "string") {
        date = new Date(date.replace(" ", "T"))
    }
    var hours = date.getHours()
    var minutes = date.getMinutes()
    minutes = ('0' + minutes).slice(-2)
    hours = ('0' + hours).slice(-2)
    return `${hours}:${minutes}`
}

export function frmtDate(date) {
    date = new Date(date);
    let year = date.getFullYear();
    var datePart = date.getDate();
    var month = date.getMonth() + 1;
    month = ('0' + month).slice(-2);
    datePart = ('0' + datePart).slice(-2);
    return `${year}-${month}-${datePart}`
}

export function getMinimumTestDate() {
    if (new Date().getHours() >= 19) {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + 1);
        return frmtDate(newDate)
    }
    else {
        return frmtDate(new Date())
    }
}