import React from 'react'
// import CustomCalender from 'components/common/custom.calender/custom.calender';
import clsx from 'clsx';
import MaterialAutoComplete from 'components/common/material.autocomplete/material.autocomplete';
import { IsMobileWidth } from 'components/common/util/util';
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
import { Button } from '@material-ui/core';
import MaterialAutoCompleteTypeable from 'components/common/material.autocomplete.typeable/material.autocomplete.typeable';

export default function KitFilters(props) {

    const { selectedOrg, selectedResultStatus, selectedKitStatus,
        execStatusGetOrganisations, search, execStatusGetKits,
        selectedPartnerLab,
        organisations, events, execStatusGetEvents, selectedEvent } = props;
    const onSubmit = (event) => {
        event.preventDefault();
        props.onSearchEnter();
    }
    let orgs = []
    orgs.push(
        {
            name: 'All Organisations',
            value: ''
        }
    )
    organisations && organisations.map((org) => {
        orgs.push({
            name: org.get('organisationId'),
            value: org.get('organisationId')
        })
    })
    let evts = events && events.size > 0 ? [] : "";
    if (events && events.size > 0) {
        evts.push(
            {
                name: 'All Events',
                value: ''
            }
        )
    }
    events && events.map((evnt) => {
        evts.push({
            name: evnt.get('eventName'),
            value: evnt.get('eventId')
        })
    })

    // const handleClick = (value) => {
    //     setState({ ...state, selected: value })
    // }
    // const onAccept = (date, value) => {
    //     setState({ ...state, [value]: date })
    // }
    // const addMonths = (date, months) => {
    //     date.setMonth(date.getMonth() + months);
    //     return date;
    // }
    const mobileWidth = IsMobileWidth();

    return (
        <form className="w-100">
            <div className="w-100 d-flex flex-wrap pt-3">
                {/* <div className={clsx(!mobileWidth && "w-20 d-flex align-items-center", mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                <CustomCalender label="From"
                    onAccept={(date) => onAccept(date, "fromDate")}
                    value={state.fromDate}
                />
            </div>
            <div className={clsx(!mobileWidth && "w-20 d-flex align-items-center pl-3", mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                <CustomCalender
                    label="To"
                    onAccept={(date) => onAccept(date, "toDate")}
                    value={state.toDate}
                />
            </div> */}
                <div className={clsx(!mobileWidth && "w-15 d-flex align-items-center pl-3", mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                    <MaterialAutoComplete
                        disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                        selectedItem={selectedKitStatus}
                        onSelect={props.onKitStatusChange}
                        placeholder="Select Status"
                        data={[
                            // {
                            //     name: 'All',
                            //     value: ''
                            // },
                            {
                                name: 'Confirmation Pending',
                                value: 'CONFIRMATION_PENDING'
                            },
                            {
                                name: 'Confirmation Link Sent',
                                value: 'CONFIRMATION_LINK_SENT'
                            },
                            {
                                name: 'Individual Confirmed Details',
                                value: 'INDIVIDUAL_CONFIRMED_TEST_DETAILS'
                            },
                            {
                                name: 'Assigned',
                                value: 'Assigned'
                            },
                            {
                                name: 'Result Ready',
                                value: 'ResultReady'
                            },
                            {
                                name: 'Cancelled',
                                value: 'CANCELLED'
                            }
                        ]}
                    />
                </div>
                <div className={clsx(!mobileWidth && "w-15 d-flex ml-4 align-items-center", mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                    <MaterialAutoCompleteTypeable
                        disabled={execStatusGetKits.status === "PENDING" ? true : false}
                        selectedItem={selectedPartnerLab}
                        onSelect={(value, data) => props.onPartnerLabChange(value, data, 'selectedPartnerLab')}
                        placeholder="Select Partner Lab"
                        data={[
                            {
                                name: 'All Labs',
                                value: ''
                            },
                            {
                                name: "Katalyst",
                                value: 'catalyst',
                            },
                            {
                                name: "SBS",
                                value: 'SBS',
                            }
                        ]}
                    />
                </div>
                <div className={clsx(!mobileWidth && "w-15 d-flex ml-4 align-items-center", mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                    <MaterialAutoCompleteTypeable
                        execStatus={execStatusGetOrganisations}
                        disabled={execStatusGetKits.status === "PENDING" ? true : false}
                        selectedItem={selectedOrg}
                        onSelect={(value, data) => { if (value) { props.onOrganisationChange(value, data, 'selectedOrg') } }}
                        placeholder="Select organisation"
                        data={orgs}
                    />
                </div>
                {
                    selectedOrg && selectedOrg.value ?
                        <div className={clsx(!mobileWidth && "w-15 d-flex align-items-center pl-3", mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                            <MaterialAutoComplete
                                //startAdornment={"Events"}
                                execStatus={execStatusGetEvents}
                                //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                selectedItem={selectedEvent}
                                onSelect={(value, data) => props.onEventFilterSelect(value, data, 'selectedEvent')}
                                placeholder="Select event"
                                data={evts}
                            />
                        </div> : null
                }
                {/* <div className={clsx(!mobileWidth && "w-20 d-flex align-items-center pl-3", mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                    <MaterialAutoComplete
                        disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                        selectedItem={selectedResultStatus}
                        onSelect={props.onResultStatusChange}
                        placeholder="Select Result"
                        data={[
                            {
                                name: 'All',
                                value: ''
                            },
                            {
                                name: 'Pending',
                                value: 'Pending'
                            },
                            {
                                name: 'Positive',
                                value: 'Positive'
                            },
                            {
                                name: 'Negative',
                                value: 'Negative'
                            },
                            {
                                name: 'Inconclusive',
                                value: 'Inconclusive'
                            }
                        ]}
                    />
                </div> */}
                {/* <div className={clsx(!mobileWidth && "w-25 d-flex align-items-center pl-3",
                    mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                    <SiteLabelTextField
                        disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                        onChange={props.onSearchChange}
                        value={search} label="Search"
                        placeholder="Search By Email" />
                </div> */}
                <Button className="d-none" type="submit" onClick={onSubmit} />
            </div>
        </form>
    )
}
