import React from 'react'
import ContentDialog from 'components/common/content.dialog/content.dialog'
import { Typography } from '@material-ui/core'
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
import LoaderButton from 'components/common/loader.button/loader.button';

const AddEventDialog = (props) => {
    const { open, eventName, isValidEventName, isEventAlreadyExist, execStatusCreateEvent } = props;
    const getErrorMessage = () => {
        if (isEventAlreadyExist === true) {
            return "Job already exists"
        }
        else if (isValidEventName === false) {
            return "Job Name is not valid"
        }
        else {
            return ""
        }
    }
    return (
        <div>
            <ContentDialog
                onClose={props.handleClose}
                dialogIsOpen={open}
                title={<div><Typography><b>Add new Job</b></Typography></div>}>
                <div className="p-4 pl-5">
                    <SiteLabelTextField
                        label="Job Name"
                        error={getErrorMessage()}
                        value={eventName}
                        onChange={(e) => props.onChange(e.target.value, "eventName")}
                        placeholder="Job Name" />
                </div>
                <div className="p-3 d-flex justify-content-center">
                    <LoaderButton
                        loading={execStatusCreateEvent.status === "PENDING" ? true : false}
                        variant="contained"
                        onClick={props.onEventAdd}
                        classNames="text-capitalize" >
                        Add new Job
                    </LoaderButton>
                </div>
            </ContentDialog>
        </div>
    )
}
export default AddEventDialog