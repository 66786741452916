import React, { useContext, useEffect, useState } from 'react'
import Form from './components/form';
import { useAuth } from '../../utils';
import moment from 'moment';
import { checkExecutionStatus } from 'components/common/util/util';
import { AppContext } from '../../app.container';
import { Skeleton } from "@material-ui/lab";
import { isValidPatientDetail } from './validator';
import { Column, LoadingAnimation } from '../../components';
import ValidationErrorDialog from './components/validation.error.dialog';

const ConfirmationForm = (props) => {
    const { match, userConfirmationDetail, confirmPatientDetailResponse } = props;
    const context = useContext(AppContext);
    const execStatusGetUserConfirmationDetail = checkExecutionStatus(context, "GET_USER_CONFIRMATION_DETAIL");
    const execStatusConfirmDetail = checkExecutionStatus(context, "CONFIRM_PATIENT_DETAIL");
    const { authStatus } = useAuth()
    const [state, setState] = useState({
        familyName: '',
        givenName: '',
        fullName: '',
        email: '',
        DOB: '',
        telephone: '',
        gender: '',

        postcode: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',

        isHomeAddressSameAsAboveAddress: false,
        homeAddressPostcode: '',
        homeAddressLine1: "",
        homeAddressLine2: "",
        homeAddressCity: '',
        homeAddressCountry: '',

        patientComments: '',
        nationality: '',
        healthNumber: '',
        documentType: '',
        documentCountry: '',
        documentNumber: '',
        documentExpiration: '',
        documentAuthority: '',
        isFitToFly: '',
        isProducerCheckFitToFly: false,
        showValidationErrorDialog: false,
    })

    useEffect(() => {
        if (userConfirmationDetail && userConfirmationDetail.size > 0) {
            setState({
                ...state, ...userConfirmationDetail.toJS(), gender: {
                    value: userConfirmationDetail.get('gender')
                },
                country: {
                    value: userConfirmationDetail.get('country'),
                    name: userConfirmationDetail.get('countryText')
                },
                homeAddressCountry: {
                    value: userConfirmationDetail.get('homeAddressCountry'),
                    name: userConfirmationDetail.get('homeAddressCountryText')
                },
                documentCountry: {
                    value: userConfirmationDetail.get('documentCountry'),
                    name: userConfirmationDetail.get('documentCountryText')
                },
                DOB: userConfirmationDetail.get('DOB') ? moment(userConfirmationDetail.get('DOB'), "DD/MM/YYYY") : '',
                isFitToFly: userConfirmationDetail.get('isProducerCheckFitToFly') === true ? "true" : (userConfirmationDetail.get('isFitToFly') === true ? "true" : "false"),
                documentExpiration: userConfirmationDetail.get('documentExpiration') ? moment(userConfirmationDetail.get('documentExpiration'), "DD/MM/YYYY") : ''
            })
        }
    }, [userConfirmationDetail])

    useEffect(() => {
        if (confirmPatientDetailResponse === true) {
            window.scrollTo(0, 0)
        }
    }, [confirmPatientDetailResponse])

    useEffect(() => {
        if (authStatus && authStatus !== 'Authorizing') {
            props.getUserConfirmationDetail({
                kitId: match.params.kitId
            })
        }
    }, [authStatus])

    const onChange = (value, name) => {
        if (name === "isFitToFly" && value === "false") {

            if (name === "isHomeAddressSameAsAboveAddress") {

                if (!value) {
                    console.log(value, name)
                    setState({
                        ...state, [name]: !value,
                        homeAddressLine1: state.addressLine1,
                        homeAddressLine2: state.addressLine2,
                        homeAddressCity: state.city,
                        homeAddressCountry: state.country,
                        homeAddressPostcode: state.postcode,
                        documentType: '',
                        documentCountry: '',
                        documentNumber: '',
                        documentExpiration: '',
                        documentAuthority: ''
                    })
                }
                else {
                    setState({
                        ...state, [name]: value,
                        documentType: '',
                        documentCountry: '',
                        documentNumber: '',
                        documentExpiration: '',
                        documentAuthority: ''
                    })
                }
            }
            else {
                setState({
                    ...state, [name]: value,
                    documentType: '',
                    documentCountry: '',
                    documentNumber: '',
                    documentExpiration: '',
                    documentAuthority: ''
                })
            }

        }
        else {
            if (name === "isHomeAddressSameAsAboveAddress") {
                if (!value) {
                    console.log(value, name)
                    setState({
                        ...state, [name]: !value,
                        homeAddressLine1: state.addressLine1,
                        homeAddressLine2: state.addressLine2,
                        homeAddressCity: state.city,
                        homeAddressCountry: state.country,
                        homeAddressPostcode: state.postcode,
                    })
                }
                else {
                    console.log(value, name)
                    setState({ ...state, [name]: !value })
                }
            }
            else {
                setState({ ...state, [name]: value })
            }
        }
    }

    const onSelect = (value, data, name) => {
        setState({ ...state, [name]: data })
    }

    const getSelectedAddress = (addressObj) => {
        if (addressObj.length > 0) {
            setState({
                ...state,
                [addressObj[0]?.key]: addressObj[0]?.value,
                [addressObj[1]?.key]: addressObj[1]?.value,
                [addressObj[2]?.key]: addressObj[2]?.value,
            })
        }
    }

    const confirmDetail = () => {
        let DOB = new Date(state.DOB);
        let documentExpiration = new Date(state.documentExpiration);
        DOB = state.DOB ? moment(DOB).format('DD/MM/YYYY').trim() : ""
        documentExpiration = state.documentExpiration ? moment(documentExpiration).format('DD/MM/YYYY').trim() : ""
        const validationResult = isValidPatientDetail({
            ...state
        })
        let newState = { ...state };
        delete newState['isValidemail']
        delete newState['isValidfullName']
        delete newState['isValidfamilyName']
        delete newState['isValidgivenName']
        delete newState['isValidDOB']
        delete newState['isValidtelephone']
        delete newState['isValidgender']
        delete newState['isValidaddressLine1']
        delete newState['isValidpostcode']
        delete newState['isValidcity']

        delete newState['isValidHomeAddressLine1']

        delete newState['isValidnationality']
        delete newState['isValiddocumentType']
        delete newState['isValiddocumentCountry']
        delete newState['isValiddocumentNumber']
        delete newState['isValiddocumentAuthority']
        delete newState['isValiddocumentExpiration']
        delete newState['allValid']
        delete newState['showValidationErrorDialog']


        if (state.serviceType === "PCR_DAY_2") {
            console.log('Function will hit for SBS Day 2')
        } else if (validationResult.allValid) {
            setState({
                ...state, showValidationErrorDialog: false,
                ...validationResult
            })
            props.confirmPatientDetail({
                kit: {
                    ...newState, DOB: DOB?.trim(),
                    documentExpiration: documentExpiration?.trim(),
                    isFitToFly: state?.isFitToFly === "true" ? true : false,

                    email: state?.email && state?.email?.toLowerCase()?.trim(),
                    telephone: state?.telephone?.trim()?.replaceAll(" ", ""),

                    fullName: `${state?.givenName?.trim()} ${state?.familyName?.trim()}`,
                    givenName: state?.givenName?.trim(),
                    familyName: state?.familyName?.trim(),
                    gender: state?.gender && state?.gender?.value,

                    country: state?.country && state?.country?.value,
                    countryText: state?.country && state?.country?.name?.trim(),
                    homeAddressPostcode: state?.homeAddressPostcode && state?.homeAddressPostcode?.trim(),
                    homeAddressLine1: state?.homeAddressLine1 && state?.homeAddressLine1?.trim(),
                    homeAddressLine2: state?.homeAddressLine2 && state?.homeAddressLine2?.trim(),
                    homeAddressCity: state?.homeAddressCity && state?.homeAddressCity?.trim(),
                    homeAddressCountry: state?.homeAddressCountry && state?.homeAddressCountry?.value,
                    homeAddressCountryText: state?.homeAddressCountry && state?.homeAddressCountry?.name?.trim(),
                    documentCountry: state?.documentCountry && state?.documentCountry?.value,
                    documentCountryText: state?.documentCountry && state?.documentCountry?.name?.trim(),
                    status: "INDIVIDUAL_CONFIRMED_TEST_DETAILS"
                }
            })
        }
        else {
            setState({
                ...state, showValidationErrorDialog: true,
                ...validationResult
            })
        }
    }

    const closeValidationErrorDialog = () => {
        setState({
            ...state, showValidationErrorDialog: false
        })
    }

    return (
        <div className="w-100">
            {
                authStatus === 'Authorizing' ?
                    <Column centered style={{ minHeight: '20rem' }}>
                        <LoadingAnimation orange />
                    </Column> :
                    <div>
                        <div className="w-100">
                            {
                                execStatusGetUserConfirmationDetail.status === "PENDING" ?
                                    <div className="d-flex p-3 flex-column align-items-center justify-content-center">
                                        <div className="w-75">
                                            <div className="d-flex p-5 justify-content-center font-weight-bold">
                                                Loading your details, please wait...
                                            </div>
                                            <Skeleton variant="text" width="100%" height={45} />
                                            <Skeleton variant="text" width="100%" height={45} />
                                            <Skeleton variant="text" width="100%" height={45} />
                                        </div>
                                    </div> :
                                    confirmPatientDetailResponse === true ?
                                        <div className="p-5">
                                            <h3 style={{ marginLeft: '35%' }}>  Test details successfully submitted. </h3>

                                            <div style={{
                                                marginLeft: '35%'
                                            }} className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Event / Shoots
                                                </div>
                                                <div className="pl-3">
                                                    {state?.eventName}
                                                </div>
                                            </div>
                                            <div style={{
                                                marginLeft: '35%'
                                            }} className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Time of test
                                                </div>
                                                <div className="pl-3">
                                                    {state?.preferredTime}
                                                </div>
                                            </div>
                                            <div style={{
                                                marginLeft: '35%'
                                            }} className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Producer Comments
                                                </div>
                                                <div className="pl-3">
                                                    {state?.producerComments}
                                                </div>
                                            </div>
                                            <div style={{
                                                marginLeft: '35%'
                                            }} className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Date of Test
                                                </div>
                                                <div className="pl-3">
                                                    {state?.dateOfTest ? state?.dateOfTest : ''}
                                                </div>
                                            </div>
                                            <div style={{
                                                marginLeft: '35%'
                                            }} className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Telephone
                                                </div>
                                                <div className="pl-3">
                                                    {state?.telephone ? state?.telephone : ''}
                                                </div>
                                            </div>
                                            {state?.country?.length > 0 &&
                                                <div style={{
                                                    marginLeft: '35%'
                                                }} className="d-flex p-2">
                                                    <div className="font-weight-bold">
                                                        Country
                                                    </div>
                                                    <div className="pl-3">
                                                        {state?.country?.length > 0 ? state?.country : ''}
                                                    </div>
                                                </div>
                                            }
                                            {/* <div style={{
                                                marginLeft: '35%'
                                            }} className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Nationality
                                                </div>
                                                <div className="pl-3">
                                                    {state?.nationality ? state?.nationality : ''}
                                                </div>
                                            </div> */}
                                            {state?.patientComments &&
                                                <div style={{
                                                    marginLeft: '35%'
                                                }} className="d-flex p-2">
                                                    <div className="font-weight-bold">
                                                        Your Comments
                                                    </div>
                                                    <div className="pl-3">
                                                        {state?.patientComments ? state?.patientComments : ''}
                                                    </div>
                                                </div>
                                            }
                                            <div style={{
                                                marginLeft: '35%'
                                            }} className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    PostCode
                                                </div>
                                                <div className="pl-3">
                                                    {state?.postcode ? state?.postcode : ''}
                                                </div>
                                            </div>
                                            <div style={{
                                                marginLeft: '35%'
                                            }} className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Test Address
                                                </div>
                                                <div className="pl-3">
                                                    {state?.addressLine1 ? state?.addressLine1 + state?.addressLine2 : ''}
                                                </div>
                                            </div>
                                            <div style={{
                                                marginLeft: '35%'
                                            }} className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    City
                                                </div>
                                                <div className="pl-3">
                                                    {state?.city ? state?.city : ''}
                                                </div>
                                            </div>
                                            <div style={{
                                                marginLeft: '35%'
                                            }} className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Email
                                                </div>
                                                <div className="pl-3">
                                                    {state?.email ? state?.email : ''}
                                                </div>
                                            </div>
                                            <div style={{
                                                marginLeft: '35%'
                                            }} className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Full Name
                                                </div>
                                                <div className="pl-3">
                                                    {state?.fullName ? state?.fullName : ''}
                                                </div>
                                            </div>


                                        </div> :
                                        <Form onSubmit={confirmDetail} onChange={onChange}
                                            execStatusGetUserConfirmationDetail={execStatusGetUserConfirmationDetail}
                                            execStatusConfirmDetail={execStatusConfirmDetail}
                                            onSelect={onSelect} getSelectedAddress={getSelectedAddress}
                                            {...props} {...state} />
                            }
                        </div>
                    </div>
            }
            {
                state.showValidationErrorDialog === true ?
                    <ValidationErrorDialog open={state.showValidationErrorDialog}
                        handleClose={closeValidationErrorDialog} {...state} /> : null
            }
        </div>
    )
}
export default ConfirmationForm