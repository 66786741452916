import React from 'react'
import { Row } from '../../../components'

const statusLabelStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '80px',
	height: '24px',
	borderRadius: '3px',
	textTransform: 'capitalize',
	textAlign: 'center',
}

const negativeStyle = {
	...statusLabelStyle,
	backgroundColor: 'lime',
}

const positiveStyle = {
	...statusLabelStyle,
	backgroundColor: '#ea0b2c',
}

const retestStyle = {
	...statusLabelStyle,
	backgroundColor: 'orange',
}

export const resolveKitResultStatusLabel = (status, resultStatus) => {
	if (status === 'ResultReady') {
		if (resultStatus === 'Positive') {
			return (
				<Row fillRemaining style={positiveStyle}>
					Positve
				</Row>
			)
		} else if (resultStatus === 'Negative') {
			return (
				<Row fillRemaining style={negativeStyle}>
					Negative
				</Row>
			)
		} else if (resultStatus === 'Retest') {
			return (
				<Row fillRemaining style={retestStyle}>
					Re-test
				</Row>
			)
		}
	} else {
		return (
			<Row desktopSize={1} tabletSize={1}>
				{status}
			</Row>
		)
	}
}

export const resolveSameDayKitResultStatusLabel = (status, resultStatus) => {
	if (status === 'ResultReady') {
		if (resultStatus === 'Positive') {
			return (
				<Row fillRemaining style={positiveStyle}>
					Positve
				</Row>
			)
		} else if (resultStatus === 'Negative') {
			return (
				<Row fillRemaining style={negativeStyle}>
					Negative
				</Row>
			)
		} else if (resultStatus === 'Inconclusive') {
			return (
				<Row fillRemaining style={retestStyle}>
					Re-test
				</Row>
			)
		}
	} else {
		return (
			<Row desktopSize={1} tabletSize={1}>
				{status}
			</Row>
		)
	}
}
