import React, { useState, useEffect } from 'react'
import { Row, Column, Body, TextInput, Form, Spacer, Paginator, Button } from '../../../components'
import { useMetrics } from '../../../utils/backend'
import { KitsLoadingIndicator } from './KitsLoadingIndicator'
import { formatShortDate } from '../../../utils'

const toPrettyStatus = (status) => {
	switch (status) {
		case 'AwaitingAssigment':
			return 'Assigning'
		case 'OrderFailed':
			return 'Failed'
		case 'KitDispatched':
			return 'Dispatched'
		case 'KitReceived':
			return 'Received'
		case 'ResultReady':
			return 'Tested'
		default:
			return status
	}
}

const KitRow = ({ kit }) => {
	console.log('KIT: ', kit)
	const { id, firstName, lastName, email, updatedAt, status, barcode } = kit
	const prettyDate = formatShortDate(new Date(updatedAt))

	return (
		<Column
			desktopSize={8}
			tabletSize={12}
			style={{ padding: '1.6rem 0', borderBottom: ' solid 1px #c7d4da' }}
		>
			<Row desktopSize={8} tabletSize={12}>
				<Row desktopSize={2} tabletSize={2}>
					<Body>{`${firstName} ${lastName}`}</Body>
				</Row>
				<Row desktopSize={2} tabletSize={4}>
					<Body small>{email}</Body>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<Body>{id.split('-').pop().toUpperCase()}</Body>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<Body>{barcode}</Body>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<Body>{prettyDate}</Body>
				</Row>
				<Row desktopSize={1} tabletSize={1}>
					<Body>{toPrettyStatus(status)}</Body>
				</Row>
			</Row>
			<Spacer spacing="s" />
		</Column>
	)
}

const NoKits = (props) => {
	return (
		<Row desktopSize={8} tabletSize={12} centered bordered>
			<h3>There are currently no statuses</h3>
		</Row>
	)
}

const KitsStatusesList = () => {
	const defaultFilter = { page: 0 }

	const [emailInput, setEmailInput] = useState('')
	const [filters, setFilters] = useState(defaultFilter)
	const [kits, setKits] = useState(null)
	const [kitsLoading, setKitsLoading] = useState(false)
	const [page, setPage] = useState(0)
	const [numKits, setNumKits] = useState(0)

	const { queryAllKits } = useMetrics()

	const isFiltersEnabled = true
	const hasKits = !kits || kits.length === 0
	const isFiltersSelected = isFiltersEnabled && typeof filters.email === 'string'

	const handleOnEmailSubmit = () => {
		setFilters({ email: emailInput })
		setEmailInput('')
	}

	useEffect(() => {
		setKitsLoading(true)
		queryAllKits(filters)
			.then((response) => {
				const { kits, numKits, page } = response.data
				
				setKits(kits)
				setPage(page)
				setNumKits(numKits)
				setKitsLoading(false)
			})
			.catch((error) => {
				console.error(error)
				setKitsLoading(false)
			})
	}, [queryAllKits, filters])

	if (kitsLoading === true) {
		return <KitsLoadingIndicator />
	}

	return (
		<>
			{isFiltersEnabled && (
				<Form onSubmit={handleOnEmailSubmit}>
					<Row desktopSize={8} tabletSize={12} spaced style={{ alignItems: 'flex-end' }}>
						{isFiltersSelected && (
							<Button secondary onClick={() => setFilters({ page: 0 })}>
								Clear Filters
							</Button>
						)}
						<Spacer spacing="m" />
						<TextInput
							placeholder="alice@email.com"
							value={emailInput}
							setter={setEmailInput}
							desktopSize={3}
							tabletSize={4}
						/>
					</Row>
				</Form>
			)}
			<Paginator
				desktopSize={8}
				tabletSize={12}
				page={page}
				pageSize={50}
				total={numKits}
				maxPages={8}
			/>
			<Row desktopSize={8} tabletSize={12} noWrap>
				<Row desktopSize={2} tabletSize={2}>
					<h5>Name</h5>
				</Row>
				<Row desktopSize={2} tabletSize={4}>
					<h5>email</h5>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<h5>Test Kit Id</h5>
				</Row>
				<Row desktopSize={1} tabletSize={1}>
					<h5>SBS Kit Id</h5>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<h5>Last update</h5>
				</Row>
				<Row desktopSize={1} tabletSize={1}>
					<h5>Status</h5>
				</Row>
			</Row>
			<Column desktopSize={8} tabletSize={12}>
				{hasKits ? <NoKits /> : kits.map((kit) => <KitRow kit={kit} key={kit.id} />)}
			</Column>
		</>
	)
}

export { KitsStatusesList }
