import { useState } from 'react'
// import { useParams } from 'react-router-dom'

const unsafeWindowScroll = () => {
	try {
		window.scrollTo(0, 0)
	} catch (error) {
		console.error('useWorkflow:unsafeWindowScroll', error)
	}
}

export const useWorkflow = (steps, defaultFirstStepIndex) => {
	const [step, setStep] = useState(steps[defaultFirstStepIndex])

	const gotos = steps.map((stepName) => {
		return () => {
			setStep(stepName)
			unsafeWindowScroll()
			//console.log("to step", stepName)
		}
	})

	const resetWorkflow = (step = defaultFirstStepIndex) => {
		setStep(steps[step])
		unsafeWindowScroll()
	}

	return [step, ...gotos, resetWorkflow]
}

// export const useRoutedWorkflow = (steps, firstStepIndex) => {
// 	const
// }
