import React, { useEffect, useState } from 'react'
import { sub } from 'date-fns'
import { Row, Column, LoadingAnimation, Body, Spacer } from '../../../components'
import { useMetrics } from '../../../utils/backend'
import { PieChart, LegendRow } from '../../../components/charts'
import { VictoryChart, VictoryBar, VictoryGroup } from 'victory'
import { formatShortDate } from '../../../utils'

const fillColor = (color) => () => color

const today = new Date(new Date().toISOString().split('T').shift())
const days = Array.from({ length: 7 }).map((_, index) => sub(today, { days: index }).getTime())

const toBarChartGroups = (timeseries) => {
	return days.reduce(
		(groups, time) => {
			const kits = Object.values(timeseries[time] || {}).sort((kitA, kitB) => kitA.date - kitB.date)
			const orderedKits = kits.filter((kit) => kit.status === 'Ordered')
			const dispatchedKits = kits.filter((kit) => kit.status === 'KitDispatched')
			const receivedKits = kits.filter((kit) => kit.status === 'KitReceived')
			const negativeKits = kits.filter((kit) => kit.resultStatus === 'Negative')
			const positiveKits = kits.filter((kit) => kit.resultStatus === 'Positive')
			const retestKits = kits.filter((kit) => kit.resultStatus === 'Retest')

			groups.ordered.push({ x: formatShortDate(new Date(time)), y: orderedKits.length })
			groups.dispatched.push({ x: formatShortDate(new Date(time)), y: dispatchedKits.length })
			groups.received.push({ x: formatShortDate(new Date(time)), y: receivedKits.length })
			groups.negative.push({ x: formatShortDate(new Date(time)), y: negativeKits.length })
			groups.positive.push({ x: formatShortDate(new Date(time)), y: positiveKits.length })
			groups.retest.push({ x: formatShortDate(new Date(time)), y: retestKits.length })

			return groups
		},
		{ ordered: [], dispatched: [], received: [], negative: [], positive: [], retest: [] }
	)
}

const OverviewCharts = (props) => {
	const { queryOrganisationMetrics } = useMetrics()
	const [isDataLoading, setIsDataLoading] = useState(false)
	const [chartsData, setChartsData] = useState({
		testResultsChartData: [],
		completionChartData: [],
		totalKits: 0,
		totalReady: 0,
		testResultsStats: {
			negative: 0,
			positive: 0,
			retest: 0,
			awaiting: 0,
		},
		completionStats: {
			awaiting: 0,
			provided: 0,
		},
		timeseries: {},
	})

	const {
		testResultsChartData,
		completionChartData,
		totalKits,
		totalReady,
		testResultsStats,
		completionStats,
		timeseries,
	} = chartsData

	const barChartData = toBarChartGroups(timeseries)

	useEffect(() => {
		setIsDataLoading(true)
		queryOrganisationMetrics()
			.then((response) => {
				const {
					numDispatched,
					numKits,
					numNegative,
					numOrdered,
					numPositive,
					numReady,
					numReceived,
					numRetest,
					timeseries,
				} = response.data

				const testResultsStats = {
					negative: numNegative,
					positive: numPositive,
					retest: numRetest,
					awaiting: numOrdered + numDispatched + numReceived,
				}

				const completionStats = {
					awaiting: numOrdered + numDispatched + numReceived,
					provided: numReady,
				}

				const testResultsChartData = Object.entries(testResultsStats).map(([key, value]) => ({
					x: key,
					y: value,
				}))

				const completionChartData = Object.entries(completionStats).map(([key, value]) => ({
					x: key,
					y: value,
				}))

				setChartsData({
					testResultsChartData,
					completionChartData,
					totalKits: numKits,
					totalReady: numReady,
					completionStats,
					testResultsStats,
					timeseries,
				})

				setIsDataLoading(false)
			})
			.catch((error) => {
				setIsDataLoading(false)
				console.error(error)
			})
	}, [queryOrganisationMetrics])

	return (
		<>
			<Row desktopSize={8} tabletSize={12}>
				<Row bordered desktopSize={4} tabletSize={6}>
					<Column fillRemaining>
						<h5>TEST KIT COMPLETION</h5>
						{isDataLoading ? (
							<LoadingAnimation />
						) : (
							<Row fillRemaining noWrap>
								<PieChart data={completionChartData} colors={['#067ab8', '#81ccf5']} />
								<Spacer spacing="m" />
								<Column fillRemaining>
									<Body small>Test-kits:</Body>
									<Body>{totalKits} kits</Body>
									<Spacer spacing="s" />
									<LegendRow color="#067ab8" value={completionStats.awaiting} label="Awaiting" />
									<LegendRow
										color="#81ccf5"
										value={completionStats.provided}
										label="Results provided"
									/>
								</Column>
							</Row>
						)}
					</Column>
				</Row>
				<Row bordered desktopSize={4} tabletSize={6}>
					<Column fillRemaining>
						<h5>RECEIVED RESULTS</h5>
						{isDataLoading ? (
							<LoadingAnimation />
						) : (
							<Row fillRemaining noWrap>
								<PieChart
									data={testResultsChartData}
									colors={['#07bfc1', '#f40e32', '#ff7500', '#067ab8']}
								/>
								<Spacer spacing="m" />
								<Column fillRemaining>
									<Body small>Test-kits:</Body>
									<Body>{totalReady} results</Body>
									<Spacer spacing="s" />
									<LegendRow color="#07bfc1" value={testResultsStats.negative} label="Negative" />
									<LegendRow color="#f40e32" value={testResultsStats.positive} label="Positive" />
									<LegendRow color="#ff7500" value={testResultsStats.retest} label="Retest" />
									<LegendRow color="#067ab8" value={testResultsStats.awaiting} label="Awaiting" />
								</Column>
							</Row>
						)}
					</Column>
				</Row>
			</Row>
			<Spacer spacing="m" />
			<Column bordered desktopSize={8} tabletSize={12}>
				<h5>TEST-KITS OVERVIEW</h5>
				<Row centered style={{ height: '25rem' }} fillRemaining>
					<VictoryChart width={700} height={220}>
						<VictoryGroup colorScale={'qualitative'} offset={25}>
							<VictoryBar
								data={barChartData.ordered.reverse()}
								style={{ data: { fill: fillColor('#067ab8') } }}
								labels={({ datum }) => datum.y}
							/>
							<VictoryBar
								data={barChartData.dispatched.reverse()}
								style={{ data: { fill: fillColor('#81ccf5') } }}
								labels={({ datum }) => datum.y}
							/>
						</VictoryGroup>
					</VictoryChart>
				</Row>
			</Column>
			<Spacer spacing="m" />
			<Column bordered desktopSize={8} tabletSize={12}>
				<h5>TEST-KITS RESULTS</h5>
				<Row centered style={{ height: '25rem' }} fillRemaining>
					<VictoryChart width={700} height={220}>
						<VictoryGroup colorScale={'qualitative'} offset={25}>
							<VictoryBar
								data={barChartData.negative.reverse()}
								style={{ data: { fill: fillColor('#07bfc1') } }}
							/>
							<VictoryBar
								data={barChartData.positive.reverse()}
								style={{ data: { fill: fillColor('#f40e32') } }}
							/>
							<VictoryBar
								data={barChartData.retest.reverse()}
								style={{ data: { fill: fillColor('ff7500') } }}
							/>
						</VictoryGroup>
					</VictoryChart>
				</Row>
			</Column>
		</>
	)
}

export { OverviewCharts }
