const ENV = "QA"; // DEV

const DEV_URL = "http://localhost";
const QA_URL = "http://localhost";
const UAT_URL ="http://localhost";

const getServerUrl = (ENV) => {
    switch (ENV) {
        case "DEV":
            return DEV_URL;
        case "QA":
            return QA_URL;
        case "UAT":
            return UAT_URL;
    }
}

export const SERVER_URL = getServerUrl(ENV);
export const SERVER_PORT = "";
