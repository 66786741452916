export const themeProps = {
  MuiButton: {
      color: "primary",
      variant : "contained"
  },
  MuiCheckbox: {
    color : "primary"
  },
  MuiTextField: {
      variant: "outlined",
      fullWidth:true
  }
}
