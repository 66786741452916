import React, { useState, useEffect } from 'react'
import ContentDialog from 'components/common/content.dialog/content.dialog'
import { IsMobileWidth } from "components/common/util/util";
import clsx from 'clsx';
// import CustomCalender from 'components/common/custom.calender/custom.calender';
import Calender from 'components/common/custom.calender/Calender';
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
import MaterialAutoComplete from 'components/common/material.autocomplete/material.autocomplete';
import { COUNTRIES_LIST } from 'components/common/util/util';
import MaterialAutoCompleteTypeable from 'components/common/material.autocomplete.typeable/material.autocomplete.typeable';
import { getPastDate } from 'components/common/util/util';
import { SERVICE_TYPES } from 'components/common/util/util';
import { PREFERRED_TIMES } from 'components/common/util/util';
import ReactTelInput from 'react-telephone-input';
import 'react-telephone-input/css/default.css'
import { COUNTRY_CODES } from 'components/common/util/util';
import { isValidLastMinuteTest } from './validator';
import { sortableUid, useAdmin, useOrdering } from '../../../utils';
import { formatCurrency, formatDate } from '../../../utils/formatters';
import { Checkbox, FormControlLabel } from "@material-ui/core";
import moment from 'moment';
import { lastWord, PostCodeDropdown, PostCodeHandle } from "../../../components/common/postcode";
import { Col } from "reactstrap";
import { ETHNICITY_LIST, TRAVEL_METHOD_LIST, VACCINATION_STATUS } from '../../../components/common/util/util';

const AddLastMinuteTestDialog = (props) => {
    const [inputPostValue, setInputPostValue] = useState("");
    const [homeAddressPostCodeAtr, setHomeAddressPostCodeAtr] = useState({
        inputPostValue: "",
        resValue: "",
        errorValue: [],
        postcodeDropdown: false
    })
    const [resValue, setResValue] = useState('');
    const [errorValue, setErrorValue] = useState([]);
    const [postcodeDropdown, setPostcodeDropdown] = useState(false);

    const [ethnicityList, setEthnicityList] = useState(undefined);
    const [travelMethodList, setTravelMethodList] = useState(undefined);
    const [vaccinationList, setVaccinationList] = useState(undefined);
    const [sbsCountriesList, setSbsCountriesList] = useState(undefined);

    const [adressValue, setAddressValue] = useState({});
    const [propTarget, setPropTarget] = useState({});

    const [state, setState] = useState({
        open: "",
        selectedOrg: undefined,
        selectedEvent: undefined,
        dateOfTest: new Date(),
        preferredTime: undefined,
        serviceType: SERVICE_TYPES[0],

        postcode: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: {
            name: "United Kingdom",
            value: "GBR"
        },

        familyName: '',
        givenName: '',
        documentNumber: '',
        ethnicity: '',
        vaccinationStatus: '',

        email: '',
        telephone: '',
        arrivalDate: '',
        travelMethod: '',
        vesselNumber: '',
        departureDate: '',
        departureCountry: '',
        swabTaken: '',

        homeAddressLine1: "",
        homeAddressLine2: "",
        homeAddressPostcode: "",
        homeAddressCity: "",
        isHomeAddressSameAsAboveAddress: false,

    })
    const { organisations, execStatusGetOrganisations, events, execStatusGetEvents, open, execStatusAddLastMinuteTest } = props;

    const { customerData } = useOrdering();
    const { getAllSbsEthnicity, getAllSbsTravelMethods, getAllSbsVaccinationStatus, getAllSbsCountries } = useAdmin();
    // console.log(execStatusGetEvents)
    const mobileWidth = IsMobileWidth();
    let contryList = COUNTRY_CODES.map((cnt) => {
        return { name: cnt[0], iso2: cnt[1], dialCode: cnt[2], priority: 0, format: cnt[3] }
    })
    let orgs = []
    // orgs.push(
    //     {
    //         name: 'All',
    //         value: ''
    //     }
    // )
    organisations && organisations.map((org) => {
        orgs.push({
            name: org.get('organisationId'),
            value: org.get('organisationId')
        })
    })
    let evts = events && events.size > 0 ? [] : undefined;

    events && events.map((evnt) => {
        evts.push({
            name: evnt.get('eventName'),
            value: evnt.get('eventId')
        })
    })

    const addLastMinuteTest = () => {
        const validationResult = isValidLastMinuteTest({ ...state });
        if (validationResult.allValid === true) {
            const numKits = "1";
            const subTotal = numKits * 120;
            const vat = subTotal * 0.2
            // disable adding vat to total
            // const total = subTotal + vat
            const total = subTotal;

            const numberOfKitsText = "1 Kit"
            const orderType = 'same-day-test';
            const orderTypeText = "Same day order test";

            const subTotalText = formatCurrency(subTotal)
            const vatText = formatCurrency(vat)
            const totalText = formatCurrency(total)

            const orderDatetime = new Date()
            const orderDate = orderDatetime.toISOString().split('T')[0]
            const orderDateText = formatDate(orderDatetime)

            let patient = {
                filledBy: 'BEACON_AMDIN',
                using: 'LAST_MINUTE_TESTS',

                dateOfTest: state.dateOfTest ? moment(new Date(state?.dateOfTest))?.format('DD/MM/YYYY')?.trim() : '',
                serviceType: state?.serviceType && state?.serviceType?.value,
                serviceTypeText: state?.serviceType && state?.serviceType?.serviceTypeText?.trim(),

                postcode: state?.postcode?.trim(),
                addressLine1: state?.addressLine1?.trim(),
                addressLine2: state?.addressLine2?.trim(),
                city: state?.city?.trim(),
                country: state?.country && state?.country?.value,
                countryText: state?.country && state?.country?.name?.trim(),


                //orderRecivedDate: moment(new Date())?.format('DD/MM/YYYY'),
                //DOB: state?.DOB ? moment(new Date(state?.DOB))?.format('DD/MM/YYYY') : '',
                //gender: state?.gender && state?.gender?.value,

                familyName: state?.familyName?.trim(),
                givenName: state?.givenName?.trim(),

                email: state?.email && state?.email?.toLowerCase()?.trim(),
                telephone: state?.telephone?.trim()?.replaceAll(" ", ""),
                requestedBy: customerData?.displayName,
                homeAddressLine1: state?.homeAddressLine1?.trim(),
                homeAddressLine2: state?.homeAddressLine2?.trim(),
                homeAddressPostcode: state?.homeAddressPostcode?.trim(),
                homeAddressCity: state?.homeAddressCity?.trim(),
                homeAddressCountry: state?.homeAddressCountry && state?.homeAddressCountry?.value,
                homeAddressCountryText: state?.homeAddressCountry && state?.homeAddressCountry?.name?.trim(),


                //producerComments: state?.producerComments && state?.producerComments?.trim(),
                // documentCountry: state?.documentCountry && state?.documentCountry?.value,
                // documentCountryText: state?.documentCountry && state?.documentCountry?.name,
                price: state?.serviceType && state?.serviceType?.price,
                eventId: state?.selectedEvent && state?.selectedEvent?.value,
                eventName: state?.selectedEvent && state?.selectedEvent?.name && state?.selectedEvent?.name?.trim(),
                courierCost: state?.serviceType && state?.serviceType?.courierCost,
                currency: state?.serviceType && state?.serviceType?.currency,
                currencySymbol: state?.serviceType && state?.serviceType?.currencySymbol,
                // nationality: state?.nationality,
                // healthNumber: state?.healthNumber,
                // documentType: state?.documentType,
                // documentNumber: state?.documentNumber,
                //documentExpiration: state?.documentExpiration ? moment(new Date(state?.documentExpiration))?.format('DD/MM/YYYY') : '',
                //documentAuthority: state?.documentAuthority
            }

            if (state?.serviceType?.value === "NEXT_DAY" || state?.serviceType?.value === "SAME_DAY") {
                patient = {
                    ...patient,
                    fullName: `${state?.givenName} ${state?.familyName}`,
                    preferredTime: state?.preferredTime && state?.preferredTime?.value,
                    preferredFromTime: state?.preferredTime && state?.preferredTime?.preferredFromTime,
                    preferredToTime: state?.preferredTime && state?.preferredTime?.preferredToTime,
                }
            }
            else if (state?.serviceType?.value === "PCR_DAY_2") {
                patient = {
                    ...patient,
                    documentNumber: state?.documentNumber,
                    ethnicity: state?.ethnicity?.value,
                    ethnicityText: state?.ethnicity?.name,
                    vaccinationStatus: state?.vaccinationStatus?.value,
                    vaccinationStatusText: state?.vaccinationStatus?.name,

                    arrivalDate: state?.arrivalDate ? moment(new Date(state?.arrivalDate))?.format('DD/MM/YYYY') : '',
                    vesselNumber: state?.vesselNumber,
                    travelMethod: state?.travelMethod?.value,
                    travelMethodText: state?.travelMethod?.name,
                    departureDate: state?.departureDate ? moment(new Date(state?.departureDate))?.format('DD/MM/YYYY') : '',
                    departureCountry: state?.departureCountry && state?.departureCountry?.value,
                    departureCountryText: state?.departureCountry && state?.departureCountry?.name,
                    swabTaken: state?.swabTaken,

                    selfIsolationAddressCountry: "",
                    selfIsolationAddressCountryText: ""

                }
            }

            let order = {
                orderUid: sortableUid(),
                orderDate,
                createdAt: Date.now(),
                organisationId: state.selectedOrg && state.selectedOrg.value.trim(),
                orderType,
                numKits,
                subTotal: subTotalText,
                vat: vatText,
                total: totalText,
                partnerLab: state.serviceType.value === "PCR_DAY_2" ? "SBS" : "catalyst",
                patients: [patient],
                confirmationDisplayText: {
                    subTotalText,
                    vatText,
                    totalText,
                    numberOfKitsText,
                    orderTypeText,
                    orderDateText
                }
            }

            props.addLastMinuteTest(order)
        }
        else {
            alert("Please fill all the required fields")
        }
    }

    const onChange = (value, name) => {
        if (name === "isHomeAddressSameAsAboveAddress") {

            if (!value) {
                console.log(value, name)
                setState({
                    ...state, [name]: !value, homeAddressLine1: state.addressLine1, homeAddressLine2: state.addressLine2,
                    homeAddressPostcode: state.postcode, homeAddressCity: state.city, homeAddressCountry: state.country
                })
            }
            else {
                console.log(value, name)
                setState({ ...state, [name]: !value })
            }
        }
        else {
            setState({ ...state, [name]: value })
        }
    }

    const onSelect = (value, data, name) => {
        if (name === "selectedOrg") {
            props.getEvents({
                orgId: value
            });
            setState({ ...state, [name]: data, selectedEvent: undefined })
        }
        else
            setState({ ...state, [name]: data })
    }

    const handlePostCodeInputClick = (event, evnetTargetName) => {

        console.log(state.postcode)
        if (evnetTargetName === "testAddressPostCode") {
            PostCodeHandle(state.postcode, (item) => {
                setPostcodeDropdown(item?.dropdown);
                setErrorValue(item?.errorValue);
                setResValue(item?.resValue);
            });
        }
        else if (evnetTargetName === "homeAddressPostcode") {
            PostCodeHandle(state.homeAddressPostcode, (item) => {
                setHomeAddressPostCodeAtr({
                    ...homeAddressPostCodeAtr,
                    postcodeDropdown: item?.dropdown,
                    errorValue: item?.errorValue,
                    resValue: item?.resValue
                })
            });
        }
    }

    const addressDetails = (item, evnetTargetName) => {
        if (evnetTargetName === "testAddressPostCode") {
            setState({ ...state, city: lastWord(item?.Place), addressLine1: item?.StreetAddress, addressLine2: item?.addressLine2 })
            setPostcodeDropdown(false);
        }
        else if (evnetTargetName === "homeAddressPostcode") {
            setState({ ...state, homeAddressCity: lastWord(item?.Place), homeAddressLine1: item?.StreetAddress, homeAddressLine2: item?.addressLine2 })
            setHomeAddressPostCodeAtr({
                ...homeAddressPostCodeAtr,
                postcodeDropdown: false
            })
        }
    }

    useEffect(() => {
        if (state.postcode !== state.homeAddressPostcode || state.addressLine1 !== state.homeAddressLine1 || state.addressLine2 != state.homeAddressLine2 || state.city !== state.homeAddressCity || state.country !== state.homeAddressCountry) {
            setState({ ...state, isHomeAddressSameAsAboveAddress: false })
        }
    }, [state.postcode, state.addressLine1, state.addressLine2, state.city, state.country, state.homeAddressPostcode, state.homeAddressLine1, state.homeAddressLine2, state.homeAddressCity, state.homeAddressCountry])

    useEffect(() => {
        getAllSbsEthnicity()
            .then((response) => {
                const updatedEthnicity = [];
                const res = response?.data?.map((item) => {
                    updatedEthnicity.push({ name: item?.name, value: item?.pheCode })
                })
                setEthnicityList(updatedEthnicity)
            })
            .catch((error) => { })

        getAllSbsTravelMethods()
            .then((response) => {
                console.log('Response travel method BE', response);

                const updatedTravelMethod = [];
                const res = response?.data?.map((item) => {
                    updatedTravelMethod.push({ name: item?.name, value: item?.travelMethodId.toString() })
                })
                setTravelMethodList(updatedTravelMethod)
            })
            .catch((error) => { })

        getAllSbsVaccinationStatus()
            .then((response) => {
                console.log('response vacc BE', response);
                const updatedVaccination = [];
                const res = response?.data?.map((item) => {
                    updatedVaccination.push({ name: item?.name, value: item?.vaccinationStatusId.toString() })
                })
                setVaccinationList(updatedVaccination)
            })
            .catch((error) => { })

        getAllSbsCountries()
            .then((response) => {
                const updatedCountries = [];
                const res = response?.data?.map((item) => {
                    updatedCountries.push({ name: item?.name, value: item?.iso3Code })
                })
                setSbsCountriesList(updatedCountries)
            })
            .catch((error) => { })

    }, []);

    return (
        <div>
            <ContentDialog
                onClose={props.handleClose}
                dialogIsOpen={open}
                showActions={true}
                loading={execStatusAddLastMinuteTest.status === "PENDING" ? true : false}
                successText="Add last minutes test"
                onSuccess={addLastMinuteTest}
            //title={<div><Typography><b>Patient Detail</b></Typography></div>}
            >
                <div className='p-2'>
                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <MaterialAutoCompleteTypeable
                            startAdornment="Organisation"
                            execStatus={execStatusGetOrganisations}
                            //disabled={execStatusGetKits.status === "PENDING" ? true : false}
                            selectedItem={state?.selectedOrg}
                            required={true}
                            onSelect={(value, data) => onSelect(value, data, "selectedOrg")}
                            placeholder="Select organisation"
                            data={orgs}
                        />
                    </div>
                    {
                        state.selectedOrg && state.selectedOrg.value ?
                            <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                                <MaterialAutoComplete
                                    startAdornment={"Jobs"}
                                    execStatus={execStatusGetEvents}
                                    //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                    selectedItem={state.selectedEvent}
                                    onSelect={(value, data) => onSelect(value, data, 'selectedEvent')}
                                    placeholder="Select Job"
                                    data={evts}
                                />
                            </div> : null
                    }
                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <Calender
                            label="Test Date"
                            value={state.dateOfTest}
                            placeholder="Test Date"
                            minDate={getPastDate(0)}
                            required={true}
                            getSelectedDate={(date) => { onChange(date, "dateOfTest") }}
                        />
                    </div>
                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <MaterialAutoComplete
                            startAdornment="Service Type"
                            selectedItem={state.serviceType}
                            required={true}
                            //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                            onSelect={(value, data) => onSelect(value, data, "serviceType")}
                            placeholder="Select Service Type"
                            data={SERVICE_TYPES}
                        />
                    </div>
                    {
                        state.serviceType.value !== "PCR_DAY_2" &&
                        <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                            <MaterialAutoComplete
                                startAdornment="Preferred Time"
                                selectedItem={state.preferredTime}
                                //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                placeholder="Select Preferred Time"
                                required={true}
                                onSelect={(value, data) => onSelect(value, data, "preferredTime")}
                                data={PREFERRED_TIMES}
                            />
                        </div>
                    }


                    <div className="position-relative">
                        <SiteLabelTextField label="Post Code"
                            value={state.postcode}
                            required={true}
                            onKeyDown={(e) => { if (e.key === 'Enter') { handlePostCodeInputClick(e, "testAddressPostCode") } }}
                            onChange={(e) => { setState({ ...state, postcode: e.target.value }) }}
                            // onChange={(e) => props.onChange(e.target.value, "postcode")}
                            placeholder="Please enter postcode" />
                        <div className="position-absolute "
                            onClick={(e) => { handlePostCodeInputClick(e, "testAddressPostCode") }}
                            style={{ top: '36px', right: '15px', display: 'flex', alignItems: 'center' }}>
                            {postcodeDropdown &&
                                <i onClick={(e) => { e.stopPropagation(); setPostcodeDropdown(false); }}
                                    className="fas fa-times-circle"
                                    style={{ marginRight: '10px', color: 'darkred', fontSize: '14px', cursor: 'pointer' }}
                                >
                                </i>

                            }
                            <button type="button" className="btn btn-primary">Search</button>
                        </div>

                        {postcodeDropdown &&
                            <PostCodeDropdown
                                absoluteLeft={false}
                                errorValue={errorValue}
                                resValue={resValue}
                                addressDetails={(e) => addressDetails(e, "testAddressPostCode")}
                            />
                        }
                    </div>

                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <SiteLabelTextField
                            required={true}
                            label="Test Address Line 1" value={state.addressLine1} onChange={(e) => onChange(e.target.value, "addressLine1")} placeholder="Address Line 1" />
                    </div>
                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <SiteLabelTextField
                            label="Test Address Line 2" value={state.addressLine2} onChange={(e) => onChange(e.target.value, "addressLine2")} placeholder="Address Line 2" />
                    </div>
                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <SiteLabelTextField
                            required={true}
                            label="Test City" value={state.city} onChange={(e) => onChange(e.target.value, "city")} placeholder="Test City" />
                    </div>

                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <MaterialAutoCompleteTypeable
                            startAdornment="Test Country"
                            required={true}
                            selectedItem={state.country}
                            onSelect={(value, data) => onSelect(value, data, "country")}
                            placeholder="Select Country from a list"
                            data={COUNTRIES_LIST}
                        />
                    </div>


                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <SiteLabelTextField
                            required={true}
                            label="First Name"
                            value={state.givenName} onChange={(e) => onChange(e.target.value, "givenName")} placeholder="First Name" />
                    </div>
                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <SiteLabelTextField
                            required={true}
                            label="Last Name"
                            value={state.familyName} onChange={(e) => onChange(e.target.value, "familyName")} placeholder="Last Name" />
                    </div>

                    {
                        state.serviceType.value === "PCR_DAY_2" &&
                        <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                            <SiteLabelTextField
                                label="Passport/ID Number"
                                required={true}
                                value={state.documentNumber} onChange={(e) => onChange(e.target.value, "documentNumber")} placeholder="Passport/ID Number" />
                        </div>
                    }

                    {
                        (state.serviceType.value === "PCR_DAY_2" && ethnicityList !== undefined) &&
                        <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                            <MaterialAutoComplete
                                required={true}
                                startAdornment="Ethnicity"
                                selectedItem={state.ethnicity}
                                onSelect={(value, data) => onSelect(value, data, "ethnicity")}
                                placeholder="Select Ethnicity"
                                data={ethnicityList}
                            />
                        </div>
                    }

                    {
                        (state.serviceType.value === "PCR_DAY_2" && vaccinationList !== undefined) &&
                        <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                            <MaterialAutoComplete
                                required={true}
                                startAdornment="Vaccination Status"
                                selectedItem={state.vaccinationStatus}
                                onSelect={(value, data) => onSelect(value, data, "vaccinationStatus")}
                                placeholder="Select Vaccination Status"
                                data={vaccinationList}
                            />
                        </div>
                    }

                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <SiteLabelTextField
                            label="Email"
                            required={true}
                            value={state.email} onChange={(e) => onChange(e.target.value, "email")} placeholder="Email" />
                    </div>

                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <div className="font-weight-bold pt-2 pb-2">
                            <span style={{ color: '#DC3545', fontWeight: 'bold' }}>*</span>Telephone
                        </div>
                        <ReactTelInput
                            defaultCountry="gb"
                            preferredCountries={['gb', 'us']}
                            value={state.telephone}
                            onlyCountries={contryList}
                            onChange={(telNumber, selectedCountry) => onChange(telNumber, "telephone")}
                            flagsImagePath={`${process.env.PUBLIC_URL}/flags.png`}
                        />
                    </div>

                    {
                        state.serviceType.value === "PCR_DAY_2" &&
                        <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                            <Calender
                                label="Arrival Date"
                                required={true}
                                value={state.arrivalDate}
                                placeholder="Select Date of Arrival"
                                minDate={state.departureDate ? state.departureDate : getPastDate(0)}
                                getSelectedDate={(date) => { onChange(date, "arrivalDate") }}
                            />
                        </div>
                    }
                    {
                        (state.serviceType.value === "PCR_DAY_2" && travelMethodList !== undefined) &&
                        <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                            <MaterialAutoComplete
                                required={true}
                                startAdornment="Travel Method"
                                selectedItem={state.travelMethod}
                                onSelect={(value, data) => onSelect(value, data, "travelMethod")}
                                placeholder="Select Travel Method"
                                data={travelMethodList}
                            />
                        </div>
                    }
                    {
                        state.serviceType.value === "PCR_DAY_2" &&
                        <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                            <SiteLabelTextField
                                label="Flight/Coach/Vessel Number"
                                required={true}
                                value={state.vesselNumber} onChange={(e) => onChange(e.target.value, "vesselNumber")} placeholder="Flight/Coach/Vessel Number" />
                        </div>
                    }
                    {
                        state.serviceType.value === "PCR_DAY_2" &&
                        <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                            <Calender
                                label="Departure Date "
                                required={true}
                                value={state.departureDate}
                                placeholder="Select Date of Departure"
                                maxDate={state.arrivalDate}
                                getSelectedDate={(date) => { onChange(date, "departureDate") }}
                            />
                        </div>
                    }
                    {
                        state.serviceType.value === "PCR_DAY_2" &&
                        <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                            <MaterialAutoCompleteTypeable
                                startAdornment="Departure Country "
                                required={true}
                                selectedItem={state.departureCountry}
                                onSelect={(value, data) => onSelect(value, data, "departureCountry")}
                                placeholder="Select Country from a list"
                                data={state.serviceType.value === "PCR_DAY_2" ? sbsCountriesList : COUNTRIES_LIST}
                            />
                        </div>
                    }

                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <FormControlLabel
                            className="checkbox-size mb-0"
                            control={
                                <Checkbox
                                    size='medium'
                                    checked={state.isHomeAddressSameAsAboveAddress}
                                    onChange={(e) => onChange(state.isHomeAddressSameAsAboveAddress, "isHomeAddressSameAsAboveAddress")}
                                    name="isHomeAddressSameAsAboveAddress"
                                    color="secondary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }} />

                            }
                            label="Home address same as test address"
                        />
                    </div>

                    <div className="position-relative">
                        <SiteLabelTextField label="Home Post Code"
                            value={state.homeAddressPostcode}
                            required={false}
                            onKeyDown={(e) => { if (e.key === 'Enter') { handlePostCodeInputClick(e, "homeAddressPostcode") } }}
                            onChange={(e) => { setState({ ...state, homeAddressPostcode: e.target.value }) }}
                            placeholder="Please enter postcode" />
                        <div className="position-absolute "
                            onClick={(e) => { handlePostCodeInputClick(e, "homeAddressPostcode") }}
                            style={{ top: '36px', right: '15px', display: 'flex', alignItems: 'center' }}>
                            {homeAddressPostCodeAtr.postcodeDropdown &&
                                <i onClick={(e) => { e.stopPropagation(); setHomeAddressPostCodeAtr({ ...homeAddressPostCodeAtr, postcodeDropdown: false }); }}
                                    className="fas fa-times-circle"
                                    style={{ marginRight: '10px', color: 'darkred', fontSize: '14px', cursor: 'pointer' }}
                                >
                                </i>

                            }
                            <button type="button" className="btn btn-primary">Search</button>
                        </div>

                        {homeAddressPostCodeAtr.postcodeDropdown &&
                            <PostCodeDropdown
                                absoluteLeft={false}
                                errorValue={homeAddressPostCodeAtr.errorValue}
                                resValue={homeAddressPostCodeAtr.resValue}
                                addressDetails={(e) => addressDetails(e, "homeAddressPostcode")}
                            />
                        }
                    </div>

                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <SiteLabelTextField label="Home Address Line 1"
                            value={state.homeAddressLine1}
                            onChange={(e) => onChange(e.target.value, "homeAddressLine1")}
                            placeholder="Home Address Line 1" />
                    </div>

                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <SiteLabelTextField label="Home Address Line 2"
                            value={state.homeAddressLine2}
                            onChange={(e) => onChange(e.target.value, "homeAddressLine2")}
                            placeholder="Home Address Line 2" />
                    </div>
                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <SiteLabelTextField
                            label="Home City" value={state.homeAddressCity} onChange={(e) => onChange(e.target.value, "homeAddressCity")} placeholder="Home City" />
                    </div>

                    <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                        <MaterialAutoCompleteTypeable
                            startAdornment="Home Country"
                            selectedItem={state.homeAddressCountry}
                            onSelect={(value, data) => onSelect(value, data, "homeAddressCountry")}
                            placeholder="Select Country from a list"
                            data={COUNTRIES_LIST}
                        />
                    </div>
                </div>
            </ContentDialog>
        </div>
    )
}
export default AddLastMinuteTestDialog