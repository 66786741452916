import React from 'react'
import { Row, Column, Button, Body, Spacer, Breadcrumps } from '../../components'
import { withAuthentication } from '../../utils/withAuthentication'

const Users = withAuthentication(() => {


	return (
		<>
			<Column desktopSize={8} tabletSize={12}>
				<Breadcrumps />
				<h3>Users</h3>
				<Row>
					list
				</Row> 
			</Column>
		</>
	)
})

export { Users }
