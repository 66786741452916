import React from 'react'
import { Link } from 'react-router-dom'

export const resolveInputAdendum = (addendumLabel, type) => {
	if (addendumLabel === '') {
		return null
	} else if (typeof addendumLabel === 'function') {
		return addendumLabel
	} else if (type === 'password') {
		return (
			<Link className="form-component" to="/forgot-password" tabIndex="-1">
				Forgot your password?
			</Link>
		)
	}

	return null
}
