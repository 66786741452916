import ConfirmationForm from './confirmation-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getUserConfirmationDetail, confirmPatientDetail } from '../../view.updater/actions/app.actions';
import { selectUserConfirmationDetail, selectConfirmPatientDetailResponse } from '../../view.updater/selectors/app.selectors';

const mapDispatchToProps = (dispatch) => ({
    getUserConfirmationDetail: (data) => dispatch(getUserConfirmationDetail(data)),
    confirmPatientDetail: (data) => dispatch(confirmPatientDetail(data))
});

const mapStateToProps = createStructuredSelector({
    userConfirmationDetail : selectUserConfirmationDetail(),
    confirmPatientDetailResponse : selectConfirmPatientDetailResponse()
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ConfirmationForm);