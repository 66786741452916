//import { pink } from '@material-ui/core/colors'
export const primaryColor = "#04a5a7";
export const secondaryColor = "#db6400";
//THEME_COLOR_CHANGES #82156f
export const materialThemeBase = {
    typography: {
        fontFamily : "inherit",
        fontSize : 'small'
        //fontFamily: "Comfortaa"
      },
      palette: {
        primary: {
          main: primaryColor
          //extraLight: pink[200],
          //darkLight : "#701367"
        },
        secondary: {
          main: secondaryColor
        }
      }
      // shadows: Array(25).fill("none")
}
