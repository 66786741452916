export const toDdMmYyyy = (date) => {
	if (!date) {
		return ''
	}

	const year = date.substring(0, 4)
	const month = date.substring(4, 6)
	const day = date.substring(6, 8)
	return `${day} / ${month} / ${year}`
}

export const toDdMmYyyy2 = (date) => {
	if (!date) {
		return ''
	}

	const year = date.substring(0, 4)
	const month = date.substring(4, 6)
	const day = date.substring(6, 8)
	return `${day}/${month}/${year}`
}

export const toYyyyMmDd = (date) => {
	if (!date) {
		return ''
	}

	const safeDate = date.trim().split(' ').join('').split('/').join('').split('_').join('')

	const day = safeDate.substring(0, 2)
	const month = safeDate.substring(2, 4)
	const year = safeDate.substring(4, 8)
	return year + month + day
}

export const toSbsPatient = (uiPatient) => ({
	...uiPatient,
	dateOfBirth: toYyyyMmDd(uiPatient.dateOfBirth),
	dateOfSwab: toYyyyMmDd(uiPatient.dateOfSwab),
	sbs: true,
})

export const toUiPatient = (sbsPatient) => {
	if (sbsPatient === null || sbsPatient === undefined) {
		return sbsPatient
	}

	return {
		...sbsPatient,
		dateOfBirth: toDdMmYyyy(sbsPatient.dateOfBirth),
		dateOfSwab: toDdMmYyyy2(sbsPatient.dateOfSwab),
	}
}
