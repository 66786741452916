import React from 'react'
import { Row } from '../containers'
import { Body } from '../typography'

const Dot = ({ size = 6, color }) => {
	return (
		<div
			style={{
				width: `${size}px`,
				height: `${size}px`,
				borderRadius: `${size / 2}px`,
				background: color,
			}}
		/>
	)
}

const LegendRow = ({ color, value, label }) => (
	<Row fillRemaining style={{ alignItems: 'center' }}>
		<Dot color={color} />
		&nbsp;
		<Body small>
			{value} {label}
		</Body>
	</Row>
)

export { LegendRow }
