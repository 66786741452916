import React, { useState, useEffect } from 'react'
import { useOrdering, drawInvoice, db } from '../../utils'
import { Button, Column, Row, Body, LoadingAnimation, Spacer, Breadcrumps } from '../../components'
import { formatOnlyTime } from '../../utils/formatters'

const OrderRow = ({ label, children }) => {
	return (
		<Row size={4} marginTop={0}>
			<Row size={2} marginTop={0}>
				<Body>{label}:</Body>
			</Row>
			<Row size={2} marginTop={0}>
				<Body>{children}</Body>
			</Row>
		</Row>
	)
}

const OrderItem = ({ order }) => {

	const {
		confirmationDisplayText: {
			numberOfKitsText,
			orderDateText,
			orderTypeText,
		},
		orderUid,
		orderType,
		address,
		createdAt
	} = order

	let dispatchedTo = ''
	if (address) {
		dispatchedTo =
			orderType === 'self-test' ? (
				'Multiple addresses'
			) : (
					<>
						{address.recipient}
						<br />
						{address.addressLine1}
						<br />
						{address.addressLine && (
							<>
								{address.addressLine2}
								<br />
							</>
						)}
						{address.city}
						<br />
						{address.postcode}
					</>
				)
	}

	return (
		<>
			<Column bordered size={8}>
				<Row size={12}>
					<Column size={3}>
						<h5>Order Date</h5>
						<Body>{orderDateText}</Body>
					</Column>
					<Column size={3}>
						<h5>Order Time</h5>
						<Body>{formatOnlyTime(new Date(createdAt))}</Body>
					</Column>
					<Column size={3}>
						<h5>Order status</h5>
						<Body>{order.status}</Body>
					</Column>
				</Row>
				<Spacer spacing="m" />
				<Row size={12}>
					<Column size={3}>
						<h5>Order number</h5>
						<Body>{orderUid}</Body>
					</Column>
					<Column size={3}>
						<h5>Order type</h5>
						<Body>{orderTypeText}</Body>
					</Column>
					<Column size={3}>
						<h5>Number of kits</h5>
						<Body>{numberOfKitsText}</Body>
					</Column>
				</Row>
				<Spacer spacing="m" />
				<Row size={8} contained>
					<Spacer padding={2} />
					<Button
						secondary
						onClick={() => {
							window.location.href = `mailto:enquiries@beaconsecure.co.uk?subject=Enquiry about order number:${orderUid}`
						}}
					>
						Contact us about this order
					</Button>
				</Row>
			</Column>
		</>
	)
}

const OrderCourierPage = (props) => {
	const [isLoading, setIsLoading] = useState(false)
	const [tasks, setTasks] = useState([])
	const [page, setPage] = useState(0)
	const pageSize = 10

	const order = JSON.parse(localStorage.getItem('orderFromHx'))

	// create url for matching in courier table
	const taskIds = order.patients.map(
		(patient) => `https://gsmtasks.com/api/tasks/tasks/${patient.taskId}/`
	)

	// stream the tasks given an array of task ids
	const streamTasks = (taskIds, observer) => {
		return db
			.collection('couriers')
			.where('task', 'in', taskIds)
			.orderBy('updated_at', 'desc')
			.onSnapshot(observer)
	}

	useEffect(() => {
		setIsLoading(true)
		const pT = taskIds.slice(page * pageSize, page * pageSize + pageSize)
		const unsubscribe = streamTasks(pT, {
			next: (querySnapshot) => {
				const updatedTasks = querySnapshot.docs.map((docSnapshot) => docSnapshot.data())
				// console.log('upatedTasks: ', updatedTasks)
				setTasks(updatedTasks)
				setIsLoading(false)
			},
			error: (e) => {
				console.error('error streaming tasks: ', e)
				setIsLoading(false)
			},
		})
		return unsubscribe
	}, [tasks.length, page, setTasks])

	const dateTime = (isoDate) => {
		const date = new Date(isoDate)
		return (
			date.getDate() +
			'/' +
			(date.getMonth() + 1) +
			' ' +
			(date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
			':' +
			(date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
		)
	}
	const time = (isoDate) => {
		const date = new Date(isoDate)
		return (
			(date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
			':' +
			(date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
		)
	}

	const TaskItem = ({ taskId }) => {
		// find all instances of the taskId from the tasks collection

		const tasksFound = tasks.filter(
			(task) => task.task === `https://gsmtasks.com/api/tasks/tasks/${taskId}/`
		)
		return (
			<>
				{tasksFound.map((task) => (
					<div key={task.id}>
						<Body small>{task.notes}</Body>
						<Body small>
							{task.event === 'create' && 'Delivery order created ' + dateTime(task.updated_at)}
						</Body>
						<Body small>
							{task.event === 'assign' && 'Assigned to driver ' + dateTime(task.updated_at)}
						</Body>
						<Body small>
							{task.event === 'unassign' && 'Unassigned from driver ' + dateTime(task.updated_at)}
						</Body>
						<Body small>
							{task.event === 'transit' && '🚚 En route ' + dateTime(task.updated_at)}
						</Body>
						<Body small>
							<div style={{ color: 'green' }}>
								<b>
									{task.event === 'complete' && '✅ Delivery complete ' + dateTime(task.updated_at)}
								</b>
							</div>
						</Body>
						<Body small>
							<div style={{ color: 'red' }}>
								<b>{task.event === 'fail' && '❌ Delivery fail ' + dateTime(task.updated_at)}</b>
							</div>
						</Body>
						<Body small>
							<div style={{ color: 'red' }}>
								<b>
									{task.event === 'cancel' && '❌ Delivery cancel ' + dateTime(task.updated_at)}
								</b>
							</div>
						</Body>
					</div>
				))}
			</>
		)
	}

	const PatientItem = ({ patient, index }) => {
		const {
			fullName,
			firstName,
			lastName,
			postcode,
			taskId,
			deliveryDate,
			addressLine1,
			addressLine2,
			city,
		} = patient
		console.log("patient data is - ", patient)
		return (
			<>
				<Row size={8}>
					<Column size={2}>
						<Body>
							{index + 1}. { fullName ? fullName : firstName + ' ' + lastName}
						</Body>
						<br />
						<Body small>{addressLine1}</Body>
						<Body small>{addressLine2}</Body>
						<Body small>{city}</Body>
					</Column>
					<Column size={2}>
						<Body>{postcode}</Body>
					</Column>
					<Column size={1}>
						<Body>{deliveryDate}</Body>
					</Column>
					<Column size={2}>
						<TaskItem taskId={taskId} />
					</Column>
				</Row>
				<Spacer spacing="s" />
				<hr style={{ width: '90%', marginLeft: 0 }} />
			</>
		)
	}

	return (
		<Column size={8}>
			<Spacer spacing="m" />
			<Breadcrumps />
			<Spacer spacing="m" />
			<h3>Track your courier order</h3>
			<Spacer spacing="s" />
			<Column size={8}>
				{isLoading ? (
					<Column size={8} centered>
						<LoadingAnimation orange />
					</Column>
				) : null}
				<span key={order.orderUid}>
					<OrderItem order={order} />
					<Spacer spacing="s" />
					{order.patients.length > pageSize-1 ? (<Row>
						<Button secondary disabled={page<=0} onClick={() => { setPage(page > 0 ? page - 1 : 0) }}> Prev Page</Button>
						<Button 
							secondary 
							disabled={Math.ceil(order.patients.length / pageSize) <= page + 1}
							onClick={() => { setPage(Math.ceil(order.patients.length / pageSize) <= page - 1 ? page : page + 1) }}> Next Page</Button>
					</Row>) : null}
					<Column bordered size={8}>
						<Row size={8}>
							<Column size={2}>
								<h5>Name</h5>
							</Column>
							<Column size={2}>
								<h5>Postcode</h5>
							</Column>
							<Column size={1}>
								<h5>Delivery Date</h5>
							</Column>
							<Column size={2}>
								<h5>Status</h5>
							</Column>
						</Row>
						{order.patients.slice(page * pageSize, page * pageSize + pageSize).map((patient, index) => (
							<span key={index + '-PatientItem'}>
								<PatientItem patient={patient} index={page * pageSize + index} />
								<Spacer spacing="s" />
								<Spacer spacing="s" />
							</span>
						))}
					</Column>
				</span>
			</Column>
		</Column>
	)
}

export { OrderCourierPage }
