import React, { useState, useEffect } from 'react'
import { Row, Column, Body, TextInput, Form, Spacer, Button } from '../../../components'
import { useMetrics } from '../../../utils/backend'
import { KitsLoadingIndicator } from './KitsLoadingIndicator'
import { formatShortDate } from '../../../utils'

const SameDayKitRow = ({ kit }) => {

	const { id, fullName, email, updatedAt, status } = kit
	const prettyDate = formatShortDate(new Date(updatedAt))

	return (
		<Column
			desktopSize={8}
			tabletSize={12}
			style={{ padding: '1.6rem 0', borderBottom: ' solid 1px #c7d4da' }}
		>
			<Row desktopSize={8} tabletSize={12}>
				<Row desktopSize={2} tabletSize={2}>
					<Body>{fullName}</Body>
				</Row>
				<Row desktopSize={3} tabletSize={5}>
					<Body small>{email}</Body>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<Body>{id}</Body>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<Body>{prettyDate}</Body>
				</Row>
				<Row desktopSize={1} tabletSize={1}>
					<Body>{status}</Body>
				</Row>
			</Row>
			<Spacer spacing="s" />
		</Column>
	)
}

const SameDayNoKits = (props) => {
	return (
		<Row desktopSize={8} tabletSize={12} centered bordered>
			<h3>There are currently no statuses for same day kits</h3>
		</Row>
	)
}

const SameDayKitsStatusesList = () => {
	const [sameDayKits, setSameDayKits] = useState(null)
	const [gettingSameDayKits, setGettingSameDayKits] = useState(false)

	const [kitSearchText, setKitSearchText] = useState("");
    const [pagingObj, setPagingObj] = useState({ page: 1, pageType: '', docRef: '' })
    const [pageSize, setPageSize] = useState(20)

	const { queryAllSameDayKits } = useMetrics()

	const pageWrapper = {
        alignItems: "center"
    }

    const pageInfoStyle = {
        fontSize: "14px",
        padding: "0 1rem",
        fontWeight: "600",
    }

	const totalPages = () => {
        return sameDayKits ? Math.ceil(sameDayKits.totalCount / pageSize) : 0
    }
    const next = () => {
        setPagingObj({
            page: pagingObj.page + 1,
            // pageType: 'next',
            // docRef: sameDayKits.kits[sameDayKits.kits.length - 1].id
        })
    }
    const prev = () => {
        setPagingObj({
            page: pagingObj.page - 1,
            // pageType: 'prev',
            // docRef: sameDayKits.kits[0].id
        })
    }

	const onSameDayKitsSearch = () => {
        let requestParams = { page: pagingObj.page }
        if (kitSearchText) {
            requestParams["query"] = kitSearchText
        }
        setGettingSameDayKits(true)
        queryAllSameDayKits(requestParams)
            .then((response) => {
                setGettingSameDayKits(false)
                setSameDayKits(response.data)
				// console.log("response is - ", response);
            })
            .catch((error) => {
                setGettingSameDayKits(false)
                console.error(error)
            })
    }

	useEffect(() => {
		onSameDayKitsSearch()
	}, [queryAllSameDayKits, pagingObj])

	if (gettingSameDayKits === true) {
		return <KitsLoadingIndicator />
	}

	return (
		<>
			<Form onSubmit={onSameDayKitsSearch}>
				<Row desktopSize={8} tabletSize={12} spaced>
					<Spacer spacing="m" />
					<TextInput
						placeholder="Search by Full Name, Email or Order Id"
						value={kitSearchText}
						setter={setKitSearchText}
						desktopSize={3}
						tabletSize={4}
					/>
				</Row>
			</Form>
			<Row desktopSize={8} tabletSize={12} noWrap>
				<Row desktopSize={2} tabletSize={2}>
					<h5>Full Name</h5>
				</Row>
				<Row desktopSize={3} tabletSize={5}>
					<h5>Email</h5>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<h5>Test Kit Id</h5>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<h5>Last Update</h5>
				</Row>
				<Row desktopSize={1} tabletSize={1}>
					<h5>Status</h5>
				</Row>
			</Row>
			<Column desktopSize={8} tabletSize={12}>
				{totalPages() === 0 ? <SameDayNoKits /> : sameDayKits.kits.map((kit) => <SameDayKitRow kit={kit} key={kit.id} />)}
			</Column>
			<Column size={8}>
				{
					sameDayKits && sameDayKits.totalCount ?
						<div style={pageWrapper}>
							{
								pagingObj.page > 1 ?
									<Button secondary onClick={prev}>
										Prev
									</Button>
									: null
							}
							<div style={pageInfoStyle}>Page {pagingObj.page} of {totalPages()}</div>
							{
								pagingObj.page < totalPages() ?
									<Button secondary onClick={next}>
										Next
									</Button>
									: null
							}

						</div>
						: null
				}

			</Column>
		</>
	)
}

export { SameDayKitsStatusesList }
