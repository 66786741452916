import React, { useState, useEffect } from 'react'
import { Column, Breadcrumps } from '../../../components'
import { withAuthentication, useWorkflow, useAuth } from '../../../utils'
import { OrderPatientDetailsEntry } from './OrderPatientDetailsEntry'
import { OrderConfirmation } from './OrderConfirmation'
import { useAdmin } from '../../../utils'

const OrderKitsAdminPage = withAuthentication(() => {
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const { customer, defaultOrganisation } = useAuth()
	const [patientDetails, setPatientDetails] = useState({})
	const [organisationId, setOrganisationId] = useState(defaultOrganisation())
	const [organisations, setOrganisations] = useState([])

	const { getAllOrgs } = useAdmin()

	useEffect(() => {
		setIsLoading(true)
		getAllOrgs()
			.then((response) => {
				const orgs = response.data
				console.log('orgs: ', orgs)
				setOrganisations(orgs)
				// setTestResults(testResults)
				// onTestResults({ testResults })
				setIsLoading(false)
			})
			.catch((error) => {
				setIsLoading(false)
				setErrorMessage('Error occured')
				console.error('getAllOrgs: ', errorMessage)
			})
	}, [errorMessage, organisations.length])

	const workflowSteps = ['PatientDetails', 'OrderConfirmation', 'OrderConfirmed']

	const [workflowStep, toPatientDetails, toOrderConfirmation, toOrderConfirmed] = useWorkflow(
		workflowSteps,
		0
	)

	// const handleOrganisationSelect = ({ organisationId }) => {
	// 	setOrganisationId(organisationId)
	// 	toPatientDetails()
	// }

	const handleOnPatientDetailsSubmit = ({ patientDetails, idDocument }) => {
		console.log('handleOnPatientDetailsSubmit', patientDetails)
		setPatientDetails(patientDetails)
		setOrganisationId(patientDetails.org)
		toOrderConfirmation()
		// setIdDocument(idDocument)
		// toTestKitId()
	}

	// const handleOnKitIdSubmit = ({ testKitId }) => {
	// 	setTestKitBarcode(testKitId)
	// 	toOrderConfirmation()
	// }

	return (
		<>
			<Column desktopSize={8} spacingTop="s">
				{workflowStep === 'PatientDetails' && (
					<OrderPatientDetailsEntry
						onSubmit={handleOnPatientDetailsSubmit}
						value={{ patientDetails }}
						// onBack={isIndependent ? toSelectOrganisation : null}
						orgs={organisations}
					/>
				)}
				{workflowStep === 'OrderConfirmation' && (
					<OrderConfirmation
						onBackClick={toPatientDetails}
						onNext={toOrderConfirmed}
						onEditPatientDetailsClick={toPatientDetails}
						patientDetails={patientDetails}
						organisationId={organisationId}
						confirmed={false}
						customer={customer}
					/>
				)}
				{workflowStep === 'OrderConfirmed' && (
					<OrderConfirmation
						onNext={toOrderConfirmed}
						patientDetails={patientDetails}
						organisationId={organisationId}
						confirmed={true}
					/>
				)}
				{}
			</Column>
		</>
	)
})

export { OrderKitsAdminPage }
