import ReactDOM from 'react-dom'
import React from 'react'
import { Row, Column, Button, HamburgerIcon, Spacer } from '../components'

let currentModal = null

const ModalChrome = ({ children, onCloseClick }) => {
	return (
		<Row style={{ height: '42rem' }}>
			<Column desktopSize={3} tabletSize={1} />
			<Column desktopSize={6} tabletSize={10} className="modal-chrome">
				<Spacer spacing="xs" />
				<Spacer spacing="s" />
				<Row desktopSize={6} tabletSize={10} className="modal-bar">
					<Column desktopSize={5} />
					<Button chromeless onClick={onCloseClick}>
						Close&nbsp;
						<HamburgerIcon closeIcon />
					</Button>
				</Row>
				{children}
				<Spacer spacing="m" />
				<Spacer spacing="s" />
				<Spacer spacing="s" />
			</Column>
			<Column desktopSize={3} tabletSize={1} />
		</Row>
	)
}

const closeModal = () => {
	const bodyEl = document.querySelector('body')
	const modalsEl = document.querySelector('#modals')

	currentModal = null

	bodyEl.className = ''
	modalsEl.className = ''
}

export const showModal = (element, onCloseClick) => {
	const bodyEl = document.querySelector('body')
	const modalsEl = document.querySelector('#modals')

	bodyEl.className = 'modal-visible'
	modalsEl.className = 'visible'
	currentModal = element

	const handleCloseClick = () => {
		closeModal()
		onCloseClick()
	}

	return ReactDOM.createPortal(
		<>
			<ModalChrome onCloseClick={handleCloseClick}>{element}</ModalChrome>
		</>,
		modalsEl
	)
}

export const useModal = (element) => {
	const isModalOpen = currentModal !== null
	return [showModal.bind(null, element), closeModal, isModalOpen]
}
