import React from 'react'
import ContentDialog from 'components/common/content.dialog/content.dialog'
import { Typography } from '@material-ui/core'
import Calender from 'components/common/custom.calender/Calender';
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
import MaterialAutoComplete from 'components/common/material.autocomplete/material.autocomplete';
import { COUNTRIES_LIST } from 'components/common/util/util';
import MaterialAutoCompleteTypeable from 'components/common/material.autocomplete.typeable/material.autocomplete.typeable';
import { GENDER_LIST } from 'components/common/util/util';

const PatientDetailDialog = (props) => {
    const {
        open,
        familyName,
        givenName,
        DOB,
        gender,
        nationality,
        healthNumber,
        documentType,
        documentCountry,
        documentNumber,
        documentExpiration,
        documentAuthority } = props;
    return (
        <div>
            <ContentDialog
                onClose={props.handleClose}
                dialogIsOpen={open}
                showActions={true}
                successText="Save"
                onSuccess={props.handleClose}
                title={<div><Typography><b>Patient Detail</b></Typography></div>}>
                <div className="p-3">
                    <div className="d-flex p-2 font-weight-bold ">
                        <Calender
                            label="Date of Birth"
                            value={DOB}
                            placeholder="Select Date of Birth"
                            getSelectedDate={(date) => { console.log(date, "Got Date"); props.onChange(date, "DOB") }}
                        // onAccept={(date) => props.onChange(date, "DOB")}
                        //onAccept={(date) => onAccept(date, "fromDate")}
                        //value={new Date()}
                        />
                    </div>
                    {/* <div className="d-flex p-2 font-weight-bold ">
                        <MaterialAutoCompleteTypeable
                            startAdornment="Country"
                            selectedItem={country}
                            onSelect={(value, data) => props.onSelect(value, data, "country")}
                            placeholder="Select Country from a list"
                            data={COUNTRIES_LIST}
                        />
                    </div> */}
                    <div className="d-flex p-2 font-weight-bold ">
                        <MaterialAutoComplete
                            selectedItem={gender}
                            onSelect={(value, data) => props.onSelect(value, data, "gender")}
                            //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                            //onSelect={props.onKitStatusChange}
                            placeholder="Select gender"
                            data={GENDER_LIST}
                        />
                    </div>
                    {/* <div className="d-flex p-2 font-weight-bold  ">
                        <SiteLabelTextField value={addressLine1} onChange={(e) => props.onChange(e.target.value, "addressLine1")} placeholder="Address Line 1" />
                    </div>
                    <div className="d-flex p-2 font-weight-bold  ">
                        <SiteLabelTextField value={addressLine2} onChange={(e) => props.onChange(e.target.value, "addressLine2")} placeholder="Address Line 2" />
                    </div>
                    <div className="d-flex p-2 font-weight-bold  ">
                        <SiteLabelTextField value={city} onChange={(e) => props.onChange(e.target.value, "city")} placeholder="City" />
                    </div>
                    <div className="d-flex p-2 font-weight-bold  ">
                        <SiteLabelTextField value={postcode} onChange={(e) => props.onChange(e.target.value, "postcode")} placeholder="Post Code" />
                    </div>
                    <div className="d-flex p-2 font-weight-bold  ">
                        <SiteLabelTextField value={country} onChange={(e) => props.onChange(e.target.value, "country")} placeholder="Country" />
                    </div> */}
                    {/* <div className="d-flex p-2 font-weight-bold  ">
                        <SiteLabelTextField value={nationality} onChange={(e) => props.onChange(e.target.value, "nationality")} placeholder="Nationality" />
                    </div> */}
                    <div className="d-flex p-2 font-weight-bold  ">
                        <SiteLabelTextField value={healthNumber} onChange={(e) => props.onChange(e.target.value, "healthNumber")} placeholder="National Health Number" />
                    </div>
                    <div className="d-flex p-2 font-weight-bold  ">
                        <SiteLabelTextField value={documentType} onChange={(e) => props.onChange(e.target.value, "documentType")} placeholder="Document Type" />
                    </div>
                    <div className="d-flex p-2 font-weight-bold  ">
                        <SiteLabelTextField value={documentAuthority} onChange={(e) => props.onChange(e.target.value, "documentAuthority")} placeholder="Document Authority" />
                    </div>
                    <div className="d-flex p-2 font-weight-bold  ">
                        <SiteLabelTextField value={documentNumber} onChange={(e) => props.onChange(e.target.value, "documentNumber")} placeholder="Document Number" />
                    </div>
                    <div className="d-flex p-2 font-weight-bold ">
                        <MaterialAutoCompleteTypeable
                            startAdornment="Issuing Country"
                            selectedItem={documentCountry}
                            onSelect={(value, data) => props.onSelect(value, data, "documentCountry")}
                            placeholder="Select Country from a list"
                            data={COUNTRIES_LIST}
                        />
                    </div>

                    <div className="d-flex p-2 font-weight-bold ">
                        <Calender
                            value={documentExpiration}
                            label="Document Expiration"
                            placeholder="Select Document Expiary Date"
                            getSelectedDate={(date) => props.onChange(date, "documentExpiration")}
                        //onAccept={(date) => onAccept(date, "fromDate")}
                        //value={new Date()}
                        />
                    </div>


                </div>
            </ContentDialog>
        </div>
    )
}
export default PatientDetailDialog