import React from 'react'
// import CustomCalender from 'components/common/custom.calender/custom.calender';
import clsx from 'clsx';
import MaterialAutoComplete from 'components/common/material.autocomplete/material.autocomplete';
import { IsMobileWidth } from 'components/common/util/util';
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
import { Button } from '@material-ui/core';

export default function KitFilters(props) {

    const { selectedResultStatus, selectedKitStatus, search, execStatusGetKits } = props;
    const onSubmit = (event) => {
        event.preventDefault();
        props.onSearchEnter();
    }
    // const handleClick = (value) => {
    //     setState({ ...state, selected: value })
    // }
    // const onAccept = (date, value) => {
    //     setState({ ...state, [value]: date })
    // }
    // const addMonths = (date, months) => {
    //     date.setMonth(date.getMonth() + months);
    //     return date;
    // }
    const mobileWidth = IsMobileWidth();

    return (
        <form className="w-100">
            <div className="w-100 d-flex flex-wrap pt-3">
                {/* <div className={clsx(!mobileWidth && "w-20 d-flex align-items-center", mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                <CustomCalender label="From"
                    onAccept={(date) => onAccept(date, "fromDate")}
                    value={state.fromDate}
                />
            </div>
            <div className={clsx(!mobileWidth && "w-20 d-flex align-items-center pl-3", mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                <CustomCalender
                    label="To"
                    onAccept={(date) => onAccept(date, "toDate")}
                    value={state.toDate}
                />
            </div> */}
                <div className={clsx(!mobileWidth && "w-15 d-flex align-items-center pl-3", mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                    <MaterialAutoComplete
                        disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                        selectedItem={selectedKitStatus}
                        onSelect={props.onKitStatusChange}
                        placeholder="Select Status"
                        data={[
                            {
                                name: 'All Statuses',
                                value: ''
                            },
                            {
                                name: 'Assigned',
                                value: 'Assigned'
                            },
                            {
                                name: 'Results of tests',
                                value: 'ResultReady'
                            },
                            {
                                name: 'Cancelled Tests',
                                value: 'CANCELLED'
                            }
                        ]}
                    />
                </div>
                <div className={clsx(!mobileWidth && "w-15 d-flex align-items-center pl-3", mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                    <MaterialAutoComplete
                        disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                        selectedItem={selectedResultStatus}
                        onSelect={props.onResultStatusChange}
                        placeholder="Select Result"
                        data={[
                            {
                                name: 'All Results',
                                value: ''
                            },
                            {
                                name: 'Pending',
                                value: 'Pending'
                            },
                            {
                                name: 'Positive',
                                value: 'Positive'
                            },
                            {
                                name: 'Negative',
                                value: 'Negative'
                            },
                            {
                                name: 'Inconclusive',
                                value: 'Inconclusive'
                            }
                        ]}
                    />
                </div>
                <div className={clsx(!mobileWidth && "w-30 d-flex align-items-center pl-3",
                    mobileWidth && "w-100 d-flex align-items-center pt-3")}>
                    <SiteLabelTextField
                        // disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                        disabled={false}
                        onChange={props.onSearchChange}
                        value={search}
                        //label="Search"
                        placeholder="Search By Email" />
                </div>
                <Button className="d-none" type="submit" onClick={onSubmit} />
            </div>
        </form>
    )
}
