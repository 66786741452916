import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import moment from 'moment';

export function setLocalStorgeValue(key, value) {
    window.localStorage.setItem(key, value)
}
export const CALENDER_LIMIT = "01/01/2018"

export function getLocalStorageValue(key) {
    return window.localStorage.getItem(key)
}
export const isVersionDifferent = (versionA, versionB) => {
    if (versionA === versionB)
        return false;
    else
        return true;
};
export const IsMobileWidth = () => {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.between('xs', 'sm'))
}

export const IsDesktopWidth = () => {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.between('lg', 'xl'))
}

export const IsTabletWidth = () => {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.between('sm', 'md'))
}

export const IsDesktopSmallWidth = () => {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.between('md', 'lg'))
}

export const differenceBetweenDatesInMinutes = (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const ms = Math.abs(date2 - date1);
    var diffMins = Math.round(((ms % 86400000) % 3600000) / 60000); // minutes
    return diffMins;
}

export function getRandomNumber() {
    let randomNumber = Math.floor(1000 + Math.random() * 9000); // generate a 4 digit random number
    return randomNumber
}
export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}
export const displayMinutes = (minutes) => {
    var num = minutes;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutesTemp = (hours - rhours) * 60;
    var rminutes = Math.round(minutesTemp);
    if (rhours === 0) {
        return rminutes + " mins";
    }
    else if (rminutes === 0) {
        return `${rhours} hour`;
    }
    else {
        return `${rhours} hour ${rminutes} mins`;
    }
}

export function formatDate(date) {
    if (date === "")
        return ""
    if (date && date._d && date._d.toString() === "Invalid Date")
        return ""
    date = new Date(date);

    let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let day = date.getDate();
    let monthIndex = date.getMonth();
    let dayIndex = date.getDay();
    let year = date.getFullYear();

    return days[dayIndex] + ' ' + day + `-` + monthNames[monthIndex] + '-' + year;
}
export function format24HrDateTime(date) {
    if (date === undefined) {
        return ""
    }
    if (date && date._d && date._d.toString() === "Invalid Date")
        return ""
    if (typeof date === "string") {
        date = new Date(date.replace(" ", "T")) // Fix for Explorer unable to handle backend returned format
    }
    else
        date = new Date(date)
    let monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    let day = date.getDate()
    let monthIndex = date.getMonth()
    let year = date.getFullYear()
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var seconds = date.getSeconds()
    minutes = ('0' + minutes).slice(-2)
    hours = ('0' + hours).slice(-2)
    seconds = ('0' + seconds).slice(-2)
    if (seconds > 0)
        return `${day}-${monthNames[monthIndex]}-${year}, ${hours}:${minutes}`
    else
        return `${day}-${monthNames[monthIndex]}-${year}, ${hours}:${minutes}`
}

export function formatSBSSwabDate(date, time) {
    if (date === undefined) {
        return ""
    }
    else {
        date = moment(date, "YYYYMMDD")
    }
    if (date && date._d && date._d.toString() === "Invalid Date")
        return ""
    date = new Date(date);
    let monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    let day = date?.getDate()
    let monthIndex = date?.getMonth()
    let year = date?.getFullYear()
    return `${day}-${monthNames[monthIndex]}-${year}, ${time}`
}

export function format24HrDateTimeV2(date) {
    if (date === undefined) {
        return ""
    }
    if (typeof date === "string") {
        date = new Date(date.replace(" ", "T")) // Fix for Explorer unable to handle backend returned format
    }
    let monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    let day = date.getDate()
    let monthIndex = date.getMonth()
    let year = date.getFullYear()
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var seconds = date.getSeconds()
    minutes = ('0' + minutes).slice(-2)
    hours = ('0' + hours).slice(-2)
    seconds = ('0' + seconds).slice(-2)
    if (seconds > 0)
        return `${day}-${monthNames[monthIndex]}-${year}, ${hours}:${minutes}`
    else
        return `${day}-${monthNames[monthIndex]}-${year}, ${hours}:${minutes}`
}
export function isEmpty(str) {
    return (!str || 0 === str.length);
}
export function delay(milisec) {
    return new Promise(resolve => {
        setTimeout(() => { resolve('') }, milisec);
    })
}
export const checkIfAllEmpty = (data) => {
    let allEmpty = true
    Object.entries(data).forEach(pt => {
        if (pt[1] !== undefined) {
            if (pt[1].trim() !== "") {
                allEmpty = false;
                return allEmpty
            }
        }
    })
    return allEmpty;
}

export function checkExecutionStatus(context, action) {
    if (context && context.executions && context.executions.size > 0) {
        const execution = context.executions.find(
            (execution) => execution.get('name') === action
        )
        if (execution !== undefined && execution !== null) {
            return {
                status: execution.get('status'),
                error: execution.get('errorId'),
                errorDetail: execution.get('error')
            }
        }
        return {
            status: '',
            error: '',
            errorDetail: ''
        }
    }
    return {
        status: '',
        error: '',
        errorDetail: ''
    }
}

export function getPastDate(days) {
    var fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - days);
    return fromDate;
}

export function getCookieValue(key) {
    var b = document.cookie.match('(^|[^;]+)\\s*' + key + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

export function getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

export const LANGUAGES = [
    'English', 'Arabic', 'French', 'German', 'Chinese/Cantonese', 'Chinese/Mandarin'
]

export const PREFERRED_TIMES = [
    {
        name: "07:00 - 09:00",
        value: '07:00-09:00',
        preferredFromTime: "07:00",
        preferredToTime: "09:00"
    },
    {
        name: "08:00 - 10:00",
        value: '08:00-10:00',
        preferredFromTime: "08:00",
        preferredToTime: "10:00"
    },
    {
        name: "09:00 - 11:00",
        value: '09:00-11:00',
        preferredFromTime: "09:00",
        preferredToTime: "11:00"
    },
    {
        name: "10:00 - 12:00",
        value: '10:00-12:00',
        preferredFromTime: "10:00",
        preferredToTime: "12:00"
    },
    {
        name: "11:00 - 13:00",
        value: '11:00-13:00',
        preferredFromTime: "11:00",
        preferredToTime: "13:00"
    },
    {
        name: "12:00 - 14:00",
        value: '12:00-14:00',
        preferredFromTime: "12:00",
        preferredToTime: "14:00"
    },
    {
        name: "13:00 - 15:00",
        value: '13:00-15:00',
        preferredFromTime: "13:00",
        preferredToTime: "15:00"
    },
    {
        name: "14:00 - 16:00",
        value: '14:00-16:00',
        preferredFromTime: "14:00",
        preferredToTime: "16:00"
    },
    {
        name: "15:00 - 17:00",
        value: '15:00-17:00',
        preferredFromTime: "15:00",
        preferredToTime: "17:00"
    },
    {
        name: "16:00 - 18:00",
        value: '16:00-18:00',
        preferredFromTime: "16:00",
        preferredToTime: "18:00"
    },
    {
        name: "17:00 - 19:00",
        value: '17:00-19:00',
        preferredFromTime: "19:00",
        preferredToTime: "19:00"
    }
]


export const PARTNER_LABS = [
    {
        name: "Katalyst",
        value: 'catalyst',
    },
    {
        name: "SBS",
        value: 'SBS',
    }
]

export const SERVICE_TYPES = [
    {
        name: 'Same Day',
        value: 'SAME_DAY',
        serviceTypeText: "Same Day",
        price: 175,
        courierCost: 0,
        currency: 'GBP',
        currencySymbol: '£'
    },
    {
        name: 'Next Day',
        value: 'NEXT_DAY',
        serviceTypeText: "Next Day",
        price: 135,
        courierCost: 0,
        currency: 'GBP',
        currencySymbol: '£'
    },
    // PCR Day 2
    // {

    //     name: 'PCR Day 2',
    //     value: 'PCR_DAY_2',
    //     serviceTypeText: "PCR Day 2",
    //     price: 175,
    //     courierCost: 0,
    //     currency: 'GBP',
    //     currencySymbol: '£'
    // }
]

export const GENDER_LIST = [
    {
        name: 'Male',
        value: 'MALE'
    },
    {
        name: 'Female',
        value: 'FEMALE'
    },
    {
        name: 'Transgender',
        value: 'TRANSGENDER'
    },
    {
        name: 'Other',
        value: 'OTHER'
    }
]

export const GENDER_LIST_SBS = [
    {
        name: 'Male',
        value: 'M'
    },
    {
        name: 'Female',
        value: 'F'
    },
    {
        name: 'Unknown',
        value: 'U'
    }
]

export const COUNTRY_CODES = [
    ["Afghanistan (‫افغانستان‬‎)", "af", "93", "+..-..-...-...."],
    ["Åland Islands", "ax", "358", ""],
    ["Albania (Shqipëri)", "al", "355", "+...(...)...-..."],
    ["Algeria (‫الجزائر‬‎)", "dz", "213", "+...-..-...-...."],
    ["American Samoa", "as", "1684", "+.(...)...-...."],
    ["Andorra", "ad", "376", "+...-...-..."],
    ["Angola", "ao", "244", "+...(...)...-..."],
    ["Anguilla", "ai", "1264", "+.(...)...-...."],
    ["Antarctica", "aq", "672", ""],
    ["Antigua and Barbuda", "ag", "1268", "+.(...)...-...."],
    ["Argentina", "ar", "54", "+..(...)...-...."],
    ["Armenia (Հայաստան)", "am", "374", "+...-..-...-..."],
    ["Aruba", "aw", "297", "+...-...-...."],
    ["Australia", "au", "61", "+.. ... ... ..."],
    ["Austria (Österreich)", "at", "43", "+..(...)...-...."],
    ["Azerbaijan (Azərbaycan)", "az", "994", "+...-..-...-..-.."],
    ["Bahamas", "bs", "1242", "+.(...)...-...."],
    ["Bahrain (‫البحرين‬‎)", "bh", "973", "+...-....-...."],
    ["Bangladesh (বাংলাদেশ)", "bd", "880", "+...-..-...-..."],
    ["Barbados", "bb", "1246", "+.(...)...-...."],
    ["Belarus (Беларусь)", "by", "375", "+...(..)...-..-.."],
    ["Belgium (België)", "be", "32", "+.. ... .. .. .."],
    ["Belize", "bz", "501", "+...-...-...."],
    ["Benin (Bénin)", "bj", "229", "+...-..-..-...."],
    ["Bermuda", "bm", "1441", "+.(...)...-...."],
    ["Bhutan (འབྲུག)", "bt", "975", "+...-.-...-..."],
    ["Bolivia", "bo", "591", "+...-.-...-...."],
    ["Bosnia and Herzegovina (Босна и Херцеговина)", "ba", "387", "+...-..-...."],
    ["Botswana", "bw", "267", "+...-..-...-..."],
    ["Bouvet Island", "bv", "47", ""],
    ["Brazil (Brasil)", "br", "55", "+..-..-....-...."],
    ["British Indian Ocean Territory", "io", "246", "+...-...-...."],
    ["British Virgin Islands", "vg", "1284", "+.(...)...-...."],
    ["Brunei", "bn", "673", "+...-...-...."],
    ["Bulgaria (България)", "bg", "359", "+...(...)...-..."],
    ["Burkina Faso", "bf", "226", "+...-..-..-...."],
    ["Burundi (Uburundi)", "bi", "257", "+...-..-..-...."],
    ["Cambodia (កម្ពុជា)", "kh", "855", "+...-..-...-..."],
    ["Cameroon (Cameroun)", "cm", "237", "+...-....-...."],
    [
        "Canada",
        "ca",
        "1",
        "+. (...) ...-....",
        1,
        [
            "204",
            "236",
            "249",
            "250",
            "289",
            "306",
            "343",
            "365",
            "387",
            "403",
            "416",
            "418",
            "431",
            "437",
            "438",
            "450",
            "506",
            "514",
            "519",
            "548",
            "579",
            "581",
            "587",
            "604",
            "613",
            "639",
            "647",
            "672",
            "705",
            "709",
            "742",
            "778",
            "780",
            "782",
            "807",
            "819",
            "825",
            "867",
            "873",
            "902",
            "905",
        ],
    ],
    ["Cape Verde (Kabu Verdi)", "cv", "238", "+...(...)..-.."],
    ["Caribbean Netherlands", "bq", "599", "+...-...-....", 1],
    ["Cayman Islands", "ky", "1345", "+.(...)...-...."],
    [
        "Central African Republic (République centrafricaine)",
        "cf",
        "236",
        "+...-..-..-....",
    ],
    ["Chad (Tchad)", "td", "235", "+...-..-..-..-.."],
    ["Chile", "cl", "56", "+..-.-....-...."],
    ["China (中国)", "cn", "86", "+.. ..-........"],
    ["Christmas Island", "cx", "61", ""],
    ["Cocos (Keeling) Islands", "cc", "61", ""],
    ["Colombia", "co", "57", "+..(...)...-...."],
    ["Comoros (‫جزر القمر‬‎)", "km", "269", "+...-..-....."],
    [
        "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
        "cd",
        "243",
        "+...(...)...-...",
    ],
    ["Congo (Republic) (Congo-Brazzaville)", "cg", "242", "+...-..-...-...."],
    ["Cook Islands", "ck", "682", "+...-..-..."],
    ["Costa Rica", "cr", "506", "+... ....-...."],
    ["Côte d’Ivoire", "ci", "225", "+...-..-...-..."],
    ["Croatia (Hrvatska)", "hr", "385", "+...-..-...-..."],
    ["Cuba", "cu", "53", "+..-.-...-...."],
    ["Curaçao", "cw", "599", "+...-...-....", 0],
    ["Cyprus (Κύπρος)", "cy", "357", "+...-..-...-..."],
    ["Czech Republic (Česká republika)", "cz", "420", "+...(...)...-..."],
    ["Denmark (Danmark)", "dk", "45", "+.. .. .. .. .."],
    ["Djibouti", "dj", "253", "+...-..-..-..-.."],
    ["Dominica", "dm", "1767", "+.(...)...-...."],
    [
        "Dominican Republic (República Dominicana)",
        "do",
        "1",
        "+.(...)...-....",
        2,
        ["809", "829", "849"],
    ],
    ["Ecuador", "ec", "593", "+...-.-...-...."],
    ["Egypt (‫مصر‬‎)", "eg", "20", "+..(...)...-...."],
    ["El Salvador", "sv", "503", "+... ....-...."],
    ["Equatorial Guinea (Guinea Ecuatorial)", "gq", "240", "+...-..-...-...."],
    ["Eritrea", "er", "291", "+...-.-...-..."],
    ["Estonia (Eesti)", "ee", "372", "+...-...-...."],
    ["Ethiopia", "et", "251", "+...-..-...-...."],
    ["Falkland Islands (Islas Malvinas)", "fk", "500", "+...-....."],
    ["Faroe Islands (Føroyar)", "fo", "298", "+...-...-..."],
    ["Fiji", "fj", "679", "+...-..-....."],
    ["Finland (Suomi)", "fi", "358", "+... .. .... ...."],
    ["France", "fr", "33", "+.. . .. .. .. .."],
    ["French Guiana (Guyane française)", "gf", "594", "+...-.....-...."],
    ["French Polynesia (Polynésie française)", "pf", "689", "+...-..-..-.."],
    ["French Southern and Antarctic Lands", "tf", "262", ""],
    ["Gabon", "ga", "241", "+...-.-..-..-.."],
    ["Gambia", "gm", "220", "+...(...)..-.."],
    ["Georgia (საქართველო)", "ge", "995", "+...(...)...-..."],
    ["Germany (Deutschland)", "de", "49", "+.. ... ......."],
    ["Ghana (Gaana)", "gh", "233", "+...(...)...-..."],
    ["Gibraltar", "gi", "350", "+...-...-....."],
    ["Greece (Ελλάδα)", "gr", "30", "+..(...)...-...."],
    ["Greenland (Kalaallit Nunaat)", "gl", "299", "+...-..-..-.."],
    ["Grenada", "gd", "1473", "+.(...)...-...."],
    ["Guadeloupe", "gp", "590", "", 0],
    ["Guam", "gu", "1671", "+.(...)...-...."],
    ["Guatemala", "gt", "502", "+... ....-...."],
    //["Guernsey", "gg", "44", ""],
    ["Guinea (Guinée)", "gn", "224", "+...-..-...-..."],
    ["Guinea-Bissau (Guiné Bissau)", "gw", "245", "+...-.-......"],
    ["Guyana", "gy", "592", "+...-...-...."],
    ["Haiti", "ht", "509", "+... ....-...."],
    ["Heard Island and McDonald Islands", "hm", "672", ""],
    ["Honduras", "hn", "504", "+...-....-...."],
    ["Hong Kong (香港)", "hk", "852", "+... .... ...."],
    ["Hungary (Magyarország)", "hu", "36", "+..(...)...-..."],
    ["Iceland (Ísland)", "is", "354", "+... ... ...."],
    ["India (भारत)", "in", "91", "+.. .....-....."],
    ["Indonesia", "id", "62", "+..-..-...-.."],
    ["Iran (‫ایران‬‎)", "ir", "98", "+..(...)...-...."],
    ["Iraq (‫العراق‬‎)", "iq", "964", "+...(...)...-...."],
    ["Ireland", "ie", "353", "+... .. ......."],
    //["Isle of Man", "im", "44", ""],
    ["Israel (‫ישראל‬‎)", "il", "972", "+...-.-...-...."],
    ["Italy (Italia)", "it", "39", "+.. ... ......", 0],
    ["Jamaica", "jm", "1876", "+.(...)...-...."],
    ["Japan (日本)", "jp", "81", "+.. ... .. ...."],
    //["Jersey", "je", "44", ""],
    ["Jordan (‫الأردن‬‎)", "jo", "962", "+...-.-....-...."],
    ["Kazakhstan (Казахстан)", "kz", "7", "+. ... ...-..-..", 1],
    ["Kenya", "ke", "254", "+...-...-......"],
    ["Kiribati", "ki", "686", "+...-..-..."],
    ["Kosovo", "xk", "383", ""],
    ["Kuwait (‫الكويت‬‎)", "kw", "965", "+...-....-...."],
    ["Kyrgyzstan (Кыргызстан)", "kg", "996", "+...(...)...-..."],
    ["Laos (ລາວ)", "la", "856", "+...-..-...-..."],
    ["Latvia (Latvija)", "lv", "371", "+...-..-...-..."],
    ["Lebanon (‫لبنان‬‎)", "lb", "961", "+...-.-...-..."],
    ["Lesotho", "ls", "266", "+...-.-...-...."],
    ["Liberia", "lr", "231", "+...-..-...-..."],
    ["Libya (‫ليبيا‬‎)", "ly", "218", "+...-..-...-..."],
    ["Liechtenstein", "li", "423", "+...(...)...-...."],
    ["Lithuania (Lietuva)", "lt", "370", "+...(...)..-..."],
    ["Luxembourg", "lu", "352", "+...(...)...-..."],
    ["Macau (澳門)", "mo", "853", "+...-....-...."],
    ["Macedonia (FYROM) (Македонија)", "mk", "389", "+...-..-...-..."],
    ["Madagascar (Madagasikara)", "mg", "261", "+...-..-..-....."],
    ["Malawi", "mw", "265", "+...-.-....-...."],
    ["Malaysia", "my", "60", "+.. ..-....-...."],
    ["Maldives", "mv", "960", "+...-...-...."],
    ["Mali", "ml", "223", "+...-..-..-...."],
    ["Malta", "mt", "356", "+...-....-...."],
    ["Marshall Islands", "mh", "692", "+...-...-...."],
    ["Martinique", "mq", "596", "+...(...)..-..-.."],
    ["Mauritania (‫موريتانيا‬‎)", "mr", "222", "+...-..-..-...."],
    ["Mauritius (Moris)", "mu", "230", "+...-...-...."],
    ["Mayotte", "yt", "262", ""],
    ["Mexico (México)", "mx", "52", "+..-..-..-...."],
    ["Micronesia", "fm", "691", "+...-...-...."],
    ["Moldova (Republica Moldova)", "md", "373", "+...-....-...."],
    ["Monaco", "mc", "377", "+...-..-...-..."],
    ["Mongolia (Монгол)", "mn", "976", "+...-..-..-...."],
    ["Montenegro (Crna Gora)", "me", "382", "+...-..-...-..."],
    ["Montserrat", "ms", "1664", "+.(...)...-...."],
    ["Morocco (‫المغرب‬‎)", "ma", "212", "+...-..-....-..."],
    ["Mozambique (Moçambique)", "mz", "258", "+...-..-...-..."],
    ["Myanmar (Burma) (မြန်မာ)", "mm", "95", "+..-...-..."],
    ["Namibia (Namibië)", "na", "264", "+...-..-...-...."],
    ["Nauru", "nr", "674", "+...-...-...."],
    ["Nepal (नेपाल)", "np", "977", "+...-..-...-..."],
    ["Netherlands (Nederland)", "nl", "31", "+.. .. ........"],
    ["New Caledonia (Nouvelle-Calédonie)", "nc", "687", "+...-..-...."],
    ["New Zealand", "nz", "64", "+.. ...-...-...."],
    ["Nicaragua", "ni", "505", "+...-....-...."],
    ["Niger (Nijar)", "ne", "227", "+...-..-..-...."],
    ["Nigeria", "ng", "234", "+...-..-...-.."],
    ["Niue", "nu", "683", "+...-...."],
    ["Norfolk Island", "nf", "672", "+...-...-..."],
    ["North Korea (조선 민주주의 인민 공화국)", "kp", "850", "+...-...-..."],
    ["Northern Mariana Islands", "mp", "1670", "+.(...)...-...."],
    ["Norway (Norge)", "no", "47", "+.. ... .. ..."],
    ["Oman (‫عُمان‬‎)", "om", "968", "+...-..-...-..."],
    ["Pakistan (‫پاکستان‬‎)", "pk", "92", "+.. ...-......."],
    ["Palau", "pw", "680", "+...-...-...."],
    ["Palestine (‫فلسطين‬‎)", "ps", "970", "+...-..-...-...."],
    ["Panama (Panamá)", "pa", "507", "+...-...-...."],
    ["Papua New Guinea", "pg", "675", "+...(...)..-..."],
    ["Paraguay", "py", "595", "+...(...)...-..."],
    ["Peru (Perú)", "pe", "51", "+..(...)...-..."],
    ["Philippines", "ph", "63", "+.. ... ...."],
    ["Pitcairn Islands", "pn", "64", ""],
    ["Poland (Polska)", "pl", "48", "+.. ...-...-..."],
    ["Portugal", "pt", "351", "+...-..-...-...."],
    ["Puerto Rico", "pr", "1", "+. (...) ...-....", 3, ["787", "939"]],
    ["Qatar (‫قطر‬‎)", "qa", "974", "+...-....-...."],
    ["Réunion (La Réunion)", "re", "262", "+...-.....-...."],
    ["Romania (România)", "ro", "40", "+..-..-...-...."],
    ["Russia (Россия)", "ru", "7", "+. ... ...-..-..", 0],
    ["Rwanda", "rw", "250", "+...(...)...-..."],
    ["Saint Barthélemy (Saint-Barthélemy)", "bl", "590", "", 1],
    ["Saint Helena", "sh", "290"],
    ["Saint Kitts and Nevis", "kn", "1869", "+.(...)...-...."],
    ["Saint Lucia", "lc", "1758", "+.(...)...-...."],
    ["Saint Martin (Saint-Martin (partie française))", "mf", "590", "", 2],
    ["Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)", "pm", "508"],
    ["Saint Vincent and the Grenadines", "vc", "1784", "+.(...)...-...."],
    ["Samoa", "ws", "685", "+...-..-...."],
    ["San Marino", "sm", "378", "+...-....-......"],
    ["São Tomé and Príncipe (São Tomé e Príncipe)", "st", "239", "+...-..-....."],
    [
        "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
        "sa",
        "966",
        "+...-..-...-....",
    ],
    ["Senegal (Sénégal)", "sn", "221", "+...-..-...-...."],
    ["Serbia (Србија)", "rs", "381", "+...-..-...-...."],
    ["Seychelles", "sc", "248", "+...-.-...-..."],
    ["Sierra Leone", "sl", "232", "+...-..-......"],
    ["Singapore", "sg", "65", "+.. ....-...."],
    ["Sint Maarten", "sx", "1721", "+.(...)...-...."],
    ["Slovakia (Slovensko)", "sk", "421", "+...(...)...-..."],
    ["Slovenia (Slovenija)", "si", "386", "+...-..-...-..."],
    ["Solomon Islands", "sb", "677", "+...-....."],
    ["Somalia (Soomaaliya)", "so", "252", "+...-.-...-..."],
    ["South Africa", "za", "27", "+..-..-...-...."],
    ["South Georgia and the South Sandwich Islands", "gs", "500", ""],
    ["South Korea (대한민국)", "kr", "82", "+..-..-...-...."],
    ["South Sudan (‫جنوب السودان‬‎)", "ss", "211", "+...-..-...-...."],
    ["Spain (España)", "es", "34", "+.. ... ... ..."],
    ["Sri Lanka (ශ්‍රී ලංකාව)", "lk", "94", "+..-..-...-...."],
    ["Sudan (‫السودان‬‎)", "sd", "249", "+...-..-...-...."],
    ["Suriname", "sr", "597", "+...-...-..."],
    ["Svalbard and Jan Mayen", "sj", "47", ""],
    ["Swaziland", "sz", "268", "+...-..-..-...."],
    ["Sweden (Sverige)", "se", "46", "+.. .. ... .. .."],
    ["Switzerland (Schweiz)", "ch", "41", "+.. .. ... .. .."],
    ["Syria (‫سوريا‬‎)", "sy", "963", "+...-..-....-..."],
    ["Taiwan (台灣)", "tw", "886", "+...-....-...."],
    ["Tajikistan", "tj", "992", "+...-..-...-...."],
    ["Tanzania", "tz", "255", "+...-..-...-...."],
    ["Thailand (ไทย)", "th", "66", "+..-..-...-..."],
    ["Timor-Leste", "tl", "670", "+...-...-...."],
    ["Togo", "tg", "228", "+...-..-...-..."],
    ["Tokelau", "tk", "690", "+...-...."],
    ["Tonga", "to", "676", "+...-....."],
    ["Trinidad and Tobago", "tt", "1868", "+.(...)...-...."],
    ["Tunisia (‫تونس‬‎)", "tn", "216", "+...-..-...-..."],
    ["Turkey (Türkiye)", "tr", "90", "+.. ... ... .. .."],
    ["Turkmenistan", "tm", "993", "+...-.-...-...."],
    ["Turks and Caicos Islands", "tc", "1649", "+.(...)...-...."],
    ["Tuvalu", "tv", "688", "+...-....."],
    ["U.S. Virgin Islands", "vi", "1340", "+.(...)...-...."],
    ["Uganda", "ug", "256", "+...(...)...-..."],
    ["Ukraine (Україна)", "ua", "380", "+...(..)...-..-.."],
    [
        "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
        "ae",
        "971",
        "+...-.-...-....",
    ],
    ["United Kingdom", "gb", "44", "+.. .... ......"],
    ["United States", "us", "1", "+. (...) ...-....", 0],
    ["United States Minor Outlying Islands", "um", "1", "", 2],
    ["Uruguay", "uy", "598", "+...-.-...-..-.."],
    ["Uzbekistan (Oʻzbekiston)", "uz", "998", "+...-..-...-...."],
    ["Vanuatu", "vu", "678", "+...-....."],
    ["Vatican City (Città del Vaticano)", "va", "39", "+.. .. .... ....", 1],
    ["Venezuela", "ve", "58", "+..(...)...-...."],
    ["Vietnam (Việt Nam)", "vn", "84", "+..-..-....-..."],
    ["Wallis and Futuna", "wf", "681", "+...-..-...."],
    ["Western Sahara", "eh", "212", "+...-..-...."],
    ["Yemen (‫اليمن‬‎)", "ye", "967", "+...-.-...-..."],
    ["Zambia", "zm", "260", "+...-..-...-...."],
    ["Zimbabwe", "zw", "263", "+...-.-......"],
];


export const COUNTRIES_LIST = [
    {
        "value": "AFG",
        "name": "Afghanistan"
    },
    {
        "value": "ALA",
        "name": "Åland Islands"
    },
    {
        "value": "ALB",
        "name": "Albania"
    },
    {
        "value": "DZA",
        "name": "Algeria"
    },
    {
        "value": "ASM",
        "name": "American Samoa"
    },
    {
        "value": "AND",
        "name": "Andorra"
    },
    {
        "value": "AGO",
        "name": "Angola"
    },
    {
        "value": "AIA",
        "name": "Anguilla"
    },
    {
        "value": "ATA",
        "name": "Antarctica"
    },
    {
        "value": "ATG",
        "name": "Antigua and Barbuda"
    },
    {
        "value": "ARG",
        "name": "Argentina"
    },
    {
        "value": "ARM",
        "name": "Armenia"
    },
    {
        "value": "ABW",
        "name": "Aruba"
    },
    {
        "value": "AUS",
        "name": "Australia"
    },
    {
        "value": "AUT",
        "name": "Austria"
    },
    {
        "value": "AZE",
        "name": "Azerbaijan"
    },
    {
        "value": "BHS",
        "name": "Bahamas"
    },
    {
        "value": "BHR",
        "name": "Bahrain"
    },
    {
        "value": "BGD",
        "name": "Bangladesh"
    },
    {
        "value": "BRB",
        "name": "Barbados"
    },
    {
        "value": "BLR",
        "name": "Belarus"
    },
    {
        "value": "BEL",
        "name": "Belgium"
    },
    {
        "value": "BLZ",
        "name": "Belize"
    },
    {
        "value": "BEN",
        "name": "Benin"
    },
    {
        "value": "BMU",
        "name": "Bermuda"
    },
    {
        "value": "BTN",
        "name": "Bhutan"
    },
    {
        "value": "VEN",
        "name": "Bolivarian Republic of Venezuela"
    },
    {
        "value": "BOL",
        "name": "Bolivia"
    },
    {
        "value": "BES",
        "name": "Bonaire, Sint Eustatius and Saba"
    },
    {
        "value": "BIH",
        "name": "Bosnia and Herzegovina"
    },
    {
        "value": "BWA",
        "name": "Botswana"
    },
    {
        "value": "BVT",
        "name": "Bouvet Island"
    },
    {
        "value": "BRA",
        "name": "Brazil"
    },
    {
        "value": "IOT",
        "name": "British Indian Ocean Territory"
    },
    {
        "value": "VGB",
        "name": "British Virgin Islands"
    },
    {
        "value": "BRN",
        "name": "Brunei Darussalam"
    },
    {
        "value": "BGR",
        "name": "Bulgaria"
    },
    {
        "value": "BFA",
        "name": "Burkina Faso"
    },
    {
        "value": "BDI",
        "name": "Burundi"
    },
    {
        "value": "CPV",
        "name": "Cabo Verde"
    },
    {
        "value": "KHM",
        "name": "Cambodia"
    },
    {
        "value": "CMR",
        "name": "Cameroon"
    },
    {
        "value": "CAN",
        "name": "Canada"
    },
    {
        "value": "CYM",
        "name": "Cayman Islands"
    },
    {
        "value": "CAF",
        "name": "Central African Republic"
    },
    {
        "value": "TCD",
        "name": "Chad"
    },
    {
        "value": "CHL",
        "name": "Chile"
    },
    {
        "value": "CHN",
        "name": "China"
    },
    {
        "value": "CXR",
        "name": "Christmas Island"
    },
    {
        "value": "CCK",
        "name": "Cocos (Keeling) Islands"
    },
    {
        "value": "COL",
        "name": "Colombia"
    },
    {
        "value": "COM",
        "name": "Comoros"
    },
    {
        "value": "COG",
        "name": "Congo"
    },
    {
        "value": "COK",
        "name": "Cook Islands"
    },
    {
        "value": "CRI",
        "name": "Costa Rica"
    },
    {
        "value": "CIV",
        "name": "Côte d’Ivoire"
    },
    {
        "value": "HRV",
        "name": "Croatia"
    },
    {
        "value": "CUB",
        "name": "Cuba"
    },
    {
        "value": "CUW",
        "name": "Curaçao Antillian"
    },
    {
        "value": "CYP",
        "name": "Cyprus"
    },
    {
        "value": "CZE",
        "name": "Czech Republic"
    },
    {
        "value": "PRK",
        "name": "Democratic People's Republic of Korea North"
    },
    {
        "value": "DNK",
        "name": "Denmark"
    },
    {
        "value": "DJI",
        "name": "Djibouti"
    },
    {
        "value": "DMA",
        "name": "Dominica"
    },
    {
        "value": "DOM",
        "name": "Dominican Republic"
    },
    {
        "value": "ECU",
        "name": "Ecuador"
    },
    {
        "value": "EGY",
        "name": "Egypt"
    },
    {
        "value": "SLV",
        "name": "El Salvador"
    },
    {
        "value": "GNQ",
        "name": "Equatorial Guinea"
    },
    {
        "value": "ERI",
        "name": "Eritrea"
    },
    {
        "value": "EST",
        "name": "Estonia"
    },
    {
        "value": "ETH",
        "name": "Ethiopia"
    },
    {
        "value": "FLK",
        "name": "Falkland Islands (Malvinas)"
    },
    {
        "value": "FRO",
        "name": "Faroe Islands"
    },
    {
        "value": "FSM",
        "name": "Federated States of Micronesia"
    },
    {
        "value": "FJI",
        "name": "Fiji"
    },
    {
        "value": "FIN",
        "name": "Finland"
    },
    {
        "value": "FRA",
        "name": "France"
    },
    {
        "value": "GUF",
        "name": "French Guiana"
    },
    {
        "value": "PYF",
        "name": "French Polynesia"
    },
    {
        "value": "ATF",
        "name": "French Southern Territories"
    },
    {
        "value": "GAB",
        "name": "Gabon"
    },
    {
        "value": "GMB",
        "name": "Gambia"
    },
    {
        "value": "GEO",
        "name": "Georgia"
    },
    {
        "value": "DEU",
        "name": "Germany"
    },
    {
        "value": "GHA",
        "name": "Ghana"
    },
    {
        "value": "GIB",
        "name": "Gibraltar"
    },
    {
        "value": "GRC",
        "name": "Greece"
    },
    {
        "value": "GRL",
        "name": "Greenland"
    },
    {
        "value": "GRD",
        "name": "Grenada"
    },
    {
        "value": "GLP",
        "name": "Guadeloupe"
    },
    {
        "value": "GUM",
        "name": "Guam"
    },
    {
        "value": "GTM",
        "name": "Guatemala"
    },
    {
        "value": "GGY",
        "name": "Guernsey"
    },
    {
        "value": "GIN",
        "name": "Guinea"
    },
    {
        "value": "GNB",
        "name": "Guinea-Bissau"
    },
    {
        "value": "GUY",
        "name": "Guyana"
    },
    {
        "value": "HTI",
        "name": "Haiti"
    },
    {
        "value": "HMD",
        "name": "Heard Island and McDonald Islands"
    },
    {
        "value": "VAT",
        "name": "Holy See (Vatican City State)"
    },
    {
        "value": "HND",
        "name": "Honduras"
    },
    {
        "value": "HKG",
        "name": "Hong Kong"
    },
    {
        "value": "HUN",
        "name": "Hungary"
    },
    {
        "value": "ISL",
        "name": "Iceland"
    },
    {
        "value": "IND",
        "name": "India"
    },
    {
        "value": "IDN",
        "name": "Indonesia"
    },
    {
        "value": "IRQ",
        "name": "Iraq"
    },
    {
        "value": "IRL",
        "name": "Ireland"
    },
    {
        "value": "IRN",
        "name": "Islamic Republic of Iran"
    },
    {
        "value": "IMN",
        "name": "Isle of Man"
    },
    {
        "value": "ISR",
        "name": "Israel"
    },
    {
        "value": "ITA",
        "name": "Italy"
    },
    {
        "value": "JAM",
        "name": "Jamaica"
    },
    {
        "value": "JPN",
        "name": "Japan"
    },
    {
        "value": "JEY",
        "name": "Jersey"
    },
    {
        "value": "JOR",
        "name": "Jordan"
    },
    {
        "value": "KAZ",
        "name": "Kazakhstan"
    },
    {
        "value": "KEN",
        "name": "Kenya"
    },
    {
        "value": "KIR",
        "name": "Kiribati"
    },
    {
        "value": "RKS",
        "name": "Kosovo"
    },
    {
        "value": "KWT",
        "name": "Kuwait"
    },
    {
        "value": "KGZ",
        "name": "Kyrgyzstan"
    },
    {
        "value": "LAO",
        "name": "Lao People's Democratic Republic"
    },
    {
        "value": "LVA",
        "name": "Latvia"
    },
    {
        "value": "LBN",
        "name": "Lebanon"
    },
    {
        "value": "LSO",
        "name": "Lesotho"
    },
    {
        "value": "LBR",
        "name": "Liberia"
    },
    {
        "value": "LBY",
        "name": "Libya"
    },
    {
        "value": "LIE",
        "name": "Liechtenstein"
    },
    {
        "value": "LTU",
        "name": "Lithuania"
    },
    {
        "value": "LUX",
        "name": "Luxembourg"
    },
    {
        "value": "MAC",
        "name": "Macao"
    },
    {
        "value": "MKD",
        "name": "Macedonia"
    },
    {
        "value": "MDG",
        "name": "Madagascar"
    },
    {
        "value": "MWI",
        "name": "Malawi"
    },
    {
        "value": "MYS",
        "name": "Malaysia"
    },
    {
        "value": "MDV",
        "name": "Maldives"
    },
    {
        "value": "MLI",
        "name": "Mali"
    },
    {
        "value": "MLT",
        "name": "Malta"
    },
    {
        "value": "MHL",
        "name": "Marshall Islands"
    },
    {
        "value": "MTQ",
        "name": "Martinique"
    },
    {
        "value": "MRT",
        "name": "Mauritania"
    },
    {
        "value": "MUS",
        "name": "Mauritius"
    },
    {
        "value": "MYT",
        "name": "Mayotte"
    },
    {
        "value": "MEX",
        "name": "Mexico"
    },
    {
        "value": "MDA",
        "name": "Moldova, Republic of"
    },
    {
        "value": "MCO",
        "name": "Monaco"
    },
    {
        "value": "MNG",
        "name": "Mongolia"
    },
    {
        "value": "MNE",
        "name": "Montenegro"
    },
    {
        "value": "MSR",
        "name": "Montserrat"
    },
    {
        "value": "MAR",
        "name": "Morocco"
    },
    {
        "value": "MOZ",
        "name": "Mozambique"
    },
    {
        "value": "MMR",
        "name": "Myanmar"
    },
    {
        "value": "NAM",
        "name": "Namibia"
    },
    {
        "value": "NRU",
        "name": "Nauru"
    },
    {
        "value": "NPL",
        "name": "Nepal"
    },
    {
        "value": "NLD",
        "name": "Netherlands"
    },
    {
        "value": "NCL",
        "name": "New Caledonia"
    },
    {
        "value": "NZL",
        "name": "New Zealand"
    },
    {
        "value": "NIC",
        "name": "Nicaragua"
    },
    {
        "value": "NER",
        "name": "Niger"
    },
    {
        "value": "NGA",
        "name": "Nigeria"
    },
    {
        "value": "NIU",
        "name": "Niue"
    },
    {
        "value": "NFK",
        "name": "Norfolk Island"
    },
    {
        "value": "MNP",
        "name": "Northern Mariana Islands"
    },
    {
        "value": "NOR",
        "name": "Norway"
    },
    {
        "value": "OMN",
        "name": "Oman"
    },
    {
        "value": "PAK",
        "name": "Pakistan"
    },
    {
        "value": "PLW",
        "name": "Palau"
    },
    {
        "value": "PAN",
        "name": "Panama"
    },
    {
        "value": "PNG",
        "name": "Papua New Guinea"
    },
    {
        "value": "PRY",
        "name": "Paraguay"
    },
    {
        "value": "PER",
        "name": "Peru"
    },
    {
        "value": "PHL",
        "name": "Philippines"
    },
    {
        "value": "PCN",
        "name": "Pitcairn"
    },
    {
        "value": "POL",
        "name": "Poland"
    },
    {
        "value": "PRT",
        "name": "Portugal"
    },
    {
        "value": "PRI",
        "name": "Puerto Rico"
    },
    {
        "value": "QAT",
        "name": "Qatar"
    },
    {
        "value": "KOR",
        "name": "Republic of Korea"
    },
    {
        "value": "REU",
        "name": "Réunion"
    },
    {
        "value": "ROU",
        "name": "Romania"
    },
    {
        "value": "RUS",
        "name": "Russian Federation"
    },
    {
        "value": "RWA",
        "name": "Rwanda"
    },
    {
        "value": "BLM",
        "name": "Saint Barthélemy"
    },
    {
        "value": "SHN",
        "name": "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
        "value": "KNA",
        "name": "Saint Kitts and Nevis"
    },
    {
        "value": "LCA",
        "name": "Saint Lucia"
    },
    {
        "value": "MAF",
        "name": "Saint Martin (French part)"
    },
    {
        "value": "SPM",
        "name": "Saint Pierre and Miquelon"
    },
    {
        "value": "VCT",
        "name": "Saint Vincent and the Grenadines"
    },
    {
        "value": "WSM",
        "name": "Samoa"
    },
    {
        "value": "SMR",
        "name": "San Marino"
    },
    {
        "value": "STP",
        "name": "Sao Tome and Principe"
    },
    {
        "value": "SAU",
        "name": "Saudi Arabia"
    },
    {
        "value": "SEN",
        "name": "Senegal"
    },
    {
        "value": "SRB",
        "name": "Serbia"
    },
    {
        "value": "SYC",
        "name": "Seychelles"
    },
    {
        "value": "SLE",
        "name": "Sierra Leone"
    },
    {
        "value": "SGP",
        "name": "Singapore"
    },
    {
        "value": "SXM",
        "name": "Sint Maarten (Dutch part)"
    },
    {
        "value": "SVK",
        "name": "Slovakia"
    },
    {
        "value": "SVN",
        "name": "Slovenia"
    },
    {
        "value": "SLB",
        "name": "Solomon Islands"
    },
    {
        "value": "SOM",
        "name": "Somalia"
    },
    {
        "value": "ZAF",
        "name": "South Africa"
    },
    {
        "value": "SGS",
        "name": "South Georgia and the South Sandwich"
    },
    {
        "value": "SSD",
        "name": "South Sudan"
    },
    {
        "value": "ESP",
        "name": "Spain"
    },
    {
        "value": "LKA",
        "name": "Sri Lanka"
    },
    {
        "value": "PSE",
        "name": "State of Palestine"
    },
    {
        "value": "SDN",
        "name": "Sudan"
    },
    {
        "value": "SUR",
        "name": "Suriname"
    },
    {
        "value": "SJM",
        "name": "Svalbard and Jan Mayen"
    },
    {
        "value": "SWZ",
        "name": "Swaziland"
    },
    {
        "value": "SWE",
        "name": "Sweden"
    },
    {
        "value": "CHE",
        "name": "Switzerland"
    },
    {
        "value": "SYR",
        "name": "Syrian Arab Republic"
    },
    {
        "value": "TWN",
        "name": "Taiwan, Province of China"
    },
    {
        "value": "TJK",
        "name": "Tajikistan"
    },
    {
        "value": "THA",
        "name": "Thailand"
    },
    {
        "value": "COD",
        "name": "The Democratic Republic of the Congo"
    },
    {
        "value": "TLS",
        "name": "Timor-Leste"
    },
    {
        "value": "TGO",
        "name": "Togo"
    },
    {
        "value": "TKL",
        "name": "Tokelau"
    },
    {
        "value": "TON",
        "name": "Tonga"
    },
    {
        "value": "TTO",
        "name": "Trinidad and Tobago"
    },
    {
        "value": "TUN",
        "name": "Tunisia"
    },
    {
        "value": "TUR",
        "name": "Turkey"
    },
    {
        "value": "TKM",
        "name": "Turkmenistan"
    },
    {
        "value": "TCA",
        "name": "Turks and Caicos Islands"
    },
    {
        "value": "TUV",
        "name": "Tuvalu"
    },
    {
        "value": "UGA",
        "name": "Uganda"
    },
    {
        "value": "UKR",
        "name": "Ukraine"
    },
    {
        "value": "ARE",
        "name": "United Arab Emirates"
    },
    {
        "value": "GBR",
        "name": "United Kingdom"
    },
    {
        "value": "TZA",
        "name": "United Republic of Tanzania"
    },
    {
        "value": "USA",
        "name": "United States"
    },
    {
        "value": "UMI",
        "name": "United States Minor Outlying Islands"
    },
    {
        "value": "ZZZ",
        "name": "Unknown"
    },
    {
        "value": "URY",
        "name": "Uruguay"
    },
    {
        "value": "UZB",
        "name": "Uzbekistan"
    },
    {
        "value": "VUT",
        "name": "Vanuatu"
    },
    {
        "value": "VNM",
        "name": "Vietnam"
    },
    {
        "value": "VIR",
        "name": "Virgin Islands, U.S."
    },
    {
        "value": "WLF",
        "name": "Wallis and Futuna"
    },
    {
        "value": "ESH",
        "name": "Western Sahara"
    },
    {
        "value": "YEM",
        "name": "Yemen"
    },
    {
        "value": "ZMB",
        "name": "Zambia"
    },
    {
        "value": "ZWE",
        "name": "Zimbabwe"
    }
]

export const ETHNICITY_LIST = [
    {
        name: 'Ethnicity Not Found',
        value: '1'
    },
    {
        name: 'ANY OTHER ETHNIC CATEGORY',
        value: '2'
    },
    {
        name: 'ANY OTHER MIXED GROUP',
        value: '3'
    },
    {
        name: 'BANGLADESHI',
        value: '4'
    },
    {
        name: 'BLACK - AFRICAN',
        value: '5'
    },
    {
        name: 'BLACK - CARIBBEAN',
        value: '6'
    },
    {
        name: 'BLACK - OTHER',
        value: '7'
    },
    {
        name: 'CHINESE',
        value: '8'
    },
    {
        name: 'INDIAN',
        value: '9'
    },
    {
        name: 'ISC - UNSPECIFIED',
        value: '10'
    },
    {
        name: 'OTHER / MIXED',
        value: '11'
    },
    {
        name: 'PAKISTANI',
        value: '12'
    },
    {
        name: 'UNKNOWN',
        value: '13'
    },
    {
        name: 'WHITE',
        value: '14'
    },
    {
        name: 'WHITE AND ASIAN',
        value: '15'
    },
    {
        name: 'WHITE AND BLACK AFRICAN',
        value: '16'
    },
    {
        name: 'WHITE AND BLACK CARIBBEAN',
        value: '17'
    },
    {
        name: 'WHITE BRITISH',
        value: '18'
    },
    {
        name: 'WHITE IRISH',
        value: '19'
    },
    {
        name: 'WHITE OTHER',
        value: '20'
    },
]

export const VACCINATION_STATUS = [
    {
        name: 'Complete Vaccination',
        value: '01'
    },
    {
        name: 'Incomplete Vaccination',
        value: '02'
    },
    {
        name: 'Not Vaccinated',
        value: '03'
    }
]

export const TRAVEL_METHOD_LIST = [
    {
        name: 'Flight',
        value: '01'
    },
    {
        name: 'Coach',
        value: '02'
    },
    {
        name: 'Ship/Boat',
        value: '03'
    },
    {
        name: 'Rail',
        value: '04'
    }
]


export const getCountry = (value) => {
    var country = null
    if (typeof value === "object") {
        return value
    }
    else {
        country = COUNTRIES_LIST?.filter((item) => item?.name?.toLowerCase() === value?.toLowerCase() || item?.value?.toLowerCase() === value?.toLowerCase())
        if (country.length >= 1) {
            return country[0]
        }
        else {
            return ""
        }

    }
}

export const getPreferredTime = (serviceType, preferredTime) => {
    if (preferredTime) {
        let prfTime = PREFERRED_TIMES.find((time) => time.value === preferredTime.replaceAll(" ", "").trim());
        console.log(serviceType === "Same Day" && parseInt(prfTime.preferredToTime.slice(0, 2)) > 13, "serviceType")
        if (serviceType === "Same Day" && parseInt(prfTime.preferredToTime.slice(0, 2)) > 13) {
            return undefined
        }
        else {
            return prfTime;
        }
    }
    else
        return undefined
}

export const getServiceType = (serviceType) => {
    if (serviceType) {
        let srvType = SERVICE_TYPES.find((type) => type.name === serviceType.trim());
        return srvType;
    }
    else
        return undefined
}

export const getTelephoneNumber = (telephone) => {
    if (telephone) {
        if (telephone.includes("+") || telephone.includes("-") || telephone.includes("(")) {
            return telephone;
        }
        else if (telephone.startsWith("00")) {
            return `+${telephone.substring(2)}`
        }
        else {
            return `+44${telephone}`
        }
    }
    else {
        return "+44"
    }
}

export const getGender = (gender) => {
    if (gender && gender.trim()) {
        let gd = GENDER_LIST.find((gndr) => gndr.value === gender.toUpperCase().trim());
        return gd;
    }
    else
        return undefined
}

export const getReferedTimeAccordingToTodaysTime = (date, serviceType) => {

    let tempPreferedTime = PREFERRED_TIMES
    let tempPreferedTime2 = []
    let preferredTimeValue = "no-change-in-value"
    if (serviceType === 'Same Day') {
        tempPreferedTime = tempPreferedTime?.slice(0, 5)
    }
    let filteredPreferedTime = tempPreferedTime
    tempPreferedTime2 = filteredPreferedTime
    let loopBreak = false



    if (date !== "" && date !== "Invalid Date") {
        // console.log(date, "hey date")
        if (date?.toString().slice(0, 15) === new Date()?.toString().slice(0, 15)) {

            let hour = parseInt(date.toString().slice(16, 18))
            let min = parseInt(date.toString().slice(19, 21))

            if (hour === 0 && min === 0) {
                hour = parseInt(new Date().toString().slice(16, 18))
                min = parseInt(new Date().toString().slice(19, 21))
            }

            tempPreferedTime.map((time, key) => {
                const preferredFromTime = parseInt(time.preferredToTime.slice(0, 2))
                console.log(preferredFromTime)
                if (!loopBreak) {


                    if (hour > 19 || (hour === 19 && min >= 0)) {
                        filteredPreferedTime = []
                        //preferredTimeValue = "change-to-null"
                        loopBreak = true
                    }
                    else {
                        if (hour < preferredFromTime || (hour === preferredFromTime && min === 0)) {
                            console.log(hour, preferredFromTime, key, filteredPreferedTime, "hey")
                            filteredPreferedTime = tempPreferedTime2?.slice(key, tempPreferedTime2.length)
                            //preferredTimeValue = "change-to-null"
                            loopBreak = true
                        }
                        else {
                            console.log(tempPreferedTime2?.slice(key + 1, tempPreferedTime2.length), key, "check")
                            filteredPreferedTime = tempPreferedTime2?.slice(key + 1, tempPreferedTime2.length)
                            if (filteredPreferedTime.length === 0) {
                                //preferredTimeValue = "change-to-null"
                            }
                        }
                    }
                }
            })
        }
    }
    return {
        preferredTime: filteredPreferedTime,
        preferredTimeValue: preferredTimeValue
    };
}
