import React, { useEffect } from 'react'
import "./assets/styles/main.scss"
//import 'bootstrap/dist/css/bootstrap.css';
//ROUTE_CHANGES
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Switch, Route, useLocation } from 'react-router';
import { ConnectedRouter } from 'connected-react-router'
import { AppColumn, MainColumn, NavigationBar, FooterBar } from './components'
import {
	HomePage,
	AboutUsPage,
	ForBusinessesPage,
	ForIndividualPage,
	ForHealthPractitionersPage,
	KitInstructionsPage,
} from './pages/marketing'
import {
	ContactUsPage,
	TermsAndConditionsPage,
	PrivacyPolicyPage,
	StyleguidePage,
	DataProtectionPage,
	TestToReleasePage,
} from './pages'

import {
	AuthPage,
	LoginPage,
	ForgotPasswordPage,
	ChangePasswordPage,
	ResetPasswordPage,
	SignupPage,
	AccountApproval
} from './pages/authentication'
import { RetrieveTestResultsPage } from './pages/candidate'
import {
	CustomerHomePage,
	PlaceOrderPage,
	OrderSelfTestKitsPage,
	OrderKitsAdminPage,
	OrderHealthPractitionerTestKitsPage,
	OrderHistoryPage,
	DeliveryAddressPage,
	UpdateDeliveryAddressPage,
	KitsDataPage,
	AssignTestKitPage,
	OrderCourierPage,
	//PlaceOrderSameDayPage,
	AssignSameDayKitsPage,
	PrintSameDayKitPage
} from './pages/customers'
import { BulkAssignTestKitPage } from './pages/customers/BulkAssignTestKitPage'
import BulkAssignSameDayTestKitPage from './pages/customers/bulk.order.index'
import { SuperUser, Users, Organisations } from './pages/admin'
import { ResetScroll } from './utils'
import { BackendProvider } from './utils/backend'
import PlaceOrderSameDayPageV2 from './pages/customers/place.order.same.day.v2/index'
import OrderKitsSameDayPage from './pages/customers/OrderKitsSameDayPage/index'
import AppContainer from './app.container.index'
import DashboardPage from './pages/dashboard/index'
import ViewWronglyAssignedKits from './pages/view.wrongly.assigned.orders/index'
import ScheduleTests from './pages/schedule-tests/index';
import ConfirmOrders from './pages/confirm.orders';
import ConfirmationForm from './pages/confirmation-form/index';
import Barcode from "./pages/barcode/Barcode";

const CUSTOMER_PATH = 'customer'
const ADMIN_PATH = 'admin'
const PATIENT_PATH = 'candidate'

const App = (props) => {
	const location = useLocation();

	useEffect(() => { }, [location])

	return (
		<AppContainer>
			<ConnectedRouter history={props.history}>
				<AppColumn>
					{/* <Router> */}
					<BackendProvider>
						<ResetScroll />
						{/* {(props.history.location.pathname !== '/login') && (props.history.location.pathname !== '/signup') && */}
						<NavigationBar />
						{/* } */}
						<MainColumn>
							<Switch>
								{/* Retail Customers Routes */}
								<Route path="/retail/signup" component={SignupPage} />

								<Route path="/test-results" component={RetrieveTestResultsPage} />
								<Route path={`/${PATIENT_PATH}/test-results`} component={RetrieveTestResultsPage} />

								<Route
									path={`/${CUSTOMER_PATH}/change-password`}
									exact
									component={ChangePasswordPage}
								/>
								<Route path={`/auth`} component={AuthPage} />
								<Route path={`/${CUSTOMER_PATH}/account-approval`} exact component={AccountApproval} />
								<Route path={`/${CUSTOMER_PATH}`} exact component={CustomerHomePage} />
								<Route path={`/${CUSTOMER_PATH}/schedule-tests`} exact component={ScheduleTests} />
								<Route path={`/${CUSTOMER_PATH}/kits-data`} component={KitsDataPage} />
								<Route path={`/${CUSTOMER_PATH}/dashboard`} component={DashboardPage} />
								<Route path={`/wrongly-assigned-kits`} component={ViewWronglyAssignedKits} />
								<Route path={`/${CUSTOMER_PATH}/place-order`} component={PlaceOrderPage} />
								{/* <Route path={`/${CUSTOMER_PATH}/place-order-same-day`} component={PlaceOrderSameDayPage} /> */}
								<Route path={`/${CUSTOMER_PATH}/place-order-same-day-v2`} component={PlaceOrderSameDayPageV2} />
								<Route
									path={`/${CUSTOMER_PATH}/order-self-test-kits`}
									component={OrderSelfTestKitsPage}
								/>
								<Route path={`/${CUSTOMER_PATH}/order-admin-kits`} component={OrderKitsAdminPage} />
								<Route path={`/${CUSTOMER_PATH}/order-same-day-kits`} component={OrderKitsSameDayPage} />
								<Route
									path={`/${CUSTOMER_PATH}/order-professional-test-kits`}
									component={OrderHealthPractitionerTestKitsPage}
								/>
								<Route path={`/${CUSTOMER_PATH}/order-history`} component={OrderHistoryPage} />
								<Route path={`/${CUSTOMER_PATH}/order-courier`} component={OrderCourierPage} />

								<Route
									path={`/${CUSTOMER_PATH}/new-delivery-address`}
									component={DeliveryAddressPage}
								/>
								<Route
									path={`/${CUSTOMER_PATH}/update-delivery-address`}
									component={UpdateDeliveryAddressPage}
								/>
								<Route path={`/${CUSTOMER_PATH}/assign-test-kit`} component={AssignTestKitPage} />
								<Route path={`/${CUSTOMER_PATH}/assign-same-day-kit`} component={AssignSameDayKitsPage} />
								<Route path={`/confirm-orders`} component={ConfirmOrders} />
								<Route path={`/barcode`} component={Barcode} />
								<Route path={`/${CUSTOMER_PATH}/bulk-assign-test-kit`} component={BulkAssignTestKitPage} />
								<Route path={`/${CUSTOMER_PATH}/bulk-assign-sameday-test-kit`} component={BulkAssignSameDayTestKitPage} />
								<Route path={`/${CUSTOMER_PATH}/print-same-day-kit/:kitId`} component={PrintSameDayKitPage} />
								<Route path={`/${ADMIN_PATH}/manage-users`} component={Users} />
								<Route path={`/${ADMIN_PATH}/manage-organisations`} component={Organisations} />
								<Route path={`/${ADMIN_PATH}`} component={SuperUser} />

								<Route path={`/about-us`} component={AboutUsPage} />
								<Route path={`/for-individuals`} component={ForIndividualPage} />
								<Route path={`/for-businesses`} component={ForBusinessesPage} />
								<Route path={`/for-health-practitioners`} component={ForHealthPractitionersPage} />
								<Route path={`/test-kit-instructions`} component={KitInstructionsPage} />
								<Route path={`/contact-us`} component={ContactUsPage} />
								{/* <Route path={`/test-to-release`} component={TestToReleasePage} /> */}
								<Route path={`/confirmation-form/:kitId`} component={ConfirmationForm} />
								<Route path={`/privacy-policy`} component={PrivacyPolicyPage} />
								<Route path={`/terms-and-conditions`} component={TermsAndConditionsPage} />

								<Route path={`/signup`} component={SignupPage} />

								<Route path="/login" component={LoginPage} />
								<Route path="/forgot-password" component={ForgotPasswordPage} />
								<Route path="/reset-password" component={ResetPasswordPage} />

								<Route path={`/styleguide`} component={StyleguidePage} />
								<Route path={`/styleguide/:workflowStep`} component={StyleguidePage} />

								<Route path="/dataprotection" component={DataProtectionPage} />
								<Route path="/dataprotection/requested" component={DataProtectionPage} />

								<Route path={`/`} component={HomePage} />
								<Route path={`/home`} component={HomePage} />
							</Switch>
						</MainColumn>



						{/* {(props.history.location.pathname !== '/login') && (props.history.location.pathname !== '/signup') && */}
						<FooterBar />
						{/* } */}
					</BackendProvider>
					{/* </Router> */}
				</AppColumn>
			</ConnectedRouter>
		</AppContainer>
	)
}

export { App }
