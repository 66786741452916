import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, CircularProgress } from '@material-ui/core';
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
// import CustomCalender from 'components/common/custom.calender/custom.calender';
import Calender from 'components/common/custom.calender/Calender';
import MaterialAutoComplete from 'components/common/material.autocomplete/material.autocomplete';
import MaterialAutoCompleteTypeable from 'components/common/material.autocomplete.typeable/material.autocomplete.typeable';
import { PREFERRED_TIMES, SERVICE_TYPES } from 'components/common/util/util';
// import moment from 'moment';
import { GENDER_LIST } from 'components/common/util/util';
import { getPastDate } from 'components/common/util/util';
import InfoValidationDialog from './info.validation.dialog';
import { COUNTRIES_LIST, COUNTRY_CODES, getCountry, getReferedTimeAccordingToTodaysTime } from '../../../components/common/util/util';
import { ReactTelephoneInput } from 'react-telephone-input';
import LoaderButton from '../../../components/common/loader.button/loader.button';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        display: 'flex',
        //justifyContent: 'center',
        marginBottom: theme.spacing(2),
    },
    tableContainer: {
        width: '100%',
        //height: '300px',
        overflow: 'auto'
    },
    table: {
        width: '100%',
        // minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const headCells = [
    {
        label: "No",
        id: "no"
    },
    {
        label: "Email",
        id: "email",
        required: true
    },
    {
        label: "Date of Test",
        id: "dateOfTest",
        required: true
    },
    {
        label: "Service Type",
        id: "serviceType",
        required: true
    },
    {
        label: "Available Time Window",
        id: "preferredTime",
        required: true
    },
    {
        label: "Test Postcode",
        id: "postcode",
        required: true
    },
    {
        label: "Test Address Line 1",
        id: "addressLine1",
        required: true
    },
    {
        label: "Test Address Line 2",
        id: "addressLine2",
    },
    {
        label: "Test City",
        id: "city",
        required: true
    },
    {
        label: "Test Country",
        id: "country",
        required: true
    },
    {
        label: "First Name",
        id: "givenName",
        required: true
    },
    {
        label: "Last Name",
        id: "familyName",
        required: true
    },
    {
        label: "Full Name",
        id: "fullName",
        required: true
    },
    {
        label: "Phone",
        id: "telephone",
        required: true
    },
    {
        label: "Date of Birth",
        id: "DOB"
    },
    {
        label: "Gender",
        id: "gender",
    },

    {
        label: "Home Postcode",
        id: "homeAddressPostcode"
    },
    {
        label: "Home Address Line 1",
        id: "homeAddressLine1"
    },
    {
        label: "Home Address Line 2",
        id: "homeAddressLine2"
    },
    {
        label: "Home City",
        id: "homeAddressCity"
    },
    {
        label: "Home Country",
        id: "homeAddressCountry"
    },
    {
        label: "Document Number",
        id: "documentNumber"
    },
    {
        label: "Health Number",
        id: "healthNumber"
    },
    {
        label: "Document Type",
        id: "documentType"
    },
    {
        label: "Issuing Country",
        id: "documentCountry"
    },
    {
        label: "Document Expiration",
        id: "documentExpiration"
    },
    {
        label: "Document Authority",
        id: "documentAuthority"
    }
];



function EnhancedTableHead(props) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        style={{ fontSize: '16px' }}
                        className="font-weight-bold"
                        key={headCell.id} style={{ backgroundColor: "rgb(6, 122, 184)" }}>
                        {headCell.label}
                        {
                            headCell?.required && <span style={{ color: "red" }}>*</span>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

let contryList = COUNTRY_CODES.map((cnt) => {
    return { name: cnt[0], iso2: cnt[1], dialCode: cnt[2], priority: 0, format: cnt[3] }
})
export default function EditableTable(props) {
    const { rows, execStatusScheduleTest, submitPatientDetails } = props;
    const [state, setState] = useState({
        showErrorDialog: false
    })
    const [selectedRow, setSelectedRow] = useState(null)
    const classes = useStyles();

    const showErrorDialog = () => {
        setState({ ...state, showErrorDialog: true })
    }
    const closeErrorDialog = () => {
        setState({ ...state, showErrorDialog: false })
    }

    // console.log('row', rows && rows)

    return (
        <div className={classes.root}>
            <Paper elevation={0} className={clsx(classes.paper, 'pt-5')}>
                <TableContainer className={classes.tableContainer} style={{ width: "95vw" }}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'small'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                        />
                        <TableBody>
                            {
                                rows && rows.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    let preferredTimeWindow = row.preferredTime;
                                    const preferredTime = getReferedTimeAccordingToTodaysTime(new Date(row.dateOfTest), row.serviceType?.serviceTypeText)
                                    // if (preferredTime.preferredTimeValue === "change-to-null") {
                                    //     preferredTimeWindow = ""
                                    //     // props.updateData(row.serialNumber, 'preferredTime', "")
                                    // }
                                    const preferredTimes = preferredTime.preferredTime
                                    console.log(row, "row g row")
                                    return (
                                        <TableRow
                                            hover
                                            style={{
                                                background: row.executionStatus === "PENDING" ? '#fcffcd' : row.executionStatus === "DONE" ? "#b1ffb1" : row.executionStatus === "ERROR" ? '#ffcdcd' : 'inherit'
                                            }}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.serialNumber}>
                                            <TableCell id={labelId} scope="row" >
                                                <div className="pt-3 d-flex align-items-center">
                                                    <div>
                                                        {index + 1}
                                                    </div>
                                                    <div className="pl-3">
                                                        {row.executionStatus === "PENDING" ? <CircularProgress size={15} /> : null}
                                                    </div>
                                                    <div className="pl-2">
                                                        <div style={{ cursor: 'pointer' }}
                                                            className="text-primary cursor-pointer"
                                                            onClick={() => props.onDelete(row.serialNumber)}>
                                                            {(row.executionStatus === "DONE" || row.executionStatus === "PENDING") ? '' : 'Delete'}

                                                        </div>
                                                    </div>
                                                    <div
                                                        onClick={() => { showErrorDialog(); setSelectedRow(rows[index]) }}
                                                    >
                                                        {row.executionStatus === "ERROR" ? <div style={{
                                                            cursor: 'pointer',
                                                            width: 'max-content'
                                                        }} className="text-danger pl-3 cursor-pointer">View Error</div> : null}
                                                    </div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'email', e.target.value)}
                                                    value={row.email} placeholder="Email" />
                                            </TableCell>
                                            <TableCell align="left">
                                                {/* <CustomCalender
                                                    defaultValue={moment(row.dateOfTest, "DD/MM/YYYY")}
                                                    placeholder="Select Date of Test"
                                                    minDate={getPastDate(0)}
                                                    onAccept={(date) => props.updateData(row.serialNumber, 'dateOfTest', date)}
                                                /> */}
                                                {/* <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'email', e.target.value)}
                                                    value={new Date(row.dateOfTest)} placeholder="Email" /> */}
                                                <Calender
                                                    value={row.dateOfTest ? (new Date(row.dateOfTest).toString() !== "Invalid Date" ? row.dateOfTest?._d : null) : null}
                                                    placeholder="Select Date of Test"
                                                    minDate={getPastDate(0)}
                                                    isDayRequired
                                                    getSelectedDate={(date) => { props.updateData(row.serialNumber, 'dateOfTest', date) }}
                                                //onAccept={(date) => onAccept(date, "fromDate")}
                                                //value={new Date()}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <MaterialAutoComplete
                                                    selectedItem={row.serviceType}
                                                    onSelect={(value, data) => props.updateData(row.serialNumber, 'serviceType', data)}
                                                    //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                                    //onSelect={props.onKitStatusChange}
                                                    placeholder="Select service type"
                                                    data={SERVICE_TYPES}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {
                                                    preferredTimes.length === 0
                                                        ?
                                                        <>
                                                            {
                                                                row.preferredTime !== "" && props.updateData(row.serialNumber, 'preferredTime', "")
                                                            }
                                                            <span className="mt-1" style={{ color: "red" }}>change test date or service type to select </span>
                                                        </>
                                                        :
                                                        <MaterialAutoComplete
                                                            selectedItem={preferredTimeWindow}
                                                            onSelect={(value, data) => props.updateData(row.serialNumber, 'preferredTime', data)}
                                                            //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                                            //onSelect={props.onKitStatusChange}
                                                            placeholder="Time Window"
                                                            data={preferredTimes}
                                                        />
                                                }

                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'postcode', e.target.value)}
                                                    value={row.postcode} placeholder="Test Postcode" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'addressLine1', e.target.value)}
                                                    value={row.addressLine1} placeholder="Test Address Line 1" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'addressLine2', e.target.value)}
                                                    value={row.addressLine2} placeholder="Test Address Line 2" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'city', e.target.value)}
                                                    value={row.city} placeholder="Test City" />
                                            </TableCell>

                                            <TableCell align="left">
                                                {/*{row.country}*/}
                                                {/* <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'country', e.target.value)}
                                                    value={row?.country} placeholder="Test Country" /> */}
                                                <MaterialAutoCompleteTypeable
                                                    // startAdornment="Document Country"
                                                    style={{ width: "max-content" }}
                                                    selectedItem={getCountry(row?.country)}
                                                    onSelect={(value, data) => props.updateData(row.serialNumber, 'country', data)}
                                                    placeholder="Select Country from a list"
                                                    data={COUNTRIES_LIST}
                                                />
                                            </TableCell>

                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'givenName', e.target.value)}
                                                    value={row.givenName} placeholder="Given Name" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'familyName', e.target.value)}
                                                    value={row.familyName} placeholder="Family Name" />
                                            </TableCell>
                                            <TableCell id={labelId} scope="row" >
                                                <div style={{ width: "283px" }}>
                                                    <SiteLabelTextField
                                                        disabled
                                                        onChange={() => { }}
                                                        value={row.fullName} placeholder="Full Name" />
                                                </div>
                                            </TableCell>
                                            <TableCell align="left">
                                                {/* <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'telephone', e.target.value)}
                                                    value={row.telephone} placeholder="Telephone" /> */}
                                                <div style={{ width: "283px" }}>
                                                    {
                                                        <ReactTelephoneInput
                                                            defaultCountry="gb"
                                                            preferredCountries={['gb', 'us']}
                                                            value={row.telephone}
                                                            onlyCountries={contryList}
                                                            onChange={(telNumber, selectedCountry) => props.updateData(row.serialNumber, 'telephone', telNumber)}
                                                            flagsImagePath={`${process.env.PUBLIC_URL}/flags.png`}
                                                        //onChange={handleInputChange}
                                                        />
                                                    }
                                                </div>
                                            </TableCell>

                                            <TableCell align="left">
                                                {/* <CustomCalender
                                                    defaultValue={moment(row.DOB, "DD/MM/YYYY")}
                                                    placeholder="Select Date of Birth"
                                                    onAccept={(date) => props.updateData(row.serialNumber, 'DOB', date)}
                                                /> */}

                                                <Calender
                                                    value={row.DOB ? (new Date(row.DOB).toString() !== "Invalid Date" ? row.DOB?._d : null) : null}
                                                    placeholder="Select Date of Birth"
                                                    getSelectedDate={(date) => { props.updateData(row.serialNumber, 'DOB', date) }}
                                                //onAccept={(date) => onAccept(date, "fromDate")}
                                                //value={new Date()}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <MaterialAutoComplete
                                                    selectedItem={row.gender}
                                                    onSelect={(value, data) => props.updateData(row.serialNumber, 'gender', data)}
                                                    //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                                    //onSelect={props.onKitStatusChange}
                                                    placeholder="Select gender"
                                                    data={GENDER_LIST}
                                                />
                                            </TableCell>

                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'homeAddressPostcode', e.target.value)}
                                                    value={row.homeAddressPostcode} placeholder="Home Postcode" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'homeAddressLine1', e.target.value)}
                                                    value={row.homeAddressLine1} placeholder="Home Address Line 1" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'homeAddressLine2', e.target.value)}
                                                    value={row.homeAddressLine2} placeholder="Home Address Line 2" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'homeAddressCity', e.target.value)}
                                                    value={row.homeAddressCity} placeholder="Home Address City" />
                                            </TableCell>

                                            <TableCell align="left">
                                                {/* <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'homeAddressCountry', e.target.value)}
                                                    value={row.homeAddressCountry} placeholder="Home Country" /> */}
                                                <MaterialAutoCompleteTypeable
                                                    // startAdornment="Document Country"
                                                    selectedItem={getCountry(row?.homeAddressCountry)}
                                                    onSelect={(value, data) => props.updateData(row.serialNumber, 'homeAddressCountry', data)}
                                                    placeholder="Select Country from a list"
                                                    data={COUNTRIES_LIST}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'documentNumber', e.target.value)}
                                                    value={row.documentNumber} placeholder="Document Number" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'healthNumber', e.target.value)}
                                                    value={row.healthNumber} placeholder="Health Number" />
                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'documentType', e.target.value)}
                                                    value={row.documentType} placeholder="Document Type" />
                                            </TableCell>
                                            <TableCell align="left">
                                                {/*{row.documentCountry}*/}
                                                {/* <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'documentCountry', e.target.value)}
                                                    value={row?.documentCountry} placeholder="Home Country" /> */}
                                                <MaterialAutoCompleteTypeable
                                                    // startAdornment="Document Country"
                                                    selectedItem={getCountry(row?.documentCountry)}
                                                    onSelect={(value, data) => props.updateData(row.serialNumber, 'documentCountry', data)}
                                                    placeholder="Select Country from a list"
                                                    data={COUNTRIES_LIST}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {/* <CustomCalender
                                                    defaultValue={moment(row.documentExpiration, "DD/MM/YYYY")}
                                                    placeholder="Document Expiry"
                                                    onAccept={(date) => props.updateData(row.serialNumber, 'documentExpiration', date)}
                                                /> */}
                                                {/* {
                                                    console.log(row.documentExpiration, row.documentExpiration, new Date(row.documentExpiration) ? (new Date(row.documentExpiration) !== "Invalid Date" ? row.documentExpiration?._d : null) : null)
                                                } */}
                                                <Calender
                                                    value={row.documentExpiration ? (new Date(row.documentExpiration).toString() !== "Invalid Date" ? row.documentExpiration?._d : null) : null}
                                                    placeholder="Select Date of Birth"
                                                    getSelectedDate={(date) => { props.updateData(row.serialNumber, 'documentExpiration', date) }}
                                                //onAccept={(date) => onAccept(date, "fromDate")}
                                                //value={new Date()}
                                                />

                                            </TableCell>
                                            <TableCell align="left">
                                                <SiteLabelTextField onChange={(e) => props.updateData(row.serialNumber, 'documentAuthority', e.target.value)}
                                                    value={row.documentAuthority} placeholder="Document Authority" />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <div className='d-flex'>
                <Button onClick={props.addNew} style={{ backgroundColor: "rgb(6, 122, 184)" }} className="ml-3">
                    Add new row
                </Button>
                <div className='ml-3'>
                    {rows.filter((data) => { return (data?.executionStatus !== "DONE") }).length === 0 ? <div className={{ color: 'lightgreen' }}>Form Submitted</div> :
                        <LoaderButton style={{ backgroundColor: "rgb(6, 122, 184)" }} loading={execStatusScheduleTest.status === "PENDING" ? true : false} onClick={submitPatientDetails}>
                            Process
                        </LoaderButton>
                    }
                </div>
            </div>

            <InfoValidationDialog handleClose={closeErrorDialog} open={state.showErrorDialog} dataToValidate={selectedRow ? selectedRow : null} />
        </div>
    );
}
