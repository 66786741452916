import React, { useState } from 'react'
import styled from 'styled-components';

const MessagesStyled = styled.div`
    display: block;
    width: 100%;

    .total-records {
        display: block;
        font-size: 20px;
        color: var(--color-blue);
    }

    .messages {
        display: block;
        margin: 2rem 0px;
        padding: 1rem 2rem;
        border: 1px solid;
        width: 100%;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 24px;

        .header {
            font-weight: bold;
            font-size: 18px;
        }

        .success {
            color: #48b748;
        }

        .failure {
            color: #ef8484;
        }

        .success, .failure {
            padding: 0.5rem 0;
        }

        .start {
            font-size: 16px;
            font-weight: 600;
            margin: 2rem 0;
        }
    }

`

export default function ProcessingMessages({ messages, totalRecords }) {
    return (
        <MessagesStyled>
            <h3 className="total-records">{totalRecords} record(s) found in the uploaded file.</h3>
            {messages.length > 0 && (
                <div className="messages">
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={message.type}
                        >
                            {message.text}
                        </div>
                    ))}
                </div>
            )}
        </MessagesStyled>
    );
}