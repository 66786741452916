import { regex } from 'components/common/util/regex'

export function isValidPatientDetail(data) {
    console.log('Patient Data validation', data)

    const isValidfullName = data.fullName !== undefined && data.fullName && data.fullName.trim() !== "" && data.fullName.length > 1;
    const isValidfamilyName = data.familyName !== undefined && data.familyName && data.familyName.trim() !== "";
    const isValidgivenName = data.givenName !== undefined && data.givenName && data.givenName.trim() !== "";
    const isValidDOB = data.DOB !== undefined && data.DOB && data.DOB !== "" && data.DOB?.toString().toLowerCase() !== "invalid date";
    const isValidtelephone = data.telephone !== undefined && data.telephone && data.telephone.trim() !== "";
    const isValidgender = data.gender !== undefined && data.gender && data.gender.value && data.gender.value.trim() !== "";
    const isValidaddressLine1 = data.addressLine1 !== undefined && data.addressLine1 && data.addressLine1.trim() !== "";

    const isValidHomeAddressLine1 = data.homeAddressLine1 !== undefined && data.homeAddressLine1 && data.homeAddressLine1.trim() !== "";
    const isValidHomeAddressCity = data.homeAddressCity !== undefined && data.homeAddressCity && data.homeAddressCity.trim() !== "";
    const isValidHomeAddressCountry = data.homeAddressCountry !== undefined && data.homeAddressCountry && data.homeAddressCountry.value && data.homeAddressCountry.value.trim() !== "";
    const isValiHomeAddressPostcode = data.homeAddressPostcode !== undefined && data.homeAddressPostcode && data.homeAddressPostcode.trim() !== "";

    const isValidEthnicity = data.ethnicity !== undefined && data.ethnicity ? data.ethnicity?.trim() !== ""  : data.ethnicity !== undefined && data.ethnicity?.value && data.ethnicity?.value.trim() !== "";
    const isValidVaccination = data.vaccinationStatus !== undefined && data.vaccinationStatus ? data.vaccinationStatus?.trim() !== ""  : data.vaccinationStatus !== undefined && data.vaccinationStatus?.value && data.vaccinationStatus?.value.trim() !== "";
    const isValidArrivalDate = data.arrivalDate !== undefined && data.arrivalDate && data.arrivalDate.trim() !== "";
    const isValidDepartureDate = data.departureDate !== undefined && data.departureDate && data.departureDate.trim() !== "";
    const isValidDepartureCountry = data.departureCountry !== undefined && data.departureCountry ? data.departureCountry?.trim() !== ""  : data.departureCountry !== undefined && data.departureCountry?.value && data.departureCountry?.value.trim() !== "";
    const isValidTravelMethod = data.travelMethod !== undefined && data.travelMethod ? data.travelMethod?.trim() !== ""  : data.travelMethod?.value !== undefined && data.travelMethod?.value && data.travelMethod?.value.trim() !== "";
    const isValidVesselNumber = data.vesselNumber !== undefined && data.vesselNumber ? data.vesselNumber?.trim() !== ""  : data.vesselNumber?.value !== undefined && data.vesselNumber?.value && data.vesselNumber?.value.trim() !== "";


    const isValidpostcode = data.postcode !== undefined && data.postcode && data.postcode.trim() !== "";
    const isValidcity = data.city !== undefined && data.city && data.city.trim() !== "";
    const isValidnationality = data.nationality !== undefined && data.nationality && data.nationality.trim() !== "";
    const isValiddocumentType = data.documentType !== undefined && data.documentType && data.documentType.trim() !== "";
    const isValidcountry = data.country !== undefined && data.country && data.country.value && data.country.value.trim() !== "";
    const isValiddocumentCountry = data.documentCountry !== undefined && data.documentCountry && data.documentCountry.value && data.documentCountry.value.trim() !== "";
    const isValiddocumentNumber = data.documentNumber !== undefined && data.documentNumber && data.documentNumber.trim() !== "";
    const isValiddocumentAuthority = data.documentAuthority !== undefined && data.documentAuthority && data.documentAuthority.trim() !== "";
    const isValiddocumentExpiration = data.documentExpiration !== undefined && data.documentExpiration && data.documentExpiration !== "";
    const isValidemail = regex.email.test(data.email?.trim()) && data.email && data.email.trim() !== ""
    let allValid = false;
    if (data.isFitToFly === "true") {
        allValid = isValidfullName && isValidfamilyName && isValidgivenName && isValidemail && isValidDOB
            && isValidtelephone && isValidgender && isValidaddressLine1 && isValidpostcode &&
            isValidHomeAddressLine1 && isValidHomeAddressCity && isValidHomeAddressCountry && isValiHomeAddressPostcode &&
            isValidcity &&
            isValiddocumentType && isValiddocumentCountry && isValiddocumentNumber &&
            isValiddocumentAuthority && isValiddocumentExpiration && isValidcountry
    }
    else if(data.serviceType === "PCR_DAY_2"){
        allValid = isValidfullName && isValidfamilyName && isValidgivenName && isValidemail && isValidDOB
            && isValidtelephone && isValidgender && isValidaddressLine1 && isValidpostcode &&
            isValidHomeAddressLine1 && isValidHomeAddressCity && isValidHomeAddressCountry && isValiHomeAddressPostcode &&
            isValidcity && isValidcountry
        && isValidEthnicity && isValidVaccination && isValidArrivalDate && isValidDepartureDate && isValidDepartureCountry &&
            isValidTravelMethod && isValidVesselNumber
    }else{
        allValid = isValidfullName && isValidfamilyName && isValidgivenName && isValidemail && isValidDOB
            && isValidtelephone && isValidgender && isValidaddressLine1 && isValidpostcode &&
            isValidHomeAddressLine1 && isValidHomeAddressCity && isValidHomeAddressCountry && isValiHomeAddressPostcode &&
            isValidcity &&
            isValidcountry
    }
    return {
        isValidemail: isValidemail,
        isValidfullName: isValidfullName,
        isValidfamilyName: isValidfamilyName,
        isValidgivenName: isValidgivenName,
        isValidDOB: isValidDOB,
        isValidcountry: isValidcountry,
        isValidtelephone: isValidtelephone,
        isValidgender: isValidgender,
        isValidaddressLine1: isValidaddressLine1,
        isValidpostcode: isValidpostcode,
        isValidcity: isValidcity,
        isValiHomeAddressPostcode: isValiHomeAddressPostcode,
        isValidHomeAddressLine1: isValidHomeAddressLine1,
        isValidHomeAddressCity: isValidHomeAddressCity,
        isValidHomeAddressCountry: isValidHomeAddressCountry,

        isValidEthnicity: isValidEthnicity,
        isValidVaccination: isValidVaccination,
        isValidArrivalDate: isValidArrivalDate,
        isValidDepartureDate: isValidDepartureDate,
        isValidDepartureCountry: isValidDepartureCountry,
        isValidTravelMethod: isValidTravelMethod,
        isValidVesselNumber: isValidVesselNumber,

        // isValidnationality: isValidnationality,
        isValiddocumentType: isValiddocumentType,
        isValiddocumentCountry: isValiddocumentCountry,
        isValiddocumentNumber: isValiddocumentNumber,
        isValiddocumentAuthority: isValiddocumentAuthority,
        isValiddocumentExpiration: isValiddocumentExpiration,
        allValid: allValid
    }
}