import DashboardPage from './dashboard';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getAllWronglyAssignedKits, getKits, getTestSummary } from '../../view.updater/actions/dashboard.actions';
import { makeSelectDashboardKits, makeSelectTestSummary, makeSelectWronglyAssignedKits } from '../../view.updater/selectors/dashboard.selectors';

const mapDispatchToProps = (dispatch) => ({
    getKits: (data) => dispatch(getKits(data)),
    getTestSummary: () => dispatch(getTestSummary()),
});

const mapStateToProps = createStructuredSelector({
    kits: makeSelectDashboardKits(),
    testSummary: makeSelectTestSummary(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(DashboardPage);