//SAME_DAY_ORDER_CHANGES
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { Row, Column, Button, Body, Spacer, BackButton } from '../../../components'
import { parseSameDayPatientCsv, useModal } from '../../../utils'
import { UploadInstructionsModal } from './UploadInstructionsModal'
import { Icon } from '../../../components/Icon'
import downloadIcon from '../../../assets/images/download-icon.svg'

const PatientCsvUploadForm = ({ onPatientsProcessing, onPatientsProcessed }) => {
	const [isInstructionsModalVisible, setIsInstructionsModalVisible] = useState(false)

	const onDrop = useCallback(
		(acceptedFiles) => {
			const file = acceptedFiles.pop()
			const reader = new FileReader()

			reader.addEventListener('load', (event) => {
				const result = event.target.result
				const patients = parseSameDayPatientCsv(result)
				console.log('parsed csv: ', patients)
				onPatientsProcessed({ patients })
			})

			reader.addEventListener('progress', (event) => {
				if (event.loaded && event.total) {
					const percent = (event.loaded / event.total) * 100
					onPatientsProcessing({ progress: percent })
				}
			})

			reader.readAsText(file)
		},
		[onPatientsProcessing, onPatientsProcessed]
	)

	const handleDownloadTemplateClick = () => {
		const templateHref = '/delivery_template_same_day.csv'
		const anchor = document.createElement('a')
		anchor.href = templateHref
		anchor.download = templateHref
		document.body.appendChild(anchor)
		anchor.click()
	}

	const [showModal] = useModal(<UploadInstructionsModal />)

	const handleBarcodeScanClick = () => {
		setIsInstructionsModalVisible(true)
	}

	const handleModalClose = () => {
		setIsInstructionsModalVisible(false)
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
	return (
		<>
			<Column desktopSize={8}>
				<Spacer spacing="m" />
				<h5 className="no-space">step 1 of 3</h5>
				<h3 className="no-space">Please upload your completed delivery template</h3>
				<Link to="/customer/order-same-day-kits">
						Add New Order
					</Link>
				<Body>
					Please upload a CSV file.
					<Link to="#" onClick={handleBarcodeScanClick}>
						See instructions
					</Link>
				</Body>
				<div
					className="bordered"
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						minHeight: '25.6rem',
						marginTop: '2.4rem',
					}}
					{...getRootProps()}
				>
					<input {...getInputProps({})} />
					{isDragActive ? (
						<Body>Drop It!</Body>
					) : (
						<>
							<Button fitted primary onClick={() => null}>
								Select template to upload
							</Button>
							<Body>or drag &amp; drop file</Body>
						</>
					)}
				</div>
				<Spacer spacing="s" />
				<Row desktopSize={8} contained>
					<BackButton />
					<Button secondary icon onClick={handleDownloadTemplateClick}>
						<Icon img={downloadIcon} type="btn-inline-left" alt="Download icon" />
						Download blank template
					</Button>
				</Row>
			</Column>
			{isInstructionsModalVisible && showModal(handleModalClose)}
		</>
	)
}

export { PatientCsvUploadForm }
