import firebase from 'firebase/app'
export const ADD_UPDATE_APP_LOADERS_STATUS = "ADD_UPDATE_APP_LOADERS_STATUS";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_APP_PROPERTIES = "SET_APP_PROPERTIES";
export const SET_ANIMATION = "SET_ANIMATION";
export const RESET_PRELOGIN = "RESET_PRELOGIN";
export const CLEAR_APP_LOADER_STATUS = "CLEAR_APP_LOADER_STATUS";
export const CANCEL_REQUEST = "CANCEL_REQUEST";
export const CANCEL_ALL_REQUEST = "CANCEL_ALL_REQUEST";
export const GET_USER_CONFIRMATION_DETAIL = "GET_USER_CONFIRMATION_DETAIL"
export const CONFIRM_PATIENT_DETAIL = "CONFIRM_PATIENT_DETAIL"
export const GET_ALL_ORGANISATIONS = "GET_ALL_ORGANISATIONS"

const REGION = process.env.REACT_APP_FIREBASE_REGION || 'europe-west2'
const funs = firebase.app().functions(REGION)

export function getUserConfirmationDetail(data) {
  return {
    firebaseFunction: funs.httpsCallable('invokeGetKitByIdCall'),
    type: GET_USER_CONFIRMATION_DETAIL,
    data
  }
}
export function getAllOrganisations() {
  return {
    firebaseFunction: funs.httpsCallable('queryOrgsCall'),
    type: GET_ALL_ORGANISATIONS
  }
}

export function confirmPatientDetail(data) {
  return {
    firebaseFunction: funs.httpsCallable('invokeConfirmPatientUpdateTestDetailCall'),
    type: CONFIRM_PATIENT_DETAIL,
    data
  }
}

export function cancelAllRequest() {
  return {
    type: CANCEL_ALL_REQUEST
  }
}
export function clearAppLoaderStatus(data) {
  return {
    type: CLEAR_APP_LOADER_STATUS,
    payload: data
  };
}

export function cancelRequest(data) {
  return {
    type: CANCEL_REQUEST,
    payload: data
  }
}

export function addUpdateAppLoadersStatus(name, status, errorId, error = {}) {
  return {
    type: ADD_UPDATE_APP_LOADERS_STATUS,
    name,
    status,
    errorId,
    error
  };
}

export function changeLanguage(language) {
  return {
    type: CHANGE_LANGUAGE,
    language
  }
}

export function setAppProperties(data) {
  return {
    type: SET_APP_PROPERTIES,
    data
  }
}

export function setAnimation(data) {
  return {
    type: SET_ANIMATION,
    data
  }
}

//DEMO CODE
// export function resetResendOtp() {
//   return {
//     type: RESET_RESEND_OTP,
//     payload: {
//       resetActionNames: ["RESEND_OTP"]
//     }
//   }
// }