import React from 'react'
import { Column, Row, Body, Spacer, Button } from '../../components'

import bagIcon from './assets/ki/bag.svg'
import boxIcon from './assets/ki/box.svg'
import mirrorIcon from './assets/ki/mirror.svg'
import penIcon from './assets/ki/pen.svg'
import sampleTubeIcon from './assets/ki/sample-tube.svg'
import selfTestFormIcon from './assets/ki/self-test-form.svg'
import sinkIcon from './assets/ki/sink.svg'
import handTowelIcon from './assets/ki/handtowel.svg'
import swabIcon from './assets/ki/swab.svg'
import tissueIcon from './assets/ki/tissue.svg'
import firstAStepIllustration from './assets/ki/step-1-form.svg'
import firstBStepIllustration from './assets/ki/step-1-tissue.svg'
import firstCStepIllustration from './assets/ki/step-1-wash-hands.svg'
import secondStepIllustration from './assets/ki/step-2-throat-swab.svg'
import thirdStepIllustration from './assets/ki/step-3-nose-swab.svg'
import fourthStepIllustration from './assets/ki/step-4-sample.svg'
import fifthStepIllustration from './assets/ki/illustration-step-5-securing-the-sample.svg'
import sixthStepIllustration from './assets/ki/illustreation-step-6-put-in-box.svg'
import seventhStepIllustration from './assets/ki/step-7-seal.svg'
import { useBreakpoint } from '../../utils/breakpoints'

const InstructionIcon = ({ icon, alt, text }) => {
	return (
		<Column desktopSize={2} tabletSize={4} mobileSize={8} centered>
			<Spacer spacing="m" />
			<img src={icon} alt={alt} />
			<Body centered>{text}</Body>
		</Column>
	)
}

const InstructionExplanation = ({ img, alt, textOffset = 0, children }) => {
	const { isMobile } = useBreakpoint()
	const to = isMobile ? 0 : textOffset

	return (
		<Row desktopSize={10} tabletSize={12} style={{ alignItems: 'center' }}>
			<Column
				desktopSize={4}
				tabletSize={6}
				className="ki instruction explanation"
				style={{ alignItems: 'flex-end', paddingRight: '3.2rem' }}
			>
				<img src={img} alt={alt} />
			</Column>
			<Column desktopSize={6} tabletSize={6} style={{ marginTop: `-${to}rem` }}>
				{children}
			</Column>
		</Row>
	)
}

const KitInstructionsPage = () => {
	return (
		<>
			<Column marketing className="ki hero" centered>
				<Column desktopSize={8}>
					<h1 className="huge centered">How to use the self-test kit</h1>
					<Body paragraph centered>
						If you have received one of Beacon’s self-test kits, it is important that you read the
						instructions below carefully before attempting to collect a sample (these instructions
						also come in the kit).
					</Body>
				</Column>
			</Column>

			<Column marketing className="ki" centered>
				<Column desktopSize={1} />
				<Column desktopSize={10} bordered centered>
					<Spacer spacing="m" />
					<h3>What you’ll need before you start</h3>
					<Row desktopSize={10} centered>
						<InstructionIcon
							icon={penIcon}
							text="A ballpoint pen"
							alt="Ballpoint pen required for Covid-19 test to fill in the form"
						/>
						<InstructionIcon icon={tissueIcon} text="Some tissues" alt="Some tissues" />
						<InstructionIcon
							icon={sinkIcon}
							text="Nearby sink"
							alt="It is recommended to have covid 19 test near sink"
						/>
						<InstructionIcon icon={handTowelIcon} text="A clean handtowel" alt="A clean handtowel" />
						<InstructionIcon icon={mirrorIcon} text="A mirror" alt="A mirror" />
					</Row>
					<Spacer spacing="m" />
				</Column>
				<Column desktopSize={1} />
			</Column>

			<Column marketing className="ki" centered>
				<Column desktopSize={1} />
				<Column desktopSize={10} bordered centered>
					<Spacer spacing="m" />
					<h3>The self-test kit contains</h3>
					<Row desktopSize={10} centered>
						<InstructionIcon icon={swabIcon} text="1x Swab" alt="Swab" />

						<InstructionIcon
							icon={sampleTubeIcon}
							text="1x Sample tube containing transport medium"
							alt="1x Sample tube containing transport medium"
						/>
						<InstructionIcon
							icon={bagIcon}
							text="1x Clear sealable bag"
							alt="1x Clear sealable bag"
						/>
						<InstructionIcon
							icon={boxIcon}
							text="1x Box (the box it came in – you’ll need this)"
							alt="1x Box (the box it came in – you’ll need this)"
						/>
						<InstructionIcon
							icon={selfTestFormIcon}
							text="1x Diagnostic test requisition form"
							alt="1x Diagnostic test requisition form"
						/>
					</Row>
					<Spacer spacing="m" />
				</Column>
				<Column desktopSize={1} />
			</Column>

			<Column marketing centered>
				<h1>Ready? Let’s get started</h1>
				<InstructionExplanation img={firstAStepIllustration} alt="step 1 – form" textOffset={3.2}>
					<h3>Step 1: Preparation</h3>
					<Body paragraph>
						Unpack your self-test kit and use the ballpoint pen to clearly fill out your details on
						the test requisition form.
					</Body>
					<Body paragraph>
						Complete the peelable label from the test requisition form (it’s on the top right of the
						form) and attach it to the sample tube.
					</Body>
				</InstructionExplanation>
				<InstructionExplanation img={firstBStepIllustration} alt="step 1 – form">
					<Body paragraph>Blow your nose to ensure nothing interferes with the test</Body>
				</InstructionExplanation>
				<InstructionExplanation img={firstCStepIllustration} alt="step 1 – form">
					<Body paragraph>
						Then wash your hands in soap and water for 20 seconds Tip: if you sing happy birthday
						twice in your head, that’s about 20 seconds.
					</Body>
				</InstructionExplanation>
				<Spacer spacing="m" />
				<Spacer spacing="m" />
				<Spacer spacing="m" />
				<InstructionExplanation
					img={secondStepIllustration}
					alt="Step 2: Taking the throat swab"
					textOffset={14.4}
				>
					<h3>Step 2: Taking the throat swab</h3>
					<Body paragraph>
						Unscrew the swab from the tube. Be careful <strong>not</strong> to touch the fabric end
						of the swab on anything
					</Body>

					<Body paragraph>
						Using a mirror, look at the back of your throat and rub the fabric end over your tonsils
						(or where they were if you’ve had them removed) <strong>5 times</strong>
					</Body>

					<Body paragraph>
						Remove the swab being careful not to touch anywhere else in your mouth
					</Body>
				</InstructionExplanation>
				<InstructionExplanation img={thirdStepIllustration} alt="Step 3: Taking the nose swab">
					<h3>Step 3: Taking the nose swab</h3>
					<Body paragraph>
						With <strong>the same swab</strong>, insert the fabric end into your nostril making sure
						you don’t touch any other part of your face, until you reach resistance (usually about
						2.5cm)
					</Body>

					<Body paragraph>
						Rotate the swab <strong>5 times</strong> against the inside of your nose. Carefully
						remove and repeat in the other nostril.
					</Body>
				</InstructionExplanation>

				<Spacer spacing="m" />
				<Spacer spacing="m" />

				<InstructionExplanation img={fourthStepIllustration} alt="Step 4: Completing the sample">
					<h3>Step 4: Completing the sample</h3>
					<Body paragraph>
						Place the swab into the small sample tube containing liquid, snapping or cutting off any
						excess stem and ensure the lid is securely closed
					</Body>

					<Body paragraph>
						<strong>Now wash your hands</strong>
					</Body>
				</InstructionExplanation>

				<Spacer spacing="m" />
				<Spacer spacing="m" />

				<InstructionExplanation img={fifthStepIllustration} alt="Step 5: Securing the sample">
					<h3>Step 5: Securing the sample</h3>
					<Body paragraph>
						Place the sample tube inside the clear plastic bag (leaving the absorbent material
						inside the bag), peel off the adhesive strip and <strong>seal firmly</strong>
					</Body>
				</InstructionExplanation>

				<Spacer spacing="m" />
				<Spacer spacing="m" />

				<InstructionExplanation img={sixthStepIllustration} alt="Step 6: Put it in the box">
					<h3>Step 6: Put it in the box</h3>
					<Body paragraph>
						Place the clear plastic bag containing the sample along with the completed requisition
						form into the UN3373 transport box
					</Body>

					<Body paragraph>
						<strong>
							NOTE: It is essential that you return the completed test requisition form with your
							swab
						</strong>
					</Body>
					<Body paragraph>Close the box carefully</Body>
				</InstructionExplanation>

				<Spacer spacing="m" />
				<Spacer spacing="m" />

				<InstructionExplanation img={seventhStepIllustration} alt="Step 7: Seal the box">
					<h3>Step 7: Seal the box</h3>
					<Body paragraph>
						Ensure the box is securely closed and the flaps are tucked inside the box
					</Body>

					<Body paragraph>
						Attach the security seal to the flap and bottom of the box, in the areas marked
					</Body>
					<Body paragraph>
						You can then either take it to your nearest post office, call us on:
						<strong> 0115 9739012</strong> to arrange courier collection or give to your healthcare
						professional / employer
					</Body>
				</InstructionExplanation>
			</Column>
			<Column marketing className="ki alternative" centered>
				<Column desktopSize={5}>
					<h1>One last check</h1>
					<Body paragraph>
						Before posting your swab collection kit, please ensure that:
						<ol>
							<li>Your details have been written on the test requisition form</li>
							<li>Your swab has been placed inside the box with the test requisition form</li>
							<li>The box has been sealed using the security seal</li>
						</ol>
					</Body>
					<Button fitted href="/contact-us">
						Get in touch
					</Button>
				</Column>
			</Column>
		</>
	)
}

export { KitInstructionsPage }
