import React, { useEffect, useState } from 'react'
import { Row, Spacer, Column, Button, LoadingAnimation, Body } from '../../../components'
import { useAuth, useAdmin, useKits } from '../../../utils/backend'
import { withAuthentication } from '../../../utils/withAuthentication'
import { OrderDisplay } from '../OrderDisplay'
import { OrganisationStats } from './OrganisationStats'

const resolveOrderView = (latestOrder, loadingOrders) => {
	if (loadingOrders) {
		return (
			<Column desktopSize={3} tabletSize={5} centered>
				<LoadingAnimation orange />
			</Column>
		)
	} else {
		const isLatestOrderDefined = latestOrder !== undefined && latestOrder !== null

		if (isLatestOrderDefined) {
			return <OrderDisplay order={latestOrder} />
		} else {
			return <Body>No Orders Yet!</Body>
		}
	}
}

const CustomerHomePage = withAuthentication(() => {
	const { customer, defaultOrganisation } = useAuth()
	let org = defaultOrganisation();
	const { queryLastestKit } = useKits()
	const { backendLoading } = useAdmin()
	const [latestOrder, setLatestOrder] = useState(null)
	const [loadingOrders, setLoadingOrders] = useState(false)

	useEffect(() => {
		if (!loadingOrders) {
			setLoadingOrders(true)
			setLatestOrder(null)

			queryLastestKit()
				.then((result) => {
					setLoadingOrders(false)
					setLatestOrder(result.data)
				})
				.catch((error) => {
					setLoadingOrders(false)
				})
		}
	}, [queryLastestKit])

	if (customer.permissions && customer.permissions.isSuperUser && backendLoading)
		return (
			<Column desktopSize={8} tabletSize={12} spacingTop="s">
				<LoadingAnimation orange />
			</Column>
		)

	return (
		<>
			<Column desktopSize={8} tabletSize={12} spacingTop="s">
				<Row desktopSize={8} tabletSize={12} spacingTop="s" centered>
					<Column desktopSize={5} tabletSize={9}>
						<h3>Welcome, {customer.displayName}</h3>
						<Body small>{customer.lastSignInTime}</Body>
					</Column>
					<Column desktopSize={3} tabletSize={3}>
						<Row style={{
							display: 'flex',
							flexWrap: 'wrap'
						}}>
							{customer.permissions && customer.permissions.isSuperUser && (
								<Button href="/customer/order-admin-kits" noSpacing>
									Place admin order
								</Button>
							)}{' '}
							&nbsp;&nbsp;&nbsp;&nbsp;
							{customer.isMedicalPractitioner && (
								<div>
									<Button primary href="/customer/assign-test-kit" noSpacing>
										Assign a test kit
									</Button>
								</div>
							)}

						</Row>
						<Row style={{
							display: 'flex',
							marginTop: "7px",
							flexWrap: 'wrap',
							alignItems: 'center'
						}}>
							{customer.isMedicalPractitioner && (
								<div>
									<Button primary href="/customer/assign-same-day-kit" noSpacing>
										Assign Same day test kits
									</Button>
								</div>
							)}
							{customer.permissions && customer.permissions.isSuperUser && (
								<div>
									<Button primary href="/customer/bulk-assign-test-kit">
										Bulk assign test kits
									</Button>
								</div>
							)}

						</Row>
						<Row style={{
							display: 'flex',
							flexWrap: 'wrap'
						}}>
							{customer.permissions && customer.permissions.isSuperUser &&
								<div className='d-flex flex-column'>
									<div className='d-flex'>
										<div>
											<Button primary href="/customer/bulk-assign-sameday-test-kit">
												Place same day bulk order
											</Button>
										</div>
										<div>
											<Button primary href="/confirm-orders">
												Confirm Tests
											</Button>
										</div>
									</div>
									<div className='w-75'>
										<Button primary href="/wrongly-assigned-kits">
											View Wrongly Assigned Kits
										</Button>
									</div>
									{/* <div>
										<Button primary href="/barcode">
											Barcodes
										</Button>
									</div> */}
								</div>
							}
						</Row>
					</Column>
				</Row>

				<div className="d-flex justify-content-between my-4">
					<div className="position-relative" style={{ width: '49%', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: '20px' }}>
						<div style={{ height: '300px' }}>
							<OrganisationStats />
						</div>

						<div className="d-flex flex-wrap justify-content-between position-absolute" style={{ bottom: '0px', padding: '20px' }} >

							{/*{customer.permissions && customer.permissions.isSuperUser &&*/}
							{/*	<div className="my-2 mr-2">*/}
							{/*		<Button primary href="/customer/data-invoices" noSpacing>*/}
							{/*			Data and invoices*/}
							{/*		</Button>*/}
							{/*	</div>*/}
							{/*}*/}
							<div className="my-2 ml-2">
								<Button primary href="/customer/dashboard" noSpacing>
									View Dashboard
								</Button>
							</div>
						</div>
					</div>

					<div className="d-flex flex-column justify-content-between align-items-start"
						style={{ width: '49%', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: '20px' }}>

						<div className="w-100">
							<h5>Your Recent Requested Test</h5>
							{resolveOrderView(latestOrder, loadingOrders)}
						</div>
						<Spacer spacing="s" />

						<div className="d-flex flex-wrap">
							{customer.permissions && customer.permissions.isSuperUser &&
								<>

									<div className="my-2 mx-2">
										<Button primary href="/admin/manage-organisations" noSpacing>
											Create Organisation
										</Button>
									</div>
									<div className="my-2 mx-2">
										<Button secondary href="/admin/manage-users" noSpacing>
											Manage Users
										</Button>
									</div>
								</>
							}
							<div className="my-2 mx-2">
								{
									org === "test-gb" ||
										// org === "riffraff13-gb" ||
										org === "curatefilms3-gb" ||
										org === "skin-iqeq-ltd-gb" ||
										org === "littleislandproductions-gb" ||
										org === "biscuitfilmworks4-gb" ||
										(customer.permissions && customer.permissions.isSuperUser) ||
										//org === "pulsefilms32-gb" ||
										org === "beaconsecure-gb" ?
										<Button secondary href="/customer/schedule-tests" noSpacing>
											Request Test(s)
										</Button>
										: null
								}
							</div>
						</div>
					</div>
				</div>

				{/*<Row desktopSize={8} tabletSize={12} spacingTop="s">*/}
				{/*	{customer.permissions && !customer.permissions.isSuperUser && (*/}
				{/*		<Column bordered desktopSize={4} tabletSize={6} spacingTop="s">*/}
				{/*			<OrganisationStats />*/}
				{/*			<Spacer spacing="m" />*/}
				{/*			/!*<Row contained desktopSize={4} tabletSize={6} style={{ marginTop: 'auto' }}>*!/*/}
				{/*			/!*	<Button primary href="/customer/kits-data" noSpacing>*!/*/}
				{/*			/!*		View Data*!/*/}
				{/*			/!*	</Button>*!/*/}
				{/*			/!*</Row>*!/*/}
				{/*			<Row contained desktopSize={4} tabletSize={6} style={{ marginTop: 'auto' }}>*/}
				{/*				<div className="mt-2">*/}
				{/*					<Button primary href="/customer/dashboard" noSpacing>*/}
				{/*						View Dashboard*/}
				{/*					</Button>*/}
				{/*				</div>*/}
				{/*			</Row>*/}
				{/*		</Column>*/}
				{/*	)}*/}
				{/*	{customer.permissions && customer.permissions.isSuperUser && (*/}
				{/*		<Column bordered desktopSize={4} tabletSize={6} spacingTop="s">*/}
				{/*			<OrganisationStats />*/}
				{/*			<Spacer spacing="m" />*/}
				{/*			<Row contained desktopSize={4} tabletSize={6} style={{ marginTop: 'auto' }}>*/}
				{/*				<Button primary href="/customer/data-invoices" noSpacing>*/}
				{/*					Data and invoices*/}
				{/*				</Button>*/}
				{/*				<Button primary href="/customer/dashboard" noSpacing>*/}
				{/*					View Dashboard*/}
				{/*				</Button>*/}
				{/*				/!*<Button primary href="/customer/kits-data" noSpacing>*!/*/}
				{/*				/!*	View Data*!/*/}
				{/*				/!*</Button>*!/*/}
				{/*			</Row>*/}
				{/*			/!*<Row contained desktopSize={4} tabletSize={6} style={{ marginTop: 'auto' }}>*!/*/}
				{/*			/!*	<div className="mt-2">*!/*/}
				{/*			/!*		<Button primary href="/customer/dashboard" noSpacing>*!/*/}
				{/*			/!*			View Dashboard*!/*/}
				{/*			/!*		</Button>*!/*/}
				{/*			/!*	</div>*!/*/}
				{/*			/!*</Row>*!/*/}
				{/*		</Column>*/}
				{/*	)}*/}
				{/*	<Column bordered desktopSize={4} tabletSize={6} spacingTop="s">*/}
				{/*		{customer.permissions && !customer.permissions.isSuperUser && (*/}
				{/*			<>*/}
				{/*				<h5>Your Orders</h5>*/}
				{/*				{resolveOrderView(latestOrder, loadingOrders)}*/}
				{/*				<Spacer spacing="m" />*/}
				{/*				<Row contained desktopSize={4} tabletSize={6} style={{ marginTop: 'auto' }}>*/}
				{/*					/!* <Button primary href="/customer/place-order" noSpacing>*/}
				{/*						Place order*/}
				{/*					</Button> *!/*/}
				{/*					/!*<Spacer spacing="s" />*!/*/}
				{/*					/!*<Button secondary href="/customer/order-history" noSpacing>*!/*/}
				{/*					/!*	Order history*!/*/}
				{/*					/!*</Button>*!/*/}
				{/*					/!*<br />*!/*/}
				{/*					/!*<br />*!/*/}
				{/*					/!*<br />*!/*/}
				{/*					{customer.permissions && customer.permissions.isOrganisationAdmin && (*/}
				{/*						<Button secondary href="/customer/manage-users" noSpacing>*/}
				{/*							Manage Users*/}
				{/*						</Button>*/}
				{/*					)}*/}
				{/*					{*/}
				{/*						org === "test-gb" ||*/}
				{/*							org === "beaconsecure-gb" ?*/}
				{/*							<Button secondary href="/customer/schedule-tests" noSpacing>*/}
				{/*								Schedule Tests*/}
				{/*							</Button> : null*/}
				{/*					}*/}
				{/*				</Row>*/}
				{/*			</>*/}
				{/*		)}*/}
				{/*		{customer.permissions && customer.permissions.isSuperUser && (*/}
				{/*			<>*/}
				{/*				<h5>Your Orders</h5>*/}
				{/*				{resolveOrderView(latestOrder, loadingOrders)}*/}
				{/*				<Spacer spacing="s" />*/}
				{/*				<Row contained desktopSize={4} tabletSize={6} style={{ marginTop: 'auto' }}>*/}
				{/*					<Button primary href="/admin/manage-organisations" noSpacing>*/}
				{/*						Create Organisation*/}
				{/*					</Button>*/}
				{/*					<Spacer spacing="s" />*/}
				{/*					<Button secondary href="/admin/manage-users" noSpacing>*/}
				{/*						Manage Users*/}
				{/*					</Button>*/}
				{/*				</Row>*/}
				{/*				<Row contained desktopSize={4} tabletSize={6} style={{ marginTop: 'auto' }}>*/}
				{/*					<Button primary href="/customer/place-order" noSpacing>*/}
				{/*						Place order*/}
				{/*					</Button>*/}
				{/*					<Spacer spacing="s" />*/}
				{/*					<Button secondary href="/customer/order-history" noSpacing>*/}
				{/*						Order history*/}
				{/*					</Button>*/}
				{/*				</Row>*/}
				{/*				<Row contained desktopSize={4} tabletSize={6} style={{ marginTop: '6px' }}>*/}
				{/*					<Button secondary href="/customer/manage-users" noSpacing>*/}
				{/*						Manage Users*/}
				{/*					</Button>*/}
				{/*					{*/}
				{/*						org === "test-gb" ||*/}
				{/*							org === "beaconsecure-gb" ?*/}
				{/*							<Button secondary href="/customer/schedule-tests" noSpacing>*/}
				{/*								Schedule Tests*/}
				{/*							</Button> : null*/}
				{/*					}*/}
				{/*				</Row>*/}
				{/*			</>*/}
				{/*		)}*/}
				{/*	</Column>*/}
				{/*</Row>*/}
			</Column>
		</>
	)
})

export { CustomerHomePage }
