import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { appTheme } from './container/material.theme/app.theme/app.material.theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

export const AppContext = React.createContext();
const AppContainer = (props) => {

    return (
        <AppContext.Provider value={{
            executions: props.executions
        }} >
            <ThemeProvider theme={appTheme}>
                <CssBaseline />
                {props.children}
            </ThemeProvider>
        </AppContext.Provider>
    )
}
export default connect(null, { push })(AppContainer);

