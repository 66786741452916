// The initial state of the App
import { fromJS } from 'immutable';
import { getActionName } from './util';
import { LOGIN, RESET_AUTH, LOGOUT } from '../actions/auth.actions';

const initialState = fromJS({
    auth: fromJS(JSON.parse(window.localStorage.getItem('auth'))) || {}
});
function authReducer(state = initialState, action) {
    switch (action.type) {
        case getActionName(LOGIN):
            window.localStorage.setItem("auth", JSON.stringify(action.response));
            window.localStorage.setItem("loginTime",new Date());
            return state.set('auth', fromJS(action.response));
        case getActionName(LOGOUT):
            window.localStorage.removeItem("auth");
            window.localStorage.removeItem("loginTime");
            return state.set('auth', '');
        case RESET_AUTH:
            window.localStorage.removeItem("auth");
            return state.set('auth', '');
        default:
            return state;
    }
}
export default authReducer;
