import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Row, Column, TextInput, Button, Body, Form, Spacer } from '../../components'
import { useAuth, useQuery } from '../../utils'

const ForgotPasswordPage = () => {
	const query = useQuery()
	const pageState = query.get('pageState') || 'initial'
	const isInitialState = pageState === 'initial'

	return isInitialState ? <RequestPasswordForm /> : <PasswordRequested />
}

const PasswordRequested = () => {
	return (
		<Row>
			<Column desktopSize={4} tabletSize={2} mobileSize={0} spacingTop="m" />
			<Column desktopSize={4} tabletSize={8} mobileSize={12} spacingTop="m">
				<h1>Forgot password?</h1>
				<Body>
					If there is an account associated with the email address provided, you will receive a
					reset password email.{' '}
				</Body>
				<Column desktopSize={4} tabletSize={8} mobileSize={12} spacingTop="m">
					<Button href="/login">Back to log in</Button>
				</Column>
			</Column>
			<Column desktopSize={4} tabletSize={2} mobileSize={0} spacingTop="m" />
		</Row>
	)
}

const RequestPasswordForm = () => {
	const [email, setEmail] = useState('')
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)

	const history = useHistory()
	const { sendPasswordResetEmail } = useAuth()

	const handlePasswordReset = async () => {
		setError(null)
		setLoading(true)

		sendPasswordResetEmail(email)
			.then(() => {
				setLoading(false)
				history.push('/forgot-password?pageState=requested')
			})
			.catch((error) => {
				setLoading(false)
				setError(error.message)
			})
	}

	return (
		<>
			<Row>
				<Column desktopSize={4} tabletSize={2} mobileSize={0} spacingTop="m" />
				<Column desktopSize={4} tabletSize={8} mobileSize={12} spacingTop="m">
					<h1>Forgot password?</h1>
					<span className="small error">{error || null}</span>
					<Body>Enter your email address to reset your password.</Body>
					<Column desktopSize={4} tabletSize={8} mobileSize={12} spacingTop="m">
						<Form onSubmit={handlePasswordReset}>
							<TextInput
								label="Email Address"
								value={email}
								setter={setEmail}
								desktopSize={4}
								tabletSize={8}
								mobileSize={12}
								disabled={loading}
							/>
							<Spacer spacing="s" />
							<Button submit loading={loading}>
								Reset password
							</Button>
						</Form>

						<Spacer spacing="m" />
						<Body className="centered">
							Go back to <Link to="/login">log in</Link>
						</Body>
					</Column>
				</Column>
				<Column desktopSize={4} tabletSize={2} mobileSize={0} spacingTop="m" />
			</Row>
		</>
	)
}

export { ForgotPasswordPage }
