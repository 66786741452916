import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Column, Spacer, Body, Form, TextInput, Button } from '../../components'
import { useKits } from '../../utils'

const RequestSecretCode = ({ onSecretCode }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [email, setEmail] = useState('')
	const { invokeRequestTestResultAccess } = useKits()
	const history = useHistory()


	const handleRequestWithEmailSubmit = () => {
		setIsLoading(true)
		invokeRequestTestResultAccess({ email })
			.then((response) => {
				setIsLoading(false)
				onSecretCode({ email: email })
				history.push('/test-results?shouldStartAgain=no')
			})
			.catch((error) => {
				setIsLoading(false)
				setErrorMessage('Error occured')
				console.error('handleRequestWithEmailSubmit', error)
			})
	}

	return (
		<>
			<Column desktopSize={6} tabletSize={6} spacingTop="m">
				<h1>Get my test results</h1>
				<span className="small error">{errorMessage || null}</span>
				<Body>
					Please enter your email address and we’ll send you a link to access your test results.{' '}
				</Body>
				<Column desktopSize={4} tabletSize={6} spacingTop="m">
					<Form onSubmit={handleRequestWithEmailSubmit}>
						<TextInput
							label="Email address"
							value={email}
							type="email"
							setter={setEmail}
							desktopSize={4}
							tabletSize={6}
						/>
						<Spacer spacing="s" />
						<Button fitted submit loading={isLoading}>
							Send my secure link
						</Button>
					</Form>
				</Column>
			</Column>
		</>
	)
}

export { RequestSecretCode }
