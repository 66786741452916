import React from "react";

export const PostCodeHandle = (inputPostValue, callback) => {
    let setPostcodeDropdown = false;
    let setErrorValue = "";
    let setResValue = [];

    fetch(`https://api.getaddress.io/find/${inputPostValue}?expand=true&api-key=qq4zoBigBEG_ZfJz0uw2uw34119`, {
        "method": "GET"
    }).then(response => {
        return response.json();
    }).then((data) => {
        setPostcodeDropdown = true;
        if (data.Message) {
            setErrorValue = data.Message;
        }
        else {
            if (data?.addresses?.length === 0) {
                setErrorValue = 'The postcode is invalid please try again';

            } else {
                setResValue = data.addresses;
                setErrorValue = '';
            }
        }
        callback({ dropdown: setPostcodeDropdown, errorValue: setErrorValue, resValue: setResValue })
    })
        .catch(err => {
        });

    setResValue = [];
    setErrorValue = '';
    setPostcodeDropdown = false;

    callback({ dropdown: setPostcodeDropdown, errorValue: setErrorValue, resValue: setResValue })


}

export const PostCodeDropdown = ({ errorValue, resValue, addressDetails, absoluteLeft, signUpPage }) => {
    return (
        <div
            style={signUpPage ? { height: "max-content", maxHeight: "200px" } : { height: "max-content", maxHeight: "340px" }}
            className={`absolute-input ${absoluteLeft ? 'absolute-left-2' : 'absolute-left'} ${errorValue ? 'absolute-error animateSearchUploadError' : ' absolute-sucess animateSearchUpload'} `}>
            {errorValue ?
                <div className={'error-style p-5'}>
                    {errorValue}
                </div>
                :
                resValue?.map((item, index) => (
                    <div className={'item-style'} key={index}
                        onClick={() => addressDetails({
                            Place: item.town_or_city, // city
                            StreetAddress: item.line_1, // addressLine1
                            addressLine2: item.line_2
                        })}
                    >
                        {item?.line_1}
                        <span className={'ml-2'}>
                            , {item?.town_or_city}
                        </span>
                    </div>
                ))}
        </div>
    )
}


export const lastWord = (words) => {
    if (words) {
        return words;
    }
}
