import './kits.scss';
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, CircularProgress, FormControlLabel, Paper } from "@material-ui/core";
import { IsMobileWidth } from "components/common/util/util";
import Pagination from '@material-ui/lab/Pagination';
import { formatDate } from 'components/common/util/util';
import clsx from 'clsx';
import { Skeleton } from "@material-ui/lab";
import moment from 'moment';
import LoaderButton from '../../../components/common/loader.button/loader.button';
import CourierStatusDialog from './courier.status.dialog';

//NOT_IN_USE
const KitsList = (props) => {
    const mobileWidth = IsMobileWidth();
    const { kits, execStatusGetKits, execStatusSendConfirmationLink, selectedEvent } = props;
    const [state, setState] = useState({
        isAllCheckBoxSelected: false,
        showCourierStatusDialogOpen: false,
        selectedKit: ''
    })
    useEffect(() => {
        props.getKits({
            pageIndex: 0,
            kitStatus: "CONFIRMATION_APPROVED"
        })
    }, [])
    const onPageChange = (event, pageNumber) => {
        window.scrollTo(0, 0);
        props.getKits({
            pageIndex: pageNumber - 1,
            kitStatus: "CONFIRMATION_APPROVED",
            eventId: selectedEvent && selectedEvent.value
        })
    }
    const handleDownloadCertificate = (url) => {
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = url
        anchor.target = "_blank"
        document.body.appendChild(anchor)
        anchor.click()
    }
    let totalCount = 0;
    if (kits && kits.get('totalCount')) {
        totalCount = Math.ceil(kits.get('totalCount') / kits.get('limit'))
    }
    const getResultClassName = (resultStatus) => {
        switch (resultStatus) {
            case "Negative":
                return "result-negative"
            case "Positive":
                return "result-positive"
            case "Inconclusive":
                return "result-inconclusive"
            default:
                return "result-default"
        }
    }
    const handleCheckBoxChange = (kit) => {
        props.updateKitData({
            kitId: kit.get('id')
        })
    }
    const handleAllCheckBoxChange = () => {
        setState({ ...state, isAllCheckBoxSelected: !state.isAllCheckBoxSelected })
        props.updateKitData({
            changeAllKitStatus: true,
            selected: !state.isAllCheckBoxSelected
        })
    }
    const sendConfirmationEmail = () => {
        for (let kit of kits && kits.get('kitsArray')) {
            if (kit && kit.get('selected') === true) {
                props.sendConfirmationLink({
                    kit: {
                        id: kit.get('id'),
                        eventName: kit.get('eventName'),
                        email: kit.get('email')?.toLowerCase().trim(),
                        orderUid: kit.get('orderUid'),
                        status: "CONFIRMATION_LINK_SENT"
                    }
                })
            }
        }
    }
    const showCourierStatusDialog = (kit) => {
        setState({ ...state, showCourierStatusDialogOpen: true, selectedKit: kit })
    }
    const hideCourierStatusDialog = () => {
        setState({ ...state, showCourierStatusDialogOpen: false, selectedKit: '' })
    }
    return (
        <div id="kit-list">
            <div className="d-flex justify-content-end pt-3">
                {
                    kits && kits.get('kitsArray') && kits.get('kitsArray').size > 0 ?
                        <LoaderButton loading={execStatusSendConfirmationLink.status === "PENDING" ? true : false} onClick={sendConfirmationEmail} color="primary" >
                            Send Confirmation Email
                        </LoaderButton> : null
                }
            </div>
            <div className="w-100 d-flex justify-content-start">
                {!(mobileWidth) ? (
                    <div className="d-flex flex-column w-100">
                        <div className="pt-3">
                            <div className="w-100 d-flex pb-2 pt-2 p-1">
                                <div className="w-5 d-flex justify-content-center align-items-center">
                                    <div>
                                        {
                                            kits && kits.get('kitsArray') && kits.get('kitsArray').size > 0 ?
                                                <FormControlLabel
                                                    className="checkbox-size ml-1"
                                                    control={
                                                        <Checkbox
                                                            size='medium'
                                                            checked={state.isAllCheckBoxSelected}
                                                            onChange={handleAllCheckBoxChange}
                                                            color="secondary"
                                                            name=""
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    }
                                                    label="" /> : null
                                        }
                                    </div>
                                </div>
                                <div className="w-15 d-flex justify-content-start align-items-start">
                                    <b>Full Name</b>
                                </div>
                                <div className="w-25 d-flex justify-content-start align-items-start">
                                    <b>Email Address</b>
                                </div>
                                <div className="w-15 d-flex justify-content-start align-items-start">
                                    <b>Telephone</b>
                                </div>
                                <div className="w-15 d-flex justify-content-start align-items-start">
                                    <b>Address</b>
                                </div>
                                <div className="w-15 d-flex justify-content-start align-items-start">
                                    <b>Post Code</b>
                                </div>
                                <div className="w-15 d-flex justify-content-center align-items-center">
                                    <b>Test Date</b>
                                </div>
                                <div className="w-15 d-flex justify-content-center align-items-center">
                                    <b>Preferred Time</b>
                                </div>
                                <div className="w-10 d-flex justify-content-center align-items-center">
                                    <b>Service Type</b>
                                </div>
                                <div className="w-5 d-flex justify-content-center align-items-center">
                                    <b>Courier Cost</b>
                                </div>
                                <div className="w-15 d-flex justify-content-center align-items-center">
                                    <b>Status</b>
                                </div>
                                <div className="w-15 d-flex justify-content-center align-items-center">
                                    <b></b>
                                </div>
                            </div>
                        </div>
                        {
                            kits && kits.get('kitsArray') && kits.get('kitsArray').map((kit, index) => {
                                return <Paper key={index} className={clsx("w-100 paper-root d-flex p-4 border-radius-10", index % 2 === 0 && "bg-light")} elevation={1}>
                                    <div className="w-5 d-flex justify-content-start align-items-center border-right">
                                        <div className="">
                                            {/* {
                                                kit.get('partnerLab') === "catalyst" ?
                                                    <Tooltip title="Same Day Test">
                                                        <Rating max={1} value={1} size="large" />
                                                    </Tooltip> : null
                                            } */}
                                        </div>
                                        {
                                            kit.get('executionStatus') === "PENDING" || kit.get('executionStatus') === "DONE" ?
                                                null : <FormControlLabel
                                                    className="checkbox-size"
                                                    control={
                                                        <Checkbox
                                                            color="secondary"
                                                            checked={kit.get('selected') === true ? true : false}
                                                            onChange={() => handleCheckBoxChange(kit)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    }
                                                    label="" />
                                        }


                                        {/* <div className="pl-2">
                                            {index + 1 + (kits.get('pageIndex') * kits.get('limit'))}
                                        </div> */}
                                    </div>
                                    <div className="w-15  ml-2 d-flex justify-content-start align-items-start border-right">
                                        {kit.get("fullName") ? kit.get("fullName") : `${kit.get('firstName')} ${kit.get('lastName')}`}
                                    </div>
                                    <div className="w-25 ml-2 d-flex justify-content-start align-items-start border-right">
                                        {kit.get("email")}
                                    </div>
                                    <div className="w-15  ml-2 d-flex justify-content-start align-items-start border-right">
                                        {kit.get("telephone")}
                                    </div>
                                    <div className="w-15  ml-2 d-flex justify-content-start align-items-start border-right">
                                        {kit.get("addressLine1")}
                                    </div>
                                    <div className="w-15  ml-2 d-flex justify-content-start align-items-start border-right">
                                        {kit.get("postcode")}
                                    </div>
                                    <div className="w-15 d-flex justify-content-center align-items-center border-right">
                                        <b> {formatDate(moment(kit.get('dateOfTest'), "DD/MM/YYYY"))}</b>
                                    </div>
                                    <div className="w-15 d-flex justify-content-center align-items-center border-right">
                                        {
                                            kit.get('preferredTime')
                                        }
                                    </div>
                                    <div className="w-10 d-flex font-weight-bold justify-content-center align-items-center border-right">
                                        {kit.get('serviceTypeText')}
                                    </div>
                                    <div className="w-5 pl-2 d-flex justify-content-start align-items-center border-right">
                                        {kit.get("courierCost") ? kit.get("courierCost") : '0'}
                                    </div>
                                    <div className="w-15 d-flex font-weight-bold justify-content-center align-items-center border-right">
                                        Approved
                                    </div>
                                    <div className="w-15 d-flex font-weight-bold justify-content-center align-items-center border-right">
                                        {kit.get('executionStatus') === "PENDING" ?
                                            <CircularProgress className="p-2" size={30} /> :
                                            kit.get('executionStatus') === "DONE" ? "Confirmation Link Sent"
                                                : null}

                                    </div>
                                </Paper>
                            })
                        }
                        {
                            totalCount > 1 ?
                                <div className="d-flex justify-content-center pt-4">
                                    <Pagination
                                        page={kits.get('pageIndex') + 1}
                                        onChange={onPageChange}
                                        count={totalCount} color="primary" />
                                </div> : null
                        }
                    </div>
                ) : (
                    <div >
                        <div>
                            <div>
                                Select All
                            </div>
                            <div>
                                {
                                    kits && kits.get('kitsArray') && kits.get('kitsArray').size > 0 ?
                                        <FormControlLabel
                                            className="checkbox-size ml-1"
                                            control={
                                                <Checkbox
                                                    size='medium'
                                                    checked={state.isAllCheckBoxSelected}
                                                    onChange={handleAllCheckBoxChange}
                                                    color="secondary"
                                                    name=""
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            }
                                            label="" /> : null
                                }

                            </div>
                        </div>
                        {
                            kits && kits.get('kitsArray') && kits.get('kitsArray').map((kit, index) => {
                                return <div key={index} className="d-flex flex-wrap justify-content-center w-100 mt-2 ml-2">
                                    <Paper elevation={1} className="w-100 paper-root d-flex flex-column p-3 mt-2">
                                        {/* <div className="d-flex w-100 justify-content-between">
                                            {
                                                kit.get('partnerLab') === "catalyst" ?
                                                    <Tooltip title="Same Day Test">
                                                        <Rating max={1} value={1} size="large" />
                                                    </Tooltip> : null
                                            }
                                        </div> */}
                                        <div className="d-flex w-100 justify-content-between">
                                            {
                                                kit.get('executionStatus') === "PENDING" || kit.get('executionStatus') === "DONE" ?
                                                    null :
                                                    <FormControlLabel
                                                        className="checkbox-size"
                                                        control={
                                                            <Checkbox
                                                                color="secondary"
                                                                checked={kit.get('selected') === true ? true : false}
                                                                onChange={() => handleCheckBoxChange(kit)}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />
                                                        }
                                                        label="" />

                                            }

                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div> {kit.get("fullName") ? kit.get("fullName") : `${kit.get('firstName')} ${kit.get('lastName')}`} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>   {kit.get("email")} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>     {kit.get("telephone")}</div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>    {kit.get("addressLine1")} {kit.get("addressLine2") && kit.get("addressLine2")}  </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>    {kit.get("postcode")} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {formatDate(moment(kit.get('dateOfTest'), "DD/MM/YYYY"))}
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {
                                                kit.get('preferredTime')
                                            }
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <b className={getResultClassName(kit.get('resultStatus'))}>
                                                {kit.get('serviceTypeText')}
                                            </b>
                                        </div>
                                        <div className="d-flex cursor-pointer w-100 justify-content-center">
                                            Approved
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {kit.get('executionStatus') === "PENDING" ?
                                                <CircularProgress className="p-2" size={30} /> :
                                                kit.get('executionStatus') === "DONE" ? "Confirmation Link Sent" :
                                                    null}
                                        </div>
                                    </Paper>
                                </div>
                            })
                        }
                        {
                            totalCount > 1 ?
                                <div className="d-flex justify-content-center pt-3">
                                    <Pagination
                                        page={kits.get('pageIndex') + 1}
                                        onChange={onPageChange}
                                        count={totalCount} color="primary" />
                                </div> : null
                        }
                    </div>
                )}
            </div>
            {
                execStatusGetKits && execStatusGetKits.status === "PENDING" ?
                    <div>
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                    </div> : null
            }
            {
                kits && kits.get('kitsArray') && kits.get('kitsArray').size == 0 ?
                    <Paper className="paper-root p-4 text-danger d-flex justify-content-center">
                        No Data Found
                    </Paper> : null
            }
            {execStatusGetKits && execStatusGetKits.status === "ERROR" ? (
                <Paper className="paper-root">
                    <div className="text-danger d-flex justify-content-center font-italic pt-2 pb-2">
                        <b>
                            {" "}
                            {execStatusGetKits.errorDetail && typeof execStatusGetKits.errorDetail === 'object'
                                ? execStatusGetKits.errorDetail.get("description")
                                : "Something isn’t working as it should, please try again"}
                        </b>
                    </div>
                </Paper>
            ) : null}
            {
                state.showCourierStatusDialogOpen === true ?
                    <CourierStatusDialog selectedKit={state.selectedKit} {...props} handleClose={hideCourierStatusDialog}
                        open={state.showCourierStatusDialogOpen} /> : null
            }
        </div>
    )
}

export default KitsList