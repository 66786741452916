const desktopGridWidth = 84.7
const desktopGridGutter = 24

const tabletGridWidth = 43.7
const tabletGridGutter = 16

const mobileGridWidth = 17.7
const mobileGridGutter = 6.9

const deviceSizes = (width, gutter) => {
	const sizes = []
	for (let s = 1; s <= 12; s++) {
		const size = width * s + gutter * (s - 1)
		sizes.push(size)
	}

	return sizes
}

const sizes = {
	mobile: deviceSizes(mobileGridWidth, mobileGridGutter),
	tablet: deviceSizes(tabletGridWidth, tabletGridGutter),
	desktop: deviceSizes(desktopGridWidth, desktopGridGutter),
}

export const getWidthForSize = (
	isMobile,
	isTablet,
	isDesktop,
	mobileSize,
	tabletSize,
	desktopSize
) => {
	if (isMobile) {
		return sizes.mobile[mobileSize - 1]
	} else if (isTablet) {
		return sizes.tablet[tabletSize - 1]
	} else if (isDesktop) {
		return sizes.desktop[desktopSize - 1]
	}
}

const spacingUnit = 0.8

const verticalSpacingMap = {
	none: '0',
	xs: `${spacingUnit / 2}rem`,
	s: `${spacingUnit * 1}rem`,
	sm: `${spacingUnit * 3}rem`,	
	m: `${spacingUnit * 4}rem`,
	l: `${spacingUnit * 6}rem`,
	xl: `${spacingUnit * 10}rem`,
	max: `120rem`,
}

export const resolveVerticalSpacing = (size = 'none') => {
	const maybeSize = verticalSpacingMap[size]

	if (typeof maybeSize === 'string') {
		return maybeSize
	} else {
		throw new Error(
			`resolveSpacing(${size}) invalid ${size}, it has to be one of ${Object.keys(
				verticalSpacingMap
			)}`
		)
	}
}
