
export default function processFirebaseFunction(firebaseFunction, data) {
  const resFirebase = firebaseFunction(data).then((response) => {
    return response && response.data;
  }).catch(error => {
      return {
          description: error.message,
          code: error.code,
          errorCode : error.code
      }
    });
  return resFirebase;
}
