import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import React, { useEffect } from 'react'

/**
 * 
 * @param {Object} props 
 * @param {Array} props.options array of objects and object must have name and value
 * @param {Object} props.selectedOption object having name and value which should be in option
 * @param {Function} props.onSelect a call back funtion which returns the selected option(object) 
 * @returns 
 */

export default function RadioButton(props) {

    const { options, selectedOption, onSelect } = props

    const getOption = (option) => {
        if (option instanceof Object) {
            return option.value
        }
        else {
            const filterOption = options.filter((item) => { return item.value === option })
            if (filterOption.length > 0) {
                return filterOption[0].value
            }
            else {
                return ""
            }
        }
    }

    return (
        <FormControl component="fieldset">
            {/* <FormLabel component="legend">Gender</FormLabel> */}
            <RadioGroup
                defaultValue={options && options[0]?.value}
                value={selectedOption ? getOption(selectedOption) : ""}
            >
                {
                    options.map((option, index) => {
                        return (
                            <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.name} onClick={() => { onSelect(option) }} />
                        )
                    })
                }
            </RadioGroup>
        </FormControl>
    )
}
