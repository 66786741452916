import React, { useState } from 'react'
import { Body, Row, Column, TextInput, Button, BackButton, Card, Spacer} from '../../../components'
import { BarcodeScanModal } from './BarcodeScanModal'
import { useModal } from '../../../utils'
import testKitPlaceholder from '../../../assets/images/test-kit-placeholder.png'

const TestKitIdEntry = ({ onBackClick, onSubmit }) => {
	const maybeTestKitId = JSON.parse(localStorage.getItem('testKitId'))
	const [testKitId, setTestKitId] = useState(maybeTestKitId || '')
	const [isBarcodeScanModalVisible, setIsBarcodeScanModalVisible] = useState(false)

	const handleBarcodeDetected = ({ text }) => {
		console.log('handleBarcodeDetected', text)
		localStorage.setItem('testKitId', JSON.stringify(text))
		setTestKitId(text)
		closeModal()
	}

	const [showModal, closeModal] = useModal(
		<BarcodeScanModal testKitId={testKitId} onBarcodeDetected={handleBarcodeDetected} />
	)

	const handleBarcodeScanClick = () => {
		setIsBarcodeScanModalVisible(true)
	}

	const handleModalClose = () => {
		setIsBarcodeScanModalVisible(false)
	}

	const handleSubmitClick = () => {
		onSubmit({ testKitId })
	}

	return (
		<>
			<Column desktopSize={6} spacingTop="s">
				<Column desktopSize={6} tabletSize={12}>
					<h3>Please enter or scan the test kit ID</h3>
					<Row
						desktopSize={8}
						tabletSize={12}
						spacingTop="s"
						contained
						style={{ alignItems: 'flex-end' }}
					>
						<TextInput
							desktopSize={3}
							tabletSize={6}
							label="Test kit ID"
							value={testKitId}
							setter={(value)=>{
								localStorage.setItem('testKitId', JSON.stringify(value))

								setTestKitId(value)
								}
							}
						/>
						<Button secondary onClick={handleBarcodeScanClick}>
							Scan using web camera
						</Button>
					</Row>
					<Row contained desktopSize={6} spacingTop="s">
						<BackButton onClick={onBackClick} />
						<Button primary onClick={handleSubmitClick}>
							Next
						</Button>
					</Row>
				
					<Row contained desktopSize={6} spacingTop="l">
						<Card img={testKitPlaceholder} alt='Test kit placeholder'>
							<Body>You can find the test kit ID on the front of the box in the location circled below:</Body>
							<Spacer spacing="xs" />
						</Card>
					</Row>
				</Column>
			</Column>
			{isBarcodeScanModalVisible && showModal(handleModalClose)}
		</>
	)
}

export { TestKitIdEntry }
