import React, { useContext, useState, useEffect } from 'react'
import { Breadcrumps } from '../../components'
import PatientsList from './components/patient.list'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { sortableUid, useOrdering, withAuthentication } from '../../utils';
import { formatCurrency, formatDate } from '../../utils/formatters';
import ConfirmationPendingList from './components/confirmation.pending.list';
import { checkExecutionStatus } from 'components/common/util/util';
import { AppContext } from "../../app.container";
import moment from 'moment';
import ConfirmedList from './components/confirmed.list';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { isValidPatientDetail } from './validator';
import ValidationErrorDialog from './components/validation.error.dialog'
import ConfirmationLinkSentList from './components/confirmed.link.sent.list';
import InProgressTest from './components/test.in.progress';
import ScheduleTest from './components/schedule-test';
import { SERVICE_TYPES, TEST_TYPES } from 'components/common/util/util';
import MaterialAutoComplete from 'components/common/material.autocomplete/material.autocomplete';
import CancelledTests from './components/test.cancelled';
import CompletedTest from './components/test.completed';
import DashboardIconBarV2 from 'components/common/dashboard.icon.bar.v2/dashboard.icon.bar.v2'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ScheduleTests = withAuthentication((props) => {
    const { scheduleTestResponse, defaultSelectedEvent, events } = props;
    const context = useContext(AppContext);
    const execStatusGetKits = checkExecutionStatus(context, "GET_KITS");
    const execStatusGetEvents = checkExecutionStatus(context, "GET_EVENTS");
    const execStatusCreateEvent = checkExecutionStatus(context, "CREATE_EVENT");
    const execStatusScheduleTest = checkExecutionStatus(context, "SCHEDULE_TEST");
    const execStatusSendConfirmationLink = checkExecutionStatus(context, "SEND_CONFIMRATION_LINK");
    const execStatusGetCourierStatus = checkExecutionStatus(context, "GET_COURIER_STATUS");
    const { defaultOrganisation, customerData } = useOrdering()
    const [selectedTab, setSelectedTab] = useState("ADD_TEST_DETAIL")
    const [isBulkSubmit, setIsBulkSubmit] = useState(false)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [state, setState] = useState({
        selectedEvent: undefined,
        dateOfTest: '',
        serviceType: SERVICE_TYPES[0],
        preferredTime: '',
        preferredFromTime: '',
        preferredToTime: '',
        isProducerCheckFitToFly: false,
        postcode: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: {
            name: "United Kingdom",
            value: "GBR"
        },
        familyName: '',
        givenName: '',
        fullName: '',
        DOB: '',
        gender: '',
        // documentNumber: '',
        ethnicity: '',
        vaccinationStatus: '',
        email: '',
        telephone: '',
        arrivalDate: '',
        travelMethod: '',
        vesselNumber: '',
        departureDate: '',
        departureCountry: '',
        swabTaken: '',
        producerComments: '',
        isHomeAddressSameAsAboveTestAddress: false,
        homeAddressPostcode: '',
        homeAddressLine1: "",
        homeAddressLine2: "",
        homeAddressCity: '',
        homeAddressCountry: "",
        isSelfAddressSameAsAboveTestAddress: false,
        selfIsolationAddressPostcode: '',
        selfIsolationAddressLine1: "",
        selfIsolationAddressLine2: "",
        selfIsolationAddressCity: '',
        selfIsolationAddressCountry: {
            name: "United Kingdom",
            value: "GBR"
        },
        healthNumber: '',
        documentType: '',
        documentCountry: '',
        documentNumber: '',
        documentExpiration: '',
        documentAuthority: '',
        showValidationErrorDialog: false
    })
    const handleTabChange = (event, newValue) => {
        // console.log(newValue, "hey")
        setSelectedTab(newValue)
        //setState({ ...state, selectedEvent: '' })
    };
    const onChange = (value, name) => {
        if (name === "isHomeAddressSameAsAboveTestAddress" || name === "isSelfAddressSameAsAboveTestAddress" || name === "isProducerCheckFitToFly") {
            if (!value) {
                if (name === "isHomeAddressSameAsAboveTestAddress") {
                    setState({
                        ...state, [name]: !value,
                        homeAddressPostcode: state.postcode,
                        homeAddressLine1: state.addressLine1, homeAddressLine2: state.addressLine2,
                        homeAddressCity: state.city, homeAddressCountry: state.country
                    })
                }
                else if (name === "isSelfAddressSameAsAboveTestAddress") {
                    setState({
                        ...state, [name]: !value,
                        selfIsolationAddressPostcode: state.postcode,
                        selfIsolationAddressLine1: state.addressLine1, selfIsolationAddressLine2: state.addressLine2,
                        selfIsolationAddressCity: state.city
                    })
                }
                else {
                    setState({ ...state, [name]: !value })
                }
            }
            else {
                setState({ ...state, [name]: !value })
            }
        }
        else {
            setState({ ...state, [name]: value })
        }
    }

    const getSelectedAddress = (addressObj) => {
        if (addressObj.length > 0) {
            setState({
                ...state,
                [addressObj[0]?.key]: addressObj[0]?.value,
                [addressObj[1]?.key]: addressObj[1]?.value,
                [addressObj[2]?.key]: addressObj[2]?.value,
            })
        }
    }

    const onSelect = (value, data, name) => {
        if (name === "selectedEvent") {
            props.resetEvent()
        }

        setState({ ...state, [name]: data })
    }

    const onEventFilterSelect = (value, data, name) => {
        if (name === "selectedEvent") {
            props.resetEvent()
        }
        setState({ ...state, [name]: data })

        console.log(selectedTab)
        if (selectedTab === "CONFIRMATION_PENDING") {
            props.getKits({
                pageIndex: 0,
                kitStatus: "CONFIRMATION_PENDING",
                eventId: value
            })
        }
        else if (selectedTab === "CONFIRMATION_LINK_SENT") {
            props.getKits({
                pageIndex: 0,
                kitStatus: ["CONFIRMATION_LINK_SENT", "Assigned", "INDIVIDUAL_CONFIRMED_TEST_DETAILS"],
                eventId: value
            })
        }
        else if (selectedTab === "IN_PROGRESS") {
            props.getKits({
                pageIndex: 0,
                kitStatus: "Assigned",
                eventId: value
            })
        }
        else if (selectedTab === "CANCELLED_TESTS") {
            props.getKits({
                pageIndex: 0,
                kitStatus: "CANCELLED",
                eventId: value
            })
        }
        else if (selectedTab === "RESULT_READY") {
            props.getKits({
                pageIndex: 0,
                kitStatus: "ResultReady",
                eventId: value
            })
        }
    }
    useEffect(() => {
        if (defaultSelectedEvent) {
            setState({
                ...state, selectedEvent: {
                    name: defaultSelectedEvent.eventName,
                    value: defaultSelectedEvent.eventId
                }
            })
        }
    }, [defaultSelectedEvent])

    useEffect(() => {
        setState({ ...state, fullName: `${state.givenName} ${state.familyName}` })
    }, [state.familyName, state.givenName])

    const onScheduleSubmit = () => {
        setIsBulkSubmit(false);
        const validationResult = isValidPatientDetail({
            ...state
        })
        if (validationResult.allValid === true) {
            setState({
                ...state,
                ...validationResult
            })

            let newState = { ...state };
            delete newState['selectedEvent']
            delete newState['isValiddateOfTest']
            delete newState["isValidpreferredTime"]

            delete newState['isValidpostcode']
            delete newState['isValidaddressLine1']
            delete newState['isValidcity']

            delete newState['isValidfullName']
            delete newState['isValidfamilyName']
            delete newState['isValidgivenName']
            delete newState['isValidDOB']
            delete newState['isValidgender']
            delete newState['isValiddocumentNumber']
            delete newState['isValidethnicity']
            delete newState['isValidvaccinationStatus']

            delete newState['isValidemail']
            delete newState['isValidtelephone']
            delete newState['isValidarrivalDate']
            delete newState['isValidtravelMethod']
            delete newState['isValidvesselNumber']
            delete newState['isValiddepartureDate']
            delete newState['isValiddepartureCountry']

            delete newState['isValiHomeAddressPostcode']
            delete newState['isValidHomeAddressLine1']
            delete newState['isValidHomeAddressCity']
            delete newState['isValidHomeAddressCountry']
            // delete newState['isValidnationality']
            delete newState['isValiddocumentType']
            delete newState['isValiddocumentCountry']
            delete newState['isValiddocumentNumber']
            delete newState['isValiddocumentAuthority']
            delete newState['isValiddocumentExpiration']
            delete newState['allValid']
            delete newState["showValidationErrorDialog"]
            if (state.serviceType.value === "PCR_DAY_2") {
                delete newState['preferredFromTime']
                delete newState['preferredTime']
                delete newState['preferredToTime']
                delete newState['fullName']
            }
            else if (state.serviceType.value === "NEXT_DAY" || state.serviceType.value === "SAME_DAY") {
                delete newState['documentNumber']
                delete newState['ethnicity']
                delete newState['vaccinationStatus']
                delete newState['arrivalDate']
                delete newState['travelMethod']
                delete newState['vesselNumber']
                delete newState['departureDate']
                delete newState['departureCountry']
                delete newState['swabTaken']
                delete newState['isSelfAddressSameAsAboveTestAddress']
                delete newState['selfIsolationAddressPostcode']
                delete newState['selfIsolationAddressLine1']
                delete newState['selfIsolationAddressLine2']
                delete newState['selfIsolationAddressCity']
                delete newState['selfIsolationAddressCountry']
            }


            const organisationId = defaultOrganisation()
            const numKits = "1";
            const subTotal = numKits * 120;
            const vat = subTotal * 0.2
            // disable adding vat to total
            // const total = subTotal + vat
            const total = subTotal;

            const numberOfKitsText = "1 Kit"
            const orderType = 'same-day-test';
            const orderTypeText = "Same day order test";

            const subTotalText = formatCurrency(subTotal)
            const vatText = formatCurrency(vat)
            const totalText = formatCurrency(total)

            const orderDatetime = new Date()
            const orderDate = orderDatetime.toISOString().split('T')[0]
            const orderDateText = formatDate(orderDatetime)

            let patient = {
                ...newState,
                //orderRecivedDate: moment(new Date()).format('DD/MM/YYYY'),
                filledBy: 'PRODUCER',
                using: 'BEACON_PRODUCER_FORM',
                requestedBy: customerData.displayName,

                dateOfTest: state?.dateOfTest ? moment(new Date(state?.dateOfTest))?.format('DD/MM/YYYY')?.trim() : '',
                serviceType: state?.serviceType && state?.serviceType?.value?.trim(),
                serviceTypeText: state?.serviceType && state?.serviceType?.serviceTypeText,

                postcode: state?.postcode?.trim(),
                addressLine1: state?.addressLine1?.trim(),
                addressLine2: state?.addressLine2?.trim(),
                city: state?.city?.trim(),
                country: state?.country && state?.country?.value,
                countryText: state?.country && state?.country?.name?.trim(),

                familyName: state?.familyName?.trim(),
                givenName: state?.givenName?.trim(),
                fullName: state?.fullName?.trim(),

                email: state?.email && state?.email?.toLowerCase()?.trim(),
                telephone: state?.telephone?.trim().replaceAll(" ", ""),

                DOB: state?.DOB ? moment(new Date(state?.DOB))?.format('DD/MM/YYYY')?.trim() : '',
                gender: state?.gender && state?.gender?.value?.trim(),


                homeAddressCountry: state?.homeAddressCountry && state?.homeAddressCountry?.value,
                homeAddressCountryText: state?.homeAddressCountry && state?.homeAddressCountry?.name?.trim(),
                producerComments: state?.producerComments && state?.producerComments?.trim(),

                documentCountry: state?.documentCountry && state?.documentCountry?.value?.trim(),
                documentCountryText: state?.documentCountry && state?.documentCountry?.name,

                price: state?.serviceType && state?.serviceType?.price,
                eventId: state?.selectedEvent && state?.selectedEvent?.value,
                eventName: state?.selectedEvent && state?.selectedEvent?.name && state?.selectedEvent?.name?.trim(),
                courierCost: state?.serviceType && state?.serviceType?.courierCost,
                currency: state?.serviceType && state?.serviceType?.currency,
                currencySymbol: state?.serviceType && state?.serviceType?.currencySymbol,

                // nationality: state?.nationality,
                // healthNumber: state?.healthNumber,
                // documentType: state?.documentType,
                // documentNumber: state?.documentNumber,
                documentExpiration: state?.documentExpiration ? moment(new Date(state?.documentExpiration))?.format('DD/MM/YYYY')?.trim() : '',
                //documentAuthority: state?.documentAuthority
            }
            if (state?.serviceType?.value === "NEXT_DAY" || state?.serviceType?.value === "SAME_DAY") {
                patient = {
                    ...patient,
                    preferredTime: state?.preferredTime && state?.preferredTime?.value?.trim(),
                    preferredFromTime: state?.preferredTime && state?.preferredTime?.preferredFromTime?.trim(),
                    preferredToTime: state?.preferredTime && state?.preferredTime?.preferredToTime?.trim(),
                }
            }
            else if (state?.serviceType?.value === "PCR_DAY_2") {
                patient = {
                    ...patient,
                    documentNumber: state?.documentNumber?.trim(),
                    ethnicity: state?.ethnicity?.value?.trim(),
                    ethnicityText: state?.ethnicity?.name?.trim(),
                    vaccinationStatus: state?.vaccinationStatus?.value?.trim(),
                    vaccinationStatusText: state?.vaccinationStatus?.name?.trim(),
                    arrivalDate: state?.arrivalDate ? moment(new Date(state?.arrivalDate))?.format('DD/MM/YYYY')?.trim() : '',
                    vesselNumber: state?.vesselNumber?.trim(),
                    travelMethod: state?.travelMethod?.value?.trim(),
                    travelMethodText: state?.travelMethod?.name?.trim(),
                    departureDate: state?.departureDate ? moment(new Date(state?.departureDate))?.format('DD/MM/YYYY')?.trim() : '',
                    departureCountry: state?.departureCountry && state?.departureCountry?.value?.trim(),
                    departureCountryText: state?.departureCountry && state?.departureCountry?.name?.trim(),
                    swabTaken: state?.swabTaken?.trim(),
                    selfIsolationAddressCountry: state?.selfIsolationAddressCountry && state?.selfIsolationAddressCountry?.value?.trim(),
                    selfIsolationAddressCountryText: state?.selfIsolationAddressCountry && state?.selfIsolationAddressCountry?.name?.trim()
                }

            }
            let order = {
                orderUid: sortableUid(),
                orderDate,
                createdAt: Date?.now(),
                organisationId: organisationId?.trim(),
                orderType,
                numKits,
                subTotal: subTotalText,
                vat: vatText,
                total: totalText,
                partnerLab: state?.serviceType?.value === "PCR_DAY_2" ? "SBS" : "catalyst",
                patients: [patient],
                confirmationDisplayText: {
                    subTotalText,
                    vatText,
                    totalText,
                    numberOfKitsText,
                    orderTypeText,
                    orderDateText
                }
            }
            props.scheduleTest(order);
        }
        else {
            setState({
                ...state,
                ...validationResult,
                showValidationErrorDialog: true
            })
        }
    }

    const bulkSubmit = (patientData) => {
        setIsBulkSubmit(true);
        const organisationId = defaultOrganisation()
        const numKits = "1";
        const subTotal = numKits * 120;
        const vat = subTotal * 0.2
        // disable adding vat to total
        // const total = subTotal + vat
        const total = subTotal;

        const numberOfKitsText = "1 Kit"
        const orderType = 'same-day-test';
        const orderTypeText = "Same day order test";

        const subTotalText = formatCurrency(subTotal)
        const vatText = formatCurrency(vat)
        const totalText = formatCurrency(total)

        const orderDatetime = new Date()
        const orderDate = orderDatetime?.toISOString()?.split('T')[0]
        const orderDateText = formatDate(orderDatetime)

        let patient = {
            ...patientData,
            dateOfTest: patientData?.dateOfTest ? moment(new Date(patientData?.dateOfTest))?.format('DD/MM/YYYY') : '',
            email: patientData?.email && patientData?.email?.toLowerCase()?.trim(),
            //orderRecivedDate: moment(new Date()).format('DD/MM/YYYY'),
            DOB: patientData?.DOB ? moment(new Date(patientData?.DOB))?.format('DD/MM/YYYY') : '',
            gender: patientData?.gender && patientData?.gender?.value,
            filledBy: 'PRODUCER',
            using: 'BEACON_EXCEL_UPLOAD',
            requestedBy: customerData?.displayName,

            postcode: patientData?.postcode?.trim(),
            addressLine1: patientData?.addressLine1?.trim(),
            addressLine2: patientData?.addressLine2?.trim(),
            city: patientData?.city?.trim(),
            country: patientData?.country && patientData?.country?.value,
            countryText: patientData?.country && patientData?.country?.name?.trim(),

            familyName: patientData?.familyName?.trim(),
            givenName: patientData?.givenName?.trim(),
            fullName: patientData?.fullName?.trim(),

            telephone: patientData?.telephone?.trim().replaceAll(" ", ""),

            homeAddressCountry: state?.homeAddressCountry && state?.homeAddressCountry.value,
            homeAddressCountryText: state?.homeAddressCountry && state?.homeAddressCountry?.name?.trim(),
            documentCountry: patientData?.documentCountry && patientData?.documentCountry?.value,
            documentCountryText: patientData?.documentCountry && patientData?.documentCountry?.name?.trim(),
            preferredTime: patientData?.preferredTime && patientData?.preferredTime?.value?.trim(),
            eventId: state?.selectedEvent && state?.selectedEvent?.value,
            eventName: state?.selectedEvent && state?.selectedEvent?.name && state?.selectedEvent?.name?.trim(),
            preferredFromTime: patientData?.preferredTime && patientData?.preferredTime?.preferredFromTime?.trim(),
            preferredToTime: patientData?.preferredTime && patientData?.preferredTime?.preferredToTime?.trim(),
            serviceType: patientData?.serviceType && patientData?.serviceType?.value?.trim(),
            price: patientData?.serviceType && patientData?.serviceType.price,
            courierCost: patientData?.serviceType && patientData?.serviceType?.courierCost,
            currency: patientData?.serviceType && patientData?.serviceType?.currency,
            currencySymbol: patientData?.serviceType && patientData?.serviceType?.currencySymbol,
            serviceTypeText: patientData?.serviceType && patientData?.serviceType?.serviceTypeText?.trim(),
            // nationality: patientData.nationality,
            // healthNumber: patientData.healthNumber,
            // documentType: patientData.documentType,
            // documentNumber: patientData.documentNumber,
            documentExpiration: patientData?.documentExpiration ? moment(new Date(patientData?.documentExpiration))?.format('DD/MM/YYYY')?.trim() : '',
            //documentAuthority: patientData.documentAuthority
        }
        let order = {
            serialNumber: patient?.serialNumber,
            orderUid: sortableUid(),
            orderDate,
            createdAt: Date?.now(),
            organisationId: organisationId?.trim(),
            orderType,
            numKits,
            subTotal: subTotalText,
            vat: vatText,
            total: totalText,
            partnerLab: state?.serviceType?.value === "PCR_DAY_2" ? "SBS" : "catalyst",
            patients: [patient],
            confirmationDisplayText: {
                subTotalText,
                vatText,
                totalText,
                numberOfKitsText,
                orderTypeText,
                orderDateText
            }
        }
        props.scheduleTest(order);
        window.scrollTo(0, 0);
    }

    const resetState = () => {
        setState({
            ...state,
            familyName: '',
            givenName: '',
            fullName: '',
            email: '',
            //dateOfTest: '',
            preferredTime: '',
            preferredFromTime: '',
            //selectedEvent: undefined,
            preferredToTime: '',
            serviceType: SERVICE_TYPES[1],
            DOB: '',
            telephone: undefined,
            producerComments: '',
            gender: '',
            addressLine1: '',
            addressLine2: '',
            postcode: '',
            city: '',
            country: {
                name: "United Kingdom",
                value: "GBR"
            },
            isHomeAddressSameAsAboveTestAddress: false,
            homeAddressPostcode: '',
            homeAddressLine1: "",
            homeAddressLine2: "",
            homeAddressCity: '',
            homeAddressCountry: {
                name: "United Kingdom",
                value: "GBR"
            },
            healthNumber: '',
            documentType: '',
            documentCountry: '',
            documentNumber: '',
            documentExpiration: '',
            documentAuthority: ''
        })
    }
    useEffect(() => {
        if (scheduleTestResponse === true) {
            resetState()
            setShowSuccessAlert(true);
            props.resetScheduleTestResponse();
            window.scrollTo(0, 0);
        }
    }, [scheduleTestResponse])
    const handleClose = () => {
        setShowSuccessAlert(false)
    }
    const closeValidationErrorDialog = () => {
        setState({
            ...state, showValidationErrorDialog: false
        })
    }
    useEffect(() => {
        props.getEvents();
    }, [])
    let evts = events && events.map((evnt) => {
        return {
            name: evnt.get('eventName'),
            value: evnt.get('eventId')
        }
    })
    return (
        <div className="d-flex flex-column w-100 ml-5 mr-5 pr-5 pl-5">
            <div>
                <Breadcrumps />
            </div>
            <div>
                <h3>
                    Test Scheduling Dashboard
                </h3>
            </div>
            <Paper className="paper-root" square>
                <Tabs
                    value={selectedTab}
                    indicatorColor="secondary"
                    textColor="primary"
                    style={{
                        fontSize: '17px'
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleTabChange}>
                    <Tab
                        disabled={execStatusGetKits.status === "PENDING" ? true : false}
                        style={{
                            textTransform: 'inherit'
                        }} value="ADD_TEST_DETAIL" label="Request Test(s)" />
                    <Tab
                        disabled={execStatusGetKits.status === "PENDING" ? true : false}
                        style={{
                            textTransform: 'inherit'
                        }} value="CONFIRMATION_PENDING" label="Pending Test(s)" />
                    {/* <Tab value="CONFIRMED_TESTS" label="Confirmed Tests" /> */}
                    <Tab
                        disabled={execStatusGetKits.status === "PENDING" ? true : false}
                        style={{
                            textTransform: 'inherit'
                        }} value="CONFIRMATION_LINK_SENT" label="Confirmed Test(s)" />
                    <Tab
                        disabled={execStatusGetKits.status === "PENDING" ? true : false}
                        style={{
                            textTransform: 'inherit'
                        }} value="RESULT_READY" label="Completed Test(s)" />
                    <Tab
                        disabled={execStatusGetKits.status === "PENDING" ? true : false}
                        style={{
                            textTransform: 'inherit'
                        }} value="CANCELLED_TESTS" label="Cancelled Test(s)" />

                </Tabs>
            </Paper>
            <div className='w-100 d-flex justify-content-between'>
                {
                    selectedTab !== "ADD_TEST_DETAIL" ?

                        <div className="w-25 pt-2 pb-2" style={{ maxWidth: "400px" }}>
                            <MaterialAutoComplete
                                //startAdornment={"Events"}
                                execStatus={execStatusGetEvents}
                                //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                selectedItem={state.selectedEvent}
                                onSelect={(value, data) => onEventFilterSelect(value, data, 'selectedEvent')}
                                placeholder="Select Job"
                                data={evts && evts.toJS()}
                            />
                        </div> : null
                }

                {
                    selectedTab === "ADD_TEST_DETAIL" ?
                        <div className="w-100 d-flex justify-content-start pt-2" >
                            <ScheduleTest
                                execStatusGetKits={execStatusGetKits}
                                execStatusGetEvents={execStatusGetEvents}
                                execStatusCreateEvent={execStatusCreateEvent}
                                bulkSubmit={bulkSubmit}
                                execStatusScheduleTest={execStatusScheduleTest}
                                onScheduleSubmit={onScheduleSubmit}
                                {...state}
                                onChange={onChange}
                                getSelectedAddress={getSelectedAddress}
                                onSelect={onSelect}
                                {...props} />
                        </div> : null
                }
                {/* <div className='mt-2' style={{ width: "25%" }}> */}
                {
                    (state.selectedEvent && selectedTab === "RESULT_READY") &&
                    < DashboardIconBarV2
                        className="result-default-v2"
                        style={{ marginTop: "100px" }}
                        description={"0"}
                        selected={false}>
                        Total Cost
                    </DashboardIconBarV2>
                }

                {/* </div> */}
            </div>
            {
                selectedTab === "CONFIRMATION_PENDING" ?
                    <ConfirmationPendingList execStatusGetCourierStatus={execStatusGetCourierStatus} selectedEvent={state.selectedEvent} execStatusGetKits={execStatusGetKits} {...props} /> : null
            }
            {
                selectedTab === "CONFIRMED_TESTS" ?
                    <ConfirmedList execStatusGetCourierStatus={execStatusGetCourierStatus} selectedEvent={state.selectedEvent} execStatusSendConfirmationLink={execStatusSendConfirmationLink} execStatusGetKits={execStatusGetKits} {...props} /> : null
            }
            {
                selectedTab === "CONFIRMATION_LINK_SENT" ?
                    <ConfirmationLinkSentList execStatusGetCourierStatus={execStatusGetCourierStatus} selectedEvent={state.selectedEvent} execStatusGetKits={execStatusGetKits} {...props} /> : null
            }
            {
                selectedTab === "IN_PROGRESS" ?
                    <InProgressTest execStatusGetCourierStatus={execStatusGetCourierStatus} selectedEvent={state.selectedEvent} execStatusGetKits={execStatusGetKits} {...props} /> : null
            }
            {
                selectedTab === "CANCELLED_TESTS" ?
                    <CancelledTests execStatusGetCourierStatus={execStatusGetCourierStatus} selectedEvent={state.selectedEvent} execStatusGetKits={execStatusGetKits} {...props} /> : null
            }
            {
                selectedTab === "RESULT_READY" ?
                    <CompletedTest execStatusGetCourierStatus={execStatusGetCourierStatus} selectedEvent={state.selectedEvent} execStatusGetKits={execStatusGetKits} {...props} /> : null
            }
            <Snackbar anchorOrigin={
                { vertical: 'center', horizontal: 'center' }}
                open={showSuccessAlert && isBulkSubmit === false} autoHideDuration={8000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Individual details successfully send for approval.
                    You can add more individuals using below form. Thanks
                </Alert>
            </Snackbar>
            {
                state.showValidationErrorDialog === true ?
                    <ValidationErrorDialog open={state.showValidationErrorDialog}
                        handleClose={closeValidationErrorDialog} {...state} /> : null
            }
        </div >
    )
})
export default ScheduleTests