import React, { useState } from 'react'
import { Column, Breadcrumps } from '../../../components'
import { withAuthentication, useWorkflow, useAuth } from '../../../utils'
import { PatientDetailsEntry } from './PatientDetailsEntry'
import { TestKitIdEntry } from './TestKitIdEntry'
import { OrderConfirmation } from './OrderConfirmation'
import { SelectOrganisation } from './SelectOrganisation'

const AssignTestKitPage = withAuthentication(() => {
	const { customer, defaultOrganisation } = useAuth()
	const { isIndependent, organisationIds } = customer
	const firstWorkflowStep = isIndependent ? 0 : 1
	const [patientDetails, setPatientDetails] = useState({})
	const [idDocument, setIdDocument] = useState('')
	const [testKitBarcode, setTestKitBarcode] = useState('')
	const [organisationId, setOrganisationId] = useState(defaultOrganisation())
	const workflowSteps = [
		'SelectOrganisation',
		'PatientDetails',
		'TestKitId',
		'OrderConfirmation',
		'OrderConfirmed',
	]

	const [
		workflowStep,
		toSelectOrganisation,
		toPatientDetails,
		toTestKitId,
		toOrderConfirmation,
		toOrderConfirmed,
	] = useWorkflow(workflowSteps, firstWorkflowStep)

	const handleOrganisationSelect = ({ organisationId }) => {
		setOrganisationId(organisationId)
		toPatientDetails()
	}

	const handleOnPatientDetailsSubmit = ({ patientDetails, idDocument }) => {
		console.log('handleOnPatientDetailsSubmit', patientDetails)
		setPatientDetails(patientDetails)
		setIdDocument(idDocument)
		toTestKitId()
	}

	const handleOnKitIdSubmit = ({ testKitId }) => {
		setTestKitBarcode(testKitId)
		toOrderConfirmation()
	}

	return (
		<>
			<Column desktopSize={8} spacingTop="s">
				<Breadcrumps />
				{workflowStep === 'SelectOrganisation' && (
					<SelectOrganisation
						organisationIds={organisationIds}
						onOrganisationSelect={handleOrganisationSelect}
					/>
				)}
				{workflowStep === 'PatientDetails' && (
					<PatientDetailsEntry
						onSubmit={handleOnPatientDetailsSubmit}
						value={{ patientDetails, idDocument }}
						onBack={isIndependent ? toSelectOrganisation : null}
					/>
				)}
				{workflowStep === 'TestKitId' && (
					<TestKitIdEntry onBackClick={toPatientDetails} onSubmit={handleOnKitIdSubmit} />
				)}
				{workflowStep === 'OrderConfirmation' && (
					<OrderConfirmation
						onBackClick={toTestKitId}
						onNext={toOrderConfirmed}
						onEditPatientDetailsClick={toPatientDetails}
						patientDetails={patientDetails}
						testKitBarcode={testKitBarcode}
						organisationId={organisationId}
						confirmed={false}
					/>
				)}
				{workflowStep === 'OrderConfirmed' && (
					<OrderConfirmation
						onBackClick={toTestKitId}
						onNext={toOrderConfirmed}
						patientDetails={patientDetails}
						testKitBarcode={testKitBarcode}
						organisationId={organisationId}
						confirmed={true}
					/>
				)}
				{}
			</Column>
		</>
	)
})

export { AssignTestKitPage }
