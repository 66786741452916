import { primaryColor } from "./app.material.theme.base";
import ComfortaaFont from '../fonts/Comfortaa-Regular.ttf';

export const gradientColor = "linear-gradient(-180deg,#07bfc1,#07bfc1)";

const comfortaa = {
  fontFamily: 'Comfortaa',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Comfortaa'),
    local('Comfortaa-Regular'),
    url(${ComfortaaFont}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const themeStyles = {
    MuiPaper: {},
    MuiAppBar: {
      root : {
        //background : gradientColor
      }
    },
    MuiButton: {
        root : {
            outline : 'none !important'
        }
    },
    MuiIconButton: {
        root : {
            outline : 'none !important'
        }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#07bfc1', // THEME_CHANGES  backgroundColor: '#f8e1f1',
          color : '#ffffff',
          "&& i": {
            fontSize: "20px",
            background: '#ffffff',
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }
        }
      },
      root: {
        '&$selected': {
          backgroundColor: '#07bfc1',//    backgroundColor: '#ffe0f5' THEME_CHANGES
          color : '#ffffff',
          "&& i": {
            fontSize: "20px",
            background: '#ffffff',
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }
        },
        '&$selected:hover': {
          backgroundColor: '#07bfc1',//     backgroundColor: '#f8e1f1' THEME_CHANGES
          color : '#ffffff',
          "&& i": {
            fontSize: "20px",
            background: '#ffffff',
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }
        }
      }
    },
    MuiListItemAvatar: {},
    MuiListItemIcon: {
      root: {
        "&& i": {
          fontSize: "20px",
          background: gradientColor,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent"
        }
      }
    },
    MuiTab: {
      root : {
        outline : 'none !important'
      }
    },
    MuiTableCell: {
      head: {
        color : '#ffffff'
      },
      root: {
        verticalAlign:"top"
      }
    },
    MuiTableHead: {
      root: {
        backgroundColor: primaryColor
      }
    }
}
