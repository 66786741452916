

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// Import root app
import './assets/fonts/fonts.css'
import './styles.css'
import App from './app.index'
import { BreakpointProvider } from './utils/breakpoints'
import configureStore from './configureStore';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
//import * as serviceWorker from './service.worker';
// Create redux store with history
import { initialState } from 'view.updater/reducers/init';
// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const queries = {
  isMobile: '(max-width: 767px)',
  isTablet: '(min-width: 768px) and (max-width: 1279px)',
  isDesktop: '(min-width: 1280px)',
}

export const history = createBrowserHistory();
export const store = configureStore(initialState, history);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BreakpointProvider queries={queries}>
          <App store={store} history={history} />
        </BreakpointProvider>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

//serviceWorker.register();