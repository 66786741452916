import React from 'react'
import { Row, Column, Button, Body, Spacer, Breadcrumps } from '../../components'
import { withAuthentication } from '../../utils/withAuthentication'
import { Icon } from '../../components/Icon'
import downloadIcon from '../../assets/images/download-icon.svg'
//SAME_DAY_ORDER_CHANGES
const PlaceOrderPage = withAuthentication(() => {
	const handleDownloadTemplate = () => {
		const templateHref = '/delivery_template.csv'
		const anchor = document.createElement('a')
		anchor.href = templateHref
		anchor.download = templateHref
		document.body.appendChild(anchor)
		anchor.click()
	}
	const handleCourierDownloadTemplate = () => {
		const templateHref = '/courier_template.csv'
		const anchor = document.createElement('a')
		anchor.href = templateHref
		anchor.download = templateHref
		document.body.appendChild(anchor)
		anchor.click()
	}

	return (
		<>
			<Column desktopSize={8} tabletSize={12}>
				<Breadcrumps />
				{/* <h3>Please choose a kit type</h3> */}
				<Spacer spacing="s" />
				<Row>
					<Column>
						<Column desktopSize={6} tabletSize={8} bordered>
							<h4>Bulk ordered professional test kits</h4>
							<Body small paragraph>
								These test kits are ordered in bulk to be administered by individuals or a medical
								professional if required. You keep these kits on hand and activate when required.
							</Body>
							<Body small>Delivered by courier.</Body>

							<Spacer spacing="s" />

							<Button fitted primary href="/customer/order-professional-test-kits">
								Order Professional kits
							</Button>
						</Column>

						<Spacer spacing="s" />

						<Row contained>
							<Column desktopSize={6} tabletSize={8} bordered>
								<h4>Self test kits (via mail)</h4>
								<Body small paragraph>
									These self-test kits are mailed to the individual. Kit activation is performed by
									account administrator.
								</Body>
								<Body small paragraph>
									To order these kits, you’ll need to download and fill the delivery template to the
									right before proceeding, all fields are required.
									<br />
								</Body>
								<Row contained>
									<Button primary href="/customer/order-self-test-kits">
										Order Mail Delivered kits
									</Button>
								</Row>
							</Column>
							<Column desktopSize={2} tabletSize={4} style={{ justifyContent: 'auto' }}>
								<h4>Delivery template</h4>
								<Body small paragraph>
									You will need Microsoft Excel or compatible spreadsheet software to use this CSV
									template.
								</Body>
								<Spacer spacing="s" />
								<Button className="button" icon secondary onClick={handleDownloadTemplate}>
									<Icon img={downloadIcon} type="btn-inline-left" alt="Download icon" />
									Download template
								</Button>
							</Column>
						</Row>

						<Spacer spacing="s" />

						<Row contained>
							<Column desktopSize={6} tabletSize={8} bordered>
								<h4>Courier Delivered Kits</h4>
								<Body small paragraph>
									These self-test kits are for individuals who require the express courier service.
									The individual receiving the kit from the courier will be responsible for
									activating the kit.
								</Body>
								<Body small paragraph>
									To order these kits, you’ll need to download and fill the delivery template to the
									right before proceeding, all fields are required.
									<br />
									<b>Please note the delivery area is limited to Greater London and Midlands.</b>
								</Body>
								<Row contained>
									<Button primary href="/customer/order-self-test-kits?courier">
										Order Courier Delivered kits
									</Button>
								</Row>
							</Column>
							<Column desktopSize={2} tabletSize={4} style={{ justifyContent: 'auto' }}>
								<h4>Courier delivery template</h4>
								<Body small paragraph>
									You will need Microsoft Excel or compatible spreadsheet software to use this CSV
									template.
								</Body>
								<Spacer spacing="s" />
								<Button className="button" icon secondary onClick={handleCourierDownloadTemplate}>
									<Icon img={downloadIcon} type="btn-inline-left" alt="Download icon" />
									Download template
								</Button>
							</Column>
						</Row>
						<Spacer spacing="s" />
						<Row contained>
							<Column desktopSize={6} tabletSize={8} bordered>
								<h4>Courier Delivered Kits Same Day</h4>
								<Body small paragraph>
									These self-test kits are for individuals who require the express courier service.
									The individual receiving the kit from the courier will be responsible for
									activating the kit.
								</Body>
								<Body small paragraph>
									To order these kits, you’ll need to download and fill the delivery template to the
									right before proceeding, all fields are required.
									<br />
									<b>Please note the delivery area is limited to Greater London and Midlands.</b>
								</Body>
								<Row contained>
									<Button primary href="/customer/place-order-same-day-v2">
										Order Same Day Delivered kits
									</Button>
								</Row>
							</Column>
						</Row>
					</Column>
				</Row>
				
			</Column>
		</>
	)
})

export { PlaceOrderPage }
