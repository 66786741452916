import React from 'react'
import { useAuth } from '../../utils/backend'
import { useHistory, useLocation } from 'react-router-dom'
import { PasswordChangeForm } from './PasswordChangeForm'

const ResetPasswordPage = () => {
	const { confirmPasswordReset } = useAuth()
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const oobCode = params.get('oobCode')

	const history = useHistory()

	const handlePasswordSubmit = async ({ password }) => {
		return confirmPasswordReset(oobCode, password)
			.then(() => {
				history.push('/login')
				return true
			})
			.catch((error) => {
				return error
			})
	}

	return <PasswordChangeForm onPasswordSubmit={handlePasswordSubmit} />
}

export { ResetPasswordPage }
