import React, { useState, useEffect } from 'react'
import { Column, Breadcrumps, Button, BackButton } from '../../../components'
import { withAuthentication, useWorkflow, useAuth } from '../../../utils'
import { OrderPatientDetailsEntry } from './OrderPatientDetailsEntry'
import { OrderConfirmation } from './OrderConfirmation'

const OrderKitsSameDayPage = withAuthentication((props) => {
    const { customer, defaultOrganisation } = useAuth()
    const [patientsList, setPatientsList] = useState([{}])
    const [patientDetails, setpatientDetails] = useState({});
    const [organisationId, setOrganisationId] = useState(defaultOrganisation())
    const workflowSteps = ['PatientDetails', 'OrderConfirmation', 'OrderConfirmed']

    const [workflowStep, toPatientDetails, toOrderConfirmation, toOrderConfirmed] = useWorkflow(
        workflowSteps,
        0
    )

    // const handleOrganisationSelect = ({ organisationId }) => {
    // 	setOrganisationId(organisationId)
    // 	toPatientDetails()
    // }

    const handleOnPatientDetailsSubmit = ({ patientDetails, idDocument }, index) => {
        console.log('handleOnPatientDetailsSubmit', patientDetails)
        let list = [...patientsList];
        list[index] = { ...patientDetails };
        setPatientsList(list)
        setOrganisationId(patientDetails.org)
    }
    const addNext = () => {
        let list = [...patientsList];
        list.push({});
        setPatientsList(list)
    }
    const confirmAllOrders = () => {
        toOrderConfirmation()
    }
    const onDelete = (index) => {
        let list = [...patientsList];
        list.splice(index, 1)
        setPatientsList(list)
    }

    //toOrderConfirmation()
    // setIdDocument(idDocument)
    // toTestKitId()

    // const handleOnKitIdSubmit = ({ testKitId }) => {
    // 	setTestKitBarcode(testKitId)
    // 	toOrderConfirmation()
    // }

    return (
        <>
            <Column desktopSize={8} spacingTop="s">
                <div style={{
                    width: '25%'
                }}>
                    <BackButton />
                </div>
                {workflowStep === 'PatientDetails' && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                {
                                    patientsList.map((patient, index) => {
                                        return <OrderPatientDetailsEntry
                                            index={index}
                                            onDelete={() => onDelete(index)}
                                            onSubmit={(data) => handleOnPatientDetailsSubmit(data, index)}
                                            value={{ patientDetails: patient }}
                                        // onBack={isIndependent ? toSelectOrganisation : null}
                                        //orgs={organisations}
                                        />
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            {
                                patientsList && patientsList[patientsList.length - 1] && patientsList[patientsList.length - 1].fullName !== undefined &&
                                    patientsList[patientsList.length - 1].fullName !== "" ?
                                    <div>
                                        <Button onClick={addNext}>Add Next Order</Button>
                                        <Button onClick={confirmAllOrders}>{patientsList && patientsList.length === 1 ? `Process ${patientsList && patientsList.length} Order` : `Process ${patientsList && patientsList.length} Orders`}</Button>
                                    </div> : null
                            }

                        </div>
                        {/* <div>
                                <OrderPatientDetailsEntry
                                    onSubmit={(data) => handleOnPatientDetailsSubmit(data, patientsList.length)}
                                    value={{ patientDetails: {} }} />
                            </div> */}
                    </div>
                )}
                {workflowStep === 'OrderConfirmation' && (
                    <OrderConfirmation
                        onBackClick={toPatientDetails}
                        onNext={toOrderConfirmed}
                        onEditPatientDetailsClick={toPatientDetails}
                        patientDetails={patientDetails}
                        patientsList={patientsList}
                        organisationId={organisationId}
                        confirmed={false}
                        customer={customer}
                        {...props}
                    />
                )}
                {workflowStep === 'OrderConfirmed' && (
                    <OrderConfirmation
                        onNext={toOrderConfirmed}
                        patientDetails={patientDetails}
                        patientsList={patientsList}
                        organisationId={organisationId}
                        confirmed={true}
                        {...props}
                    />
                )}
                { }
            </Column>
        </>
    )
})

export { OrderKitsSameDayPage }
