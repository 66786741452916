import React, {useRef} from 'react';
import styled from 'styled-components';

const FileInputStyled = styled.div`

    input[type="file"] {
        font-size: 14px;
        letter-spacing: 0.5px;
    }
    
`

const BulkAssignUpload = ({onFileSelect}) => {
    const fileInput = useRef(null)

    const handleFileInput = (e) => {
        // handle validations
        onFileSelect(e.target.files[0])
    }

    return (
        <FileInputStyled>
            <div className="d-flex file-uploader">
                <br />
                <input type="file" onChange={handleFileInput} />
                {/*<button onClick={e => fileInput.current && fileInput.current.click()} className="btn" />*/}
            </div>
        </FileInputStyled>
    )
}

export { BulkAssignUpload }