import React, { useEffect, useState } from 'react'
import { Row, Button } from '../../../components'
import { useKits } from '../../../utils/backend'
import { withAuthentication } from '../../../utils/withAuthentication'
import beaconSecureLogo from '../../../assets/images/beacon-secure-logo.png'

const PrintSameDayKitPage = withAuthentication(({ match, location }) => {
    const { retrieveSameDayKit } = useKits()
    const [kitData, setKitData] = useState({});
    const fetchKitData = () => {
        let requestParams = { kitId: match.params.kitId }
        retrieveSameDayKit(requestParams)
            .then((response) => {
                setKitData(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        fetchKitData();
    }, [])

    const handlePrint = () => {
        const divContents = document.querySelector(".print-preview").outerHTML;
        const printElement = window.open('', '', 'height=500, width=500');
        printElement.document.write(`<html><body>${divContents}</body></html>`);
        printElement.document.close();
        printElement.print();
    }

    const outerContainerStyle = {
        display: "grid"
    }

    const mainWrapper = {
        marginTop: "10rem"
    }

    const topRowStyle = {
        display: "grid",
        gridTemplateColumns: "3fr 1fr",
        gridColumnGap: "1rem",
        alignItems: "flex-start",
        width: '100%'
    }

    const topRowLeftColStyle = {
        display: "grid"
    }

    // const testInfoWrapperStyle = {
    //     display: "grid",
    //     gridTemplateColumns: "3fr 1fr",
    //     gridColumnGap: "1rem",
    //     alignItems: "flex-start",
    //     width: '100%'
    // }

    const testInfoRowStyle = {
        display: "grid",
        margin: "1rem 0"
    }

    const testInfoHeaderRowStyle = {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)"
    }

    const testInfoHeaderStyle = {
        display: "block",
        fontWeight: "600",
        fontSize: "16px",
        padding: "1rem",
        background: "#b0b0b4",
        color: "#fff"
    }

    const testInfoValueRowStyle = {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)"
    }

    const testInfoValueStyle = {
        display: "block",
        fontSize: "16px",
        padding: "1rem",
        border: "1px solid #b0b0b4"
    }

    const mainHeader = {
        background: "#5a8fa2",
        color: "#fff",
        fontSize: "18px",
        padding: "2rem 1rem",
        textAlign: "center",
        display: "block",
        borderRadius: "4px"
    }

    const kitNumberStyle = {
        display: "block",
        fontSize: "16px",
        border: "1px solid",
        padding: "1rem",
        textTransform: "uppercase",
        height: "calc(100% - 1rem)"
    }

    const officialLabelWrapperStyle = {
        display: "block",
        margin: "1rem 0",
        textTransform: "uppercase"
    }
    const officialLabelTextStyle = {
        fontSize: "12px",
        marginBottom: "0.25rem",
    }
    const officialLabelBoxStyle = {
        border: "1px solid #d2d4cb",
        height: "70px",
        background: "#f1f2ed",
        borderRadius: "5px",
    }

    const patientInfoWrapperStyle = {
        display: "grid",
        width: '100%'
    }

    const patientInfoHeaderStyle = {
        display: "block",
        fontWeight: "600",
        fontSize: "16px",
        padding: "1rem",
        background: "#b0b0b4",
        color: "#fff"
    }

    const patientInfoRowStyle = {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)"
    }

    const patientInfoDataStyle = {
        display: "block",
        fontSize: "16px",
        padding: "1rem",
        border: "1px solid #000000a8"
    }

    const patientInfoRow2Style = {
        display: "block",
        fontSize: "16px",
        padding: "1rem",
        border: "1px solid #000000a8",
        borderWidth: "0 1px 1px"
    }

    const sampleInfoWrapperStyle = {
        display: "grid",
        width: '100%'
    }
    const sampleInfoHeaderStyle = {
        display: "block",
        fontWeight: "600",
        fontSize: "16px",
        padding: "1rem",
        background: "rgb(90, 143, 162)",
        color: "rgb(255, 255, 255)",
        margin: "3rem 0",
        textTransform: "uppercase",
        letterSpacing: "0.5px",
    }
    const sampleInfoRowStyle = {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)"
    }
    const sampleInfoDataStyle = {
        display: "block",
        fontSize: "16px",
        padding: "1rem",
        border: "1px solid #000000a8"
    }
    const signatureRowStyle = {
        display: "grid",
        width: "100%"
    }
    const signatureStyle = {
        display: "block",
        fontSize: "16px",
        padding: "3rem 1rem",
        border: "1px solid #000000a8",
        marginTop: "1rem"
    }

    const printBtnRowStyle = {
        marginTop: "3rem"
    }

    const printHeaderRowStyle = {
        marginBottom: "2rem",
        display: "grid",
        gridTemplateColumns: "1fr auto",
        width: "100%",
        fontSize: "16px",
        fontWeight: "600",
    }

    const contactColStyle = {
        display: "block",
        letterSpacing: "0.5px"
    }

    return (
        <div style={outerContainerStyle}>
            <Row desktopSize={8} tabletSize={12} spaced style={mainWrapper} className="print-preview">
                <div style={printHeaderRowStyle}>
                    <img src={beaconSecureLogo} width="100" height="40" alt="Beacon Secure Logo" />
                    <div style={contactColStyle}>
                        <div>Tel. +44(0)115 973 9012</div>
                        <div>Fax. +44(1)732 326 8377</div>
                    </div>
                </div>
                <div style={topRowStyle}>
                    <div style={topRowLeftColStyle}>
                        <div style={mainHeader}>Diagnostic Test Requisition Form</div>
                        <div style={testInfoRowStyle}>
                            <div style={testInfoHeaderRowStyle}>
                                <div style={testInfoHeaderStyle}>Test Selection</div>
                                <div style={testInfoHeaderStyle}>Sample Type</div>
                            </div>
                            <div style={testInfoValueRowStyle}>
                                <div style={testInfoValueStyle}>COVID-19 PCR</div>
                                <div style={testInfoValueStyle}>ORAL / NASAL SWAB</div>
                            </div>
                        </div>
                    </div>
                    <div style={kitNumberStyle}>Kit Id: {kitData.id}</div>
                </div>

                {/* <div style={testInfoWrapperStyle}> */}
                    {/* <div style={testInfoRowStyle}>
                        <div style={testInfoHeaderRowStyle}>
                            <div style={testInfoHeaderStyle}>Test Selection</div>
                            <div style={testInfoHeaderStyle}>Sample Type</div>
                        </div>
                        <div style={testInfoValueRowStyle}>
                            <div style={testInfoValueStyle}>COVID-19 PCR</div>
                            <div style={testInfoValueStyle}>ORAL / NASAL SWAB</div>
                        </div>
                    </div> */}
                    {/* <div style={officialLabelWrapperStyle}>
                        <div style={officialLabelTextStyle}>LABEL FOR OFFICIAL USE ONLY</div>
                        <div style={officialLabelBoxStyle}></div>
                    </div> */}
                {/* </div> */}

                <div style={patientInfoWrapperStyle}>
                    <div style={patientInfoHeaderStyle}>
                        Patient Information
                    </div>
                    <div style={patientInfoRowStyle}>
                        <div style={patientInfoDataStyle}>Full Name: {kitData.fullName}</div>
                        <div style={patientInfoDataStyle}>Email: {kitData.email}</div>
                        <div style={patientInfoDataStyle}>Date of Birth: {kitData.DOB}</div>
                        <div style={patientInfoRow2Style}>Gender: {kitData.gender}</div>
                        <div style={patientInfoRow2Style}>Phone: {kitData.telephone}</div>
                    </div>
                </div>

                <div style={sampleInfoWrapperStyle}>
                    <div style={sampleInfoHeaderStyle}>
                        Please make sure you follow the instructions leaflet provided
                    </div>
                    <div style={sampleInfoRowStyle}>
                        <div style={sampleInfoDataStyle}>Time of Sample:</div>
                        <div style={sampleInfoDataStyle}>Date of Sample:</div>
                    </div>
                    <div style={signatureRowStyle}>
                        <div style={signatureStyle}>Signature:</div>
                    </div>
                </div>
            </Row>
            <div style={printBtnRowStyle}>
                <Button secondary onClick={handlePrint}>
                    Print
                </Button>
            </div>
            
        </div>
    )
})

export { PrintSameDayKitPage }
