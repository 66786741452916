import React from 'react'
import { Row, Column, Button, Body, Spacer, Breadcrumps } from '../../components'
import { withAuthentication } from '../../utils/withAuthentication'

const SuperUser = withAuthentication(() => {


	return (
		<>
			<Column desktopSize={8} tabletSize={12}>
				<Breadcrumps />
				<h3>Admin menu</h3>
				<Row>
					<Column>
							<Button fitted primary href="/admin/manage-users">
								Users
							</Button>
						</Column>
						<Column>
							<Button fitted primary href="/admin/manage-organisations">
								Organisation
							</Button>
						</Column>
				</Row> 
			</Column>
		</>
	)
})

export { SuperUser }
