import React, { useContext, useEffect, useState } from "react";
import KitFilters from "./components/kits.filters";
import { withAuthentication } from "../../utils";
import KitsList from "./components/kits.list";
import { checkExecutionStatus } from 'components/common/util/util';
import { AppContext } from "../../app.container";
import { Breadcrumps } from "../../components";
import DashboardIconBarV2 from 'components/common/dashboard.icon.bar.v2/dashboard.icon.bar.v2'
import { IsMobileWidth } from 'components/common/util/util'
import clsx from 'clsx';
import { CircularProgress } from "@material-ui/core";

const Dashboard = withAuthentication((props) => {
    const { testSummary } = props;
    const context = useContext(AppContext);
    const execStatusGetKits = checkExecutionStatus(context, "GET_KITS");
    const execStatusGetTestSummary = checkExecutionStatus(context, "GET_TEST_SUMMARY");
    const mobileWidth = IsMobileWidth();
    const [state, setState] = useState({
        selectedResultStatus: {
            name: "All",
            value: ''
        },
        selectedKitStatus: {
            name: "All",
            value: ''
        },
        search: "",
        selectedCounter: 'TOTAL_TESTS'
    })
    useEffect(() => {
        props.getKits({
            pageIndex: 0,
        });
        props.getTestSummary();
    }, [])
    const onResultStatusChange = (value, data) => {
        setState({ ...state, selectedResultStatus: data, selectedCounter: "" })
        props.getKits({
            pageIndex: 0,
            resultStatus: value,
            kitStatus: state.selectedKitStatus.value,
            search: state.search
        })
    }
    const onKitStatusChange = (value, data) => {
        setState({ ...state, selectedKitStatus: data, selectedCounter: "" })
        props.getKits({
            pageIndex: 0,
            resultStatus: state.selectedResultStatus.value,
            kitStatus: value,
            search: state.search
        })
    }
    const onSearchChange = (e) => {
        setState({ ...state, search: e.target.value && e.target.value.toLowerCase() })
    }
    const onPageChange = (event, pageNumber) => {
        window.scrollTo(0, 0);
        props.getKits({
            pageIndex: pageNumber - 1,
            resultStatus: state.selectedResultStatus.value,
            kitStatus: state.selectedKitStatus.value,
            search: state.search
        })
    }
    const onSearchEnter = () => {
        setState({ ...state, selectedCounter: "" })
        props.getKits({
            pageIndex: 0,
            resultStatus: state.selectedResultStatus.value,
            kitStatus: state.selectedKitStatus.value,
            search: state.search
        })
    }

    const onSelectedCounterChange = (selectedCounter) => {
        if (execStatusGetKits.status !== "PENDING") {
            switch (selectedCounter) {
                case "TOTAL_TESTS":
                    props.getKits({
                        pageIndex: 0,
                        resultStatus: '',
                    })
                    setState({
                        ...state, selectedKitStatus: {
                            name: "All",
                            value: ''
                        },
                        selectedResultStatus: {
                            name: "All",
                            value: ''
                        },
                        search: "",
                        selectedCounter
                    })
                    break;
                case "TOTAL_POSITIVE":
                    props.getKits({
                        pageIndex: 0,
                        resultStatus: 'Positive',
                    })
                    setState({
                        ...state, selectedKitStatus: {
                            name: "All",
                            value: ''
                        },
                        selectedResultStatus: {
                            name: "Positive",
                            value: 'Positive'
                        },
                        search: "",
                        selectedCounter
                    })
                    break;
                case "TOTAL_NEGATIVE":
                    props.getKits({
                        pageIndex: 0,
                        resultStatus: 'Negative',
                    })
                    setState({
                        ...state, selectedKitStatus: {
                            name: "All",
                            value: ''
                        },
                        selectedResultStatus: {
                            name: "Negative",
                            value: 'Negative'
                        },
                        search: "",
                        selectedCounter
                    })
                    break;
                case "TOTAL_INCONCLUSIVE":
                    props.getKits({
                        pageIndex: 0,
                        resultStatus: 'Inconclusive',
                    })
                    setState({
                        ...state, selectedKitStatus: {
                            name: "All",
                            value: ''
                        },
                        selectedResultStatus: {
                            name: "Inconclusive",
                            value: 'Inconclusive'
                        },
                        search: "",
                        selectedCounter
                    })
                    break;

            }

        }

    }
    return (
        <div className="d-flex flex-column w-100 ml-5 mr-5 pr-5 pl-5">
            <div>
                <Breadcrumps />
            </div>
            <div>
                <h3>
                    Results Dashboard
                </h3>
            </div>
            <div>
                {
                    execStatusGetTestSummary.status === "PENDING" ?
                        <CircularProgress size={30} /> :
                        testSummary ?
                            <div className={clsx("d-flex", mobileWidth && 'w-100 flex-column')}>
                                <DashboardIconBarV2
                                    className="result-default-v2 mb-2"
                                    description={testSummary.get('totalTests') === 0 ? "0" : testSummary.get('totalTests')}
                                    onClick={() => onSelectedCounterChange("TOTAL_TESTS")}
                                    selected={state.selectedCounter === "TOTAL_TESTS" ? true : false}>
                                    Total Tests
                                </DashboardIconBarV2>
                                <DashboardIconBarV2
                                    className="result-positive-v2 mb-2"
                                    description={testSummary.get('totalPositiveResults') === 0 ? "0" : testSummary.get('totalPositiveResults')}
                                    onClick={() => onSelectedCounterChange("TOTAL_POSITIVE")}
                                    selected={state.selectedCounter === "TOTAL_POSITIVE" ? true : false}>
                                    Total Positive
                                </DashboardIconBarV2>
                                <DashboardIconBarV2
                                    className="result-negative-v2 mb-2"
                                    description={testSummary.get('totalNegativeResults') === 0 ? "0" : testSummary.get('totalNegativeResults')}
                                    onClick={() => onSelectedCounterChange("TOTAL_NEGATIVE")}
                                    selected={state.selectedCounter === "TOTAL_NEGATIVE" ? true : false}>
                                    Total Negative
                                </DashboardIconBarV2>
                                <DashboardIconBarV2
                                    className="result-inconclusive-v2 mb-2"
                                    description={testSummary.get('totalInconclusiveResults') === 0 ? "0" : testSummary.get('totalInconclusiveResults')}
                                    onClick={() => onSelectedCounterChange("TOTAL_INCONCLUSIVE")}
                                    selected={state.selectedCounter === "TOTAL_INCONCLUSIVE" ? true : false}>
                                    Total  Inconclusive
                                </DashboardIconBarV2>

                            </div> : null

                }

            </div>
            <div className="w-100 d-flex justify-content-start">
                <KitFilters
                    execStatusGetKits={execStatusGetKits}
                    onSearchEnter={onSearchEnter}
                    onSearchChange={onSearchChange}
                    onKitStatusChange={onKitStatusChange}
                    onResultStatusChange={onResultStatusChange}
                    {...props} {...state}
                />
            </div>
            <div>
                <KitsList execStatusGetKits={execStatusGetKits} onPageChange={onPageChange} {...props} />
            </div>
        </div>
    );
})

export default Dashboard;
