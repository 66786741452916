import PlaceOrderSameDayPage from './place.order.same.day.v2';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { processSameDayOrder } from 'view.updater/actions/order.actions';

const mapDispatchToProps = (dispatch) => ({
    processSameDayOrder: (data) => dispatch(processSameDayOrder(data))
});

const mapStateToProps = createStructuredSelector({
    
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PlaceOrderSameDayPage);