import React from 'react'
import { Button, Column, Row, PatientDetailsEditor, Body, Spacer, Line } from '../../../components'
import { BeaconForm, SubmitButton } from '../../../components/forms'
import { toSbsPatient, toUiPatient } from '../../../utils/sbs-utils'

const PatientOrderRow = ({
	pid,
	firstName,
	lastName,
	email,
	telephone,
	addressLine1,
	addressLine2,
	city,
	postcode,
	deliveryDate,
	deliverBy,
	deliveryWindow,
	jobNumber,
	driverNotes,
	showJobNumber,
	showDriverNotes,
	showDelivery,
	isInEditingMode,
	onDeleteEntry,
	onEnterEditingMode,
	onExitEditingMode,
}) => {
	const patient = {
		firstName,
		lastName,
		email,
		telephone,
		addressLine1,
		addressLine2,
		city,
		postcode,
		jobNumber,
		deliveryDate,
		deliverBy,
		deliveryWindow,
		driverNotes,
	}
	// console.log('patient order row: ', patient)
	const handleCancelClick = () => {
		onExitEditingMode({ patient: { ...patient, pid } })
	}

	const handleDeleteClick = () => {
		onDeleteEntry({ patient: { ...patient, pid } })
	}

	const handleSave = (patient) => {
		onExitEditingMode({ patient: { ...patient, pid } })
	}

	if (isInEditingMode === true) {
		return (
			<Column desktopSize={9} style={{ borderBottom: '1px solid #c7d4da' }}>
				<Spacer spacing="s" />
				<BeaconForm
					onSubmit={handleSave}
					initialValues={patient}
					io={{ input: toUiPatient, output: toSbsPatient }}
					triggered
				>
					<PatientDetailsEditor editPatientMode isMailKit={true} />
					<Spacer spacing="s" />
					<Row contained desktopSize={9}>
						<SubmitButton primary>Save changes</SubmitButton>
						<Button dangerous onClick={handleDeleteClick}>
							Delete entry
						</Button>
						<Button secondary onClick={handleCancelClick}>
							Cancel
						</Button>
					</Row>
				</BeaconForm>
				<Spacer spacing="s" />
				<Line />
			</Column>
		)
	} else {
		const displayName = `${firstName} ${lastName}`

		// const prettyDateOfBirth =
		// 	typeof dateOfBirth === 'string' && dateOfBirth.length === 8
		// 		? formatDate(new Date(toHyphenatedDateString(dateOfBirth)))
		// 		: 'Invalid Date'

		return (
			<Column desktopSize={9}>
				<Spacer spacing="sm" />
				<Row desktopSize={9}>
					<Column desktopSize={2} tabletSize={3} style={{ justifyContent: 'auto' }}>
						<Body>{displayName}</Body>
						<Body small>{email}</Body>
						<Button link tiny chromeless onClick={() => onEnterEditingMode({ pid })}>
							Edit
						</Button>
					</Column>
					<Column desktopSize={1} tabletSize={2}>
						<Body style={{ fontSize: 12 }}>{telephone}</Body>
					</Column>
					<Column desktopSize={2} tabletSize={4} style={{ fontSize: 12 }}>
						<Body style={{ fontSize: 12 }}>{addressLine1}</Body>
						<Body style={{ fontSize: 12 }}>{addressLine2}</Body>
						<Body style={{ fontSize: 12 }}>{city}</Body>
						<Body style={{ fontSize: 12 }}>{postcode}</Body>
					</Column>
					{showJobNumber && (
						<Column desktopSize={1} tabletSize={2}>
							<Body style={{ fontSize: 12 }}>{jobNumber}</Body>
						</Column>
					)}
					{showDelivery && (
						<Column desktopSize={1} tabletSize={2}>
							<Body style={{ fontSize: 12 }}>
								{deliveryDate}
								{deliverBy && <div style={{ fontSize: 10, color: 'red' }}>Before: {deliverBy}</div>}
								{deliveryWindow && <div style={{ fontSize: 10 }}>{deliveryWindow}</div>}
							</Body>
						</Column>
					)}
					{showDriverNotes && (
						<Column desktopSize={2} tabletSize={2}>
							<Body>
								<div style={{ fontSize: 12 }}>{driverNotes}</div>
							</Body>
						</Column>
					)}
				</Row>
				<Spacer spacing="sm" />
				<Line />
			</Column>
		)
	}
}

export { PatientOrderRow }
