import React, { useState } from 'react';
import { Row, Column, Body, TextInput, Button, Form, Spacer } from '../../components';
import { useAuth } from '../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import './authentication.css';

import { rewriteErrorMessage } from '../../utils';
import { BeaconForm } from "../../components/forms";
import {EyePassword} from "./common";
const extractEmailFromSearch = (searchString) => {
	const maybeEmail = atob(searchString.substring(1))
	const emailRe = /.*@.*\..*/
	const isEmailLike = emailRe.test(maybeEmail)

	return isEmailLike ? maybeEmail : null
}

const LoginPage = () => {
	const { search } = useLocation()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [eye, setEye] = useState(true)
	const history = useHistory()

	const receivedEmailAddress = extractEmailFromSearch(search)
	const isFederatedFlow = typeof receivedEmailAddress === 'string'

	const { signInWithEmailAndPassword } = useAuth()

	const handleLoginWithEmail = async () => {
		setError(null)
		setLoading(true)

		const safeEmail = isFederatedFlow ? receivedEmailAddress : email.trim()

		signInWithEmailAndPassword(safeEmail, password)
			.then((userCredential) => {
				setLoading(false)
				history.push('/customer')
			})
			.catch((error) => {
				const errorMessage = rewriteErrorMessage(error);
				setError(errorMessage);
				setLoading(false)
			})
	}

	const SignUpRoute = () => {
		history.push("/signup");
	}

	return (
		<>
			<div className="d-flex flex-row w-100">
				<div className="col-md-12">
					<div className="d-flex row justify-content-center align-items-center" style={{ minHeight: "460px", height: "calc(100vh - 423px - 121px)" }}>
						<div>
							<h1>Log in</h1>
							<span className="small error">{error || null}</span>
							<Column desktopSize={4} tabletSize={6} spacingTop="m">
								<Form onSubmit={handleLoginWithEmail}>
									{isFederatedFlow ? (
										<Body>{receivedEmailAddress}</Body>
									) : (
										<div style={{ marginLeft: '5px', marginTop: '20px' }}>
											<TextInput
												label="Email Address"
												value={email}
												setter={setEmail}
												desktopSize={4}
												tabletSize={6}
												disabled={loading}
											/>
										</div>
									)}
									<div style={{ marginLeft: '5px', marginTop: '20px' }}>
										<div className="position-relative">
											<TextInput
												label="Password"
												value={password}
												password
												paswordType={eye}
												setter={setPassword}
												desktopSize={4}
												tabletSize={6}
												text
												disabled={loading}
											/>

											<EyePassword
												eye = {eye}
												setEye = {(flag)=>setEye(!flag) }
											/>

										</div>
									</div>

									<div style={{ marginTop: '20px' }}>
										<Button submit loading={loading}>
											Login
										</Button>
									</div>

								</Form>
							</Column>

							{/* <div className="mt-4">
								Do not have any account?

								<span className="signUpBtn cursor-pointer"
									  onClick={()=>SignUpRoute()}
								>
									SignUp
								</span>
							</div> */}

							{isFederatedFlow ? null :
								(<div style={{ marginTop: '20px', marginBottom: '25px' }}>
									<Body centered>Just taken a swab/PCR test?</Body>
									<Body centered href="/test-results">
										<Button secondary href="/test-results">Get my test results</Button>
									</Body>
								</div>)
							}
						</div>
					</div>
				</div>
			</div>
			<Row>

			</Row>
		</>
	)
}

export { LoginPage }
