export const validateKit = (data) => {
    let validationResult = {
        isValidDOB: false,
        isValidGender : false
    };
    if (data.DOB !== "" && data.DOB !== undefined) {
        validationResult.isValidDOB = true;
    }
    if (data.gender !== "" && data.gender !== undefined) {
        validationResult.isValidGender = true;
    }
    

    return validationResult
}