import React from 'react'
import propTypes from 'prop-types'
import { useBeaconForm } from './BeaconForm'
import { inputDefaultPropTypes, inputDefaultPropValues } from './defaults'
import { Row } from '../containers'

const FormCheckboxInput = ({
	children,
	name,
	disabled,
	mobileSize,
	tabletSize,
	desktopSize,
	isConsent,
	size,
}) => {
	const { register } = useBeaconForm(name)

	const reg = isConsent ? register({ validate: (value) => value === true }) : register

	return (
		<Row mobileSize={mobileSize} tabletSize={tabletSize} desktopSize={desktopSize} size={size}>
			<label className="form-component">
				<input type="checkbox" name={name} disabled={disabled === true} ref={reg} />
				&nbsp;{children}
			</label>
		</Row>
	)
}

FormCheckboxInput.propTypes = {
	...inputDefaultPropTypes,

	datalist: propTypes.string,
	mask: propTypes.string,
	pattern: propTypes.any,
	// errorMessage: propTypes.string.isRequired,
}

FormCheckboxInput.defaultProps = { ...inputDefaultPropValues }

export { FormCheckboxInput }
