import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AppContainer from './app.container';
import { executionsSelector } from 'view.updater/selectors/app.selectors'

const mapStateToProps = createStructuredSelector({
    executions: executionsSelector()
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);