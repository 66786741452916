import React, { useEffect, useState } from 'react'
import { Paper, Typography } from "@material-ui/core";
import { IsMobileWidth } from "components/common/util/util";
import Pagination from '@material-ui/lab/Pagination';
import { format24HrDateTime, formatDate } from 'components/common/util/util';
import clsx from 'clsx';
import { Skeleton } from "@material-ui/lab";
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { copyTextToClipboard } from 'components/common/util/util';
import ContentDialog from '../../../components/common/content.dialog/content.dialog';
import UpdatePatientDetailsDialog from './update.patient.details.dialog';
import CourierStatusDialog from './courier.status.dialog';
import Table from '../../../components/common/table/Table';
import { fromJS } from 'immutable';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ConfirmationLinkSentList = (props) => {
    const mobileWidth = IsMobileWidth();
    const { kits, execStatusGetKits, selectedEvent } = props;

    const [showUpdateDialog, setShowUpdateDialog] = useState(false)
    const [showViewDialog, setShowViewDialog] = useState(false)
    const [selectedKit, setSelectedKit] = useState(null)
    const [rows, setRows] = useState(kits && kits.get('kitsArray') && kits.get('kitsArray'))

    useEffect(() => {
        props.getKits({
            pageIndex: 0,
            eventId: selectedEvent && selectedEvent.value,
            kitStatus: ["CONFIRMATION_LINK_SENT", "Assigned"]
        })
    }, [])
    const [state, setState] = useState({
        showCopyAlert: false,
        showCourierStatusDialogOpen: false,
        selectedKit: ''
    })
    const onPageChange = (event, pageNumber) => {
        window.scrollTo(0, 0);
        props.getKits({
            pageIndex: pageNumber - 1,
            eventId: selectedEvent && selectedEvent.value,
            kitStatus: ["CONFIRMATION_LINK_SENT", "Assigned"]
        })
    }
    const showCourierStatusDialog = (kit) => {
        setState({ ...state, showCourierStatusDialogOpen: true, selectedKit: kit })
    }
    const hideCourierStatusDialog = () => {
        setState({ ...state, showCourierStatusDialogOpen: false, selectedKit: '' })
    }

    let totalCount = 0;
    if (kits && kits.get('totalCount')) {
        totalCount = Math.ceil(kits.get('totalCount') / kits.get('limit'))
    }
    const copyLink = (link) => {
        copyTextToClipboard(link).then(() => {
            setState({ ...state, showCopyAlert: true })
        })
    }
    const closeCopyAlert = () => {
        setState({ ...state, showCopyAlert: false })
    }

    const tableHeader = [
        { name: "Test Date", value: "dateOfTest", type: "date", requirement: "formatDate" },
        { name: "Preferred Time", value: "preferredTime", type: "number" },
        { name: "Service Type", value: "serviceTypeText", type: "string" },
        { name: "Full Name", value: "fullName", type: "string" },
        { name: "Email Address", value: "email", type: "string" },
        { name: "Telephone", value: "telephone", type: "number" },
        { name: "Test Address", value: "addressLine1", type: "string" },
        { name: "Post Code", value: "postcode", type: "string" },
        { name: "Event", value: "eventName", type: "string" },
        { name: "Courier Cost", value: "courierCost", type: "string" },
        { name: "Last Updated", value: "updatedAt", type: "date", requirement: "format24HrDateTime" },
        { name: "Form Link", value: "formLink", type: "showLinks", disableSorting: true },
    ]

    useEffect(() => {
        if (kits && kits.toJS().kitsArray) {
            setRows(kits.toJS().kitsArray)
        }
    }, [kits])

    return (
        <div id="kit-list">
            <Snackbar anchorOrigin={
                { vertical: 'center', horizontal: 'center' }}
                open={state.showCopyAlert} autoHideDuration={5000} onClose={closeCopyAlert}>
                <Alert onClose={closeCopyAlert} severity="success">
                    Link copied
                </Alert>
            </Snackbar>
            <div className="w-100 d-flex justify-content-start">
                {!(mobileWidth) ? (
                    execStatusGetKits && execStatusGetKits.status === "PENDING" ?
                        <div className="w-100">
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                        </div> : <div className="d-flex flex-column w-100">
                            <Table
                                headers={tableHeader}
                                rows={rows}
                                showNo={true}
                                showActions={true}
                                onView={(kit) => { setShowViewDialog(true); setSelectedKit(kit) }}
                                onUpdate={(kit) => { setShowUpdateDialog(true); setSelectedKit(kit) }}
                                onCopyLink={(link) => { copyLink(link) }}
                                onCourierStatusShow={(kit) => { showCourierStatusDialog(fromJS(kit)) }}
                                pageNo={totalCount > 1 ? kits.get('pageIndex') * kits.get('limit') : 1}
                            />
                            {
                                totalCount > 1 ?
                                    <div className="d-flex justify-content-center pt-4">
                                        <Pagination
                                            page={kits.get('pageIndex') + 1}
                                            onChange={onPageChange}
                                            count={totalCount} color="primary" />
                                    </div> : null
                            }
                        </div>

                ) : (
                    <div >
                        {
                            kits && kits.get('kitsArray') && kits.get('kitsArray').map((kit, index) => {
                                return <div key={index} className="d-flex flex-wrap justify-content-center w-100 mt-2 ml-2">
                                    <Paper elevation={1} className="w-100 paper-root d-flex flex-column p-3 mt-2">
                                        {/* <div className="d-flex w-100 justify-content-between">
                                            {
                                                kit.get('partnerLab') === "catalyst" ?
                                                    <Tooltip title="Same Day Test">
                                                        <Rating max={1} value={1} size="large" />
                                                    </Tooltip> : null
                                            }
                                        </div> */}
                                        <div className="d-flex w-100 justify-content-between">
                                            <div> {kit.get("fullName") ? kit.get("fullName") : `${kit.get('firstName')} ${kit.get('lastName')}`} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>   {kit.get("email")} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>     {kit.get("telephone")}</div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>    {kit.get("addressLine1")} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>    {kit.get("postcode")} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <b> {formatDate(moment(kit.get('dateOfTest'), "DD/MM/YYYY"))}</b>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {kit.get("preferredTime")}
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {kit.get("serviceTypeText")}
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {kit.get("eventName")}
                                        </div>
                                        <div className="d-flex flex-column w-100 justify-content-between">
                                            <a target="_blank" href={`${window.location.origin}/confirmation-form/${kit.get('id')}`}>Form Link</a>
                                            {
                                                kit.get('status') === "CONFIRMATION_LINK_SENT" ?
                                                    <div className='text-primary cursor-pointer' onClick={() => copyLink(`${window.location.origin}/confirmation-form/${kit.get('id')}`)}>Copy Link</div> : ''
                                            }
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {`Updated at ${format24HrDateTime(kit.get('updatedAt'))}`}
                                        </div>

                                        <div style={{ width: '100%', padding: '2px 4px' }}
                                            className="rounded pl-2 d-flex justify-content-center align-items-center border-right">
                                            <i onClick={() => { setShowViewDialog(true); setSelectedKit(kit) }}
                                                style={{ fontSize: '18px', color: "rgb(6, 122, 184)" }}
                                                className="mr-2  text-center cursor-pointer fas fa-info-circle"></i>

                                            {/* <i onClick={() => {
                                                if (kit.get('status') === "CONFIRMATION_LINK_SENT") {
                                                    setShowUpdateDialog(true); setSelectedKit(kit)
                                                }
                                            }}
                                                style={{
                                                    fontSize: '18px',
                                                    color: `${kit.get('status') === "CONFIRMATION_LINK_SENT" ? "rgb(6, 122, 184)" : "lightgray"}`,
                                                    cursor: `${kit.get('status') === "CONFIRMATION_LINK_SENT" ? "pointer" : "not-allowed"}`
                                                }} className="fas fa-edit text-center cursor-pointer"></i> */}
                                        </div>
                                    </Paper>
                                </div>
                            })
                        }
                        {
                            totalCount > 1 ?
                                <div className="d-flex justify-content-center pt-3">
                                    <Pagination
                                        page={kits.get('pageIndex') + 1}
                                        onChange={onPageChange}
                                        count={totalCount} color="primary" />
                                </div> : null
                        }
                    </div>
                )}
            </div>
            {
                kits && kits.get('kitsArray') && kits.get('kitsArray').size == 0 ?
                    <Paper className="paper-root p-4 text-danger d-flex justify-content-center">
                        No Data Found
                    </Paper> : null
            }
            {execStatusGetKits && execStatusGetKits.status === "ERROR" ? (
                <Paper className="paper-root">
                    <div className="text-danger d-flex justify-content-center font-italic pt-2 pb-2">
                        <b>
                            {" "}
                            {execStatusGetKits.errorDetail && typeof execStatusGetKits.errorDetail === 'object'
                                ? execStatusGetKits.errorDetail.get("description")
                                : "Something isn’t working as it should, please try again"}
                        </b>
                    </div>
                </Paper>
            ) : null}

            <ContentDialog
                onClose={() => setShowViewDialog(false)}
                dialogIsOpen={showViewDialog}
                open={showViewDialog}
                title={<div><Typography><b>More Information</b></Typography></div>}>
                {selectedKit ?
                    <div className='d-flex flex-column' style={{ paddingLeft: '24px', paddingBottom: '16px' }}>
                        <span style={{ fontWeight: 'bold' }}>Date of test:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['dateOfTest'] ? selectedKit['dateOfTest'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Service type:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['serviceType'] ? selectedKit['serviceType'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Preferred time:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['preferredTime'] ? selectedKit['preferredTime'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Test postcode:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['postcode'] ? selectedKit['postcode'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Test line address 1:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['addressLine1'] ? selectedKit['addressLine1'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Test line address 2:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['addressLine2'] ? selectedKit['addressLine2'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Test city:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['city'] ? selectedKit['city'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Test country:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['country'] ? selectedKit['country'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Full name:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['fullName'] ? selectedKit['fullName'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>First name:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['givenName'] ? selectedKit['givenName'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Last name:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['faimlyName'] ? selectedKit['faimlyName'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Email:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['email'] ? selectedKit['email'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Telephone number:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['telephone'] ? selectedKit['telephone'] : ''}`}</span></span>
                        <span style={{ fontWeight: 'bold' }}>Comments:   <span style={{ fontWeight: "normal" }}>{`${selectedKit['producerComments'] ? selectedKit['producerComments'] : ''}`}</span></span>
                    </div>
                    :
                    <div style={{ paddingBottom: '16px' }}
                        className='w-100 d-flex justify-content-center align-items-center p-5'>
                        <span className='font-weight-bold'>Nothing to show</span>
                    </div>
                }
            </ContentDialog>

            {
                showUpdateDialog &&
                <UpdatePatientDetailsDialog
                    handleClose={() => setShowUpdateDialog(false)}
                    isOpen={showUpdateDialog}
                    kit={{ ...selectedKit }}
                    {...props}
                />
            }
            {
                state.showCourierStatusDialogOpen === true ?
                    <CourierStatusDialog selectedKit={state.selectedKit} {...props}
                        handleClose={hideCourierStatusDialog}
                        open={state.showCourierStatusDialogOpen} /> : null
            }
        </div>
    )
}

export default ConfirmationLinkSentList