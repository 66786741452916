import firebase from 'firebase/app'
import { db } from '../../utils';

export const GET_KITS = "GET_KITS";
export const GET_ADMIN_KITS = "GET_ADMIN_KITS";
export const UPDATE_ADMIN_KIT_STATUS = "UPDATE_ADMIN_KIT_STATUS";
export const UPDATE_KIT = "UPDATE_KIT";
export const UPDATE_KIT_DATA = "UPDATE_KIT_DATA";
export const SEND_CONFIMRATION_LINK = "SEND_CONFIMRATION_LINK";
export const UPDATE_PATIENT_DETAIL = "UPDATE_PATIENT_DETAIL";
export const GET_COURIER_STATUS = "GET_COURIER_STATUS";
export const UPDATE_TEST_KIT_CALL = "UPDATE_TEST_KIT_CALL";
export const UPDATE_PRODUCER_TEST_KIT_CALL = "UPDATE_PRODUCER_TEST_KIT_CALL";
export const GET_TEST_SUMMARY = "GET_TEST_SUMMARY";
export const GET_ALL_WRONGLY_ASSIGNED_KITS = "GET_ALL_WRONGLY_ASSIGNED_KITS";

const REGION = process.env.REACT_APP_FIREBASE_REGION || 'europe-west2'
const funs = firebase.app().functions(REGION)

export function getKits(data) {
    return {
        firebaseFunction: funs.httpsCallable('queryGetKits'),
        type: GET_KITS,
        data
    }
}

export function getTestSummary(data) {
    return {
        firebaseFunction: funs.httpsCallable('queryTestSummaryCall'),
        type: GET_TEST_SUMMARY,
        data
    }
}

export function getCourierStatus(data) {
    return {
        firebaseQuery: db
            .collection('couriers')
            .where('task', 'in', data.taskIds)
            .orderBy('updated_at', 'desc'),
        type: GET_COURIER_STATUS,
        data
    }
}
export function getAdminKits(data) {
    return {
        firebaseFunction: funs.httpsCallable('queryGetAdminKits'),
        type: GET_ADMIN_KITS,
        data
    }
}
export function updateAdminKitStatus(data) {
    return {
        type: UPDATE_ADMIN_KIT_STATUS,
        data
    }
}
export function updateKitData(data) {
    return {
        type: UPDATE_KIT_DATA,
        data
    }
}
export function updateKit(data, isLocal) {
    if (isLocal === true) {
        return {
            type: UPDATE_KIT,
            data: { ...data, isLocal }
        }
    }
    else if (data.kit && data.kit.status === "CONFIRMATION_LINK_SENT") {
        return {
            firebaseFunction: funs.httpsCallable('invokeSendPatientConfirmationLinkCall'),
            type: UPDATE_KIT,
            data
        }
    }
    else
        return {
            firebaseFunction: funs.httpsCallable('invokeUpdateSameDayKitsCall'),
            type: UPDATE_KIT,
            data
        }
}
export function updatePatientDetail(data) {
    return {
        //firebaseFunction: funs.httpsCallable('invokeUpdateSameDayKitsCall'),
        type: UPDATE_PATIENT_DETAIL,
        data
    }
}
export function updateTestKitDataCall(data) {
    return {
        firebaseFunction: funs.httpsCallable('invokeUpdateSameDayKitsCall'),
        type: UPDATE_TEST_KIT_CALL,
        data
    }
}
export function updateProducerTestKitCall(data) {
    return {
        firebaseFunction: funs.httpsCallable('invokeUpdateSameDayKitsCall'),
        type: UPDATE_PRODUCER_TEST_KIT_CALL,
        data
    }
}

export function sendConfirmationLink(data) {
    return {
        firebaseFunction: funs.httpsCallable('invokeSendPatientConfirmationLinkCall'),
        type: SEND_CONFIMRATION_LINK,
        data
    }
}

export function getAllWronglyAssignedKits(data) {
    return {
        firebaseFunction: funs.httpsCallable('getWronglyAssignedKitsCall'),
        type: GET_ALL_WRONGLY_ASSIGNED_KITS,
        data
    }
}