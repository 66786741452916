import React, { useEffect, useState } from 'react'
import { Row, Column, Spacer, LoadingAnimation, Body } from '../../../components'
import { useMetrics } from '../../../utils/backend'
import { PieChart, LegendRow } from '../../../components/charts'

const OrganisationStats = (props) => {
	const { queryOrganisationMetrics } = useMetrics()
	const [isDataLoading, setIsDataLoading] = useState(false)
	const [chartsData, setChartsData] = useState({
		chartData: [],
		totalKits: 0,
		stats: {
			negative: 0,
			positive: 0,
			retest: 0,
			awaiting: 0,
			provided: 0,
		},
	})

	const { totalKits, stats, chartData } = chartsData

	useEffect(() => {
		setIsDataLoading(true)
		queryOrganisationMetrics()
			.then((response) => {
				const {
					numKits,
					numNegative,
					numPositive,
					numReady,
					numReceived,
					numRetest,
				} = response.data

				const stats = {
					negative: numNegative,
					positive: numPositive,
					retest: numRetest,
					awaiting: numKits - (numReceived + numReady),
					provided: numReady,
				}

				const chartData = Object.entries(stats).map(([key, value]) => ({
					x: key,
					y: value,
				}))

				setChartsData({
					totalKits: numKits,
					stats,
					chartData,
				})

				setIsDataLoading(false)
			})
			.catch((error) => {
				setIsDataLoading(false)
				console.error(error)
			})
	}, [queryOrganisationMetrics])

	return (
		<>
			<Column fillRemaining>
				<h5>Statistics</h5>
				{isDataLoading ? (
					<LoadingAnimation />
				) : (
					<Row fillRemaining noWrap>
						<PieChart
							data={chartData}
							colors={['#07bfc1', '#f40e32', '#ff7500', '#067ab8', '#81ccf5']}
						/>
						<Spacer spacing="m" />
						<Column fillRemaining>
							<Body small>Test-kits:</Body>
							<Body>{totalKits} kits</Body>
							<Spacer spacing="s" />
							<LegendRow color="#07bfc1" value={stats.negative} label="Negative" />
							<LegendRow color="#f40e32" value={stats.positive} label="Positive" />
							<LegendRow color="#ff7500" value={stats.retest} label="Retest" />
							<LegendRow color="#067ab8" value={stats.awaiting} label="Awaiting" />
							<LegendRow color="#81ccf5" value={stats.provided} label="Results provided" />
						</Column>
					</Row>
				)}
			</Column>
		</>
	)
}

export { OrganisationStats }
