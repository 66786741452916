//SAME_DAY_ORDER_CHANGES
import React from 'react'
import { Column } from '../../../components'

const UploadInstructionsModal = ({ onBarcodeDetected }) => {
	return (
		<>
			<Column desktopSize={8} tabletSize={10} style={{ padding: '0 5rem' }} contained>
				<h3 className="no-space">Save an Excel file as a CSV file</h3>
				<ol>
					<li>In your Excel spreadsheet click ‘File’</li>
					<li>Click ‘Save as’</li>
					<li>Click Browse to choose where you want to save your file</li>
					<li>Select ‘CSV’ from the ‘Save as type’ drop-down menu</li>
					<li>Click ‘Save’</li>
					<li>You can now upload the saved CSV file</li>
				</ol>
			</Column>
		</>
	)
}

export { UploadInstructionsModal }
