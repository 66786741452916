import { createSelector } from 'reselect';

const selectAuthorization = (state) => {
      return state.authorization;
}
const makeSelectAuth = () => createSelector(
    selectAuthorization,
    (authorizationState) => authorizationState.get('auth')
);
export {
    selectAuthorization,
    makeSelectAuth
};
