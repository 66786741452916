import React, { useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { LoadingAnimation } from './LoadingAnimation'
import clsx from 'clsx';
const notImplemented = () => {}

const resolveButtonClassName = ({
	alternative,
	primary,
	secondary,
	tiny,
	link,
	chromeless,
	fitted,
	spread,
	disabled,
	dangerous,
	icon,
}) => {
	const classNames = ['button']

	if (alternative === true) {
		classNames.push('alternative')
	} else if (primary === true) {
		classNames.push('primary')
	} else if (secondary === true) {
		classNames.push('secondary')
	} else if (chromeless === true) {
		classNames.push('chromeless')
	}

	if (fitted === true) {
		classNames.unshift('fitted')
	} else if (spread === true) {
		classNames.unshift('spread')
	}

	if (disabled === true) {
		classNames.push('disabled')
	}

	if (tiny === true) {
		classNames.unshift('tiny')
	}

	if (dangerous === true) {
		classNames.push('dangerous')
	}

	if (icon === true) {
		classNames.push('icon')
	}

	if (link === true) {
		classNames.push('link')
	}

	return classNames.join(' ')
}

const SubmitButton = ({ className, children, disabled, loading }) => {
	const submitInputEl = useRef(null)

	const handleButtonClick = () => {
		if (!disabled) {
			submitInputEl.current.click()
		}
	}

	return (
		<>
			<input type="submit" style={{ display: 'none' }} ref={submitInputEl} />
			<div className={clsx("d-flex",className)} onClick={handleButtonClick}>
				{loading ? (
					<div className="lds-heart">
						<div></div>
					</div>
				) : (
					children
				)}
			</div>
		</>
	)
}

const Button = (props) => {
	const { href, onClick, noSpacing, disabled, submit, loading, style, children } = props
	const hasUrl = typeof href === 'string'
	const className = resolveButtonClassName(props)

	const handleClick = (event) => {
		if (!disabled) {
			onClick(event)
		}
	}

	const buttonStyle = {
		...style,
		margin: noSpacing ? '0' : '',
	}

	if (submit) {
		return (
			<SubmitButton className={className} style={buttonStyle} loading={loading} disabled={disabled}>
				{children}
			</SubmitButton>
		)
	} else if (hasUrl) {
		return (
			<Link className="link-button" to={href}>
				<div className={className} style={buttonStyle}>
					{children}
				</div>
			</Link>
		)
	} else {
		return (
			<div className={clsx("d-flex",className)} style={buttonStyle} onClick={handleClick}>
				{loading ? <LoadingAnimation /> : children}
			</div>
		)
	}
}

Button.defaultProps = {
	fitted: false,
	spread: false,
	loading: false,
	onClick: notImplemented,
}

const BackButton = ({ onClick }) => {
	const history = useHistory()

	const handleDefaultBackClick = () => {
		history.goBack()
	}

	const handleClick = typeof onClick === 'function' ? onClick : handleDefaultBackClick

	return (
		<Button secondary onClick={handleClick}>
			← Back
		</Button>
	)
}

export { Button, BackButton }
