import React from 'react'
import { Row, Column, Body, Spacer, BackButton, Button, Icon } from '../../components'
import { formatDate } from '../../utils'
import { RetestCopy, PositiveCopy, NegativeCopy } from './what-this-means-copy'
import downloadIcon from '../../assets/images/download-icon.svg'
import { isEmpty } from '../../components/common/util/util'

const resolveWhatThisMeansCopyElement = (testResult) => {

	try {
		//debugger;
		const tr = testResult.resultStatus.toLowerCase()
		if (tr === 'negative') {
			return <NegativeCopy />
		} else if (tr === 'positive') {
			return <PositiveCopy />
		} else if (tr === 'retest') {
			return <RetestCopy />
		} else {
			console.error(`resolveCopyElement: Unexpected test result: ${testResult}`)
		}
	} catch (e) {
		return <div></div>
	}


	return null
}

const TestResult = ({ testResult, onBackClick }) => {
	const whatThisMeansCopyElement = resolveWhatThisMeansCopyElement(testResult)
	const handleDownloadCertificate = (url) => {
		const anchor = document.createElement('a')
		anchor.href = url
		anchor.download = url
		anchor.target = "_blank"
		document.body.appendChild(anchor)
		anchor.click()
	}
	const getName = (testResult) => {
		if (testResult) {
			if (!isEmpty(testResult.fullName)) {
				return testResult.fullName;
			}
			else if (!isEmpty(testResult.firstName)) {
				return `${testResult.firstName} ${testResult.lastName}`
			}
			else if (!isEmpty(testResult.givenName)) {
				return `${testResult.givenName} ${testResult.familyName}`
			}
			else {
				return ""
			}
		}
	}
	return (
		<>
			<Column desktopSize={8} tabletSize={12} spacingTop="m">
				<Column desktopSize={4} tabletSize={7}>
					<Body>
						in partnership with the Source Bioscience laboratory&nbsp;
						<strong>ISO:15189:2012</strong>&nbsp;medical laboratories standard
					</Body>
				</Column>
				<Spacer spacing="m" />
				<Row desktopSize={8} tabletSize={12} spacingTop="m">
					<Column desktopSize={4} tabletSize={6}>
						<Body>Individual Name</Body>
						<h3 className="no-space">{getName(testResult)}</h3>
						<Body>Date of Birth</Body>
						<h3 className="no-space">{testResult.DOB ? testResult.DOB : formatDate
							(new Date(
								parseInt(testResult.dateOfBirth.substring(0, 4)),
								parseInt(testResult.dateOfBirth.substring(4, 6)) - 1,
								parseInt(testResult.dateOfBirth.substring(6, 8))))
						}</h3>
						<Body>Date tested</Body>
						<h3 className="no-space">{formatDate(new Date(testResult.catalyst_resultReportedAt ? testResult.catalyst_resultReportedAt : testResult.testedAt))}</h3>
						<Spacer spacing="m" />
						<Body>Your SARS-CoV-2 result is</Body>
						<h3 className="no-space">{testResult.resultStatus}</h3>
						<Spacer spacing="m" />
						<Spacer spacing="m" />
						{testResult.certificateUrl ? (
							<Button className="button" icon secondary onClick={() => { handleDownloadCertificate(testResult.certificateUrl) }}>
								<Icon img={downloadIcon} type="btn-inline-left" alt='Download icon' />
								Download certificate
							</Button>
						) : ''}
						<Spacer spacing="m" />
						<Button onClick={() => onBackClick()}>
							← Back
						</Button>
						{/* {testResult.certificateUrl ? (<Link to={testResult.certificateUrl} target="_blank" download>Download certificate</Link>):''} */}
					</Column>
					<Column desktopSize={4} tabletSize={6}>
						{whatThisMeansCopyElement}
					</Column>
				</Row>
			</Column>
		</>
	)
}

export { TestResult }
