import './content.dialog.scss'
import React from 'react';
import { useStyles } from './content.dialog.style'
import { CircularProgress, Dialog, DialogTitle, Icon } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

export default function ContentDialog(props) {
  const { title, showActions, successText, maxWidth, loading } = props;
  const classes = useStyles()

  return (
    <Dialog
      open={props.dialogIsOpen}
      onClose={props.onClose}
      PaperProps={{
        className: 'dialog-paper',
      }}
      maxWidth={maxWidth ? maxWidth : 'sm'}
      fullWidth
    >
      <div id="content-dialog" className="pt-4 pb-4">
        <DialogTitle>
          <div className="d-flex justify-content-between w-100">
            {title}
            <div
              onClick={props.onClose}
              className={`${classes.closeIcon} d-flex align-items-center justify-content-center`}
            >
              <Icon>close_icon</Icon>
            </div>
          </div>
        </DialogTitle>
        <div className="content">{props.children}</div>
      </div>
      {
        showActions ?
          loading === true ?
            <DialogActions className="p-4">
              <CircularProgress size={30} color="primary" />
            </DialogActions> :
            <DialogActions className="p-4">
              <Button onClick={props.onClose} color="default">
                Cancel
              </Button>
              <Button onClick={props.onSuccess} color="secondary">
                {successText}
              </Button>
            </DialogActions> : null
      }

    </Dialog>
  )
}
