import React from 'react'
import ContentDialog from 'components/common/content.dialog/content.dialog'
import { Typography } from '@material-ui/core'

const ValidationErrorDialog = (props) => {
    const { open,
        isValidemail,
        isValidfullName,
        isValidfamilyName,
        isValidgivenName,
        isValidDOB,
        isValidtelephone,
        isValidgender,
        isValidaddressLine1,
        isValidpostcode,
        isValidcity,

        isValiHomeAddressPostcode,
        isValidHomeAddressLine1,
        isValidHomeAddressCity,
        isValidHomeAddressCountry,

        serviceType,
        isValidEthnicity,
        isValidVaccination,
        isValidArrivalDate,
        isValidDepartureDate,
        isValidDepartureCountry,
        isValidTravelMethod,
        isValidVesselNumber,

        isValidnationality,
        isValiddocumentType,
        isFitToFly,
        isValiddocumentCountry,
        isValiddocumentNumber,
        isValidcountry,
        isValiddocumentAuthority,
        isValiddocumentExpiration } = props;

    console.log('props',props);

    return (
        <div>
            <ContentDialog
                onClose={props.handleClose}
                dialogIsOpen={open}
                title={<div><Typography><b>Please fill all required fields</b></Typography></div>}>
                <div className="p-3">
                    {
                        !isValidemail ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidemail ? "Email is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidfullName ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidfullName ? "Full Name is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidgivenName ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidgivenName ? "First Name is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidfamilyName ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidfamilyName ? "Second Name is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidDOB ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidDOB ? "Date of Birth is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidtelephone ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidtelephone ? "Telephone number is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidgender ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidgender ? "Please select gender" : null}
                            </div> : null

                    }
                    {/*{*/}
                    {/*    !isValidaddressLine1 ?*/}
                    {/*        <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">*/}
                    {/*            {!isValidaddressLine1 ? "Test Address Line 1 is not valid" : null}*/}
                    {/*        </div> : null*/}
                    {/*}*/}
                    {
                        !isValiHomeAddressPostcode ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiHomeAddressPostcode ? "Home Address Postcode is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidHomeAddressLine1 ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidHomeAddressLine1 ? "Home Address Line 1 is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidHomeAddressCity ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidHomeAddressCity ? "Home Address City is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidHomeAddressCountry ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidHomeAddressCountry ? "Home Address Country is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidpostcode ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidpostcode ? "Post Code is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidcountry ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidcountry ? "Country is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValidcity ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidcity ? "City is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidEthnicity && serviceType === "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidEthnicity ? "Ethnicity is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidVaccination && serviceType === "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidVaccination ? "Vaccination is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidArrivalDate && serviceType === "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidArrivalDate ? "Arrival date is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidDepartureDate && serviceType === "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidDepartureDate ? "Departure date is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidDepartureCountry && serviceType === "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidDepartureCountry ? "Departure country is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidTravelMethod && serviceType === "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidTravelMethod ? "Travel Method is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValidVesselNumber && serviceType === "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidVesselNumber ? "Vessel Number is not valid" : null}
                            </div> : null

                    }
                    {/* {
                        !isValidnationality ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValidnationality ? "Nationality is not valid" : null}
                            </div> : null

                    } */}
                    {
                        !isValiddocumentType && isFitToFly === "true" && serviceType !== "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddocumentType && isFitToFly === "true" ? "Document type is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValiddocumentCountry && isFitToFly === "true" && serviceType !== "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddocumentCountry && isFitToFly === "true" ? "Document Country is not valid" : null}
                            </div> : null
                    }
                    {
                        !isValiddocumentNumber && isFitToFly === "true" && serviceType !== "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddocumentNumber && isFitToFly === "true" ? "Document Number is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValiddocumentAuthority && isFitToFly === "true" && serviceType !== "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddocumentAuthority && isFitToFly === "true" ? "Document Authority is not valid" : null}
                            </div> : null

                    }
                    {
                        !isValiddocumentExpiration && isFitToFly === "true" && serviceType !== "PCR_DAY_2" ?
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                {!isValiddocumentExpiration && isFitToFly === "true" ? "Document Expiry is not valid" : null}
                            </div> : null

                    }
                </div>
            </ContentDialog>
        </div>
    )
}
export default ValidationErrorDialog