/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectDashboard = (state) => {
    return state.dashboard;
}

const makeSelectDashboardKits = () => createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.get('kits')
);

const makeSelectKitsCourierStatus = () => createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.get('kitsCourierStatus')
);

const makeSelectAdminKits = () => createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.get('adminKits')
);

const makeSelectTestSummary = () => createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.get('testSummary')
);

const makeSelectWronglyAssignedKits = () => createSelector(
    selectDashboard,
    (dashboardState) => dashboardState.get('wronglyAssignedKits')
);

export {
    selectDashboard,
    makeSelectDashboardKits,
    makeSelectAdminKits,
    makeSelectKitsCourierStatus,
    makeSelectTestSummary,
    makeSelectWronglyAssignedKits
};
