import React from 'react'
import { Row, Column, Body, Spacer } from '../../components'
import programmerImg from './assets/programmer.svg'
import doctorsImg from './assets/doctors.svg'
import fastSecureIcon from './assets/fast-secure.svg'

const AboutUsPage = () => {
	return (
		<>
			<Row marketing className="about-us hero" style={{ justifyContent: 'center' }}>
				<Column desktopSize={4} tabletSize={7}>
					<h1 className="huge">Who we are?</h1>
					<Body paragraph>
						Beacon Secure is a leader in the field of digital health testing solutions, offering a proprietary platform for employee healthcare monitoring and management integrated with the leading global laboratory providers.
					</Body>
				</Column>
				<Column desktopSize={4} tabletSize={5}>
					<img
						src={programmerImg}
						className="programmer-img"
						alt="Happy programmer passed COVID19 test"
					/>
				</Column>
			</Row>
			<Row marketing className="about-us" centered>
				<Column desktopSize={3} tabletSize={4} className="doctors-img-container">
					<img
						src={doctorsImg}
						className="not-mobile doctors-img"
						alt="Doctors doing hard work testing for COVID19"
					/>
				</Column>
				<Column desktopSize={5} tabletSize={8}>
					<h1>Flexible to your needs</h1>
					<Body paragraph>
						Our solutions are tailored to you or your specific workplace requirements, whether it’s one-off tests for individuals, regular on-site screening or an alternative of your choice. Our platform can seamlessly integrate with your existing HR or healthcare workflow or be used as a stand-alone application. We provide you and your employees with fast and accurate results to make timely decisions to keep your workplace safe.
					</Body>
				</Column>
			</Row>
			<Row desktopSize={6} className="about-us secure-and-accurate" centered>
				<Row desktopSize={6} centered>
					<img
						className="secure-and-accurate-icon"
						src={fastSecureIcon}
						alt="Secure and accurate"
					/>
				</Row>
				<Column desktopSize={6} centered>
					<Spacer spacing="m" />
					<h1>Secure and accurate</h1>
					<Body paragraph centered style={{ textAlign: "left" }}>
						Our tests are processed within 24 hours of receipt by Source Bioscience, a highly accredited ISO certified medical laboratory. We follow the latest and most advanced testing standards to ensure the speed and accuracy of our results. All data is encrypted and securely handled in compliance with local legal regulations.
					</Body>
				</Column>
			</Row>
		</>
	)
}

export { AboutUsPage }
