import React from 'react'
import PropTypes from 'prop-types'
import conditionalPropType from '../utils/ConditionalPropType'

//TO DO
// - Dynamic sizing by button/icon type
// - Reposition icon CSS
// - Style

const buildProps = (type) => {
	const props = {}
	props['style'] = {
		border: 'solid 1px #c7d4da',
		borderRadius: '1.2rem',
		padding: '2rem',
		flexDirection: 'column',
	}

	return props
}

const Card = ({ type, img, alt, children }) => {
	return (
		<div className="card" {...buildProps(type)}>
			{children}
			<img src={img} alt={alt} width="100%" height="auto" />
		</div>
	)
}

Card.propTypes = {
	img: PropTypes.string,
	type: PropTypes.string,
	alt: conditionalPropType(
		(props) => props.img && typeof props.alt !== 'string',
		"'alt' must be a string if 'img' is used"
	),
}

export { Card }
