import React, { useState, useEffect } from 'react'
import { Row, Column, Body, TextInput, Form, Spacer, Paginator, Button, Icon } from '../../../components'
import { useMetrics } from '../../../utils/backend'
import { KitsLoadingIndicator } from './KitsLoadingIndicator'
import { resolveKitResultStatusLabel } from './resolveKitResultStatusLabel'
import { formatShortDate } from '../../../utils'
import pdfIcon from '../../../assets/images/pdf_download.svg'

const AssignedKit = ({ kit }) => {
	const { id, firstName, lastName, email, testedAt, status, resultStatus, certificateUrl } = kit
	const prettyDate = formatShortDate(new Date(testedAt))
	const statusOrResult = resolveKitResultStatusLabel(status, resultStatus)
	const handleDownloadCertificate = (url) => {
		const anchor = document.createElement('a')
		anchor.href = url
		anchor.download = url
		anchor.target = "_blank"
		document.body.appendChild(anchor)
		anchor.click()
	}
	return (
		<Column
			desktopSize={8}
			tabletSize={12}
			style={{ padding: '1.6rem 0', borderBottom: ' solid 1px #c7d4da' }}
		>
			<Row desktopSize={8} tabletSize={12}>
				<Row desktopSize={2} tabletSize={2}>
					<Body>{`${firstName} ${lastName}`}</Body>
				</Row>
				<Row desktopSize={3} tabletSize={4}>
					<Body>{email}</Body>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<Body>{id.split('-').pop().toUpperCase()}</Body>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<Body>{prettyDate}</Body>
				</Row>
				<Row desktopSize={1} tabletSize={1}>
					<Body>{statusOrResult}</Body>
					{certificateUrl ? (
						<Button className="button" style={{backgroundColor:'transparent'}} onClick={() => { handleDownloadCertificate(certificateUrl) }}>
							<Icon img={pdfIcon} type="pdf-inline-center" alt='Download icon' />
						</Button>
					) : ''}
				</Row>
			</Row>
			<Spacer spacing="s" />
		</Column>
	)
}

const NoKits = (props) => {
	return (
		<Row desktopSize={8} tabletSize={12} centered bordered>
			<h3>There are no results</h3>
		</Row>
	)
}

const ResultsReadyKitsList = ({ alerts }) => {
	const defaultFilter = alerts === true ? { alerts: true } : { page: 0 }

	const [emailInput, setEmailInput] = useState('')
	const [filters, setFilters] = useState(defaultFilter)
	const [kits, setKits] = useState(null)
	const [kitsLoading, setKitsLoading] = useState(false)
	const [page, setPage] = useState(0)
	const [numKits, setNumKits] = useState(0)

	const { queryReadyKits } = useMetrics()

	const isFiltersEnabled = alerts !== true
	const hasKits = !kits || kits.length === 0
	const isFiltersSelected = isFiltersEnabled && typeof filters.email === 'string'

	const handleOnEmailSubmit = () => {
		setFilters({ email: emailInput })
		setEmailInput('')
	}

	useEffect(() => {
		setKitsLoading(true)
		queryReadyKits(filters)
			.then((response) => {
				const { kits, numKits, page } = response.data

				setKits(kits)
				setPage(page)
				setNumKits(numKits)
				setKitsLoading(false)
			})
			.catch((error) => {
				console.error(error)
				setKitsLoading(false)
			})
	}, [queryReadyKits, filters])

	if (kitsLoading === true) {
		return <KitsLoadingIndicator />
	}

	return (
		<>
			{isFiltersEnabled && (
				<Form onSubmit={handleOnEmailSubmit}>
					<Row desktopSize={8} tabletSize={12} spaced style={{ alignItems: 'flex-end' }}>
						{isFiltersSelected && (
							<Button secondary onClick={() => setFilters({ page: 0 })}>
								Clear Filters
							</Button>
						)}
						<Spacer spacing="m" />
						<TextInput
							placeholder="alice@email.com"
							value={emailInput}
							setter={setEmailInput}
							desktopSize={3}
							tabletSize={4}
						/>
					</Row>
				</Form>
			)}
			<Paginator
				desktopSize={8}
				tabletSize={12}
				page={page}
				pageSize={50}
				total={numKits}
				maxPages={8}
			/>
			<Row desktopSize={8} tabletSize={12}>
				<Row desktopSize={2} tabletSize={2}>
					<h5>Name</h5>
				</Row>
				<Row desktopSize={3} tabletSize={4}>
					<h5>email</h5>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<h5>Test Kit Id</h5>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<h5>Date tested</h5>
				</Row>
				<Row desktopSize={1} tabletSize={1}>
					<h5>Result</h5>
				</Row>
			</Row>
			<Column desktopSize={8} tabletSize={12}>
				{hasKits ? <NoKits /> : kits.map((kit) => <AssignedKit kit={kit} key={kit.id} />)}
			</Column>
		</>
	)
}

export { ResultsReadyKitsList }
