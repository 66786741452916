// The initial state of the App
import { fromJS } from 'immutable';
import { getActionName } from './util';
import {
    SCHEDULE_TEST, RESET_SCHEDULE_TEST_RESPONSE, GET_EVENTS,
    CREATE_EVENT, RESET_EVENT, RESET_EVENTS_LIST, ADD_LAST_MINUTE_TEST,
    RESET_ADD_LAST_MINUTE_TEST
} from '../actions/order.actions';

const initialState = fromJS({
    scheduleTestResponse: false,
    sceduleTestStatus: [],
    defaultSelectedEvent: '',
    events: '',
    lastMinuteTestLinkSent: false
});
function orderReducer(state = initialState, action) {
    switch (action.type) {
        case SCHEDULE_TEST:
            const indexI = state.get('sceduleTestStatus') && state.get('sceduleTestStatus').findIndex((item) => item.get('serialNumber') === action.data.serialNumber);
            if (indexI > -1) {
                return state.updateIn(['sceduleTestStatus', indexI], (item) => {
                    return item.merge({
                        executionStatus: "PENDING"
                    })
                })
            }
            else
                return state.update('sceduleTestStatus', (statuses) => {
                    return statuses.push(fromJS({
                        serialNumber: action.data.serialNumber,
                        executionStatus: 'PENDING'
                    }))
                })
        case getActionName(GET_EVENTS):
            return state.set('events', fromJS(action.response))
        case GET_EVENTS:
            return state.set('events', '')
        case getActionName(CREATE_EVENT):
            return state.set('defaultSelectedEvent', action.data)
        case getActionName(ADD_LAST_MINUTE_TEST):
            if (action.payload.sendConfirmationLink === true) {
                return state.set('lastMinuteTestLinkSent', true)
            }
            return state;
        case RESET_EVENT:
            return state.set('defaultSelectedEvent', '')
        case RESET_ADD_LAST_MINUTE_TEST:
            return state.set('lastMinuteTestLinkSent', false)
        case getActionName(SCHEDULE_TEST):
            const index = state.get('sceduleTestStatus') && state.get('sceduleTestStatus').findIndex((item) => item.get('serialNumber') === action.data.serialNumber);
            state = state.updateIn(['sceduleTestStatus', index], (item) => {
                return item.merge({
                    executionStatus: "DONE"
                })
            })
            state = state.set('scheduleTestResponse', true);
            return state;
        case RESET_SCHEDULE_TEST_RESPONSE:
            return state.set('scheduleTestResponse', false);
        case RESET_EVENTS_LIST:
            return state.set('events', '');
        default:
            return state;
    }
}
export default orderReducer;
