// import React from 'react'
// import { Row } from './containers'
// import { Body } from './typography'

const Paginator = ({
	desktopSize,
	tabletSize,
	mobileSize,
	size,
	page,
	pageSize,
	total,
	maxPages,
	onPageChange,
}) => {
	return null
}

export { Paginator }
