import { regex } from 'components/common/util/regex'
import moment from 'moment';
import { COUNTRIES_LIST } from "../../../components/common/util/util";

export function isValidConfirmationPatientDetail(kit) {
    let data = kit

    // let isValidDOB = true;
    // let isValiddocumentExpiration = true;
    // console.log(data, "hey321")
    let isValidpreferredTime = true;

    const isValidfamilyName = data.familyName !== undefined && data.familyName && data.familyName.trim() !== "";
    const isValidgivenName = data.givenName !== undefined && data.givenName && data.givenName.trim() !== "";
    const isValidaddressLine1 = data.addressLine1 !== undefined && data.addressLine1 && data.addressLine1.trim() !== "";
    const isValidpostcode = data.postcode !== undefined && data.postcode && data.postcode.trim() !== "";
    const isValidcity = data.city !== undefined && data.city && data.city.trim() !== "";
    const isValiddateOfTest = data.dateOfTest && data.dateOfTest !== "" && data.dateOfTest?.toString().toLowerCase() !== "invalid date"
    const isValidemail = regex.email.test(data.email?.trim()) && data.email && data.email.trim() !== ""
    const isValidtelephone = data.telephone !== undefined && data.telephone && data.telephone.trim() !== "";
    const isValidServiceType = data.serviceType !== undefined && data.serviceType && data.serviceType.trim() !== "";
    isValidpreferredTime = data.serviceType !== "PCR_DAY_2" ? data.preferredTime !== undefined && data.preferredTime && data.preferredTime.trim() !== "" : true;


    // const countryCompleteList = COUNTRIES_LIST?.filter((item, index) => data?.homeAddressCountryText ===  item?.name )
    // console.log('data date of test',data)
    // if (data.DOB)
    //     isValidDOB = data.DOB !== undefined && data.DOB && data.DOB !== "" && data.DOB?.toString().toLowerCase() !== "invalid date";
    // if (data.documentExpiration)
    //     isValiddocumentExpiration = data.documentExpiration !== undefined && data.documentExpiration && data.documentExpiration !== "" && data.documentExpiration?.toString().toLowerCase() !== "invalid date";
    // if (data.gender)
    //     isValidgender = data.gender !== undefined && data.gender && data.gender.trim() !== "";

    // const isValidfullName = data.fullName !== undefined && data.fullName && data.fullName.trim() !== "";
    // const isValidDOB = data.DOB !== undefined && data.DOB && data.DOB !== "";
    // const isValidgender = data.gender !== undefined && data.gender && data.gender && data.gender.trim() !== "";
    // const isValidnationality = data.nationality !== undefined && data.nationality && data.nationality.trim() !== "";
    // const isValiddocumentType = data.documentType !== undefined && data.documentType && data.documentType.trim() !== "";
    // const isValidcountry = data.country !== undefined && data.country && data.country && data.country.trim() !== "";
    // const isValiddocumentCountry = data.documentCountry !== undefined && data.documentCountry && data.documentCountry && data.documentCountry.trim() !== "";
    // const isValiddocumentNumber = data.documentNumber !== undefined && data.documentNumber && data.documentNumber.trim() !== "";
    // const isValiddocumentAuthority = data.documentAuthority !== undefined && data.documentAuthority && data.documentAuthority.trim() !== "";
    // const isValiddocumentExpiration = data.documentExpiration !== undefined && data.documentExpiration && data.documentExpiration !== "";

    let allValid = false;
    allValid = isValidfamilyName && isValidgivenName && isValidaddressLine1 && isValidpostcode && isValidcity && isValiddateOfTest &&
        isValidemail && isValidtelephone && isValidServiceType && isValidpreferredTime

    return {
        isValidgivenName: isValidgivenName,
        isValidfamilyName: isValidfamilyName,
        isValidaddressLine1: isValidaddressLine1,
        isValidpostcode: isValidpostcode,
        isValidcity: isValidcity,
        isValiddateOfTest: isValiddateOfTest,
        isValidemail: isValidemail,
        isValidtelephone: isValidtelephone,
        isValidserviceType: isValidServiceType,
        isValidpreferredTime: isValidpreferredTime,
        // isValidfullName: isValidfullName,
        // isValidDOB: isValidDOB,
        // isValidcountry: isValidcountry,
        // isValidgender: isValidgender,
        // isValidnationality: isValidnationality,
        // isValiddocumentType: isValiddocumentType,
        // isValiddocumentCountry: isValiddocumentCountry,
        // isValiddocumentNumber: isValiddocumentNumber,
        // isValiddocumentAuthority: isValiddocumentAuthority,
        // isValiddocumentExpiration: isValiddocumentExpiration,
        allValid: allValid
    }

}

export function isValidLastMinuteTest(data) {

    let isValidDOB = true;
    let isValiddocumentExpiration = true;
    let isValidgender = true;

    const isValidEvent = data.selectedEvent !== undefined && data.selectedEvent && data.selectedEvent.value.trim() !== "";

    const isValiddateOfTest = data.dateOfTest !== undefined && data.dateOfTest && data.dateOfTest !== "" && data.dateOfTest?.toString().toLowerCase() !== "invalid date" && moment(data.dateOfTest, "DD/MM/YYYY").toDate().setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
    const isValidServiceType = data.serviceType !== undefined && data.serviceType && data.serviceType.value.trim() !== "";
    const isValidpreferredTime = data.preferredTime !== undefined && data.preferredTime && data.preferredTime.value.trim() !== "";

    const isValidpostcode = data.postcode !== undefined && data.postcode && data.postcode.trim() !== "";
    const isValidaddressLine1 = data.addressLine1 !== undefined && data.addressLine1 && data.addressLine1.trim() !== "";
    const isValidcity = data.city !== undefined && data.city && data.city.trim() !== "";

    // const isValidfullName = data.fullName !== undefined && data.fullName && data.fullName.trim() !== "";
    const isValidfamilyName = data.familyName !== undefined && data.familyName && data.familyName.trim() !== "";
    const isValidgivenName = data.givenName !== undefined && data.givenName && data.givenName.trim() !== "";
    if (data.DOB)
        isValidDOB = data.DOB !== undefined && data.DOB && data.DOB !== "" && data.DOB?.toString().toLowerCase() !== "invalid date";
    if (data.gender)
        isValidgender = data.gender !== undefined && data.gender && data.gender.trim() !== "";
    const isValidpassportNum = data.passportNum !== undefined && data.passportNum && data.passportNum.trim() !== "";
    const isValidethnicity = data.ethnicity !== undefined && data.ethnicity.value && data.ethnicity.value.trim() !== "";
    const isValidvaccinationStatus = data.vaccinationStatus !== undefined && data.vaccinationStatus.value && data.vaccinationStatus.value.trim() !== "";

    const isValidemail = regex.email.test(data.email) && data.email && data.email.trim() !== ""
    const isValidtelephone = data.telephone !== undefined && data.telephone && data.telephone.trim() !== "";
    const isValidarrivalDate = data.arrivalDate !== undefined && data.arrivalDate && data.arrivalDate !== "" && data.arrivalDate?.toString().toLowerCase() !== "invalid date";
    const isValidtravelMethod = data.travelMethod !== undefined && data.travelMethod.value && data.travelMethod.value.trim() !== "";
    const isValidvesselNumber = data.vesselNumber !== undefined && data.vesselNumber && data.vesselNumber.trim() !== "";
    const isValiddepartureDate = data.departureDate !== undefined && data.departureDate && data.departureDate !== "" && data.departureDate?.toString().toLowerCase() !== "invalid date";
    const isValiddepartureCountry = data.departureCountry !== undefined && data.departureCountry.value && data.departureCountry.value.trim() !== "";


    if (data.documentExpiration)
        isValiddocumentExpiration = data.documentExpiration !== undefined && data.documentExpiration && data.documentExpiration !== "" && data.documentExpiration?.toString().toLowerCase() !== "invalid date";

    // const isValidDOB = data.DOB !== undefined && data.DOB && data.DOB !== "";


    // const isValidgender = data.gender !== undefined && data.gender && data.gender && data.gender.trim() !== "";

    // const isValidHomeAddressLine1 = data.homeAddressLine1 !== undefined && data.homeAddressLine1 && data.homeAddressLine1.trim() !== "";
    // const isValidnationality = data.nationality !== undefined && data.nationality && data.nationality.trim() !== "";
    // const isValiddocumentType = data.documentType !== undefined && data.documentType && data.documentType.trim() !== "";
    // const isValidcountry = data.country !== undefined && data.country && data.country && data.country.trim() !== "";
    // const isValiddocumentCountry = data.documentCountry !== undefined && data.documentCountry && data.documentCountry && data.documentCountry.trim() !== "";
    // const isValiddocumentNumber = data.documentNumber !== undefined && data.documentNumber && data.documentNumber.trim() !== "";
    // const isValiddocumentAuthority = data.documentAuthority !== undefined && data.documentAuthority && data.documentAuthority.trim() !== "";
    // const isValiddocumentExpiration = data.documentExpiration !== undefined && data.documentExpiration && data.documentExpiration !== "";

    const allValidDay = isValidEvent && isValiddateOfTest && isValidpreferredTime && isValidaddressLine1 && isValidpostcode && isValidcity && isValidfamilyName && isValidgivenName && isValidemail && isValidtelephone;
    const allValidDay2 = isValidEvent && isValiddateOfTest
        && isValidpostcode && isValidaddressLine1 && isValidcity
        && isValidgivenName && isValidfamilyName && isValidpassportNum && isValidethnicity && isValidvaccinationStatus
        && isValidemail && isValidtelephone && isValidarrivalDate && isValidtravelMethod && isValidvesselNumber && isValiddepartureDate && isValiddepartureCountry;
    //  && isValidDOB &&
    // isValidfullName && isValidfamilyName &&  isValidgivenName
    // && isValidtelephone && isValidgender && isValidaddressLine1 && isValidpostcode && isValidcity &&
    // isValidnationality && isValiddocumentType && isValiddocumentCountry && isValiddocumentNumber && 
    // isValiddocumentAuthority && isValiddocumentExpiration && isValidcountry 

    return {
        isValidEvent: isValidEvent,
        isValiddateOfTest: isValiddateOfTest,
        isValidpreferredTime: isValidpreferredTime,
        isValidServiceType: isValidServiceType,

        isValidpostcode: isValidpostcode,
        isValidaddressLine1: isValidaddressLine1,
        isValidcity: isValidcity,

        isValidfamilyName: isValidfamilyName,
        isValidgivenName: isValidgivenName,
        isValidpassportNum: isValidpassportNum,
        isValidethnicity: isValidethnicity,
        isValidvaccinationStatus: isValidvaccinationStatus,

        isValidemail: isValidemail,
        isValidtelephone: isValidtelephone,
        isValidarrivalDate: isValidarrivalDate,
        isValidtravelMethod: isValidtravelMethod,
        isValidvesselNumber: isValidvesselNumber,
        isValiddepartureDate: isValiddepartureDate,
        isValiddepartureCountry: isValiddepartureCountry,

        // isValidDOB: isValidDOB,
        // isValidcountry: isValidcountry,
        // isValidgender: isValidgender,


        // isValidnationality: isValidnationality,
        // isValiddocumentType: isValiddocumentType,
        // isValiddocumentCountry: isValiddocumentCountry,
        // isValiddocumentNumber: isValiddocumentNumber,
        // isValiddocumentAuthority: isValiddocumentAuthority,
        isValiddocumentExpiration: isValiddocumentExpiration,
        allValid: data?.serviceType?.value === 'PCR_DAY_2' ? allValidDay2 : allValidDay
    }
}