import firebase from 'firebase/app'

export const PROCESS_SAME_DAY_ORDER = "PROCESS_SAME_DAY_ORDER";
export const SCHEDULE_TEST = "SCHEDULE_TEST";
export const RESET_SCHEDULE_TEST_RESPONSE = "RESET_SCHEDULE_TEST_RESPONSE";
export const CREATE_EVENT = "CREATE_EVENT";
export const GET_EVENTS = "GET_EVENTS";
export const RESET_EVENT = "RESET_EVENT";
export const RESET_EVENTS_LIST = "RESET_EVENTS_LIST";
export const ADD_LAST_MINUTE_TEST = "ADD_LAST_MINUTE_TEST";
export const RESET_ADD_LAST_MINUTE_TEST = "RESET_ADD_LAST_MINUTE_TEST";

const REGION = process.env.REACT_APP_FIREBASE_REGION || 'europe-west2'
const funs = firebase.app().functions(REGION)

export function processSameDayOrder(data, bulkUplaod) {
    return {
        firebaseFunction: funs.httpsCallable('invokeCreateSameOrderCall'),
        type: PROCESS_SAME_DAY_ORDER,
        data,
        payload: {
            bulkUplaod
        }
    }
}

export function scheduleTest(data) {
    return {
        firebaseFunction: funs.httpsCallable('invokeAddScheduleTestCall'),
        type: SCHEDULE_TEST,
        data,
        payload: { ...data }
    }
}

export function addLastMinuteTest(data, sendConfirmationLink = false) {
    if (sendConfirmationLink === true) {
        return {
            firebaseFunction: funs.httpsCallable('invokeSendPatientConfirmationLinkCall'),
            type: ADD_LAST_MINUTE_TEST,
            data,
            payload: { ...data, sendConfirmationLink }
        }
    }
    else {
        return {
            firebaseFunction: funs.httpsCallable('invokeAddScheduleTestCall'),
            type: ADD_LAST_MINUTE_TEST,
            data,
            payload: { ...data, sendConfirmationLink }
        }
    }
}

export function createEvent(data) {
    return {
        firebaseFunction: funs.httpsCallable('invokeAddEventCall'),
        type: CREATE_EVENT,
        data
    }
}
export function getEvents(data) {
    return {
        firebaseFunction: funs.httpsCallable('retrieveEventsCall'),
        type: GET_EVENTS,
        data
    }
}

export function resetScheduleTestResponse() {
    return {
        type: RESET_SCHEDULE_TEST_RESPONSE
    }
}
export function resetEvent() {
    return {
        type: RESET_EVENT
    }
}
export function resetAddLastMinuteTest() {
    return {
        type: RESET_ADD_LAST_MINUTE_TEST
    }
}
export function resetEventList() {
    return {
        type: RESET_EVENTS_LIST
    }
}