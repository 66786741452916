

export const rewriteErrorMessage = ({message = '', code}) => {

	const errorMessageMap = {
		'auth/wrong-password': 'The email address or password is invalid. Please check and try again.',
		'auth/invalid-email': 'Please enter a valid email address.'
	}
	const { [code]: formattedErrorMessage = message } = errorMessageMap;
	return formattedErrorMessage;
};
