import React, { useState, useEffect } from 'react'
import { Row, Column, Body, TextInput, Form, Spacer, Button, Icon } from '../../../components'
import { useMetrics } from '../../../utils/backend'
import { KitsLoadingIndicator } from './KitsLoadingIndicator'
import { resolveSameDayKitResultStatusLabel } from './resolveKitResultStatusLabel'
import { formatShortDate } from '../../../utils'
import pdfIcon from '../../../assets/images/pdf_download.svg'

const SameDayKitResult = ({ kit }) => {
	const { id, fullName, email, catalyst_sampleCollectAt, status, resultStatus, certificateUrl } = kit
	const formattedDate = catalyst_sampleCollectAt ? formatShortDate(new Date(catalyst_sampleCollectAt)) : ""
	const statusOrResult = resolveSameDayKitResultStatusLabel(status, resultStatus)
	const handleDownloadCertificate = (url) => {
		const anchor = document.createElement('a')
		anchor.href = url
		anchor.download = url
		anchor.target = "_blank"
		document.body.appendChild(anchor)
		anchor.click()
	}
	return (
		<Column
			desktopSize={8}
			tabletSize={12}
			style={{ padding: '1.6rem 0', borderBottom: ' solid 1px #c7d4da' }}
		>
			<Row desktopSize={8} tabletSize={12}>
				<Row desktopSize={2} tabletSize={2}>
					<Body>{ fullName }</Body>
				</Row>
				<Row desktopSize={3} tabletSize={4}>
					<Body>{email}</Body>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<Body>{ id }</Body>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<Body>{ formattedDate }</Body>
				</Row>
				<Row desktopSize={1} tabletSize={1}>
					<Body>{ statusOrResult }</Body>
					{certificateUrl ? (
						<Button className="button" style={{backgroundColor:'transparent'}} onClick={() => { handleDownloadCertificate(certificateUrl) }}>
							<Icon img={pdfIcon} type="pdf-inline-center" alt='Download icon' />
						</Button>
					) : ''}
				</Row>
			</Row>
			<Spacer spacing="s" />
		</Column>
	)
}

const NoKits = (props) => {
	return (
		<Row desktopSize={8} tabletSize={12} centered bordered>
			<h3>There are no results</h3>
		</Row>
	)
}



const SameDayKitResultsList = () => {
	// const [kitsLoading, setKitsLoading] = useState(false)

	const [sameDayKits, setSameDayKits] = useState(null)
	const [gettingSameDayKits, setGettingSameDayKits] = useState(false)

	const [kitSearchText, setKitSearchText] = useState("");
    const [pagingObj, setPagingObj] = useState({ page: 1, pageType: '', docRef: '' })
    const [pageSize, setPageSize] = useState(20)

	const { querySameDayReadyKits } = useMetrics()

	const pageWrapper = {
        alignItems: "center"
    }

    const pageInfoStyle = {
        fontSize: "14px",
        padding: "0 1rem",
        fontWeight: "600",
    }

	const totalPages = () => {
        return sameDayKits ? Math.ceil(sameDayKits.totalCount / pageSize) : 0
    }
    const next = () => {
        setPagingObj({
            page: pagingObj.page + 1,
            pageType: 'next',
            docRef: sameDayKits.kits[sameDayKits.kits.length - 1].id
        })
    }
    const prev = () => {
        setPagingObj({
            page: pagingObj.page - 1,
            pageType: 'prev',
            docRef: sameDayKits.kits[0].id
        })
    }

	const onSameDayKitResultsSearch = () => {
        let requestParams = { page: pagingObj.page }
        if (kitSearchText) {
            requestParams["query"] = kitSearchText
        }
        setGettingSameDayKits(true)
        querySameDayReadyKits(requestParams)
            .then((response) => {
                setGettingSameDayKits(false)
                setSameDayKits(response.data)
            })
            .catch((error) => {
                setGettingSameDayKits(false)
                console.error(error)
            })
    }

	useEffect(() => {
		onSameDayKitResultsSearch()
	}, [querySameDayReadyKits, pagingObj])

	if (gettingSameDayKits === true) {
		return <KitsLoadingIndicator />
	}

	return (
		<>
			<Form onSubmit={onSameDayKitResultsSearch}>
				<Row desktopSize={8} tabletSize={12} spaced style={{ alignItems: 'flex-end' }}>
					<Spacer spacing="m" />
					<TextInput
						placeholder="Search by Full Name, Email or Order Id"
						value={kitSearchText}
						setter={setKitSearchText}
						desktopSize={3}
						tabletSize={4}
					/>
				</Row>
			</Form>
			<Row desktopSize={8} tabletSize={12}>
				<Row desktopSize={2} tabletSize={2}>
					<h5>Name</h5>
				</Row>
				<Row desktopSize={3} tabletSize={4}>
					<h5>email</h5>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<h5>Test Kit Id</h5>
				</Row>
				<Row desktopSize={1} tabletSize={2}>
					<h5>Date tested</h5>
				</Row>
				<Row desktopSize={1} tabletSize={1}>
					<h5>Result</h5>
				</Row>
			</Row>
			<Column desktopSize={8} tabletSize={12}>
				{totalPages() === 0 ? <NoKits /> : sameDayKits.kits.map((kit) => <SameDayKitResult kit={kit} key={kit.id} />)}
			</Column>
			<Column size={8}>
				{
					sameDayKits && sameDayKits.totalCount ?
						<div style={pageWrapper}>
							{
								pagingObj.page > 1 ?
									<Button secondary onClick={prev}>
										Prev
									</Button>
									: null
							}
							<div style={pageInfoStyle}>Page {pagingObj.page} of {totalPages()}</div>
							{
								pagingObj.page < totalPages() ?
									<Button secondary onClick={next}>
										Next
									</Button>
									: null
							}

						</div>
						: null
				}

			</Column>
		</>
	)
}

export { SameDayKitResultsList }
