import React from 'react'
import { Column, Row, Breadcrumps } from '../../../components'
import { withAuthentication } from '../../../utils'
import { ResultsReadyKitsList } from './ResultsReadyKitsList'
import { OverviewCharts } from './OverviewCharts'
import { KitsStatusesList } from './KitsStatusesList'
import { SameDayKitResultsList } from './SameDayKitResultsList'
import { SameDayKitsStatusesList } from './SameDayKitsStatusesList'

const KitsDataPage = withAuthentication((props) => {
	return (
		<Column desktopSize={8} tabletSize={12}>
			<Breadcrumps />
			<Row desktopSize={8} tabletSize={12}>
				<h3>Results data</h3>
			</Row>
			<OverviewCharts />
			<Column desktopSize={8} tabletSize={12}>
				<Row desktopSize={8} tabletSize={12}>
					<KitsStatusesList />
				</Row>
				<Row desktopSize={8} tabletSize={12}>
					<h4>Same Day Kit Statuses</h4>
				</Row>
				<Row desktopSize={8} tabletSize={12}>
					<SameDayKitsStatusesList />
				</Row>
				<Row desktopSize={8} tabletSize={12}>
					<h4>Alerts</h4>
				</Row>
				<ResultsReadyKitsList alerts />
			</Column>
			
			<Column desktopSize={8} tabletSize={12}>
				<Row desktopSize={8} tabletSize={12}>
					<h4>Latest Updates</h4>
				</Row>
				<ResultsReadyKitsList />
			</Column>

			<Column desktopSize={8} tabletSize={12}>
				<Row desktopSize={8} tabletSize={12}>
					<h4>Same Day Test Results</h4>
				</Row>
				<SameDayKitResultsList />
			</Column>
		
		</Column>
	)
})

export { KitsDataPage }
