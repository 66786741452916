import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appReducer from 'view.updater/reducers/app.reducer';
import localizationReducer from 'view.updater/reducers/localization.reducer';
import authReducer from './auth.reducer';
import dashboardReducer from './dashboard.reducer';
import orderReducer from './order.reducer';
//import REDUCER_NAMEReducer from './REDUCER_FILENAME.reducer';
//import --EDUCER_NAMEReducer from './--EDUCER_FILENAME.reducer';
export default function createReducer(history,injectedReducers) {
  return combineReducers({
    router: connectRouter(history),
    app: appReducer,
    order : orderReducer,
    dashboard : dashboardReducer,
    authorization : authReducer,
    localization :  localizationReducer,
    //INDEX_STATE_NAME_REDUCER : REDUCER_NAMEReducer,
    //__INDEX_STATE_NAME_REDUCER : --EDUCER_NAMEReducer,
    ...injectedReducers,
  });
}
//Remove__ and Replace -- with R