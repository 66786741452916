/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectOrder = (state) => {
    return state.order;
}

const makeSelectScheduleTestResponse = () => createSelector(
    selectOrder,
    (orderState) => orderState.get('scheduleTestResponse')
);

const makeSelectEvents = () => createSelector(
    selectOrder,
    (orderState) => orderState.get('events')
);

const makeSelectDefaultSelectedEvent = () => createSelector(
    selectOrder,
    (orderState) => orderState.get('defaultSelectedEvent')
);

const makeSelectScheduleTestStatus = () => createSelector(
    selectOrder,
    (orderState) => orderState.get('sceduleTestStatus')
);

const makeSelectLastMinuteTestLink = () => createSelector(
    selectOrder,
    (orderState) => orderState.get('lastMinuteTestLinkSent')
);
export {
    selectOrder,
    makeSelectScheduleTestResponse,
    makeSelectScheduleTestStatus,
    makeSelectEvents,
    makeSelectDefaultSelectedEvent,
    makeSelectLastMinuteTestLink
};
