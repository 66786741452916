import React, { useEffect, useState } from 'react'
import { Row, Spacer, Column, Button, LoadingAnimation, TextInput, Paginator, Form } from '../../../components'
import { useAuth, useOrdering, useAdmin, useMetrics } from '../../../utils/backend'
import { withAuthentication } from '../../../utils/withAuthentication'
import ReactPaginate from 'react-paginate';
import { KitsLoadingIndicator } from '../KitsDataPage/KitsLoadingIndicator'
import { useModal } from '../../../utils';
import { EditSameDayKitModal } from './EditSameDayKitModal';
import { Link } from 'react-router-dom';
import { validateKit } from './validator';

//ASSIGN_SAME_DAY_KITS_CHANGES
const AssignSameDayKitsPage = withAuthentication(() => {
    const { customer } = useAuth()
    const { backendLoading } = useAdmin()
    const { querySameDayKits, invokeGenerateSameDayKitsCsv, invokeUpdateSameDayKits, queryAllSameDayKits } = useMetrics()
    const [sameDayKits, setSameDayKits] = useState(null)
    const [gettingSameDayKits, setGettingSameDayKits] = useState(false)
    const [selectedKitsIds, setSelectedKitsIds] = useState([]);
    const [showEditKitModal, setShowEditKitModal] = useState(false);
    const [selectedKit, setSelectedKit] = useState(null);
    const [assignKitLoading, setAssignKitLoadig] = useState(null)
    const [editKitLoading, setEditKitLoadig] = useState(null)
    const [kitSearchText, setKitSearchText] = useState("");
    const [pagingObj, setPagingObj] = useState({ page: 1, pageType: '', docRef: '' })
    const [pageSize, setPageSize] = useState(20)

    const onSelectKit = (kit, validDOB, validGender) => {
        if (validDOB && validGender) {
            let kitIds = [...selectedKitsIds];
            if (kit) {
                if (kitIds.includes(kit.id)) {
                    var index = selectedKitsIds.indexOf(kit.id)
                    kitIds.splice(index, 1)
                }
                else {
                    kitIds.push(kit.id)
                }
                setSelectedKitsIds(kitIds)
            }
        }
    }
    const editKit = (kitPayload) => {
        setEditKitLoadig(true);
        invokeUpdateSameDayKits({ kit: kitPayload })
            .then(response => {
                setEditKitLoadig(false);
                const bodyEl = document && document.querySelector('body')
                const modalsEl = document && document.querySelector('#modals')
                if (bodyEl)
                    bodyEl.className = ''
                if (modalsEl)
                    modalsEl.className = ''
                setShowEditKitModal(false);
                onKitsSearch()
            })
    }
    const checkIsKitSelected = (kitId) => {
        if (selectedKitsIds.includes(kitId)) {
            return true
        }
        else {
            return false
        }
    }
    const openEditKitModal = (e, kit) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedKit(kit);
        setShowEditKitModal(true);
    }
    const hideEditKitModal = () => {
        setShowEditKitModal(false)
    }
    const assignKit = (type) => {
        setAssignKitLoadig(true)
        invokeGenerateSameDayKitsCsv({ kitIds: selectedKitsIds, type })
            .then(response => {
                setAssignKitLoadig(false)
                setSelectedKitsIds([]);
                onKitsSearch();
            })
    }

    const totalPages = () => {
        return Math.ceil(sameDayKits.totalCount / pageSize)
    }
    const next = () => {
        setPagingObj({
            page: pagingObj.page + 1,
            pageType: 'next',
            docRef: sameDayKits.kits[sameDayKits.kits.length - 1].id
        })
    }
    const prev = () => {
        setPagingObj({
            page: pagingObj.page - 1,
            pageType: 'prev',
            docRef: sameDayKits.kits[0].id
        })
    }
    const onKitsSearch = () => {
        let requestParams = { page: pagingObj.page }
        if (kitSearchText) {
            requestParams["query"] = kitSearchText
        }
        setGettingSameDayKits(true)
        querySameDayKits(requestParams)
            .then((response) => {
                setGettingSameDayKits(false)
                setSameDayKits(response.data)
            })
            .catch((error) => {
                setGettingSameDayKits(false)
                console.error(error)
            })
    }

    useEffect(() => {
        if (!gettingSameDayKits) {
            onKitsSearch()
        }
    }, [querySameDayKits, pagingObj])

    const pageWrapper = {
        alignItems: "center"
    }

    const pageInfoStyle = {
        fontSize: "14px",
        padding: "0 1rem",
        fontWeight: "600",
    }

    const orderIdTagStyle = {
        background: "rgb(6 122 184 / 63%)",
        color: "#fff",
        padding: "0.5rem",
        borderRadius: "0.5rem",
        fontWeight: "400"
    }

    const requiredMsgStyle = {
        fontSize: "14px",
        color: "#ff0000cf",
        fontWeight: "600"
    }

    const validationMsgStyle = {
        display: 'flex',
        flexDirection: 'column',
        marginTop: "1rem"
    }

    const searchRowStyle = {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(2, auto)",
        justifyContent: "space-between",
        alignItems: "center"
    }

    if (gettingSameDayKits === true) {
        return <div style={{ marginTop: '12rem' }}><KitsLoadingIndicator /></div>
    }

    const [showKitModal] = useModal(<EditSameDayKitModal editKitLoading={editKitLoading} kit={selectedKit} editKit={editKit} />)

    return (
        <div>
            <Column size={8}>
                <Spacer spacing="m" />
                <Spacer spacing="m" />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <div>
                        <h3>Assign Same Day Order Kits</h3>
                    </div>
                    <div>
                        <h5 style={{
                            paddingRight: '35px'
                        }}>
                            {
                                selectedKitsIds.length > 0 ?
                                    `${selectedKitsIds.length} Selected` : null
                            }
                        </h5>
                        {
                            selectedKitsIds.length > 0 ?
                                <div>
                                    {
                                        assignKitLoading === true ?
                                            <Button loading={assignKitLoading} primary noSpacing>
                                            </Button> :
                                            <div style={{
                                                display : 'flex',
                                                flexDirection: 'column'
                                            }}>
                                                <Button loading={assignKitLoading} onClick={()=>assignKit("CSV")} primary noSpacing>
                                                    {`Assign ${selectedKitsIds.length} kit(s) to Lab via CSV`}
                                                </Button>
                                                <div style={{
                                                    paddingTop : '3px'
                                                }}>
                                                <Button loading={assignKitLoading} onClick={()=>assignKit("API")} primary noSpacing>
                                                    {`Assign ${selectedKitsIds.length} kit(s) to Lab via API`}
                                                </Button>
                                                </div>
                                            </div>
                                    }
                                </div> : null
                        }
                    </div>
                </div>
                <div style={searchRowStyle}>
                    <div>
                        <h4>Select kit(s) to assign</h4>
                    </div>
                    <div>
                        <Form onSubmit={onKitsSearch}>
                            <TextInput
                                placeholder="Search by Full Name, Email or Order Id"
                                value={kitSearchText}
                                setter={setKitSearchText}
                                desktopSize={3}
                                tabletSize={4}
                            />
                        </Form>
                    </div>
                </div>

                <Spacer spacing="s" />
                <Column size={8}>
                    {sameDayKits && sameDayKits.kits && sameDayKits.kits.map((
                        kit,
                        index
                    ) => {
                        const validationResult = validateKit(kit);
                        return (
                            <span onClick={() => onSelectKit(kit, validationResult.isValidDOB, validationResult.isValidGender)} key={index + '-KitItem'}
                            >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '12px',
                                    cursor: 'pointer',
                                    background: checkIsKitSelected(kit.id) ? '#c2c2c2' : '#f5f5f5',
                                    boxShadow: checkIsKitSelected(kit.id) ? '0 2px 5px 1px rgb(64 60 67 / 16%)' : 'none'
                                }}>
                                    <div style={{
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        fontSize: '18px'
                                    }}>
                                        <div>
                                            {kit.fullName}
                                        </div>
                                        <div style={orderIdTagStyle}>
                                            {kit.orderUid}
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <div style={{
                                                fontSize: '14px'
                                            }}>
                                                {kit.email}
                                            </div>
                                            <div style={{
                                                fontSize: '14px'
                                            }}>
                                                {kit.gender}
                                            </div>
                                            <div style={{
                                                fontSize: '14px'
                                            }}>
                                                {kit.status}
                                            </div>
                                            <Link style={{
                                                width: '25%'
                                            }} to="" onClick={(e) => openEditKitModal(e, kit)} >Edit</Link>
                                            <Link style={{
                                                width: '25%'
                                            }} to={`/customer/print-same-day-kit/${kit.id}`} target="_blank" >Print</Link>
                                        </div>
                                        <div style={validationMsgStyle}>
                                            {
                                                validationResult.isValidDOB === false ?
                                                    <div style={requiredMsgStyle}>
                                                        Missing Date of Birth
                                                    </div> : null
                                            }
                                            {
                                                validationResult.isValidGender === false ?
                                                    <div style={requiredMsgStyle}>
                                                        Missing Gender
                                                    </div> : null
                                            }
                                        </div>
                                    </div>

                                </div>
                                <Spacer spacing="s" />
                            </span>
                        )
                    })}

                </Column>
                <Column size={8}>
                    {
                        sameDayKits && sameDayKits.totalCount ?
                            <div style={pageWrapper}>
                                {
                                    pagingObj.page > 1 ?
                                        <Button secondary onClick={prev}>
                                            Prev
                                        </Button>
                                        : null
                                }
                                <div style={pageInfoStyle}>Page {pagingObj.page} of {totalPages()}</div>
                                {
                                    pagingObj.page < totalPages() ?
                                        <Button secondary onClick={next}>
                                            Next
                                        </Button>
                                        : null
                                }

                            </div>
                            : null
                    }

                </Column>

            </Column >
            {showEditKitModal ? showKitModal(hideEditKitModal) : null}
        </div>
    )
})

export { AssignSameDayKitsPage }
