import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { Paper } from '@material-ui/core'
import { formatDate, format24HrDateTime, format24HrDateTimeV2 } from 'components/common/util/util'

export default function CustomCalender(props) {
  const [state, setState] = useState({
    anchorEl: undefined,
    width: undefined,
    dateDialogIsOpen: false,
    dateAnchor: undefined
  })
  const { classNames, required, defaultValue, views, label, value, displayText, minDate, maxDate, error, showTime } = props; // views can be month , year , date , should be array
  const [sDate, setSDate] = useState(value);
  const [defaultSelectedValue, setDefaultSelectedValue] = useState(false);
  let cdate;
  if (showTime) {
    cdate = format24HrDateTimeV2(value);
  }
  else {
    cdate = formatDate(value);
  }
  const onDateSelect = (event) => {
    event.stopPropagation()
    setState({ ...state, dateAnchor: event.currentTarget })
  }
  const onAccept = (date) => {
    //if(defaultValue) {
    setDefaultSelectedValue(formatDate(date))
    props.onAccept && props.onAccept(date);
    //}
    // if (views && views.length > 0) {
    // }
    // else
    //   setState({ ...state, dateAnchor: false })
  }
  const onClose = (date) => {
    setState({ ...state, dateAnchor: false })
  }
  const onYearChange = (date, event) => {
    setSDate(date);
    //props.onYearChange && props.onYearChange(date);
    //setState({ ...state, dateAnchor: false })
  }
  const onChange = (date) => {
    setSDate(date);
  }
  useEffect(() => {
    if (defaultValue) {
      setDefaultSelectedValue(formatDate(defaultValue))
    }
  }, [])

  useEffect(() => {
    if (state.dateAnchor === false) {
      if (showTime) {
        var neDate = format24HrDateTime(sDate);
        var cdate = format24HrDateTime(value);
        if (cdate !== neDate) {
          props.onChange && props.onChange(sDate);
        }
      }
      else {
        var neDate = formatDate(sDate);
        var cdate = formatDate(value);
        if (cdate !== neDate) {
          props.onChange && props.onChange(sDate);
        }
      }
    }
  }, [sDate, state.dateAnchor])

  const openKeyboardDatePicker = Boolean(state.dateAnchor)
  return (
    <React.Fragment>
      <div className="w-100">
        <div>
          {label ? (
            <Typography className=" d-flex pb-2 pt-2 font-weight-bold">
              {
                required == true ?
                  <div className="text-danger">*</div> : null
              }
              <div>
                {label}
              </div>
            </Typography>
          ) : null}
        </div>
        <div>
          <Paper
            elevation={1}
            style={{
              height: '40px',
              minWidth: '160px'
            }}
            className={`${classNames} p-2 d-flex align-items-center justify-content-between w-100 cursor-pointer paper-root`}
            onClick={(event) => onDateSelect(event)}
          >
            <div className="d-flex align-items-center">

              <Typography className="">
                {displayText ? displayText : value ? cdate : defaultSelectedValue ? defaultSelectedValue : "Select Date"}
              </Typography>
            </div>
            <img src={`${process.env.PUBLIC_URL}/icons/calendar-icon.svg`} />
          </Paper>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              onYearChange={onYearChange}
              views={views}
              autoOk={true}
              disableToolbar={false}
              margin="normal"
              variant="inline"
              id="date-picker-dialog"
              label="Date picker dialog"
              format="MM/dd/yyyy"
              PopoverProps={{
                anchorEl: state.dateAnchor,
                open: openKeyboardDatePicker,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              defaultValue={defaultValue}
              value={sDate ? sDate : value}
              style={{
                display: 'none',
              }}
              onChange={onChange}
              keyboardIcon={true}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              onAccept={(date) => onAccept(date)}
              onClose={() => onClose()}
              minDate={minDate}
              maxDate={maxDate}
            />
          </MuiPickersUtilsProvider>
          {
            error ?
              <div className="text-danger pt-2 pl-1">
                Please select date
              </div> : null
          }
        </div>
      </div>
    </React.Fragment>
  )
}

// <div className="w-25">
//           <CustomCalender
//             classNames="ml-3"
//             placeholder="Select Date"
//             variant={state.searchBy == 'date' ? 'contained' : 'outlined'}
//             onClick={() => setState({ ...state, searchBy: 'date' })}
//             onChange={() => onDateChange()}
//             onAccept={onAccept}
//             selectedDate={state.selectedDate}
//             label="From"
//           ></CustomCalender>
//         </div>
