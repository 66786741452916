import React from 'react'
import { Redirect } from 'react-router'
import { Column, LoadingAnimation } from '../components'
import { useAuth } from './backend'

const withAuthentication = (component) => (props) => {
	const { authStatus } = useAuth()

	if (authStatus === 'NotAuthorized') {
		return (
			<>
				<Redirect to="/" />
			</>
		)
	} else if (authStatus === 'Authorizing') {
		return (
			<>
				<Column centered style={{ minHeight: '20rem' }}>
					<LoadingAnimation orange />
				</Column>
			</>
		)
	} else if (authStatus === 'Authorized') {
		const ComponentClass = component
		return <ComponentClass {...props} />
	} else {
		console.error(`withAuthentication unexpected authStatus:${authStatus} `)
	}
}

export { withAuthentication }
