import React, { useEffect, useState } from 'react'
import ContentDialog from 'components/common/content.dialog/content.dialog'
import { Typography } from '@material-ui/core'
import { isValidPatientDetail } from '../validator';
import { isValidExcelPatientDetail } from './validator';
import { isValidConfirmationPatientDetail } from '../../confirm.orders/components/validator';

const InfoValidationDialog = (props) => {
    const { open, dataToValidate } = props;
    const [validationStatus, setValidationStatus] = useState(null)

    useEffect(() => {
        if (dataToValidate) {
            if (props?.validationFor === "adminConfirmationOrder") {
                setValidationStatus({
                    ...isValidConfirmationPatientDetail({ ...dataToValidate })
                })
            }
            else {
                setValidationStatus({
                    ...isValidExcelPatientDetail({ ...dataToValidate })
                })
            }

        }
    }, [dataToValidate])

    useEffect(() => {
        console.log(validationStatus, "status")
    }, [validationStatus])
    return (
        <div>
            <ContentDialog
                onClose={props.handleClose}
                dialogIsOpen={open}
                title={<div><Typography><b>Following information is missing or incorrect</b></Typography></div>}>
                {
                    validationStatus &&
                    <div className="p-3">
                        {
                            validationStatus.allValid &&
                            <div className="w-100 d-flex flex-column p-2 pl-5 font-weight-bold justify-content-center align-items-center">
                                <i class="far fa-5x fa-check-circle" style={{ color: "green" }}></i>
                                <span className="mt-2">No Errors! You can process it now</span>
                            </div>
                        }
                        {
                            !validationStatus.isValidemail &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                Email is not valid
                            </div>
                        }
                        {
                            (props?.validationFor === "adminConfirmationOrder"
                                ? !validationStatus.isValidgivenName : !validationStatus.isValidGivenName) &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                First Name is not valid
                            </div>
                        }
                        {
                            (props?.validationFor === "adminConfirmationOrder"
                                ? !validationStatus.isValidfamilyName : !validationStatus.isValidFamilyName) &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                Last Name is not valid
                            </div>
                        }
                        {
                            !validationStatus.isValiddateOfTest &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                Date of Test is not valid
                            </div>
                        }
                        {
                            (props?.validationFor === "adminConfirmationOrder"
                                ? !validationStatus.isValidserviceType : !validationStatus.isValidServiceType) &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                Service Type is not valid
                            </div>
                        }
                        {
                            !validationStatus.isValidpreferredTime &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                Selected Available Time Window is not available. Please change Available Time Window or date of test
                            </div>
                        }

                        {
                            !validationStatus.isValidpostcode &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                Test Post Code is not valid
                            </div>
                        }
                        {
                            !validationStatus.isValidaddressLine1 &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                Test Address Line 1 is not valid
                            </div>
                        }
                        {
                            !validationStatus.isValidcity &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                Test City is not valid
                            </div>
                        }
                        {
                            (props?.validationFor !== "adminConfirmationOrder" && !validationStatus.isValidCountry) &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                Test Country is not valid in uploaded file
                            </div>
                        }
                        {
                            !validationStatus.isValidtelephone &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                Telephone is not valid
                            </div>
                        }
                        {
                            (props?.validationFor !== "adminConfirmationOrder" && !validationStatus.isValidHomeAddressCountry) &&
                            <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                Home Country is not valid in uploaded file
                            </div>
                        }
                        {
                            props?.validationFor !== "adminConfirmationOrder" &&
                            <>
                                {
                                    !validationStatus.isValidDOB &&
                                    <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                        Date of Birth is not valid in uploaded file
                                    </div>
                                }
                                {
                                    !validationStatus.isValidgender &&
                                    <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                        Gender is not valid in uploaded file
                                    </div>
                                }
                                {
                                    !validationStatus.isValiddocumentExpiration &&
                                    <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                        Document Expiration is not valid in uploaded file
                                    </div>
                                }
                                {
                                    !validationStatus.isValidDocumentCountry &&
                                    <div className="d-flex p-2 text-danger pl-5 font-weight-bold justify-content-start align-items-center ">
                                        Document Country is not validin uploaded file
                                    </div>
                                }
                            </>
                        }
                    </div>
                }
            </ContentDialog>
        </div>
    )
}
export default InfoValidationDialog