import React from 'react'
import { Column, Body, Spacer, Button } from '../../components'

const LinkSent = ({ email, resetWorkflow }) => {
	const handleOnClick = () => {
		resetWorkflow(0)
	}
	return (
		<>
			<Column desktopSize={6} tabletSize={6} spacingTop="m">
				<h1>You’ve been sent a link</h1>
				<Body>We’ve sent an email to <strong>{email}</strong> with the link. Please check your email and click the link to log in</Body>
				<br />
				<Body>It may take up to few minutes to arrive. Please be patient.</Body>
				<Spacer spacing="s" />
				<Button fitted onClick={handleOnClick}>
					Back
				</Button>
			</Column>
		</>
	)
}

export { LinkSent }
