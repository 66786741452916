import { regex } from 'components/common/util/regex'
import moment from 'moment';
import { object } from 'prop-types';
import { COUNTRIES_LIST } from "../../../components/common/util/util";


export function isValidEvent(data) {
    const isValidEventName = data.eventName !== undefined && data.eventName && data.eventName.trim() !== "" ? true : false;
    let allValid = true;
    allValid = isValidEventName
    return {
        isValidEventName: isValidEventName,
        allValid: allValid
    }
}



export function isValidExcelPatientDetail(data) {

    console.log(data, "excel")

    const checkValidCountry = (country) => {
        if (country) {
            if (country instanceof Object && country !== undefined && country?.value && country?.value.trim() !== "") {
                return true
            }
            else {
                let countryArr = COUNTRIES_LIST?.filter((item) => item?.name === country || item?.value === country)
                if (countryArr.length > 0) {
                    return true
                }
                else {
                    return false
                }
            }
        }
    }

    let isValidDOB = true;
    let isValiddocumentExpiration = true;
    let isValidgender = true;
    let isValidHomeAddressCountry = true;
    let isValidDocumentCountry = true;

    // console.log(data.documentExpiration !== undefined, data.documentExpiration, data.documentExpiration !== "", data.documentExpiration?.toString().toLowerCase() !== "invalid date", regex.date.test(moment(data.documentExpiration).format('DD/MM/YYYY')), "check")

    //------------------Dates
    const isValiddateOfTest = data.dateOfTest !== undefined && data.dateOfTest && data.dateOfTest !== "" && data.dateOfTest?.toString().toLowerCase() !== "invalid date" && regex.date.test(moment(data.dateOfTest).format('DD/MM/YYYY')) && new Date(data.dateOfTest.toString()).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
    if (data.DOB)
        isValidDOB = data.DOB !== undefined && data.DOB && data.DOB !== "" && data.DOB?.toString().toLowerCase() !== "invalid date" && regex.date.test(moment(data.DOB).format('DD/MM/YYYY'));

    if (data.documentExpiration)
        isValiddocumentExpiration = data.documentExpiration !== undefined && data.documentExpiration && data.documentExpiration !== "" && data.documentExpiration?.toString().toLowerCase() !== "invalid date" && regex.date.test(moment(data.documentExpiration).format('DD/MM/YYYY'));

    //-----------------Country
    const isValidCountry = checkValidCountry(data.country);
    if (data.homeAddressCountry)
        isValidHomeAddressCountry = checkValidCountry(data.homeAddressCountry)

    if (data.isValidDocumentCountry)
        isValidDocumentCountry = checkValidCountry(data.documentCountry)

    const isValidGivenName = data.givenName !== undefined && data.givenName && data.givenName.trim() !== "" && data.givenName.length > 1;
    const isValidFamilyName = data.familyName !== undefined && data.familyName && data.familyName.trim() !== "" && data.familyName.length > 1;
    const isValidemail = regex.email.test(data.email) && data.email && data.email.trim() !== ""
    const isValidpreferredTime = data.preferredTime !== undefined && data.preferredTime && data.preferredTime.value.trim() !== "";
    // const isValidpreferredTime = checkValidPreferTime(data) ? true : false
    const isValidServiceType = data.serviceType !== undefined && data.serviceType !== null && data.serviceType !== ""
    const isValidaddressLine1 = data.addressLine1 !== undefined && data.addressLine1 && data.addressLine1.trim() !== "";
    const isValidpostcode = data.postcode !== undefined && data.postcode && data.postcode.trim() !== "";
    const isValidcity = data.city !== undefined && data.city && data.city.trim() !== "";
    const isValidHomeAddressLine1 = data.homeAddressLine1 !== undefined && data.homeAddressLine1 && data.homeAddressLine1.trim() !== "";
    const isValidtelephone = data.telephone !== undefined && data.telephone && data.telephone.trim() !== "";

    if (data.gender)
        isValidgender = data.gender !== undefined && data.gender && data.gender.value.trim() !== "";


    const allValid = isValidemail && isValidGivenName && isValidFamilyName && isValiddateOfTest && isValidServiceType && isValidpreferredTime && isValidtelephone && isValidaddressLine1 && isValidpostcode && isValidcity && isValidCountry && isValidDOB && isValiddocumentExpiration && isValidgender && isValidHomeAddressCountry && isValidDocumentCountry


    return {
        isValidemail: isValidemail,
        isValiddateOfTest: isValiddateOfTest,
        isValidServiceType: isValidServiceType,
        isValidpreferredTime: isValidpreferredTime,
        // isValidfullName: isValidfullName,
        isValidtelephone: isValidtelephone,
        isValidaddressLine1: isValidaddressLine1,
        isValidHomeAddressLine1: isValidHomeAddressLine1,
        isValidpostcode: isValidpostcode,
        isValidcity: isValidcity,
        isValidCountry: isValidCountry,
        isValidDOB: isValidDOB,
        isValidgender: isValidgender,
        isValiddocumentExpiration: isValiddocumentExpiration,
        isValidHomeAddressCountry: isValidHomeAddressCountry,
        isValidDocumentCountry: isValidDocumentCountry,
        isValidGivenName: isValidGivenName,
        isValidFamilyName: isValidFamilyName,
        allValid: allValid
    }
}

// export function isValidExcelPatientDetail(data) {
//     const dateOnTest = data?.dateOfTest;
//     const todayDate = moment();
//     const validDate = todayDate.diff(dateOnTest, 'days');

//     console.log('validDate', validDate)

//     const isValidemail = regex.email.test(data.email) && data.email && data.email.trim() !== ""
//     const isValiddateOfTest = (validDate === 0 || validDate < 0) && data.dateOfTest && data.dateOfTest
//     let isValidDOB = true;
//     let isValiddocumentExpiration = true;
//     let isValidgender = true;

//     if (data.DOB)
//         isValidDOB = regex.date.test(moment(data.DOB).format('DD/MM/YYYY')) && data.DOB !== undefined && data.DOB && data.DOB !== "";

//     if (data.documentExpiration)
//         isValiddocumentExpiration = regex.date.test(moment(data.documentExpiration).format('DD/MM/YYYY')) && data.documentExpiration !== undefined && data.documentExpiration && data.documentExpiration !== "";

//     if (data.gender)
//         isValidgender = data.gender !== undefined && data.gender && data.gender.value.trim() !== "";

//     const isValidpreferredTime = data.preferredTime !== undefined && data.preferredTime && data.preferredTime.value.trim() !== "";
//     const isValidServiceType = data.serviceType !== undefined && data.serviceType.value && data.serviceType.value.trim() !== "";
//     const isValidfullName = data.fullName !== undefined && data.fullName && data.fullName.trim() !== "";
//     // const isValidfamilyName= data.familyName !== undefined && data.familyName && data.familyName.trim() !== "";
//     // const isValidgivenName = data.givenName !== undefined && data.givenName && data.givenName.trim() !== "";
//     // const isValidDOB = data.DOB !== undefined && data.DOB && data.DOB !== "";
//     const isValidtelephone = data.telephone !== undefined && data.telephone && data.telephone.trim() !== "";
//     const isValidserviceType = data.serviceType !== undefined && data.serviceType.value && data.serviceType.value.trim() !== "";
//     // const isValidgender = data.gender !== undefined && data.gender && data.gender.value && data.gender.value.trim() !== "";
//     // const isValidaddressLine1 = data.addressLine1 !== undefined && data.addressLine1 && data.addressLine1.trim() !== "";
//     // const isValidpostcode = data.postcode !== undefined && data.postcode && data.postcode.trim() !== "";
//     // const isValidcity = data.city !== undefined && data.city && data.city.trim() !== "";
//     // const isValidnationality = data.nationality !== undefined && data.nationality && data.nationality.trim() !== "";
//     // const isValiddocumentType = data.documentType !== undefined && data.documentType && data.documentType.trim() !== "";
//     // const isValidcountry = data.country !== undefined && data.country && data.country.value && data.country.value.trim() !== "";
//     // const isValiddocumentCountry = data.documentCountry !== undefined && data.documentCountry && data.documentCountry.value && data.documentCountry.value.trim() !== "";
//     // const isValiddocumentNumber = data.documentNumber !== undefined && data.documentNumber && data.documentNumber.trim() !== "";
//     // const isValiddocumentAuthority = data.documentAuthority !== undefined && data.documentAuthority && data.documentAuthority.trim() !== "";
//     // const isValiddocumentExpiration = data.documentExpiration !== undefined && data.documentExpiration && data.documentExpiration !== "";
//     let allValid = true;
//     allValid = isValidemail && isValidserviceType && isValidfullName && isValiddateOfTest && isValidpreferredTime &&
//         isValidDOB && isValidServiceType && isValiddocumentExpiration && isValidgender && isValidtelephone;

//     //  && isValidDOB &&
//     // isValidfullName && isValidfamilyName &&  isValidgivenName
//     // && isValidtelephone && isValidgender && isValidaddressLine1 && isValidpostcode && isValidcity &&
//     // isValidnationality && isValiddocumentType && isValiddocumentCountry && isValiddocumentNumber && 
//     // isValiddocumentAuthority && isValiddocumentExpiration && isValidcountry 

//     return {
//         isValidemail: isValidemail,
//         isValiddateOfTest: isValiddateOfTest,
//         isValidpreferredTime: isValidpreferredTime,
//         isValidfullName: isValidfullName,
//         isValidserviceType: isValidserviceType,
//         isValidServiceType: isValidServiceType,
//         // isValidfamilyName: isValidfamilyName,
//         // isValidgivenName: isValidgivenName,
//         isValidDOB: isValidDOB,
//         // isValidcountry: isValidcountry,
//         isValidtelephone: isValidtelephone,
//         isValidgender: isValidgender,
//         // isValidaddressLine1: isValidaddressLine1,
//         // isValidpostcode: isValidpostcode,
//         // isValidcity: isValidcity,
//         // isValidnationality: isValidnationality,
//         // isValiddocumentType: isValiddocumentType,
//         // isValiddocumentCountry: isValiddocumentCountry,
//         // isValiddocumentNumber: isValiddocumentNumber,
//         // isValiddocumentAuthority: isValiddocumentAuthority,
//         isValiddocumentExpiration: isValiddocumentExpiration,
//         allValid: allValid
//     }
// }