export const regex = {
    wholeNumber: /^\d+$/,
    decimalNumber: /^\d*\.\d+$/,
    wholeNumberAndDecimalNumber: /^\d*(\.\d+)?$/,
    alphanumericSpecifiedSpecialCharaters : /^[ A-Z0-9 @,_.]*$/i,
    alphanumericWithOutSpaceTextField: /^[a-zA-Z0-9]{1,999}$/,
    alphanumericWithOutSpace: /^[a-zA-Z0-9]{1,16}$/,
    alphanumericWithSpace: /^[a-z\d\-_\s]+$/i,
    date: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
     // /^\d*[a-zA-Z][a-zA-Z0-9]*$/
    email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    isNotEmpty: /^\s*\S+.*/,
    cnicNumber: /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/
  };