import React from 'react'
import PropTypes from 'prop-types'
import { useBreakpoint } from '../utils/breakpoints'

const resolveSrc = (isMobile, isTablet, isDesktop, props) => {
	const { mobileSrc, tabletSrc, desktopSrc, src } = props
	if (isMobile && mobileSrc) {
		return mobileSrc
	} else if (isTablet && tabletSrc) {
		return tabletSrc
	} else if (isDesktop && desktopSrc) {
		return desktopSrc
	} else {
		return src
	}
}

const Image = (props) => {
	const { isMobile, isTablet, isDesktop } = useBreakpoint()
	const { className, alt, width } = props

	const imageSource = resolveSrc(isMobile, isTablet, isDesktop, props)

	return <img src={imageSource} className={className} alt={alt} width={width} />
}

Image.propTypes = {
	mobileSrc: PropTypes.string,
	tabletSrc: PropTypes.string,
	desktopSrc: PropTypes.string,
	src: PropTypes.string,
	className: PropTypes.string,
	alt: PropTypes.string.isRequired,
}

export { Image }
