import './material.autocomplete.scss'
import React, { useState, useRef, useEffect } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import InputBase from '@material-ui/core/InputBase';
import { useStyles } from './material.autocomplete.style';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { CircularProgress, Popper, ClickAwayListener, Icon, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar'

export default function MaterialAutoComplete(props) {
    const { data, placeholder, signUp, execStatus, minWidth, required, error, dropdownData, selectedItem, endAdornment, placement, startAdornment, disabled, showSelectedValue, showAccountNumberMask } = props;
    const autoCompleteRef = useRef()
    const [dropdownValue, setDropdownValue] = useState(false);
    let selectedItm;
    let dropdownDt;
    if (selectedItem)
        selectedItm = (data) && data.find((item) => item.value === selectedItem.value);

    if (dropdownData)
        dropdownDt = (data) && data.find((item) => item.value === dropdownData.value);

    const classes = useStyles()
    const [state, setState] = useState({
        anchorELe: false,
        autoCompleteWidth: false
    })
    useEffect(() => {
        if (autoCompleteRef && autoCompleteRef.current && autoCompleteRef.current !== null && autoCompleteRef.current.offsetWidth !== null) {
            setTimeout(() => {
                if (autoCompleteRef && autoCompleteRef.current && autoCompleteRef.current !== null && autoCompleteRef.current.offsetWidth !== null) {
                    setState({ ...state, autoCompleteWidth: autoCompleteRef.current.offsetWidth })
                }
            }, 200)
        }
    }, [autoCompleteRef, execStatus])
    const onOpen = (event) => {
        if (props.disabled === true) {

        }
        else {
            if (state.anchorELe) {
                setState({ ...state, anchorELe: false })
            }
            else
                setState({ ...state, anchorELe: event.currentTarget })
        }
    }

    const closePopper = () => {
        setState({ ...state, anchorELe: false })
    }

    const open = Boolean(state.anchorELe)

    const onMobileChange = (e, data) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.disabled === true) {

        }
        else {
            if (props.onSelect) {
                props.onSelect && props.onSelect(data ? data.value : null, { ...data });
            }
            else {
                props.onDefaultSelect && props.onDefaultSelect({ ...data });
                setDropdownValue(data)
            }
            setState({ ...state, anchorELe: false })
        }
    }
    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'material-autocomplete',
        options: data,
        open: open,
        openOnFocus: true,
        value: dropdownValue ? dropdownValue.value : selectedItem && selectedItem.value,
        onChange: (event, data, detail) => {
            // if (props.disabled === true) {

            // }
            // else {
            //     props.onSelect(data ? data.value : null, { ...data });
            // }
        },
        onClose: (event) => {
            setState({ ...state, open: false })
        },
        onOpen: (event) => {

            if (props.disabled === true) {

            }
            else
                setState({ ...state, open: true })
        },
        getOptionLabel: (option) => {
            return option.name
        }
    })
    useEffect(() => {
        setDropdownValue(dropdownDt)
    }, [])
    return (
        <React.Fragment>
            <div style={{
                width: '100%',
                minWidth: '150px'
            }}>
                {
                    startAdornment ?
                        <div className={`pt-2 pb-2 d-flex ${signUp ? '' : 'font-weight-bold'}`}>
                            <div style={{
                                width: 'max-content'
                            }}>
                                {
                                    required === true ?
                                        <div className="text-danger">*</div> : null
                                }
                            </div>
                            <div style={{
                                width: 'max-content'
                            }}>
                                {startAdornment}
                            </div>

                        </div>
                        : null
                }
                <div style={{
                    width: '100%',
                    minWidth: minWidth ? minWidth : 'auto'
                }} >
                    {
                        execStatus && execStatus.status === "PENDING" && !data ?
                            <Paper className="paper-root">
                                <div className="d-flex justify-content-center p-1">
                                    <CircularProgress className="p-2" size={30} />
                                </div>
                            </Paper> : null
                    }
                    {execStatus && execStatus.status === "ERROR" ? (
                        <Paper className="paper-root">
                            <div className="text-danger d-flex justify-content-center font-italic pt-2 pb-2">
                                <b>
                                    {" "}
                                    {execStatus.errorDetail && typeof execStatus.errorDetail === 'object'
                                        ? execStatus.errorDetail.get("description")
                                        : "Something isn’t working as it should, please try again"}
                                </b>
                            </div>
                        </Paper>
                    ) : null}
                    {
                        data && data.length > 0 ?
                            <ClickAwayListener onClickAway={closePopper}>
                                <div className="w-100 d-flex justify-content-center align-items-center position-relative"
                                    {...getRootProps()}>
                                    <Paper
                                        id="paper-autocomplete"
                                        onClick={onOpen}
                                        ref={autoCompleteRef}
                                        component="div"
                                        className={clsx("d-flex align-items-center cursor-pointer paper-root",
                                            state.open && classes.borderRadiusPartial,
                                            !state.open && classes.borderRadiusFull,
                                            classes.paperRoot,
                                        )}>
                                        {
                                            selectedItm && selectedItm.img ?
                                                <Paper className="svg-logo" elevation={3}>
                                                    <img
                                                        className="svg-logo"
                                                        src={`${process.env.PUBLIC_URL}/assets/images/${selectedItm.img}`}

                                                    /></Paper> : null
                                        }
                                        {
                                            selectedItm && selectedItm.svgLogo ?
                                                <Paper className={clsx("svg-logo", !startAdornment && "ml-1", startAdornment && "ml-2")} elevation={3}>
                                                    <Avatar
                                                        className="svg-logo"
                                                        alt={""}
                                                        src={selectedItm.svgLogo}
                                                    /></Paper> : null
                                        }
                                        <InputBase
                                            {...getInputProps()}
                                            disabled={true}
                                            placeholder={placeholder}
                                            onFocus={onOpen}
                                            onBlur={closePopper}
                                            value={dropdownValue ? dropdownValue.name : selectedItm && selectedItm.name ? selectedItm.name : ""}
                                            inputProps={{
                                                className: 'pb-0 pt-0 text-ellipsis-clamp-1',

                                            }}
                                            className={clsx(`flex-grow-1 ${classes.input}`, !startAdornment && !selectedItem && "pl-2", startAdornment && !selectedItem && "pl-2", selectedItem && "pl-2")}
                                        />
                                        {

                                            !disabled ?
                                                selectedItem && selectedItem.value && data && data.length === 1 ?
                                                    null :
                                                    <Icon className="pr-2" color="secondary">
                                                        {
                                                            placement && placement.indexOf("right") >= 0 ?
                                                                <React.Fragment>

                                                                    {
                                                                        open ?
                                                                            <span class="material-icons">
                                                                                keyboard_arrow_left</span> :
                                                                            <span class="material-icons">
                                                                                keyboard_arrow_right</span>
                                                                    }
                                                                </React.Fragment> :
                                                                <React.Fragment>
                                                                    {
                                                                        open ?
                                                                            <span class="material-icons">
                                                                                keyboard_arrow_up</span> :
                                                                            <span class="material-icons">
                                                                                keyboard_arrow_down</span>
                                                                    }
                                                                </React.Fragment>

                                                        }
                                                    </Icon> : null
                                        }

                                    </Paper>

                                    {data && data.length > 0 ? (
                                        <Popper open={open} anchorEl={state.anchorELe} placement={placement ? placement : "bottom"} className={clsx(classes.listbox, "paper-root")} style={{
                                            width: state.autoCompleteWidth
                                        }} {...getListboxProps()}>
                                            <Paper className="paper-root" elevation={1}>
                                                {data.length > 0 && data.map((option, index) => (
                                                    <React.Fragment>
                                                        <li
                                                            className={clsx(classes.listItem, selectedItm && selectedItm.value === option.value && classes.selectedListItem)}
                                                            {...getOptionProps({ option, index })}
                                                            onClick={(e) => onMobileChange(e, option)}
                                                        //onTouchEndCapture={(e) => onMobileChange(e, option)}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                {
                                                                    option.img ?
                                                                        <Paper className="svg-logo " elevation={3}>
                                                                            <img
                                                                                src={`${process.env.PUBLIC_URL}/assets/images/${option.img}`}
                                                                                className="svg-logo"
                                                                            /></Paper> : null
                                                                }
                                                                {
                                                                    option.svgLogo ?
                                                                        <Paper className="svg-logo" elevation={3}>

                                                                            <Avatar
                                                                                alt={""}
                                                                                className="svg-logo"
                                                                                src={option.svgLogo}
                                                                            /></Paper> : null
                                                                }
                                                                <div className="w-100 d-flex justify-content-between align-items-center">
                                                                    <div className="pl-1 d-flex flex-column w-100 pr-2">
                                                                        <div>
                                                                            <Typography><b>{option.name}</b></Typography>
                                                                        </div>

                                                                        <div>
                                                                            <Typography>{option.description1}</Typography>
                                                                        </div>
                                                                        <div>
                                                                            <Typography>{option.description2}</Typography>
                                                                        </div>

                                                                    </div>
                                                                    {
                                                                        selectedItm && selectedItm.value == option.value ?
                                                                            <div>
                                                                                <Icon>
                                                                                    <i className="fa fa-check text-success"></i>
                                                                                </Icon>
                                                                            </div> : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </li>
                                                    </React.Fragment>
                                                ))}
                                            </Paper>
                                        </Popper>
                                    ) : null}
                                </div>
                            </ClickAwayListener> : execStatus && execStatus.status !== "PENDING" &&
                                execStatus.status !== "ERROR" ? <Paper>
                                <div className=" text-muted d-flex justify-content-center p-2">
                                    <Typography>No Data Found</Typography>
                                </div>
                            </Paper> : null
                    }
                </div>
                {
                    error ?
                        <div className="text-danger 
                    d-flex justify-content-start p-1">
                            <Typography> {error}</Typography>
                        </div> : null
                }
            </div>
        </React.Fragment>

    )
}
//name , value , img