import React, { useState, useEffect } from 'react'
import { Row, Column } from '../../components'
import { useLocation, useHistory } from 'react-router-dom'
import { useWorkflow } from '../../utils'
import { RequestSecretCode } from './RequestSecretCode'
import { LinkSent } from './LinkSent'
import { TestResults } from './TestResults'
import { TestResult } from './TestResult'

const parseEmailAndCode = (searchString) => {
	const params = new URLSearchParams(searchString)

	const unsafeEmail = params.get('id')
	const unsafeCode = params.get('code')

	if (unsafeEmail && unsafeCode) {
		const sanitizedEmail = unsafeEmail.split('___').join('@').toLowerCase()
		const sanitizedCode = unsafeCode.toLowerCase()
		return { isIdAndCodeProvided: true, email: sanitizedEmail, code: sanitizedCode }
	} else {
		return { isIdAndCodeProvided: false }
	}
}

const parseShouldStartAgain = (searchString) => {
	const params = new URLSearchParams(searchString)

	const unsafeShouldStartAgain = params.get('shouldStartAgain')

	if (unsafeShouldStartAgain) {
		return { shouldStartAgain: unsafeShouldStartAgain === 'true' }
	} else {
		return { shouldStartAgain: false }
	}
}

const RetrieveTestResultsPage = () => {
	const { search } = useLocation()
	const { isIdAndCodeProvided, email, code } = parseEmailAndCode(search)
	const { shouldStartAgain } = parseShouldStartAgain(search)
	const history = useHistory()

	const workflowSteps = ['RequestSecretCode', 'LinkSent', 'TestResults', 'TestResult']
	const [workflowStep, toRequestSecretCode, toLinkSent, toTestResults, toTestResult, resetWorkflow] = useWorkflow(
		workflowSteps,
		isIdAndCodeProvided ? 2 : 0
	)

	useEffect(() => {
		if (shouldStartAgain === true) {
			resetWorkflow()
			history.push('/test-results?shouldStartAgain=no')
		}

	}, [shouldStartAgain])

	const [enteredEmail, setEnteredEmail] = useState('')
	const [testResults, setTestResults] = useState([])
	const [selectedTestResult, setSelectedTestResult] = useState(null)

	const handleSecretCodeRequest = ({ email }) => {
		setEnteredEmail(email)
		toLinkSent();
	}

	const handleTestResults = ({ testResults }) => {
		setTestResults(testResults)
	}

	const handleTestResultSelect = ({ id }) => {
		setSelectedTestResult(testResults.find((testResult) => testResult.id === id))
		toTestResult()
	}

	if (workflowStep === 'TestResult') {
		return <TestResult testResult={selectedTestResult} onBackClick={()=>{resetWorkflow(2)}} />
	} else {
		return (
			<>
				<Row>
					<Column desktopSize={3} tabletSize={3} mobileSize={1} spacingTop="m" />
					{workflowStep === 'RequestSecretCode' && (
						<RequestSecretCode onSecretCode={handleSecretCodeRequest} />
					)}
					{workflowStep === 'LinkSent' && <LinkSent email={enteredEmail} resetWorkflow={resetWorkflow}/>}
					{workflowStep === 'TestResults' && (
						<TestResults
							email={email}
							code={code}
							cachedTestResults={testResults}
							onTestResults={handleTestResults}
							onTestResultSelect={handleTestResultSelect}
						/>
					)}
					<Column desktopSize={3} tabletSize={3} mobileSize={1} spacingTop="m" />
				</Row>
			</>
		)
	}
}

export { RetrieveTestResultsPage }
