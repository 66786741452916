import React, {useState} from 'react'
import {Row, Column, Body, Spacer, TextInput} from '../../components'
import { BeaconForm, FormTextInput, SubmitButton } from '../../components/forms'
import {EyePassword} from "./common";

export const PasswordChangeForm = ({ onPasswordSubmit }) => {
	const [eye1, setEye1] = useState(true);
	const [eye2, setEye2] = useState(true);


	return (
		<Row>
			<Column desktopSize={4} tabletSize={3} spacingTop="m" />
			<Column desktopSize={4} tabletSize={6} spacingTop="m">
				<h1>Change your password</h1>
				<Column desktopSize={4} tabletSize={6} spacingTop="m">
					<BeaconForm onSubmit={onPasswordSubmit}>
						<Body centered>Your new password should be at least 8 characters long</Body>
						<Spacer spacing="m" />
						<div className="position-relative">
						<FormTextInput
							label="New password"
							name="password"
							type={eye1 ? 'password' : 'text'}
							pattern={/(?=.*[a-zA-Z\d]).{8,}/}
							errorMessage="Password is not complex enough"
							placeholder="For example: lanky-auburn-53-bowtie"
							desktopSize={4}
							tabletSize={6}
							labelAdendum=""
						/>
							<EyePassword
								eye = {eye1}
								setEye = {(flag)=>setEye1(!flag) }
							/>
						</div>

						<div className="position-relative">

						<FormTextInput
							label="Confirm new password"
							name="confirmPassword"
							type={eye2 ? 'password' : 'text'}
							confirmationFor="password"
							errorMessage="Passwords have to match"
							placeholder="For example: lanky-auburn-53-bowtie"
							desktopSize={4}
							tabletSize={6}
							labelAdendum=""
						/>
							<EyePassword
								eye = {eye2}
								setEye = {(flag)=>setEye2(!flag) }
							/>
						</div>
						<Spacer spacing="s" />
						<SubmitButton>Change password</SubmitButton>
					</BeaconForm>
				</Column>
			</Column>
			<Column desktopSize={4} tabletSize={3} spacingTop="m" />
		</Row>
	)
}
