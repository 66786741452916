import React, { useState, useEffect } from 'react'
import { Button, Paper, Checkbox, FormControlLabel } from "@material-ui/core";

import { GENDER_LIST_SBS, IsMobileWidth } from "components/common/util/util";
import clsx from 'clsx';
import Calender from 'components/common/custom.calender/Calender';
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
import MaterialAutoComplete from 'components/common/material.autocomplete/material.autocomplete';
import PatientDetailDialog from './patient.detail.dialog';
import LoaderButton from 'components/common/loader.button/loader.button';
import { COUNTRIES_LIST } from 'components/common/util/util';
import MaterialAutoCompleteTypeable from 'components/common/material.autocomplete.typeable/material.autocomplete.typeable';
import { getPastDate } from 'components/common/util/util';
import { SERVICE_TYPES } from 'components/common/util/util';
import { PREFERRED_TIMES } from 'components/common/util/util';
import ReactTelInput from 'react-telephone-input';
import 'react-telephone-input/css/default.css';
import './postcode.css';
import { Row, Col } from "reactstrap"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { COUNTRY_CODES } from 'components/common/util/util';
import { lastWord, PostCodeDropdown, PostCodeHandle } from "../../../components/common/postcode";
import { ETHNICITY_LIST, GENDER_LIST, getReferedTimeAccordingToTodaysTime, TEST_TYPES, TRAVEL_METHOD_LIST, VACCINATION_STATUS } from '../../../components/common/util/util';
import { useAdmin } from '../../../utils';

const PatientsList = (props) => {
    const { testType, dateOfTest, preferredTime, serviceType, swabTaken, isProducerCheckFitToFly,
        postcode, addressLine1, addressLine2, isHomeAddressSameAsAboveTestAddress, city, country,
        givenName, familyName, fullName, DOB, gender, documentNumber, ethnicity, vaccinationStatus,
        email, telephone, arrivalDate, travelMethod, vesselNumber, departureDate, departureCountry,
        homeAddressPostcode, homeAddressLine1, homeAddressLine2, homeAddressCity, homeAddressCountry,
        isSelfAddressSameAsAboveTestAddress, selfIsolationAddressPostcode, selfIsolationAddressLine1, selfIsolationAddressLine2, selfIsolationAddressCity, selfIsolationAddressCountry,
        execStatusScheduleTest, producerComments } = props;

    const { getAllSbsEthnicity, getAllSbsTravelMethods, getAllSbsVaccinationStatus, getAllSbsCountries } = useAdmin();

    const [state, setState] = useState({
        showPatientDetailDialog: false
    })

    const [resValue, setResValue] = useState('');
    const [errorValue, setErrorValue] = useState([]);
    const [postcodeDropdown, setPostcodeDropdown] = useState(false);
    const [updateServiceType, setUpdateServiceType] = useState(serviceType);
    const [updatedPreferedTime, setUpdatedPreferedTime] = useState([]);
    const [homeAddressPostCodeAtr, setHomeAddressPostCodeAtr] = useState({
        inputPostValue: "",
        resValue: "",
        errorValue: [],
        postcodeDropdown: false
    })
    const [selfIsolationAddressPostCodeAtr, setSelfIsolationAddressPostCodeAtr] = useState({
        inputPostValue: "",
        resValue: "",
        errorValue: [],
        postcodeDropdown: false
    })

    const [ethnicityList, setEthnicityList] = useState(undefined);
    const [travelMethodList, setTravelMethodList] = useState(undefined);
    const [vaccinationList, setVaccinationList] = useState(undefined);
    const [sbsCountriesList, setSbsCountriesList] = useState(undefined);


    useEffect(() => {
        if (postcode !== homeAddressPostcode || addressLine1 !== homeAddressLine1 || addressLine2 != homeAddressLine2 || city !== homeAddressCity || country !== homeAddressCountry) {
            props.onChange(true, "isHomeAddressSameAsAboveTestAddress")
        }
        if (postcode !== selfIsolationAddressPostcode || addressLine1 !== selfIsolationAddressLine1 || addressLine2 != selfIsolationAddressLine2 || city !== selfIsolationAddressCity || country !== selfIsolationAddressCountry) {
            props.onChange(true, "isSelfAddressSameAsAboveTestAddress")
        }
    }, [postcode, addressLine1, addressLine2, city, country, homeAddressPostcode, homeAddressLine1, homeAddressLine2, homeAddressCity, homeAddressCountry,
        selfIsolationAddressPostcode, selfIsolationAddressLine1, selfIsolationAddressLine2, selfIsolationAddressCity, selfIsolationAddressCountry])

    const showPatientDetail = () => {
        setState({
            ...state,
            showPatientDetailDialog: true
        })
    }

    const hidePatientDetail = () => {
        setState({
            ...state,
            showPatientDetailDialog: false
        })
    }

    useEffect(() => {
        const preferredTime = getReferedTimeAccordingToTodaysTime(dateOfTest, updateServiceType?.serviceTypeText)
        setUpdatedPreferedTime(preferredTime.preferredTime)
    }, [dateOfTest, updateServiceType])

    const handlePostCodeInputClick = (event, evnetTargetName) => {

        if (evnetTargetName === "testAddressPostCode") {
            PostCodeHandle(postcode, (item) => {
                setPostcodeDropdown(item?.dropdown);
                setErrorValue(item?.errorValue);
                setResValue(item?.resValue);
            });
        }
        else if (evnetTargetName === "homeAddressPostcode") {
            PostCodeHandle(homeAddressPostcode, (item) => {
                setHomeAddressPostCodeAtr({
                    ...homeAddressPostCodeAtr,
                    postcodeDropdown: item?.dropdown,
                    errorValue: item?.errorValue,
                    resValue: item?.resValue
                })
            });
        }
        else if (evnetTargetName === "selfIsolationAddressPostcode") {
            PostCodeHandle(selfIsolationAddressPostcode, (item) => {
                setSelfIsolationAddressPostCodeAtr({
                    ...selfIsolationAddressPostCodeAtr,
                    postcodeDropdown: item?.dropdown,
                    errorValue: item?.errorValue,
                    resValue: item?.resValue
                })
            });
        }
    }

    const handlePostCodeInputChange = (event, evnetTargetName) => {
        if (evnetTargetName === "testAddressPostCode") {
            props.onChange(event.target.value, "postcode")
        }
        else if (evnetTargetName === "homeAddressPostcode") {
            props.onChange(event.target.value, "homeAddressPostcode")
        }
        else if (evnetTargetName === "selfIsolationAddressPostcode") {
            props.onChange(event.target.value, "selfIsolationAddressPostcode")
        }
    }

    const addressDetails = (item, evnetTargetName) => {
        if (evnetTargetName === "testAddressPostCode") {
            props.getSelectedAddress([
                { key: "city", value: lastWord(item?.Place) },
                { key: "addressLine1", value: item?.StreetAddress },
                { key: "addressLine2", value: item?.addressLine2 },
            ]);
            setPostcodeDropdown(false);
        }
        else if (evnetTargetName === "homeAddressPostcode") {
            props.getSelectedAddress([
                { key: "homeAddressCity", value: lastWord(item?.Place) },
                { key: "homeAddressLine1", value: item?.StreetAddress },
                { key: "homeAddressLine2", value: item?.addressLine2 },
            ]);
            setHomeAddressPostCodeAtr({
                ...homeAddressPostCodeAtr,
                postcodeDropdown: false
            })
        }
        else if (evnetTargetName === "selfIsolationAddressPostcode") {
            props.getSelectedAddress([
                { key: "selfIsolationAddressCity", value: lastWord(item?.Place) },
                { key: "selfIsolationAddressLine1", value: item?.StreetAddress },
                { key: "selfIsolationAddressLine2", value: item?.addressLine2 },
            ]);
            setSelfIsolationAddressPostCodeAtr({
                ...selfIsolationAddressPostCodeAtr,
                postcodeDropdown: false
            })
        }
    }

    const addressLine1Handle = (e) => {
        if (e.target.value.length > 0) {
            props.onChange(e.target.value, "addressLine1")
        } else {
            props.onChange('', "addressLine1")
        }
    }

    const addressLine2Handle = (e) => {
        if (e.target.value.length > 0) {
            props.onChange(e.target.value, "addressLine2")
        } else {
            props.onChange('', "addressLine2")
        }
    }

    useEffect(() => {
        getAllSbsEthnicity()
            .then((response) => {
                const updatedEthnicity = [];
                const res = response?.data?.map((item) => {
                    updatedEthnicity.push({ name: item?.name, value: item?.pheCode })
                })
                setEthnicityList(updatedEthnicity)
            })
            .catch((error) => { })

        getAllSbsTravelMethods()
            .then((response) => {
                console.log('Response travel method BE', response);

                const updatedTravelMethod = [];
                const res = response?.data?.map((item) => {
                    updatedTravelMethod.push({ name: item?.name, value: item?.travelMethodId.toString() })
                })
                setTravelMethodList(updatedTravelMethod)
            })
            .catch((error) => { })

        getAllSbsVaccinationStatus()
            .then((response) => {
                console.log('response vacc BE', response);
                const updatedVaccination = [];
                const res = response?.data?.map((item) => {
                    updatedVaccination.push({ name: item?.name, value: item?.vaccinationStatusId.toString() })
                })
                setVaccinationList(updatedVaccination)
            })
            .catch((error) => { })

        getAllSbsCountries()
            .then((response) => {
                const updatedCountries = [];
                const res = response?.data?.map((item) => {
                    updatedCountries.push({ name: item?.name, value: item?.iso3Code })
                })
                setSbsCountriesList(updatedCountries)
            })
            .catch((error) => { })

    }, []);

    const mobileWidth = IsMobileWidth();
    let contryList = COUNTRY_CODES.map((cnt) => {
        return { name: cnt[0], iso2: cnt[1], dialCode: cnt[2], priority: 0, format: cnt[3] }
    })

    return (
        <div className="w-100" id="kit-list">
            <div className="w-100 d-flex justify-content-start">
                {
                    <div className="d-flex w-100 mt-3" style={{ maxWidth: "1200px" }}>
                        <Paper className={clsx("paper-root flex-column d-flex p-4 border-radius-10")} elevation={1}>

                            <div className="py-5 mb-2" style={{ borderBottom: "1px solid gray" }}>
                                <div className="mb-3">
                                    <span className="font-weight-bold" style={{ fontSize: "1.75rem" }}>Test Details</span>
                                </div>
                                <Row className="w-100">

                                    <Col md="4" className="p-3">
                                        <Calender label="Date of Test"
                                            value={dateOfTest}
                                            required={true}
                                            placeholder="Please enter desired test date"
                                            minDate={getPastDate(0)}
                                            isDayRequired
                                            // onAccept={(date) => props.onChange(date, "dateOfTest")}
                                            getSelectedDate={(date) => { console.log(date, "Got Date"); props.onChange(date, "dateOfTest") }}
                                        />
                                    </Col>
                                    {
                                        <Col md="4" className="p-3">
                                            <MaterialAutoComplete
                                                startAdornment="Service Type"
                                                selectedItem={updateServiceType ? updateServiceType : 'Please choose service type'}
                                                required={true}
                                                //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                                onSelect={(value, data) => {
                                                    props.onSelect(value, data, "serviceType");
                                                    setUpdateServiceType(data)
                                                }
                                                }
                                                placeholder="Please choose service type"
                                                data={SERVICE_TYPES}
                                            />
                                        </Col>
                                    }
                                    {
                                        (serviceType.value === "SAME_DAY" || serviceType.value === "NEXT_DAY") &&
                                        <Col md="4" className="p-3">
                                            {updateServiceType &&
                                                <>
                                                    <MaterialAutoComplete
                                                        startAdornment="Available Time Window"
                                                        selectedItem={preferredTime}
                                                        required={true}
                                                        //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                                        placeholder="Select Preferred Time"
                                                        onSelect={(value, data) => props.onSelect(value, data, "preferredTime")}
                                                        data={updatedPreferedTime}
                                                    />
                                                    {
                                                        updatedPreferedTime.length === 0 &&
                                                        <span className="mt-1" style={{ color: "red" }}>There is no available window to select so please change the test date </span>
                                                    }
                                                </>
                                            }
                                        </Col>
                                    }

                                </Row>
                                <Row className="w-100">
                                    <Col md="12" className="p-3">
                                        <FormControlLabel
                                            style={{ width: "max-content" }}
                                            className="checkbox-size d-flex"
                                            control={
                                                <Checkbox
                                                    size='medium'
                                                    checked={isProducerCheckFitToFly}
                                                    onChange={(e) => props.onChange(isProducerCheckFitToFly, "isProducerCheckFitToFly")}
                                                    color="secondary"
                                                    name=""
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            }
                                            label="Please select this option if the test is for travel." />
                                    </Col>
                                    <Col md="4" className="p-3">
                                        <div className="position-relative">
                                            <SiteLabelTextField label="Post Code"
                                                value={postcode}
                                                required={true}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { handlePostCodeInputClick(e, "testAddressPostCode") } }}
                                                onChange={(e) => { handlePostCodeInputChange(e, "testAddressPostCode") }}
                                                // onChange={(e) => props.onChange(e.target.value, "postcode")}
                                                placeholder="Please enter postcode" />
                                            <div className="position-absolute "
                                                onClick={(e) => { handlePostCodeInputClick(e, "testAddressPostCode") }}
                                                style={{ top: '36px', right: '15px', display: 'flex', alignItems: 'center' }}>
                                                {postcodeDropdown &&
                                                    <i onClick={(e) => { e.stopPropagation(); setPostcodeDropdown(false); }}
                                                        className="fas fa-times-circle"
                                                        style={{ marginRight: '10px', color: 'darkred', fontSize: '14px', cursor: 'pointer' }}
                                                    >
                                                    </i>

                                                }
                                                <button type="button" className="btn btn-primary">Search</button>
                                            </div>

                                            {postcodeDropdown &&
                                                <PostCodeDropdown
                                                    absoluteLeft={false}
                                                    errorValue={errorValue}
                                                    resValue={resValue}
                                                    addressDetails={(e) => addressDetails(e, "testAddressPostCode")}
                                                />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="w-100">
                                    <Col md="4" className="p-3">
                                        <SiteLabelTextField label="Test address line 1:"
                                            value={addressLine1}
                                            required={true}
                                            onChange={(e) => addressLine1Handle(e)}
                                            placeholder="Please enter test address" />
                                    </Col>
                                    <Col md="4" className="p-3">

                                        <SiteLabelTextField label="Test Address Line 2"
                                            value={addressLine2}
                                            onChange={(e) => addressLine2Handle(e)}
                                            placeholder="Please enter test address" />
                                    </Col>
                                    <Col md="4" className="p-3">
                                        <SiteLabelTextField label="Test City"
                                            value={city}
                                            required={true}
                                            onChange={(e) => props.onChange(e.target.value, "city")}
                                            placeholder="Please enter test city" />
                                    </Col>
                                    <Col md="4" className="p-3">
                                        <MaterialAutoCompleteTypeable
                                            startAdornment="Test Country"
                                            required={true}
                                            selectedItem={country}
                                            onSelect={(value, data) => props.onSelect(value, data, "country")}
                                            placeholder="Please Select your test country"
                                            data={COUNTRIES_LIST}
                                        />
                                    </Col>
                                </Row>

                            </div>
                            <div className="w-100 py-3 mt-2">
                                <div className="mb-3">
                                    <span className="font-weight-bold" style={{ fontSize: "1.75rem" }}>Individual Personal Details</span>
                                </div>
                                <Row className="w-100">
                                    <Col md="4" className="p-3">
                                        <SiteLabelTextField
                                            label="First Name"
                                            required={true}
                                            value={givenName} onChange={(e) => props.onChange(e.target.value, "givenName")} placeholder="Please enter first name" />
                                    </Col>
                                    <Col md="4" className="p-3">
                                        <SiteLabelTextField
                                            label="Last Name"
                                            required={true}
                                            value={familyName} onChange={(e) => props.onChange(e.target.value, "familyName")} placeholder="Please enter last name" />
                                    </Col>
                                    {
                                        (serviceType.value === "SAME_DAY" || serviceType.value === "NEXT_DAY") &&
                                        <Col md="4" className="p-3">
                                            <SiteLabelTextField
                                                label="Full Name"
                                                required={true}
                                                disabled
                                                value={fullName} onChange={() => { }} placeholder="Full Name" />
                                        </Col>
                                    }

                                    {
                                        serviceType.value === "PCR_DAY_2" &&
                                        <Col md="4" className="p-3">
                                            <Calender
                                                label="Date of Birth"
                                                value={DOB}
                                                placeholder="Select Date of Birth"
                                                getSelectedDate={(date) => { props.onChange(date, "DOB") }}
                                            />
                                        </Col>
                                    }

                                    {
                                        serviceType.value === "PCR_DAY_2" &&
                                        <Col md="4" className="p-3">
                                            <MaterialAutoComplete
                                                required={true}
                                                startAdornment="Gender"
                                                selectedItem={gender}
                                                onSelect={(value, data) => props.onSelect(value, data, "gender")}
                                                placeholder="Select gender"
                                                data={GENDER_LIST_SBS}
                                            />
                                        </Col>
                                    }
                                    {
                                        serviceType.value === "PCR_DAY_2" &&
                                        <Col md="4" className="p-3">
                                            <SiteLabelTextField
                                                label="Passport/ID Number"
                                                required={true}
                                                value={documentNumber} onChange={(e) => props.onChange(e.target.value, "documentNumber")} placeholder="Passport/ID Number" />
                                        </Col>
                                    }

                                    {
                                        (serviceType.value === "PCR_DAY_2" && ethnicityList !== undefined) &&
                                        <Col md="4" className="p-3">
                                            <MaterialAutoComplete
                                                required={true}
                                                startAdornment="Ethnicity"
                                                selectedItem={ethnicity}
                                                onSelect={(value, data) => props.onSelect(value, data, "ethnicity")}
                                                placeholder="Select Ethnicity"
                                                data={ethnicityList}
                                            />
                                        </Col>
                                    }

                                    {
                                        (serviceType.value === "PCR_DAY_2" && vaccinationList !== undefined) &&
                                        <Col md="4" className="p-3">
                                            <MaterialAutoComplete
                                                required={true}
                                                startAdornment="Vaccination Status"
                                                selectedItem={vaccinationStatus}
                                                onSelect={(value, data) => props.onSelect(value, data, "vaccinationStatus")}
                                                placeholder="Select Vaccination Status"
                                                data={vaccinationList}
                                            />
                                        </Col>
                                    }
                                </Row>
                                <div className="mt-4 pt-4" style={{ borderTop: "1px solid black" }}>
                                    <span className="font-weight-bold" style={{ fontSize: "1.75rem" }}>Additional Details</span>
                                </div>
                                <Row className="w-100">

                                    <Col md="4" className="p-3">
                                        <SiteLabelTextField
                                            label="Email"
                                            required={true}
                                            value={email} onChange={(e) => props.onChange(e.target.value, "email")} placeholder="Please enter email address" />
                                    </Col>
                                    <Col md="4" className="p-3">
                                        <div className="font-weight-bold pt-2 pb-2">
                                            <span style={{ color: '#DC3545', fontWeight: 'bold' }}>*</span>Telephone
                                        </div>
                                        {
                                            execStatusScheduleTest.status === "PENDING" ?
                                                <SiteLabelTextField
                                                    label="Telephone"
                                                    required={true}
                                                    value={telephone} onChange={(e) => props.onChange(e.target.value, "telephone")} placeholder="Please enter telephone number" /> :
                                                <ReactTelInput
                                                    defaultCountry="gb"
                                                    preferredCountries={['gb', 'us']}
                                                    value={telephone}
                                                    onlyCountries={contryList}
                                                    onChange={(telNumber, selectedCountry) => props.onChange(telNumber, "telephone")}
                                                    flagsImagePath={`${process.env.PUBLIC_URL}/flags.png`}
                                                //onChange={handleInputChange}
                                                />
                                        }
                                    </Col>
                                    {
                                        serviceType.value === "PCR_DAY_2" &&
                                        <Col md="4" className="p-3">
                                            <Calender
                                                label="Arrival Date"
                                                required={true}
                                                value={arrivalDate}
                                                minDate={departureDate ? departureDate : getPastDate(0)}
                                                placeholder="Select Date of Arrival"
                                                getSelectedDate={(date) => { props.onChange(date, "arrivalDate") }}
                                            />
                                        </Col>
                                    }
                                    {
                                        (serviceType.value === "PCR_DAY_2" && travelMethodList !== undefined) &&
                                        <Col md="4" className="p-3">
                                            <MaterialAutoComplete
                                                required={true}
                                                startAdornment="Travel Method"
                                                selectedItem={travelMethod}
                                                onSelect={(value, data) => props.onSelect(value, data, "travelMethod")}
                                                placeholder="Select Travel Method"
                                                data={travelMethodList}
                                            />
                                        </Col>
                                    }
                                    {
                                        serviceType.value === "PCR_DAY_2" &&
                                        <Col md="4" className="p-3">
                                            <SiteLabelTextField
                                                label="Flight/Coach/Vessel Number"
                                                required={true}
                                                value={vesselNumber} onChange={(e) => props.onChange(e.target.value, "vesselNumber")} placeholder="Flight/Coach/Vessel Number" />
                                        </Col>
                                    }
                                    {
                                        serviceType.value === "PCR_DAY_2" &&
                                        <Col md="4" className="p-3">
                                            <Calender
                                                label="Departure Date "
                                                required={true}
                                                value={departureDate}
                                                maxDate={arrivalDate}
                                                placeholder="Select Date of Departure"
                                                getSelectedDate={(date) => { props.onChange(date, "departureDate") }}
                                            />
                                        </Col>
                                    }
                                    {
                                        (serviceType.value === "PCR_DAY_2" && sbsCountriesList) &&
                                        <Col md="4" className="p-3">
                                            <MaterialAutoCompleteTypeable
                                                startAdornment="Departure Country "
                                                required={true}
                                                selectedItem={departureCountry}
                                                onSelect={(value, data) => props.onSelect(value, data, "departureCountry")}
                                                placeholder="Select Country from a list"
                                                data={sbsCountriesList}
                                            />
                                        </Col>
                                    }
                                    {/*{*/}
                                    {/*    testType.value === "PCR_DAY_2" &&*/}
                                    {/*    <Col md="4" className="p-3">*/}
                                    {/*        <Calender*/}
                                    {/*            label="Swab Taken "*/}
                                    {/*            required={true}*/}
                                    {/*            value={swabTaken}*/}
                                    {/*            placeholder="Select swab date and time"*/}
                                    {/*            getSelectedDate={(date) => { props.onChange(date, "swabTaken") }}*/}
                                    {/*        />*/}
                                    {/*    </Col>*/}
                                    {/*}*/}
                                </Row>

                                <div className="mt-4 pt-4" style={{ borderTop: "1px solid black" }}>
                                    <span className="font-weight-bold" style={{ fontSize: "1.75rem" }}>Home Address</span>
                                </div>

                                <div className="w-100">
                                    <FormControlLabel
                                        className="checkbox-size mb-0"
                                        control={
                                            <Checkbox
                                                size='medium'
                                                checked={isHomeAddressSameAsAboveTestAddress}
                                                onChange={(e) => props.onChange(isHomeAddressSameAsAboveTestAddress, "isHomeAddressSameAsAboveTestAddress")}
                                                name="isHomeAddressSameAsAboveTestAddress"
                                                color="secondary"
                                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                                        }
                                        label="Home address same as above test address?"
                                    />
                                    <Row className="w-100">
                                        <Col md="4" className="position-relative">
                                            <SiteLabelTextField label="Post Code"
                                                value={homeAddressPostcode}
                                                required={false}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { handlePostCodeInputClick(e, "homeAddressPostcode") } }}
                                                onChange={(e) => { handlePostCodeInputChange(e, "homeAddressPostcode") }}
                                                // onChange={(e) => props.onChange(e.target.value, "postcode")}
                                                placeholder="Please enter postcode" />
                                            <div className="position-absolute "
                                                onClick={(e) => { handlePostCodeInputClick(e, "homeAddressPostcode") }}
                                                style={{ top: '36px', right: '30px', display: 'flex', alignItems: 'center' }}>
                                                {homeAddressPostCodeAtr.postcodeDropdown &&
                                                    <i onClick={(e) => { e.stopPropagation(); setHomeAddressPostCodeAtr({ ...homeAddressPostCodeAtr, postcodeDropdown: false }); }}
                                                        className="fas fa-times-circle"
                                                        style={{ marginRight: '10px', color: 'darkred', fontSize: '14px', cursor: 'pointer' }}
                                                    >
                                                    </i>
                                                }
                                                <button type="button" className="btn btn-primary">Search</button>
                                            </div>

                                            {homeAddressPostCodeAtr.postcodeDropdown &&
                                                <PostCodeDropdown
                                                    absoluteLeft={false}
                                                    errorValue={homeAddressPostCodeAtr.errorValue}
                                                    resValue={homeAddressPostCodeAtr.resValue}
                                                    addressDetails={(e) => addressDetails(e, "homeAddressPostcode")}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="w-100">
                                        <Col md="4" className="p-3">
                                            <SiteLabelTextField label="Home Address Line 1"
                                                value={homeAddressLine1}
                                                onChange={(e) => props.onChange(e.target.value, "homeAddressLine1")}
                                                placeholder="Please enter home address" />
                                        </Col>
                                        <Col md="4" className="p-3">
                                            <SiteLabelTextField label="Home Address Line 2"
                                                value={homeAddressLine2}
                                                onChange={(e) => props.onChange(e.target.value, "homeAddressLine2")}
                                                placeholder="Please enter home address" />
                                        </Col>
                                        <Col md="4" className="p-3">
                                            <SiteLabelTextField label="Home City"
                                                value={homeAddressCity}
                                                required={false}
                                                onChange={(e) => props.onChange(e.target.value, "homeAddressCity")}
                                                placeholder="Please enter home city" />
                                        </Col>
                                        <Col md="4" className="p-3">
                                            <MaterialAutoCompleteTypeable
                                                startAdornment="Home Country"
                                                required={false}
                                                selectedItem={homeAddressCountry}
                                                onSelect={(value, data) => props.onSelect(value, data, "homeAddressCountry")}
                                                placeholder="Please select your home country"
                                                data={serviceType.value === "PCR_DAY_2" ? sbsCountriesList : COUNTRIES_LIST}
                                            />
                                        </Col>
                                    </Row>
                                </div>

                                {
                                    serviceType.value === "PCR_DAY_2" &&
                                    <>
                                        <div className="mt-4 pt-4" style={{ borderTop: "1px solid black" }}>
                                            <span className="font-weight-bold" style={{ fontSize: "1.75rem" }}>Self-Isolation Address</span>
                                        </div>


                                        <div className="w-100">
                                            <FormControlLabel
                                                className="checkbox-size mb-0"
                                                control={
                                                    <Checkbox
                                                        size='medium'
                                                        checked={isSelfAddressSameAsAboveTestAddress}
                                                        onChange={(e) => props.onChange(isSelfAddressSameAsAboveTestAddress, "isSelfAddressSameAsAboveTestAddress")}
                                                        name="isSelfAddressSameAsAboveTestAddress"
                                                        color="secondary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }} />

                                                }
                                                label="Self-Isolation address same as test address?"
                                            />
                                            <Row className="w-100">
                                                <Col md="4" className="position-relative">
                                                    <SiteLabelTextField label="Post Code"
                                                        value={selfIsolationAddressPostcode}
                                                        required={false}
                                                        onKeyDown={(e) => { if (e.key === 'Enter') { handlePostCodeInputClick(e, "selfIsolationAddressPostcode") } }}
                                                        onChange={(e) => { handlePostCodeInputChange(e, "selfIsolationAddressPostcode") }}
                                                        // onChange={(e) => props.onChange(e.target.value, "postcode")}
                                                        placeholder="Post Code" />
                                                    <div className="position-absolute "
                                                        onClick={(e) => { handlePostCodeInputClick(e, "selfIsolationAddressPostcode") }}
                                                        style={{ top: '36px', right: '30px', display: 'flex', alignItems: 'center' }}>
                                                        {selfIsolationAddressPostCodeAtr.postcodeDropdown &&
                                                            <i onClick={(e) => { e.stopPropagation(); setSelfIsolationAddressPostCodeAtr({ ...selfIsolationAddressPostCodeAtr, postcodeDropdown: false }); }}
                                                                className="fas fa-times-circle"
                                                                style={{ marginRight: '10px', color: 'darkred', fontSize: '14px', cursor: 'pointer' }}
                                                            >
                                                            </i>

                                                        }
                                                        <button type="button" className="btn btn-primary">Search</button>
                                                    </div>

                                                    {
                                                        selfIsolationAddressPostCodeAtr.postcodeDropdown &&
                                                        <PostCodeDropdown
                                                            absoluteLeft={false}
                                                            errorValue={selfIsolationAddressPostCodeAtr.errorValue}
                                                            resValue={selfIsolationAddressPostCodeAtr.resValue}
                                                            addressDetails={(e) => addressDetails(e, "selfIsolationAddressPostcode")}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="w-100">
                                                <Col md="4" className="p-3">
                                                    <SiteLabelTextField label="Self-Isolation Address Line 1"
                                                        value={selfIsolationAddressLine1}
                                                        onChange={(e) => props.onChange(e.target.value, "selfIsolationAddressLine1")}
                                                        placeholder="Self-Isolation Address Line 1" />
                                                </Col>
                                                <Col md="4" className="p-3">
                                                    <SiteLabelTextField label="Self-Isolation Address Line 2"
                                                        value={selfIsolationAddressLine2}
                                                        onChange={(e) => props.onChange(e.target.value, "selfIsolationAddressLine2")}
                                                        placeholder="Self-Isolation Address Line 2" />
                                                </Col>
                                                <Col md="4" className="p-3">
                                                    <SiteLabelTextField label="Self-Isolation City"
                                                        value={selfIsolationAddressCity}
                                                        required={false}
                                                        onChange={(e) => props.onChange(e.target.value, "selfIsolationAddressCity")}
                                                        placeholder="City" />
                                                </Col>
                                                <Col md="4" className="p-3">
                                                    <SiteLabelTextField
                                                        label="Self-Isolation Country"
                                                        required={true}
                                                        disabled
                                                        value={selfIsolationAddressCountry.name} onChange={() => { }} placeholder="Self-Isolation Country" />
                                                </Col>
                                            </Row>
                                        </div>

                                    </>

                                }

                                <div className="w-100">
                                    <SiteLabelTextField label="Comments" value={producerComments} onChange={(e) => props.onChange(e.target.value, "producerComments")} placeholder="Comments" />
                                </div>
                            </div>
                            {
                                (serviceType.value === "NEXT_DAY" || serviceType.value === "SAME_DAY") &&
                                <div className={clsx("w-25 p-2 d-flex justify-content-start flex-column align-items-start", mobileWidth && "w-75")}>
                                    <Button onClick={showPatientDetail} variant="outlined">
                                        Optional Details
                                    </Button>
                                </div>
                            }

                            <div className={clsx("w-35 p-2 d-flex justify-content-center flex-column align-items-center", mobileWidth && "w-100")}>
                                <LoaderButton
                                    fullWidth={true}
                                    color="primary"
                                    loading={execStatusScheduleTest.status === "PENDING" ? true : false}
                                    onClick={props.onScheduleSubmit} >
                                    Submit
                                </LoaderButton>
                            </div>
                        </Paper>
                    </div>
                    // ) : 
                }
            </div>
            {
                state.showPatientDetailDialog ?
                    <PatientDetailDialog
                        {...props}
                        onSelect={props.onSelect}
                        onChange={props.onChange}
                        handleClose={hidePatientDetail}
                        open={state.showPatientDetailDialog} /> : null
            }
        </div>
    )
}
export default PatientsList