import React from 'react'
import { Row, Column, Body, Spacer, Button } from '../../../components'
import tickIcon from '../../../assets/images/tick-icon.svg'
import unCheckedIcon from '../../../assets/images/unchecked-icon.svg'

const HealthPractitionerAddress = ({
	practitioner,
	address,
	index,
	isSelected,
	borderedAsList,
	onClick,
	onEnterEditMode,
}) => {
	const { firstName, lastName, email, companyOrPracticeName } = practitioner
	const { addressLine1, addressLine2, city, postcode } = address

	return (
		<Column
			tabletSize={10}
			desktopSize={8}
			marginTop={0}
			onClick={onClick}
			borderedAsList={borderedAsList}
			bordered={!borderedAsList}
			style={{ padding: '1rem 2.5rem' }}
		>
			<Row tabletSize={10} desktopSize={8} marginTop={0}>
				<Column tabletSize={4} desktopSize={3} mobileSize={1}>
					<h5 className="no-space" style={{ paddingLeft: '3.3rem' }}>
						Care of
					</h5>
				</Column>
				<Column tabletSize={4} desktopSize={3} mobileSize={1}>
					<h5 className="no-space">Address</h5>
				</Column>
				<Column desktopSize={1} tabletSize={1} />
			</Row>
			<Spacer spacing="s" />
			<Row>
				<Column tabletSize={4} desktopSize={3}>
					<Row>
						{isSelected ? (
							<img
								src={tickIcon}
								className="img-button"
								width="25"
								height="25"
								alt="selected icon"
							/>
						) : (
							<img
								src={unCheckedIcon}
								style={{ opacity: 0.2 }}
								className="img-button"
								width="25"
								height="25"
								alt="unselected icon"
							/>
						)}
						<Spacer spacing="s" />
						<Column contained>
							<Body>{`${firstName} ${lastName}`}</Body>
							<Body>{email}</Body>
						</Column>
					</Row>
				</Column>
				<Column tabletSize={4} desktopSize={3}>
					<Body>{companyOrPracticeName}</Body>
					<Body>{addressLine1}</Body>
					<Body>{addressLine2}</Body>
					<Body>{city}</Body>
					<Body>{postcode}</Body>
				</Column>
				<Column desktopSize={2} tabletSize={2}>
					<Button link tiny chromeless onClick={() => onEnterEditMode({ index })}>
						Edit
					</Button>
				</Column>
			</Row>
			<Spacer spacing="m" />
		</Column>
	)
}

export { HealthPractitionerAddress }
