import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Row } from './containers'
import { Body } from './typography'

const parsePath = (pathPart, index, arrayPath) => {
	switch (pathPart) {
		case 'customer':
			return { text: 'Home', href: '/customer' }
		case 'assign-test-kit':
			return { text: 'Assign a test kit', href: '' }
		case 'place-order':
			return { text: 'Place an order', href: index < arrayPath.length - 1 ? '/' + pathPart : '' }
		case 'order-history':
			return { text: 'Order history', href: '' }
		case 'order-professional-test-kits':
			return { text: 'Order professional test kits', href: '' }
		case 'admin':
			return { text: 'Setup', href: '/admin' }
		case 'user':
			return { text: 'User', href: '' }
		case 'organisation':
			return { text: 'Organisation', href: '' }
		case 'confirm-orders':
			return { text: 'Home', href: '/customer' }
		case 'billing':
			return { text: 'Home', href: '/customer' }
		default:
			return { text: pathPart }
	}
}

const Breadcrumps = () => {
	const { pathname } = useLocation()

	const crumps = pathname
		.substring(1)
		.split('/')
		.map((pathPart, index, arrayPath) => parsePath(pathPart, index, arrayPath))
		.map(({ text, href }, index, arrayPath) => {
			const hasHref = typeof href === 'string' && href.length > 1
			const urlPath = hasHref ? arrayPath.slice(0, index + 1).map(i => i.href).join('') : '';

			const textElement = hasHref ? (
				<Link className="small" to={urlPath}>
					{text}
				</Link>
			) : (
				text
			)

			if (index === 0) {
				return (
					<Body small key={text}>
						{textElement}
					</Body>
				)
			} else {
				return (
					<React.Fragment key={index}>
						<Body small key={index}>
							&nbsp;›&nbsp;
						</Body>
						<Body small key={text}>
							{textElement}
						</Body>
					</React.Fragment>
				)
			}
		})

	return (
		<Row contained spacingTop="s">
			{crumps}
		</Row>
	)
}

export { Breadcrumps }
