import { regex } from 'components/common/util/regex'
import moment from 'moment';

export function isValidPatientDetail(data) {

    let isValidDOB = true
    let isValidhomeAddressCountry = true

    const isValiddateOfTest = data.dateOfTest !== undefined && data.dateOfTest && data.dateOfTest !== "" && data.dateOfTest?.toString().toLowerCase() !== "invalid date" && moment(data.dateOfTest, "DD/MM/YYYY").toDate().setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
    const isValidpreferredTime = data.preferredTime !== undefined && data.preferredTime && data.preferredTime.value && data.preferredTime.value.trim() !== "";

    const isValidpostcode = data.postcode !== undefined && data.postcode && data.postcode.trim() !== "";
    const isValidaddressLine1 = data.addressLine1 !== undefined && data.addressLine1 && data.addressLine1.trim() !== "";
    const isValidcity = data.city !== undefined && data.city && data.city.trim() !== "";

    // const isValidfullName = data.fullName !== undefined && data.fullName && data.fullName.trim() !== "" && data.fullName.length > 1;
    const isValidfamilyName = data.familyName !== "" && data.familyName !== undefined && data.familyName && data.familyName.trim() !== "" && data.familyName.length > 1;
    const isValidgivenName = data.givenName !== "" && data.givenName !== undefined && data.givenName && data.givenName.trim() !== "" && data.givenName.length > 1;
    if (data.DOB) {
        isValidDOB = data.DOB !== undefined && data.DOB && data.DOB !== "" && data.DOB?.toString().toLowerCase() !== "invalid date";
    }
    const isValidgender = data.gender !== undefined && data.gender.value && data.gender.value.trim() !== "";
    const isValiddocumentNumber = data.documentNumber !== undefined && data.documentNumber && data.documentNumber.trim() !== "";
    const isValidethnicity = data.ethnicity !== undefined && data.ethnicity.value && data.ethnicity.value.trim() !== "";
    const isValidvaccinationStatus = data.vaccinationStatus !== undefined && data.vaccinationStatus.value && data.vaccinationStatus.value.trim() !== "";

    const isValidemail = regex.email.test(data.email?.trim()) && data.email && data.email.trim() !== ""
    const isValidtelephone = data.telephone !== undefined && data.telephone && data.telephone.trim() !== "" && data.telephone.trim() !== "+";
    const isValidarrivalDate = data.arrivalDate !== undefined && data.arrivalDate && data.arrivalDate !== "" && data.arrivalDate?.toString().toLowerCase() !== "invalid date";
    const isValidtravelMethod = data.travelMethod !== undefined && data.travelMethod.value && data.travelMethod.value.trim() !== "";
    const isValidvesselNumber = data.vesselNumber !== undefined && data.vesselNumber && data.vesselNumber.trim() !== "";
    const isValiddepartureDate = data.departureDate !== undefined && data.departureDate && data.departureDate !== "" && data.departureDate?.toString().toLowerCase() !== "invalid date";
    const isValiddepartureCountry = data.departureCountry !== undefined && data.departureCountry.value && data.departureCountry.value.trim() !== "";

    const isValidHomeAddressLine1 = data.homeAddressLine1 !== undefined && data.homeAddressLine1 && data.homeAddressLine1.trim() !== "";
    if (data.homeAddressCountry) {
        isValidhomeAddressCountry = data.homeAddressCountry !== undefined && data.homeAddressCountry.value && data.homeAddressCountry.value.trim() !== "";
    }

    // const isValidnationality = data.nationality !== undefined && data.nationality && data.nationality.trim() !== "";
    // const isValiddocumentType = data.documentType !== undefined && data.documentType && data.documentType.trim() !== "";
    //const isValidcountry = data.country !== undefined && data.country && data.country.value && data.country.value.trim() !== "";
    // const isValiddocumentCountry = data.documentCountry !== undefined && data.documentCountry && data.documentCountry.value && data.documentCountry.value.trim() !== "";
    // const isValiddocumentNumber = data.documentNumber !== undefined && data.documentNumber && data.documentNumber.trim() !== "";
    // const isValiddocumentAuthority = data.documentAuthority !== undefined && data.documentAuthority && data.documentAuthority.trim() !== "";
    // const isValiddocumentExpiration = data.documentExpiration !== undefined && data.documentExpiration && data.documentExpiration !== "";

    const allValidDay = isValidemail && isValiddateOfTest && isValidpreferredTime && isValidfamilyName && isValidgivenName && isValidtelephone && isValidaddressLine1 && isValidpostcode && isValidcity;

    const allValidDay2 = isValiddateOfTest
        && isValidpostcode && isValidaddressLine1 && isValidcity
        && isValidgivenName && isValidfamilyName && isValidDOB && isValiddocumentNumber && isValidethnicity && isValidvaccinationStatus
        && isValidemail && isValidtelephone && isValidarrivalDate && isValidtravelMethod && isValidvesselNumber && isValiddepartureDate && isValiddepartureCountry
        && isValidhomeAddressCountry;

    // console.log(isValidemail, isValiddateOfTest, isValidpreferredTime, isValidfamilyName, isValidgivenName, isValidtelephone, isValidaddressLine1, isValidpostcode, isValidcity)
    // console.log(isValiddateOfTest
    //     , isValidpostcode, isValidaddressLine1, isValidcity
    //     , isValidgivenName, isValidfamilyName, isValidDOB, isValidgender, isValiddocumentNumber, isValidethnicity, isValidvaccinationStatus
    //     , isValidemail, isValidtelephone, isValidarrivalDate, isValidtravelMethod, isValidvesselNumber, isValiddepartureDate, isValiddepartureCountry
    //     , isValidhomeAddressCountry)

    //  && isValidDOB &&
    // isValidfullName && isValidfamilyName &&  isValidgivenName
    // && isValidtelephone && isValidgender && isValidaddressLine1 && isValidpostcode && isValidcity &&
    // isValidnationality && isValiddocumentType && isValiddocumentCountry && isValiddocumentNumber && 
    // isValiddocumentAuthority && isValiddocumentExpiration && isValidcountry 

    return {
        isValidemail: isValidemail,
        isValiddateOfTest: isValiddateOfTest,
        isValidpreferredTime: isValidpreferredTime,
        // isValidfullName: isValidfullName,
        isValidfamilyName: isValidfamilyName,
        isValidgivenName: isValidgivenName,
        isValidDOB: isValidDOB,
        isValidtelephone: isValidtelephone,
        isValidaddressLine1: isValidaddressLine1,
        isValidHomeAddressLine1: isValidHomeAddressLine1,
        isValidhomeAddressCountry: isValidhomeAddressCountry,
        isValidpostcode: isValidpostcode,
        isValidcity: isValidcity,
        isValidgender: isValidgender,
        isValiddocumentNumber: isValiddocumentNumber,
        isValidethnicity: isValidethnicity,
        isValidvaccinationStatus: isValidvaccinationStatus,
        isValidarrivalDate: isValidarrivalDate,
        isValidtravelMethod: isValidtravelMethod,
        isValidvesselNumber: isValidvesselNumber,
        isValiddepartureDate: isValiddepartureDate,
        isValiddepartureCountry: isValiddepartureCountry,
        // isValidnationality: isValidnationality,
        // isValiddocumentType: isValiddocumentType,
        // isValiddocumentCountry: isValiddocumentCountry,
        // isValiddocumentNumber: isValiddocumentNumber,
        // isValiddocumentAuthority: isValiddocumentAuthority,
        // isValiddocumentExpiration: isValiddocumentExpiration,
        allValid: data?.serviceType?.value === 'PCR_DAY_2' ? allValidDay2 : allValidDay
    }
}