import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { scheduleTest, resetScheduleTestResponse, createEvent, getEvents, resetEvent, resetEventList } from '../../view.updater/actions/order.actions';
import { makeSelectDashboardKits, makeSelectKitsCourierStatus } from '../../view.updater/selectors/dashboard.selectors';
import ScheduleTests from './schedule-tests';
import { getKits, updateKitData, sendConfirmationLink, updateProducerTestKitCall, updatePatientDetail,getCourierStatus } from 'view.updater/actions/dashboard.actions';
import { makeSelectScheduleTestResponse, makeSelectScheduleTestStatus, makeSelectEvents, makeSelectDefaultSelectedEvent } from 'view.updater/selectors/order.selectors';

const mapDispatchToProps = (dispatch) => ({
    scheduleTest: (data) => dispatch(scheduleTest(data)),
    getKits: (data) => dispatch(getKits(data)),
    updateKitData: (data) => dispatch(updateKitData(data)),
    updateProducerTestKitCall: (data) => { dispatch(updateProducerTestKitCall(data)) },
    updatePatientDetail: (data) => { dispatch(updatePatientDetail(data)) },
    resetScheduleTestResponse: (data) => dispatch(resetScheduleTestResponse(data)),
    sendConfirmationLink: (data) => dispatch(sendConfirmationLink(data)),
    createEvent: (data) => dispatch(createEvent(data)),
    getEvents: () => dispatch(getEvents()),
    resetEvent: () => dispatch(resetEvent()),
    resetEventList: () => dispatch(resetEventList()),
    getCourierStatus: (data) => dispatch(getCourierStatus(data))
});

const mapStateToProps = createStructuredSelector({
    kits: makeSelectDashboardKits(),
    scheduleTestResponse: makeSelectScheduleTestResponse(),
    scheduleTestStatus: makeSelectScheduleTestStatus(),
    events: makeSelectEvents(),
    defaultSelectedEvent: makeSelectDefaultSelectedEvent(),
    kitsCourierStatus : makeSelectKitsCourierStatus()
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ScheduleTests);