import React, { useState, useMemo, useReducer } from 'react'
import { Column, Breadcrumps, Row, Spacer, Button, Icon } from '../../components'
import { withAuthentication, useOrdering, useAuth } from '../../utils'
import downloadIcon from '../../assets/images/download-icon.svg';
import { BulkAssignUpload } from './AssignTestKitPage/BulkAssignUpload'
import Table from "./Table";
import ProcessingMessages from './ProcessingMessages';
import XLSX from 'xlsx';
import moment from 'moment';
import styled from 'styled-components';
import { checkIfAllEmpty } from '../../components/common/util/util';

const WrapperStyled = styled.div`
	display: block;
`

function messagesReducer(state, newMessage) {
	return [...state, newMessage];
}

const BulkAssignSameDayTestKitPage = withAuthentication((props) => {

	const [patientsData, setPatientsData] = useState([]);
	const [selectedFileName, setSelectedFileName] = useState('');
	const [messagesArray, setMessagesArray] = useReducer(messagesReducer, []);
	const { customer, defaultOrganisation } = useAuth()

	const { submitOrder, createSameDayOrder } = useOrdering()

	// const { assignTestKit } = useOrdering()

	const bulkAssignFileUploaded = (file) => {
		setSelectedFileName(file.name);
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = (e) => {
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', cellDates: true, dateNF: 'dd/mm/yyyy;@' });
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			const data = XLSX.utils.sheet_to_json(ws, { raw: false, blankrows: false, defval: '' });
			let exsValidData = [];
			data && data.map((dat) => {
				if (!checkIfAllEmpty(dat)) {
					exsValidData.push({
						email : dat["Email Address"],
						fullName : dat["Full Name"],
						familyName : dat["Family Name"],
						givenName : dat["Given Name"], 
						telephone : dat["Telephone"],
						DOB: dat["Date of Birth"],
						gender: dat["Gender"],
						documentType: dat["Document Type"],
						documentCountry : dat["Document Country"],
						documentAuthority : dat["Document Authority"],
						documentNumber : dat["Document Number"],
						documentExpiration : dat["Document Expiry Date"],
						addressLine1: dat["Address Line 1"],
						addressLine2: dat["Address Line 2"],
						city: dat["City"],
						country: dat["Country"],
						nationality : dat["Nationality"],
						postcode: dat["Postcode"],
						healthNumber: dat["Health Number"],
						organisationId: dat["Org-ID"]
					});
				}
			})
			setPatientsData(exsValidData);
		};
		if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
	}

	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	const validateDateValue = (date) => {
		const date_regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
		return date_regex.test(date);
	}

	const validateTimeValue = (time) => {
		const time_regex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/;
		return time_regex.test(time);
	}

	const validatePatientData = (patientData) => {
		let valid = true;
		// Reuired fields check
		if (
			patientData.organisationId && patientData.fullName && patientData.email && patientData.DOB && patientData.telephone && patientData.gender && patientData.addressLine1 &&
			patientData.postcode && patientData.country && patientData.nationality
		) {
			// Validate gender values
			if (['MALE', 'FEMALE', 'TRANSGENDER', 'OTHER'].includes(patientData.gender.toUpperCase())) {
				// Validate email values
				if (validateEmail(patientData.email)) {
					// Validate DOB
					if (validateDateValue(patientData.DOB)) {
						valid = true;
					} else {
						setMessagesArray({
							text: `${patientData.fullName} (${patientData.organisationId}) has invalid date of birth value. The correct format is DD/MM/YYYY.`,
							type: "failure"
						});
						valid = false;
					}
				} else {
					setMessagesArray({
						text: `${patientData.fullName} (${patientData.organisationId}) has invalid email value.`,
						type: "failure"
					});
					valid = false;
				}
			} else {
				setMessagesArray({
					text: `${patientData.fullName} (${patientData.organisationId}) has invalid gender value. Valid gender values are: MALE, FEMALE, TRANSGENDER, OTHER.`,
					type: "failure"
				});
				valid = false;
			}

		} else {
			setMessagesArray({
				text: `${patientData.fullName} (${patientData.organisationId}) is missing one or more mandatory field(s). Mandatory fields are: 
				organisationId, fullName, email, DOB, telephone, gender, addressLine1, postcode, country, nationality`,
				type: "failure"
			});
			valid = false;
		}
		return valid;
	}

	const formatDateValueForBackend = (dateValue) => {
		const dateArray = dateValue.trim().split('/');
		return dateArray.length == 3 ? `${dateArray[2]}${dateArray[1]}${dateArray[0]}` : dateValue;
	}

	const formatBooleanValues = (value) => {
		if (typeof value === "string") {
			return value.toLowerCase() === 'true';
		} else {
			return value;
		}
	}
	const handleDownloadTemplateClick = () => {
		const templateHref = '/bulk.upload.template.xlsx'
		const anchor = document.createElement('a')
		anchor.href = templateHref
		anchor.download = templateHref
		document.body.appendChild(anchor)
		anchor.click()
	}

	const handleBulkProcess = () => {
		console.log(patientsData, 'patient data');
		setMessagesArray({ text: `Processing started for document - ${selectedFileName}`, type: "start" });
		for (const patient of patientsData) {
			if (validatePatientData(patient)) {
				let newPatient = {...patient};
				newPatient.gender = newPatient.gender && newPatient.gender.toUpperCase();
				newPatient.email = newPatient.email && newPatient.email.toLowerCase();
				newPatient.DOB = newPatient.DOB;// && moment(newPatient.DOB, "DD/MM/YYYY").format('YYYY/MM/DD');
				const orderDraft = createSameDayOrder([newPatient], false)

				const order = {
					...orderDraft,
					organisationId: patient.organisationId,
					patients: [newPatient],
					createdBy: customer.uid,
					email: customer.email
				}
				order.orderType = "same-day-test"
				// setIsLoading(true)
				Promise.resolve(props.processSameDayOrder(order, true)).then(() => {
					setMessagesArray({ text: `${patient.fullName} (${patient.organisationId}) has been successfully processed.`, type: "success" });
				}).catch((error) => {
					console.error("error", error)
					setMessagesArray({ text: `${patient.fullName} (${patient.organisationId}) - We are unable to process data.`, type: "failure" });
				})
				// submitOrder(order)
				// 	.then((response) => {
				// 		setMessagesArray({ text: `${patient.fullName} (${patient.organisationId}) has been successfully processed.`, type: "success" });
				// 	})
				// 	.catch((error) => {
				// 		console.error("error", error)
				// 		setMessagesArray({ text: `${patient.fullName} (${patient.organisationId}) - We are unable to process data.`, type: "failure" });
				// 	})
			}
		}
	}

	const patientColumns = useMemo(
		() => [
			{
				Header: "Organisation ID",
				accessor: "organisationId"
			},
			{
				Header: "Full Name",
				accessor: "fullName"
			},
			{
				Header: "Given Name",
				accessor: "givenName"
			},
			{
				Header: "Family Name",
				accessor: "familyName"
			},
			{
				Header: "Email",
				accessor: "email"
			},
			{
				Header: "Date of Birth",
				accessor: "DOB"
			},
			{
				Header: "Phone",
				accessor: "telephone"
			},
			{
				Header: "Gender",
				accessor: "gender"
			},
			{
				Header: "Address Line 1",
				accessor: "addressLine1"
			},
			{
				Header: "Address Line 2",
				accessor: "addressLine2"
			},
			{
				Header: "Postcode",
				accessor: "postcode"
			},
			{
				Header: "Country",
				accessor: "country"
			},
			{
				Header: "Nationality",
				accessor: "nationality"
			},
			{
				Header: "Health Number",
				accessor: "healthNumber"
			},
			{
				Header: "Document Type",
				accessor: "documentType"
			},
			{
				Header: "Document Country",
				accessor: "documentCountry"
			},
			{
				Header: "Document Number",
				accessor: "documentNumber"
			},
			{
				Header: "Document Expiration",
				accessor: "documentExpiration"
			},
			{
				Header: "Document Authority",
				accessor: "documentAuthority"
			}
		],
		[]
	);

	return (
		<>
			<Column desktopSize={12} spacingTop="s" style={{ width: '80%' }}>
				<Breadcrumps />
				
				<Row size={8} spacingTop="s">
					<h3>Upload excel file to place bulk same day orders.</h3>
				</Row>
				<Row size={8}>
					<BulkAssignUpload
						onFileSelect={bulkAssignFileUploaded}
					// onFileSelectError={({ error }) => alert(error)}
					/>
				</Row>
				<Spacer spacing="m" />
				<Row>
					<Button secondary icon onClick={handleDownloadTemplateClick}>
						<Icon img={downloadIcon} type="btn-inline-left" alt="Download icon" />
						Download bulk upload template
					</Button>
				</Row>
				<Spacer spacing="m" />
				{patientsData.length > 0 &&
					<WrapperStyled>
						{/* <Row size={8}>
							<div>{patientsData.length} Records</div>
						</Row> */}
						<Row size={8}>
							<Table columns={patientColumns} data={patientsData} />
						</Row>
						<Row size={8}>
							<ProcessingMessages messages={messagesArray} totalRecords={patientsData.length} />
						</Row>
						<Row size={4}>
							<Button secondary onClick={handleBulkProcess}>Process</Button>
						</Row>
					</WrapperStyled>
				}
			</Column>
		</>
	)
})

export { BulkAssignSameDayTestKitPage }
