import React from 'react'
import { sortableUid } from '../utils'
import { genderPattern, gendersList, preferredTimeList, preferredTimePattern } from '../utils/uitl'
import { Column, Row } from './containers'
import { FormTextInput, FormCheckboxInput } from './forms'

//SAME_DAY_ORDER_CHANGES
const PatientDetailsEditorSameDay = ({ editPatientMode, isMailKit, orgs }) => {
    console.log('patient details editor: ', isMailKit)
    const emailRow = editPatientMode ? (
        <Row size={8} spacingTop="s">
            <FormTextInput
                desktopSize={8}
                tabletSize={8}
                label="Email"
                name="email"
                required
                pattern={/(.*)@(.*)\.([a-zA-Z]{2,20})/}
                errorMessage="Has to be valid email address"
            />
        </Row>
    ) : (
        <>
            <Row size={8} spacingTop="s" spaced>
                <FormTextInput
                    desktopSize={4}
                    tabletSize={4}
                    label="Email"
                    name="email"
                    required
                    pattern={/(.*)@(.*)\.([a-zA-Z]{2,20})/}
                    errorMessage="Has to be valid email address"
                />
                <FormTextInput
                    desktopSize={4}
                    tabletSize={4}
                    label="Confirm email"
                    name="confirmationEmail"
                    confirmationFor="email"
                    errorMessage="Emails must match"
                />
            </Row>
            <FormCheckboxInput name="shouldShareResults">
                Send a copy of results to this email address (individual will also receive results through
                their employer)
            </FormCheckboxInput>
        </>
    )
    let date = new Date()
    let options = { day: '2-digit', month: '2-digit', year: 'numeric' }
    let today = date.toLocaleDateString('en-GB', options)
    let todayTime = date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    })
    const datalistId = sortableUid();
    const datalistIdPt = sortableUid();

    return (
        <>
            <Column size={8}>
                <h5>Enter patient details</h5>
                <Row size={8} spacingTop="xs" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Full name"
                        name="fullName"
                        required
                        errorMessage="Field is required"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        pattern={/^\d+$/}
                        label="Telephone"
                        name="telephone"
                        required
                        errorMessage="Field is required"
                    />
                </Row>
                {emailRow}
                <Row size={8} spacingTop="m" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Gender"
                        listId={datalistId}
                        datalist={gendersList}
                        pattern={genderPattern}
                        name="gender"
                        required
                        errorMessage="Please enter one of: Female, Male, Transgender or Other"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Date of birth (DD / MM / YYYY)"
                        placeholder="01/11/1980"
                        //mask="99  /  99  /  9999"
                        name="DOB"
                        required
                        pattern={/^(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](?:19|20)\d\d$/}
                        errorMessage="Please enter date in DD / MM / YYYY format"
                    />
                </Row>
                <Row size={8} spacingTop="m">
                    <FormTextInput
                        desktopSize={8}
                        tabletSize={8}
                        label="Address line 1"
                        name="addressLine1"
                        required={true}
                        errorMessage="Field is required"
                    />
                </Row>
                <Row size={8} spacingTop="s">
                    <FormTextInput
                        desktopSize={8}
                        tabletSize={8}
                        label="Address line 2"
                        name="addressLine2"
                        errorMessage="Field is required"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Town/City"
                        name="city"
                        required
                        //required={editPatientMode}
                        errorMessage="Field is required"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Postcode"
                        name="postcode"
                        required
                        //required={editPatientMode}
                        errorMessage="Field is required"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Country"
                        name="country"
                        required
                        //required={editPatientMode}
                        errorMessage="Field is required"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Document Type"
                        name="documentType"
                        //required={editPatientMode}
                        errorMessage="Field is required"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Document Number"
                        name="documentNumber"
                        //required={editPatientMode}
                        errorMessage="Field is required"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Document Authority"
                        name="documentAuthority"
                        //required={editPatientMode}
                        errorMessage="Field is required"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Document Country"
                        name="documentCountry"
                        //required={editPatientMode}
                        errorMessage="Field is required"
                    />

                </Row>
                <Row size={8} spacingTop="s" spaced>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Given Name"
                        name="givenName"
                        //required={editPatientMode}
                        errorMessage="Field is required"
                    />
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Family Name"
                        name="familyName"
                        //required={editPatientMode}
                        errorMessage="Field is required"
                    />
                </Row>
                <Row size={8} spacingTop="s" spaced>
                    {/* <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Nationality"
                        name="nationality"
                        //required={editPatientMode}
                        errorMessage="Field is required"
                    /> */}
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Preferred Time"
                        placeholder={"Preferred Time"}
                        name="preferredTime"
                        listId={datalistIdPt}
                        datalist={preferredTimeList}
                        pattern={preferredTimePattern}
                        //required
                        //pattern={/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/}
                        errorMessage={`Please enter preferred time in 24 hour HH:mm format`}
                    />
                </Row>
                {/* <Row>
                    <FormTextInput
                        desktopSize={4}
                        tabletSize={4}
                        label="Health Number"
                        name="healthNumber"
                        //required={editPatientMode}
                        errorMessage="Field is required"
                    />
                </Row> */}
            </Column>
        </>
    )
}

export { PatientDetailsEditorSameDay }
