import React from 'react'
import { useBeaconForm } from './BeaconForm'
import { Button } from '../Button'

const SubmitButton = ({ primary, fitted, children }) => {
	const { errors, isSubmitting } = useBeaconForm()
	const errorsList = Object.values(errors)
	const numErrors = errorsList.length
	const isDisabled = numErrors > 0 || isSubmitting

	return (
		<Button primary={primary} submit fitted={fitted} disabled={isDisabled} loading={isSubmitting}>
			{children}
		</Button>
	)
}

export { SubmitButton }
