import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme) => ({
  textColor: {
    color: '#000000',
  },
  nooutline: {
    outline: 'none',
  },
  noBorder: {
    border: 'none',
  },
  iconColor: {
    color: theme.palette.secondary.main,
  },
  endAdn : {
     paddingRight : '3.5px'
  },
  startAdn : {
    paddingLeft : '0px'
  },
  input: {
    height: 8,
    minWidth : '250px',
    textOverflow:"ellipsis"
  },
  disabled : {
    color:"#000000"
  },
  paperRoot: {
    width: '100%',
    height: 'auto',
    border: '1px',
    position: "relative",
    boxShadow: '0px 4px 6px -2px rgb(90 85 85 / 20%)',
    outline: 'none',
    background: theme.palette.background.paper,
    display: 'flex',
    //alignItems: 'center'
  }
}))
