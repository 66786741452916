import React from "react";

export const EyePassword = ({eye, setEye}) => {
    return (
        <div className="position-absolute " style={{ top: '40px', right: '30px' }} >
            {eye ?
                <i onClick={(e) => {
                    e.stopPropagation();
                    setEye(eye)
                }}
                   className="fas fa-eye"
                   style={{
                       marginRight: '0px', color: 'black',
                       fontSize: '16px', cursor: 'pointer'
                   }}
                >
                </i>
                :
                <i onClick={(e) => {
                    e.stopPropagation();
                    setEye(eye);
                }}
                   className="fas fa-eye-slash"
                   style={{
                       marginRight: '0px', color: 'black',
                       fontSize: '16px', cursor: 'pointer'
                   }}
                >
                </i>
            }
        </div>
    )
}