import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
	Row,
	Column,
	Body,
	BackButton,
	Spacer,
	OrderPatientDetailsEditor,
} from '../../../components'
import {
	BeaconForm,
	SubmitButton,
	FormCheckboxInput,
	FormRadioMenuInput,
} from '../../../components/forms'
import { OrderPatientDetailsSameDayEditor } from '../../../components/OrderPatientDetailsSameDayEditor'
import { toUiPatient, toSbsPatient } from '../../../utils/sbs-utils'

const options = [
	{ value: 'courier', label: 'Courier' },
	{ value: 'self-test', label: 'Mail' }
]

const patient = {
	addressLine1: '43 Old Bond St',
	addressLine2: 'Mayfair',
	city: 'London',
	confirmationPhone: '012',
	dateOfBirth: '',
	dateOfSwab: '',
	deliverBy: '',
	deliveryDate: '29/01/2021',
	deliveryWindow: '',
	driverNotes: '',
	email: 'a@a.com',
	firstName: 'Ameer',
	jobNumber: '',
	lastName: 'Ahmed',
	org: 'test-gb',
	postcode: 'W1S 4QT',
	telephone: '012',
	orderType: 'courier',
}

const OrderPatientDetailsEntry = ({ onSubmit, value, onBack, orgs }) => {
	// const [patientDetails, setPatientDetails] = useState(value.patientDetails)
	// value.patientDetails = patient
	const [orderType, setOrderType] = useState(value.patientDetails.orderType || 'courier')
	console.log('value.adaf: ', value.patientDetails.orderType)
	const [isMailKit, setIsMailKit] = useState(
		(value.patientDetails.orderType && value.patientDetails.orderType !== 'courier') || false
	)

	console.log('on order entry page: ')

	const handleOrderType = (incomingOrderType) => {
		setOrderType(incomingOrderType)
		if (incomingOrderType === 'self-test') {
			setIsMailKit(true)
		} else {
			setIsMailKit(false)
		}
	}

	const handlePatientSubmit = (patientDetails) => {
		console.log('patient: ', patientDetails)
		onSubmit({ patientDetails })
	}

	return (
		<>
			<Row size={8} spacingTop="s">
				<h3>Please enter order details</h3>
				<Body>
					All fields are required for test kit processing purposes unless labeled optional.
				</Body>
			</Row>
			<BeaconForm
				onSubmit={handlePatientSubmit}
				initialValues={value.patientDetails}
				io={{ input: toUiPatient, output: toSbsPatient }}
			>
				<h5>Order Type</h5>
				<FormRadioMenuInput
					name="orderType"
					group="orderType"
					options={options}
					value={orderType}
					setter={handleOrderType}
					required={true}
				/>
				<OrderPatientDetailsEditor isMailKit={isMailKit} orgs={orgs} />
				<Spacer spacing="s" />
				<Spacer spacing="s" />
				<Row contained size={8}>
					<BackButton onClick={onBack} />
					<Spacer spacing="s" />
					<SubmitButton primary submit>
						Next
					</SubmitButton>
				</Row>
			</BeaconForm>
		</>
	)
}

export { OrderPatientDetailsEntry }
