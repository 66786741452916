import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import { Alert } from '@material-ui/lab';
import ContentDialog from "../../components/common/content.dialog/content.dialog";
import SiteLabelTextField from "../../components/common/site.label.textfield/site.label.textfield";
import {IsMobileWidth} from "../../components/common/util/util";
import {useAdmin} from "../../utils";

const BarcodeDialog = (props) => {
    const mobileWidth = IsMobileWidth;
    const [barcode, setBarcode] = useState(props.currentBarCode?.code);
    const [showErrors, setShowErrors] = useState('');
    const [loader, setLoader] = useState(false);
    const { createBarcode, updateBarcode, deleteBarcode } = useAdmin()


    const onChange = (value) => {
        setBarcode(value)
    }

    useEffect(()=>{
        setShowErrors('')
    },[])

    const onClick = (value) => {
        setLoader(true)
        if(barcode?.length !== 7){
            setShowErrors('Barcode must be 7 digit');
            setLoader(false)
        }else{
            setShowErrors('')
            if(props.barcodeFlag === 'added'){
                createBarcode({code: barcode})
                    .then((response) => {
                        console.log('vv addd', response)
                        if(response.data.message === 'This barcode has already been added.'){
                            console.log('vv addd to error')
                            setShowErrors('Same barcode has already added. Please use different one')
                        }else if(response.data.success === false){
                            setShowErrors('Unknown Error')
                        }else{
                            props.barcodeStatuses();
                        }
                        setLoader(false);
                    })
                    .catch((error) => {})

            }else if(props.barcodeFlag === 'edited'){

                updateBarcode({id: props.currentBarCode?.code, newCode: barcode, isUsed: props.currentBarCode?.isUsed})
                    .then((response) => {
                        if(response.data.message === 'No such barcode'){
                            setShowErrors('No Barcode Existed')
                        }else if(response.data.success === false){
                            setShowErrors('Unknown Error')
                        }else{
                            props.barcodeStatuses();
                        }
                        setLoader(false);
                    })
                    .catch((error) => {})
            }else if(props.barcodeFlag === 'deleted'){
                console.log('vv delete')

                deleteBarcode({id: barcode})
                    .then((response) => {
                        props.barcodeStatuses();
                        setLoader(false);
                    })
                    .catch((error) => {
                    })
            }
        }

    }

    console.log('ShowErrors',showErrors)

    return (
        <div>
            <ContentDialog
                onClose={props.handleClose}
                dialogIsOpen={props.isOpen}
                open={props.isOpen}
                showActions={true}
                loading={loader ? true : false}
                successText={` ${props.barcodeFlag === 'added' ? 'Create'
                    : props.barcodeFlag === 'edited' ? 'Update' 
                    : 'Delete'}`}
                onSuccess={(code) => onClick(code)}
                //title={<div><Typography><b>Patient Detail</b></Typography></div>}
            >
                {props.barcodeFlag === 'delete' ?
                    <div className="px-4">
                        {`Are you sure you want to delete barcode  "${props.currentBarCode}"?`}
                    </div>
                    :
                    <div className="px-4">
                        <div className={clsx("w-100 p-2 d-flex flex-column", mobileWidth && "w-100")}>
                            <SiteLabelTextField
                                required={true}
                                label="Barcode"
                                value={barcode}
                                onChange={(e) => onChange(e.target.value)}
                                placeholder="Please Enter Barcode" />
                            {
                                showErrors && <span className='ml-1 mt-1 text-danger font-weight-bold'>{showErrors}</span>
                            }
                        </div>
                    </div>
                }
            </ContentDialog>
        </div>
    )
}

export default BarcodeDialog
