import { BulkAssignSameDayTestKitPage } from './BulkAssignSameDayTestKitPage';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { processSameDayOrder } from 'view.updater/actions/order.actions';

const mapDispatchToProps = (dispatch) => ({
    processSameDayOrder: (data,bulkUpload) => dispatch(processSameDayOrder(data,bulkUpload))
});

const mapStateToProps = createStructuredSelector({
    
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(BulkAssignSameDayTestKitPage);