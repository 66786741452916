import React, { useEffect, useState } from 'react'
import { Row, Column, Body, Button, Spacer, CheckboxInput, BackButton } from '../../../components'
import { useModal, useOrdering } from '../../../utils'
import { ChangeTestKitModal } from './ChangeTestKitModal'
import tickIcon from '../../../assets/images/tick-icon.svg'

const DetailsRow = ({ label, value }) => {
	return (
		<Row desktopSize={5} tabletSize={10}>
			<Row desktopSize={2} tabletSize={3}>
				<Body>{label}:</Body>
			</Row>
			<Row desktopSize={3} tabletSize={7}>
				<Body>{value}</Body>
			</Row>
		</Row>
	)
}

const OrderRow = ({ label, noLabel, children }) => {
	return (
		<Row desktopSize={4} tabletSize={5} margin={0}>
			<Column desktopSize={2} tabletSize={3} marginTop={0} marginRight={0} padding="none">
				{noLabel ? null : <Body>{label}:</Body>}
			</Column>
			<Column
				desktopSize={2}
				tabletSize={2}
				marginTop={0}
				padding="none"
				style={{ maxWidth: '150px' }}
			>
				<Body>{children}</Body>
			</Column>
		</Row>
	)
}

const toDdMmYyyy = (date) => {
	if (!date) {
		return ''
	}
	const year = date.substring(0, 4)
	const month = date.substring(4, 6)
	const day = date.substring(6, 8)
	return `${day}/${month}/${year}`
}

const OrderConfirmation = ({
	confirmed,
	patientDetails,
	organisationId,
	onBackClick,
	onNext,
	onEditPatientDetailsClick,
	customer,
}) => {
	const [greeting] = confirmed ? ['Thank you'] : ['Confirm details and test completion']
	const {
		firstName,
		lastName,
		fullName,
		email,
		telephone,
		jobNumber,
		deliveryDate,
		deliverBy,
		deliveryWindow,
		driverNotes,
		addressLine1,
		addressLine2,
		city,
		postcode,
		orderType,
	} = patientDetails
	const [errorMessage, setErrorMessage] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const { submitOrder, createSelfTestOrder } = useOrdering()

	const performOnNext = () => {
		// onNext()
	}

	// setIsSubmittingOrder(true)

	const performSubmitOrder = () => {
		console.log('submitting order::')
		const orderDraft = createSelfTestOrder([patientDetails], orderType === 'courier' ? true : false)
		if (orderType !== 'courier') {
			const address = {
				recipient: patientDetails.firstName + ' ' + patientDetails.lastName,
				addressLine1: patientDetails.addressLine1,
				addressLine2: patientDetails.addressLine2,
				city: patientDetails.city,
				postcode: patientDetails.postcode,
			}
			orderDraft.address = address
		}
		const patients = orderDraft.patients.map((patient) => ({
			...patient,
		}))

		// const courier = params.get('courier')

		const order = {
			...orderDraft,
			organisationId,
			patients,
			createdBy: customer.uid,
			email: customer.email,
		}
		order.orderType = orderType
		setIsLoading(true)

		submitOrder(order)
			.then((result) => {
				console.log('submitting to firebase: ', order)
				return order.patients.map((patient) => ({
					orderUid: order.orderUid,
					patient,
				}))
			})
			.then((results) => {
				console.log('results: ', results)
				setIsLoading(false)
				onNext()
				// history.push('/customer/order-history')
			})
			.catch((error) => {
				console.error('submitOrder', 'error:', error)
				setErrorMessage(error && error.message)
				setIsLoading(false)
			})
	}

	const handleOnNextClick = confirmed ? performOnNext : performSubmitOrder

	return (
		<>
			<Row desktopSize={8}>
				<Column desktopSize={6} tabletSize={12}>
					<h3>{greeting}</h3>
					{confirmed ? (
						<>
							<Body>The order has been placed.</Body>
							<Spacer spacing="sm" />
						</>
					) : null}
					<Column bordered desktopSize={6} tabletSize={12}>
						<Spacer spacing="xs" />
						<Row
							desktopSize={5}
							tabletSize={10}
							spaced
							style={{
								width: '100%',
								position: 'relative',
							}}
						>
							<h5>
								Organisation: {organisationId}
								<br />
								Order type: {orderType === 'self-test' ? 'Mail' : orderType === 'same-day-test' ? "Same day test" : 'Courier'}
								<br />
								<br />
								Patient details
							</h5>
							{confirmed ? (
								<img
									src={tickIcon}
									alt="Completed Icon"
									style={{
										position: 'relative',
										right: '0',
									}}
								/>
							) : (
								<Button tiny chromeless onClick={onEditPatientDetailsClick}>
									Edit
								</Button>
							)}
						</Row>
						{orderType === 'courier' && (
							<>
								<Row desktopSize={5} tabletSize={10}>
									<Row desktopSize={2} tabletSize={3}>
										<Body>Delivery Date:</Body>
									</Row>
									<Row desktopSize={3} tabletSize={7}>
										<Body>
											<b>{deliveryDate}</b>
										</Body>
									</Row>
								</Row>
								<DetailsRow label="Contact details" value={`${telephone} | ${email}`} />
							</>
						)}
						<OrderRow label="Dispatch to">
							<b>
								{
									orderType === "same-day-test" ? 
									`${fullName}` : `${firstName} ${lastName}`
								}
							</b>
						</OrderRow>
						<OrderRow noLabel>{addressLine1}</OrderRow>
						<OrderRow noLabel>{addressLine2}</OrderRow>
						<OrderRow noLabel>
							{city} {postcode}
						</OrderRow>
						<OrderRow noLabel>United Kingdom</OrderRow>

						{((driverNotes && driverNotes.length > 0) ||
							(deliveryWindow && deliveryWindow.length > 0) ||
							(deliverBy && deliverBy.length > 0)) && (
							<>
								<br />
								<OrderRow label="Delivery instructions">
									{deliverBy && deliverBy.length > 0 && 'Before ' + deliverBy}
									<br />
									{deliveryWindow && deliveryWindow.length > 0 && 'Window: ' + deliveryWindow}
									<br />
									{driverNotes && driverNotes.length > 0 && 'Driver Notes: ' + driverNotes}
								</OrderRow>
							</>
						)}

						{/* <DetailsRow
							label="Address"
							value={`${addressLine1} ${addressLine2} ${city} ${postcode}`}
						/> */}
					</Column>

					<Spacer spacing="s" />
					{confirmed && (
						<Row contained>
							<Button primary href="/customer">
								Go to Home
							</Button>
							<Spacer spacing="s" />
							<Button primary href="/customer/order-history">
								Go to order history
							</Button>
						</Row>
					)}

					{!confirmed && (
						<>
							{/* <CheckboxInput
								value={testKitCompletedSuccessfuly}
								setter={setTestKitCompletedSuccessfuly}
							>
								<Body>
									I confirm that the peelable label from the test requisition form has been attached
									to the sample tube.
								</Body>
							</CheckboxInput>
							<Spacer spacing="s" /> */}

							<Row contained>
								<Button primary onClick={handleOnNextClick} loading={isLoading}>
									Place order
								</Button>
							</Row>
						</>
					)}
				</Column>
			</Row>
		</>
	)
}

export { OrderConfirmation }
