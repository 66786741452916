import './kits.scss';
import React from 'react'
import { Paper } from "@material-ui/core";
import { IsMobileWidth } from "components/common/util/util";
import Pagination from '@material-ui/lab/Pagination';
import { format24HrDateTime, formatSBSSwabDate } from 'components/common/util/util';
import clsx from 'clsx';
import { Skeleton } from "@material-ui/lab";
import { Rating } from '@material-ui/lab'
import { Tooltip } from '@material-ui/core'

const KitsList = (props) => {

    const mobileWidth = IsMobileWidth();
    const { kits, execStatusGetKits } = props;

    const handleDownloadCertificate = (url) => {
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = url
        anchor.target = "_blank"
        document.body.appendChild(anchor)
        anchor.click()
    }
    let totalCount = 0;
    if (kits && kits.get('totalCount')) {
        totalCount = Math.ceil(kits.get('totalCount') / kits.get('limit'))
    }
    const getResultClassName = (resultStatus) => {
        switch (resultStatus) {
            case "Negative":
                return "result-negative"
            case "Positive":
                return "result-positive"
            case "Inconclusive":
                return "result-inconclusive"
            default:
                return "result-default"
        }
    }
    const getStatusDescription = (status) => {
        switch (status) {
            case "CONFIRMATION_PENDING":
                return "Confirmation Pending"
            case "CONFIRMATION_LINK_SENT":
                return "Confirmation Link Sent"
            case "INDIVIDUAL_CONFIRMED_TEST_DETAILS":
                return "Test Details Confirmed"
            case "CANCELLED":
                return "Cancelled"
            default:
                return status
        }
    }
    return (
        <div id="kit-list">
            <div className="w-100 d-flex justify-content-start">
                {!(mobileWidth) ? (
                    <div className="d-flex flex-column w-100">
                        <div className="pt-3">
                            <div className="w-100 d-flex pb-2 pt-2 p-1">
                                <div className="w-5 d-flex justify-content-start align-items-center">
                                    <b>No</b>
                                </div>
                                <div className="w-5 pl-2 d-flex justify-content-start align-items-center">
                                    <b>Barcode</b>
                                </div>
                                <div className="w-20 pl-2 d-flex justify-content-start align-items-center">
                                    <b>Full Name</b>
                                </div>
                                <div className="w-20 pl-2 d-flex justify-content-start align-items-center">
                                    <b>Email Address</b>
                                </div>
                                <div className="w-15 pl-2 d-flex justify-content-start align-items-center">
                                    <b>Date Tested</b>
                                </div>
                                <div className="w-15 pl-2 d-flex justify-content-start align-items-center">
                                    <b>Last Updated</b>
                                </div>
                                <div className="w-15 pl-2 d-flex justify-content-center align-items-center">
                                    <b>Status</b>
                                </div>
                                <div className="w-15 pl-2 d-flex justify-content-center align-items-center">
                                    <b>Result</b>
                                </div>
                                <div className="w-5 pl-2 d-flex justify-content-start align-items-center">
                                    <b> Certificate </b>
                                </div>
                            </div>
                        </div>
                        {
                            kits && kits.get('kitsArray') && kits.get('kitsArray').map((kit, index) => {
                                return <Paper key={index} className={clsx("w-100 paper-root d-flex py-4 px-2 border-radius-10", index % 2 === 0 && "bg-light")} elevation={1}>
                                    <div className="w-5 d-flex justify-content-center align-items-center border-right">
                                        <div className="">
                                            {/* {
                                                kit.get('partnerLab') === "catalyst" ?
                                                    <Tooltip title="Same Day Test">
                                                        <Rating max={1} value={1} size="large" />
                                                    </Tooltip> : null
                                            } */}
                                        </div>
                                        <div className="">
                                            {index + 1 + (kits.get('pageIndex') * kits.get('limit'))}
                                        </div>
                                    </div>
                                    <div className="w-5 pl-2 d-flex justify-content-start align-items-center border-right">
                                        {kit.get("barcode")}
                                    </div>
                                    <div className="w-20 pl-2  d-flex justify-content-start align-items-center border-right">
                                        <b>{kit.get("fullName") ? kit.get("fullName") : `${kit.get('firstName')} ${kit.get('lastName')}`}</b>
                                    </div>
                                    <div style={{ wordBreak: 'break-all' }} className="w-20 d-flex  justify-content-start align-items-center pl-2  border-right">
                                        {kit.get("email")}
                                    </div>
                                    <div className="w-15 d-flex pl-2 justify-content-start align-items-center border-right">
                                        {kit.get("catalyst_sampleCollectAt") ?
                                            format24HrDateTime(kit.get("catalyst_sampleCollectAt")) :
                                            kit.get("dateOfSwab") ? formatSBSSwabDate(kit.get("dateOfSwab"), kit.get("timeOfSwab")) : ''}
                                    </div>
                                    <div className="w-15 d-flex pl-2  justify-content-start align-items-center border-right">
                                        {
                                            kit.get('partnerLab') !== "catalyst" ?
                                                format24HrDateTime(kit.get("updatedAt")) :
                                                kit.get("catalyst_resultReportedAt") ?
                                                    format24HrDateTime(kit.get("catalyst_resultReportedAt")) :
                                                    format24HrDateTime(kit.get("updatedAt"))

                                        }
                                    </div>
                                    <div className="w-15 d-flex pl-2 font-weight-bold justify-content-center align-items-center border-right">
                                        <b >
                                            {kit.get("status") ? getStatusDescription(kit.get("status")) : ""}
                                        </b>
                                    </div>
                                    <div className="w-15 d-flex pl-2 font-weight-bold justify-content-center align-items-center border-right">
                                        <b className={getResultClassName(kit.get('resultStatus'))}>
                                            {kit.get("resultStatus") ? kit.get("resultStatus") : kit.get("status")}
                                        </b>
                                    </div>
                                    <div className="w-5 d-flex pl-2 justify-content-center cursor-pointer align-items-center border-right">
                                        {
                                            kit.get("certificateUrl") ?
                                                <div onClick={() => { handleDownloadCertificate(kit.get("certificateUrl")) }} className="text-primary cursor-pointer">
                                                    <img style={{
                                                        height: '20px',
                                                        width: '20px'
                                                    }} src={`${process.env.PUBLIC_URL}/icons/pdf_download.svg`} />
                                                </div> : null
                                        }
                                    </div>
                                </Paper>
                            })
                        }
                        {
                            totalCount > 1 ?
                                <div className="d-flex justify-content-center pt-4">
                                    <Pagination
                                        page={kits.get('pageIndex') + 1}
                                        onChange={props.onPageChange}
                                        count={totalCount} color="primary" />
                                </div> : null
                        }
                    </div>
                ) : (
                    <div >
                        {
                            kits && kits.get('kitsArray') && kits.get('kitsArray').map((kit, index) => {
                                return <div key={index} className="d-flex flex-wrap justify-content-center w-100 mt-2 ml-2">
                                    <Paper elevation={1} className="w-100 paper-root d-flex flex-column p-3 mt-2">
                                        {/* <div className="d-flex w-100 justify-content-between">
                                            {
                                                kit.get('partnerLab') === "catalyst" ?
                                                    <Tooltip title="Same Day Test">
                                                        <Rating max={1} value={1} size="large" />
                                                    </Tooltip> : null
                                            }
                                        </div> */}
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>{kit.get("fullName") ? kit.get("fullName") : `${kit.get('firstName')} ${kit.get('lastName')}`} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div> {kit.get("email")} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>  {`Date tested ${kit.get("catalyst_sampleCollectAt") ?
                                                format24HrDateTime(kit.get("catalyst_sampleCollectAt")) :
                                                kit.get("dateOfSwab") ? formatSBSSwabDate(kit.get("dateOfSwab"), kit.get("timeOfSwab")) : ''}`} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {
                                                kit.get('partnerLab') !== "catalyst" ?
                                                    `Updated At ${format24HrDateTime(kit.get("updatedAt"))}` :
                                                    kit.get("catalyst_resultReportedAt") ?
                                                        `Updated At ${format24HrDateTime(kit.get("catalyst_resultReportedAt"))}` :
                                                        `Updated At ${format24HrDateTime(kit.get("updatedAt"))}`
                                            }
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <b>
                                                {kit.get("status") ? getStatusDescription(kit.get("status")) : ""}
                                            </b>
                                        </div>

                                        <div className="d-flex w-100 justify-content-between">
                                            <b className={getResultClassName(kit.get('resultStatus'))}>
                                                {kit.get("resultStatus") ? kit.get("resultStatus") : kit.get("status")}
                                            </b>
                                        </div>

                                        <div className="d-flex cursor-pointer w-100 justify-content-center">
                                            {
                                                kit.get("certificateUrl") ?
                                                    <div onClick={() => { handleDownloadCertificate(kit.get("certificateUrl")) }} className="text-primary cursor-pointer font-weight-bolder">
                                                        <img style={{
                                                            height: '20px',
                                                            width: '20px'
                                                        }} src={`${process.env.PUBLIC_URL}/icons/pdf_download.svg`} />
                                                    </div> : null
                                            }
                                        </div>
                                    </Paper>
                                </div>
                            })
                        }
                        {
                            totalCount > 1 ?
                                <div className="d-flex justify-content-center pt-3">
                                    <Pagination
                                        page={kits.get('pageIndex') + 1}
                                        onChange={props.onPageChange}
                                        count={totalCount} color="primary" />
                                </div> : null
                        }
                    </div>
                )}
            </div>
            {
                execStatusGetKits && execStatusGetKits.status === "PENDING" ?
                    <div>
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                    </div> : null
            }
            {
                kits && kits.get('kitsArray') && kits.get('kitsArray').size == 0 ?
                    <Paper className="paper-root p-4 text-danger d-flex justify-content-center">
                        No Data Found
                    </Paper> : null
            }
            {execStatusGetKits && execStatusGetKits.status === "ERROR" ? (
                <Paper className="paper-root">
                    <div className="text-danger d-flex justify-content-center font-italic pt-2 pb-2">
                        <b>
                            {" "}
                            {execStatusGetKits.errorDetail && typeof execStatusGetKits.errorDetail === 'object'
                                ? execStatusGetKits.errorDetail.get("description")
                                : "Something isn’t working as it should, please try again"}
                        </b>
                    </div>
                </Paper>
            ) : null}
        </div>
    )
}

export default KitsList