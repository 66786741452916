import React from 'react'

const LoadingAnimation = ({ orange }) => {
	const colorClass = [orange === true ? 'orange' : ''].join(' ').trim()
	const className = `lds-heart ${colorClass}`
	return (
		<div className={className}>
			<div></div>
		</div>
	)
}

export { LoadingAnimation }
