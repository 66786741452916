import React, { useState, useRef, useEffect } from 'react'
import Webcam from 'react-webcam'
import * as ZXing from '@zxing/library'
import { Row, Column } from '../../../components'

const videoConstraints = {
	width: 720,
	height: 720,
	facingMode: 'environment',
	advanced: [{ width: 720, height: 720 }, { aspectRatio: 1 }],
}

const codeReader = new ZXing.BrowserDatamatrixCodeReader()

const BarcodeScanModal = ({ onBarcodeDetected }) => {
	const webcamRef = useRef(null)
	const [scanningIntervalId] = useState(-1)

	useEffect(() => {
		const timerId = setInterval(() => {
			if (webcamRef && webcamRef.current) {
				const screenshot = webcamRef.current.getScreenshot()

				if (screenshot) {
					const imgElement = document.createElement('img')
					imgElement.setAttribute('src', screenshot)

					codeReader
						.decodeFromImageElement(imgElement)
						.then((result) => {
							const { text } = result
							onBarcodeDetected({ text })
							clearInterval(scanningIntervalId)
						})
						.catch((error) => {})
				}
			}
		}, 100)

		setTimeout(() => clearInterval(timerId), 60000)

		return () => clearInterval(timerId)
	})

	return (
		<>
			<Column desktopSize={8} tabletSize={10} centered contained>
				<Row desktopSize={7} tabletSize={9} contained>
					{/* <div id="webcam-window">
						<Body>Please scan the test kit ID on the form</Body>
					</div> */}
					<Webcam
						ref={webcamRef}
						audio={false}
						width={300}
						height={300}
						videoConstraints={videoConstraints}
						screenshotFormat="image/jpeg"
					/>
				</Row>
				{/* <Row desktopSize={7} tabletSize={9} contained>
				<Body>Please align the 2D barcode on the form so it fits in the camera view above.
The 2D barcode looks like this:</Body>
				</Row> */}
			</Column>
		</>
	)
}

export { BarcodeScanModal }
