import ConfirmOrders from './confirm.orders';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getAdminKits, updateAdminKitStatus, getCourierStatus,
    updateKit, updatePatientDetail,updateTestKitDataCall } from '../../view.updater/actions/dashboard.actions';
import { makeSelectAdminKits, makeSelectKitsCourierStatus } from '../../view.updater/selectors/dashboard.selectors';
import { selectOrganisations } from '../../view.updater/selectors/app.selectors';
import { getEvents, scheduleTest, addLastMinuteTest, resetAddLastMinuteTest,resetEventList } from '../../view.updater/actions/order.actions';
import { makeSelectEvents, makeSelectLastMinuteTestLink } from 'view.updater/selectors/order.selectors';

const mapDispatchToProps = (dispatch) => ({
    getAdminKits: (data) => dispatch(getAdminKits(data)),
    updateAdminKitStatus: (data) => dispatch(updateAdminKitStatus(data)),
    updateKit: (data,isLocal) => dispatch(updateKit(data,isLocal)),
    updatePatientDetail: (data) => dispatch(updatePatientDetail(data)),
    getEvents: (data) => dispatch(getEvents(data)),
    scheduleTest: (data) => dispatch(scheduleTest(data)),
    addLastMinuteTest: (data) => dispatch(addLastMinuteTest(data)),
    getCourierStatus: (data) => dispatch(getCourierStatus(data)),
    resetEventList: (data) => dispatch(resetEventList(data)),
    updateTestKitDataCall: (data) => dispatch(updateTestKitDataCall(data)),
    resetAddLastMinuteTest: (data) => dispatch(resetAddLastMinuteTest(data))
});

const mapStateToProps = createStructuredSelector({
    kits : makeSelectAdminKits(),
    organisations : selectOrganisations(),
    events : makeSelectEvents(),
    kitsCourierStatus : makeSelectKitsCourierStatus(),
    lastMinuteTestLinkSent: makeSelectLastMinuteTestLink()
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ConfirmOrders);