
import { addUpdateAppLoadersStatus, SET_APP_PROPERTIES, clearAppLoaderStatus } from 'view.updater/actions/app.actions';
import { put, takeEvery, select } from 'redux-saga/effects';
import { cancelRequest } from '../actions/app.actions';
import { RESET_AUTH, LOGOUT } from '../actions/auth.actions';


export function* processBeforeMiddleware(action) {
    if (action.url || action.firebaseFunction || action.firebaseQuery) {
        yield put(addUpdateAppLoadersStatus(action.type, 'PENDING'));
    }
    if(action.type === RESET_AUTH || action.type === LOGOUT) {
      
    }
    if (action.type === '@@router/LOCATION_CHANGE') { //ROUTE_CHANGES // ROUTE_CHANGE
      
    }
    if (action.type && action.type.includes("RESET")) {
        if (action.payload && action.payload.resetActionNames) {
            for (var i = 0; i < action.payload.resetActionNames.length; i++) {
                yield put(cancelRequest({
                    type : action.payload.resetActionNames[i]
                }));
                yield put(addUpdateAppLoadersStatus(action.payload.resetActionNames[i], 'REQUEST_CANCELLED', `${action.type}_ERROR_ID`, { description: "Connection Failed" }));
            }
            yield put(clearAppLoaderStatus(action.payload.resetActionNames));
        }
    }
    switch (action.type) {
        case SET_APP_PROPERTIES: {
            if (action.data && action.data.internetConnected === true) {
                // Here need to dispatch those actions that are failed due to lost of internet connection
            }
        }
            break;
        default:
            return;
    }
}
export default function* allbeforeMiddleware() {
    yield takeEvery('*', processBeforeMiddleware);
}
