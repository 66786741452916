const locale = 'en-GB'
const currencyFormatter = new Intl.NumberFormat(locale, { style: 'currency', currency: 'GBP' })

const fullDateFormatter = new Intl.DateTimeFormat(locale, {
	year: 'numeric',
	month: 'long',
	day: 'numeric',
})

const dayAndMonthDateFormatter = new Intl.DateTimeFormat(locale, {
	month: 'long',
	day: 'numeric',
})

const timeOnlyFormatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' })

export const toHyphenatedDateString = (value) =>
	`${value.substring(0, 4)}-${value.substring(4, 6)}-${value.substring(6, 8)}`
export const formatDate = (value) => fullDateFormatter.format(value)
export const formatOnlyTime = (value) => timeOnlyFormatter.format(value)
export const formatShortDate = (value) => dayAndMonthDateFormatter.format(value)
export const formatCurrency = (value) => currencyFormatter.format(value)
