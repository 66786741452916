import React, { useContext, useEffect, useState } from 'react'
import { withAuthentication } from "../../utils";
import KitsList from "./components/kits.list";
import { checkExecutionStatus } from 'components/common/util/util';
import { AppContext } from "../../app.container";
import { Breadcrumps } from '../../components';

const ConfirmOrders = withAuthentication((props) => {
    const { kits, kitsCourierStatus } = props;
    const [state, setState] = useState({
        selectedKitStatus: {
            name: "Confirmation Pending",
            value: 'CONFIRMATION_PENDING'
        },
        selectedOrg: {
            name: 'All Organisations',
            value: ''
        },
        selectedPartnerLab: {
            name: 'All Labs',
            value: ''
        },
        selectedEvent: {
            name: 'All Events',
            value: ''
        }
    })
    const context = useContext(AppContext);
    const execStatusGetKits = checkExecutionStatus(context, "GET_ADMIN_KITS");
    const execStatusGetEvents = checkExecutionStatus(context, "GET_EVENTS");
    const execStatusGetOrganisations = checkExecutionStatus(context, "GET_ALL_ORGANISATIONS");
    const execStatusUpdatePatientDetail = checkExecutionStatus(context, "UPDATE_PATIENT_DETAIL");
    const execStatusCancelTest = checkExecutionStatus(context, "UPDATE_TEST_KIT_CALL");
    const execStatusGetCourierStatus = checkExecutionStatus(context, "GET_COURIER_STATUS");
    const execStatusAddLastMinuteTest = checkExecutionStatus(context, "ADD_LAST_MINUTE_TEST");
    const execStatusUpdateKit = checkExecutionStatus(context, "UPDATE_KIT");

    useEffect(() => {
        props.getAdminKits({
            pageIndex: 0,
            kitStatus: 'CONFIRMATION_PENDING',
        });
        // props.getCourierStatus({
        //     taskIds : ['https://gsmtasks.com/api/tasks/tasks/55']
        // })
    }, [])
    const onKitStatusChange = (value, data) => {
        setState({ ...state, selectedKitStatus: data })
        props.getAdminKits({
            pageIndex: 0,
            eventId: state.selectedEvent && state.selectedEvent.value,
            organisationId: state.selectedOrg && state.selectedOrg.value,
            kitStatus: value,
            partnerLab: state.selectedPartnerLab && state.selectedPartnerLab.value
        })
    }
    const onPartnerLabChange = (value, data) => {
        setState({ ...state, selectedPartnerLab: data })
        props.getAdminKits({
            pageIndex: 0,
            eventId: state.selectedEvent && state.selectedEvent.value,
            organisationId: state.selectedOrg && state.selectedOrg.value,
            kitStatus: state.selectedKitStatus && state.selectedKitStatus.value,
            partnerLab: value
        })
    }
    const onRefresh = () => {
        props.getAdminKits({
            pageIndex: 0,
            eventId: state.selectedEvent && state.selectedEvent.value,
            organisationId: state.selectedOrg && state.selectedOrg.value,
            kitStatus: state.selectedKitStatus && state.selectedKitStatus.value,
            partnerLab: state.selectedPartnerLab && state.selectedPartnerLab.value
        })
    }
    const onOrganisationChange = (value, data) => {
        setState({
            ...state, selectedOrg: data,
            selectedEvent: {
                name: 'All',
                value: ''
            }
        })
        props.getEvents({
            orgId: value
        });
        props.getAdminKits({
            pageIndex: 0,
            organisationId: value,
            partnerLab: state.selectedPartnerLab && state.selectedPartnerLab.value,
            //eventId: value && state.selectedEvent && state.selectedEvent.value,
            kitStatus: state.selectedKitStatus.value
        })
    }
    const onEventFilterSelect = (value, data) => {
        setState({ ...state, selectedEvent: data })
        props.getAdminKits({
            pageIndex: 0,
            organisationId: state.selectedOrg && state.selectedOrg.value,
            kitStatus: state.selectedKitStatus.value,
            partnerLab: state.selectedPartnerLab && state.selectedPartnerLab.value,
            eventId: value
        })
    }
    const onPageChange = (event, pageNumber) => {
        window.scrollTo(0, 0);
        if (state.selectedKitStatus.value === "CONFIRMATION_PENDING") {
            if (kits && kits.get('kitsArray') && kits.get('kitsArray') && kits.get('kitsArray').findIndex((kit) => kit.get('status') === "CONFIRMATION_LINK_SENT") > -1) {
                props.getAdminKits({
                    pageIndex: pageNumber - 2,
                    kitStatus: state.selectedKitStatus.value,
                    partnerLab: state.selectedPartnerLab && state.selectedPartnerLab.value,
                    organisationId: state.selectedOrg && state.selectedOrg.value,
                    eventId: state.selectedEvent && state.selectedEvent.value
                })
            }
            else
                props.getAdminKits({
                    pageIndex: pageNumber - 1,
                    kitStatus: state.selectedKitStatus.value,
                    partnerLab: state.selectedPartnerLab && state.selectedPartnerLab.value,
                    organisationId: state.selectedOrg && state.selectedOrg.value,
                    eventId: state.selectedEvent && state.selectedEvent.value
                })
        }
        else
            props.getAdminKits({
                pageIndex: pageNumber - 1,
                kitStatus: state.selectedKitStatus.value,
                partnerLab: state.selectedPartnerLab && state.selectedPartnerLab.value,
                organisationId: state.selectedOrg && state.selectedOrg.value,
                eventId: state.selectedEvent && state.selectedEvent.value
            })
    }
    return (
        <div className="d-flex flex-column w-100 ml-5 mr-5 pr-5 pl-5">
            <div>
                <Breadcrumps />
            </div>
            <div>
                <h3>
                    Confirm Tests
                </h3>
            </div>
            {/* <div className="w-100 d-flex justify-content-start">
            <KitFilters
                execStatusGetKits={execStatusGetKits}
                onSearchEnter={onSearchEnter}
                onSearchChange={onSearchChange}
                onKitStatusChange={onKitStatusChange}
                onResultStatusChange={onResultStatusChange}
                {...props} {...state}
            />
        </div> */}
            <div>
                <KitsList
                    onPageChange={onPageChange}
                    execStatusCancelTest={execStatusCancelTest}
                    updatedState={state?.selectedKitStatus?.value}
                    execStatusUpdatePatientDetail={execStatusUpdatePatientDetail}
                    onKitStatusChange={onKitStatusChange} {...state}
                    execStatusUpdateKit={execStatusUpdateKit}
                    execStatusGetEvents={execStatusGetEvents}
                    onOrganisationChange={onOrganisationChange}
                    onEventFilterSelect={onEventFilterSelect}
                    onPartnerLabChange={onPartnerLabChange}
                    execStatusGetCourierStatus={execStatusGetCourierStatus}
                    execStatusAddLastMinuteTest={execStatusAddLastMinuteTest}
                    execStatusGetOrganisations={execStatusGetOrganisations}
                    execStatusGetKits={execStatusGetKits}
                    kitsCourierStatus={kitsCourierStatus}
                    onRefresh={onRefresh}
                    {...props} />
            </div>
        </div>
    )
})

export default ConfirmOrders