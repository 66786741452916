import React, { useState, useEffect } from 'react'
import { useOrdering, drawInvoice } from '../../utils'
import { Button, Column, Row, Body, LoadingAnimation, Spacer, Breadcrumps } from '../../components'
import { useHistory } from 'react-router-dom'
import { formatOnlyTime } from '../../utils/formatters'

const OrderRow = ({ label, children }) => {
	return (
		<Row size={4} marginTop={0}>
			<Row size={2} marginTop={0}>
				<Body>{label}:</Body>
			</Row>
			<Row size={2} marginTop={0}>
				<Body>{children}</Body>
			</Row>
		</Row>
	)
}

const OrderItem = ({ order }) => {
	const [buttonDisabled, setButtonDisabled] = useState(false)
	const [buttonText, setButtonText] = useState('Download')
	const history = useHistory()

	const {
		confirmationDisplayText: {
			numberOfKitsText,
			orderDateText,
			orderTypeText,
			estimatedDeliveryDateText,
		},
		orderUid,
		orderType,
		address,
		createdAt
	} = order

	let dispatchedTo = ''
	if (address) {
		dispatchedTo =
			orderType === 'self-test' ? (
				'Multiple addresses'
			) : (
				<>
					{address.recipient}
					<br />
					{address.addressLine1}
					<br />
					{address.addressLine && (
						<>
							{address.addressLine2}
							<br />
						</>
					)}
					{address.city}
					<br />
					{address.postcode}
				</>
			)
	}
	const onReportDownload = async () => {
		setButtonDisabled(true)
		setButtonText('Generate ...')
		await drawInvoice(order)

		setButtonDisabled(false)
		setButtonText('Download')
	}

	const onCourierView = async () => {
		localStorage.setItem('orderFromHx', JSON.stringify(order))
		history.push('/customer/order-courier')
	}

	return (
		<>
			<Column bordered size={8}>
				<Row size={12}>
					<Column size={3}>
						<h5>Order Date</h5>
						<Body>{orderDateText}</Body>
					</Column>
					<Column size={3}>
						<h5>Order Time</h5>
						<Body>{formatOnlyTime(new Date(createdAt))}</Body>
					</Column>
					<Column size={3}>
						<h5>Order status</h5>
						<Body>{order.status}</Body>
					</Column>
				</Row>
				<Spacer spacing="m" />
				<Row size={12}>
					<Column size={3}>
						<h5>Order number</h5>
						<Body>{orderUid}</Body>
					</Column>
					<Column size={3}>
						<h5>Order type</h5>
						<Body>{orderTypeText}</Body>
					</Column>
					<Column size={3}>
						<h5>Number of kits</h5>
						<Body>{numberOfKitsText}</Body>
					</Column>
				</Row>
				<Spacer spacing="m" />
				{dispatchedTo && (
					<>
						<Column size={4} marginTop={2}>
							<OrderRow label="Dispatched to">{dispatchedTo}</OrderRow>
						</Column>
						<Spacer spacing="s" />
					</>
				)}

				<Row size={8} contained>
					{/* <Button secondary onClick={() => null}>
					Download invoice

				</Button> */}
					{/* <Button disabled={buttonDisabled} onClick={onReportDownload}>{buttonText}</Button> */}
					<Spacer padding={2} />
					{ (order.orderType === 'courier' || order.orderType === 'same-day-test') && (
						<>
							<Button primary onClick={onCourierView}>
								View courier status
							</Button>
							<Spacer padding={2} />
						</>
					)}

					<Button
						secondary
						onClick={() => {
							window.location.href = `mailto:enquiries@beaconsecure.co.uk?subject=Enquiry about order number:${orderUid}`
						}}
					>
						Contact us about this order
					</Button>
				</Row>
			</Column>
		</>
	)
}

const OrderHistoryPage = (props) => {
	const [orders, setOrders] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const { queryOrders } = useOrdering()

	useEffect(() => {
		if (orders.length === 0) {
			setIsLoading(true)

			queryOrders()
				.then((result) => {
					setIsLoading(false)
					setOrders(result.data)
				})
				.catch((error) => {
					setIsLoading(false)
				})
		}
	}, [orders.length, queryOrders])

	return (
		<Column size={8}>
			<Spacer spacing="m" />
			<Breadcrumps />
			<Spacer spacing="m" />
			<h3>Order history</h3>
			<Spacer spacing="s" />
			<Column size={8}>
				{isLoading ? (
					<Column size={8} centered>
						<LoadingAnimation orange />
					</Column>
				) : null}
				{orders.map((
					order,
					index //+order.orderUid
				) => (
					<span key={index + '-OrderItem'}>
						<OrderItem order={order} />
						<Spacer spacing="s" />
					</span>
				))}
			</Column>
		</Column>
	)
}

export { OrderHistoryPage }
