import { put, select } from 'redux-saga/effects';
import { addUpdateAppLoadersStatus } from 'view.updater/actions/app.actions';
import { push } from 'connected-react-router';
import { LOGIN, LOGOUT, login } from '../actions/auth.actions';
import { CREATE_EVENT, getEvents, PROCESS_SAME_DAY_ORDER, resetEventList, ADD_LAST_MINUTE_TEST, addLastMinuteTest } from '../actions/order.actions';
import { getCourierStatus, GET_ADMIN_KITS } from '../actions/dashboard.actions';

export default function* allAfterMiddleware(action) {
    yield put(addUpdateAppLoadersStatus(action.baseType, 'DONE', action.errorId));
    yield put(addUpdateAppLoadersStatus(action.baseType, 'FINNISH', action.errorId));
    switch (action.baseType) {
        case LOGIN: // LOGIN_CHANGES
            yield put(push('/'));
            break;
        case LOGOUT:
            yield put(push('/login'));
            break;
        case CREATE_EVENT:
            yield put(resetEventList())
            yield put(getEvents());
            break;
        case GET_ADMIN_KITS:
            if (action.data.kitStatus === "Assigned" && action.response && action.response.kitsArray && action.response.kitsArray.length > 0) {
                let taskIds = [];
                for (let kit of action.response.kitsArray) {
                    if (kit.taskId) {
                        taskIds.push(`https://gsmtasks.com/api/tasks/tasks/${kit.taskId}/`)
                    }
                }
                // yield put(getCourierStatus({
                //     taskIds: taskIds
                // }));
            }
            break;
        case ADD_LAST_MINUTE_TEST:
            if (action.payload.sendConfirmationLink === false && action.response && action.response.length > 0) {
                yield put(addLastMinuteTest({
                    kit: {
                        ...action.response[0],
                        id: action.response[0].id,
                        eventName: action.response[0].eventName,
                        email: action.response[0].email?.trim()?.toLowerCase(),
                        orderUid: action.response[0].orderUid,
                        status: "CONFIRMATION_LINK_SENT"
                    }
                },true))
            }
            break;
        case PROCESS_SAME_DAY_ORDER:
            if (action.payload && action.payload.bulkUplaod === true) {

            }
            else
                yield put(push('/customer/order-history'));
        default:
            return;
    }
}