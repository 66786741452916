import React from 'react'
import { Column, Row, Body } from '../../components'

const OrderRow = ({ label, noLabel, children }) => {
	return (
		<Row desktopSize={4} tabletSize={5} margin={0}>
			<Column desktopSize={2} tabletSize={3} marginTop={0} marginRight={0} padding="none">
				{noLabel ? null : <Body small>{label}:</Body>}
			</Column>
			<Column
				desktopSize={2}
				tabletSize={2}
				marginTop={0}
				padding="none"
				style={{ maxWidth: '150px' }}
			>
				<Body small>{children}</Body>
			</Column>
		</Row>
	)
}

const OrderDisplay = ({ order }) => {
	const {
		fullName,
		email,
		eventName,
		dateOfTest,
		ServiceType,
		preferredTime,
		resultStatus,
		addressLine1,
		addressLine2,
		createdAt,
	} = order

	console.log('order',order)
	// const shouldDisplayDispatchTo = orderType !== 'self-test' && orderType !== 'courier' && orderType !== 'same-day-test'

	// console.log('should display: ', shouldDisplayDispatchTo)
	return (
		<div>
			<div className="d-flex">
				<div style={{ width: "35%", fontSize: '12px' }}>Full Name:</div>
				<div style={{ width: "65%", fontSize: '12px' }}>{fullName}</div>
			</div>

			{/*<div className="d-flex ">*/}
			{/*	<div style={{ width: "35%" }} className="font-weight-bold">Status:</div>*/}
			{/*	<div style={{ width: "65%" }}>{resultStatus}</div>*/}
			{/*</div>*/}

			<div className="d-flex ">
				<div style={{ width: "35%", fontSize: '12px'}} >Email:</div>
				<div style={{ width: "65%", fontSize: '12px' }}>{email}</div>
			</div>

			{/* <div className="d-flex ">
				<div style={{ width: "35%" }} className="font-weight-bold">Status:</div>
				<div style={{ width: "65%" }}>{resultStatus}</div>
			</div> */}

			<div className="d-flex ">
				<div style={{ width: "35%", fontSize: '12px' }} className="">Test Date:</div>
				<div style={{ width: "65%", fontSize: '12px' }}>{dateOfTest}</div>
			</div>

			<div className="d-flex ">
				<div style={{ width: "35%", fontSize: '12px' }} >Time Window:</div>
				<div style={{ width: "65%", fontSize: '12px' }}>{preferredTime}</div>
			</div>

			<div className="d-flex ">
				<div style={{ width: "35%", fontSize: '12px' }} >Test Address:</div>
				<div style={{ width: "65%", fontSize: '12px' }}>{addressLine1} {addressLine2}</div>
			</div>

			<div className="d-flex ">
				<div style={{ width: "35%", fontSize: '12px' }} >Event / Shoot Name:</div>
				<div style={{ width: "65%", fontSize: '12px' }}>{eventName}</div>
			</div>

			{/*<div className="d-flex ">*/}
			{/*	<div style={{ width: "35%" }} className="font-weight-bold">Result Status:</div>*/}
			{/*	<div style={{ width: "65%" }}>{resultStatus}</div>*/}
			{/*</div>*/}

		</div>
		// <Column size={4}>
		// 	<Body>Your latest order:</Body>
		// 	<Column size={4} spacingTop="s">
		// 		<OrderRow label="Full Name">{fullName}</OrderRow>
		// 		<OrderRow label="Status">{resultStatus}</OrderRow>
		// 		<OrderRow label="Email">{email}</OrderRow>
		// 		<OrderRow label="Address">{addressLine1} {addressLine2}</OrderRow>
		// 	</Column>
		//
		// 	<Column size={4} spacingTop="s" margin={0}>
		// 		<OrderRow label="Event Name">{eventName}</OrderRow>
		// 		<OrderRow label="Test Date">{dateOfTest}</OrderRow>
		// 		<OrderRow label="Preferred Time">{preferredTime}</OrderRow>
		// 		<OrderRow label="Service Type">{ServiceType}</OrderRow>
		// 		<OrderRow label="Created at">{createdAt}</OrderRow>
		// 		{/* <OrderRow label="Estimated delivery date">{estimatedDeliveryDateText}</OrderRow> */}
		// 	</Column>
		// 	{/*{shouldDisplayDispatchTo ? (*/}
		// 	{/*	<Column size={4} spacingTop="s">*/}
		// 	{/*		<OrderRow label="Dispatch to">{address && address.recipient}</OrderRow>*/}
		// 	{/*		<OrderRow noLabel>{address && address.addressLine1}</OrderRow>*/}
		// 	{/*		<OrderRow noLabel>{address && address.addressLine2}</OrderRow>*/}
		// 	{/*		<OrderRow noLabel>*/}
		// 	{/*			{address && address.city} {address && address.postcode}*/}
		// 	{/*		</OrderRow>*/}
		// 	{/*		<OrderRow noLabel>United Kingdom</OrderRow>*/}
		// 	{/*	</Column>*/}
		// 	{/*) : null}*/}
		// </Column>
	)
}

export { OrderDisplay }
