import React, { createContext, useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

const defaultValue = {}

const BeaconFormContext = createContext(defaultValue)

const BeaconForm = ({ initialValues, onSubmit, io, children, triggered, resetAfterSubmit }) => {
	const { register, getValues, trigger, errors, control, handleSubmit, reset } = useForm({
		defaultValues: io.input(initialValues),
	})

	useEffect(() => {
		let intervalId
		if (triggered === true) {
			intervalId = setInterval(() => {
				console.log('invoked trigger')
				trigger()
			}, 150)
		}

		return () => clearInterval(intervalId)
	})

	const [isSubmitting, setIsSubmitting] = useState(false)

	const { output } = io

	const handleFormSubmit = (values) => {
		console.log('Event: ', values)
		const outputValues = output(values)

		const maybePromise = onSubmit(outputValues)

		if (maybePromise instanceof Promise) {
			setIsSubmitting(true)

			maybePromise
				.then((response) => {
					setIsSubmitting(false)
					if (resetAfterSubmit) {
						reset()
					}
				})
				.catch((error) => {
					setIsSubmitting(false)
				})
		}
	}

	const context = {
		isSubmitting,
		register,
		getValues,
		errors,
		control,
	}

	return (
		<form onSubmit={handleSubmit(handleFormSubmit)}>
			<BeaconFormContext.Provider value={context}>{children}</BeaconFormContext.Provider>
		</form>
	)
}

const identity = (x) => x

BeaconForm.defaultProps = {
	io: { input: identity, output: identity },
}

const useBeaconForm = (name) => {
	let context = useContext(BeaconFormContext)

	if (context === defaultValue) {
		throw new Error('useBeaconForm must be used within BeaconForm component')
	}

	if (typeof name === 'string' && name.length > 0) {
		const maybeError = context.errors[name]
		const hasError = maybeError !== null && maybeError !== undefined
		const error = maybeError || null

		context = {
			...context,
			hasError,
			error,
		}
	}

	return context
}

export { BeaconForm, useBeaconForm }
