import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import './containers.css'
import { useBreakpoint } from '../utils/breakpoints'
import { getWidthForSize, resolveVerticalSpacing } from './utils'

const defaultProps = {
	mobileSize: 12,
	tabletSize: 12,
	desktopSize: 12,
	size: -1,
	bordered: false,
	borderedAsList: false,
	className: '',
	spaced: false,
	noWrap: false,
	marketing: false,
	marginTop: 1,
	padding: 'none',
}

const resolveContainerWidth = (props, isMobile, isTablet, isDesktop) => {
	const { contained, fillRemaining, size, mobileSize, tabletSize, desktopSize } = props

	const ts = size === -1 ? tabletSize : size
	const ds = size === -1 ? desktopSize : size

	if (contained === true) {
		return ''
	} else if (fillRemaining === true) {
		return '100%'
	} else {
		return getWidthForSize(isMobile, isTablet, isDesktop, mobileSize, ts, ds)
	}
}

const Box = (props) => {
	const {
		className,
		direction,
		style,
		children,
		onClick,
		noWrap,
		marketing,
		bordered,
		borderedAsList,
		separated,
		spaced,
		centered,
		padding,
		spacingTop,
		spacingBottom,
	} = props
	const { isMobile, isTablet, isDesktop } = useBreakpoint()

	const centeredStyle = centered ? { alignItems: 'center', justifyContent: 'center' } : {}
	const spacedStyle =
		direction === 'row'
			? { justifyContent: spaced ? 'space-between' : '' }
			: { alignItems: spaced ? 'space-between' : '' }

	const divStyle = {
		...spacedStyle,
		...centeredStyle,
		width: resolveContainerWidth(props, isMobile, isTablet, isDesktop),
		flexWrap: noWrap ? 'no-wrap' : 'wrap',
		marginTop: borderedAsList ? 0 : resolveVerticalSpacing(spacingTop),
		marginBottom: borderedAsList ? 0 : resolveVerticalSpacing(spacingBottom),
		padding: bordered ? '2.4rem' : `${resolveVerticalSpacing(padding)} 0`,
		...style,
	}

	const concatenatedClassNames = [
		className,
		'd-flex container-app',
		direction,
		bordered ? 'bordered' : '',
		borderedAsList ? 'bordered-as-list' : '',
		separated && !marketing ? 'separated' : '',
	]
		.join(' ')
		.trim()

	if (marketing === true) {
		return (
			<div
				className={`d-flex ${className} marketing`}
				style={{
					display: 'flex',
					flexDirection: direction,
					[direction === 'row' ? 'justifyContent' : 'alignItems']: 'center',
					borderTop: separated ? ' solid 1px #c7d4da' : '',
					width: '100%',
				}}
			>
				<Box direction={direction} style={divStyle}>
					{children}
				</Box>
			</div>
		)
	} else {
		return (
			<div className={concatenatedClassNames} style={divStyle} onClick={onClick}>
				{children}
			</div>
		)
	}
}

const columnAndRowPropTypes = {
	className: PropTypes.string,
	style: PropTypes.any,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
	fillRemaining: PropTypes.bool,
	noWrap: PropTypes.bool,
	marketing: PropTypes.bool,
	bordered: PropTypes.bool,
	borderedAsList: PropTypes.bool,
	contained: PropTypes.bool,
	spaced: PropTypes.bool,
	centered: PropTypes.bool,
	size: PropTypes.oneOf([-1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	mobileSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	tabletSize: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	desktopSize: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	padding: PropTypes.oneOf(['xs', 's', 'm', 'l', 'none']),
	spacingTop: PropTypes.oneOf(['xs', 's', 'm', 'l', 'none']),
	spacingBottom: PropTypes.oneOf(['xs', 's', 'm', 'l', 'none']),
}

const Column = (props) => {
	return <Box direction="column-app" {...props} />
}

Column.propTypes = columnAndRowPropTypes
Column.defaultProps = defaultProps

const Row = (props) => {
	return <Box direction="row-app" {...props} />
}

Row.propTypes = columnAndRowPropTypes
Row.defaultProps = defaultProps

const Spacer = ({ spacing, style, className }) => {
	const divStyle = {
		...style,
		paddingTop: resolveVerticalSpacing(spacing),
		paddingLeft: resolveVerticalSpacing(spacing),
	}

	return <div className={className} style={divStyle} />
}

const AppColumn = ({ children }) => {
	const style = {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		padding: '0',
		margin: '0',
	}

	return (
		<div id="app-column" className="d-flex container-app column-app" style={style}>
			{children}
		</div>
	)
}

const MainColumn = ({ children }) => {
	const { pathname } = useLocation()
	const classPath = pathname.split('/')[1] || '';
	const style = {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		padding: '0',
	}

	return (
		<div id="main-column" className={clsx(`page-${classPath}`, 'd-flex container-app', 'column-app')} style={style}>
			{children}
		</div>
	)
}

export { AppColumn, MainColumn, Spacer, Box, Row, Column }
