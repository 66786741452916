import React from 'react'
import { Column, Row, Body, Spacer, Line } from '../../../components'

const PatientsList = ({patient,no}) => {
    return (
        <Column desktopSize={9}>
            <Spacer spacing="sm" />
            <Row desktopSize={9}>
                <Column desktopSize={2} tabletSize={2} style={{ justifyContent: 'auto' }}>
                    <Body> {patient && `${no}) ${patient.fullName}`}</Body>
                </Column>
                <Column desktopSize={3} tabletSize={3}>
                    <Body small>  <Body>{patient && patient.email}</Body></Body>
                </Column>
                <Column desktopSize={1} tabletSize={1}>
                    <Body style={{ fontSize: 12 }}>{patient && patient.telephone}</Body>
                </Column>
                <Column desktopSize={3} tabletSize={3} style={{ fontSize: 12 }}>
                    <Body style={{ fontSize: 12 }}>{patient && patient.addressLine1}</Body>
                    <Body style={{ fontSize: 12 }}>{patient && patient.addressLine2}</Body>
                    <Body style={{ fontSize: 12 }}>{patient && patient.city}</Body>
                    <Body style={{ fontSize: 12 }}>{patient && patient.postcode}</Body>
                </Column>
            </Row>
            <Spacer spacing="sm" />
            <Line />
        </Column>
    )
}

export default PatientsList