import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { Row, Column } from './containers'
import { sortableUid } from '../utils'
import './inputs.css'
import { Body } from './typography'

const resolveTextInputType = (type, isPassword) => {
	if (isPassword === true) {
		return 'password'
	}

	return type
}

const resolveAddendum = (addendum, password, noAddendum) => {
	if (noAddendum === true) {
		return null
	}

	if (typeof addendum === 'function') {
		return addendum
	}

	if (password === true) {
		return (
			<Link className="form-component" to="/forgot-password" tabIndex="-1">
				Forgot your password?
			</Link>
		)
	}

	return null
}

const Form = ({ loading, size, onSubmit, children }) => {
	const handleSubmitAndPreventDefault = (event) => {
		event.preventDefault()
		onSubmit(event)
	}

	return <form onSubmit={handleSubmitAndPreventDefault}>{children}</form>
}

const inputDefaultProps = {
	mobileSize: 12,
	tabletSize: 12,
	desktopSize: 12,
	size: -1,
	inputRef: null,
}

const CheckboxInput = ({
	children,
	name,
	inputRef,
	value,
	onChange,
	checked,
	disabled,
	setter,
	mobileSize,
	tabletSize,
	desktopSize,
	size,
}) => {
	const handleChange =
		typeof onChange === 'function' ? onChange : (event) => setter(event.target.checked)
	const interactiveParams =
		inputRef === null
			? {
					onChange: handleChange,
					value: value,
			  }
			: { ref: inputRef }
	return (
		<Row mobileSize={mobileSize} tabletSize={tabletSize} desktopSize={desktopSize} size={size}>
			<label className="form-component">
				<input
					type="checkbox"
					name={name}
					checked={checked}
					disabled={disabled === true}
					{...interactiveParams}
				/>
				{children}
			</label>
		</Row>
	)
}

CheckboxInput.defaultProps = inputDefaultProps

const RadioInputItem = ({ option, group, checked, onChange, validator }) => {
	const { label, value } = option
	return (
		<label className="form-component large">
			<input type="radio" value={value} name={group} checked={checked} onChange={onChange} ref={validator} />
			{label}
		</label>
	)
}

const RadioMenu = ({
	value,
	options,
	group,
	setter,
	onChange,
	mobileSize,
	tabletSize,
	desktopSize,
	size,
  validator
}) => {
	const [selectedItem, setSelectedItem] = useState(value)

	const handleOnChange = (event) => {
		const value = event.target.value
		setSelectedItem(value)
		if (typeof onChange === 'function') {
			onChange({ target: { value } })
		} else {
			setter(value)
		}
	}

	return (
		<Column mobileSize={mobileSize} tabletSize={tabletSize} desktopSize={desktopSize} size={size}>
			{options.map((option) => {
				const isSelected = option.value === selectedItem

				return (
					<RadioInputItem
						key={option.value}
						checked={isSelected}
						option={option}
						group={group}
						onChange={handleOnChange}
            validator={validator}
					/>
				)
			})}
		</Column>
	)
}

RadioMenu.defaultProps = inputDefaultProps

const createDataList = (id, datalist) => {
	return (
		<datalist id={id}>
			{datalist.map((item, index) => (
				<option value={item} key={index} />
			))}
		</datalist>
	)
}
const TextInput = ({
	label,
	placeholder,
	password,
	rightAligned,
	value,
	setter,
	disabled,
	labelAdendum,
	noAddendum,
	mobileSize,
	tabletSize,
	desktopSize,
	size,
	datalist,
	mask,
	type,
	onChange,
	onBlur,
	inputRef,
	name,
   paswordType,
	error,
}) => {
	const addendum = resolveAddendum(labelAdendum, password, noAddendum)
	const datalistId = datalist ? sortableUid() : ''
	const handleChange =
		typeof onChange === 'function'
			? onChange
			: (event) => {
					setter(event.target.value)
			  }
	const hasMask = typeof mask === 'string' && mask.length > 2

	const hasError = error !== null && error !== undefined
	const isInvalid = hasError

	const interactiveParams =
		inputRef === null
			? {
					onChange: handleChange,
					value: value,
			  }
			: { ref: inputRef }

	const inputElement = hasMask ? (
		<InputMask
			mask={mask}
			placeholder={placeholder}
			name={name}
			maskChar={null}
			onChange={onChange}
			onBlur={onBlur}
			value={value}
		>
			{(inputProps) => (
				<input
					className="form-component "
					style={{ textAlign: rightAligned ? 'right' : 'left' }}
					type={resolveTextInputType(type, paswordType)}
					placeholder={placeholder}
					list={datalistId}
					name={name}
				/>
			)}
		</InputMask>
	) : (
		<input
			className="form-component "
			style={{ textAlign: rightAligned ? 'right' : 'left' }}
			type={resolveTextInputType(type, paswordType)}
			placeholder={placeholder}
			disabled={disabled === true}
			list={datalistId}
			name={name}
			onBlur={onBlur}
			{...interactiveParams}
		/>
	)

	const borderClassName = `form-component-border ${isInvalid ? 'invalid' : ''}`.trim()

	return (
		<Column
			className="form-component"
			mobileSize={mobileSize}
			tabletSize={tabletSize}
			desktopSize={desktopSize}
			size={size}
		>
			<Row mobileSize={mobileSize} tabletSize={tabletSize} desktopSize={desktopSize} size={size}>
				<label className="form-component">{label}</label>&nbsp;
				{addendum}
			</Row>
			<Row
				className={borderClassName}
				mobileSize={mobileSize}
				tabletSize={tabletSize}
				desktopSize={desktopSize}
				size={size}
			>
				{inputElement}
			</Row>
			{error && (
				<Body small error>
					{error.message}
				</Body>
			)}
			{datalist && createDataList(datalistId, datalist)}
		</Column>
	)
}

TextInput.defaultProps = { ...inputDefaultProps, type: 'text' }

const TextAreaInput = ({ label, placeholder, size, mobileSize, tabletSize, desktopSize }) => {
	return (
		<Column size={size} mobileSize={mobileSize} tabletSize={tabletSize} desktopSize={desktopSize}>
			<label className="form-component">{label}</label>
			<textarea className="form-component" />
		</Column>
	)
}

TextAreaInput.defaultProps = inputDefaultProps

export { Form, CheckboxInput, RadioMenu, TextInput, TextAreaInput }
