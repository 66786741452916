import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Column, Row, Form, Body, TextInput, Button, BackButton, Spacer } from '../../components'

// TODO add "Mamoo Digital to companyOrPracticeName"

const DeliveryAddressPage = () => {
	const history = useHistory()
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [companyOrPracticeName, setCompanyOrPracticeName] = useState('')
	const [addressLine1, setAddressLine1] = useState('')
	const [addressLine2, setAddressLine2] = useState('')
	const [city, setCity] = useState('')
	const [postcode, setPostcode] = useState('')

	const handleNewAddressSubmit = (event) => {
		const maybeAddresses = JSON.parse(localStorage.getItem('deliveryAddresses'))
		const addresses = Array.isArray(maybeAddresses) ? maybeAddresses : []

		addresses.push({
			practitioner: {
				companyOrPracticeName,
				firstName,
				lastName,
				email,
			},
			address: {
				addressLine1,
				addressLine2,
				city,
				postcode,
			},
		})

		localStorage.setItem('deliveryAddresses', JSON.stringify(addresses))

		history.push('/customer/order-professional-test-kits')
	}

	return (
		<Column size={8}>
			<Row spacingTop="m" spacingBottom="s" contained>
				<BackButton />
			</Row>
			<h3>Enter a new delivery address</h3>
			<Body>
				The test kits will be addressed to the health practitioner below and they will be sent a
				login for this service.
			</Body>

			<Form onSubmit={handleNewAddressSubmit}>
				<Column size={8}>
					<h5>Health practitioner</h5>
					<Row size={8} spaced>
						<TextInput label="First name" size={4} value={firstName} setter={setFirstName} />
						<TextInput label="Last name" size={4} value={lastName} setter={setLastName} />
					</Row>
					<TextInput label="Email address" size={8} value={email} setter={setEmail} />
				</Column>
				<Column size={8}>
					<h5>Delivery address</h5>
					<TextInput
						label="Company or practice name"
						size={8}
						value={companyOrPracticeName}
						setter={setCompanyOrPracticeName}
					/>
					<TextInput
						label="Address Line 1"
						size={8}
						value={addressLine1}
						setter={setAddressLine1}
					/>
					<TextInput
						label="Address Line 2"
						size={8}
						value={addressLine2}
						setter={setAddressLine2}
					/>
					<Row size={8} spaced>
						<TextInput label="Town / City" size={5} value={city} setter={setCity} />
						<TextInput label="Post code " size={3} value={postcode} setter={setPostcode} />
					</Row>
				</Column>
				<Spacer padding={1} />
				<Button fitted primary submit>
					Use this address
				</Button>
			</Form>
		</Column>
	)
}

export { DeliveryAddressPage }
