import React, { useState, useEffect } from 'react'
import { Row, Column, Spacer, Body, TextInput, Button } from '../../../components'
import { useOrdering } from '../../../utils'

const SelectOrganisation = ({ organisationIds, onOrganisationSelect }) => {
	const organisationIdsHash = organisationIds.join('||')
	const [organisations, setOrganisations] = useState([])
	const [organisationName, setOrganisationName] = useState('')
	const [showOrganisationSelector, setShowOrganisationSelector] = useState(false)
	const { defaultOrganisation, retrieveOrganisationsByIds } = useOrdering()

	const organisationNames = organisations.map((organisation) => organisation.displayName)
	const maybeSelectedOrganisation = organisations.find(
		({ displayName }) => displayName === organisationName.trim()
	)
	const isOrganisatinSelected =
		maybeSelectedOrganisation !== null && maybeSelectedOrganisation !== undefined

	const handleNextClick = () => {
		if (maybeSelectedOrganisation) {
			onOrganisationSelect({ organisationId: maybeSelectedOrganisation.organisationId })
		} else {
			onOrganisationSelect({ organisationId: defaultOrganisation() })
		}
	}

	useEffect(() => {
		retrieveOrganisationsByIds(organisationIds)
			.then((organisations) => {
				setOrganisations(organisations.data)
			})
			.catch((error) => {
				console.error('organisations:error', error)
			})
	}, [organisationIdsHash])

	return (
		<>
			<Column desktopSize={8} tabletSize={12}>
				<h3>Assign a test kit</h3>
				<Body>To assign a test kit, please answer the questions below:</Body>
				<h4>1. Was this patient sent to take this test by their employer?</h4>
				<Spacer spacing="s" />
				<Row desktopSize={8} tabletSize={12}>
					<Button primary onClick={() => setShowOrganisationSelector(true)}>
						&nbsp;&nbsp;Yes&nbsp;&nbsp;
					</Button>
					<Button secondary onClick={handleNextClick}>
						&nbsp;&nbsp;No&nbsp;&nbsp;
					</Button>
				</Row>
				<Spacer spacing="m" />
				<Spacer spacing="m" />
				{showOrganisationSelector && (
					<>
						{' '}
						<h4>2. Please enter the patient’s employer:</h4>
						<Body>
							<strong>IMPORTANT:</strong> The patient’s results will be shared the employer
							selected, please confirm.
						</Body>
						<Row desktopSize={8} tabletSize={12} style={{ alignItems: 'flex-end' }}>
							<TextInput
								desktopSize={7}
								tabletSize={10}
								datalist={organisationNames}
								label="Company name"
								value={organisationName}
								setter={setOrganisationName}
							/>
							<Button fitted primary disabled={!isOrganisatinSelected} onClick={handleNextClick}>
								Next
							</Button>
						</Row>
					</>
				)}
			</Column>
		</>
	)
}

export { SelectOrganisation }
