import React, { useEffect, useState } from 'react'
import Calender from 'components/common/custom.calender/Calender';
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
import MaterialAutoComplete from 'components/common/material.autocomplete/material.autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import {
    ETHNICITY_LIST,
    GENDER_LIST_SBS, getPastDate,
    IsMobileWidth,
    TRAVEL_METHOD_LIST,
    VACCINATION_STATUS
} from "components/common/util/util";
import clsx from 'clsx';
import LoaderButton from 'components/common/loader.button/loader.button';
import { COUNTRIES_LIST } from 'components/common/util/util';
import MaterialAutoCompleteTypeable from 'components/common/material.autocomplete.typeable/material.autocomplete.typeable';
import { GENDER_LIST } from 'components/common/util/util';
import ReactTelInput from 'react-telephone-input';
import 'react-telephone-input/css/default.css';
import moment from 'moment';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { COUNTRY_CODES } from 'components/common/util/util';
import { lastWord, PostCodeDropdown, PostCodeHandle } from "../../../components/common/postcode";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { Row, Col } from 'reactstrap';
import { useAdmin } from "../../../utils";
import { BackendProvider } from "../../../utils/backend";

const Form = (props) => {
    const {
        email,
        familyName,
        givenName,
        fullName,
        DOB,
        telephone,
        gender,
        isProducerCheckFitToFly,
        addressLine1,
        addressLine2,
        postcode,
        preferredTime,
        producerComments,

        isHomeAddressSameAsAboveAddress,
        homeAddressLine1,
        homeAddressLine2,
        homeAddressCity,
        homeAddressCountry,
        homeAddressPostcode,

        serviceType,
        serviceTypeText,
        arrivalDate,
        departureDate,
        departureCountry,
        departureCountryText,
        ethnicity,
        ethnicityText,
        travelMethod,
        travelMethodText,
        vaccinationStatus,
        vaccinationStatusText,
        passportNum,
        vesselNumber,

        city,
        country,
        nationality,
        healthNumber,
        documentType,
        documentCountry,
        documentNumber,
        documentExpiration,
        isFitToFly,
        status,
        eventName,
        patientComments,
        dateOfTest,
        execStatusConfirmDetail,
        execStatusGetUserConfirmationDetail,
        documentAuthority } = props;
    const mobileWidth = IsMobileWidth();
    const { getAllSbsEthnicity, getAllSbsTravelMethods, getAllSbsVaccinationStatus, getAllSbsCountries } = useAdmin();
    let contryList = COUNTRY_CODES.map((cnt) => {
        return { name: cnt[0], iso2: cnt[1], dialCode: cnt[2], priority: 0, format: cnt[3] }
    })

    const [inputPostValue, setInputPostValue] = useState("");
    const [resValue, setResValue] = useState('');
    const [errorValue, setErrorValue] = useState([]);
    const [postcodeDropdown, setPostcodeDropdown] = useState(false);
    const [adressValue, setAddressValue] = useState({});
    const [propTarget, setPropTarget] = useState({});

    const [ethnicityList, setEthnicityList] = useState(undefined);
    const [travelMethodList, setTravelMethodList] = useState(undefined);
    const [vaccinationList, setVaccinationList] = useState(undefined);
    const [sbsCountriesList, setSbsCountriesList] = useState(undefined);

    const [homeAddressPostCodeAtr, setHomeAddressPostCodeAtr] = useState({
        inputPostValue: "",
        resValue: "",
        errorValue: [],
        postcodeDropdown: false
    }) //Atr means attributes

    useEffect(() => {
        setPropTarget({ addressLine1: addressLine1, addressLine2: addressLine2, city: city });
    }, [addressLine1, addressLine2, city])

    useEffect(() => {
        if (postcode !== homeAddressPostcode || addressLine1 !== homeAddressLine1 || addressLine2 !== homeAddressLine2 || city !== homeAddressCity || country !== homeAddressCountry || postcode !== homeAddressPostcode) {
            props.onChange(true, "isHomeAddressSameAsAboveAddress")
        }
    }, [postcode, addressLine1, addressLine2, city, country, homeAddressPostcode, homeAddressLine1, homeAddressLine2, homeAddressCity, homeAddressCountry, postcode, homeAddressPostcode])

    useEffect(() => {
        getAllSbsEthnicity()
            .then((response) => {
                const updatedEthnicity = [];
                const res = response?.data?.map((item) => {
                    updatedEthnicity.push({ name: item?.name, value: item?.pheCode })
                })
                setEthnicityList(updatedEthnicity)
            })
            .catch((error) => { })

        getAllSbsTravelMethods()
            .then((response) => {
                console.log('Response travel method BE', response);

                const updatedTravelMethod = [];
                const res = response?.data?.map((item) => {
                    updatedTravelMethod.push({ name: item?.name, value: item?.travelMethodId.toString() })
                })
                setTravelMethodList(updatedTravelMethod)
            })
            .catch((error) => { })

        getAllSbsVaccinationStatus()
            .then((response) => {
                console.log('response vacc BE', response);
                const updatedVaccination = [];
                const res = response?.data?.map((item) => {
                    updatedVaccination.push({ name: item?.name, value: item?.vaccinationStatusId.toString() })
                })
                setVaccinationList(updatedVaccination)
            })
            .catch((error) => { })

        getAllSbsCountries()
            .then((response) => {
                const updatedCountries = [];
                const res = response?.data?.map((item) => {
                    updatedCountries.push({ name: item?.name, value: item?.iso3Code })
                })
                setSbsCountriesList(updatedCountries)
            })
            .catch((error) => { })

    }, []);


    const handlePostCodeInputClick = (event, evnetTargetName) => {

        if (evnetTargetName === "testAddressPostCode") {
            PostCodeHandle(inputPostValue, (item) => {
                setPostcodeDropdown(item?.dropdown);
                setErrorValue(item?.errorValue);
                setResValue(item?.resValue);
            });
        }
        else if (evnetTargetName === "homeAddressPostcode") {
            PostCodeHandle(homeAddressPostcode, (item) => {
                setHomeAddressPostCodeAtr({
                    ...homeAddressPostCodeAtr,
                    postcodeDropdown: item?.dropdown,
                    errorValue: item?.errorValue,
                    resValue: item?.resValue
                })
            });
        }
    }

    const handlePostCodeInputChange = (event, evnetTargetName) => {
        if (evnetTargetName === "testAddressPostCode") {
            setInputPostValue(event.target.value);
            props.onChange(event.target.value, "postcode")
        }
        else if (evnetTargetName === "homeAddressPostcode") {
            setHomeAddressPostCodeAtr({
                ...homeAddressPostCodeAtr,
                inputPostValue: event.target.value
            })
            props.onChange(event.target.value, "homeAddressPostcode")
        }
    }

    const addressDetails = (item, evnetTargetName) => {
        if (evnetTargetName === "testAddressPostCode") {
            setPropTarget({ addressLine1: "", addressLine2: "", city: "" });
            setAddressValue({ street: item?.StreetAddress, city: item?.Place });
            props.getSelectedAddress([
                { key: "city", value: lastWord(item?.Place) },
                { key: "addressLine1", value: item?.StreetAddress },
                { key: "addressLine2", value: item?.addressLine2 },
            ]);
            setPostcodeDropdown(false);
        }
        else if (evnetTargetName === "homeAddressPostcode") {
            props.getSelectedAddress([
                { key: "homeAddressCity", value: lastWord(item?.Place) },
                { key: "homeAddressLine1", value: item?.StreetAddress },
                { key: "homeAddressLine2", value: item?.addressLine2 },
            ]);

            setHomeAddressPostCodeAtr({
                ...homeAddressCountry,
                postcodeDropdown: false
            })
        }
    }

    const addressLine1Handle = (e) => {
        if (e.target.value.length > 0) {
            props.onChange(e.target.value, "addressLine1")
        } else {
            props.onChange('', "addressLine1")
            setAddressValue({ street: '' });
        }
    }

    const addressLine2Handle = (e) => {
        if (e.target.value.length > 0) {
            props.onChange(e.target.value, "addressLine2")
        } else {
            props.onChange('', "addressLine2")
            setAddressValue({ city: '' });
        }
    }

    useEffect(() => {
        if (documentType.length > 0 || documentAuthority.length > 0 || documentNumber.length > 0) {
            props.onChange("true", "isFitToFly")
        }
    }, [])

    return (
        <div className="w-100">
            {
                status === "CONFIRMATION_LINK_SENT" ?
                    <React.Fragment>
                        <div className="d-flex justify-content-center">

                        </div>
                        <div className="d-flex justify-content-center">
                            <div className={clsx(mobileWidth && "w-100", !mobileWidth && "w-50")}>
                                <h3 className="pl-3 pr-3">
                                    PCR Test Data Confirmation
                                </h3>
                                <p style={{
                                    fontSize: 'initial',
                                    textAlign: 'justify'
                                }} className="pl-3 pr-3">
                                    {'You are scheduled to have a '}
                                    <span className="">PCR Test</span>
                                    {` for ${eventName} `}
                                    {' on '}
                                    <span className="font-weight-bold">{dateOfTest}</span>
                                    {serviceType !== "PCR_DAY_2" &&
                                        <>
                                            {' between '}
                                            <span className="font-weight-bold">{preferredTime}</span>
                                        </>
                                    }
                                    {'. This forms contains data provided to us and it is important that you update and complete this form as soon as possible and before your test is undertaken.'}
                                </p>
                                <p style={{
                                    fontSize: 'initial',
                                    textAlign: 'justify'
                                }} className="pt-1 pl-3 pr-3 font-weight-bold">
                                    If you require any changes to this test booking, please could you contact the individual who has scheduled this test in for you.
                                </p>
                                <div className="p-3">
                                    <div style={{ borderBottom: "1px solid gray" }}>
                                        <div>
                                            <span className="font-weight-bold" style={{ fontSize: "1.7rem" }}>Scheduled Test Details</span>
                                        </div>
                                        <Row className="w-100">
                                            <Col lg="4" className="p-3">
                                                <SiteLabelTextField label="Date of Test"
                                                    value={dateOfTest}
                                                    disabled={true}
                                                    onChange={(e) => addressLine1Handle(e)}
                                                    placeholder="Address Line 1" />
                                            </Col>
                                            {(serviceType === "SAME_DAY" || serviceType === "NEXT_DAY") &&
                                                <Col lg="4" className="p-3">
                                                    <SiteLabelTextField label="Time Slot"
                                                        value={preferredTime}
                                                        disabled={true}
                                                        placeholder="Time Slot" />
                                                </Col>
                                            }
                                            <Col lg="4" className="p-3">
                                                <SiteLabelTextField label="Post Code"
                                                    value={postcode}
                                                    disabled={true}
                                                    placeholder="Post Code" />
                                            </Col>

                                            <Col lg="4" className="p-3">
                                                <SiteLabelTextField label="Test Address Line 1"
                                                    value={propTarget?.addressLine1 ? propTarget?.addressLine1 : adressValue?.street}
                                                    disabled={true}
                                                    onChange={(e) => addressLine1Handle(e)}
                                                    placeholder="Address Line 1" />
                                            </Col>
                                            <Col lg="4" className="p-3">

                                                <SiteLabelTextField label="Test Address Line 2"
                                                    value={propTarget?.addressLine2 ? propTarget?.addressLine2 : adressValue?.city}
                                                    disabled={true} required={false}
                                                    onChange={(e) => addressLine2Handle(e)}
                                                    placeholder="Address Line 2" />
                                            </Col>
                                            <Col lg="4" className="p-3">
                                                <SiteLabelTextField label="Test City"
                                                    disabled={true}
                                                    value={propTarget?.city ? propTarget?.city : lastWord(adressValue?.city)}
                                                    onChange={(e) => props.onChange(e.target.value, "city")}
                                                    placeholder="City" />
                                            </Col>
                                            <Col lg="4" className="p-3">
                                                <SiteLabelTextField label="Test Country"
                                                    value={country.name}
                                                    disabled={true}
                                                    placeholder="Country" />
                                            </Col>

                                            {
                                                // producerComments &&
                                                // <>
                                                //     <Col lg="4" className="p-3">
                                                //         {/* <SiteLabelTextField label="Producer Comments"
                                                //             value={producerComments}
                                                //             disabled={true}
                                                //             placeholder="Producer Comments" /> */}
                                                //         <div className="d-flex flex-row font-weight-bold pt-2 pb-2" >
                                                //             <div style={{ color: '#DC3545', fontWeight: 'bold' }}>*</div>Producer Comments
                                                //         </div>
                                                //         <div>
                                                //             {producerComments}
                                                //         </div>
                                                //     </Col>
                                                // </>
                                            }
                                        </Row>

                                    </div>

                                    <div className="w-100 py-3 mt-2">
                                        <div className="mb-3">
                                            <span className="font-weight-bold" style={{ fontSize: "1.75rem" }}>Individual Personal Details</span>
                                        </div>
                                        <Row className="w-100">
                                            <Col md="4" className="p-3">
                                                <SiteLabelTextField required={true} label="First Name" value={givenName} onChange={(e) => props.onChange(e.target.value, "givenName")} placeholder="First Name" />
                                            </Col>
                                            <Col md="4" className="p-3">
                                                <SiteLabelTextField required={true} label="Last Name" value={familyName} onChange={(e) => props.onChange(e.target.value, "familyName")} placeholder="Last Name" />
                                            </Col>
                                            <Col md="4" className="p-3">
                                                <SiteLabelTextField disabled={true} required={true} label="Full Name" value={givenName ? `${givenName} ${familyName}` : fullName} onChange={(e) => props.onChange(e.target.value, "fullName")} placeholder="Full Name" />
                                            </Col>


                                            {/*  See SBS Day 2 Fields */}
                                            {
                                                serviceType === "PCR_DAY_2" &&
                                                <>

                                                    {ethnicityList !== undefined &&
                                                        <Col md="4" className="p-3">
                                                            <MaterialAutoComplete
                                                                required={true}
                                                                startAdornment="Ethnicity"
                                                                selectedItem={ethnicity?.value ? ethnicity : { value: ethnicity, name: ethnicityText }}
                                                                onSelect={(value, data) => props.onSelect(value, data, "ethnicity")}
                                                                placeholder="Select Ethnicity"
                                                                data={ethnicityList}
                                                            />
                                                        </Col>
                                                    }
                                                    {vaccinationList !== undefined &&
                                                        <Col md="4" className="p-3">
                                                            <MaterialAutoComplete
                                                                required={true}
                                                                startAdornment="Vaccination Status"
                                                                selectedItem={vaccinationStatus?.value ? vaccinationStatus : { value: vaccinationStatus, name: vaccinationStatusText }}
                                                                onSelect={(value, data) => props.onSelect(value, data, "vaccinationStatus")}
                                                                placeholder="Select Vaccination Status"
                                                                data={vaccinationList}
                                                            />
                                                        </Col>
                                                    }

                                                    <Col md="4" className="p-3">
                                                        <Calender
                                                            label="Arrival Date"
                                                            required={true}
                                                            value={arrivalDate}
                                                            placeholder="Select Date of Arrival"
                                                            minDate={departureDate ? departureDate : getPastDate(0)}
                                                            getSelectedDate={(date) => { props.onChange(date, "arrivalDate") }}
                                                        />
                                                    </Col>

                                                    <Col md="4" className="p-3">
                                                        <Calender
                                                            label="Departure Date "
                                                            required={true}
                                                            value={departureDate}
                                                            maxDate={arrivalDate}
                                                            placeholder="Select Date of Departure"
                                                            getSelectedDate={(date) => { props.onChange(date, "departureDate") }}
                                                        />
                                                    </Col>

                                                    {sbsCountriesList !== undefined &&
                                                        <Col md="4" className="p-3">
                                                            <MaterialAutoCompleteTypeable
                                                                startAdornment="Departure Country "
                                                                required={true}
                                                                selectedItem={departureCountry?.value ? departureCountry : { value: departureCountry, name: departureCountryText }}
                                                                onSelect={(value, data) => props.onSelect(value, data, "departureCountry")}
                                                                placeholder="Select Country from a list"
                                                                data={sbsCountriesList}
                                                            />
                                                        </Col>
                                                    }

                                                    {travelMethodList !== undefined &&
                                                        <Col md="4" className="p-3">
                                                            <MaterialAutoComplete
                                                                required={true}
                                                                startAdornment="Travel Method"
                                                                selectedItem={travelMethod?.value ? travelMethod : { value: travelMethod, name: travelMethodText }}
                                                                onSelect={(value, data) => props.onSelect(value, data, "travelMethod")}
                                                                placeholder="Select Travel Method"
                                                                data={travelMethodList}
                                                            />
                                                        </Col>
                                                    }

                                                    <Col md="4" className="p-3">
                                                        <SiteLabelTextField
                                                            label="Flight/Coach/Vessel Number"
                                                            required={true}
                                                            value={vesselNumber}
                                                            onChange={(e) => props.onChange(e.target.value, "vesselNumber")}
                                                            placeholder="Enter Number" />
                                                    </Col>

                                                </>
                                            }

                                            <Col md="4" className="p-3">
                                                <SiteLabelTextField required={true} label="Email Address" value={email} onChange={(e) => props.onChange(e.target.value, "email")} placeholder="Email Address" />
                                            </Col>
                                            <Col md="4" className="d-flex flex-column justify-content-center">
                                                <div className="d-flex flex-row font-weight-bold pt-2 pb-2" >
                                                    <div style={{ color: '#DC3545', fontWeight: 'bold' }}>*</div>Telephone
                                                </div>
                                                <ReactTelInput
                                                    defaultCountry="gb"
                                                    preferredCountries={['gb', 'us']}
                                                    value={telephone}
                                                    onlyCountries={contryList}
                                                    onChange={(telNumber, selectedCountry) => props.onChange(telNumber, "telephone")}
                                                    flagsImagePath={`${process.env.PUBLIC_URL}/flags.png`}
                                                //onChange={handleInputChange}
                                                />
                                            </Col>

                                        </Row>

                                        {/*<Row className="w-100">*/}

                                        {/*</Row>*/}



                                        <div className="w-100">
                                            <FormControlLabel
                                                className="checkbox-size mb-0"
                                                control={
                                                    <Checkbox
                                                        size='medium'
                                                        checked={isHomeAddressSameAsAboveAddress}
                                                        onChange={(e) => props.onChange(isHomeAddressSameAsAboveAddress, "isHomeAddressSameAsAboveAddress")}
                                                        name="isHomeAddressSameAsAboveAddress"
                                                        color="secondary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }} />

                                                }
                                                label="Home address same as test address above"
                                            />
                                            <Row className="w-100">
                                                <Col md="4" className="position-relative">
                                                    <SiteLabelTextField label="Post Code"
                                                        value={homeAddressPostcode}
                                                        required={true}
                                                        onKeyDown={(e) => { if (e.key === 'Enter') { handlePostCodeInputClick(e, "homeAddressPostcode") } }}
                                                        onChange={(e) => { handlePostCodeInputChange(e, "homeAddressPostcode") }}
                                                        // onChange={(e) => props.onChange(e.target.value, "postcode")}
                                                        placeholder="Post Code" />
                                                    <div className="position-absolute "
                                                        onClick={(e) => { handlePostCodeInputClick(e, "homeAddressPostcode") }}
                                                        style={{ top: '36px', right: '15px', display: 'flex', alignItems: 'center' }}>
                                                        {homeAddressPostCodeAtr.postcodeDropdown &&
                                                            <i onClick={(e) => { e.stopPropagation(); setHomeAddressPostCodeAtr({ ...homeAddressPostCodeAtr, postcodeDropdown: false }); }}
                                                                className="fas fa-times-circle"
                                                                style={{ marginRight: '10px', color: 'darkred', fontSize: '14px', cursor: 'pointer' }}
                                                            >
                                                            </i>

                                                        }
                                                        <button type="button" className="btn btn-primary">Search</button>
                                                    </div>

                                                    {homeAddressPostCodeAtr.postcodeDropdown &&
                                                        <PostCodeDropdown
                                                            absoluteLeft={false}
                                                            errorValue={homeAddressPostCodeAtr.errorValue}
                                                            resValue={homeAddressPostCodeAtr.resValue}
                                                            addressDetails={(e) => addressDetails(e, "homeAddressPostcode")}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="w-100">
                                                <Col md="4" className="p-3">
                                                    <SiteLabelTextField label="Home Address Line 1"
                                                        required={true}
                                                        value={homeAddressLine1}
                                                        onChange={(e) => props.onChange(e.target.value, "homeAddressLine1")}
                                                        placeholder="Home Address Line 1" />
                                                </Col>
                                                <Col md="4" className="p-3">
                                                    <SiteLabelTextField label="Home Address Line 2"
                                                        required={false}
                                                        value={homeAddressLine2}
                                                        onChange={(e) => props.onChange(e.target.value, "homeAddressLine2")}
                                                        placeholder="Home Address Line 2" />
                                                </Col>
                                                <Col md="4" className="p-3">
                                                    <SiteLabelTextField
                                                        required={true}
                                                        label="Home City"
                                                        value={homeAddressCity}
                                                        onChange={(e) => props.onChange(e.target.value, "homeAddressCity")}
                                                        placeholder="City" />
                                                </Col>
                                                {sbsCountriesList !== undefined &&
                                                    <Col md="4" className="p-3">
                                                        <MaterialAutoCompleteTypeable
                                                            required={true}
                                                            startAdornment="Home Country"
                                                            selectedItem={homeAddressCountry}
                                                            onSelect={(value, data) => props.onSelect(value, data, "homeAddressCountry")}
                                                            placeholder="Select Country from a list"
                                                            data={serviceType !== "PCR_DAY_2" ? COUNTRIES_LIST : sbsCountriesList}
                                                        />
                                                    </Col>
                                                }
                                            </Row>
                                        </div>

                                        <Row className="w-100 mt-3">
                                            <Col md="4" className="p-3">
                                                {/* <CustomCalender
                                                        required={true}
                                                        label="Date Of Birth"
                                                        value={DOB}
                                                        placeholder="Select Date of Birth"
                                                        onAccept={(date) => props.onChange(date, "DOB")}
                                                    //onAccept={(date) => onAccept(date, "fromDate")}
                                                    //value={new Date()}
                                                    /> */}
                                                <Calender
                                                    required={true}
                                                    label="Date Of Birth"
                                                    value={DOB}
                                                    placeholder="Select Date of Birth"
                                                    getSelectedDate={(date) => { props.onChange(date, "DOB") }}
                                                />
                                            </Col>

                                            <Col md="4" className="p-3">
                                                <MaterialAutoComplete
                                                    required={true}
                                                    startAdornment="Gender"
                                                    selectedItem={gender}
                                                    onSelect={(value, data) => props.onSelect(value, data, "gender")}
                                                    //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                                    //onSelect={props.onKitStatusChange}
                                                    placeholder="Select gender"
                                                    data={serviceType !== "PCR_DAY_2" ? GENDER_LIST : GENDER_LIST_SBS}
                                                />
                                            </Col>


                                            {/* <Col md="4" className="p-3">

                                                <SiteLabelTextField required={true} label="Nationality" value={nationality} onChange={(e) => props.onChange(e.target.value, "nationality")} placeholder="Nationality" />
                                            </Col> */}
                                            {/* <Col md="4" className="p-3"> */}

                                            {/*<SiteLabelTextField label="Health Number" value={healthNumber} onChange={(e) => props.onChange(e.target.value, "healthNumber")} placeholder="National Health Number" />*/}
                                            {/* <SiteLabelTextField
                                                    label="Passport / ID Number"
                                                    value={passportNum ? passportNum : documentNumber}
                                                    onChange={(e) => props.onChange(e.target.value, passportNum ? "passportNum" : "documentNumber")}
                                                    placeholder="Enter Passport / ID Number" /> */}
                                            {/* </Col> */}
                                            <Col md="12" className="p-3">
                                                <SiteLabelTextField label="Comments" value={patientComments} onChange={(e) => props.onChange(e.target.value, "patientComments")} placeholder="Comments" />
                                            </Col>
                                        </Row>
                                        {serviceType !== "PCR_DAY_2" &&
                                            <div className="d-flex p-2 pt-4 font-weight-bold flex-column justify-content-start align-items-start ">
                                                {
                                                    isProducerCheckFitToFly
                                                        ?
                                                        <p style={{
                                                            fontSize: 'initial',
                                                            textAlign: 'justify'
                                                        }} className="pt-1font-weight-bold">
                                                            The individual who has requested this test has indicated that it is required for travel.  If this test is for travel purposes, please could you complete the information required below
                                                        </p>
                                                        :
                                                        <p style={{
                                                            fontSize: 'initial',
                                                            textAlign: 'justify'
                                                        }} className="pt-1font-weight-bold">
                                                            Is a Fit to Fly certificate required?
                                                        </p>
                                                }
                                                <RadioGroup aria-label="" value={isFitToFly} onChange={(e) => props.onChange(e.target.value, "isFitToFly")} name="" style={{
                                                    flexWrap: 'nowrap',
                                                    fontSize: 'initial'
                                                }} className="d-flex flex-row" >
                                                    <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
                                                    <FormControlLabel value={"false"} control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </div>
                                        }
                                        {
                                            (isFitToFly === "true" && serviceType !== "PCR_DAY_2") ?
                                                <Row className="w-100">
                                                    <Col md="4" className="p-3">
                                                        <SiteLabelTextField required={true} label="Document Type" value={documentType} onChange={(e) => props.onChange(e.target.value, "documentType")} placeholder="Document Type" />
                                                    </Col>
                                                    <Col md="4" className="p-3">
                                                        <SiteLabelTextField required={true} label="Document Authority" value={documentAuthority} onChange={(e) => props.onChange(e.target.value, "documentAuthority")} placeholder="Document Authority" />
                                                    </Col>
                                                    <Col md="4" className="p-3">
                                                        <SiteLabelTextField required={true} label="Document Number" value={documentNumber} onChange={(e) => props.onChange(e.target.value, "documentNumber")} placeholder="Document Number" />
                                                    </Col>
                                                    <Col md="4" className="p-3">
                                                        <MaterialAutoCompleteTypeable
                                                            required={true}
                                                            startAdornment="Issuing Country"
                                                            selectedItem={documentCountry}
                                                            onSelect={(value, data) => props.onSelect(value, data, "documentCountry")}
                                                            placeholder="Select Country from a list"
                                                            data={COUNTRIES_LIST}
                                                        />
                                                    </Col>
                                                    <Col md="4" className="p-3">
                                                        <div className="d-flex p-2 font-weight-bold justify-content-center align-items-center">
                                                            {/* <CustomCalender
                                                        required={true}
                                                        value={documentExpiration}
                                                        onAccept={(date) => props.onChange(date, "documentExpiration")}
                                                        label="Document Expiration"
                                                        placeholder="Select Document Expiary Date"
                                                    //onAccept={(date) => onAccept(date, "fromDate")}
                                                    //value={new Date()}
                                                    /> */}
                                                            <Calender
                                                                //label="Test Date"
                                                                required={true}
                                                                value={documentExpiration}
                                                                label="Document Expiration"
                                                                placeholder="Select Document Expiry Date"
                                                                getSelectedDate={(date) => { props.onChange(date, "documentExpiration") }}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                :
                                                null
                                        }
                                    </div>
                                    {/* <div className="d-flex p-2 text-danger font-weight-bold justify-content-start align-items-center ">
                                        * Required
                                    </div> */}
                                    {/* <div className="d-flex p-2 font-weight-bold justify-content-center align-items-center ">
                                        <SiteLabelTextField validationType="NUMERIC" required={true} label="Telephone" value={telephone} onChange={(e) => props.onChange(e.target.value, "telephone")} placeholder="Telephone Number" />
                                    </div> */}
                                </div>
                                <div className="w-100 pt-3 d-flex justify-content-center">
                                    <LoaderButton color="primary" loading={execStatusConfirmDetail.status === "PENDING" ? true : false} onClick={props.onSubmit} classNames="w-75" >
                                        Submit
                                    </LoaderButton>
                                </div>
                            </div>
                        </div>
                    </React.Fragment> : status === "Assigned" || status === "INDIVIDUAL_CONFIRMED_TEST_DETAILS" ?
                        <div>
                            <div style={{ fontSize: '20px' }} className="d-flex font-weight-bolder p-2 text-danger justify-content-center">
                                Details are already submitted before.
                            </div>
                            <div>
                                <div style={{ fontSize: '20px' }} className="font-weight-bold p-2 d-flex justify-content-center">
                                    Following details were submitted
                                </div>

                                <div className="w-100 d-flex align-items-center justify-content-center"
                                    style={{ marginTop: '40px', flexWrap: 'wrap', flexDirection: 'row' }}
                                >
                                    {/*<div className="d-flex justify-content-center w-50">*/}
                                    {/*    <img src={`${process.env.PUBLIC_URL}/image_submission.png`} />*/}
                                    {/*</div>*/}
                                    <div >
                                        <div className="p-2" style={{ fontWeight: 'bold', fontSize: '18px' }}> Schedule Test Details </div>
                                        <div className="d-flex p-2 ">
                                            <div className="font-weight-bold">
                                                Event / Shoots
                                            </div>
                                            <div className="pl-3">
                                                {eventName}
                                            </div>
                                        </div>
                                        <div className="d-flex p-2 ">
                                            <div className="font-weight-bold">
                                                Test Date
                                            </div>
                                            <div className="pl-3">
                                                {dateOfTest ? dateOfTest : ''}
                                            </div>
                                        </div>
                                        <div className="d-flex p-2 ">
                                            <div className="font-weight-bold">
                                                Available Time Window
                                            </div>
                                            <div className="pl-3">
                                                {preferredTime ? preferredTime : ''}
                                            </div>
                                        </div>
                                        <div className="d-flex p-2 ">
                                            <div className="font-weight-bold">
                                                Test PostCode
                                            </div>
                                            <div className="pl-3">
                                                {postcode}
                                            </div>
                                        </div>
                                        <div className="d-flex p-2 ">
                                            <div className="font-weight-bold">
                                                Test Address
                                            </div>
                                            <div className="pl-3">
                                                {addressLine1 ? addressLine1 : ''} {addressLine2 ? addressLine2 : ''}
                                            </div>
                                        </div>
                                        {country?.length > 0 &&
                                            <div className="d-flex p-2 ">
                                                <div className="font-weight-bold">
                                                    Test Country
                                                </div>
                                                <div className="pl-3">
                                                    {country?.length > 0 ? country : ''}
                                                </div>
                                            </div>
                                        }


                                        <div className="mt-3 p-2 " style={{ fontWeight: 'bold', fontSize: '18px' }}>
                                            Individual Personal Details
                                        </div>

                                        <div className="d-flex p-2">
                                            <div className="font-weight-bold">
                                                Full Name
                                            </div>
                                            <div className="pl-3">
                                                {givenName} {familyName}
                                            </div>
                                        </div>
                                        <div className="d-flex p-2">
                                            <div className="font-weight-bold">
                                                Email
                                            </div>
                                            <div className="pl-3">
                                                {email ? email : ''}
                                            </div>
                                        </div>
                                        <div className="d-flex p-2">
                                            <div className="font-weight-bold">
                                                Telephone
                                            </div>
                                            <div className="pl-3">
                                                {telephone ? telephone : ''}
                                            </div>
                                        </div>
                                        <div className="d-flex p-2">
                                            <div className="font-weight-bold">
                                                Home PostCode
                                            </div>
                                            <div className="pl-3">
                                                {homeAddressPostcode}
                                            </div>
                                        </div>
                                        <div className="d-flex p-2">
                                            <div className="font-weight-bold">
                                                Home Address
                                            </div>
                                            <div className="pl-3">
                                                {homeAddressLine1 ? homeAddressLine1 : ''} {homeAddressLine2 ? homeAddressLine2 : ''}
                                            </div>
                                        </div>
                                        {homeAddressCountry?.length > 0 &&
                                            <div className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Home Country
                                                </div>
                                                <div className="pl-3">
                                                    {homeAddressCountry?.length > 0 ? homeAddressCountry : ''}
                                                </div>
                                            </div>
                                        }
                                        {moment(DOB).format('DD/MM/YYYY') &&
                                            <div className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Date of birth
                                                </div>
                                                <div className="pl-3">
                                                    {moment(DOB).format('DD/MM/YYYY') ? moment(DOB).format('DD/MM/YYYY') : ''}
                                                </div>
                                            </div>
                                        }
                                        {gender &&
                                            <div className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Gender
                                                </div>
                                                <div className="pl-3">
                                                    {gender ? gender?.value : ''}
                                                </div>
                                            </div>
                                        }
                                        {patientComments &&
                                            <div className="d-flex p-2">
                                                <div className="font-weight-bold">
                                                    Your Comments
                                                </div>
                                                <div className="pl-3">
                                                    {patientComments ? patientComments : ''}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div > :
                        status === "CONFIRMATION_PENDING" ?
                            <div className="d-flex font-weight-bolder p-5 text-danger justify-content-center">
                                Please wait for Admin to accept the producer changes of your form
                            </div>
                            :
                            <div className="d-flex font-weight-bolder p-5 text-danger justify-content-center">
                                No Details Found
                            </div>
            }
        </div >
    )
}
export default Form