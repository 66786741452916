import ReactDOM from 'react-dom'
import React, { useState, useEffect } from 'react'
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom'
import { Row } from './containers'
import clsx from 'clsx'
import { Button } from './Button'
import { useAuth, useAdmin } from '../utils/backend'
import beaconSecureLogo from '../assets/images/beacon-secure-logo.png'
import beaconSecureMenu from '../assets/images/logo192.png'

import { useBreakpoint } from '../utils/breakpoints'
import { HamburgerIcon } from './HamburgerIcon'
import phoneIcon from '../assets/images/phone-icon.svg'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAllOrganisations } from '../view.updater/actions/app.actions'
import SiteLabelTextField from "./common/site.label.textfield/site.label.textfield";
import { Col } from "reactstrap";
import { COUNTRIES_LIST } from "./common/util/util";
import MaterialAutoCompleteTypeable from "./common/material.autocomplete.typeable/material.autocomplete.typeable";
//TODO REFACTORED
const BeaconLogo = (props) => (
	<div className="d-flex">
		<img src={beaconSecureLogo} width="100" height="40" alt="Beacon Secure Logo" />
	</div>
)

// const BeaconMenu = (props) => (
// 	<div>
// 		<img src={beaconSecureMenu} width="40" height="40" alt="Beacon Secure Menu" />
// 	</div>
// )

const LoginAndOutButton = ({ onLogoutClick, isLoggingOut, iCustomerPage = true }) => {
	const { authStatus } = useAuth()

	if (authStatus === 'NotAuthorized') {
		return (
			<Button fitted alternative href="/login">
				Log in
			</Button>
		)
	} else if (authStatus === 'Authorizing') {
		return (
			<Button fitted alternative onClick={onLogoutClick} loading>
				Log in
			</Button>
		)
	} else if (authStatus === 'Authorized') {
		if (!iCustomerPage) {
			return (
				<Button fitted alternative href="/customer">
					My account
				</Button>
			)
		}
		return (
			<Button fitted alternative onClick={onLogoutClick} loading={isLoggingOut}>
				Log out
			</Button>
		)
	}
}

const Menu = ({ isHomePageMode }) => {
	return (
		<>
			{/* {isHomePageMode && (
				<NavLink className="header navigation" activeClassName="active" to="/about-us">
					About us
				</NavLink>
			)} */}
			{/* {isHomePageMode && (
				<NavLink className="header navigation" activeClassName="active" to="/test-to-release">
					Test to Release
				</NavLink>
			)} */}
			{isHomePageMode && (
				<NavLink className="header navigation" activeClassName="active" to="/for-individuals">
					Individuals & families
				</NavLink>
			)}
			{isHomePageMode && (
				<NavLink className="header navigation" activeClassName="active" to="/for-businesses">
					Organizations
				</NavLink>
			)}
			{isHomePageMode && (
				<NavLink
					className="header navigation"
					activeClassName="active"
					to="/for-health-practitioners"
				>
					Health practitioners
				</NavLink>
			)}
			{isHomePageMode && (
				<NavLink className="header navigation" activeClassName="active" to="/test-kit-instructions">
					FAQ
				</NavLink>
			)}
			{isHomePageMode && (
				<NavLink className="header navigation" activeClassName="active" to="/contact-us">
					Contact
				</NavLink>
			)}
		</>
	)
}

const AuthButtons = ({ isHomePageMode, onLogoutClick, isLoggingOut, iCustomerPage }) => {
	const { customer, authStatus } = useAuth()
	return (
		<>
			{isHomePageMode && (
				<>
					{/* <Button fitted href="/test-results">
					Activate kit
				</Button> */}
					<Button fitted href="/test-results?shouldStartAgain=true">
						Get my test results
					</Button>
				</>
			)}
			{/* {
				authStatus === 'Authorized' && customer.isSuperUser && (
					<Button fitted href="/admin">
						Admin menu
				</Button>
				)

			} */}

			<LoginAndOutButton
				onLogoutClick={onLogoutClick}
				isLoggingOut={isLoggingOut}
				iCustomerPage={iCustomerPage}
			/>
		</>
	)
}

const DesktopNavigationBar = ({ isLoggingOut, isHomePageMode, isPortalMode, onLogoutClick, organisations,
	organisationId, handleChange, backendLoading, }) => {

	const location = useLocation()
	const [organization, setOrganization] = useState(null)

	const iCustomerPage = location.pathname.indexOf('/customer') !== -1

	useEffect(() => {
		// if (organisations && organisations.length > 0) {
		// 	const checkOrg = organisations.length > 0 && `${organisations[0]?.displayName}-${organisations[0]?.organisationId}`;
		// 	setOrganization({ name: checkOrg })
		// 	const arrSplit = checkOrg?.length > 0 && checkOrg?.split("-");
		// 	const handleValue = arrSplit.length === 2 ? arrSplit[1] : arrSplit.length === 3 ? `${arrSplit[1]}-${arrSplit[2]}` : '';
		// 	handleChange(handleValue)
		// }
		if (organization === null && organisationId() != null) {
			setOrganization(organisationId())
		}
	}, [organization, organisationId()])

	const orgDropdown = organisations && organisations?.map((item) => {
		return { value: `${item?.displayName}-${item?.organisationId}`, name: `${item?.displayName}-${item?.organisationId}` }
	})

	const getOrg = (value) => {
		const org = orgDropdown.filter((item) => { return item.value.toLowerCase().includes(value.toLowerCase()) })
		if (org.length > 0) {
			return org[0]
		}
		else {
			return null
		}
	}

	const organizationSet = (value) => {
		if (value) {
			console.log(value)
			setOrganization(value);

			const arrSplit = value?.split("-");

			const updateArr = arrSplit?.slice(1, arrSplit?.length).join("-");

			console.log('handleValue', updateArr)
			handleChange(updateArr)
		}

	}

	return (
		<>
			<Row
				className="tablet-and-desktop"
				style={{
					padding: '20px 150px',
					borderBottom: '0.1rem solid #c7d4da',
					width: '100%',
				}}
			>
				<div className="flex">
					<img style={{ width: '32px', height: '32px' }} src={phoneIcon} alt="phone icon" />
					<span
						style={{
							height: '24px',
							padding: '8px',
							fontSize: '15px',
							fontWeight: 'bold',
							fontColor: '#000000',
						}}
					>
						<a href="tel:08000786112">0800 078 6112</a> | available 24/7
					</span>
				</div>
				<div className="w-100 flex-wrap" style={{ display: 'flex', justifyContent: 'space-between' }}>
					{/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
					style={{ width: '250px', height: '24px' }}
					*/}
					<div className="d-flex flex-wrap " style={{ width: '10%' }}>
						{isHomePageMode && (
							<Link className="link-button" to="/">
								<BeaconLogo />
							</Link>
						)}

						{isPortalMode && (
							<Link className="link-button" to="/">
								<BeaconLogo />
							</Link>
						)}
					</div>

					{/*{isPortalMode && organisations?.length > 0 &&*/}
					{/*	<div>{organization}</div>*/}
					{/*}*/}

					<div className="d-flex" style={{ width: '90%' }} >
						{isPortalMode && organisations.length > 0 && (
							<div className="d-flex">

								<select style={{
									boxShadow: '0px 8px 8px 0 rgba(0, 0, 0, 0.1)',
									border: 'none', marginRight: '20px'
								}}
									value={organisationId()}
									onChange={handleChange}
									disabled={backendLoading}>
									<option key="" value="">
										None
									</option>

									{organisations.map((org) => (
										<option key={org.organisationId} value={org.organisationId}>
											{org.displayName} - {org.organisationId}
										</option>
									))}
								</select>
								{/*
								<SiteLabelTextField label=""
									value=""
									onChange={(e) => console.log("homeAddressLine1")}
									placeholder="Filter Organization" /> */}
								{/*{*/}
								{/*	organization &&*/}
								{/*	<MaterialAutoCompleteTypeable*/}
								{/*		startAdornment=""*/}
								{/*		editableTime={true}*/}
								{/*		required={false}*/}
								{/*		organizationDropdown={true}*/}
								{/*		selectedItem={getOrg(organization)}*/}
								{/*		// onSelect={(value, data) => props.onSelect(value, data, "homeAddressCountry")}*/}
								{/*		onSelect={(value, data) => organizationSet(value)}*/}
								{/*		placeholder={`Select Organization`}*/}
								{/*		data={orgDropdown}*/}
								{/*	/>*/}
								{/*}*/}

							</div>
						)}
						<div className={clsx('d-flex nav-menu-buttons', { menuHidden: !isHomePageMode })}>
							<Menu isHomePageMode={isHomePageMode} />
							<div className="d-flex">
								<AuthButtons
									onLogoutClick={onLogoutClick}
									isHomePageMode={isHomePageMode}
									isLoggingOut={isLoggingOut}
									iCustomerPage={iCustomerPage}
								/>
							</div>
						</div>
					</div>
				</div>
			</Row>
			{/*<div*/}
			{/*	className="d-flex tablet-and-desktop"*/}
			{/*	style={{*/}
			{/*		display: 'flex',*/}
			{/*		flexDirection: 'row',*/}
			{/*		justifyContent: 'center',*/}
			{/*		width: '100%',*/}
			{/*		borderBottom: '0.1rem solid #c7d4da',*/}
			{/*	}}*/}
			{/*>*/}
			{/*	<Row*/}
			{/*		className="tablet-and-desktop"*/}
			{/*		style={{ alignItems: 'center', justifyContent: 'space-around', height: '72px' }}*/}
			{/*	>*/}

			{/*		{isPortalMode && organisations.length > 0 && (*/}
			{/*			<div className="d-flex">*/}
			{/*				<select value={organisationId()} onChange={handleChange} disabled={backendLoading}>*/}
			{/*					<option key="" value="">*/}
			{/*						None*/}
			{/*					</option>*/}

			{/*					{organisations.map((org) => (*/}
			{/*						<option key={org.organisationId} value={org.organisationId}>*/}
			{/*							{org.displayName} - {org.organisationId}*/}
			{/*						</option>*/}
			{/*					))}*/}
			{/*				</select>*/}

			{/*				<div className={clsx('d-flex nav-menu-buttons', { menuHidden: !isHomePageMode })}>*/}
			{/*					<Menu isHomePageMode={isHomePageMode} />*/}
			{/*					<div className="d-flex">*/}
			{/*						<AuthButtons*/}
			{/*							onLogoutClick={onLogoutClick}*/}
			{/*							isHomePageMode={isHomePageMode}*/}
			{/*							isLoggingOut={isLoggingOut}*/}
			{/*							iCustomerPage={iCustomerPage}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		)}*/}


			{/*	</Row>*/}
			{/*</div>*/}
		</>
	)
}

const MobileNavigationMenu = ({
	isHomePageMode,
	isPortalMode,
	isTablet,
	isLoggingOut,
	isMenuOpen,
	onMenuToggle,
	onLogoutClick,
	organisations,
	organisationId,
	handleChange,
	backendLoading,
}) => {
	const navBar = document.querySelector('#mobile-nav-bar')



	return ReactDOM.createPortal(
		<>
			<div className="mobile-nav-menu-header">
				<Link className="link-button" to="/">
					<BeaconLogo />
				</Link>
				{isPortalMode && organisations.length > 0 && (
					<div>
						<select value={organisationId()} onChange={handleChange} disabled={backendLoading}>
							<option key="" value="">
								None
							</option>
							{organisations.map((org) => (
								<option key={org.organisationId} value={org.organisationId}>
									{org.displayName} - {org.organisationId}
								</option>
							))}
						</select>
					</div>
				)}
				<div className={clsx('d-flex mobile-nav-menu-buttons', { menuHidden: !isHomePageMode })}>
					{isTablet && (
						<>
							<AuthButtons
								onLogoutClick={onLogoutClick}
								isHomePageMode={isHomePageMode}
								isLoggingOut={isLoggingOut}
							/>
						</>
					)}
					{!isPortalMode && (
						<Button chromeless onClick={onMenuToggle}>
							Menu &nbsp;
							<HamburgerIcon opened={isMenuOpen} />
						</Button>
					)}
				</div>
			</div>
			<div className="mobile-nav-menu">
				{isPortalMode && (
					<Link className="link-button" to="/customer">
						<BeaconLogo />
					</Link>
				)}
				<Menu isHomePageMode={isHomePageMode} />
				{!isTablet && (
					<AuthButtons
						onLogoutClick={onLogoutClick}
						isHomePageMode={isHomePageMode}
						isLoggingOut={isLoggingOut}
					/>
				)}
			</div>
		</>,
		navBar
	)
}

const MobileNavigationBar = ({
	onLogoutClick,
	isLoggingOut,
	isHomePageMode,
	isPortalMode,
	isTablet,
	organisations,
	organisationId,
	handleChange,
	backendLoading,
}) => {
	const { pathname } = useLocation()
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	useEffect(() => {
		if (isMenuOpen === true) {
			setIsMenuOpen(false)
		}
	}, [pathname])

	useEffect(() => {
		const navBar = document.querySelector('#mobile-nav-bar')
		const body = document.querySelector('body')

		if (isMenuOpen) {
			navBar.className = 'opened'
			body.className = 'modal-visible'
		} else {
			navBar.className = ''
			body.className = ''
		}
	}, [isMenuOpen])

	return (
		<>
			<MobileNavigationMenu
				onLogoutClick={onLogoutClick}
				isLoggingOut={isLoggingOut}
				isHomePageMode={isHomePageMode}
				isPortalMode={isPortalMode}
				isMenuOpen={isMenuOpen}
				isTablet={isTablet}
				onMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
				organisations={organisations}
				organisationId={organisationId}
				handleChange={handleChange}
				backendLoading={backendLoading}
			/>
		</>
	)
}

const NvgBar = (props) => {
	const [logoutLoading, setLogoutLoading] = useState(false)
	const [organisations, setOrganisations] = useState([])
	const [loading, setLoading] = useState(false)
	const { getAllOrgs, setSelectedOrganisation, backendLoading } = useAdmin()

	const { signOut, customer, defaultOrganisation } = useAuth()
	const location = useLocation()
	const history = useHistory()
	const { isMobile, isTablet, isDesktop } = useBreakpoint()

	useEffect(() => {
		if (!loading && customer && customer.isSuperUser && organisations.length === 0) {
			props.getAllOrganisations();
			setLoading(true)
			getAllOrgs()
				.then((values) => {
					setOrganisations(values.data)
					setLoading(false)
				})
				.catch((error) => {
					setLoading(false)
				})
		}
	}, [organisations.length, customer && customer.isSuperUser])

	const handleLogoutClick = async () => {
		setLogoutLoading(true)

		try {
			await signOut()
			document.cookie.split(';').forEach(function (c) {
				document.cookie = c.trim().split('=')[0] + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
			});
			// history.push('/')
			window.location.reload();
		} catch (error) {
			console.log('LogoutError:', error)
		} finally {
			setLogoutLoading(false)
		}

		// setLogoutLoading(false)
		// history.push('/')
	}

	const isPortalMode = location.pathname.indexOf('/customer') === 0
	const isHomePageMode = !isPortalMode

	const handleChange = (event) => {
		setSelectedOrganisation(event.target.value)
	}

	return (
		<>
			{isMobile && (
				<MobileNavigationBar
					onLogoutClick={handleLogoutClick}
					isLoggingOut={logoutLoading}
					isHomePageMode={isHomePageMode}
					isPortalMode={isPortalMode}
					organisations={organisations}
					organisationId={defaultOrganisation}
					handleChange={handleChange}
					backendLoading={backendLoading}
				/>
			)}
			{isTablet && (
				<MobileNavigationBar
					onLogoutClick={handleLogoutClick}
					isLoggingOut={logoutLoading}
					isHomePageMode={isHomePageMode}
					isPortalMode={isPortalMode}
					isTablet
					organisations={organisations}
					organisationId={defaultOrganisation}
					handleChange={handleChange}
					backendLoading={backendLoading}
				/>
			)}
			{isDesktop && (
				<DesktopNavigationBar
					onLogoutClick={handleLogoutClick}
					isLoggingOut={logoutLoading}
					isHomePageMode={isHomePageMode}
					isPortalMode={isPortalMode}
					organisations={organisations}
					organisationId={defaultOrganisation}
					handleChange={handleChange}
					backendLoading={backendLoading}
				/>
			)}
		</>
	)
}
const mapStateToProps = () => createStructuredSelector({

});

const mapDispatchToProps = (dispatch) => ({
	getAllOrganisations: () => dispatch(getAllOrganisations())
});
let NavigationBar = connect(mapStateToProps, mapDispatchToProps)(NvgBar);
export { NavigationBar }
