import React from 'react'
import { useAuth } from '../../utils/backend'
import { useHistory } from 'react-router-dom'
import { withAuthentication } from '../../utils'
import { PasswordChangeForm } from './PasswordChangeForm'

const ChangePasswordPage = withAuthentication(() => {
	const { updateCustomerPassword } = useAuth()
	const history = useHistory()

	const handlePasswordSubmit = async ({ password }) => {
		return updateCustomerPassword({ nextPassword: password })
			.then(() => {
				history.push('/customer')
				return true
			})
			.catch((error) => {
				return error
			})
	}

	return <PasswordChangeForm onPasswordSubmit={handlePasswordSubmit} />
})

export { ChangePasswordPage }
