import React, { useEffect } from 'react'
import { Paper } from "@material-ui/core";
import { IsMobileWidth } from "components/common/util/util";
import Pagination from '@material-ui/lab/Pagination';
import { format24HrDateTime, formatDate } from 'components/common/util/util';
import clsx from 'clsx';
import { Skeleton } from "@material-ui/lab";
import moment from 'moment';

const InProgressTest = (props) => {
    useEffect(() => {
        props.getKits({
            pageIndex: 0,
            kitStatus: "Assigned"
        })
    }, [])
    const mobileWidth = IsMobileWidth();
    const { kits, execStatusGetKits, selectedEvent } = props;
    const onPageChange = (event, pageNumber) => {
        window.scrollTo(0, 0);
        props.getKits({
            pageIndex: pageNumber - 1,
            kitStatus: "Assigned",
            eventId: selectedEvent && selectedEvent.value,
        })
    }
    let totalCount = 0;
    if (kits && kits.get('totalCount')) {
        totalCount = Math.ceil(kits.get('totalCount') / kits.get('limit'))
    }
    return (
        <div id="kit-list">
            <div className="w-100 d-flex justify-content-start">
                {!(mobileWidth) ? (
                    <div className="d-flex flex-column w-100">
                        <div className="pt-3">
                            <div className="w-100 d-flex pb-2 pt-2 p-1">
                                <div style={{ width: '2%' }} className=" d-flex justify-content-center align-items-center">
                                    <b>No</b>
                                </div>
                                <div className="w-10  pl-2 d-flex align-items-center">
                                    <b>Test Date</b>
                                </div>
                                <div className="w-10  pl-2 d-flex align-items-center">
                                    <b>Preferred Time</b>
                                </div>
                                <div className="w-10 pl-2 d-flex align-items-center">
                                    <b>Service Type</b>
                                </div>
                                <div className="w-10  pl-2 d-flex  align-items-center">
                                    <b>Full Name</b>
                                </div>
                                <div className="w-15  pl-2 d-flex  align-items-center">
                                    <b>Email Address</b>
                                </div>
                                <div className="w-10  pl-2 d-flex  align-items-center">
                                    <b>Telephone</b>
                                </div>
                                <div className="w-10  pl-2 d-flex  align-items-center">
                                    <b>Address</b>
                                </div>
                                <div className="w-5  pl-2 d-flex  align-items-center">
                                    <b>Post Code</b>
                                </div>
                                <div style={{ width: '8%' }} className=" pl-2 d-flex align-items-center">
                                    <b>Event</b>
                                </div>
                                <div className="w-10 ml-2 d-flex align-items-center">
                                    <b>Last Updated</b>
                                </div>
                            </div>
                        </div>
                        {
                            kits && kits.get('kitsArray') && kits.get('kitsArray').map((kit, index) => {
                                return <Paper key={index} className={clsx("w-100 paper-root d-flex py-4 px-2 border-radius-10", index % 2 === 0 && "bg-light")} elevation={1}>
                                    <div style={{ width: '2%' }} className=" d-flex justify-content-start align-items-center border-right">
                                        <div className="">
                                            {/* {
                                                kit.get('partnerLab') === "catalyst" ?
                                                    <Tooltip title="Same Day Test">
                                                        <Rating max={1} value={1} size="large" />
                                                    </Tooltip> : null
                                            } */}
                                        </div>
                                        <div className="pl-2">
                                            {index + 1 + (kits.get('pageIndex') * kits.get('limit'))}
                                        </div>
                                    </div>
                                    <div className="w-10 pl-2 d-flex justify-content-start align-items-center border-right">
                                        <b> {formatDate(moment(kit.get('dateOfTest'), "DD/MM/YYYY"))}</b>
                                    </div>
                                    <div className="w-10 pl-2 d-flex  justify-content-start align-items-center  border-right">
                                        {kit.get("preferredTime")}
                                    </div>
                                    <div className="w-10 pl-2 d-flex justify-content-start align-items-center border-right">
                                        {kit.get("serviceTypeText")}
                                    </div>
                                    <div className="w-10  pl-2 d-flex justify-content-start align-items-center border-right">
                                        {kit.get("fullName") ? kit.get("fullName") : `${kit.get('firstName')} ${kit.get('lastName')}`}
                                    </div>
                                    <div style={{ wordBreak: 'break-all' }} className="w-15 pl-2 d-flex justify-content-start align-items-center border-right">
                                        {kit.get("email")}
                                    </div>
                                    <div className="w-10  pl-2 d-flex justify-content-start align-items-center border-right">
                                        {kit.get("telephone")}
                                    </div>
                                    <div className="w-10  pl-2 d-flex justify-content-start align-items-center border-right">
                                        {kit.get("addressLine1")}
                                    </div>
                                    <div className="w-5  pl-2 d-flex justify-content-start align-items-center border-right">
                                        {kit.get("postcode")}
                                    </div>
                                    <div style={{ width: '8%' }} className=" pl-2 d-flex justify-content-start align-items-center border-right">
                                        {kit.get("eventName")}
                                    </div>
                                    <div className="w-10 pl-2 d-flex justify-content-start align-items-center border-right">
                                        {format24HrDateTime(kit.get('updatedAt'))}
                                    </div>

                                </Paper>
                            })
                        }
                        {
                            totalCount > 1 ?
                                <div className="d-flex justify-content-center pt-4">
                                    <Pagination
                                        page={kits.get('pageIndex') + 1}
                                        onChange={onPageChange}
                                        count={totalCount} color="primary" />
                                </div> : null
                        }
                    </div>
                ) : (
                    <div >
                        {
                            kits && kits.get('kitsArray') && kits.get('kitsArray').map((kit, index) => {
                                return <div key={index} className="d-flex flex-wrap justify-content-center w-100 mt-2 ml-2">
                                    <Paper elevation={1} className="w-100 paper-root d-flex flex-column p-3 mt-2">
                                        {/* <div className="d-flex w-100 justify-content-between">
                                            {
                                                kit.get('partnerLab') === "catalyst" ?
                                                    <Tooltip title="Same Day Test">
                                                        <Rating max={1} value={1} size="large" />
                                                    </Tooltip> : null
                                            }
                                        </div> */}
                                        <div className="d-flex w-100 justify-content-between">
                                            <div> {kit.get("fullName") ? kit.get("fullName") : `${kit.get('firstName')} ${kit.get('lastName')}`} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>   {kit.get("email")} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>     {kit.get("telephone")}</div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>    {kit.get("addressLine1")} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>    {kit.get("postcode")} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <b> {formatDate(moment(kit.get('dateOfTest'), "DD/MM/YYYY"))}</b>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>  {`Date tested ${format24HrDateTime(kit.get("catalyst_sampleCollectAt"))}`} </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {kit.get("preferredTime")}
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {kit.get("serviceTypeText")}
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {kit.get("eventName")}
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            {`Updated at ${format24HrDateTime(kit.get('updatedAt'))}`}
                                        </div>
                                    </Paper>
                                </div>
                            })
                        }
                        {
                            totalCount > 1 ?
                                <div className="d-flex justify-content-center pt-3">
                                    <Pagination
                                        page={kits.get('pageIndex') + 1}
                                        onChange={onPageChange}
                                        count={totalCount} color="primary" />
                                </div> : null
                        }
                    </div>
                )}
            </div>
            {
                execStatusGetKits && execStatusGetKits.status === "PENDING" ?
                    <div>
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                        <Skeleton variant="text" width="100%" height={45} />
                    </div> : null
            }
            {
                kits && kits.get('kitsArray') && kits.get('kitsArray').size == 0 ?
                    <Paper className="paper-root p-4 text-danger d-flex justify-content-center">
                        No Data Found
                    </Paper> : null
            }
            {execStatusGetKits && execStatusGetKits.status === "ERROR" ? (
                <Paper className="paper-root">
                    <div className="text-danger d-flex justify-content-center font-italic pt-2 pb-2">
                        <b>
                            {" "}
                            {execStatusGetKits.errorDetail && typeof execStatusGetKits.errorDetail === 'object'
                                ? execStatusGetKits.errorDetail.get("description")
                                : "Something isn’t working as it should, please try again"}
                        </b>
                    </div>
                </Paper>
            ) : null}
        </div>
    )
}

export default InProgressTest