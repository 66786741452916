//SAME_DAY_ORDER_CHANGES
import { sortableUid } from './sortableUid'

const { formatShortDate, formatDate, formatCurrency } = require('./formatters')

const daysToMs = (days) => 24 * 60 * 60 * 1000 * days

export const createProfessionalOrder = (
	pricePerKit,
	deliveryEstimates,
	numKits,
	address,
	practitioner
) => {
	const subTotal = numKits * pricePerKit
	const vat = subTotal * 0.2
	// disable adding vat to total
	// const total = subTotal + vat
	const total = subTotal
	const email = address && address.recipientEmail

	const [minDeliveryEstimate, maxDeliveryEstimate] = deliveryEstimates
	const minEstimateInMs = daysToMs(minDeliveryEstimate)
	const maxEstimateInMs = daysToMs(maxDeliveryEstimate)

	const numberOfKitsText = numKits === 1 ? '1 kit' : `${numKits} kits`
	const orderType = 'professional-kits'
	const orderTypeText = 'Professional Kits'

	const subTotalText = formatCurrency(subTotal)
	const vatText = formatCurrency(vat)
	const totalText = formatCurrency(total)

	const orderDatetime = new Date()
	const orderDate = orderDatetime.toISOString().split('T')[0]
	const orderDateText = formatDate(orderDatetime)
	const minDeliveryEstimateFromOrderDate = new Date(Date.now() + minEstimateInMs)
	const maxDeliveryEstimateFromOrderDate = new Date(Date.now() + maxEstimateInMs)
	const estimatedDeliveryDateText = `${formatShortDate(
		minDeliveryEstimateFromOrderDate
	)} – ${formatDate(maxDeliveryEstimateFromOrderDate)}`

	return {
		orderUid: sortableUid(),
		orderDate,
		createdAt: Date.now(),
		orderType,
		numKits,
		subTotal: subTotalText,
		vat: vatText,
		total: totalText,
		patients: [],
		address,
		email,
		confirmationDisplayText: {
			subTotalText,
			vatText,
			totalText,
			numberOfKitsText,
			orderTypeText,
			orderDateText,
			estimatedDeliveryDateText,
		},
	}
}

export const createSelfTestOrder = (pricePerKit, deliveryEstimates, patients, courier) => {
	const numKits = patients.length
	const subTotal = numKits * pricePerKit
	const vat = subTotal * 0.2
	// disable adding vat to total
	// const total = subTotal + vat
	const total = subTotal

	const [minDeliveryEstimate, maxDeliveryEstimate] = deliveryEstimates
	const minEstimateInMs = daysToMs(minDeliveryEstimate)
	const maxEstimateInMs = daysToMs(maxDeliveryEstimate)

	const numberOfKitsText = numKits === 1 ? '1 kit' : `${numKits} kits`
	const orderType = courier ? 'courier' : 'self-test'
	const orderTypeText = courier ? 'Courier delivered self test kits' : 'Mailed self test kits'

	const subTotalText = formatCurrency(subTotal)
	const vatText = formatCurrency(vat)
	const totalText = formatCurrency(total)

	const orderDatetime = new Date()
	const orderDate = orderDatetime.toISOString().split('T')[0]
	const orderDateText = formatDate(orderDatetime)
	const minDeliveryEstimateFromOrderDate = new Date(Date.now() + minEstimateInMs)
	const maxDeliveryEstimateFromOrderDate = new Date(Date.now() + maxEstimateInMs)
	const estimatedDeliveryDateText = `${formatShortDate(
		minDeliveryEstimateFromOrderDate
	)} – ${formatDate(maxDeliveryEstimateFromOrderDate)}`

	return {
		orderUid: sortableUid(),
		orderDate,
		createdAt: Date.now(),
		orderType,
		numKits,
		subTotal: subTotalText,
		vat: vatText,
		total: totalText,
		patients,
		confirmationDisplayText: {
			subTotalText,
			vatText,
			totalText,
			numberOfKitsText,
			orderTypeText,
			orderDateText,
			estimatedDeliveryDateText,
		},
	}
}
export const createSameDayOrder = (pricePerKit, deliveryEstimates, patients, courier) => {
	const numKits = patients.length
	const subTotal = numKits * pricePerKit
	const vat = subTotal * 0.2
	// disable adding vat to total
	// const total = subTotal + vat
	const total = subTotal

	const [minDeliveryEstimate, maxDeliveryEstimate] = deliveryEstimates
	const minEstimateInMs = daysToMs(minDeliveryEstimate)
	const maxEstimateInMs = daysToMs(maxDeliveryEstimate)

	const numberOfKitsText = numKits === 1 ? '1 kit' : `${numKits} kits`
	const orderType = 'same-day-test';
	const orderTypeText = "Same day order test";

	const subTotalText = formatCurrency(subTotal)
	const vatText = formatCurrency(vat)
	const totalText = formatCurrency(total)

	const orderDatetime = new Date()
	const orderDate = orderDatetime.toISOString().split('T')[0]
	const orderDateText = formatDate(orderDatetime)
	const minDeliveryEstimateFromOrderDate = new Date(Date.now() + minEstimateInMs)
	const maxDeliveryEstimateFromOrderDate = new Date(Date.now() + maxEstimateInMs)
	const estimatedDeliveryDateText = `${formatShortDate(
		minDeliveryEstimateFromOrderDate
	)} – ${formatDate(maxDeliveryEstimateFromOrderDate)}`

	patients = patients.map( patient => {
		const dateObj = new Date(patient.dateOfTest)
		patient.dateOfTest = patient.dateOfTest ? `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getUTCFullYear()}` : ""
		return patient
	})

	return {
		orderUid: sortableUid(),
		orderDate,
		createdAt: Date.now(),
		orderType,
		numKits,
		subTotal: subTotalText,
		vat: vatText,
		total: totalText,
		patients,
		confirmationDisplayText: {
			subTotalText,
			vatText,
			totalText,
			numberOfKitsText,
			orderTypeText,
			orderDateText,
			estimatedDeliveryDateText,
		},
	}
}