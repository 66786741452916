import React from 'react'
import propTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { Controller } from 'react-hook-form'
import { useBeaconForm } from './BeaconForm'
import { inputDefaultPropTypes, inputDefaultPropValues } from './defaults'
import { resolveInputAdendum } from './resolveInputAdendum'
import { Row, Column } from '../containers'
import { sortableUid } from '../../utils'
import { Body } from '../typography'
import { resolveTextInputValidators } from './resolveTextInputValidators'

const createDataList = (id, datalist) => {
	return (
		<datalist id={id}>
			{datalist && datalist.length > 0 && datalist.map((item, index) => (
				<option value={item} />
			))}
		</datalist>
	)
}

const TextInputWithMask = ({
	mask,
	rightAligned,
	pattern,
	required,
	errorMessage,
	disabled,
	datalistId,
	placeholder,
	name,
	type,
}) => {
	const { getValues, control } = useBeaconForm()
	const rules = resolveTextInputValidators(pattern, null, required, errorMessage, getValues)

	return (
		<Controller
			control={control}
			name="dateOfBirth"
			rules={rules}
			render={({ onChange, onBlur, value }) => {
				const handleChange = (event) => {
					// console.log('handleChange:', event.target.value)
					// console.log(
					// 	'regex:',
					// 	/^([0-9][0-9][\s]+\/[\s]+[0-9][0-9][\s]+\/[\s]+[0-9][0-9][0-9][0-9])$/
					// )
					// console.log(
					// 	'passing?',
					// 	/^([0-9][0-9][\s]+\/[\s]+[0-9][0-9][\s]+\/[\s]+[0-9][0-9][0-9][0-9])$/.test(
					// 		event.target.value
					// 	)
					// )
					onChange(event)
				}

				// console.log('Initial:')
				// console.log('handleChange:', value)
				// console.log(
				// 	'regex:',
				// 	/^([0-9][0-9][\s]+\/[\s]+[0-9][0-9][\s]+\/[\s]+[0-9][0-9][0-9][0-9])$/
				// )
				// console.log(
				// 	'passing?',
				// 	/^([0-9][0-9][\s]+\/[\s]+[0-9][0-9][\s]+\/[\s]+[0-9][0-9][0-9][0-9])$/.test(value)
				// )

				return (
					<InputMask
						mask={mask}
						placeholder={placeholder}
						name={name}
						maskChar={null}
						disabled={disabled === true}
						onChange={handleChange}
						onBlur={onBlur}
						value={value}
					>
						{(inputProps) => (
							<input
								className="form-component "
								style={{ textAlign: rightAligned ? 'right' : 'left' }}
								type={type}
								placeholder={placeholder}
								list={datalistId}
								name={name}
							/>
						)}
					</InputMask>
				)
			}}
		/>
	)
}

const StandardTextInput = ({
	rightAligned,
	pattern,
	required,
	confirmationFor,
	errorMessage,
	disabled,
	datalistId,
	placeholder,
	name,
	type,
	min
}) => {
	const { register, getValues } = useBeaconForm()
	const validators = resolveTextInputValidators(
		pattern,
		confirmationFor,
		required,
		errorMessage,
		getValues
	)
	return (
		<input
			className="form-component "
			style={{ textAlign: rightAligned ? 'right' : 'left' }}
			type={type}
			placeholder={placeholder}
			disabled={disabled === true}
			list={datalistId}
			name={name}
			min={min}
			ref={register({ ...validators })}
		/>
	)
}

const FormTextInput = ({
	label,
	placeholder,
	rightAligned,

	disabled,
	labelAdendum,

	datalist,
	mask,
	type,
	name,
    listId,
	pattern,
	required,
	errorMessage,
	confirmationFor,
    min,
	mobileSize,
	tabletSize,
	desktopSize,
}) => {
	const { hasError, error } = useBeaconForm(name)

	const addendum = resolveInputAdendum(labelAdendum, type)
	const datalistId = listId ? listId : datalist ? sortableUid() : ''
	const hasMask = typeof mask === 'string' && mask.length > 1

	const borderClassName = `form-component-border ${hasError ? 'invalid' : ''}`.trim()
	const TextInputComponent = hasMask ? TextInputWithMask : StandardTextInput

	return (
		<Column
			className="form-component"
			mobileSize={mobileSize}
			tabletSize={tabletSize}
			desktopSize={desktopSize}
		>
			<Row mobileSize={mobileSize} tabletSize={tabletSize} desktopSize={desktopSize} spaced>
				<label className="form-component">{label}</label>&nbsp;
				{addendum}
			</Row>
			<Row
				className={borderClassName}
				mobileSize={mobileSize}
				tabletSize={tabletSize}
				desktopSize={desktopSize}
			>
				<TextInputComponent
					mask={mask}
					min={min}
					rightAligned={rightAligned}
					disabled={disabled}
					datalistId={datalistId}
					placeholder={placeholder}
					name={name}
					type={type}
					pattern={pattern}
					required={required}
					errorMessage={errorMessage}
					confirmationFor={confirmationFor}
				/>
			</Row>
			{error && (
				<Body small error>
					{error.message}
				</Body>
			)}
			{datalist && createDataList(datalistId, datalist)}
		</Column>
	)
}

FormTextInput.propTypes = {
	...inputDefaultPropTypes,

	placeholder: propTypes.string,
	datalist: propTypes.arrayOf(propTypes.string),
	mask: propTypes.string,
	type: propTypes.string.isRequired,
	pattern: propTypes.any,
	errorMessage: propTypes.string.isRequired,
}
FormTextInput.defaultProps = {
	...inputDefaultPropValues,

	type: 'text',
}

export { FormTextInput }
