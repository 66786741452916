import React, { useState } from 'react'
import { Column, Row } from './containers'
import { FormTextInput, FormCheckboxInput } from './forms'
import 'react-dropdown/style.css'

const OrderPatientDetailsEditor = ({ editPatientMode, isMailKit, orgs }) => {
	console.log('patient details editor: ', isMailKit)

	// const optionsData = ['courier', 'mail']
	// const defaultOption = optionsData[0]

	// const onSelectChange = (event, form) => {
	// 	console.log('select box: ', event)
	// 	console.log('form : ', form)
	// }

	const emailRow = editPatientMode ? (
		<Row size={8} spacingTop="s">
			<FormTextInput
				desktopSize={8}
				tabletSize={8}
				label="Email"
				name="email"
				required
				pattern={/(.*)@(.*)\.([a-zA-Z]{2,20})/}
				errorMessage="Has to be valid email address"
			/>
		</Row>
	) : (
		<>
			<Row size={8} spacingTop="s" spaced>
				<FormTextInput
					desktopSize={4}
					tabletSize={4}
					label="Contact phone"
					// placeholder={'07212348901'}
					// pattern={/^\d+$/}
					name="telephone"
					required
					errorMessage={`Please enter a valid phone number`}
				/>

				<FormTextInput
					desktopSize={4}
					tabletSize={4}
					label="Confirm phone"
					name="confirmationPhone"
					confirmationFor="telephone"
					errorMessage="Phone numbers must match"
				/>
			</Row>
		</>
	)
	let date = new Date()
	let options = { day: '2-digit', month: '2-digit', year: 'numeric' }
	let today = date.toLocaleDateString('en-GB', options)
	let todayTime = date.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
	})
	console.log('orgs: ', orgs)

	return (
		<>
			<Column size={8}>
				{/* <Dropdown options={optionsData} onChange={onSelectChange} value={defaultOption} /> */}
				<h5>Choose an organisation </h5>
				<FormTextInput
					desktopSize={4}
					tabletSize={4}
					label="Select Organisation"
					datalist={orgs.map((o) => o.organisationId)}
					name="org"
					required
					// pattern={/^(F|M|Unspecified)$/i}
					errorMessage="Please select an oragnisation"
				/>
				<br />
				<h5>Enter patient details</h5>
				<Row size={8} spacingTop="xs" spaced>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						label="First name"
						name="firstName"
						required
						errorMessage="Field is required"
					/>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						label="Last name"
						name="lastName"
						required
						errorMessage="Field is required"
					/>
				</Row>
				{emailRow}
				<Row size={8} spacingTop="s" spaced>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						label="Email"
						name="email"
						required
						pattern={/(.*)@(.*)\.([a-zA-Z]{2,20})/}
						errorMessage="Has to be valid email address"
					/>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						label="Job Number (optional)"
						placeholder={'Your Reference #'}
						name="jobNumber"
						errorMessage={`Please enter a value`}
					/>
				</Row>
				{!isMailKit && (
					<>
						<br />
						<h5>Enter delivery details</h5>
						<Row size={8} spacingTop="xs" spaced>
							<FormTextInput
								desktopSize={4}
								tabletSize={4}
								label="Delivery Date (DD / MM / YYYY)"
								placeholder={today}
								name="deliveryDate"
								required
								pattern={/^(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](20)\d\d$/}
								errorMessage={`Please enter delivery date in DD / MM / YYYY format, e.g: ${today}`}
							/>
							<FormTextInput
								desktopSize={4}
								tabletSize={4}
								label="Delivery before (HH:mm) 24 hour format (optional)"
								name="deliverBy"
								pattern={/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/}
								errorMessage={`Please enter delivery by time in a 24 hour HH:mm format, e.g: ${todayTime}`}
							/>
						</Row>
						<Row size={8} spacingTop="xs" spaced>
							<FormTextInput
								desktopSize={4}
								tabletSize={4}
								label="Delivery Window (optional)"
								placeholder={'1-2pm and after 6pm'}
								name="deliveryWindow"
								errorMessage={`Please enter a delivery window`}
							/>
							<FormTextInput
								desktopSize={4}
								tabletSize={4}
								label="Driver Instructions (optional)"
								placeholder={'Call 15 mins before'}
								name="driverNotes"
								errorMessage={`Please enter delivery by time in a 24 hour HH:mm format, e.g: ${todayTime}`}
							/>
						</Row>
					</>
				)}
				<Row size={8} spacingTop="m">
					<FormTextInput
						desktopSize={8}
						tabletSize={8}
						label="Address line 1"
						name="addressLine1"
						required
						errorMessage="Field is required"
					/>
				</Row>
				<Row size={8} spacingTop="s">
					<FormTextInput
						desktopSize={8}
						tabletSize={8}
						label="Address line 2"
						name="addressLine2"
						errorMessage="Field is required"
					/>
				</Row>
				<Row size={8} spacingTop="s" spaced>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						label="Town/City"
						name="city"
						required
						errorMessage="Field is required"
					/>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						label="Postcode"
						name="postcode"
						required
						errorMessage="Field is required"
					/>
				</Row>
			</Column>
		</>
	)
}

export { OrderPatientDetailsEditor }
