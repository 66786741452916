import './kits.scss';
import React, { useState, useEffect, useContext } from 'react'
import { Paper } from "@material-ui/core";
import { IsMobileWidth } from "components/common/util/util";
import Pagination from '@material-ui/lab/Pagination';
import { format24HrDateTime, formatSBSSwabDate } from 'components/common/util/util';
import clsx from 'clsx';
import { Skeleton } from "@material-ui/lab";
import { Rating } from '@material-ui/lab'
import { Tooltip } from '@material-ui/core'
import { checkExecutionStatus } from 'components/common/util/util';

import { AppContext } from "../../../app.container";
import moment from 'moment';
import { formatDate } from '../../../components/common/util/util';

const KitsList = (props) => {

    const context = useContext(AppContext);

    const mobileWidth = IsMobileWidth();
    const [kits, setkits] = useState([])
    const [pageIndex, setpageIndex] = useState(0)
    const totalCount = 0

    const execWronglyAssignedKits = checkExecutionStatus(context, "GET_ALL_WRONGLY_ASSIGNED_KITS");
    const { wronglyAssignedKits } = props

    useEffect(() => {
        props.getAllWronglyAssignedKits({
            pageIndex: pageIndex,
        })
    }, [])

    const onPageChange = (i, pageNumber) => {
        console.log(pageNumber)
        props.getAllWronglyAssignedKits({
            pageIndex: pageNumber,
        })
        setpageIndex(pageNumber - 1)
    }

    return (
        <div className='w-100' id="kit-list">
            <div className="w-100 d-flex flex-column justify-content-start">
                <div className='w-100'>
                    <div className="pt-3">
                        <div className="w-100 d-flex pb-2 pt-5 p-1">
                            <div className="w-5 d-flex justify-content-start align-items-center">
                                <b>No</b>
                            </div>
                            <div className="w-20 pl-2 d-flex justify-content-start align-items-center">
                                <b>Org ID</b>
                            </div>

                            <div className="w-20 pl-2 d-flex justify-content-start align-items-center">
                                <b>Barcode</b>
                            </div>
                            <div className="w-20 pl-2 d-flex justify-content-start align-items-center">
                                <b>Date of test</b>
                            </div>

                            <div className="w-20 pl-2 d-flex justify-content-start align-items-center">
                                <b>Full Name</b>
                            </div>
                            <div className="w-20 pl-2 d-flex justify-content-start align-items-center">
                                <b>Date of birth</b>
                            </div>
                            <div className="w-20 pl-2 d-flex justify-content-start align-items-center">
                                <b>Email Address</b>
                            </div>
                            <div className="w-20 pl-2 d-flex justify-content-start align-items-center">
                                <b>Reference</b>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (!wronglyAssignedKits || (execWronglyAssignedKits && execWronglyAssignedKits.status === "PENDING")) ?
                        <div className='w-100'>
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                        </div>
                        :
                        <div className='w-100' >
                            {
                                wronglyAssignedKits && wronglyAssignedKits.toJS().length == 0 ?
                                    <>
                                        <Paper className="paper-root p-4 mt-4 text-danger d-flex justify-content-center">
                                            No Data Found
                                        </Paper>
                                        {
                                            <div className="d-flex justify-content-center pt-3">
                                                <Pagination
                                                    page={pageIndex + 1}
                                                    onChange={onPageChange}
                                                    count={20} color="primary" />
                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        {!(mobileWidth) ? (
                                            <div className="d-flex flex-column w-100">

                                                {
                                                    wronglyAssignedKits && wronglyAssignedKits.toJS().map((kit, index) => {
                                                        console.log(kit, "hey")
                                                        return <Paper key={index} className={clsx("w-100 paper-root d-flex py-4 px-2 border-radius-10", index % 2 === 0 && "bg-light")} elevation={1}>
                                                            <div className="w-5 d-flex justify-content-center align-items-center border-right">

                                                                <div className="">
                                                                    {index + 1}
                                                                </div>
                                                            </div>
                                                            <div style={{ wordBreak: 'break-all' }} className="w-20 d-flex  justify-content-start align-items-center pl-2  border-right">
                                                                {kit?.organisationId ? kit?.organisationId : ""}
                                                            </div>
                                                            <div style={{ wordBreak: 'break-all' }} className="w-20 d-flex  justify-content-start align-items-center pl-2  border-right">
                                                                {kit?.sample_id}
                                                            </div>
                                                            <div style={{ wordBreak: 'break-all' }} className="w-20 d-flex  justify-content-start align-items-center pl-2  border-right">
                                                                {formatDate(kit?.sample_created)}
                                                            </div>
                                                            <div style={{ wordBreak: 'break-all' }} className="w-20 d-flex  justify-content-start align-items-center pl-2  border-right">
                                                                {kit?.full_name}
                                                            </div>
                                                            <div style={{ wordBreak: 'break-all' }} className="w-20 d-flex  justify-content-start align-items-center pl-2  border-right">
                                                                {formatDate(kit?.date_of_birth)}
                                                            </div>
                                                            <div style={{ wordBreak: 'break-all' }} className="w-20 d-flex  justify-content-start align-items-center pl-2  border-right">
                                                                {kit?.email}
                                                            </div>
                                                            <div style={{ wordBreak: 'break-all' }} className="w-20 d-flex  justify-content-start align-items-center pl-2  border-right">
                                                                {kit?.home_address?.address_line_4}
                                                            </div>

                                                        </Paper>
                                                    })
                                                }
                                                {
                                                    <div className="d-flex justify-content-center pt-3">
                                                        <Pagination
                                                            page={pageIndex + 1}
                                                            onChange={onPageChange}
                                                            count={20} color="primary" />
                                                    </div>
                                                }
                                            </div>
                                        ) : (
                                            <div >
                                                {
                                                    wronglyAssignedKits && wronglyAssignedKits.toJS().map((kit, index) => {
                                                        return <div key={index} className="d-flex flex-wrap justify-content-center w-100 mt-2 ml-2">
                                                            <Paper elevation={1} className="w-100 paper-root d-flex flex-column p-3 mt-2">
                                                                <div className="d-flex w-100 justify-content-between">
                                                                    <div>{kit.batch_id} </div>
                                                                </div>
                                                                <div className="d-flex w-100 justify-content-between">
                                                                    <div> {kit.email} </div>
                                                                </div>
                                                            </Paper>
                                                        </div>
                                                    })
                                                }
                                                {
                                                    <div className="d-flex justify-content-center pt-3">
                                                        <Pagination
                                                            page={1}
                                                            // onChange={props.onPageChange}
                                                            count={20} color="primary" />
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    </>
                            }
                        </div>

                }

            </div>

            {execWronglyAssignedKits && execWronglyAssignedKits.status === "ERROR" ? (
                <Paper className="paper-root">
                    <div className="text-danger d-flex justify-content-center font-italic pt-2 pb-2">
                        <b>
                            {" "}
                            {execWronglyAssignedKits.errorDetail && typeof execWronglyAssignedKits.errorDetail === 'object'
                                ? execWronglyAssignedKits.errorDetail?.get("description")
                                : "Something isn’t working as it should, please try again"}
                        </b>
                    </div>
                </Paper>
            ) : null}
        </div>
    )
}

export default KitsList