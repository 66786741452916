export const resolveTextInputValidators = (
	pattern,
	confirmationFor,
	required,
	errorMessage,
	getValues
) => {
	if (!errorMessage) {
		throw new Error(`FormTextInput unable to create validator without errorMessage`)
	}

	if (confirmationFor) {
		return {
			validate: {
				confirmsAnotherField: (value) => {
					const anotherValue = getValues()[confirmationFor]
					return value === anotherValue || errorMessage
				},
			},
		}
	} else {
		const validators = {}

		if (pattern) {
			validators.pattern = {
				value: pattern,
				message: errorMessage,
			}
		}

		if (required) {
			validators.required = errorMessage
		}

		return validators
	}
}
