import React, { useState } from 'react'
import { BeaconForm, FormTextInput, SubmitButton } from '../../components/forms'
import { Column, Button, Row, Spacer, Body, Form, TextInput } from '../../components'
import { Route, useHistory } from 'react-router-dom'
import { useRegistration, useRetailPublic } from '../../utils/backend'
import { COUNTRIES_LIST, GENDER_LIST, SERVICE_TYPES } from "../../components/common/util/util";
import MaterialAutoComplete from "../../components/common/material.autocomplete/material.autocomplete";
import MaterialAutoCompleteTypeable
	from "../../components/common/material.autocomplete.typeable/material.autocomplete.typeable";
import ContentDialog from "../../components/common/content.dialog/content.dialog";
import { Typography } from "@material-ui/core";
import Calender from "../../components/common/custom.calender/Calender";
import SiteLabelTextField from "../../components/common/site.label.textfield/site.label.textfield";
import { lastWord, PostCodeDropdown, PostCodeHandle } from "../../components/common/postcode";
import { Col } from "reactstrap";
import {EyePassword} from "./common";

export const SignupPage = (props) => {
	const { invokeCreateRetailCustomer } = useRetailPublic();
	const { registerOrganization, registerProducer } = useRegistration();
	const history = useHistory();
	const [error, setError] = useState(null);
	const [identityType, setIdentityType] = useState(null);
	const [countryCode, setCountryCode] = useState(null);
	const [eye, setEye] = useState(true);

	const [orgName, setOrgName] = useState('');
	const [orgAddress, setOrgAddress] = useState('');
	const [orgEmail, setOrgEmail] = useState('');
	const [orgPassword, setOrgPassword] = useState('');
	const [personName, setPersonName] = useState('');
	const [producerName, setProducerName] = useState('');
	const [email, SetEmail] = useState('');
	const [password, setPassword] = useState('');
	const [postcodeDropdown, setPostcodeDropdown] = useState(false);
	const [inputPostValue, setInputPostValue] = useState("");
	const [resValue, setResValue] = useState('');
	const [errorValue, setErrorValue] = useState([]);
	const [errorOrg, setErrorOrg] = useState('');

	const [producerNameErr, setProducerNameErr] = useState('');
	const [emailErr, setEmailErr] = useState('');
	const [alreadyEmailErr, setAlreadyEmailErr] = useState('');
	const [passErr, setPassErr] = useState('');
	const [orgNameErr, setOrgNameErr] = useState('');
	const [postcodeErr, setPostcodeErr] = useState('');
	const [orgAddressErr, setOrgAddressErr] = useState('');
	const [regexOrgEmailErr, setRegexOrgEmailErr] = useState('');
	const [orgPasswordErr, setOrgPasswordErr] = useState('');
	const [personNameErr, setPersonNameErr] = useState('');
	const [countryCodeErr, setCountryCodeErr] = useState('');

	const handleSignUp = (retailCustomer) => {

		// console.log('Sign up:', retailCustomer)
		// setError(null)
		//
		// // Additional validation here.
		// return invokeCreateRetailCustomer(retailCustomer)
		// 	.then((response) => {
		// 		history.push('/login')
		// 	})
		// 	.catch((error) => {
		// 		console.error('Signup:error:handleSignUp', error)
		// 		setError('Unable to proceed' + error.message)
		// 	})
	}

	const roles = [{ id: '1', name: 'Organization', value: 'ORG' }, { id: '2', name: 'Producer', value: 'PROD' }]

	const SignInRoute = () => {
		history.push("/login");
	}

	const signUp = () => {
		const regexOrgEmail = orgEmail
		const regexEmail = email

		setErrorOrg(false)

		if (identityType?.id === '1') {
			if (orgName && orgAddress && orgPassword?.length >= 6 && countryCode?.value && personName && inputPostValue && orgEmail) {
				registerOrganization({
					userName: personName,
					email: orgEmail,
					password: orgPassword,
					organisationName: orgName,
					country: countryCode?.value,
					details: {
						address: orgAddress,
						postcode: inputPostValue
					}
				})
					.then(res => {
						console.log('Result Org---->', res);
						if (res?.data?.errors[0]?.errorInfo?.code === 'auth/email-already-exists') {
							setErrorOrg(true)
							setAlreadyEmailErr('Email already in use. Please use a different one')
						} else {
							setErrorOrg(false)
							history.push('/login')
						}
					})
					.catch(err => console.log('Error Org ---->', err));
			}
			else {
				setErrorOrg(true)
				if (!orgName) { setOrgNameErr('Please type organization name') }
				if (!orgAddress) { setOrgAddressErr('Please type organization home address') }
				if (!regexOrgEmail) { setRegexOrgEmailErr('Please type organization email address') }
				if (orgPassword?.length < 6) { setOrgPasswordErr('Please type password of at least 6 characters') }
				if (!personName) { setPersonNameErr('please type your name') }
				if (!countryCode?.value) { setCountryCodeErr('please select country') }
				if (!inputPostValue) { setPostcodeErr('please type postcode') }
			}
		}
		else if (identityType?.id === '2') {
			if (producerName && password?.length >= 6 && email) {
				registerProducer({
					userName: producerName,
					email: email,
					password: password
				})
					.then(res => {
						console.log('Result Producer---->', res);
						if (res?.data?.errors[0]?.errorInfo?.code === 'auth/email-already-exists') {
							setErrorOrg(true)
							setAlreadyEmailErr('Email already in use. Please use a different one')
						} else {
							setErrorOrg(false)
							history.push('/login')
						}
					})
					.catch(err => console.log('Error Producer ---->', err));
			} else {
				setErrorOrg(true);
				if (password?.length < 6) { setPassErr('Please type password of at least 6 characters') }
				if (!regexEmail) { setEmailErr('please type correct email address') }
				if (!producerName) { setProducerNameErr('please type your name') }
			}
		}

	}

	const credentials = () => {
		setErrorOrg(false);
		setOrgNameErr(''); setOrgAddressErr(''); setRegexOrgEmailErr('');
		setOrgPasswordErr(''); setPersonNameErr(''); setCountryCodeErr(''); setAlreadyEmailErr('')
		setProducerNameErr(''); setEmailErr(''); setPassErr(''); setPostcodeErr('')
	}

	const handlePostCodeInputClick = (event, eventTargetName) => {
		if (eventTargetName === "prodAddressPostCode") {
			PostCodeHandle(inputPostValue, (item) => {
				setPostcodeDropdown(item?.dropdown);
				setErrorValue(item?.errorValue);
				setResValue(item?.resValue);
			});
		}
	}

	const handlePostCodeInputChange = (event, evnetTargetName) => {
		if (evnetTargetName === "prodAddressPostCode") {
			setInputPostValue(event.target.value);
			// props.onChange(event.target.value, "postcode")
		}
	}

	const addressDetails = (item, eventTargetName) => {
		if (eventTargetName === "prodAddressPostCode") {
			// setPropTarget({addressLine1: "", addressLine2: "", city: ""});
			setOrgAddress(`${item?.StreetAddress}, ${item?.Place}`);
			setPostcodeDropdown(false);
		}
	}

	// function errorsOrgHandle(name,address,email,password,personName){
	// 	setErrorAllOrg({orgName: name, orgAddress: address, regexOrgEmail: email, orgPassword: password,	personName:personName})
	// }

	return (
		<>
			<div className="d-flex flex-row w-100">
				<div className="col-md-6 backgroundSignup" ></div>
				<div className="col-md-6" >
					<div className="d-flex row justify-content-center align-items-center min-vh-100">
						<div>
							<h1>Sign Up</h1>
							<span className="small error">{error || null}</span>

							<Column desktopSize={4} tabletSize={6} spacingTop="m">
								<BeaconForm onSubmit={signUp}>
									<div style={{ marginLeft: '5px' }}>
										<div className="w-100">
											<MaterialAutoComplete
												startAdornment="Identity Type"
												selectedItem={identityType ? identityType : 'Please select Identity'}
												signUp={true}
												//disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
												onSelect={(value, data) => {
													console.log('IDentity data', data)
													// props.onSelect(value, data, "serviceType");
													setIdentityType(data)
												}}
												// {/*}*/}
												placeholder="Select Identity Type"
												data={roles}
											/>
										</div>


										{identityType?.id == 1 &&
											<div>
												<div style={{ marginTop: '10px' }}>

													<TextInput
														label="Organization Name"
														name="displayName"
														value={orgName}
														setter={setOrgName}
														required
														errorMessage="Please provide organization name"
														desktopSize={4}
														tabletSize={6}
													/>
												</div>
												<div style={{ marginTop: '10px' }}>

													<TextInput
														label="Full Name"
														name="displayName"
														value={personName}
														setter={setPersonName}
														required
														errorMessage="Please provide your name"
														desktopSize={4}
														tabletSize={6}
													/>
												</div>
												<div className="position-relative" style={{ marginTop: '10px' }}>
													<div >
														<SiteLabelTextField label="Post Code"
															value={inputPostValue}
															signUp={true}
															// onKeyDown={(e) => { if (e.key === 'Enter') { handlePostCodeInputClick(e, "prodAddressPostCode") } }}
															onChange={(e) => { handlePostCodeInputChange(e, "prodAddressPostCode") }}
															// onChange={(e) => props.onChange(e.target.value, "postcode")}
															placeholder="Post Code" />
														<div className="position-absolute "
															onClick={(e) => { handlePostCodeInputClick(e, "prodAddressPostCode") }}
															style={{ top: '36px', right: '15px', display: 'flex', alignItems: 'center' }}>
															{postcodeDropdown &&
																<i onClick={(e) => { e.stopPropagation(); setPostcodeDropdown(false); }}
																	className="fas fa-times-circle"
																	style={{ marginRight: '10px', color: 'darkred', fontSize: '14px', cursor: 'pointer' }}
																>
																</i>

															}
															<button type="button" className="btn btn-primary">Search</button>
														</div>

														{postcodeDropdown &&
															<PostCodeDropdown
																absoluteLeft={false}
																errorValue={errorValue}
																resValue={resValue}
																signUpPage={true}
																addressDetails={(e) => addressDetails(e, "prodAddressPostCode")}
															/>
														}
													</div>
												</div>
												<div style={{ marginTop: '10px' }}>

													<TextInput
														label="Organization Address"
														name="displayName"
														value={orgAddress}
														setter={setOrgAddress}
														required
														errorMessage="Please provide organization address"
														desktopSize={4}
														tabletSize={6}
													/>
												</div>
												<div style={{ marginTop: '10px' }}>

													<TextInput
														label="Email Address"
														name="email"
														type="email"
														value={orgEmail}
														setter={setOrgEmail}
														required
														errorMessage="Please provide your email"
														desktopSize={4}
														tabletSize={6}
													/>
												</div>
												<div style={{ marginTop: '10px' }}>
													<MaterialAutoCompleteTypeable
														startAdornment="Country"
														signUp={true}
														selectedItem={countryCode ? countryCode : 'PLease Select Country'}
														onSelect={
															(value, data) => setCountryCode(data)
														}
														placeholder="Select Country from a list"
														data={COUNTRIES_LIST}
													/>
												</div>
												<div style={{ marginTop: '10px' }}>
													<div className="position-relative">
														<TextInput
															label="Password"
															name="password"
															value={orgPassword}
															setter={setOrgPassword}
															paswordType={eye}
															desktopSize={4}
															tabletSize={6}
															text
														/>

														<EyePassword
															eye = {eye}
															setEye = {(flag)=>setEye(!flag) }
														/>
													</div>
												</div>
											</div>
										}

										{identityType?.id == 2 &&
											<div>
												<div style={{ marginTop: '10px' }}>
													<TextInput
														label="Person Name"
														name="displayName"
														value={producerName}
														setter={setProducerName}
														required
														errorMessage="Please provide your name"
														desktopSize={4}
														tabletSize={6}
													/>
												</div>
												<div style={{ marginTop: '10px' }}>

													<TextInput
														label="Email Address"
														name="email"
														value={email}
														setter={SetEmail}
														type="email"
														required
														errorMessage="Please provide your email"
														desktopSize={4}
														tabletSize={6}
													/>
												</div>
												<div style={{ marginTop: '10px' }}>
													<div className="position-relative">
														<TextInput
															label="Password"
															name="password"
															value={password}
															setter={setPassword}
															paswordType={eye}
															desktopSize={4}
															tabletSize={6}
															text
														/>

														<EyePassword
															eye = {eye}
															setEye = {(flag)=>setEye(!flag) }
														/>
													</div>
												</div>
											</div>
										}
									</div>


									<Spacer spacing="s" />
									<SubmitButton>Sign Up!</SubmitButton>
								</BeaconForm>
							</Column>

							<div className="mt-4" >
								Already have an account?

								<span className="signUpBtn cursor-pointer"
									onClick={() => SignInRoute()}
								>
									Login
								</span>
							</div>


							{/*<div style={{  marginTop: '20px', marginBottom: '25px' }}>*/}
							{/*<Body centered>Just taken a swab/PCR test?</Body>*/}
							{/*<Body centered href="/test-results">*/}
							{/*	<Button secondary>Get my test results</Button>*/}
							{/*</Body>*/}
							{/*</div>*/}
						</div>
					</div>
				</div>
			</div>

			<ContentDialog
				onClose={() => credentials()}
				dialogIsOpen={errorOrg}
				open={errorOrg}
				title={<div><Typography><b>Error Detail</b></Typography></div>}>
				<div style={{ paddingLeft: '30px' }}>
					<div>{orgNameErr && orgNameErr}</div>
					<div>{orgAddressErr && orgAddressErr}</div>
					<div>{orgPasswordErr && orgPasswordErr}</div>
					<div>{personNameErr && personNameErr}</div>
					<div>{countryCodeErr && countryCodeErr}</div>
					<div>{producerNameErr && producerNameErr}</div>
					<div>{emailErr && emailErr}</div>
					<div>{passErr && passErr}</div>
					<div>{postcodeErr && postcodeErr}</div>
					<div>{alreadyEmailErr && alreadyEmailErr}</div>
					<div>{regexOrgEmailErr && regexOrgEmailErr}</div>
				</div>
			</ContentDialog>

			{/*<Row>*/}
			{/*	<Column desktopSize={4} tabletSize={3} mobileSize={0} spacingTop="m" />*/}
			{/*	<Column desktopSize={4} tabletSize={6} spacingTop="m">*/}
			{/*		<h1>Sign Up</h1>*/}
			{/*		<span className="small error">{error || null}</span>*/}

			{/*		<Column desktopSize={4} tabletSize={6} spacingTop="m">*/}
			{/*			<BeaconForm onSubmit={handleSignUp}>*/}
			{/*				<div style={{ marginLeft: '15px' }}>*/}
			{/*					<div className="w-100">*/}
			{/*						<MaterialAutoComplete*/}
			{/*							startAdornment="Identity Type"*/}
			{/*							selectedItem={'Please select Identity'}*/}
			{/*							required={true}*/}
			{/*							//disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}*/}
			{/*							// onSelect={(value, data) => {*/}
			{/*							// 	props.onSelect(value, data, "serviceType");*/}
			{/*							// 	setUpdateServiceType(data)*/}
			{/*							//}*/}
			{/*							// /!*}*!/*/}
			{/*							placeholder="Select Identity Type"*/}
			{/*							data={roles}*/}
			{/*						/>*/}
			{/*					</div>*/}

			{/*					<FormTextInput*/}
			{/*						label="Full Name"*/}
			{/*						name="displayName"*/}
			{/*						required*/}
			{/*						errorMessage="Please provide your name"*/}
			{/*						desktopSize={4}*/}
			{/*						tabletSize={6}*/}
			{/*					/>*/}
			{/*					<FormTextInput*/}
			{/*						label="Email Address"*/}
			{/*						name="email"*/}
			{/*						type="email"*/}
			{/*						required*/}
			{/*						errorMessage="Please provide your email"*/}
			{/*						desktopSize={4}*/}
			{/*						tabletSize={6}*/}
			{/*					/>*/}
			{/*					<FormTextInput*/}
			{/*						label="Password"*/}
			{/*						labelAdendum=""*/}
			{/*						name="password"*/}
			{/*						type="password"*/}
			{/*						desktopSize={4}*/}
			{/*						tabletSize={6}*/}
			{/*						required*/}
			{/*						errorMessage="Password is required"*/}
			{/*					/>*/}
			{/*				</div>*/}


			{/*				<Spacer spacing="s" />*/}
			{/*				<SubmitButton>Sign Up!</SubmitButton>*/}
			{/*			</BeaconForm>*/}
			{/*		</Column>*/}

			{/*		<Spacer spacing="m" />*/}
			{/*		<Body centered>Just taken a swab/PCR test?</Body>*/}
			{/*		<Body centered href="/test-results">*/}
			{/*			<Button secondary>Get my test results</Button>*/}
			{/*		</Body>*/}
			{/*	</Column>*/}
			{/*	<Column desktopSize={4} tabletSize={3} mobileSize={0} spacingTop="m" />*/}
			{/*</Row>*/}
		</>
	)
}
