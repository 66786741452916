import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import { IsMobileWidth } from 'components/common/util/util'
import { useStyles } from './dashboard.icon.bar.v2.style'
import clsx from 'clsx'

export default function DashboardIconBarV2(props) {
    const mobileWidth = IsMobileWidth()
    const classes = useStyles()
    const { selected, description,className } = props;

    return (
        <div style={{
            minHeight: 45
        }} className={clsx("pt-1", !mobileWidth && "w-15 pl-3", mobileWidth && "w-100")}>
            <Paper onClick={props.onClick} className={clsx(`h-100 d-flex flex-column w-100 paper-root p-1 ${classes.box} d-flex justify-content-center align-items-center border-radius-100 cursor-pointer`, selected && classes.selectedBox)}>
                <div className="font-weight-bolder p-2 d-flex align-items-center">
                    <Typography className="font-weight-bolder text-muted pl-2">{
                        props.children
                    }</Typography>
                </div>
                {
                    description ?
                        <div className={clsx("pb-2 font-weight-bolder pt-2",className)}>
                            {description}
                        </div> : null

                }

            </Paper>
        </div>
    )
}