import { fromJS } from 'immutable';
import {
    GET_KITS, GET_ADMIN_KITS, UPDATE_ADMIN_KIT_STATUS,
    SEND_CONFIMRATION_LINK, UPDATE_KIT_DATA, UPDATE_KIT,
    UPDATE_PATIENT_DETAIL, GET_COURIER_STATUS, UPDATE_TEST_KIT_CALL,
    UPDATE_PRODUCER_TEST_KIT_CALL, GET_TEST_SUMMARY, GET_ALL_WRONGLY_ASSIGNED_KITS
} from '../actions/dashboard.actions';
import { getActionName } from './util';

const initialState = fromJS({
    kits: '',
    adminKits: '',
    testSummary: '',
    wronglyAssignedKits: '',
});

function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case GET_KITS:
            return state.set('kits', '')
        case UPDATE_KIT:
            if (action.data && action.data.kit && action.data.kit.id) {
                const index = state.get('adminKits') && state.get('adminKits').get('kitsArray') && state.get('adminKits').get('kitsArray').findIndex((item) => item.get('id') === action.data.kit.id);
                if (index > -1) {
                    return state.updateIn(['adminKits', 'kitsArray', index], (item) => {
                        if (action.data.isLocal === true) {
                            return item.merge({ ...action.data.kit })
                        }
                        else
                            return item.merge({
                                executionStatus: "PENDING",
                                selected: false
                            })
                    })
                }
            }
            return state;
        case GET_COURIER_STATUS:
            return state.set('kitsCourierStatus', '')
        case getActionName(GET_COURIER_STATUS):
            return state.set('kitsCourierStatus', fromJS(action.response))
        case getActionName(GET_TEST_SUMMARY):
            return state.set('testSummary', fromJS(action.response))
        case getActionName(UPDATE_KIT):
            if (action.data && action.data.kit && action.data.kit.id) {
                const index = state.get('adminKits') && state.get('adminKits').get('kitsArray') && state.get('adminKits').get('kitsArray').findIndex((item) => item.get('id') === action.data.kit.id);
                if (index > -1) {
                    return state.updateIn(['adminKits', 'kitsArray', index], (item) => {
                        return item.merge({
                            executionStatus: "DONE",
                            status: 'CONFIRMATION_LINK_SENT'
                        })
                    })
                }
            }
            return state;
        case getActionName(GET_KITS):
            return state.set('kits', fromJS(action.response))
        case SEND_CONFIMRATION_LINK:
            if (action.data && action.data.kit && action.data.kit.id) {
                const index = state.get('kits') && state.get('kits').get('kitsArray') && state.get('kits').get('kitsArray').findIndex((item) => item.get('id') === action.data.kit.id);
                if (index > -1) {
                    return state.updateIn(['kits', 'kitsArray', index], (item) => {
                        return item.merge({
                            executionStatus: "PENDING",
                            selected: false
                        })
                    })
                }
            }
            return state;
        case getActionName(SEND_CONFIMRATION_LINK):
            if (action.data && action.data.kit && action.data.kit.id) {
                const index = state.get('kits') && state.get('kits').get('kitsArray') && state.get('kits').get('kitsArray').findIndex((item) => item.get('id') === action.data.kit.id);
                if (index > -1) {
                    return state.updateIn(['kits', 'kitsArray', index], (item) => {
                        return item.merge({
                            executionStatus: "DONE"
                        })
                    })
                }
            }
            return state;
        case UPDATE_PATIENT_DETAIL:
            if (action.data && action.data.kit && action.data.kit.id) {
                const index = state.get('adminKits') && state.get('adminKits').get('kitsArray') && state.get('adminKits').get('kitsArray').findIndex((item) => item.get('id') === action.data.kit.id);
                if (index > -1) {
                    return state.updateIn(['adminKits', 'kitsArray', index], (item) => {
                        return item.merge({ ...action.data.kit })
                    })
                }
            }
            return state;
        case getActionName(UPDATE_TEST_KIT_CALL):
            if (action.data && action.data.kit && action.data.kit.id) {
                const index = state.get('adminKits') && state.get('adminKits').get('kitsArray') && state.get('adminKits').get('kitsArray').findIndex((item) => item.get('id') === action.data.kit.id);
                if (index > -1) {
                    return state.updateIn(['adminKits', 'kitsArray', index], (item) => {
                        return item.merge({ ...action.data.kit })
                    })
                }
            }
            return state;
        case getActionName(UPDATE_PRODUCER_TEST_KIT_CALL):
            if (action.data && action.data.kit && action.data.kit.id) {
                const index = state.get('kits') && state.get('kits').get('kitsArray') && state.get('kits').get('kitsArray').findIndex((item) => item.get('id') === action.data.kit.id);
                if (index > -1) {
                    return state.updateIn(['kits', 'kitsArray', index], (item) => {
                        return item.merge({ ...action.data.kit })
                    })
                }
            }
            return state;
        case UPDATE_ADMIN_KIT_STATUS:
            if (action.data && action.data.kitId) {
                const index = state.get('adminKits') && state.get('adminKits').get('kitsArray') && state.get('adminKits').get('kitsArray').findIndex((item) => item.get('id') === action.data.kitId);
                if (index > -1) {
                    return state.updateIn(['adminKits', 'kitsArray', index], (item) => {
                        if (item.get('executionStatus') === "PENDING" || item.get('status') === "CONFIRMATION_LINK_SENT") {
                            return item.merge({
                                selected: false
                            })
                        }
                        else
                            return item.merge({
                                selected: item.get('selected') === true ? false : true
                            })
                    })
                }
            }
            else if (action.data && action.data.changeAllKitStatus === true) {
                for (let i = 0; i < state.get('adminKits').get('kitsArray').size; i++) {
                    state = state.updateIn(['adminKits', 'kitsArray', i], (item) => {
                        if (item.get('executionStatus') === "PENDING" || item.get('status') === "CONFIRMATION_LINK_SENT") {
                            return item.merge({
                                selected: false
                            })
                        }
                        else if (item.get('status') !== "CONFIRMATION_PENDING") {
                            return item.merge({
                                selected: false
                            })
                        }
                        else
                            return item.merge({
                                selected: action.data.selected
                            })
                    })
                }
                return state;
            }
            return state;
        case UPDATE_KIT_DATA:
            if (action.data && action.data.kitId) {
                const index = state.get('kits') && state.get('kits').get('kitsArray') && state.get('kits').get('kitsArray').findIndex((item) => item.get('id') === action.data.kitId);
                if (index > -1) {
                    return state.updateIn(['kits', 'kitsArray', index], (item) => {
                        if (item.get('executionStatus') === "PENDING" || item.get('executionStatus') === "DONE") {
                            return item.merge({
                                selected: false
                            })
                        }
                        else
                            return item.merge({
                                selected: item.get('selected') === true ? false : true
                            })
                    })
                }
            }
            else if (action.data && action.data.changeAllKitStatus === true) {
                for (let i = 0; i < state.get('kits').get('kitsArray').size; i++) {
                    state = state.updateIn(['kits', 'kitsArray', i], (item) => {
                        if (item.get('executionStatus') === "PENDING" || item.get('executionStatus') === "DONE") {
                            return item.merge({
                                selected: false
                            })
                        }
                        else if (item.get('status') !== "CONFIRMATION_PENDING") {
                            return item.merge({
                                selected: false
                            })
                        }
                        else
                            return item.merge({
                                selected: action.data.selected
                            })
                    })
                }
                return state;
            }
            return state;
        case GET_ADMIN_KITS:
            return state.set('adminKits', '')
        case getActionName(GET_ADMIN_KITS):
            return state.set('adminKits', fromJS(action.response))
        case getActionName(GET_ALL_WRONGLY_ASSIGNED_KITS):
            return state.set('wronglyAssignedKits', fromJS(action.response))
        default:
            return state;

    }
}
export default dashboardReducer;
