import './material.autocomplete.typeable.scss'
import React, { useEffect, useRef, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import InputBase from '@material-ui/core/InputBase';
import { useStyles } from './material.autocomplete.typeable.style';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { CircularProgress, Popper, ClickAwayListener, Icon, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar'

export default function MaterialAutoCompleteTypeable(props) {
    const { data, placeholder, execStatus, required, error, selectedItem, editableTime,
        organizationDropdown, endAdornment, placement, startAdornment, disabled, signUp } = props;
    const autoCompleteRef = useRef()
    let selectedItm;
    if (selectedItem) {
        selectedItm = (data && data.length > 0 && data != null) && data?.find((item) => item.value === selectedItem.value);
    }

    const classes = useStyles()
    const [state, setState] = useState({
        anchorELe: false,
        open: false,
        inputValue: selectedItm && selectedItm.name,
        autoCompleteWidth: false
    })
    useEffect(() => {
        if (selectedItm) {
            setState({ ...state, inputValue: selectedItm.name })
        }
    }, selectedItm)

    useEffect(() => {
        setState({ ...state, inputValue: selectedItem?.name })
    }, [selectedItem])

    useEffect(() => {
        if (autoCompleteRef && autoCompleteRef.current && autoCompleteRef.current !== null && autoCompleteRef.current.offsetWidth !== null) {
            setTimeout(() => {
                if (autoCompleteRef && autoCompleteRef.current && autoCompleteRef.current !== null && autoCompleteRef.current.offsetWidth !== null) {
                    setState({ ...state, autoCompleteWidth: autoCompleteRef.current.offsetWidth })
                }
            }, 200)
        }
    }, [autoCompleteRef, execStatus])
    const onOpen = (event) => {
        if (props.disabled === true) {

        }
        else {
            if (state.open === true) {
                setState({ ...state, anchorELe: false, open: false })
            }
            else
                setState({ ...state, anchorELe: event.currentTarget, open: true })
        }
    }

    const closePopper = () => {
        setState({ ...state, open: false })
    }

    const open = Boolean(state.anchorELe) && state.open === true;

    // const onMobileChange = (e, data) => {
    //     e.stopPropagation();
    //     e.preventDefault();
    //     setState({ ...state, open: false, anchorELe: false, inputValue: data && data.name })
    //     if (props.disabled === true) {

    //     }
    //     else {
    //         props.onSelect(data ? data.value : null, { ...data });
    //     }
    // }

    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        options: data,
        open: open,
        openOnFocus: true,
        freeSolo: true,
        onInputChange: (event, value) => {
            if (!props.disabled) {
                if (event !== null) {
                    if (value === "")
                        props.onSelect(null, null);
                    else {
                        if (props.isGetValueOnEditOn) {
                            props.onSelect(value, null);
                        }
                    }
                    setState({ ...state, open: true, anchorELe: event.currentTarget, inputValue: value })
                }
            }
        },
        value: selectedItem && selectedItem.value,
        onChange: (event, data, detail) => {
            if (props.disabled === true) {

            }
            else {
                setState({ ...state, open: false, anchorELe: false, inputValue: data && data.name })
                if (event.key === "Enter") {
                    if (groupedOptions && groupedOptions[0]) {
                        //setState({ ...state, open: false })
                        // props.onSelect(groupedOptions && groupedOptions[0] ? groupedOptions[0].name : null, {
                        //     city: groupedOptions && groupedOptions[0] && groupedOptions[0].name,
                        //     name: groupedOptions && groupedOptions[0] && groupedOptions[0].name,
                        //     value: groupedOptions && groupedOptions[0] && groupedOptions[0].name
                        // });
                        props.onSelect(data ? data.value : null, { ...data });
                    }
                }

                else {
                    props.onSelect(data ? data.value : null, { ...data });
                }

            }
        },
        getOptionLabel: (option) => {
            return option.name
        }
    })
    return (
        <React.Fragment>
            <div className="w-100" style={organizationDropdown ? { minWidth: "300px" } : { minWidth: "max-content" }}>
                {
                    execStatus && execStatus.status === "PENDING" && !data ?
                        <Paper className="paper-root">
                            <div className="d-flex justify-content-center p-1">
                                <CircularProgress className="p-2" size={30} />
                            </div>
                        </Paper> : null
                }
                {execStatus && execStatus.status === "ERROR" ? (
                    <Paper className="paper-root">
                        <div className="text-danger d-flex justify-content-center font-italic pt-2 pb-2">
                            <b>
                                {" "}
                                {execStatus.errorDetail && typeof execStatus.errorDetail === 'object'
                                    ? execStatus.errorDetail.get("description")
                                    : "Something isn’t working as it should, please try again"}
                            </b>
                        </div>
                    </Paper>
                ) : null}
                {
                    startAdornment ?
                        <div className={`pb-2 pt-2 d-flex  ${props?.signUp ? '' : 'font-weight-bold'} `}>
                            <div>
                                {
                                    required === true ?
                                        <div className="text-danger">*</div> : null
                                }
                            </div>
                            <div style={{
                                width: 'max-content'
                            }}>
                                {startAdornment}
                            </div>
                        </div>
                        : null
                }
                {
                    data && data.length > 0 ?
                        <ClickAwayListener onClickAway={closePopper}>
                            <div className="w-100 d-flex justify-content-center align-items-center position-relative"
                                {...getRootProps()}>

                                <Paper
                                    ref={autoCompleteRef}
                                    id="paper-autocomplete"
                                    onClick={onOpen}
                                    component="div"
                                    className={clsx("d-flex align-items-center cursor-pointer paper-root",
                                        state.open && classes.borderRadiusPartial,
                                        !state.open && classes.borderRadiusFull,
                                        classes.paperRoot,
                                    )}>

                                    {
                                        selectedItm && selectedItm.img ?
                                            <Paper className="svg-logo" elevation={3}>
                                                <img
                                                    className="svg-logo"
                                                    src={`${process.env.PUBLIC_URL}/assets/images/${selectedItm.img}`}

                                                /></Paper> : null
                                    }
                                    {
                                        selectedItm && selectedItm.svgLogo ?
                                            <Paper className={clsx("svg-logo", !startAdornment && "ml-1", startAdornment && "ml-2")} elevation={3}>
                                                <Avatar
                                                    className="svg-logo"
                                                    alt={""}
                                                    src={selectedItm.svgLogo}
                                                /></Paper> : null
                                    }
                                    <InputBase
                                        autoComplete="nope"
                                        {...getInputProps()}
                                        autoFocus={open}
                                        inputRef={(input) => {
                                            if (state.open === true) {
                                                input && input.focus()
                                            }
                                        }}
                                        placeholder={placeholder}
                                        onKeyDown={e => {
                                            setState({ ...state, open: true })
                                            // if (e.keyCode === 13 && e.target.value) {
                                            //     setState({ ...state, anchorELe: false })

                                            // }
                                        }}
                                        inputProps={{
                                            maxLength: editableTime && 18,
                                            autoFocus: state.open,
                                            className: 'pb-0 pt-0',
                                            autocomplete: "nope",
                                            "aria-autocomplete": "nope"
                                        }}
                                        value={state.inputValue}
                                        className={clsx(`flex-grow-1 ${classes.input}`, !startAdornment && !selectedItem && "pl-2", startAdornment && !selectedItem && "pl-2", selectedItem && "pl-2")}
                                    />
                                    {

                                        !disabled ?
                                            selectedItem && data && data.length === 1 ?
                                                null :
                                                <Icon className="pr-2" color="secondary">
                                                    {
                                                        placement && placement.indexOf("right") >= 0 ?
                                                            <React.Fragment>

                                                                {
                                                                    open ?
                                                                        <span class="material-icons">
                                                                            keyboard_arrow_left</span> :
                                                                        <span class="material-icons">
                                                                            keyboard_arrow_right</span>
                                                                }
                                                            </React.Fragment> :
                                                            <React.Fragment>
                                                                {
                                                                    open ?
                                                                        <span class="material-icons">
                                                                            keyboard_arrow_up</span> :
                                                                        <span class="material-icons">
                                                                            keyboard_arrow_down</span>
                                                                }
                                                            </React.Fragment>

                                                    }
                                                </Icon> : null
                                    }

                                </Paper>

                                {data && data.length > 0 ? (
                                    <Popper {...getListboxProps()} open={open} anchorEl={state.anchorELe} placement={placement ? placement : "bottom"} className={clsx(classes.listbox, "paper-root")}
                                        style={{
                                            width: state.autoCompleteWidth,
                                        }}>
                                        <Paper className="paper-root" elevation={1} {...getListboxProps()}>
                                            {groupedOptions && groupedOptions.map((option, index) => (
                                                <div>
                                                    <li
                                                        className={clsx(classes.listItem, selectedItm && selectedItm.value === option.value && classes.selectedListItem)}
                                                        {...getOptionProps({ option, index })}
                                                    //onTouchEndCapture={(e) => onMobileChange(e, option)}
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            {
                                                                option.img ?
                                                                    <Paper className="svg-logo " elevation={3}>
                                                                        <img
                                                                            src={`${process.env.PUBLIC_URL}/assets/images/${option.img}`}
                                                                            className="svg-logo"
                                                                        /></Paper> : null
                                                            }
                                                            {
                                                                option.svgLogo ?
                                                                    <Paper className="svg-logo" elevation={3}>

                                                                        <Avatar
                                                                            alt={""}
                                                                            className="svg-logo"
                                                                            src={option.svgLogo}
                                                                        /></Paper> : null
                                                            }
                                                            <div className="w-100 d-flex justify-content-between align-items-center">
                                                                <div className="pl-1 d-flex flex-column w-100 pr-2">
                                                                    <div>
                                                                        <Typography><b>{option.name}</b></Typography>
                                                                    </div>

                                                                    <div>
                                                                        <Typography>{option.description1}</Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography>{option.description2}</Typography>
                                                                    </div>

                                                                </div>
                                                                {
                                                                    selectedItm && selectedItm.value == option.value ?
                                                                        <div>
                                                                            <Icon>
                                                                                <i className="fa fa-check text-success"></i>
                                                                            </Icon>
                                                                        </div> : null
                                                                }

                                                            </div>
                                                        </div>
                                                    </li>
                                                </div>
                                            ))}
                                        </Paper>
                                    </Popper>
                                ) : null}
                            </div>
                        </ClickAwayListener> : execStatus && execStatus.status !== "PENDING" &&
                            execStatus.status !== "ERROR" ? <Paper>
                            <div className=" text-muted d-flex justify-content-center p-2">
                                <Typography>No Data Found</Typography>
                            </div>
                        </Paper> : null
                }
            </div>
            {
                error ?
                    <div className="text-danger 
                    d-flex justify-content-start p-1">
                        <Typography> {error}</Typography>
                    </div> : null
            }
        </React.Fragment>

    )
}
//name , value , img