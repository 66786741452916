import React from 'react'
import { Row, Column, Usp, Button, Body, Spacer } from '../../components'

import builtAroundYouIcon from './assets/built-around-you.svg'
import decisionsIcon from './assets/decision.svg'
import iso15189Icon from './assets/iso.svg'
import easyToAccessIcon from './assets/easy-access.svg'
import earnIcon from './assets/earn.svg'
import twentyFourIcon from './assets/24-hours.svg'
import customerServiceImg from './assets/customer-service.svg'
import suitcaseWalkingImg from './assets/suitcase-walking.svg'
import results from './assets/receive-results.svg'
import tested from './assets/get-tested.svg'
import register from './assets/register.svg'
import { ContactFormSegment } from './ContactFormSegment'

const FuckingTriangle = () => {
	return <div className="marketing fucking-triangle" />
}

const HomePage = () => {

	return (
		<>
			<Row marketing className="homepage hero">
				<Column desktopSize={6} tabletSize={11}>
					<h1>
						Providing seamless digital Covid-19 testing solutions for organisations, health
						practitioners and individuals
					</h1>
					<Column desktopSize={4} tabletSize={7}>
						<Body style={{ color: '#60717c' }}>
							in partnership with the
							<span style={{ color: 'var(--color-blue)' }}> Source Bioscience</span> laboratory
							<strong> ISO:15189:2012</strong> medical laboratories standard
						</Body>
					</Column>
					<Spacer spacing="s" />
					<Column desktopSize={5} tabletSize={9}>
						<Body>
							Beacon Secure is a leader in the field of digital health testing solutions, offering a
							proprietary platform for healthcare monitoring and management and digital immunity
							integrated with leading laboratory providers.
						</Body>
					</Column>
					<Spacer spacing="s" />
					<Button fitted href="/about-us">
						Learn More
					</Button>
				</Column>
				<FuckingTriangle />
			</Row>
			{/* <Row marketing separated className="homepage instructions">
			         <Spacer spacing="m" />
					<Column bordered desktopSize={5} className="antibody-indication-background">
						<h3>Industry leading testing at industry beating prices</h3>
						<Body paragraph>
						Beacon Secure delivers highly accurate, fast and reliable testing, starting from £90 per test for organisations. Our proprietary automation technology and integration with leading UK labs enables us to provide rapid testing services at competitive prices . Contact us to find out how we can tailor a solution for your needs and budget.
						</Body>
					</Column>
			</Row> */}
			<Column marketing className="homepage alternative">
				<h3 className="no-space" style={{ color: 'black' }}>
					For Organisations
				</h3>
				<Row>
					<Column desktopSize={6} className="beige-plane-background ">
						<Column desktopSize={6}>
							<h1>Manage risk, enabling a safe, healthier and more productive workplace</h1>
							<Column desktopSize={5}>
								<Body>
									Your employees are your company’s single most important asset and their safety and
									wellbeing is of paramount importance. Routine, reliable and rapid testing is
									essential to create and maintain a healthy work environment, protecting your
									employees, customers and reputation. Beacon Secure provides seamless and
									integrated testing solutions no matter what your Organisation size or needs are.
								</Body>
							</Column>
						</Column>
						<Spacer spacing="xl" />
						<Spacer spacing="m" />
						<Spacer spacing="m" />
						<Row desktopSize={5} className="not-mobile">
							<img
								src={customerServiceImg}
								width={233}
								height={199}
								alt="Customer service illustration"
							/>
							<img
								src={suitcaseWalkingImg}
								width={281}
								height={308}
								alt="Walking person, now that she passed covid19 illustration"
							/>
						</Row>
					</Column>
					<Column desktopSize={5}>
						<h1>How it works</h1>
						<Spacer spacing="m" />
						<Usp icon={builtAroundYouIcon} alt="Flexible Icon">
							<strong>Built around you.</strong> Our solutions are tailored to your specific
							workplace requirements, whether it’s one-off tests for individuals, regular on-site
							screening, or an alternative of your choice.
						</Usp>

						<Spacer spacing="m" />
						<Usp icon={decisionsIcon} alt="Gold Standard Icon">
							<strong>Making decisions easy.</strong> Results are available through our powerful and
							intuitive dashboard usually within 24 hours of test receipt by the laboratory. This
							enables you to make timely decisions to keep your workplace safe.
						</Usp>

						<Spacer spacing="m" />
						<Usp icon={iso15189Icon} alt="ISO Icon">
							<strong>We focus on quality.</strong> Quality of testing and accuracy of results is
							critical, which is why we’ve partnered with Source Bioscience, a cutting-edge, ISO
							accredited international provider of laboratory services.
						</Usp>
						<Spacer spacing="m" />
						<Button fitted href="/for-businesses">
							Find out more
						</Button>
						<Spacer spacing="xl" />
					</Column>
				</Row>
			</Column>
			<Column marketing className="homepage">
				<h3 className="no-space" style={{ color: 'black' }}>
					For Health Practitioners
				</h3>
				<Row>
					<Column desktopSize={5} style={{ justifyContent: '' }}>
						<Row desktopSize={5} tabletSize={9}>
							<h1>Provide Covid-19 testing for your patients</h1>
						</Row>
						<Spacer spacing="m" />
						<Body paragraph>
							As we return to a new normal, there is a growing demand for Covid-19 testing by
							Organisations and individuals. Join our partner scheme and offer your patients a fast
							and accurate Covid-19 test – simply administer the swab test and let our unique, safe
							and secure platform handle the rest. This is an easy and simple way to meet increasing
							customer demand whilst enabling you to unlock additional revenue.
						</Body>
					</Column>
					<Column desktopSize={1} />
					<Column desktopSize={6}>
						<h1>How it works</h1>
						<Spacer spacing="m" />
						<Usp icon={easyToAccessIcon} alt="Flexible Icon">
							<strong>Easy to access</strong> <br />
							You will obtain access to our cutting-edge testing platform at no cost enabling you to
							easily order, administer and track Covid-19 test results.
						</Usp>

						<Spacer spacing="m" />
						<Usp icon={earnIcon} alt="Gold Standard Icon">
							<strong>Earn as you test</strong> <br />
							As a trusted health provider partner, you will be paid for every test administered and
							sent for analysis.
						</Usp>

						<Spacer spacing="m" />
						<Usp icon={twentyFourIcon} alt="ISO Icon">
							<strong>Speed and accuracy</strong> <br />
							We know your clients don’t have time to waste and neither do you. Results will be
							available within 24 hours of test receipt by the laboratory and delivered via our
							intuitive digital dashboard.
						</Usp>
						<Spacer spacing="m" />
						<Button fitted href="/for-health-practitioners">
							Find out more
						</Button>
					</Column>
				</Row>
			</Column>

			<Column marketing separated className="homepage">
				<h3 className="no-space" style={{ color: 'black' }}>
					For Individuals and families
				</h3>
				<Row>
					<Column desktopSize={6}>
						<Column desktopSize={6}>
							<h1>Protect yourself, your family and friends</h1>
							<Column desktopSize={5}>
								<Body>
									Our unique digital solution enables us to offer competitive rates for individuals
									and family tests. Beacon Secure also offers ‘fit to fly’ certificates for those
									travelling to destinations where it is required and for those who want peace of
									mind after returning from COVID-19 hotspots. Take away the confusion for you and
									your loved ones through the Beacon Secure platform.
								</Body>
							</Column>
						</Column>
						<Spacer spacing="xl" />
						<Spacer spacing="m" />
						<Spacer spacing="m" />
					</Column>
					<Column desktopSize={5}>
						<h1>How it works</h1>
						<Spacer spacing="m" />
						<Usp icon={register} alt="Flexible Icon">
							<strong>Register with Us.</strong> Once registered, you will be able to place orders
							through our proprietary platform for you and your family.
						</Usp>

						<Spacer spacing="m" />
						<Usp icon={tested} alt="Gold Standard Icon">
							<strong>Get Tested.</strong> Perform the swab test at home or through one of our
							trusted Health Practitioner partners.
						</Usp>

						<Spacer spacing="m" />
						<Usp icon={results} alt="ISO Icon">
							<strong>Receive Results.</strong> Results are delivered securely through our platform
							within record time so that you and your family have peace of mind and don’t keep life
							on hold.
						</Usp>
						<Spacer spacing="m" />
						<Button fitted href="/for-individuals">
							Find out more
						</Button>
						<Spacer spacing="xl" />
					</Column>
				</Row>
			</Column>

			<Row marketing separated className="homepage instructions">
				<Column desktopSize={0} tabletSize={12} className="tablet instructions-background"></Column>
				<Column desktopSize={5}>
					<h1>What is a swab/PCR test?</h1>
					<Spacer spacing="s" />
					<Body paragraph>
						A swab test takes samples from the back of the throat and nose of the test candidate and
						is the most accurate way to detect the presence of the SARS-Cov-2 virus (which causes
						Covid-19).
					</Body>
					<Spacer spacing="s" />
					<Body paragraph>
						This test shows the presence of an active infection, meaning that the test candidate
						could be transmitting the disease to others even if they are not currently showing any
						symptoms. As it detects an active infection, it is a more reliable method of detecting
						and controlling the spread of infection in the workplace and the wider community.
					</Body>
					<Spacer spacing="m" />
					<Button fitted>Self-test kit instructions</Button>
					<Spacer spacing="m" />
					<Column bordered desktopSize={5} className="antibody-indication-background">
						<h3>Do you provide an antibody test?</h3>
						<Body paragraph>
							Currently we don’t provide antibody tests as they are more useful for detecting past
							infections rather than controlling active transmission of the virus. We are monitoring
							their quality and reliability and may offer them in the future.
						</Body>
					</Column>
				</Column>
				<Column desktopSize={1}></Column>
				<Column desktopSize={6} tabletSize={0} className="desktop instructions-background"></Column>
			</Row>
			<Row marketing separated className="homepage">
				<Column desktopSize={5} style={{ justifyContent: '' }}>
					<Spacer spacing="m" />
					<h1>Have a question?</h1>
					<Body paragraph>
						Please use this form to get in touch with us if you have any questions. We’ll get back
						to you as soon as possible.
					</Body>
				</Column>
				<Column desktopSize={1}></Column>
				<Column desktopSize={6} style={{ justifyContent: '' }}>
					<ContactFormSegment subject={'Home Page'} />
				</Column>
			</Row>
		</>
	)
}

export { HomePage }
