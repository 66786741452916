import React from 'react'
import { useBreakpoint } from '../utils/breakpoints'
import { Body } from './typography'
import { Row } from './containers'

const Usp = ({ icon, alt, children }) => {
	const { isMobile } = useBreakpoint()

	if (isMobile) {
		return (
			<Row>
				<Row centered>
					<img className="usp icon" src={icon} alt={alt} />
				</Row>
				<Row>
					<Body paragraph centered>
						{children}
					</Body>
				</Row>
			</Row>
		)
	} else {
		return (
			<Row desktopSize={6} spaced>
				<Row desktopSize={1} tabletSize={2} className="usp icon container-app">
					<img className="usp icon" src={icon} alt={alt} />
				</Row>
				<Row desktopSize={5} tabletSize={10} style={{ marginTop: '-0.5rem' }}>
					<Body paragraph>{children}</Body>
				</Row>
			</Row>
		)
	}
}

export { Usp }
