import React from 'react'
import { Column, LoadingAnimation, Row } from '../../../components'
import { BeaconForm, FormTextInput, SubmitButton } from '../../../components/forms'
import { sortableUid } from '../../../utils'
import { toSbsPatient, toUiPatient } from '../../../utils/sbs-utils'
import { genderPattern, gendersList, preferredTimeList, preferredTimePattern } from '../../../utils/uitl'

const EditSameDayKitModal = (props) => {
    const { kit, editKitLoading } = props;
    const datalistId = sortableUid();
    const datalistIdPt = sortableUid();
    return (
        <>
            <div style={{
                padding: '0 5rem',
            }}>
                <BeaconForm
                    onSubmit={props.editKit}
                    initialValues={kit}
                    io={{ input: toUiPatient, output: toSbsPatient }}
                    triggered
                >
                    <Column size={5}
                        style={{
                            //padding: '0 5rem' ,
                            height: '310px',
                            flexWrap: 'nowrap',
                            overflowX: 'hidden',
                            overflowY: 'scroll'
                        }}>
                        <h5>Kit Details</h5>
                        <Row size={5} spacingTop="xs" spaced>
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Full name"
                                name="fullName"
                                required
                                errorMessage="Field is required"
                            />
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Email"
                                pattern={/(.*)@(.*)\.([a-zA-Z]{2,20})/}
                                errorMessage="Has to be valid email address"
                                name="email"
                                required
                                errorMessage="Field is required"
                            />
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Telephone"
                                pattern={/^\d+$/}
                                name="telephone"
                                required
                                errorMessage="Field is required"
                            />
                        </Row>
                        <Row size={5} spacingTop="m" spaced>
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Gender"
                                listId={datalistId}
                                datalist={gendersList}
                                pattern={genderPattern}
                                name="gender"
                                required
                                errorMessage="Please enter one of: Female, Male, Transgender or Other"
                            />
                        </Row>
                        {
                            <Row size={5} spacingTop="m" spaced>
                                <FormTextInput
                                    desktopSize={4}
                                    tabletSize={4}
                                    label="Date of birth (DD / MM / YYYY)"
                                    // placeholder="01/11/1980"
                                    //mask="99  /  99  /  9999"
                                    name="DOB"
                                    required
                                    pattern={/^(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](?:19|20)\d\d$/}
                                    errorMessage="Please enter date in DD / MM / YYYY format"
                                />
                            </Row>
                        }
                        <Row size={5} spacingTop="m">
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Address line 1"
                                name="addressLine1"
                                required={true}
                                errorMessage="Field is required"
                            />
                        </Row>
                        <Row size={5} spacingTop="s">
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Address line 2"
                                name="addressLine2"
                                errorMessage="Field is required"
                            />
                        </Row>
                        <Row size={5} spacingTop="s" spaced>
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Town/City"
                                name="city"
                                required
                                errorMessage="Field is required"
                            />
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Postcode"
                                name="postcode"
                                required
                                //required={editPatientMode}
                                errorMessage="Field is required"
                            />
                        </Row>
                        <Row size={5} spacingTop="s" spaced>
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Document Type"
                                name="documentType"
                                //required={editPatientMode}
                                errorMessage="Field is required"
                            />
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Document Number"
                                name="documentNumber"
                                //required={editPatientMode}
                                errorMessage="Field is required"
                            />
                        </Row>
                        <Row size={5} spacingTop="s" spaced>
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Document Authority"
                                name="documentAuthority"
                                //required={editPatientMode}
                                errorMessage="Field is required"
                            />
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Health Number"
                                name="healthNumber"
                                //required={editPatientMode}
                                errorMessage="Field is required"
                            />
                        </Row>
                        <Row size={5} spacingTop="s" spaced>
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Given Name"
                                name="givenName"
                                //required={editPatientMode}
                                errorMessage="Field is required"
                            />
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Family Name"
                                name="familyName"
                                //required={editPatientMode}
                                errorMessage="Field is required"
                            />
                        </Row>
                        <Row size={5} spacingTop="s" spaced>
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Nationality"
                                name="nationality"
                                //required={editPatientMode}
                                errorMessage="Field is required"
                            />
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Document Country"
                                name="documentCountry"
                                //required={editPatientMode}
                                errorMessage="Field is required"
                            />
                        </Row>
                        <Row>
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Date of Test (DD / MM / YYYY)"
                                name="dateOfTest"
                                pattern={/^(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](?:19|20)\d\d$/}
                                errorMessage="Please enter date in DD / MM / YYYY format"
                            />
                        </Row>
                        <Row>
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Preferred Time"
                                // placeholder={"Preferred Time"}
                                name="preferredTime"
                                listId={datalistIdPt}
                                datalist={preferredTimeList}
                                pattern={preferredTimePattern}
                                //required
                                //pattern={/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/}
                                errorMessage={`Please enter preferred time in 24 hour HH:mm format`}
                            />

                        </Row>
                        <Row>
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                label="Preferred Time Instructions"
                                name="instructions"
                                errorMessage="Enter value"
                            />
                        </Row>
                        <Row style={{
                            display: 'none'
                        }}>
                            <FormTextInput
                                desktopSize={4}
                                tabletSize={4}
                                // label="Preferred Time"
                                // placeholder={"Preferred Time"}
                                name="id"
                                //required
                                // pattern={/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/}
                                errorMessage={`Please enter `}
                            />
                        </Row>
                    </Column>
                    {
                        editKitLoading === true ?
                            <h3>Saving....</h3> : <SubmitButton loading={editKitLoading} primary>Save changes</SubmitButton>
                    }
                </BeaconForm>
            </div>
        </>
    )
}

export { EditSameDayKitModal }
