import React, { useState } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { Column, Row, Form, Body, TextInput, Button, Breadcrumps, BackButton, Spacer } from '../../components'

import { withAuthentication } from '../../utils/withAuthentication'
import { useAdmin } from '../../utils'


const AWAITING = 'awaiting'
const READY = 'ready'

const OrganisationLine = ({ label, children, bold }) => {
	const l = label === '' ? '' : `${label}:`
	return (
		<Row size={6} marginTop={0}>
			<Row size={3} marginTop={0}>
				<Body className={clsx({ bold: bold })}>{l}</Body>
			</Row>
			<Row size={3} marginTop={0}>
				<Body>{children}</Body>
			</Row>
		</Row>
	)
}

//super user
const Organisations = withAuthentication(() => {
	const { createOrganisation, pricePerKit } = useAdmin()
	const [isSubmittingOrganisation, setIsSubmittingOrganisation] = useState(false)
	const [step, setStep] = useState(AWAITING)
	const defaultOrganisation = {
		"organisationId": "",
		"displayName": "",
		"emailDomains": [""],
		"loginUrl": "https://beaconsecure.co.uk",
		"country": "GB",
		"price": pricePerKit
	}
	const maybeOrganisation = Object.assign(defaultOrganisation, JSON.parse(localStorage.getItem('newOrganisation')))

	const history = useHistory()
	const [organisationId, setOrganisationId] = useState(maybeOrganisation.organisationId)
	const [displayName, setDisplayName] = useState(maybeOrganisation.displayName)
	const [emailDomains, setEmailDomains] = useState(maybeOrganisation.emailDomains)
	const [loginUrl, setLoginUrl] = useState(maybeOrganisation.loginUrl)
	const [country, setCountry] = useState(maybeOrganisation.country)
	const [price, setPrice] = useState(maybeOrganisation.price)


	const handleSubmitOrganisation = (event) => {
		const organisation = {
			organisationId,
			displayName,
			emailDomains,
			loginUrl,
			country,
			price
		}
		setIsSubmittingOrganisation(true)
		console.log('handleSubmitOrganisation', organisation);
		createOrganisation(organisation)
			.then(() => {
				localStorage.removeItem('newOrganisation')
				history.push('/admin')
			})
			.catch((error) => {
				setIsSubmittingOrganisation(false)
				console.error('submitOrganisation', 'error:', error)
			})
	}

	const handleBackFromOrganisationConfirm = (event) => {
		setStep(AWAITING)
	}

	const handleConfirm = (event) => {
		if (true) {
			localStorage.setItem('newOrganisation', JSON.stringify({
				organisationId,
				displayName,
				emailDomains,
				loginUrl,
				country,
				price
			}))
			setStep(READY)
		}
	}


	if (step === AWAITING) {
		return (
			<Column desktopSize={8} tabletSize={12}>
				<Breadcrumps />
				<h3>Create Organisation</h3>
				<h5>step 1 of 2</h5>
				<h3>Please provide the following details</h3>
				<Form onSubmit={handleConfirm}>
					<Column size={8}>
						<TextInput label="Organisation Id" size={8} value={organisationId} setter={setOrganisationId} />
						<TextInput label="Display name" size={8} value={displayName} setter={setDisplayName} />
						<TextInput label="Email Domains" size={8} value={emailDomains} setter={setEmailDomains} />
						<TextInput label="Login Url" size={8} value={loginUrl} setter={setLoginUrl} />
						<TextInput label="Country" size={8} value={country} setter={setCountry} />
						<TextInput label="Price" size={8} value={price} setter={setPrice} />
					</Column>
					<Spacer spacing="s" />
					<Button fitted primary submit>
						Review organisation data
						</Button>
				</Form>
			</Column>
		)
	} else if (step === READY) {
		return (
			<Column desktopSize={8} tabletSize={12}>
				<Breadcrumps />
				<h3>Create Organisation</h3>
				<h5>step 2 of 2</h5>
				<h3>Please confirm creating new organisation</h3>
				<Column bordered desktopSize={8} style={{ padding: '2rem' }}>
					<Column desktopSize={8}>
						<OrganisationLine label="Organisation Id">{organisationId}</OrganisationLine>
						<OrganisationLine label="Display name">{displayName}</OrganisationLine>
						<OrganisationLine label="Email Domains">{emailDomains}</OrganisationLine>
						<OrganisationLine label="Login Url">{loginUrl}</OrganisationLine>
						<OrganisationLine label="Country">{country}</OrganisationLine>
						<OrganisationLine label="Price">{price}</OrganisationLine>
					</Column>
				</Column>
				<Spacer spacing="s" />
				<Row desktopSize={8} style={{ justifyContent: 'space-between' }}>
					<Button secondary onClick={handleBackFromOrganisationConfirm}>
						← Back
				</Button>
					<Button
						primary
						style={{ minWidth: '15rem' }}
						loading={isSubmittingOrganisation}
						onClick={handleSubmitOrganisation}
					>
						Confirm Organisation
				</Button>
				</Row>
			</Column>
		)
	}

})

export { Organisations }
