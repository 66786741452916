import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Checkbox, CircularProgress, FormControlLabel } from "@material-ui/core";
import { formatDate, format24HrDateTime } from 'components/common/util/util';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
import CustomCalender from 'components/common/custom.calender/custom.calender';
import Calender from 'components/common/custom.calender/Calender';
import MaterialAutoComplete from 'components/common/material.autocomplete/material.autocomplete';
import MaterialAutoCompleteTypeable from 'components/common/material.autocomplete.typeable/material.autocomplete.typeable';
import { PREFERRED_TIMES, SERVICE_TYPES, COUNTRIES_LIST } from 'components/common/util/util';
import { PARTNER_LABS, } from 'components/common/util/util';
import { copyTextToClipboard, COUNTRY_CODES, getPastDate } from '../../../components/common/util/util';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CourierStatusDialog from './courier.status.dialog';
import { headAssignedLimited, headCancelledLimited, headCells, headConfirmationSentLimited, headLimitedCells } from "./Obj";
import CancellationConfirmationDialog from './cancellation.confirmation.dialog';
import RadioButton from '../../../components/common/material.radiobutton/RadioButton';
import { ReactTelephoneInput } from 'react-telephone-input';
import InfoValidationDialog from '../../schedule-tests/components/info.validation.dialog';
import Tooltip from '@material-ui/core/Tooltip';
import { isValidConfirmationPatientDetail } from './validator';
import { fromJS } from 'immutable';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        display: 'flex',
        //justifyContent: 'center',
        marginBottom: theme.spacing(2),
    },
    tableContainer: {
        width: '100%',
        //height: '300px',
        overflow: 'auto'
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));




function EnhancedTableHead(props) {
    const { isAllCheckBoxSelected, kits, updatedStatus } = props;
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    {
                        kits && kits.get('kitsArray') && kits.get('kitsArray').size > 0 ?
                            <FormControlLabel
                                className="checkbox-size"
                                control={
                                    <Checkbox
                                        size='medium'
                                        checked={isAllCheckBoxSelected}
                                        onChange={props.handleAllCheckBoxChange}
                                        color="secondary"
                                        name=""
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                }
                                label="" /> : null
                    }
                </TableCell>
                {
                    (updatedStatus === 'CANCELLED' || updatedStatus === 'ResultReady') ?
                        headCancelledLimited.map((headCell) => (
                            <TableCell
                                style={{ fontSize: '16px', fontWeight: '700' }}
                                key={headCell.id}>
                                {headCell.label}
                            </TableCell>
                        ))
                        : (updatedStatus === 'Assigned' || updatedStatus === 'INDIVIDUAL_CONFIRMED_TEST_DETAILS') ?
                            headAssignedLimited.map((headCell) => (
                                <TableCell
                                    style={{ fontSize: '16px', fontWeight: '700' }}
                                    key={headCell.id}>
                                    {headCell.label}
                                </TableCell>
                            ))
                            :
                            (updatedStatus === 'CONFIRMATION_LINK_SENT') ?
                                headConfirmationSentLimited.map((headCell) => (
                                    <TableCell
                                        style={{ fontSize: '16px', fontWeight: '700' }}
                                        key={headCell.id}>
                                        {headCell.label}
                                    </TableCell>
                                ))
                                :
                                headCells.map((headCell) => (
                                    <TableCell
                                        style={{ fontSize: '16px', fontWeight: '700' }}
                                        key={headCell.id}>
                                        {headCell.label}
                                    </TableCell>
                                ))
                }
            </TableRow>
        </TableHead>
    );
}



export default function KitsTableView(props) {

    const { kits, execStatusGetKits, execStatusCancelTest } = props;
    console.log(props)
    const [state, setState] = useState({
        showCopyAlert: false,
        showCourierStatusDialogOpen: false,
        showCancelConfirmationDialogOpen: false,
        showUpdateConfirmationDialogOpen: false,
        showErrorDialog: false,
        selectedKitForErrorDialog: null,
        selectedKit: ''
    })
    const classes = useStyles()
    const getStatus = (status) => {
        switch (status) {
            case "CONFIRMATION_PENDING":
                return "Confirmation Pending"
            case "CONFIRMATION_APPROVED":
                return "Confirmation Approved"
            case "CONFIRMATION_LINK_SENT":
                return "Confirmation Link Sent"
            case "CANCELLED":
                return "Cancelled"
            default:
                return status;
        }
    }
    const isShowEditAble = (kit) => {
        if (kit.get('executionStatus') === "PENDING") {
            return false;
        }
        else if (kit.get('status') !== "CONFIRMATION_PENDING" && kit.get('status') !== "CONFIRMATION_LINK_SENT") {
            return false
        }
        else
            return true;
    }
    const isShowCheckBox = (kit) => {
        if (kit.get('executionStatus') === "PENDING") {
            return false;
        }
        else if (kit.get('status') === "CONFIRMATION_PENDING") {
            return true
        }
        else
            return false;
    }
    const isShowCancelOrUpdateButton = (kit) => {
        if (kit.get('executionStatus') === "PENDING") {
            return false;
        }
        else if (kit.get('status') === "Assigned" || kit.get('status') === "ResultReady" || kit.get('status') === "CANCELLED") {
            return false
        }
        else
            return true;
    }
    const copyLink = (link) => {
        copyTextToClipboard(link).then(() => {
            setState({ ...state, showCopyAlert: true })
        })
    }
    const closeCopyAlert = () => {
        setState({ ...state, showCopyAlert: false })
    }
    const showCourierStatusDialog = (kit) => {
        setState({ ...state, showCourierStatusDialogOpen: true, selectedKit: kit })
    }
    const hideCourierStatusDialog = () => {
        setState({ ...state, showCourierStatusDialogOpen: false, selectedKit: '' })
    }
    const showCancelConfirmationDialog = (kit) => {
        setState({ ...state, showCancelConfirmationDialogOpen: true, selectedKit: kit })
    }
    const hideCancelConfirmationDialog = () => {
        //if(execStatusCancelTest.status !== "PENDING")
        setState({ ...state, showCancelConfirmationDialogOpen: false })
    }
    const showUpdateConfirmationDialog = (kit) => {
        setState({ ...state, showUpdateConfirmationDialogOpen: true, selectedKit: kit })
    }
    const hideUpdateConfirmationDialog = () => {
        //if(execStatusCancelTest.status !== "PENDING")
        setState({ ...state, showUpdateConfirmationDialogOpen: false })
    }

    const getDate = (date) => {
        let day = date.toString().substring(0, 2)
        let month = date.toString().substring(3, 5)
        let year = date.toString().substring(6, 10)

        console.log(day, month, year, "123321")

        return (
            new Date(`${year}`, `${month}`, `${day}`, '00', '00', '00')
        )

    }

    const getPreferredTime = (value) => {

        var preferredTime = null
        if (typeof value === "object") {
            return value
        }
        else {
            preferredTime = PREFERRED_TIMES?.filter((item) => item?.value === value)
            // console.log(preferredTime, value, "hey123")
            if (preferredTime.length >= 1) {
                return preferredTime[0]
            }
            else if (value != "" && value.length === 11) {
                const fromTime = value.slice(0, 5)
                const toTime = value.slice(6, value.length)
                return (
                    {
                        name: `${fromTime + " - " + toTime}`,
                        value: `${fromTime + "-" + toTime}`,
                        preferredFromTime: fromTime,
                        preferredToTime: toTime
                    }
                )
            }
            else {
                return ""
            }
        }
    }

    let contryList = COUNTRY_CODES.map((cnt) => {
        return { name: cnt[0], iso2: cnt[1], dialCode: cnt[2], priority: 0, format: cnt[3] }
    })

    const updateKit = (selectedKit) => {
        if (selectedKit) {
            const validationResult = isValidConfirmationPatientDetail(selectedKit)
            setState({ ...state, selectedKit: fromJS(selectedKit) })
            if (validationResult.allValid === true) {
                props.updateTestKitDataCall({
                    kit: {
                        id: selectedKit && selectedKit.id && selectedKit.id?.trim(),
                        givenName: selectedKit && selectedKit.givenName && selectedKit.givenName?.trim(),
                        familyName: selectedKit && selectedKit.familyName && selectedKit.familyName?.trim(),
                        fullName: selectedKit && `${selectedKit.givenName?.trim()} ${selectedKit.familyName?.trim()}`,
                        email: selectedKit && selectedKit.email && selectedKit.email?.toLowerCase()?.trim(),
                        addressLine1: selectedKit && selectedKit.addressLine1 && selectedKit.addressLine1?.trim(),
                        addressLine2: selectedKit && selectedKit.addressLine2 && selectedKit.addressLine2?.trim(),
                        city: selectedKit && selectedKit.city && selectedKit.city?.trim(),
                        postcode: selectedKit && selectedKit.postcode && selectedKit.postcode?.trim(),
                        dateOfTest: selectedKit && selectedKit.dateOfTest && selectedKit.dateOfTest?.trim(),
                        producerComments: selectedKit && selectedKit.producerComments && selectedKit.producerComments?.trim(),
                        telephone: selectedKit && selectedKit.telephone && selectedKit.telephone?.trim().replaceAll(" ", ""),
                        courierCost: selectedKit && selectedKit.courierCost && selectedKit.courierCost,
                        partnerLab: selectedKit && selectedKit.partnerLab && selectedKit.partnerLab,
                        updatedAt: Date.now()
                    }
                })
            }
            else {
                props.updateKit({
                    kit: {
                        id: selectedKit.id,
                        executionStatus: 'ERROR'
                    }
                }, true)
            }
        }
    }

    // console.log('updatedState ===>', props?.updatedState)

    return (
        <div className={classes.root}>
            <Snackbar anchorOrigin={
                { vertical: 'center', horizontal: 'center' }}
                open={state.showCopyAlert} autoHideDuration={5000} onClose={closeCopyAlert}>
                <Alert onClose={closeCopyAlert} severity="success">
                    Link copied
                </Alert>
            </Snackbar>
            <Paper elevation={0} className={clsx("w-100", classes.paper, 'pl-3 pt-5')}>
                {
                    execStatusGetKits && execStatusGetKits.status === "PENDING" ?
                        <div className="w-100">
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                            <Skeleton variant="text" width="100%" height={45} />
                        </div> : null
                }
                {
                    execStatusGetKits && execStatusGetKits.status !== "PENDING" ?
                        <TableContainer className={classes.tableContainer}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'small'}
                                aria-label="enhanced table">
                                {
                                    execStatusGetKits && execStatusGetKits.status !== "PENDING" ?
                                        <EnhancedTableHead
                                            updatedStatus={props?.updatedState}
                                            classes={classes}
                                            {...props}
                                        /> : null
                                }
                                <TableBody>
                                    {
                                        kits && kits.get('kitsArray') && kits.get('kitsArray').map((kit, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    style={{
                                                        // position: "relative",
                                                        background: kit.get('executionStatus') === "PENDING" ? '#fcffcd' : kit.get('status') === "CONFIRMATION_LINK_SENT" ? "white" : kit.get('executionStatus') === "ERROR" ? '#ffcdcd' : kit.get('selected') === true ? '#f3f3f3' : 'inherit' //fcffcd
                                                    }}
                                                    key={index}>
                                                    <TableCell scope="row">
                                                        {
                                                            isShowCheckBox(kit) ?
                                                                <FormControlLabel
                                                                    className="checkbox-size"
                                                                    control={
                                                                        <Checkbox
                                                                            color="secondary"
                                                                            checked={kit.get('selected') === true ? true : false}
                                                                            onChange={() => props.handleCheckBoxChange(kit)}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    }
                                                                    label="" /> : null
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div className="d-flex align-items-center">
                                                            <div className='d-flex flex-column justify-content-center align-items-start'>
                                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        {index + 1}
                                                                        {
                                                                            execStatusCancelTest.status === "PENDING" && state.selectedKit.get('id') === kit.get('id') &&
                                                                            <Paper className="paper-root"> <CircularProgress
                                                                                className="p-2" size={20} /> </Paper>

                                                                        }
                                                                    </div>
                                                                    {/* {
                                                                        kit.get('taskId') &&
                                                                        <div onClick={() => showCourierStatusDialog(kit)} className=" text-primary cursor-pointer">
                                                                            Courier Status</div>
                                                                    } */}
                                                                </div>
                                                                {
                                                                    kit.get('executionStatus') === "ERROR" &&
                                                                    <div
                                                                        onClick={() => {
                                                                            setState({ ...state, showErrorDialog: true, selectedKitForErrorDialog: kit });
                                                                        }}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            width: 'max-content'
                                                                        }} className="text-danger pt-3 cursor-pointer">View Error</div>
                                                                }
                                                            </div>
                                                            <div>
                                                                <div className="pl-3">
                                                                    {kit.get('executionStatus') === "PENDING" ? <CircularProgress size={15} /> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TableCell>



                                                    {
                                                        (props?.updatedState === 'CONFIRMATION_LINK_SENT' || props?.updatedState === 'INDIVIDUAL_CONFIRMED_TEST_DETAILS' || props?.updatedState === 'Assigned') &&
                                                        <>
                                                            <TableCell align="left">
                                                                <div className='="d-flex flex-column' style={{ width: "100px" }}>
                                                                    {
                                                                        (kit.get('status') === "CONFIRMATION_LINK_SENT" || kit.get('status') === "INDIVIDUAL_CONFIRMED_TEST_DETAILS" || kit.get('status') === "Assigned") ?
                                                                            <a target="_blank" href={`${window.location.origin}/confirmation-form/${kit.get('id')}`}>Form Link</a> : '-'
                                                                    }
                                                                    {
                                                                        (kit.get('status') === "CONFIRMATION_LINK_SENT") ?
                                                                            <div className='text-primary cursor-pointer' onClick={() => copyLink(`${window.location.origin}/confirmation-form/${kit.get('id')}`)}>Copy Link</div> : ''
                                                                    }
                                                                </div>
                                                            </TableCell>

                                                        </>
                                                    }
                                                    {
                                                        props?.updatedState !== 'CONFIRMATION_PENDING' &&
                                                        <>

                                                            <TableCell align="left">

                                                                {
                                                                    kit.get('tasId') &&
                                                                    <div style={{ width: "150px" }} onClick={() => showCourierStatusDialog(kit)} className="text-primary cursor-pointer">
                                                                        View Courier Status</div>
                                                                }</TableCell>
                                                        </>
                                                    }



                                                    <TableCell align="left" style={(props?.updatedState === 'CONFIRMATION_PENDING' || props?.updatedState === 'CONFIRMATION_LINK_SENT') ? { position: "-webkit-sticky", position: "sticky", left: 0, zIndex: 99 } : {}}>
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField
                                                                    minWidth={80}
                                                                    onBlur={(e) => {
                                                                        props.updatePatientDetailData(kit.get('id'), 'givenName', e.target.value, "", `${e.target.value} ${kit.get("familyName")}`);
                                                                        updateKit({ ...kit.toJS(), givenName: e.target.value })
                                                                    }
                                                                    }
                                                                    defaultValue={kit.get("givenName") ? kit.get("givenName") : ""} placeholder="Full Name" />
                                                                :
                                                                kit.get("givenName") ? kit.get("givenName") : ""
                                                        }
                                                    </TableCell>

                                                    <TableCell align="left" style={(props?.updatedState === 'CONFIRMATION_PENDING' || props?.updatedState === 'CONFIRMATION_LINK_SENT') ? { position: "-webkit-sticky", position: "sticky", left: 130, zIndex: 99 } : {}}>
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField
                                                                    minWidth={80}
                                                                    onBlur={(e) => {
                                                                        props.updatePatientDetailData(kit.get('id'), 'familyName', e.target.value, "", `${kit.get("givenName")} ${e.target.value}`)
                                                                        updateKit({ ...kit.toJS(), familyName: e.target.value })
                                                                    }}
                                                                    defaultValue={kit.get("familyName") ? kit.get("familyName") : ""} placeholder="Full Name" />
                                                                :
                                                                kit.get("familyName") ? kit.get("familyName") : ""
                                                        }
                                                    </TableCell>

                                                    <TableCell align="left" style={(props?.updatedState === 'CONFIRMATION_PENDING' || props?.updatedState === 'CONFIRMATION_LINK_SENT') ? { position: "-webkit-sticky", position: "sticky", left: 260, zIndex: 99 } : {}}>
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField onBlur={(e) => {
                                                                    props.updatePatientDetailData(kit.get('id'), 'email', e.target.value)
                                                                    updateKit({ ...kit.toJS(), email: e.target.value })
                                                                }}
                                                                    defaultValue={kit.get("email")} placeholder="Email" /> :
                                                                kit.get("email")
                                                        }
                                                    </TableCell>

                                                    <TableCell align="left" style={(props?.updatedState === 'CONFIRMATION_PENDING' || props?.updatedState === 'CONFIRMATION_LINK_SENT') ? { position: "-webkit-sticky", position: "sticky", left: 560, zIndex: 99 } : {}}>
                                                        {
                                                            isShowEditAble(kit) ?
                                                                // <div style={{ minWidth: "200px" }} >
                                                                //     <ReactTelephoneInput
                                                                //         defaultCountry="gb"
                                                                //         preferredCountries={['gb', 'us']}
                                                                //         value={kit.get("telephone")}
                                                                //         onlyCountries={contryList}
                                                                //         onChange={(telNumber, selectedCountry) => {
                                                                //             props.updatePatientDetailData(kit.get('id'), 'telephone', telNumber);
                                                                //             updateKit({ ...kit.toJS(), telephone: telNumber })
                                                                //         }}
                                                                //         flagsImagePath={`${process.env.PUBLIC_URL}/flags.png`}
                                                                //     />
                                                                // </div>
                                                                <SiteLabelTextField
                                                                    minWidth={200}
                                                                    onBlur={(e) => {
                                                                        props.updatePatientDetailData(kit.get('id'), 'telephone', e.target.value)
                                                                        updateKit({ ...kit.toJS(), telephone: e.target.value })
                                                                    }}
                                                                    defaultValue={kit.get("telephone")} placeholder="Telephone No" />
                                                                :
                                                                kit.get("telephone")
                                                        }
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <CustomCalender
                                                                    minDate={getPastDate(0)}
                                                                    defaultValue={moment(kit.get('dateOfTest'), "DD/MM/YYYY")}
                                                                    placeholder="Select Date of Test"
                                                                    onAccept={(date) => {
                                                                        props.updatePatientDetailData(kit.get('id'), 'dateOfTest', moment(date).format('DD/MM/YYYY'))
                                                                        updateKit({ ...kit.toJS(), dateOfTest: moment(date).format('DD/MM/YYYY') })
                                                                    }}
                                                                />

                                                                :
                                                                formatDate(moment(kit.get('dateOfTest'), "DD/MM/YYYY"))
                                                        }
                                                    </TableCell>


                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <MaterialAutoComplete
                                                                    dropdownData={{
                                                                        value: kit.get('serviceType')
                                                                    }}
                                                                    minWidth={250}
                                                                    onDefaultSelect={(data) => {
                                                                        props.updatePatientDetailData(kit.get('id'), 'serviceType', data.value, data);
                                                                        updateKit({
                                                                            ...kit.toJS(),
                                                                            serviceType: data.value,
                                                                            price: data && data.price,
                                                                            serviceTypeText: data && data.serviceTypeText,
                                                                            courierCost: data && data.courierCost,
                                                                            currency: data && data.currency,
                                                                            currencySymbol: data && data.currencySymbol
                                                                        })
                                                                    }}
                                                                    //onSelect={(value, data) => props.onSelect(value, data, "gender")}
                                                                    //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                                                    //onSelect={props.onKitStatusChange}
                                                                    placeholder="Select Service Type"
                                                                    data={SERVICE_TYPES}
                                                                /> :
                                                                kit.get("serviceTypeText")
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            kit.get("serviceType") !== "PCR_DAY_2" &&
                                                            <>
                                                                {
                                                                    isShowEditAble(kit) ?
                                                                        <MaterialAutoCompleteTypeable
                                                                            // dropdownData={{
                                                                            //     value: kit.get('preferredTime')
                                                                            // }}
                                                                            isEditable
                                                                            editableTime={true}
                                                                            minWidth={250}
                                                                            required={true}
                                                                            selectedItem={kit.get('preferredTime') && getPreferredTime(kit.get('preferredTime'))}
                                                                            isGetValueOnEditOn
                                                                            onSelect={(value, data) => {
                                                                                props.updatePatientDetailData(kit.get('id'), 'preferredTime', value, data);

                                                                                let tempValue = value
                                                                                let preferredFromTime = null
                                                                                let preferredToTime = null

                                                                                if (tempValue && tempValue.replaceAll(" ", "").length === 11) {
                                                                                    tempValue = tempValue.replaceAll(" ", "")
                                                                                    console.log(tempValue, "xyz")
                                                                                    const preferredTimeRagex = /(((0[7-9])|(1[0-8])):([0-5][0-9])(-| - | -|- )((0[7-9])|(1[0-8])):([0-5][0-9]))/
                                                                                    if (preferredTimeRagex.test(tempValue)) {
                                                                                        const preferredFromTimeInt = parseInt(tempValue.slice(0, 2))
                                                                                        const preferredToTimeInt = parseInt(tempValue.slice(6, 8))
                                                                                        if (preferredFromTimeInt <= preferredToTimeInt) {
                                                                                            preferredFromTime = tempValue.slice(0, 5)
                                                                                            preferredToTime = tempValue.slice(6, tempValue.length)
                                                                                        }
                                                                                        else {
                                                                                            tempValue = ""
                                                                                            preferredFromTime = null
                                                                                            preferredToTime = null
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        tempValue = ""
                                                                                        preferredFromTime = null
                                                                                        preferredToTime = null
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    tempValue = ""
                                                                                    preferredFromTime = ""
                                                                                    preferredToTime = ""
                                                                                }
                                                                                updateKit({
                                                                                    ...kit.toJS(),
                                                                                    preferredTime: tempValue,
                                                                                    preferredFromTime: preferredFromTime,
                                                                                    preferredToTime: preferredToTime,
                                                                                })
                                                                            }}
                                                                            placeholder="Select preferred Time"
                                                                            data={PREFERRED_TIMES}
                                                                        />
                                                                        :
                                                                        kit.get('preferredTime')
                                                                }
                                                            </>
                                                        }
                                                    </TableCell>



                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField onBlur={(e) => {
                                                                    props.updatePatientDetailData(kit.get('id'), 'addressLine1', e.target.value);
                                                                    updateKit({ ...kit.toJS(), addressLine1: e.target.value })
                                                                }}
                                                                    defaultValue={kit.get("addressLine1")} placeholder="Test Address Line 1" /> :
                                                                kit.get("addressLine1")
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField onBlur={(e) => {
                                                                    props.updatePatientDetailData(kit.get('id'), 'addressLine2', e.target.value);
                                                                    updateKit({ ...kit.toJS(), addressLine2: e.target.value })
                                                                }}
                                                                    defaultValue={kit.get("addressLine2")} placeholder="Test Address Line 2" /> :
                                                                kit.get("addressLine2")
                                                        }
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField onBlur={(e) => {
                                                                    props.updatePatientDetailData(kit.get('id'), 'city', e.target.value);
                                                                    updateKit({ ...kit.toJS(), city: e.target.value })
                                                                }}
                                                                    defaultValue={kit.get("city")} placeholder="Please enter test city" /> :
                                                                kit.get("city")
                                                        }
                                                    </TableCell>

                                                    {/* <TableCell align="left">
                                                        <MaterialAutoCompleteTypeable
                                                            // startAdornment="Document Country"
                                                            style={{ width: "max-content" }}
                                                            selectedItem={getCountry(kit.get("country"))}
                                                            onSelect={(value, data) => props.updatePatientDetailData(kit.get('id'), 'country', data)}
                                                            placeholder="Select Country from a list"
                                                            data={COUNTRIES_LIST}
                                                        />
                                                    </TableCell> */}

                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField
                                                                    minWidth={80}
                                                                    onBlur={(e) => {
                                                                        props.updatePatientDetailData(kit.get('id'), 'postcode', e.target.value);
                                                                        updateKit({ ...kit.toJS(), postcode: e.target.value })
                                                                    }}
                                                                    defaultValue={kit.get("postcode")} placeholder="Post Code" /> :
                                                                kit.get("postcode")
                                                        }
                                                    </TableCell>




                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField onBlur={(e) => {
                                                                    props.updatePatientDetailData(kit.get('id'), 'producerComments', e.target.value);
                                                                    updateKit({ ...kit.toJS(), producerComments: e.target.value })
                                                                }}
                                                                    defaultValue={kit.get("producerComments")} placeholder="Comments" /> :
                                                                kit.get("producerComments")
                                                        }
                                                        {/* {
                                                            <div style={{ minWidth: "150px" }}>
                                                                {
                                                                    kit.get("producerComments")
                                                                }
                                                            </div>
                                                        } */}
                                                    </TableCell>




                                                    {/* <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField
                                                                    minWidth={80}
                                                                    onBlur={(e) =>
                                                                        props.updatePatientDetailData(kit.get('id'), 'givenName', e.target.value)}
                                                                    defaultValue={kit.get("givenName")} placeholder="First Name" /> :
                                                                kit.get("givenName")
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField
                                                                    minWidth={80}
                                                                    onBlur={(e) =>
                                                                        props.updatePatientDetailData(kit.get('id'), 'familyName', e.target.value)}
                                                                    defaultValue={kit.get("familyName")} placeholder="Last Name" /> :
                                                                kit.get("familyName")
                                                        }
                                                    </TableCell> */}
                                                    {/*<TableCell align="left">*/}
                                                    {/*    {*/}
                                                    {/*        isShowEditAble(kit) ?*/}
                                                    {/*            <CustomCalender*/}
                                                    {/*                defaultValue={moment(kit.get('DOB'), "DD/MM/YYYY")}*/}
                                                    {/*                placeholder="Select Date of Birth"*/}
                                                    {/*                onAccept={(date) => props.updatePatientDetailData(kit.get('id'), 'DOB', moment(date).format('DD/MM/YYYY'))}*/}
                                                    {/*            />*/}

                                                    {/*            // < Calender*/}
                                                    {/*            //     value={getDate(kit.get('DOB'))}*/}
                                                    {/*            //     placeholder="Select Date of Test"*/}
                                                    {/*            //     getSelectedDate={(date) => props.updatePatientDetailData(kit.get('id'), 'DOB', moment(date).format('DD/MM/YYYY'))}*/}
                                                    {/*            // />*/}
                                                    {/*            :*/}

                                                    {/*            formatDate(moment(kit.get('DOB'), "DD/MM/YYYY"))*/}
                                                    {/*    }*/}
                                                    {/*</TableCell>*/}
                                                    {/*
                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <MaterialAutoComplete
                                                                    dropdownData={{
                                                                        value: kit.get('gender')
                                                                    }}
                                                                    minWidth={250}
                                                                    onDefaultSelect={(data) => props.updatePatientDetailData(kit.get('id'), 'gender', data.value)}
                                                                    //onSelect={(value, data) => props.onSelect(value, data, "gender")}
                                                                    //disabled={execStatusGetKits && execStatusGetKits.status === "PENDING" ? true : false}
                                                                    //onSelect={props.onKitStatusChange}
                                                                    placeholder="Select gender"
                                                                    data={GENDER_LIST}
                                                                /> :
                                                                kit.get("gender")
                                                        }

                                                    </TableCell> */}



                                                    {/*
                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField
                                                                    minWidth={80}
                                                                    onBlur={(e) =>
                                                                        props.updatePatientDetailData(kit.get('id'), 'homeAddressPostcode', e.target.value)}
                                                                    defaultValue={kit.get("homeAddressPostcode")} placeholder="Post Code" /> :
                                                                kit.get("homeAddressPostcode")
                                                        }
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField onBlur={(e) =>
                                                                    props.updatePatientDetailData(kit.get('id'), 'homeAddressLine1', e.target.value)}
                                                                    defaultValue={kit.get("homeAddressLine1")} placeholder="Home Address Line 1" /> :
                                                                kit.get("homeAddressLine1")
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField onBlur={(e) =>
                                                                    props.updatePatientDetailData(kit.get('id'), 'homeAddressLine2', e.target.value)}
                                                                    defaultValue={kit.get("homeAddressLine2")} placeholder="Home Address Line 2" /> :
                                                                kit.get("homeAddressLine2")
                                                        }
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField onBlur={(e) =>
                                                                    props.updatePatientDetailData(kit.get('id'), 'homeAddressCity', e.target.value)}
                                                                    defaultValue={kit.get("homeAddressCity")} placeholder="Home City" /> :
                                                                kit.get("city")
                                                        }
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <MaterialAutoCompleteTypeable
                                                            // startAdornment="Document Country"
                                                            style={{ width: "max-content" }}
                                                            selectedItem={getCountry(kit.get("homeAddressCountry"))}
                                                            onSelect={(value, data) => props.updatePatientDetailData(kit.get('id'), 'homeAddressCountry', data)}
                                                            placeholder="Select Country from a list"
                                                            data={COUNTRIES_LIST}
                                                        />
                                                    </TableCell> */}

                                                    {/*<TableCell align="left">*/}
                                                    {/*    {*/}
                                                    {/*        isShowEditAble(kit) ?*/}
                                                    {/*            <SiteLabelTextField*/}
                                                    {/*                minWidth={80}*/}
                                                    {/*                onBlur={(e) =>*/}
                                                    {/*                    props.updatePatientDetailData(kit.get('id'), 'nationality', e.target.value)}*/}
                                                    {/*                defaultValue={kit.get("nationality")} placeholder="Nationality" /> :*/}
                                                    {/*            kit.get("nationality")*/}
                                                    {/*    }*/}
                                                    {/*</TableCell>*/}

                                                    {/*<TableCell align="left">*/}
                                                    {/*    {*/}
                                                    {/*        isShowEditAble(kit) ?*/}
                                                    {/*            <SiteLabelTextField onBlur={(e) =>*/}
                                                    {/*                props.updatePatientDetailData(kit.get('id'), 'healthNumber', e.target.value)}*/}
                                                    {/*                defaultValue={kit.get("healthNumber")} placeholder="Health Number" /> :*/}
                                                    {/*            kit.get("healthNumber")*/}
                                                    {/*    }*/}
                                                    {/*</TableCell>*/}
                                                    {/*<TableCell align="left">*/}
                                                    {/*    {*/}
                                                    {/*        isShowEditAble(kit) ?*/}
                                                    {/*            <SiteLabelTextField onBlur={(e) =>*/}
                                                    {/*                props.updatePatientDetailData(kit.get('id'), 'documentType', e.target.value)}*/}
                                                    {/*                defaultValue={kit.get("documentType")} placeholder="Document Type" /> :*/}
                                                    {/*            kit.get("documentType")*/}
                                                    {/*    }*/}
                                                    {/*</TableCell>*/}
                                                    {/*<TableCell align="left"> {kit.get("documentCountry")}</TableCell>*/}
                                                    {/*<TableCell align="left">*/}
                                                    {/*    {*/}
                                                    {/*        isShowEditAble(kit) ?*/}
                                                    {/*            <SiteLabelTextField onBlur={(e) =>*/}
                                                    {/*                props.updatePatientDetailData(kit.get('id'), 'documentNumber', e.target.value)}*/}
                                                    {/*                defaultValue={kit.get("documentNumber")} placeholder="Document Number" /> :*/}
                                                    {/*            kit.get("documentNumber")*/}
                                                    {/*    }*/}
                                                    {/*</TableCell>*/}
                                                    {/*<TableCell align="left"> {*/}
                                                    {/*    isShowEditAble(kit) ?*/}
                                                    {/*        // <CustomCalender*/}
                                                    {/*        //     defaultValue={moment(kit.get('documentExpiration'), "DD/MM/YYYY")}*/}
                                                    {/*        //     placeholder="Select Date of Expiration"*/}
                                                    {/*        //     onAccept={(date) => props.updatePatientDetailData(kit.get('id'), 'documentExpiration', moment(date).format('DD/MM/YYYY'))}*/}
                                                    {/*        // />                                                             */}
                                                    {/*        <Calender*/}
                                                    {/*            value={kit.get('documentExpiration') ? (new Date(kit.get('documentExpiration')) !== "Invalid Date" ? kit.get('documentExpiration')._d : kit.get('documentExpiration')) : ""}*/}
                                                    {/*            placeholder="Select Date of Test"*/}
                                                    {/*            getSelectedDate={(date) => props.updatePatientDetailData(kit.get('id'), 'documentExpiration', moment(date).format('DD/MM/YYYY'))}*/}
                                                    {/*        />*/}
                                                    {/*        :*/}
                                                    {/*        formatDate(moment(kit.get('documentExpiration'), "DD/MM/YYYY"))*/}
                                                    {/*}</TableCell>*/}
                                                    {/*<TableCell align="left">*/}
                                                    {/*    {*/}
                                                    {/*        isShowEditAble(kit) ?*/}
                                                    {/*            <SiteLabelTextField*/}
                                                    {/*                minWidth={80}*/}
                                                    {/*                onBlur={(e) =>*/}
                                                    {/*                    props.updatePatientDetailData(kit.get('id'), 'documentAuthority', e.target.value)}*/}
                                                    {/*                defaultValue={kit.get("documentAuthority")} placeholder="Document Authority" /> :*/}
                                                    {/*            kit.get("documentAuthority")*/}
                                                    {/*    }*/}
                                                    {/*</TableCell>*/}
                                                    {/* <TableCell align="left">{getStatus(kit.get('status'))}</TableCell> */}
                                                    <TableCell align="left"> <div style={{ width: "70px" }}>{kit.get("organisationId")}</div></TableCell>
                                                    <TableCell align="left"> <div style={{ width: "70px" }}>{kit.get("eventName")}</div></TableCell>
                                                    <TableCell align="left"><div style={{ width: "70px" }}>{format24HrDateTime(kit.get('updatedAt'))}</div></TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <SiteLabelTextField
                                                                    minWidth={80}
                                                                    onBlur={(e) => {
                                                                        props.updatePatientDetailData(kit.get('id'), 'courierCost', e.target.value);
                                                                        updateKit({ ...kit.toJS(), courierCost: e.target.value })
                                                                    }}
                                                                    defaultValue={kit.get("courierCost")} placeholder="Courier Cost" /> :
                                                                kit.get("courierCost")
                                                        }
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {
                                                            isShowEditAble(kit) ?
                                                                <RadioButton
                                                                    options={PARTNER_LABS}
                                                                    selectedOption={kit.get('partnerLab')}
                                                                    onSelect={(option) => {
                                                                        props.updatePatientDetailData(kit.get('id'), 'partnerLab', option.value);
                                                                        updateKit({ ...kit.toJS(), partnerLab: option.value })
                                                                    }} />
                                                                :
                                                                kit.get("partnerLab") ? kit.get("partnerLab") : ""
                                                        }
                                                    </TableCell>

                                                    {
                                                        (props?.updatedState === 'CONFIRMATION_PENDING' || props?.updatedState === 'CONFIRMATION_LINK_SENT') &&
                                                        <>
                                                            <TableCell align='center'>
                                                                {

                                                                    execStatusCancelTest.status === "PENDING" && state.selectedKit.get('id') === kit.get('id') ?
                                                                        <Paper className="paper-root"> <CircularProgress
                                                                            className="p-2" size={20} /> </Paper> :
                                                                        isShowCancelOrUpdateButton(kit) &&
                                                                        <div className='w-100 d-flex'>
                                                                            {
                                                                                props?.updatedState === 'CONFIRMATION_LINK_SENT' &&
                                                                                <Tooltip title="Update Test Details" placement="top">
                                                                                    <i style={{ cursor: "pointer", color: "rgb(6, 122, 184)", fontSize: "1.25em" }} className="fas fa-pencil-alt fa-2x mr-3" onClick={() => showUpdateConfirmationDialog(kit)}></i>
                                                                                </Tooltip>

                                                                            }
                                                                            {
                                                                                kit.get('status') !== "CANCELLED" ?
                                                                                    <Tooltip title="Cancel Test" placement="top">
                                                                                        <i style={{ cursor: "pointer", color: "red", fontSize: "1.25em" }}
                                                                                            className="fa fa-times pl-3"
                                                                                            onClick={() => showCancelConfirmationDialog(kit)}></i>
                                                                                    </Tooltip> : null

                                                                            }
                                                                        </div>

                                                                }

                                                            </TableCell>
                                                        </>
                                                    }

                                                    {/*<TableCell align="left"> {kit.get('executionStatus') === "PENDING" ?*/}
                                                    {/*    <CircularProgress className="p-2" size={30} /> :*/}
                                                    {/*    kit.get('status') === "CONFIRMATION_LINK_SENT" ? "Successfully Updated"*/}
                                                    {/*        : null}</TableCell>*/}

                                                </TableRow>
                                            )

                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer> : null

                }
                {
                    state.showCourierStatusDialogOpen === true ?
                        <CourierStatusDialog selectedKit={state.selectedKit} {...props} handleClose={hideCourierStatusDialog}
                            open={state.showCourierStatusDialogOpen} /> : null
                }
                {
                    state.showCancelConfirmationDialogOpen === true ?
                        <CancellationConfirmationDialog selectedKit={state.selectedKit} {...props} handleClose={hideCancelConfirmationDialog}
                            open={state.showCancelConfirmationDialogOpen} type="cancel" /> : null
                }
                {
                    state.showUpdateConfirmationDialogOpen === true ?
                        <CancellationConfirmationDialog selectedKit={state.selectedKit} {...props} handleClose={hideUpdateConfirmationDialog}
                            open={state.showUpdateConfirmationDialogOpen} type="update" /> : null
                }
                <InfoValidationDialog
                    handleClose={() => { setState({ ...state, showErrorDialog: false }) }}
                    open={state.showErrorDialog}
                    dataToValidate={state.selectedKitForErrorDialog ? state.selectedKitForErrorDialog.toJS() : null}
                    validationFor="adminConfirmationOrder"
                />

            </Paper>
        </div>
    );
}
