import React from 'react'
import { Column, Row } from './containers'
import { FormTextInput, FormCheckboxInput } from './forms'

const PatientDetailsEditor = ({ editPatientMode, isMailKit, orgs }) => {
	console.log('patient details editor: ', isMailKit)
	const emailRow = editPatientMode ? (
		<Row size={8} spacingTop="s">
			<FormTextInput
				desktopSize={8}
				tabletSize={8}
				label="Email"
				name="email"
				required
				pattern={/(.*)@(.*)\.([a-zA-Z]{2,20})/}
				errorMessage="Has to be valid email address"
			/>
		</Row>
	) : (
		<>
			<Row size={8} spacingTop="s" spaced>
				<FormTextInput
					desktopSize={4}
					tabletSize={4}
					label="Email"
					name="email"
					required
					pattern={/(.*)@(.*)\.([a-zA-Z]{2,20})/}
					errorMessage="Has to be valid email address"
				/>
				<FormTextInput
					desktopSize={4}
					tabletSize={4}
					label="Confirm email"
					name="confirmationEmail"
					confirmationFor="email"
					errorMessage="Emails must match"
				/>
			</Row>
			<FormCheckboxInput name="shouldShareResults">
				Send a copy of results to this email address (individual will also receive results through
				their employer)
			</FormCheckboxInput>
		</>
	)
	let date = new Date()
	let options = { day: '2-digit', month: '2-digit', year: 'numeric' }
	let today = date.toLocaleDateString('en-GB', options)
	let todayTime = date.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
	})

	return (
		<>
			<Column size={8}>
				<h5>Enter patient details</h5>
				<Row size={8} spacingTop="xs" spaced>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						label="First name"
						name="firstName"
						required
						errorMessage="Field is required"
					/>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						label="Last name"
						name="lastName"
						required
						errorMessage="Field is required"
					/>
				</Row>
				{emailRow}
				{!isMailKit && (
					<Row size={8} spacingTop="m" spaced>
						<FormTextInput
							desktopSize={4}
							tabletSize={4}
							label="Date of birth (DD / MM / YYYY)"
							placeholder="01  /  11  /  1980"
							mask="99  /  99  /  9999"
							name="dateOfBirth"
							required
							pattern={
								/^(([0-2][0-9]|(3)[0-1])[\s]+\/[\s]+(((0)[0-9])|((1)[0-2]))[\s]+\/[\s]+(?:19|20)\d\d$)$/
							}
							errorMessage="Please enter date in DD / MM / YYYY format"
						/>
						<FormTextInput
							desktopSize={4}
							tabletSize={4}
							label="Gender"
							datalist={['M', 'F', 'U']}
							name="gender"
							required
							pattern={/^(F|M|U)$/i}
							errorMessage="Please enter one of: F, M, or U"
						/>
					</Row>
				)}
				{!isMailKit && (
					<Row size={8} spacingTop="m" spaced>
						<FormTextInput
							desktopSize={4}
							tabletSize={4}
							label="Date of Swab (DD / MM / YYYY)"
							placeholder={today}
							name="dateOfSwab"
							required
							pattern={/^(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](20)\d\d$/}
							errorMessage={`Please enter swab data in DD / MM / YYYY format, e.g: ${today}`}
						/>
						<FormTextInput
							desktopSize={4}
							tabletSize={4}
							label="Time of Swab (HH:mm) 24 hour format"
							placeholder={todayTime}
							name="timeOfSwab"
							required
							pattern={/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/}
							errorMessage={`Please enter swab time in 24 hour HH:mm format, e.g: ${todayTime}`}
						/>
					</Row>
				)}
				<Row size={8} spacingTop="m">
					<FormTextInput
						desktopSize={8}
						tabletSize={8}
						label="Address line 1"
						name="addressLine1"
						required={editPatientMode}
						errorMessage="Field is required"
					/>
				</Row>
				<Row size={8} spacingTop="s">
					<FormTextInput
						desktopSize={8}
						tabletSize={8}
						label="Address line 2"
						name="addressLine2"
						errorMessage="Field is required"
					/>
				</Row>
				<Row size={8} spacingTop="s" spaced>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						label="Town/City"
						name="city"
						required={editPatientMode}
						errorMessage="Field is required"
					/>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						label="Postcode"
						name="postcode"
						required={editPatientMode}
						errorMessage="Field is required"
					/>
				</Row>
				<Row size={8} spacingTop="s" spaced>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						label="Test kit type"
						datalist={['Mailed', 'Courier']}
						name="testKitType"
						pattern={/^(Mailed|Courier)$/i}
						errorMessage="Please enter one of: Mailed, Courier"
					/>
					<FormTextInput
						desktopSize={4}
						tabletSize={4}
						type="number"
						label="Courier Price"
						name="courierPrice"
						errorMessage="Field is a number field"
					/>
				</Row>
			</Column>
		</>
	)
}

export { PatientDetailsEditor }
