import React, { useEffect, useState, useContext } from 'react'
import { Row, Column, Body, Button, Spacer, CheckboxInput, BackButton } from '../../../components'
import { useModal, useOrdering, useAuth } from '../../../utils'
import tickIcon from '../../../assets/images/tick-icon.svg'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import PatientsList from './patientList'
import { formatTime } from '../../../utils/uitl'
import { checkExecutionStatus } from 'components/common/util/util';
import { AppContext } from '../../../app.container'
import moment from 'moment';

const DetailsRow = ({ label, value }) => {
	return (
		<Row desktopSize={5} tabletSize={10}>
			<Row desktopSize={2} tabletSize={3}>
				<Body>{label}:</Body>
			</Row>
			<Row desktopSize={3} tabletSize={7}>
				<Body>{value}</Body>
			</Row>
		</Row>
	)
}

const OrderRow = ({ label, noLabel, children }) => {
	return (
		<Row desktopSize={4} tabletSize={5} margin={0}>
			<Column desktopSize={2} tabletSize={3} marginTop={0} marginRight={0} padding="none">
				{noLabel ? null : <Body>{label}:</Body>}
			</Column>
			<Column
				desktopSize={2}
				tabletSize={2}
				marginTop={0}
				padding="none"
				style={{ maxWidth: '150px' }}
			>
				<Body>{children}</Body>
			</Column>
		</Row>
	)
}

const toDdMmYyyy = (date) => {
	if (!date) {
		return ''
	}
	const year = date.substring(0, 4)
	const month = date.substring(4, 6)
	const day = date.substring(6, 8)
	return `${day}/${month}/${year}`
}
const InvoiceLine = ({ label, children, bold }) => {
	const l = label === '' ? '' : `${label}:`
	return (
		<Row size={6} marginTop={0}>
			<Row size={3} marginTop={0}>
				<Body className={clsx({ bold: bold })}>{l}</Body>
			</Row>
			<Row size={3} marginTop={0}>
				<Body>{children}</Body>
			</Row>
		</Row>
	)
}
const AddressSegment = ({ addressWithRecipient }) => {
	if (addressWithRecipient) {
		const { recipient, addressLine1, addressLine2, city, postcode, country } = addressWithRecipient
		return (
			<Column desktopSize={8}>
				<InvoiceLine label="Pracitioner">{recipient}</InvoiceLine>
				<InvoiceLine label="Address">{addressLine1}</InvoiceLine>
				<InvoiceLine label="">{addressLine2}</InvoiceLine>
				<InvoiceLine label="">
					{city} {postcode}
				</InvoiceLine>
				<InvoiceLine label="">{country}</InvoiceLine>
			</Column>
		)
	}
	return null
}

const OrderConfirmation = ({
	confirmed,
	patientDetails,
	organisationId,
	onBackClick,
	onNext,
	onEditPatientDetailsClick,
	customer,
	patientsList,
	processSameDayOrder
}) => {
	const [greeting] = confirmed ? ['Thank you'] : ['Confirm details and test completion']
	const {
		firstName,
		lastName,
		fullName,
		email,
		telephone,
		jobNumber,
		deliveryDate,
		deliverBy,
		deliveryWindow,
		driverNotes,
		addressLine1,
		addressLine2,
		city,
		postcode,
		orderType,
	} = patientDetails
	const [errorMessage, setErrorMessage] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [agreedToTandC, setAgreedToTandC] = useState(false)
	const { submitOrder, createSameDayOrder } = useOrdering()

	const performOnNext = () => {
		// onNext()
	}
	const history = useHistory()

	// setIsSubmittingOrder(true)

	const performSubmitOrder = () => {
		console.log('submitting order::')
		const orderDraft = createSameDayOrder(patientsList, orderType === 'courier' ? true : false)
		// if (orderType !== 'courier') {
		// 	const address = {
		// 		recipient: patientDetails.firstName + ' ' + patientDetails.lastName,
		// 		addressLine1: patientDetails.addressLine1,
		// 		addressLine2: patientDetails.addressLine2,
		// 		city: patientDetails.city,
		// 		postcode: patientDetails.postcode,
		// 	}
		// 	orderDraft.address = address
		// }
		const patients = orderDraft.patients.map((patient) => {
			delete patient['confirmEmail']
			return ({
				...patient,
				gender: patient.gender && patient.gender.toUpperCase(),
				email: patient.email && patient.email.toLowerCase(),
				DOB: patient.DOB// && moment(patient.DOB, "DD/MM/YYYY").format('YYYY/MM/DD')
			})
		}
		)

		// const courier = params.get('courier')

		const order = {
			...orderDraft,
			organisationId,
			patients,
			createdBy: customer.uid,
			email: customer.email,
		}
		order.orderType = "same-day-test"
		processSameDayOrder(order)
		// submitOrder(order)
		// 	.then((result) => {
		// 		console.log('submitting to firebase: ', order)
		// 		return order.patients.map((patient) => ({
		// 			orderUid: order.orderUid,
		// 			patient,
		// 		}))
		// 	})
		// 	.then((results) => {
		// 		console.log('results: ', results)
		// 		setIsLoading(false)
		// 		//onNext()
		// 		history.push('/customer/order-history')
		// 	})
		// 	.catch((error) => {
		// 		console.error('submitOrder', 'error:', error)
		// 		setErrorMessage(error && error.message)
		// 		setIsLoading(false)
		// 	})
	}

	const handleOnNextClick = confirmed ? performOnNext : performSubmitOrder
	const { defaultOrganisation } = useAuth()
	const organisationName = defaultOrganisation();
	const createOrderDraft = createSameDayOrder.bind(null, [patientDetails], false)
	const {
		confirmationDisplayText: {
			subTotalText,
			vatText,
			totalText,
			numberOfKitsText,
			orderTypeText,
			orderDateText,
			estimatedDeliveryDateText,
		},
	} = createOrderDraft()
	const ordertime = formatTime(new Date())
	const context = useContext(AppContext);
	const execStatusProcessSameDayOrder = checkExecutionStatus(context, "PROCESS_SAME_DAY_ORDER");
	return (
		<>
			<Column desktopSize={9}>
				<Spacer spacing="m" />
				<h5 className="no-space"></h5>
				<h3 className="no-space">Please confirm your order</h3>
				<Body paragraph>
					You will be emailed an invoice once you have confirmed the order which will need to be
					settled by the agreed payment terms.
				</Body>
				<Column bordered desktopSize={9} style={{ padding: '2rem' }}>
					<Column desktopSize={9}>
						{/* <InvoiceLine label="Company">{organisationName}</InvoiceLine> */}
						<InvoiceLine label="Order date">{`${orderDateText} ${ordertime}`}</InvoiceLine>
					</Column>
					<Column desktopSize={9} style={{ paddingTop: '2rem' }}>
						<InvoiceLine label="Order type">{orderTypeText}</InvoiceLine>
						<InvoiceLine label="Number of kits">{patientsList && patientsList.length}</InvoiceLine>
						{/* <InvoiceLine label="Estimated delivery date">{estimatedDeliveryDateText}</InvoiceLine> */}
					</Column>
					{/* <Column desktopSize={8} style={{ paddingTop: '2rem' }}>
					<InvoiceLine label="Price">{subTotalText}</InvoiceLine>
					<InvoiceLine label="VAT">{vatText}</InvoiceLine>
					<InvoiceLine label="Total price" bold>
						<strong>{totalText}</strong>
					</InvoiceLine>
				</Column> */}
					<Column desktopSize={9} style={{ paddingTop: '2rem' }}>
						<h5 className="no-space">Patient Details</h5>
						{
							patientsList && patientsList.map((patient, index) => {
								return <PatientsList patient={patient} no={index + 1} key={index} />
							})
						}
					</Column >


					<Column desktopSize={9} style={{ paddingTop: '2rem' }}>
						<Column desktopSize={6} tabletSize={10}>
							<Body small bold>
								Please read and confirm:
							</Body>
							<Body small>· I have authorisation to place an order for use of this service.</Body>
							<Body small>
								· I have provided complete and accurate information required for the use of this
								service.
							</Body>
							<Body small>
								· I have been given explicit consent by the data subject for the processing of the
								data and the use of this service.
							</Body>
							<Body small>
								· I fully understand my obligations as a data controller. If I am unsure about any
								aspect of the service I will contact Beacon Secure before proceeding.
							</Body>
							<Body small>
								· I have read, understand and agree to the&nbsp;
								<Link to="/terms-and-conditionsterms" style={{ fontSize: '1.2rem' }}>
									terms and conditions
								</Link>
								&nbsp;and&nbsp;
								<Link to="/privacy-policy" style={{ fontSize: '1.2rem' }}>
									privacy policy
								</Link>
								.
							</Body>
							<br />
						</Column>
						<CheckboxInput desktopSize={8} checked={agreedToTandC} setter={setAgreedToTandC}>
							I confirm the above
						</CheckboxInput>
					</Column>
				</Column>
				<Spacer spacing="s" />
				<Row desktopSize={9} style={{ justifyContent: 'space-between' }}>
					<Button secondary onClick={onEditPatientDetailsClick}>
						← Back
					</Button>
					<Button
						primary
						style={{ minWidth: '15rem' }}
						loading={execStatusProcessSameDayOrder.status === "PENDING" ? true : false}
						onClick={handleOnNextClick}
						disabled={!agreedToTandC}
					>
						Confirm order
					</Button>
				</Row>
			</Column>
		</>
	)
}

export { OrderConfirmation }
