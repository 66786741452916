import './kits.scss';
import React, { useEffect, useState } from 'react'
import { Checkbox, CircularProgress, FormControlLabel, Paper } from "@material-ui/core";
import { IsMobileWidth } from "components/common/util/util";
import Pagination from '@material-ui/lab/Pagination';
import { format24HrDateTime, formatDate } from 'components/common/util/util';
import clsx from 'clsx';
import { Skeleton } from "@material-ui/lab";
import moment from 'moment';
import LoaderButton from 'components/common/loader.button/loader.button';
import KitFilters from './kit.filter';
import KitsTableView from './table.view';
import { isValidConfirmationPatientDetail } from './validator';
import AddLastMinuteTestDialog from './add.last.minute.test.dialog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const KitsList = (props) => {

    const mobileWidth = IsMobileWidth();
    const { kits, execStatusGetKits, execStatusUpdateKit, execStatusGetOrganisations, execStatusGetEvents, lastMinuteTestLinkSent } = props;
    const [state, setState] = useState({
        isAllCheckBoxSelected: false,
        selectedButton: '',
        showAddLastMinuteTestDialogOpen: false
    })
    const handleDownloadCertificate = (url) => {
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = url
        anchor.target = "_blank"
        document.body.appendChild(anchor)
        anchor.click()
    }
    let totalCount = 0;
    if (kits && kits.get('totalCount')) {
        totalCount = Math.ceil(kits.get('totalCount') / kits.get('limit'))
    }
    const getResultClassName = (resultStatus) => {
        switch (resultStatus) {
            case "Negative":
                return "result-negative"
            case "Positive":
                return "result-positive"
            case "Inconclusive":
                return "result-inconclusive"
            default:
                return "result-default"
        }
    }
    const getStatus = (status) => {
        switch (status) {
            case "CONFIRMATION_PENDING":
                return "Confirmation Pending"
            case "CONFIRMATION_APPROVED":
                return "Confirmation Approved"
            case "CONFIRMATION_LINK_SENT":
                return "Confirmation Link Sent"
            default:
                return status;
        }
    }
    const handleCheckBoxChange = (kit) => {
        props.updateAdminKitStatus({
            kitId: kit.get('id')
        })
    }
    const handleAllCheckBoxChange = () => {
        setState({ ...state, isAllCheckBoxSelected: !state.isAllCheckBoxSelected })
        props.updateAdminKitStatus({
            changeAllKitStatus: true,
            selected: !state.isAllCheckBoxSelected
        })
    }
    const confirmOrders = () => {
        setState({
            ...state, selectedButton: "CONFIRM_ORDER"
        })
        for (let kit of kits && kits.get('kitsArray')) {
            if (kit && kit.get('selected') === true) {
                props.updateKit({
                    kit: {
                        id: kit.get('id'),
                        status: "CONFIRMATION_APPROVED"
                    }
                })
            }
        }
    }
    const updatePatientDetailData = (kitId, name, value, data, fullNameOptional = {}) => {
        if (name === "serviceType") {
            props.updatePatientDetail({
                kit: {
                    id: kitId,
                    [name]: value,
                    price: data && data.price,
                    serviceTypeText: data && data.serviceTypeText,
                    courierCost: data && data.courierCost,
                    currency: data && data.currency,
                    currencySymbol: data && data.currencySymbol
                }
            })
        }
        else if (name === "preferredTime") {
            let tempValue = value
            let preferredFromTime = null
            let preferredToTime = null

            if (tempValue && tempValue.replaceAll(" ", "").length === 11) {
                tempValue = tempValue.replaceAll(" ", "")
                console.log(tempValue, "xyz")
                const preferredTimeRagex = /(((0[7-9])|(1[0-8])):([0-5][0-9])(-| - | -|- )((0[7-9])|(1[0-8])):([0-5][0-9]))/
                if (preferredTimeRagex.test(tempValue)) {
                    const preferredFromTimeInt = parseInt(tempValue.slice(0, 2))
                    const preferredToTimeInt = parseInt(tempValue.slice(6, 8))
                    if (preferredFromTimeInt <= preferredToTimeInt) {
                        preferredFromTime = tempValue.slice(0, 5)
                        preferredToTime = tempValue.slice(6, tempValue.length)
                    }
                    else {
                        tempValue = ""
                        preferredFromTime = null
                        preferredToTime = null
                    }
                }
                else {
                    tempValue = ""
                    preferredFromTime = null
                    preferredToTime = null
                }
            }
            else {
                tempValue = ""
                preferredFromTime = ""
                preferredToTime = ""
            }

            props.updatePatientDetail({
                kit: {
                    id: kitId,
                    [name]: tempValue,
                    preferredFromTime: preferredFromTime,
                    preferredToTime: preferredToTime,
                }
            })
        }
        else if (name === "country") {
            props.updatePatientDetail({
                kit: {
                    id: kitId,
                    country: value?.value,
                    countryText: value?.name,
                }
            })
        }
        else if (name === "homeAddressCountry") {
            props.updatePatientDetail({
                kit: {
                    id: kitId,
                    homeAddressCountry: value?.value,
                    homeAddressCountryText: value?.name,
                }
            })
        }
        else if (name === "familyName" || name === "givenName") {

            props.updatePatientDetail({
                kit: {
                    id: kitId,
                    [name]: value,
                    fullName: fullNameOptional
                }
            })
        }
        else {
            props.updatePatientDetail({
                kit: {
                    id: kitId,
                    [name]: value
                }
            })
        }
    }

    const sentConfirmationLink = () => {
        setState({
            ...state, selectedButton: "SEND_CONFIRMATION_LINK"
        })
        for (let kit of kits && kits.get('kitsArray')) {
            if (kit && kit.get('selected') === true) {
                const validationResult = isValidConfirmationPatientDetail(kit.toJS())
                if (validationResult.allValid === true && kit.get('status') === "CONFIRMATION_PENDING") {
                    props.updateKit({
                        kit: {
                            ...kit.toJS(),
                            id: kit.get('id'),
                            email: kit.get('email')?.toLowerCase()?.trim(),
                            telephone: kit.get('telephone')?.trim()?.replaceAll(" ", ""),
                            eventName: kit.get('eventName'),
                            orderUid: kit.get('orderUid'),
                            status: "CONFIRMATION_LINK_SENT",
                            fullName: `${kit.get('givenName')} ${kit.get('familyName')}`
                        }
                    })
                }
                else {
                    props.updateKit({
                        kit: {
                            id: kit.get('id'),
                            executionStatus: 'ERROR'
                        }
                    }, true)
                }
            }
        }
    }

    const showAddLastMinuteTestDialog = () => {
        props.resetAddLastMinuteTest();
        props.resetEventList();
        setState({ ...state, showAddLastMinuteTestDialogOpen: true })
    }
    const hideAddLastMinuteTestDialog = () => {
        props.resetEventList();
        setState({ ...state, showAddLastMinuteTestDialogOpen: false })
    }
    useEffect(() => {
        if (lastMinuteTestLinkSent === true) {
            setState({ ...state, showAddLastMinuteTestDialogOpen: false })
        }
    }, [lastMinuteTestLinkSent])
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    return (
        <div id="kit-list">
            <LoaderButton
                classNames="mt-2"
                variant="outlined"
                //disabled={execStatusUpdatePatientDetail.status === "PENDING" ? true : false}
                loading={execStatusGetKits.status === "PENDING" ? true : false}
                onClick={props.onRefresh} color="primary" >
                Refresh
            </LoaderButton>
            <div className="d-flex align-items-end flex-column justify-content-end">
                {/* {
                    kits && kits.get('kitsArray') && kits.get('kitsArray').size > 0 ?
                        <LoaderButton
                            disabled={execStatusUpdateKit.status === "PENDING" && state.selectedButton === "SEND_CONFIRMATION_LINK" ? true : false}
                            loading={execStatusUpdateKit.status === "PENDING" && state.selectedButton === "CONFIRM_ORDER" ? true : false}
                            onClick={confirmOrders} color="primary" >
                            Confirm Orders
                        </LoaderButton> : null
                } */}
                <Snackbar anchorOrigin={
                    { vertical: 'center', horizontal: 'center' }}
                    open={lastMinuteTestLinkSent} autoHideDuration={5000} onClose={props.resetAddLastMinuteTest}>
                    <Alert onClose={props.resetAddLastMinuteTest} severity="success">
                        Confirmation Link Successfully Sent to individual, Thanks
                    </Alert>
                </Snackbar>
                {
                    kits && kits.get('kitsArray') && kits.get('kitsArray').size > 0 ?
                        <LoaderButton
                            classNames="mt-2"
                            //disabled={execStatusUpdatePatientDetail.status === "PENDING" ? true : false}
                            loading={execStatusUpdateKit.status === "PENDING" && state.selectedButton === "SEND_CONFIRMATION_LINK" ? true : false}
                            onClick={sentConfirmationLink} color="primary" >
                            Confirm Tests
                        </LoaderButton> : null
                }
                <LoaderButton
                    classNames="mt-4"
                    //disabled={execStatusUpdatePatientDetail.status === "PENDING" ? true : false}
                    //loading={execStatusUpdateKit.status === "PENDING" && state.selectedButton === "SEND_CONFIRMATION_LINK" ? true : false}
                    onClick={showAddLastMinuteTestDialog} color="secondary" >
                    Add Last Minute Test
                </LoaderButton>
            </div>
            {
                state.showAddLastMinuteTestDialogOpen === true ?
                    <AddLastMinuteTestDialog execStatusGetEvents={execStatusGetEvents} execStatusGetOrganisations={execStatusGetOrganisations} handleClose={hideAddLastMinuteTestDialog}
                        open={state.showAddLastMinuteTestDialogOpen} {...props} /> : null
            }
            <div className="w-100 d-flex justify-content-start">
                <KitFilters
                    execStatusGetKits={execStatusGetKits}
                    //  onSearchEnter={onSearchEnter}
                    //  onSearchChange={onSearchChange}
                    onKitStatusChange={props.onKitStatusChange}
                    //onResultStatusChange={onResultStatusChange}
                    {...props} />
            </div>
            <div className="w-100 d-flex justify-content-start">
                {
                    <div className="w-100">
                        <KitsTableView
                            execStatusGetKits={execStatusGetKits}
                            kits={kits}
                            updatedState={props?.updatedState}
                            updatePatientDetailData={updatePatientDetailData}
                            {...props}
                            handleCheckBoxChange={handleCheckBoxChange}
                            handleAllCheckBoxChange={handleAllCheckBoxChange}
                            {...state} />
                        <div className="d-flex flex-column w-100">
                            {
                                totalCount > 1 ?
                                    <div className="d-flex justify-content-center pt-4">
                                        <Pagination
                                            page={kits.get('pageIndex') + 1}
                                            onChange={props.onPageChange}
                                            count={totalCount} color="primary" />
                                    </div> : null
                            }
                        </div>
                    </div>
                }
            </div>
            {
                kits && kits.get('kitsArray') && kits.get('kitsArray').size == 0 ?
                    <Paper className="paper-root p-4 text-danger d-flex justify-content-center">
                        No Data Found
                    </Paper> : null
            }
            {execStatusGetKits && execStatusGetKits.status === "ERROR" ? (
                <Paper className="paper-root">
                    <div className="text-danger d-flex justify-content-center font-italic pt-2 pb-2">
                        <b>
                            {" "}
                            {execStatusGetKits.errorDetail && typeof execStatusGetKits.errorDetail === 'object'
                                ? execStatusGetKits.errorDetail.get("description")
                                : "Something isn’t working as it should, please try again"}
                        </b>
                    </div>
                </Paper>
            ) : null}
        </div>
    )
}

export default KitsList