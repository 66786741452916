export const headCells = [
    {
        label: "No",
        id: "no"
    },
    // {
    //     label: "Courier Status",
    //     id: "courierStatus"
    // },

    {
        label: "First Name",
        id: "firstName"
    },
    {
        label: "Last Name",
        id: "lastName"
    },
    {
        label: "Email",
        id: "email"
    },
    {
        label: "Phone",
        id: "telephone"
    },
    {
        label: "Date of Test",
        id: "dateOfTest"
    },
    {
        label: "Service Type",
        id: "serviceType"
    },
    {
        label: "Preferred Time",
        id: "preferredTime"
    },
    {
        label: "Test Address Line 1",
        id: "addressLine1"
    },
    {
        label: "Test Address Line 2",
        id: "addressLine2"
    },
    {
        label: "Test City",
        id: "city"
    },
    // {
    //     label: "Test Country",
    //     id: "country"
    // },
    {
        label: "Test Postcode",
        id: "postcode"
    },

    {
        label: "Comments",
        id: "producerComments"
    },


    {
        label: "Org ID",
        id: "organisationId"
    },
    {
        label: "Job",
        id: "eventName"
    },
    {
        label: "Updated At",
        id: "updatedAt"
    },

    // {
    //     label: "Form Link",
    //     id: "ConfirmationLink"
    // },
    // {
    //     label: "Courier Status",
    //     id: "CourierStatus"
    // },

    {
        label: "Courier Cost",
        id: "courierCost"
    },


    // {
    //     label: "First Name",
    //     id: "givenName"
    // },
    // {
    //     label: "Last Name",
    //     id: "familyName"
    // },
    // {
    //     label: "Date of Birth",
    //     id: "DOB"
    // },

    // {
    //     label: "Gender",
    //     id: "gender"
    // },


    // {
    //     label: "Home Postcode",
    //     id: "homeAddressPostcode"
    // },
    // {
    //     label: "Home Address Line 1",
    //     id: "homeAddressLine1"
    // },
    // {
    //     label: "Home Address Line 2",
    //     id: "homeAddressLine2"
    // },
    // {
    //     label: "Home City",
    //     id: "homeAddressCity"
    // },
    // {
    //     label: "Home Country",
    //     id: "homeAddressCountry"
    // },
    // {
    //     label: "Nationality",
    //     id: "nationality"
    // },
    // {
    //     label: "Health Number",
    //     id: "healthNumber"
    // },
    // {
    //     label: "Document Type",
    //     id: "documentType"
    // },
    // {
    //     label: "Document Country",
    //     id: "documentCountry"
    // },
    // {
    //     label: "Document Number",
    //     id: "documentNumber"
    // },
    // {
    //     label: "Document Expiration",
    //     id: "documentExpiration"
    // },
    // {
    //     label: "Document Authority",
    //     id: "documentAuthority"
    // },
    // {
    //     label: "Status",
    //     id: "status"
    // },
    // {
    //     label: "",
    //     id: ""
    // }
    {
        label: "Lab",
        id: "lab"
    },
    {
        label: "Action",
        id: "edit-test"
    },
];

export const headCancelledLimited = [
    {
        label: "No",
        id: "no"
    },
    {
        label: "Courier Status",
        id: "courierStatus"
    },
    {
        label: "First Name",
        id: "firstName"
    },
    {
        label: "Last Name",
        id: "lastName"
    },
    {
        label: "Email",
        id: "email"
    },
    {
        label: "Phone",
        id: "telephone"
    },
    {
        label: "Date of Test",
        id: "dateOfTest"
    },
    {
        label: "Service Type",
        id: "serviceType"
    },
    {
        label: "Preferred Time",
        id: "preferredTime"
    },
    // {
    //     label: "Service Type",
    //     id: "serviceType"
    // },
    {
        label: "Test Address Line 1",
        id: "addressLine1"
    },
    {
        label: "Test Address Line 2",
        id: "addressLine2"
    },
    {
        label: "Test City",
        id: "city"
    },
    // {
    //     label: "Test Country",
    //     id: "country"
    // },
    {
        label: "Test Postcode",
        id: "postcode"
    },
    {
        label: "Comments",
        id: "producerComments"
    },
    {
        label: "Org ID",
        id: "organisationId"
    },
    {
        label: "Job",
        id: "eventName"
    },

    {
        label: "Updated At",
        id: "updatedAt"
    },

    // {
    //     label: "Form Link",
    //     id: "ConfirmationLink"
    // },
    // {
    //     label: "Courier Status",
    //     id: "CourierStatus"
    // },

    {
        label: "Courier Cost",
        id: "courierCost"
    },

    {
        label: "Lab",
        id: "lab"
    },
    // {
    //     label: "Preferred Time",
    //     id: "preferredTime"
    // },

    // {
    //     label: "First Name",
    //     id: "givenName"
    // },
    // {
    //     label: "Last Name",
    //     id: "familyName"
    // },
    // {
    //     label: "Date of Birth",
    //     id: "DOB"
    // },

    // {
    //     label: "Gender",
    //     id: "gender"
    // },


    // {
    //     label: "Home Postcode",
    //     id: "homeAddressPostcode"
    // },
    // {
    //     label: "Home Address Line 1",
    //     id: "homeAddressLine1"
    // },
    // {
    //     label: "Home Address Line 2",
    //     id: "homeAddressLine2"
    // },
    // {
    //     label: "Home City",
    //     id: "homeAddressCity"
    // },
    // {
    //     label: "Home Country",
    //     id: "homeAddressCountry"
    // },
    // {
    //     label: "Nationality",
    //     id: "nationality"
    // },
    // {
    //     label: "Health Number",
    //     id: "healthNumber"
    // },
    // {
    //     label: "Document Type",
    //     id: "documentType"
    // },
    // {
    //     label: "Document Country",
    //     id: "documentCountry"
    // },
    // {
    //     label: "Document Number",
    //     id: "documentNumber"
    // },
    // {
    //     label: "Document Expiration",
    //     id: "documentExpiration"
    // },
    // {
    //     label: "Document Authority",
    //     id: "documentAuthority"
    // },
    // {
    //     label: "Status",
    //     id: "status"
    // },
    // {
    //     label: "",
    //     id: ""
    // }
]

export const headConfirmationSentLimited = [
    {
        label: "No",
        id: "no"
    },
    {
        label: "Form Link",
        id: "ConfirmationLink"
    },
    {
        label: "Courier Status",
        id: "courierStatus"
    },
    {
        label: "First Name",
        id: "firstName"
    },
    {
        label: "Last Name",
        id: "lastName"
    },
    {
        label: "Email",
        id: "email"
    },
    {
        label: "Phone",
        id: "telephone"
    },
    {
        label: "Date of Test",
        id: "dateOfTest"
    },
    {
        label: "Service Type",
        id: "serviceType"
    },
    {
        label: "Preferred Time",
        id: "preferredTime"
    },
    // {
    //     label: "Service Type",
    //     id: "serviceType"
    // },
    {
        label: "Test Address Line 1",
        id: "addressLine1"
    },
    {
        label: "Test Address Line 2",
        id: "addressLine2"
    },
    {
        label: "Test City",
        id: "city"
    },
    // {
    //     label: "Test Country",
    //     id: "country"
    // },
    {
        label: "Test Postcode",
        id: "postcode"
    },
    {
        label: "Comments",
        id: "producerComments"
    },

    {
        label: "Org ID",
        id: "organisationId"
    },
    {
        label: "Job",
        id: "eventName"
    },
    {
        label: "Updated At",
        id: "updatedAt"
    },
    {
        label: "Courier Cost",
        id: "courierCost"
    },
    {
        label: "Lab",
        id: "lab"
    },
    {
        label: "Action",
        id: "edit-test"
    }
    // {
    //     label: "Preferred Time",
    //     id: "preferredTime"
    // },

    // {
    //     label: "First Name",
    //     id: "givenName"
    // },
    // {
    //     label: "Last Name",
    //     id: "familyName"
    // },
    // {
    //     label: "Date of Birth",
    //     id: "DOB"
    // },

    // {
    //     label: "Gender",
    //     id: "gender"
    // },


    // {
    //     label: "Home Postcode",
    //     id: "homeAddressPostcode"
    // },
    // {
    //     label: "Home Address Line 1",
    //     id: "homeAddressLine1"
    // },
    // {
    //     label: "Home Address Line 2",
    //     id: "homeAddressLine2"
    // },
    // {
    //     label: "Home City",
    //     id: "homeAddressCity"
    // },
    // {
    //     label: "Home Country",
    //     id: "homeAddressCountry"
    // },
    // {
    //     label: "Nationality",
    //     id: "nationality"
    // },
    // {
    //     label: "Health Number",
    //     id: "healthNumber"
    // },
    // {
    //     label: "Document Type",
    //     id: "documentType"
    // },
    // {
    //     label: "Document Country",
    //     id: "documentCountry"
    // },
    // {
    //     label: "Document Number",
    //     id: "documentNumber"
    // },
    // {
    //     label: "Document Expiration",
    //     id: "documentExpiration"
    // },
    // {
    //     label: "Document Authority",
    //     id: "documentAuthority"
    // },
    // {
    //     label: "Status",
    //     id: "status"
    // },
    // {
    //     label: "",
    //     id: ""
    // }
]

export const headAssignedLimited = [
    {
        label: "No",
        id: "no"
    },
    {
        label: "Form Link",
        id: "ConfirmationLink"
    },
    {
        label: "Courier Status",
        id: "courierStatus"
    },
    {
        label: "First Name",
        id: "firstName"
    },
    {
        label: "Last Name",
        id: "lastName"
    },
    {
        label: "Email",
        id: "email"
    },
    {
        label: "Phone",
        id: "telephone"
    },
    {
        label: "Date of Test",
        id: "dateOfTest"
    },
    {
        label: "Service Type",
        id: "serviceType"
    },
    {
        label: "Preferred Time",
        id: "preferredTime"
    },
    // {
    //     label: "Service Type",
    //     id: "serviceType"
    // },
    {
        label: "Test Address Line 1",
        id: "addressLine1"
    },
    {
        label: "Test Address Line 2",
        id: "addressLine2"
    },
    {
        label: "Test City",
        id: "city"
    },
    // {
    //     label: "Test Country",
    //     id: "country"
    // },
    {
        label: "Test Postcode",
        id: "postcode"
    },
    {
        label: "Comments",
        id: "producerComments"
    },
    {
        label: "Org ID",
        id: "organisationId"
    },
    {
        label: "Job",
        id: "eventName"
    },
    {
        label: "Updated At",
        id: "updatedAt"
    },

    // {
    //     label: "Form Link",
    //     id: "ConfirmationLink"
    // },
    // {
    //     label: "Courier Status",
    //     id: "CourierStatus"
    // },

    {
        label: "Courier Cost",
        id: "courierCost"
    },
    {
        label: "Lab",
        id: "lab"
    },
    // {
    //     label: "Actions",
    //     id: "edit-test"
    // }
    // {
    //     label: "Preferred Time",
    //     id: "preferredTime"
    // },

    // {
    //     label: "First Name",
    //     id: "givenName"
    // },
    // {
    //     label: "Last Name",
    //     id: "familyName"
    // },
    // {
    //     label: "Date of Birth",
    //     id: "DOB"
    // },

    // {
    //     label: "Gender",
    //     id: "gender"
    // },


    // {
    //     label: "Home Postcode",
    //     id: "homeAddressPostcode"
    // },
    // {
    //     label: "Home Address Line 1",
    //     id: "homeAddressLine1"
    // },
    // {
    //     label: "Home Address Line 2",
    //     id: "homeAddressLine2"
    // },
    // {
    //     label: "Home City",
    //     id: "homeAddressCity"
    // },
    // {
    //     label: "Home Country",
    //     id: "homeAddressCountry"
    // },
    // {
    //     label: "Nationality",
    //     id: "nationality"
    // },
    // {
    //     label: "Health Number",
    //     id: "healthNumber"
    // },
    // {
    //     label: "Document Type",
    //     id: "documentType"
    // },
    // {
    //     label: "Document Country",
    //     id: "documentCountry"
    // },
    // {
    //     label: "Document Number",
    //     id: "documentNumber"
    // },
    // {
    //     label: "Document Expiration",
    //     id: "documentExpiration"
    // },
    // {
    //     label: "Document Authority",
    //     id: "documentAuthority"
    // },
    // {
    //     label: "Status",
    //     id: "status"
    // },
    // {
    //     label: "",
    //     id: ""
    // }
]

// hmz
// export const headLimitedCells = [
//     {
//         label: "No",
//         id: "no"
//     },
//     {
//         label: "Organisation Id",
//         id: "organisationId"
//     },
//     {
//         label: "Event",
//         id: "eventName"
//     },
//     {
//         label: "Courier Cost",
//         id: "courierCost"
//     },
//     {
//         label: "Comments",
//         id: "producerComments"
//     },
//     {
//         label: "Full Name",
//         id: "fullName"
//     },
//     {
//         label: "Email",
//         id: "email"
//     },
//     {
//         label: "Date of Test",
//         id: "dateOfTest"
//     },
//     {
//         label: "Preferred Time",
//         id: "preferredTime"
//     },
//     {
//         label: "Service Type",
//         id: "serviceType"
//     },
//     {
//         label: "First Name",
//         id: "givenName"
//     },
//     {
//         label: "Last Name",
//         id: "familyName"
//     },
//     // {
//     //     label: "Date of Birth",
//     //     id: "DOB"
//     // },
//     {
//         label: "Phone",
//         id: "telephone"
//     },
//     {
//         label: "Gender",
//         id: "gender"
//     },
//     {
//         label: "Test Postcode",
//         id: "postcode"
//     },
//     {
//         label: "Test Address Line 1",
//         id: "addressLine1"
//     },
//     {
//         label: "Test Address Line 2",
//         id: "addressLine2"
//     },
//     {
//         label: "Test City",
//         id: "city"
//     },
//     {
//         label: "Test Country",
//         id: "country"
//     },
//     {
//         label: "Home Postcode",
//         id: "homeAddressPostcode"
//     },
//     {
//         label: "Home Address Line 1",
//         id: "homeAddressLine1"
//     },
//     {
//         label: "Home Address Line 2",
//         id: "homeAddressLine2"
//     },
//     {
//         label: "Home City",
//         id: "homeAddressCity"
//     },
//     {
//         label: "Home Country",
//         id: "homeAddressCountry"
//     },
//     // {
//     //     label: "Nationality",
//     //     id: "nationality"
//     // },
//     // {
//     //     label: "Health Number",
//     //     id: "healthNumber"
//     // },
//     // {
//     //     label: "Document Type",
//     //     id: "documentType"
//     // },
//     // {
//     //     label: "Document Country",
//     //     id: "documentCountry"
//     // },
//     // {
//     //     label: "Document Number",
//     //     id: "documentNumber"
//     // },
//     // {
//     //     label: "Document Expiration",
//     //     id: "documentExpiration"
//     // },
//     // {
//     //     label: "Document Authority",
//     //     id: "documentAuthority"
//     // },
//     {
//         label: "Status",
//         id: "status"
//     },
//     {
//         label: "Updated At",
//         id: "updatedAt"
//     },
//     {
//         label: "",
//         id: ""
//     }
// ];


// zain
// export const headLimitedCells = [
//     {
//         label: "No",
//         id: "no"
//     },
//     {
//         label: "Organisation Id",
//         id: "organisationId"
//     },
//     {
//         label: "Event",
//         id: "eventName"
//     },
//     {
//         label: "Cancel Test",
//         id: "cancel-test"
//     },
//     {
//         label: "Update Test",
//         id: "update-test"
//     },
//     // {
//     //     label: "Courier Status",
//     //     id: "courierStatus"
//     // },
//     {
//         label: "Full Name",
//         id: "fullName"
//     },
//     // {
//     //     label: "Service Type",
//     //     id: "serviceType"
//     // },
//     {
//         label: "Test Address Line 1",
//         id: "addressLine1"
//     },
//     {
//         label: "Test Address Line 2",
//         id: "addressLine2"
//     },
//     {
//         label: "Test City",
//         id: "city"
//     },
//     // {
//     //     label: "Test Country",
//     //     id: "country"
//     // },
//     {
//         label: "Test Postcode",
//         id: "postcode"
//     },
//     {
//         label: "Date of Test",
//         id: "dateOfTest"
//     },
//     {
//         label: "Comments",
//         id: "producerComments"
//     },
//     {
//         label: "Email",
//         id: "email"
//     },
//     {
//         label: "Phone",
//         id: "telephone"
//     },
//     {
//         label: "Org ID",
//         id: "organisationId"
//     },
//     {
//         label: "Job",
//         id: "eventName"
//     },
//     {
//         label: "Updated At",
//         id: "updatedAt"
//     },
//
//     // {
//     //     label: "Form Link",
//     //     id: "ConfirmationLink"
//     // },
//     // {
//     //     label: "Courier Status",
//     //     id: "CourierStatus"
//     // },
//
//     {
//         label: "Courier Cost",
//         id: "courierCost"
//     },
//     // {
//     //     label: "Preferred Time",
//     //     id: "preferredTime"
//     // },
//
//     // {
//     //     label: "First Name",
//     //     id: "givenName"
//     // },
//     // {
//     //     label: "Last Name",
//     //     id: "familyName"
//     // },
//     // {
//     //     label: "Date of Birth",
//     //     id: "DOB"
//     // },
//
//     // {
//     //     label: "Gender",
//     //     id: "gender"
//     // },
//
//
//     // {
//     //     label: "Home Postcode",
//     //     id: "homeAddressPostcode"
//     // },
//     // {
//     //     label: "Home Address Line 1",
//     //     id: "homeAddressLine1"
//     // },
//     // {
//     //     label: "Home Address Line 2",
//     //     id: "homeAddressLine2"
//     // },
//     // {
//     //     label: "Home City",
//     //     id: "homeAddressCity"
//     // },
//     // {
//     //     label: "Home Country",
//     //     id: "homeAddressCountry"
//     // },
//     // {
//     //     label: "Nationality",
//     //     id: "nationality"
//     // },
//     // {
//     //     label: "Health Number",
//     //     id: "healthNumber"
//     // },
//     // {
//     //     label: "Document Type",
//     //     id: "documentType"
//     // },
//     // {
//     //     label: "Document Country",
//     //     id: "documentCountry"
//     // },
//     // {
//     //     label: "Document Number",
//     //     id: "documentNumber"
//     // },
//     // {
//     //     label: "Document Expiration",
//     //     id: "documentExpiration"
//     // },
//     // {
//     //     label: "Document Authority",
//     //     id: "documentAuthority"
//     // },
//     // {
//     //     label: "Status",
//     //     id: "status"
//     // },
//     // {
//     //     label: "",
//     //     id: ""
//     // }
// ];
