import React from 'react'

const Label = ({ children, style, blue, className }) => {
	const classes = [blue ? 'blue' : 'black', className ? className : ''].join(' ')
	return (
		<label style={style} classes={classes}>
			{children}
		</label>
	)
}

const Body = ({ children, style, blue, bold, paragraph, error, centered, className, small }) => {
	const classes = [
		'body',
		small ? 'small' : '',
		blue ? 'blue' : 'black',
		bold ? 'bold' : '',
		error ? 'error' : '',
		paragraph ? 'paragraph' : '',
		centered ? 'centered' : '',
		className ? className : '',
	].join(' ')

	return (
		<span className={classes} style={style}>
			{children}
		</span>
	)
}

export { Label, Body }
