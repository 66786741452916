import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
	Row,
	Column,
	Body,
	BackButton,
	Spacer,
	OrderPatientDetailsEditor,
	Button
} from '../../../components'
import {
	BeaconForm,
	SubmitButton,
	FormCheckboxInput,
	FormRadioMenuInput,
} from '../../../components/forms'
import { OrderPatientDetailsSameDayEditor } from '../../../components/OrderPatientDetailsSameDayEditor'
import { toUiPatient, toSbsPatient } from '../../../utils/sbs-utils'

// const options = [
// 	{ value: 'courier', label: 'Courier' },
// 	{ value: 'self-test', label: 'Mail' },
// 	{ value: 'same-day-test', label: 'Same Day Order' },
// ]

// const patient = {
// 	addressLine1: '43 Old Bond St',
// 	addressLine2: 'Mayfair',
// 	city: 'London',
// 	confirmationPhone: '012',
// 	dateOfBirth: '',
// 	dateOfSwab: '',
// 	deliverBy: '',
// 	deliveryDate: '29/01/2021',
// 	deliveryWindow: '',
// 	driverNotes: '',
// 	email: 'a@a.com',
// 	firstName: 'Ameer',
// 	jobNumber: '',
// 	lastName: 'Ahmed',
// 	org: 'test-gb',
// 	postcode: 'W1S 4QT',
// 	telephone: '012',
// 	orderType: 'same-day-test',
// }

const OrderPatientDetailsEntry = ({ onSubmit, value, onBack, orgs, index, onDelete }) => {
	// const [patientDetails, setPatientDetails] = useState(value.patientDetails)
	// value.patientDetails = patient
	console.log('value.adaf: ', value.patientDetails.orderType)
	const [isMailKit, setIsMailKit] = useState(
		(value.patientDetails.orderType && value.patientDetails.orderType !== 'courier') || false
	)

	const handlePatientSubmit = (patientDetails) => {
		console.log('patient: ', patientDetails)
		onSubmit({ patientDetails })
	}

	const dBlock = {
		display: 'block'
	}

	return (
		<>
			{
				index < 1 ?
					<Row size={8} spacingTop="s" style={dBlock}>
						<h3>Please enter same day order details</h3>
						<Body>
							All fields labeled with (*) are required.
						</Body>
					</Row> : null
			}

			<BeaconForm
				onSubmit={handlePatientSubmit}
				initialValues={value.patientDetails}
				io={{ input: toUiPatient, output: toSbsPatient }}
			>
				<OrderPatientDetailsSameDayEditor isMailKit={isMailKit} />
				<Spacer spacing="s" />
				<Spacer spacing="s" />
				<Row contained size={8}>
					<Spacer spacing="s" />
					<SubmitButton primary submit>
						Save
					</SubmitButton>
					{
						index > 0 ?
							<Button onClick={onDelete}>
								Delete
					        </Button> : null
					}
				</Row>
			</BeaconForm>
		</>
	)
}

export { OrderPatientDetailsEntry }
