import React from 'react'
import { Column, Body } from '../../../components'

const NoAddressesMessage = () => {
	return (
		<Column size={8} bordered centered>
			<Body>Please add your first delivery address</Body>
		</Column>
	)
}

export { NoAddressesMessage }
